import { userConstants } from '../constants';
import { userServices, portalServices } from '../services';
import { history } from '../helpers';

export const userActions = {
  toggleBugVis
};

function toggleBugVis(bool) {
  return {
    type: userConstants.TOGGLE_BUG_REPORT_VIS,
    bool
  }
}

// function getAll() {
//   return dispatch => {
//     dispatch(request());

//     portalServices.getAll().then(
//       response => dispatch(success(response.data)),
//       error => dispatch(failure(error.toString()))
//     );
//   };

//   function request() {
//     return { type: userConstants.GET_USER_DATA_REQUEST };
//   }
//   function success(data) {
//     return { type: userConstants.GET_USER_DATA_SUCCESS, data };
//   }
//   function failure(error) {
//     return { type: userConstants.GET_USER_DATA_FAILURE, error };
//   }
// }
