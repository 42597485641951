import React, { Fragment } from "react";

import { ruler } from "../../../../../assets";
import { Tooltip, Button } from "antd";

import { useSelector, useDispatch } from "react-redux";
import { carportActions } from "../../../../Redux/actions/carport.actions";

import { Control } from "../styles";

const ToggleAlignment = () => {
  const _selected = useSelector((state) => state.carport.selected);
  const activeTool = useSelector((state) => state.carport.activeTool);
  const canWeAlign = useSelector((state) => Object.values(state.carport.rectangles).length >= 2);
  const dispatch = useDispatch();

  const toggleAlignmentTool = () => {
    dispatch(carportActions.setActiveTool(activeTool == "align" ? undefined : "align"));
  };

  return (
    <Control column={2}>
      <Tooltip title="Toggle Alignment On/Off" placement="bottom">
        <Button
          shape="circle"
          size="large"
          disabled={_selected && canWeAlign ? false : true}
          style={{
            margin: "0 4px",
            backgroundColor: activeTool === "align" ? "#60de4f" : _selected ? "#fff" : "#9e9e9e",
            borderColor: activeTool === "align" ? "#d9d9d9" : _selected ? "#60de4f" : "#fff",
          }}
          onClick={toggleAlignmentTool}
        >
          <img style={{ marginBottom: "2px" }} src={ruler} />
        </Button>
      </Tooltip>
    </Control>
  );
};

export { ToggleAlignment };
