import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers";
import config from "config";

const initialState = {};

const middleware = [thunk];

export const store =
  config.env === "prod" || config.env === "test"
    ? // production
      createStore(
        rootReducer,
        initialState,
        compose(
          applyMiddleware(...middleware)
          // Redux Devtools setup
          // Chrome Extension available here --> https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=en
        )
      )
    : // development
      createStore(
        rootReducer,
        initialState,
        compose(
          applyMiddleware(...middleware),
          // Redux Devtools setup
          // Chrome Extension available here --> https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=en
          window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
      );
