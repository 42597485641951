export const projectManagerConstants = {
  GET_USER_DATA_REQUEST: 'GET_USER_DATA_REQUEST ',
  GET_USER_DATA_SUCCESS: 'GET_USER_DATA_SUCCESS',
  GET_USER_DATA_FAILURE: 'GET_USER_DATA_FAILURE',

  NEW_PROJECT: 'NEW_PROJECT',

  LOAD_PROJECT: 'LOAD_PROJECT',
  LOAD_PROJECT_ROW: 'LOAD_PROJECT_ROW',

  SAVE_PROJECT: 'SAVE_PROJECT',
  PROJECT_SAVE_AS: 'PROJECT_SAVE_AS',

  PROJECT_NAME_UPDATE: 'PROJECT_NAME_UPDATE',

  PROJECT_SELECT: 'PROJECT_SELECT',

  DELETE_PROJET: 'DELETE_PROJECT',

  HANDLE_SHOW: 'HANDLE_SHOW',
  HANDLE_CLOSE: 'HANDLE_CLOSE',

  FILTER_PROJECTS: 'FILTER_PROJECTS',
};
