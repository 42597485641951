import React, { Component, Fragment } from "react";
import { NavLink, withRouter } from "react-router-dom";
import pubsub from "pubsub-js";
import PropTypes from "prop-types";

import styled from "styled-components";

import { Tooltip, Menu, Popconfirm, Button } from "antd";

import { authActions, userActions } from "../components/Redux/actions";
import { logo_white_text, usersIcon, panelIcon, ts_logo_white } from "../assets";

import { BugReport } from "../components/Sunfig";

// icons
import { QuestionCircleFilled, LogoutOutlined, BugFilled, CarOutlined } from "@ant-design/icons";

// *** nav styles ***
const Nav = styled.nav`
  display: grid;
  grid-column: 1;
  grid-row: 1;
  grid-template-rows: min-content min-content 1fr 200px;
  height: 100%;
  background-color: #002bcb; // for browsers that don't support gradients
  background-image: linear-gradient(-40deg, #60de4f, #00c0b6, #002bdb);
  justify-content: center;
  padding: 0;

  .brand-logo,
  .ts-logo {
    margin: 15px auto;
    width: inherit;
  }
  .brand-logo {
    grid-row: 1;

    img {
      width: 80px;
    }
  }

  .ts-logo {
    grid-row: 2;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    grid-row: 3;
    height: 100%;
    width: 95px;
    align-content: center;
    margin-top: 80px;

    .users-link {
      margin-bottom: 40px;
    }

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 75px;
      width: inherit;
      padding: 0;
      color: white;
      font-size: 14px;
      img {
        margin-bottom: 5px;
      }
    }
  }

  .active-link {
    background-color: rgba(255, 255, 255, 0.15);
  }

  .menu-container {
    grid-row: 4;
    grid-template-rows: repeat(3, min-content);
    display: grid;
    place-items: center;

    & > ul {
      background-color: #60de4f;
      padding: 0;
      border-right: 0px;

      & > li {
        padding: 0 auto;
      }
    }
  }
`;

class Navigation extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleBugreport = this.handleBugreport.bind(this);

    this.state = {
      admin: false,
      adminLoaded: false,
      userBlockCollapse: false,
      // collapse: {
      //   home: this.routeActive(['home']),
      //   simulation: this.routeActive('simulation'),
      //   userManagement: this.routeActive('userManagement'),
      //   pages: false,
      // },

      bugVisible: false,

      drawer_visible: false,
      current_drawer: -1,
      deep_drawer_visible: false,
      current_deep_drawer: -1,
    };

    this.pubsub_token = pubsub.subscribe("toggleUserblock", () => {
      this.setState({
        userBlockCollapse: !this.state.userBlockCollapse,
      });
    });
  }

  componentDidMount() {
    let isAdmin = localStorage.getItem("admin");
    this.setState({ admin: isAdmin == "true" });
  }

  toggleBugVis = (toggle) => {
    this.props.dispatch(userActions.toggleBugVis(toggle));
    this.setState({ bugVisible: toggle });
  };

  resetState(e) {
    // e.preventDefault();
    // console.log('resetting state');
    pubsub.publish("resetState");
  }

  navigator(route) {
    this.props.history.push(route);
  }

  componentWillUnmount() {
    // React removed me from the DOM, I have to unsubscribe from the pubsub using my token
    pubsub.unsubscribe(this.pubsub_token);
  }

  // routeActive(paths) {
  //   // console.log(this.props.location.pathname);
  //   paths = Array.isArray(paths) ? paths : [paths];
  //   if (paths.indexOf(this.props.location.pathname.replace('/', '')) > -1) return true;
  //   return false;
  // }

  toggleUserblock(e) {
    e.preventDefault();
    pubsub.publish("toggleUserblock");
  }

  showDrawer = (drawer) => {
    // console.log('loading drawer', drawer)
    this.setState({
      drawer_visible: true,
      current_drawer: drawer,
    });
  };

  showDeepDrawer = (drawer) => {
    this.setState({
      deep_drawer_visible: true,
      current_deep_drawer: drawer,
    });
  };

  onCloseDrawer = () => {
    // console.log('closing drawer')
    this.setState({
      drawer_visible: false,
      current_drawer: -1,
    });
  };
  onCloseDeepDrawer = () => {
    // console.log('closing drawer')
    this.setState({
      deep_drawer_visible: false,
      current_deep_drawer: -1,
    });
  };

  handleOpenDrawer = (event) => {
    this.showDrawer("rbi_root");
  };

  handleBugreport(event) {
    event.preventDefault();
    event.persist();
    this.toggleBugVis(true);
    // console.log('bug');
    // pubsub.publish('reportBug');
  }

  render() {
    const path = this.props.location.pathname.replace("/", "");
    const key = path == "" ? "portal" : path;
    return (
      <Fragment>
        <Nav>
          {/* logo */}
          <div className="brand-logo">
            <NavLink to="/">
              <img src={ts_logo_white} />
            </NavLink>
          </div>

          {/* routes */}
          <div className="nav-links">
            {this.state.admin === true && (
              <NavLink className="users-link" activeClassName="active-link" exact to="/userManagement" title="User Management">
                <img src={usersIcon} alt="" />
                Users
              </NavLink>
            )}
            <NavLink
              activeClassName="active-link"
              exact
              to="/"
              title="Ground Mount"
              onClick={(e) => {
                this.resetState(e);
              }}
            >
              <img src={panelIcon} alt="" />
              Ground
            </NavLink>
            <NavLink activeClassName="active-link" exact to="/canopy" title="Canopy">
              <CarOutlined style={{ fontSize: "30px" }} />
              Canopy
            </NavLink>
          </div>

          {/* help, bug report, logout */}
          <div className="menu-container">
            <Tooltip placement="right" title="Support Documention">
              <Button size="large" type="link" onClick={(event) => this.props.handleOpenDrawer(event)}>
                <QuestionCircleFilled style={{ color: "#fff", fontSize: 20 }} />
              </Button>
            </Tooltip>
            <Tooltip placement="right" title="Bug Report">
              <Button size="large" type="link" onClick={(event) => this.handleBugreport(event)}>
                <BugFilled style={{ color: "#fff", fontSize: 20 }} />
              </Button>
            </Tooltip>
            <Tooltip placement="right" title="Logout">
              <Popconfirm title="Logout?" onConfirm={(event) => this.props.dispatch(authActions.logout())} okText="Yes" cancelText="No">
                <Button size="large" type="link">
                  <LogoutOutlined style={{ color: "#fff", fontSize: 20 }} />
                </Button>
              </Popconfirm>
            </Tooltip>

            {/* <Menu onClick={this.handleClick} selectedKeys={[this.state.current]}>
              <Menu.Item key="help" className="nav-button">
                <Tooltip placement="right" title="Support Documention">
                  <Button type="link" onClick={(event) => this.props.handleOpenDrawer(event)}>
                    <QuestionCircleFilled style={{ color: "white", width: "100%", fontSize: "20px" }} />
                  </Button>
                </Tooltip>
              </Menu.Item>
              <Menu.Item key="bug" className="nav-button">
                <Tooltip placement="right" title="Bug Report">
                  <Button type="link" onClick={(event) => this.handleBugreport(event)}>
                    <BugFilled style={{ color: "white", width: "100%", fontSize: "20px" }} />
                  </Button>
                </Tooltip>
              </Menu.Item>
              <Menu.Item key="logout" className="nav-button">
                <Tooltip placement="right" title="Logout">
                  <Popconfirm title="Logout?" onConfirm={(event) => this.props.dispatch(authActions.logout())} okText="Yes" cancelText="No">
                    <Button type="link">
                      <LogoutOutlined style={{ color: "white", width: "100%", fontSize: "20px" }} />
                    </Button>
                  </Popconfirm>
                </Tooltip>
              </Menu.Item>
            </Menu> */}
          </div>
        </Nav>

        <BugReport visible={this.state.bugVisible} toggleVis={this.toggleBugVis} page={this.props.page} />
      </Fragment>
    );
  }
}

Navigation.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Navigation);
