import React from 'react';

import { useSelector } from 'react-redux';

import { Popup } from 'react-leaflet';

import * as turf from '@turf/turf';

const OverlapCheck = () => {
  const overlapping = useSelector((state) => state.carport.overlapping);
  const visual = useSelector((state) => state.carport.visual);

  if (Object.values(overlapping).length == 0) {
    return <></>;
  }

  const getCenter = (geoJson) => {
    let centerPoint = turf.center(geoJson);
    return { lat: centerPoint.geometry.coordinates[1], lng: centerPoint.geometry.coordinates[0] };
  };

  return (
    <>
      {Object.values(overlapping).map((id, index) => {
        return (
          <Popup key={`overlap_${index}`} closeOnClick={false} draggable={false} className="canopy-label" position={getCenter(visual[id].structure)} autoClose={false}>
            <p style={{ margin: '0px' }}>{visual[id].name}</p>
          </Popup>
        );
      })}
    </>
  );
};

export { OverlapCheck };
