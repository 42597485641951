import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { BingLayer } from './components';
import { TileSets } from './CanopyConstants';
// import { WMTSLayer } from './WMTS'

export function MapTileLayer() {
  // get the map from parent
  const bing_key = 'Apg1Wo2sThhv7onPyHncSIy7J3Nn98FFIzAhWYNFDrNLDpCRkZaSic1uuBHRoppM';
  const activeTileSet = useSelector((state) => state.carport.map.activeTileSet);
  const [bingLayerVisible, setBingLayerVisible] = useState(true);
  // const [activeTileSet, setActivetileSet] = useState('Satellite');
  // const [zoom, setZoom] = useState(map.getZoom());

  useEffect(() => {
    setBingLayerVisible(false);
    const timeout = setTimeout(() => {
      setBingLayerVisible(true);
    }, 100);
    //
    return () => clearTimeout(timeout);
  }, [activeTileSet]);

  return <>{bingLayerVisible && <BingLayer bingkey={bing_key} type={TileSets[activeTileSet]} maxZoom={25} maxNativeZoom={18} />}</>;
}
