import { inputsConstants } from "../constants";
import defaultProducts from "../../Sunfig/data/defaultProductData";
import defaultModules from "../../Sunfig/data/defaultModules";

import { inputsInitialState, default_inputs, default_topo } from "./portal_inputs";
import { getBounds, getNewCenterPoint, uuid } from "../../../utils/map_helper.js";
import { run_side_effects } from "../Util";

export function inputs(state = JSON.parse(JSON.stringify(inputsInitialState)), action) {
  switch (action.type) {
    case inputsConstants.SELECT_MODULE_BY_INDEX:
      // console.log('module selected', JSON.parse(JSON.stringify(defaultModules[action.index])))

      let new_mod_inputs;

      new_mod_inputs = {
        ...state,
        ...JSON.parse(JSON.stringify(defaultModules[action.index])),
        uiState: {
          ...state.uiState,
          selected_module_index: action.index,
        },
      };

      new_mod_inputs = run_side_effects(new_mod_inputs, null, true);

      return new_mod_inputs;

    case inputsConstants.SELECT_PRODUCT_BY_INDEX:
      // console.log('product selected', JSON.parse(JSON.stringify(defaultProducts[action.index])))

      let new_prod_inputs;

      new_prod_inputs = {
        ...state,
        ...JSON.parse(JSON.stringify(defaultProducts[action.index])),
        uiState: {
          ...state.uiState,
          selected_product_index: action.index,
        },
      };

      new_prod_inputs = run_side_effects(new_prod_inputs, null, true);

      return new_prod_inputs;

    case inputsConstants.UPDATE_UI_STATE:
      // console.log('uistate updated', action.key, action.value)
      let new_ui_state;

      let update_ui_state_keys = [];
      update_ui_state_keys.push(action.key);

      new_ui_state = {
        ...state,
        uiState: {
          ...state.uiState,
          [action.key]: action.value,
        },
      };

      if (action.key == "topo_loading") {
        // indicates some topo job has started
        new_ui_state.uiState.topo_error = "";
      }

      new_ui_state = run_side_effects(new_ui_state, update_ui_state_keys);

      return new_ui_state;

    case inputsConstants.UPDATE_INPUT_BY_NAME:
      // console.log('input updated', action.key, action.value)
      let new_input_state;

      let inputs_keys = [];
      inputs_keys.push(action.key);

      new_input_state = {
        ...state,
        [action.key]: action.value,
      };

      new_input_state = run_side_effects(new_input_state, inputs_keys);

      return new_input_state;

    case inputsConstants.UPDATE_SITE_INPUT:
      let new_site_inputs = {};
      if (action.key == "add_site_feature") {
        new_site_inputs = { site_features: { ...state.site_features } };
        new_site_inputs.site_features[action.value.properties.index] = action.value;
      }
      if (action.key == "edit_site_feature") {
        new_site_inputs = { site_features: { ...state.site_features } };
        new_site_inputs.site_features[action.value.properties.index] = action.value;
      }
      if (action.key == "remove_site_feature") {
        new_site_inputs = { site_features: { ...state.site_features } };
        delete new_site_inputs.site_features[action.value];
      }
      let new_bounds = getBounds(new_site_inputs.site_features);
      new_site_inputs.boundary_bbox = new_bounds;
      // set latitude / longitude -- which are the "site_center lat/lng"
      new_site_inputs.latitude = new_bounds[1] + Math.abs(new_bounds[3] - new_bounds[1]) / 2;
      new_site_inputs.longitude = new_bounds[0] + Math.abs(new_bounds[2] - new_bounds[0]) / 2;
      // console.log(new_site_inputs.boundary_bbox, new_site_inputs.latitude, new_site_inputs.longitude)

      return { ...state, ...new_site_inputs };

    case inputsConstants.UPDATE_BULK_INPUTS:
      console.log("update bulk", action);
      let new_bulk_update = {
        ...JSON.parse(JSON.stringify(inputsInitialState)),
        ...action.bulk_inputs,
      };
      return new_bulk_update;

    case inputsConstants.RESET_INPUTS_TO_DEFAULT:
      return { ...JSON.parse(JSON.stringify(inputsInitialState)) };

    case inputsConstants.CLEAR_TOPO_DATA:
      // by default we just clear all / reset to default topo
      let cleared_topo_data = {
        ...state,
        ...default_topo(),
      };
      if (action.which_data == "layers") {
        // if only clearing layers, hook up some older values
        cleared_topo_data.topo_id = state.topo_id;
        cleared_topo_data.graded_avail = state.graded_avail;
        cleared_topo_data.grade_target = state.grade_target;
        cleared_topo_data.grade_cut_amt = state.grade_cut_amt;
        cleared_topo_data.grade_fill_amt = state.grade_fill_amt;
        cleared_topo_data.topo_layout_surface = state.topo_layout_surface;
        cleared_topo_data.topo_layout_action = state.topo_layout_action;
      }
      return cleared_topo_data;

    // INPUT TYPE JOB HANDLERS
    case inputsConstants.TOPO_JOB_COMPLETE:
      // lets keep the job complete simple to just updating the state
      // to whatever the complete results are
      let topo_comp_uiState;
      let topo_comp_state;
      if (action.error) {
        // something failed
        topo_comp_uiState = {
          ...state.uiState,
          topo_loading: false,
          topo_error: "",
        };
      } else {
        // topo job complete
        topo_comp_uiState = {
          ...state.uiState,
          ...action.results.uiState,
          topo_loading: false,
          topo_error: "",
        };
        topo_comp_state = {
          ...state,
          ...action.results,
        };
      }

      return {
        ...topo_comp_state,
        uiState: topo_comp_uiState,
      };

    case inputsConstants.TOGGLE_SF_MODAL:
      let new_sf_state = {
        ...state.uiState,
        [action.key]: action.value,
        download_type: action.download_type,
      };

      return { ...state, uiState: new_sf_state };

    case inputsConstants.SF_CAPTURE_REQUEST:
      let sf_request_ui_state = {
        ...state.uiState,
        loading: true,
      };

      return { ...state, uiState: sf_request_ui_state };

    case inputsConstants.SF_CAPTURE_SUCCESS:
      let sf_success_state = {
        ...state,
        download_lock: false,
        uiState: {
          ...state.uiState,
          loading: false,
          show_sf_modal: false,
        },
      };

      return sf_success_state;

    case inputsConstants.SF_CAPTURE_RESET:
      let sf_reset_state = {
        ...state,
        download_lock: true,
        uiState: {
          ...state.uiState,
          loading: false,
          show_sf_modal: false,
        },
      };

      return sf_reset_state;

    default:
      return { ...state };
  }
}

export function collectInputs(state, inc_features = true) {
  return {
    features: inc_features ? Object.values(state.inputs.features) : undefined,

    center: [state.inputs._map_center.lat, state.inputs._map_center.lng],

    tab: state.uiControls.tab,
    // below moved to layout tab
    // azimuth: state.inputs.azimuth,
    // doRoads: state.inputs.doRoads,
    // doAlign: state.inputs.doAlign,

    selectedProductIndex: state.inputs.selectedProductIndex,
    racking: state.inputs.selectedProduct,

    // selectedModuleIndex: state.inputs.selectedModuleIndex,
    selectedModuleIndex: undefined,
    module: state.inputs.selectedModule,

    doOverrideTilt: state.inputs.doOverrideTilt,
    OverrideTilt: [state.inputs.OverrideTilt[0], state.inputs.OverrideTilt[1]],
    doOverrideGCR: state.inputs.doOverrideGCR,
    OverrideGCR: [state.inputs.OverrideGCR[0], state.inputs.OverrideGCR[1]],
    doEWShifting: true,
    doOverrideDims: state.inputs.doOverrideDims,
    // overrideDims: [state.inputs.current_product.filter(product => product.active)],
    product: state.inputs.current_product,
    do_continuous: state.inputs.do_continuous,

    do_dc_lock: state.inputs.do_dc_lock,
    dc_lock_value: isNaN(state.inputs.dc_lock_value) == true ? 0 : state.inputs.dc_lock_value,

    mod_width: state.inputs.selectedModule.mlm_Width,
    mod_height: state.inputs.selectedModule.mlm_Length,
    mod_string: state.inputs.mod_string,
    table_count: state.inputs.table_count,

    doTopo: state.topoData.topo_live && (state.inputs.topo_action == "delete" || state.inputs.topo_action == "flag"),
    doGrade: state.topoData.grading.enabled && state.topoData.grading.generated,
    topo_id: state.topoData.topo_id,
    // grade_limit: state.inputs.selectedProductIndex == 0 ? 10 : 20,

    grade_limit: state.inputs.grade_limit,
    dcac: state.inputs.dcac,

    ns_grade_limit: state.inputs.ns_grade_limit,
    ew_grade_limit: state.inputs.ew_grade_limit,
    u_grade_limit: state.inputs.u_grade_limit,
    do_generate_layers: state.topoData.do_generate_layers,
    generate_ns: state.topoData.layers_generated.NS.avail,
    generate_ew: state.topoData.layers_generated.EW.avail,
    generate_u: state.topoData.layers_generated.U.avail,
    layers_generated: state.topoData.layers_generated,
    topo_action: state.inputs.topo_action,

    is_bifacial: state.inputs.is_bifacial,
    bifaciality: state.inputs.bifaciality,
    bifacial_transmission_factor: state.inputs.bifacial_transmission_factor,
    bifacial_ground_clearance_height: state.inputs.bifacial_ground_clearance_height,
    bifacial_structure_shade_factor: state.inputs.bifacial_structure_shade_factor,

    losses: {
      soiling_loss: state.inputs.losses.soiling_loss,
      mismatch_loss: state.inputs.losses.mismatch_loss,
      wiring_loss: state.inputs.losses.wiring_loss,
      module_lid_loss: state.inputs.losses.module_lid_loss,
      module_quality_loss: state.inputs.losses.module_quality_loss,
      inverter_eff: state.inputs.losses.inverter_eff,
      combined_ac_loss: state.inputs.losses.combined_ac_loss,
    },

    layout: { ...state.inputs.layout },
  };
}
