import config from 'config';
import { authHeader } from '../helpers';

export const authServices = {
  login,
  logout,
  refreshToken,
  updatePassword
};

async function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password })
  };

  // temporary
  let response = await obtainToken(email, password);

  if (response.access) {
    localStorage.setItem('access', response.access);
    localStorage.setItem('refresh', response.refresh);
    localStorage.setItem('email', email);
    localStorage.setItem('admin', response.admin);
  } else {
    this.setState({ isLoading: false });
  }

  return fetch(`${config.apiUrl}/platform/rbi/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a jwt access token in the response
      if (user.access) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('user', JSON.stringify(user));
      }
      return user;
    });
}

function obtainToken(email, password) {
  let requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password })
  };

  return fetch(`${config.apiUrl}/platform/rbi/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a jwt access token in the response
      if (user.access) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // console.log(user.refresh)
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('access', JSON.stringify(user.access));
        localStorage.setItem('refresh', JSON.stringify(user.refresh));
      }

      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  return true;
}

function refreshToken(refresh) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ refresh })
  };

  return fetch(`${config.apiUrl}/api/auth/token/refresh/`, requestOptions)
    .then(handleResponse)
    .then(access => {
      // login successful if there's a jwt access token in the response
      if (access) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('user', JSON.stringify(user));
      }

      return access;
    });
}


function updatePassword(email,old_password,new_password) {
  let url = `${config.apiUrl}/platform/rbi/update/`
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: email,
      old_password: old_password,
      new_password: new_password
    })
  }
  return fetch(url, requestOptions);
}

function handleResponse(response) {
  return response.text().then(text => {
    let data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
