import React from 'react';
import ContentWrapper from '../../layouts/ContentWrapper';
import { Redirect } from 'react-router-dom';


class NewSimulation extends React.Component {
  render() {
    return (
        <ContentWrapper>
            {/* <h3>Loading...</h3> */}
            <div>
                <Redirect to="/" />
            </div>
        </ContentWrapper>
    
    );
  }
}

export default NewSimulation;
