import React from "react";

import {
  intro_1,
  intro_2,
  intro_3,
  intro_4,
  intro_5,
  intro_6,
  bug_report_1,
  map_i_1,
  map_ii_1,
  map_ii_2,
  map_ii_3,
  map_ii_4,
  map_iii_1,
  map_iii_2,
  ground_mount_icon,
  help_icon,
  canopy_icon,
  bug_report_icon,
  powered_by_icon,
  user_icon,
  logout_icon,
  county_example,
  context_menu,
  delete_icon,
  draw_icon,
  export_icon,
  layers_icon,
  load_button,
  new_button,
  save_button,
  search_icon,
  summary_legend,
  zoom_extents_icon,
  zoom_in_icon,
  zoom_out_icon,
  bifacial_parameters_example,
  fixed_tilt_example,
  fixed_tilt_or_table,
  or_visual_example,
  perf_accordian,
  product_accordian,
  select_module_dropdown,
  select_product_dropdown,
  single_axis_example,
  single_axis_or_table,
  clear_all_button,
  import_elevation_button,
  topo_accordian,
  cancel_button,
  copy_button,
  download_button,
  generate_button,
  hide_results_button,
  view_results_button,
  plot_button,
  sort_button,
  plot_dims_example1,
  plot_dims_example2,
  import_kmz_1,
  export_map_1,
  plot_map_1,
  plot_map_2,
  rack_spec_1,
  rack_spec_2,
  product_i_1,
  product_i_3,
  product_ii_1,
  product_ii_2,
  topo_i_1,
  topo_i_2,
  topo_ii_1,
  topo_ii_2,
  bifacial_1,
  azimuth_1,
  rack_align_1,
  rack_align_2,
  define_location,
  select_module,
  place_canopies,
  adjust_canopies,
  generate_results_button,
  analyze_download_one,
  analyze_download_two,
  allignment_tool,
  bug_nav_button,
  bug_report_modal,
  canopy_accordian,
  canopy_input_accordian,
  canopy_nav_button,
  copy_delete_accordian_tool,
  delete_tool,
  duplicate_tool,
  ground_nav_button,
  help_nav_button,
  image_import_tool,
  import_image_accordian,
  layers_tool,
  logout_nav_button,
  map_county_box,
  module_accordian,
  new_proj_button,
  non_rectangular_tool,
  override_accordian,
  place_canopy_tool,
  search_tool,
  show_names_tool,
  zoom_extents_tool,
  zoom_in_tool,
  zoom_out_tool,
  layers_menu,
  portal_how_it_works_one,
  portal_how_it_works_two,
  portal_how_it_works_three,
  portal_how_it_works_four,
  portal_how_it_works_five,
  topo_controls_one,
  basic_inputs_one,
  basic_inputs_two,
  basic_inputs_three,
  racking_tab_one,
  racking_tab_two,
  racking_tab_three,
  racking_tab_four,
  racking_tab_five,
  topo_tab_one,
  topo_tab_two,
  topo_tab_three,
  topo_tab_four,
  performance_tab_one,
  portal_results_table_one,
  portal_results_table_two,
  portal_report_one,
  plot_dims_one,
  plot_dims_two,
} from "../assets";

const center_image = { display: "block", margin: "0 auto", maxWidth: 400 };
const center_div = { display: "block", margin: "0 auto", textAlign: "center" };
const left_align_image = { display: "block", margin: "5px 0px", maxWidth: 400 };
const roman_type = { listStyleType: "lower-roman" };
const square_type = { listStyleType: "square", marginLeft: "-2em" };

import "./layout.css";
// {imgoogearth} style={center_image} />

// ================================================================>
// ========= START RBI    =========================================>
// ================================================================>
const Root_Tab = (props) => {
  return (
    // prettier-ignore
    <div className="help">
			<div className='drawer-content-help'>
      <p>Welcome to the Ground Mount Portal Help Menu.</p>
				<a href="javascript:void(0);" onClick={() => props.onOpenDrawer('sunfig_support')} ><b><u>Sunfig Support</u></b></a>
				<br/>
			</div>
      <br/>
			<div>
					{/* General */}
				<h4>General</h4>
				<ul>
          <li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('how_portal_works')}>How it Works</a></u></li>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('portal_side_bar')}>Sidebar Features</a></u></li>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('portal_project_management')}>Project Managment</a></u></li>
					{/* <li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('rbi_intro')} >Ground Mount Portal Overview</a></u></li>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('ground_mount_features')} >Ground Mount Features</a></u></li>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('bug_report')} >Bug Report</a></u></li> */}
				</ul>

					{/* Map */}
				<h4>Map</h4>
				<ul>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('map_overview')}>Overview</a></u></li>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('import_kmz')}>KMZ Import</a></u></li>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('map_controls')}>Controls</a></u></li>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('map_hotkeys')}>Hotkeys</a></u></li>
					{/* <li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('map_layers')}>Layers</a></u></li> */}
					{/* <li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('export_map')} >Export to KMZ or DXF</a></u></li> */}
					{/* <li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('plot_map')} >Dimensions in Plot (GCR & Pitch Definitions)</a></u></li> */}
				</ul>

        {/* simplified tab */}
        <h4>Simplified Tab</h4>
          <ul>
			
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('basic_inputs')}>Basic Inputs</a></u></li>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('portal_module_tab')}>Module Tab</a></u></li>
				</ul>
        {/* Advanced tab */}
        <h4>Advanced Tab</h4>
          <ul>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('portal_module_tab')}>Module Tab</a></u></li>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('racking_tab')}>Racking Tab</a></u></li>
          <li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('performance_tab')} >Performance Tab</a></u></li>
			  	<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('topography_tab')} >Topography Tab</a></u></li>
				</ul>

        {/* Generating Results */}
        <h4>Generating Results</h4>
          <ul>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('generating_ground_mount_results')}>Overview</a></u></li>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('portal_results_table')}>Results Table</a></u></li>
          <li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('portal_report')} >Report</a></u></li>
			    <li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('plot_dims')} >Plot Dimensions</a></u></li>
				</ul>

         {/* Additional Information */}
         <h4>Additional Information</h4>
          <ul>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('weather_source')} >Weather Source</a></u></li>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('bifacial')} >Bifacial Parameters</a></u></li>
				</ul>



				{/* <h4>Ground Mount Input Panel</h4>
				<ul>
				<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('select_product_module')} >Selecting Product and Module</a></u></li>
				<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('product_tab')} >Product Tab</a></u></li>
				<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('performance_tab')} >Performance Tab</a></u></li>
				<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('topography_tab')} >Topography Tab</a></u></li>
				</ul>

				<h4>Generating Results</h4>
				<ul>
				<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('generating_results_overview')} >Generating Results Overview</a></u></li>
				<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('result_explanations')} >Result Explanations</a></u></li>
				</ul>

				<h4>Additional Ground Mount Information</h4>
			<ul>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('rack_specs')} >Product Dimensions</a></u></li>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('module_target')} >Module Target</a></u></li>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('import_topo')} >Import USGS Topography</a></u></li>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('slope_analysis')} >Slope Analysis Layers</a></u></li>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('topo_actions')} >Topography Actions</a></u></li>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('rack_align')} >Racking Align</a></u></li>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('ew_roads')} >East-West Roads</a></u></li>   
			</ul>
				<h4>Product</h4>
				<ul>      
				</ul>
				<h4>Performance</h4>
				<ul>        
					Performance
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('bifacial')} >Bifacial Parameters</a></u></li>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('weather_source')} >Weather Source</a></u></li>
					<li><u><a href="javascript:void(0);" onClick={() => props.onOpenDrawer('azimuth')} >Azimuth</a></u></li>
				</ul>
				<h4>Topography</h4>
				<ul>            
				</ul>
		 */}
			</div>
    </div>
  );
};

const Sunfig_Support = () => {
  return (
    <div>
      <b>IF:</b>
      <ul>
        <li>The project is greater than 5MW</li>
        <li>Or this is a new customer</li>
        <li>Or the customer requests an overview of Portal</li>
      </ul>
       <b>AND:</b>
      <ul>
        <li>
          <b>IF</b> the Customer:
        </li>
        <ul>
          <li>Isn't sure what product to use and wants help determining what is "best".</li>
          <ul>
            <li>
              <i>Action: Invite Sunfig to a call with customer.</i>
            </li>
          </ul>
          <li>Is asking about optimization, finance, performance 8760s, etc.</li>
          <ul>
            <li>
              <i>Action: Contact Sunfig</i>
            </li>
          </ul>
        </ul>
         
        <li>
          <b>OR</b> Inside Sales:
        </li>
        <ul>
          <li>Wants to have a collaborative meeting with Inside Sales, Customer, and Sunfig.</li>
          <ul>
            <li>
              <i>Action: Invite Sunfig to a call with customer.</i>
            </li>
          </ul>
          <li>Needs help with advanced functionality (i.e. non-standard products, dimension calcliator).</li>
          <ul>
            <li>
              <i>Action: Contact Sunfig</i>
            </li>
          </ul>
        </ul>
         
      </ul>
      <b>Before using Portal</b> <b>Obtain this information:</b> {" "}
      <ul>
        <li>KMZ file with a boundary</li>
        <li>Module Rating, Module Data Sheet, or .PAN file</li>
        <li>String size (modules per string)</li>
      </ul>
       <b>Good questions to ask:</b> {" "}
      <ul>
        <li>What are the customer's goals?</li>
        <ul>
          <li>Capacity target?</li>
          <li>Module quantity target?</li>
          <li>Generation target?</li>
        </ul>
        <li>What product(s) is the customer interested in?</li>
        <li>Is the customer concerned about topography? </li>
      </ul>
    </div>
  );
};

const How_Portal_Works = () => {
  return (
    <div>
      <p>
        Portal powered by Sunfig is built for internal users to quickly evaluate project sites. Users can import or draw project boundaries and model any standard RBI Solar or Terrasmart racking
        product.
      </p>
      <div>
        <b>Step 1: Define Project Boundaries</b>
        <img src={portal_how_it_works_one} style={left_align_image} />
        <br />
        <b>Step 2: Select Racking Product</b>
        <img src={portal_how_it_works_two} style={left_align_image} />
        <br />
        <b>Step 3: Input Module Rating</b>
        <img src={portal_how_it_works_three} style={left_align_image} />
        <br />
        <b>Step 4: Input Modules per String</b>
        <img src={portal_how_it_works_four} style={left_align_image} />
        <br />
        <b>Step 5: Generate Results</b>
        <img src={generate_results_button} style={left_align_image} />
        <br />
        <p>Once the project is completely defined, click the Generate button. Layouts are generated for all GCRs and Tilts based on the default range for the selected racking product.</p>
        <b>Step 6: Choose a Layout</b>
        <img src={portal_how_it_works_five} style={left_align_image} />
        <br />
        <p>The results can be sorted, copied, plotted, and downloaded as needed.</p>
      </div>
    </div>
  );
};

const Portal_Project_Management = () => {
  return (
    <div>
      <b>New</b>
      <br />
      <img src={new_proj_button} style={left_align_image} />
      <p>Create a new ground mount simulation</p>
      <br />
      <b>Save/Save As</b>
      <img src={save_button} style={left_align_image} />
      <p>Save a new project or additions to an existing project. All polygons and information within the Simplified and Advanced tabs will be saved.</p>
      <br />
      <b>Load</b>
      <img src={load_button} style={left_align_image} />
      <p>View, load, delete or share any previously created projects.</p>
    </div>
  );
};

const Portal_Sidebar = () => {
  return (
    <div>
      <p>Hover the cursor over any icon within Portal to get a quick description of its functionality.</p>
      <br />
      <b>Ground Mount</b>
      <img src={ground_nav_button} style={left_align_image} />
      <p>Used to create ground mount simulations. It is the default page upon login.</p>
      <br />
      <b>Canopy</b>
      <img src={canopy_nav_button} style={left_align_image} />
      <p>Used to create canopy simulations.</p>
      <br />
      <b>Support Documentation</b>
      <img src={help_nav_button} style={left_align_image} />
      <p>Provides an explanation of all the functions within Portal.</p>
      <br />
      <b>Bug Report</b>
      <img src={bug_nav_button} style={left_align_image} />
      <p>Report any bugs or errors encountered while using Portal. It provides the Sunfig team with an exact replica of the existing project accompanied by a user description of the bug.</p>
      <img src={bug_report_modal} style={left_align_image} />
      <br />
      <b>Logout</b>
      <img src={logout_nav_button} style={left_align_image} />
      <p>Logs the user out of Portal and redirects them to the login page.</p>
    </div>
  );
};

const RBI_Intro = () => {
  return (
    <div>
      <p>
        The Ground Mount Portal powered by Sunfig is built for internal RBI Solar users to quickly evaluate project sites. Users can import or draw project boundaries and model any RBI racking
        product.
      </p>
      <b>
        <u>Step 1: Define Project Boundary</u>
      </b>
      <img src={intro_1} alt="" style={left_align_image} />
      <br />
      <b>
        <u>Step 2: Select an RBI Racking Product</u>
      </b>
      <img src={intro_2} alt="" style={left_align_image} />
      <br />
      <b>
        <u>Step 3: Select a Module</u>
      </b>
      <img src={intro_3} alt="" style={left_align_image} />
      <br />
      <b>
        <u>Step 4: Customize Product, Performance and Topography Parameters</u>{" "}
      </b>
      <img src={intro_4} alt="" style={left_align_image} />
      <br />
      <p>The Product tab allows the user to adjust various layout and racking parameters.</p>
      <p>The Performance tab allows the user to adjust various loss scheme parameters.</p>
      <p>The Topography tab allows the user to import and overlay elevation and grade layers onto the project boundary.</p>
      <p>These tabs can be manually adjusted to match the specific project requirements as needed.</p>
      {/* <p>The RBI Portal allows you to fully customize your product, layout options, performance parameters, and import topography. See the relevant help sections for more information.</p> */}
      <b>
        <u>Step 5: Generate Results</u>
      </b>
      <img src={intro_5} alt="" style={left_align_image} />
      <br />
      <p>
        Once the project is completely defined, click the Generate button. Weather information is automatically pulled from NSRDB and layouts are generated for all GCRs and Tilts within user defined
        ranges
      </p>
      <b>
        <u>Step 6: Choose a Layout</u>
      </b>
      <img src={intro_6} alt="" style={left_align_image} />
      <br />
      <p>The generated results can be sorted, plotted, copied, and downloaded as needed.</p>
      {/* <p>Once the project is defined. Click Generate Results. Weather information is automatically pulled from NSRDB. Layouts are generated for all GCRs and Tilts within the user defined ranges.</p> */}
    </div>
    // <div>
    //   <p>The RBI Portal is for internal RBI teams to quickly evaluate project sites. Users can import or draw project boundaries and quickly model the capacity and performance for any RBI racking product.</p>
    //   <b><u>Step 1: Define Project Boundary</u></b>
    //   <img src={intro_1} alt="" style={center_image}/>
    //   <br />
    //   <b><u>Step 2: Select an RBI Racking Product</u></b>
    //   <img src={intro_2} alt="" style={center_image}/>
    //   <br />
    //   <b><u>Step 3: Select a Module</u></b>
    //   <img src={intro_3} alt="" style={center_image}/>
    //   <br />
    //   <b><u>Step 4: Customize Product and Performance Parameters</u> </b>
    //   <img src={intro_4} alt="" style={center_image}/>
    //   <br />
    //   <p>The RBI Portal allows you to fully customize your product, layout options, performance parameters, and import topography. See the relevant help sections for more information.</p>
    //   <b><u>Step 5: Generate Results</u></b>
    //   <img src={intro_5} alt="" style={center_image}/>
    //   <br />
    //   <p>Once the project is defined. Click Generate Results. Weather information is automatically pulled from NSRDB. Layouts are generated for all GCRs and Tilts within the user defined ranges.</p>
    // </div>
  );
};

const Ground_Mount_Features = () => {
  return (
    <div>
      <b>Hover Descriptions</b>
      <br />
      <p>Hover the cursor over any icon within the portal to get a quick description of its functionality.</p>
      <br />
      <b>Ground Mount Simulation</b>
      <img src={ground_mount_icon} style={left_align_image} />
      <p>The Ground Mount tab is used to create ground mount solar simulations. It is the default page upon login.</p>
      <br />
      <b>Canopy Simulation</b>
      <img src={canopy_icon} style={left_align_image} />
      <p>The Canopy tab is used to create canopy solar simulations.</p>
      <br />
      <b>User Management</b>
      <img src={user_icon} style={left_align_image} />
      <p>
        The Users tab is used to manage all portal users and is only visible to users with administrative privileges. This tab allows the user to create or delete other users, reset passwords or
        assign administrative privileges
      </p>
      <br />
      <b>Help</b>
      <img src={help_icon} style={left_align_image} />
      <p>This icon provides an explanation of all the functions within the RBI Portal.</p>
      <br />
      <b>Bug Report</b>
      <img src={bug_report_icon} style={left_align_image} />
      <p>
        This icon allows the user to report any bugs encountered while using the portal. It provides the Sunfig team with an exact replica of the current portal conditions accompanied by a user
        description of the bug.
      </p>
      <br />
      <b>Logout</b>
      <img src={logout_icon} style={left_align_image} />
      <p>This icon logs the user out of the portal and redirects them to the login page.</p>
      <br />
      <b>Sunfig Website</b>
      <img src={powered_by_icon} style={left_align_image} />
      <p>This link redirects the user to the official Sunfig website.</p>
      <br />
    </div>
  );
};

const Bug_Report = () => {
  return (
    <div>
      <p>If you run in to any bugs, please use the bug report button located at the top right of the RBI Portal.</p>
      <img src={bug_report_icon} alt="" />
      <br />
      <br />
      <p>
        The Sunfig development team will get a snapshot of your project along with your description of the error. This is the best way to report issues, as it provides and exact copy of your issue to
        the team.
      </p>
    </div>
  );
};
const Map_Overview = () => {
  return (
    <div>
      <p>
        The default map view is the RBI Solar location on Steger Drive in Cincinnati, OH. At least one defined project boundary is required to generate results, however there is no limit to the number
        of project boundaries that can be analyzed.
      </p>
      <br />
      <b>
        <u>Polygon Definitions</u>
      </b>
      <p>
        <b>Boundary</b> – Represents the buildable area within an array. Boundary polygons are red.
      </p>
      <p>
        <b>Exclusion</b> – Represents the non-buildable area within an array. Examples include lakes, buildings, trees, etc. Exclusion polygons are yellow..
      </p>
      <p>
        <b>Inactive</b> – No action will be taken on this polygon. Inactive polygons are white.
      </p>
      <br />
      <b>
        <u>Project Boundary Creation</u>
      </b>
      <br />
      <p>
        A project boundary can be created by manually drawing a polygon onto the map or by dragging and dropping a polygon onto the map using a KMZ/KML file. Drawn polygons will automatically be
        labeled as a boundary in red while imported polygons will automatically be labeled as inactive in white. Note that an error message will appear if an imported KMZ/KML file only contains a pin
        or a set of undefined paths. Once a project boundary is created, the project county will be displayed on the map for reference.
      </p>
      <img src={map_county_box} style={left_align_image} />
      <br />
      <p>*The terms KMZ and KML are often used interchangeably</p>
    </div>
    // <div>
    //   <p>
    //     RBI models require at least one boundary. To create a boundary, use the draw tool to create a polygon. You may also drag and drop and KMZ and KML file directly into the map. Use the
    //     right-click menu, hotkeys, or action links to assign the polygon as a boundary.
    //   </p>

    //   <b>Definitions:</b>
    //   <p>
    //     <i>Boundary</i> – RBI will use this as the buildable area. Total allowed boundary area is limited based on user account. Boundaries are red.
    //   </p>
    //   <p>
    //     <i>Exclusion</i> – RBI will not place PV in this area. Exclusions may be placed within boundaries. Exclusions are yellow.
    //   </p>
    //   <p>
    //     <i>Inactive</i> – RBI will take no action against this polygon. Inactive polygons are white.
    //   </p>

    //   <p>If importing a KMZ or KML file, all polygons will be loaded into RBI and set as inactive. Delete and assign these polygons as necessary.</p>

    //   <b>Hotkeys:</b>
    //   <ul>
    //     <li>Right click – open action menu</li>
    //     <li>Left click and drag – map pan</li>
    //     <li>Scroll wheel – zoom in/out</li>
    //     <li>+/- keys – zoom in/out </li>
    //     <li>Middle mouse double click – zoom extents </li>
    //     <li>D – draw</li>
    //   </ul>

    //   <b>If a polygon is selected</b>
    //   <ul>
    //     <li>B – set as boundary</li>
    //     <li>E – set as exclusion</li>
    //     <li>I – set as inactive</li>
    //     <li>N – cycle through polygons</li>
    //     <li>ESC – deselect all</li>
    //     <li>ctrl+DEL – delete polygon</li>
    //   </ul>

    //   <b>Trouble Selecting a Polygon?</b>
    //   <p>
    //     You can cycle through all polygons with N. You can also right click over stacked polygons and a menu will appear. The menu includes the option to select any polygon under your mouse cursor.
    //   </p>
    //   <img src={map_i_1} alt="" style={center_image} />
    //   <p>Right click menu to select nested polygons.</p>
    // </div>
  );
};

const Map_Controls = () => {
  return (
    <div>
      <b>
        <u>Search</u>
      </b>
      <img src={search_icon} style={left_align_image} />
      <p>Search for any location by city, state, zip code, etc.</p>
      <br />
      <b>
        <u>Draw (D)</u>
      </b>
      <img src={draw_icon} style={left_align_image} />
      <p>Manually draw a polygon.</p>
      <br />
      <b>
        <u>Delete All</u>
      </b>
      <img src={delete_icon} style={left_align_image} />
      <p>Delete all existing polygons. The user will be prompted to confirm their choice</p>
      <br />
      <b>
        <u>Export</u>
      </b>
      <img src={export_icon} style={left_align_image} />
      <p>Export the existing array to a KMZ file or to an image. There must be at least one polygon to export a KMZ.</p>
      <br />
      <b>
        <u>Zoom In</u>
      </b>
      <img src={zoom_in_icon} style={left_align_image} />
      <p>Zoom in at small intervals.</p>
      <br />
      <b>
        <u>Zoom Out</u>
      </b>
      <img src={zoom_out_icon} style={left_align_image} />
      <p>Zoom out at small intervals.</p>
      <br />
      <b>
        <u>Zoom Extents</u>
      </b>
      <img src={zoom_extents_icon} style={left_align_image} />
      <p>Zoom to the extents of all existing polygons on the map.</p>
      <br />
      <b>
        <u>Layer Select</u>
      </b>
      <img src={layers_icon} style={left_align_image} />
      <p>Adjust the map layer.</p>
      <img src={layers_menu} style={left_align_image} />
      <br />
      <b>
        <u>Right Click Menu</u>
      </b>
      <p>Right click anywhere on the map to view additional map features.</p>
      <img src={context_menu} style={left_align_image} />
      <ul>
        <li>Draw</li>
        <ul>
          <li>See Draw (D) icon description.</li>
        </ul>
        <li>Topo Mode</li>
        <ul>
          <li>Elevation Ungraded</li>
          <li>NS Ungraded</li>
          <li>EW Ungraded</li>
          <li>Max Ungraded</li>
          <li>Elevation Graded</li>
          <li>NS Graded</li>
          <li>EW Graded</li>
          <li>Max Graded</li>
          <li>Cut/Fill</li>
          <li>Off</li>
        </ul>
        <li>Toggle Dimensions On/Off</li>
        <ul>
          <li>o Displays the dimensions on each side of the selected polygon.</li>
        </ul>
        <li>Toggle Azimuth On/Off</li>
        <ul>
          <li>Displays the azimuth on each side of the selected polygon.</li>
        </ul>
        <li>Show/Hide</li>
        <ul>
          <li>Boundary</li>
          <li>Exclusions</li>
          <li>Inactive</li>
          <li>Layout</li>
        </ul>
        <li>Export KMZ</li>
        <ul>
          <li>Exports a .png image of the current map view.</li>
        </ul>
      </ul>
      <br />
      <b>Result Legend</b>
      <img src={summary_legend} style={left_align_image} />
      <p>Displayed on the map when results are generated, and an array is plotted. The displayed information can be customized by right clicking on the legend.</p>
      <br />
      <b>Topography Controls</b>
      <p>Choose which visual layer to be displayed on the map. It is only available after elevation data has been imported within the Topography tab.</p>
      <img src={topo_controls_one} style={left_align_image} />
      <br />
      <b>Sunfig Website</b>
      <img src={powered_by_icon} style={left_align_image} />
      <p>Redirects the user to the official Sunfig website.</p>
    </div>
  );
};

const Map_Hotkeys = () => {
  return (
    <div>
      <b>Mouse Hotkeys</b>
      <ul>
        <li>Pan Map View</li>
        <ul>
          <li>Click & Drag</li>
        </ul>
        <li>Delete Existing Point</li>
        <ul>
          <li>Click Point</li>
        </ul>
        <li>Zoom In(Small Intervals)</li>
        <ul>
          <li>Double-click</li>
        </ul>
        <li>Zoom In/Out (Large Intervals)</li>
        <ul>
          <li>Scroll Wheel</li>
        </ul>
        <li>Zoom Extents</li>
        <ul>
          <li>Click Scroll Wheel</li>
        </ul>
        <li>Map Control Menu</li>
        <ul>
          <li>Right-click</li>
        </ul>
      </ul>
      <br />
      <b>Keyboard Hotkeys</b>
      <ul>
        <li>D – Draw</li>
        <li>CTRL+ Delete – Delete Selected Polygon</li>
        <li>CTRL+Z – Delete Last Drawn Point</li>
        <li>ESC – Delete Unfinished Polygon</li>
        <li>B – Set as Boundary</li>
        <li>E – Set as Exclusion</li>
        <li>I – Set as Inactive</li>
        <li>N – Cycle through Existing Polygons</li>
        <li>ESC – Deselect the Polygon</li>
        <li>Arrows – Pan Map View</li>
      </ul>
    </div>
  );
};

// Simplified Tab
const Basic_Inputs = () => {
  return (
    <div>
      <b>Product</b>
      <img src={basic_inputs_one} alt="" style={left_align_image} />
      <p>Select which type of racking structure to analyze.</p>
      <br />
      <b>Module Rating</b>
      <img src={basic_inputs_two} alt="" style={left_align_image} />
      <p>Input a module rating. Generic module dimensions will be assumed for the racking product.</p>
      <br />
      <b>Mod/String</b>
      <img src={basic_inputs_three} alt="" style={left_align_image} />
      <p>Input the number of modules per string.</p>
    </div>
  );
};

const Portal_Module_Tab = () => {
  return (
    <div>
      <img src={module_accordian} alt="" style={left_align_image} />
      <p>
        Can be found in both the Simplified and Advanced tabs, and it is the preferred way to define the module input. Select from a list of generic modules or import a customer PAN file for maximum
        design and performance accuracy.
      </p>
    </div>
  );
};

const Racking_Tab = () => {
  return (
    <div>
      <img src={racking_tab_one} alt="" style={left_align_image} />
      <p>Customize several racking product parameters.</p>
      <p>*The Display Continuous toggle is not an option for Terrasmart racking products and the Tilt Angle Override toggle is not an option for the TerraTrak single-axis tracker.</p>
      <br />
      <b>Azimuth</b>
      <p>Orientation of the array within the boundary</p>
      <ul>
        <li>North – 0°</li>
        <li>East – 90°</li>
        <li>South – 180° (Default: GFT & SAT)</li>
        <li>West – 270°</li>
      </ul>
      <br />
      <b>Module Target</b>
      <p>Total module limit within a boundary</p>
      <br />
      <b>Grade Limit</b>
      <p>Grade limit of racking constructability. Each product has a default grade limit defined by RBI Solar & Terrasmart.</p>
      <br />
      <b>Road Spacing</b>
      <p>Adds roads to the layout</p>
      <ul>
        <li>The purpose is to consume an accurate amount of space within the boundary.</li>
        <li>Placement determined by racking product, typical inverter size and typical road spacing.</li>
        <li>Only East-West roads are supported.</li>
      </ul>
      <br />
      <b>Racking Align</b>
      <p>Determines the alignment of the array within the boundary.</p>
      <ul>
        <li>On (Default) – typical for large sites</li>
        <li>Off – typical for small sites</li>
      </ul>
      <br />
      <b>Ground Fixed Tilt</b>
      <img src={racking_tab_two} alt="" style={left_align_image} />
      <p>On (Pictured Left)</p>
      <ul>
        <li>Strings aligned horizontally and vertically</li>
        <li>Horizontal and vertical roadways allowed</li>
      </ul>
      <p>Off (Pictured Right)</p>
      <ul>
        <li>West boundary aligned to maximize site density</li>
        <li>Vertical roadways disabled</li>
      </ul>
      <br />
      <b>Single Axis Tracker</b>
      <img src={racking_tab_three} alt="" style={left_align_image} />
      <p>On (Pictured Left)</p>
      <ul>
        <li>Strings aligned horizontally and vertically</li>
        <li>Horizontal and vertical roadways allowed</li>
      </ul>
      <p>Off (Pictured Right)</p>
      <ul>
        <li>North boundary aligned to maximize site density</li>
        <li>Horizontal roadways disabled</li>
      </ul>
      <br />
      <b>GCR Override</b>
      <p>Specify a Minimum and Maximum tilt range</p>
      <ul>
        <li>Range: 0.3 – 0.7</li>
        <li>Set Min/Max equal for single GCR analysis</li>
      </ul>
      <br />
      <b>Display Continuous </b>
      <p>Determines how the racking product is plotted within the boundary.</p>
      <ul>
        <li>On – Continuous rows</li>
        <li>Off – Individual tables</li>
      </ul>
      <br />
      <b>Tilt Angle Override</b>
      <p>Specify a Minimum and Maximum tilt range</p>
      <ul>
        <li>Range: Depends on product type</li>
        <li>Set Min/Max equal for single tilt analysis</li>
      </ul>
      <br />
      <b>Dimension Override</b>
      <p>Portal layouts accept up to 3 racking sizes for all products except RBI Solar – Dahlia – 4HP, Terrasmart – GLIDE – 2HP and Terrasmart – GLIDE – 4HL</p>
      <img src={racking_tab_four} alt="" style={left_align_image} />
      <p> A, B & C are simple differentiators and the quantity of each are reported in the results table.</p>
      <b>Active</b>
      <p>If left unchecked, this racking size will not be plotted within the boundary.</p>
      <b>Inputs</b>
      <p>Dimension Calc</p>
      <ul>
        <li>Mod X – Module Width</li>
        <li>Mod Y – Module Length</li>
        <li>Mod/Str – String Size</li>
        <li>*Table – Number of Modules Wide</li>
      </ul>
      <p>Outputs</p>
      <ul>
        <li>X (m) – Width of racking product</li>
        <li>Y (m) – Height of racking product</li>
        <li>Modules – Module count in rack</li>
      </ul>
      <p>For Ground Fixed Tilt, Y is the face height or the distance from the bottom of the lowest panel to the top of the highest panel. </p>
      <img src={racking_tab_five} alt="" style={left_align_image} />
    </div>
  );
};

const Map_Layers = () => {
  return (
    <div>
      <p>With the Map Layers button and menu, you can change the map tiles, change what is displayed, and navigate through different topography modes.</p>
      <img src={layers_icon} alt="" />
      <br />
      <br />
      <b>Tile Sets</b>
      <p>Tile sets allow you to change the look of the map. There are multiple map styles and satellite data sets for you to choose from. </p>
      <img src={map_ii_2} alt="" style={center_image} />
      <br />
      <br />
      <b>Polygons</b>
      <p>The Polygons toggles allow you to turn off different components of the generated design. If you need a screenshot without the layout for example, turn the layout off.</p>
      <img src={map_ii_3} alt="" style={center_image} />
      <br />
      <br />
      <b>Topo Mode</b>
      <p>
        Topo Mode is only active if you have imported topography in the Topo tab. The default layer is set based on the product you have selected, but you’re welcome to view other layers through this
        menu.
      </p>
      <img src={map_ii_4} alt="" style={center_image} />
    </div>
  );
};
const Import_KMZ = () => {
  return (
    <div>
      <p>To import from a KMZ (or KML), drag and drop the file directly in to the map area.</p>
      <p>Portal will attempt to parse the polygons within the file. All polygons that are recognized will show up on the map.</p>
      <img src={map_iii_1} alt="" style={left_align_image} />
      <div style={left_align_image}>KMZ imported, all polygons as inactive</div>
      <br />
      <img src={map_iii_2} alt="" style={left_align_image} />
      <div style={left_align_image}>Polygons defined as boundaries or exclusions</div>
      <br />
      <br />
      <p>Select and define each polygon and add additional polygons with the Draw tool as needed.</p>
    </div>
  );
};
const Export_Map = () => {
  return (
    <div>
      <p>To export what is drawn the map, select the Map Export button on the left map toolbar.</p>
      <img src={export_map_1} alt="" />
      <br />
      <p>Select KMZ or DXF to download.</p>
    </div>
  );
};
const Plot_Map = () => {
  return (
    <div>
      <b>Plot Dimensions</b>
      <p>
        The illustration below defines the Pitch (B) and Intra-Row Spacing (IR) in the results table. The collector width (A) is the X dimension for single axis tracker and the Y dimension for fixed
        tilt structures.
      </p>
      <img src={plot_dims_example1} style={left_align_image} />
      <p>
        Fixed tilt structures are drawn as if the modules are flat on the ground and ϴ = 0°. Fixed tilt structures are not drawn as a projected aerial view. This gives an accurate A dimension in all
        plots, but an inaccurate IR dimension.
      </p>
      <br />
      <img src={plot_dims_example2} style={left_align_image} />
      <br />
      <b>Weather Source</b>
      <p>The RBI Solar Portal automatically imports weather data from the NSRDB dataset referenced below. This automatic import only works within the continental United States. </p>
      <br />
      <b>NSRDB</b>
      <p>
        NREL’s National Solar Radiation Database covers much of North and South America. Based on the latitude and longitude provided, the RBI Solar Portal automatically pulls the nearest NSRDB PSM v3
        TMY data set.
      </p>
    </div>
    // <div>
    //   <p>The illustration below defines the Pitch (B) and Intra-Row Spacing (IR) in the results. The collector width (A) is the X-dim for single axis and the Y-dim for ground fixed tilt.</p>
    //   <img src={plot_map_1} alt="" style={center_image} />
    //   <br />
    //   <p>
    //     Ground fixed tilt is not drawn as a projected aerial view. Ground fixed tilt products are drawn as if ϴ = 0°. This gives an accurate A dimension in all plots, but an inaccurate IR dimension.
    //     This is standard for ground fixed tilt layouts.
    //   </p>

    //   <br />
    // </div>
  );
};

const Select_Product_Module = () => {
  return (
    <div>
      <u>Select RBI Racking Product</u>
      <img src={select_product_dropdown} style={left_align_image} />
      <p>This drop-down menu allows the user to select which type of RBI structure to analyze. These structures include single axis tracker (SAT) and several fixed tilt (FT) options.</p>
      <br />
      <u>Select Module</u>
      <img src={select_module_dropdown} style={left_align_image} />
      <p>This drop-down menu allows the user to select which module type to analyze when generating results. The available module types were chosen to represent average module dimensions.</p>
      <br />
    </div>
  );
};

const Product_Tab = () => {
  return (
    <div>
      <b>Product Tab</b>
      <img src={product_accordian} style={left_align_image} />
      <p>This tab allows the user to customize several product parameters. Parameters labeled with * are not available for analysis on SAT structures.</p>
      <p>
        <u>Azimuth</u> - Orientation of the array within the boundary
      </p>
      <ul>
        <li>North – 0°</li>
        <li>East – 90°</li>
        <li>South – 180°</li>
        <li>West – 270°</li>
      </ul>
      <p>
        <u>Module Target</u> - Total module limit within a boundary
      </p>
      <p>
        <u>Grade Limit</u> - Limit of constructability within a boundary. Grade Limits are:
      </p>
      <ul>
        <li>Single Axis Tracker – 10%</li>
        <li>Fixed Tilt – 20%</li>
      </ul>
      <p>*Note that this value will automatically update the corresponding grade limit value in the Topo Tab</p>
      <p>
        <u>Road Spacing</u> - Adds roads to the layout
      </p>
      <ul>
        <li>The purpose is to consume an accurate amount of space within the boundary</li>
        <li>Placement determined by racking type, typical inverter size and typical road spacing</li>
        <li>Only East-West roads are supported</li>
      </ul>
      <br />
      <b>Racking Align</b>
      <p>Determines the alignment of the array within the boundary</p>
      <ul>
        <li>On – typical for large sites</li>
        <li>Off – typical for small sites</li>
      </ul>
      <u>Fixed Tilt</u>
      <img src={fixed_tilt_example} style={left_align_image} />
      <u>On</u>
      <ul>
        <li>Strings aligned horizontally and vertically</li>
        <li>Horizontal and vertical roadways allowed</li>
      </ul>
      <u>Off</u>
      <ul>
        <li>West boundary aligned to maximize site density</li>
        <li>Vertical roadways disabled</li>
      </ul>
      <br />
      <u>Single Axis Tracker</u>
      <img src={single_axis_example} style={left_align_image} />
      <u>On</u>
      <ul>
        <li>Strings aligned horizontally and vertically</li>
        <li>Horizontal and vertical roadways allowed</li>
      </ul>
      <u>Off</u>
      <ul>
        <li>North boundary aligned to maximize site density</li>
        <li>Horizontal roadways disabled</li>
      </ul>
      <p>
        <u>GCR Override</u> – Specify a Min/Max GCR
      </p>
      <ul>
        <li>GCR = Ground Cover Ratio</li>
        <li>Range: 0.3 – 0.7</li>
        <li>Set Min/Max equal for one GCR</li>
      </ul>
      <p>
        <u>*Display Continuous</u> – Determines how rows are drawn
      </p>
      <ul>
        <li>On – Continuous rows</li>
        <li>Off – Individual tables</li>
      </ul>
      <p>
        <u>*Tilt Angle Override</u> – Specify a Min/Max tilt
      </p>
      <ul>
        <li>Range: 10°-30°</li>
        <li>Set Min/Max equal for one tilt angle</li>
      </ul>
      <br />
      <b>Dimension Override</b>
      <p>The RBI Portal layout accepts up to 3 string sizes for fixed tilt and 4 string sizes for single axis tracker.</p>
      <u>Fixed Tilt</u>
      <img src={fixed_tilt_or_table} style={left_align_image} />
      <u>Single Axis Tracker</u>
      <img src={single_axis_or_table} style={left_align_image} />
      <p> A, B, C & D are simple differentiators and the quantity of each are reported in the results table.</p>
      <u>Active</u>
      <p>If left unchecked, this string size will not be drawn within the boundary.</p>
      <u>Inputs</u>
      <ul>
        <li>Mod X – Module Width</li>
        <li>Mod Y – Module Length</li>
        <li>Mod/Str – String Size</li>
        <li>*Table – Number of Modules Wide</li>
      </ul>
      <u>Outputs</u>
      <ul>
        <li>X Column – Width of GFT or SAT</li>
        <li>Y Column – Height of GFT or SAT</li>
        <li>Modules Column – Modules in string size</li>
      </ul>
      <p>For FT, Y is the face height or the distance from the bottom of the lowest panel to the top of the highest panel. For SAT, the opposite is true.</p>
      <img src={or_visual_example} style={left_align_image} />
    </div>
  );
};
const Performance_Tab = () => {
  return (
    <div>
      <img src={performance_tab_one} style={left_align_image} />
      <p>Adjust the performance parameters related to output losses within a solar array. Portal users should reach out to the Sunfig team prior to adjusting these values.</p>

      {/* <b>Performance Tab</b>
      <img src={perf_accordian} style={left_align_image} />
      <p>This tab allows the user to adjust several performance parameters related to output losses within a solar array.</p>
      <p>
        Excluding bifacial parameters, an RBI Solar user would likely only adjust the performance parameters to compare the results of Sunfig to another platform, such as PVSyst. Please contact Dave
        Compaan, Jake Bartosch or Matt Hayes for further explanation regarding performance parameters.
      </p>
      <br />
      <u>Bifacial Parameters</u>
      <img src={bifacial_parameters_example} style={left_align_image} />
      <br />
      <u>Bifacial Module</u>
      <p>Indicates the use of bifacial modules for analysis.</p>
      <br />
      <u>Bifaciality Factor</u>
      <p>The ratio of the nominal efficiency on the rear side, with respect to the nominal efficiency on the front side. This is typically 0.6-0.8.</p>
      <br />
      <u>Clearance Height (H)</u>
      <p>For fixed tilt, this is the height from the ground to the bottom panel edge. For SAT, this is the height from the ground to the rotation axis</p>
      <br />
      <u>Transmission % (T)</u>
      <p>A percentage of how much irradiation passes through unimpeded between modules or tables. This variable is typically set at a conservative 0%.</p>
      <br />
      <u>Structure Shade % (S)</u>
      <p>Percentage of the backside irradiation that is shaded or blocked by the racking structure. This percentage is applied as a direct loss to backside irradiation.</p> */}
    </div>
  );
};

const Topography_Tab = () => {
  return (
    <div>
      <img src={topo_tab_one} style={left_align_image} />
      <p>Visually display a 10-meter USGS data set under the existing boundaries.</p>
      <br />
      <p>
        Begin by clicking the Import Elevation button shown below. This will import elevation data onto the map and unlock several other functions within the Topography tab including Grading, Visual
        Layers and Layout Options
      </p>
      <img src={import_elevation_button} style={left_align_image} />
      <br />
      <b>Grading</b>
      <p>
        Using the Grade Target field, the user can input a grade target percentage to be met on site. By clicking Calculate Cut/Fill, the individual cut and fill volumes (yd3) will be calculated and
        displayed on the map. This also unlocks the Graded visual layers to be created as needed.
      </p>
      <br />
      <b>Visual Layers</b>
      <p>
        There are 9 total visual layers that can be created and displayed on the map. For both Ungraded (U) and Graded (G) scenarios, Elevation, Max Grade, NS Grade and EW Grade layers can be created.
        Additionally, when using the Grade Target function, a Cut/Fill layer will be created for display. For reference, NS Grade is used for analyzing single-axis trackers, EW Grade is used for
        analyzing ground fixed tilt structures and Max Grade is a combination of NS Grade and EW Grade.
      </p>
      <p>
        There are several scenarios in which the user may choose to adjust the default values, such as visually representing a competitor’s grade limit for comparison or representing the realistic
        limits of on-site machinery.
      </p>
      <p>To generate additional layers, individually select the desired layers and click the Create Layers option shown below.</p>
      <img src={topo_tab_two} style={left_align_image} />
      <p>To delete the existing layers, select the Delete Layers option shown below</p>
      <img src={topo_tab_three} style={left_align_image} />
      <br />
      <b>Layout Options</b>
      <p>Choose whether to draw the layout by ignoring the specified grade limits or by removing the racks that are beyond the specified grade limit when plotting a layout on the map.</p>
      <p>If removing racks beyond the specified grade limit, the user can choose which surface to act on, Ungraded or Graded. </p>
      <p>To delete all the imported topography data, click the Clear All Topo Data option shown below.</p>
      <img src={topo_tab_four} style={left_align_image} />
      {/* <img src={topo_accordian} style={left_align_image} />
      <p>This tab allows the user to visually overlay USGS topography data on existing boundaries.</p>
      <p>Prior to importing a topography layer, the grade limits can be adjusted as needed to customize the visual display.</p>
      <ul>
        <li>NS Grade Limit (SAT) - RBI Default: 10%</li>
        <li>EW Grade Limit (FT) - RBI Default: 20%</li>
        <li>Resultant Grade Limit (SAT + FT) - RBI Default: 15%</li>
      </ul>
      <p>
        These layers include NS Grade Limit (SAT), EW Grade Limit (FT) and Resultant Grade Limit (SAT + FT). Based on standard RBI grade limits, the default NS Grade Limit is 10%, the default EW Grade
        Limit is 20% and the default Resultant Grade Limit is 15%. While the default values represent the theoretical grade limit of each RBI structure, there are several scenarios in which the user
        may choose to adjust these values, such as visually representing a competitor’s grade limit for comparison or representing the realistic limits of on-site machinery as specified by a project
        manager.
      </p>
      <i>
        *Note that the desired grade limit should first be specified in the Product Tab and will then automatically update in the Topography Tab for the applicable layer. See Product Tab description
        of Grade Limit for more details.
      </i>
      <br />
      <img src={import_elevation_button} style={left_align_image} />
      <p>This button allows the user to import a topography layer over the existing array(s).</p>
      <p>
        It may take several minutes to retrieve and load the topography data onto the map. Once loaded, a colored topography gradient will appear on the map extending just beyond the outer limits of
        all existing boundaries. The default layer is dependent on the chosen structure type. The NS Grade layer will display when a single axis tracker structure is being analyzed. Similarly, the EW
        Grade layer will display when a 2HP, fixed tilt structure is being analyzed. In addition to the grade layer, a scale, represented in percent (%), is displayed on the right side of the map as a
        tool to accurately analyze the visual gradient.
      </p>
      <p>
        Once the topography map has been imported, the Layout Actions option appears within the Topography Tab. This allows the user to choose whether to draw the layout by ignoring the grade limits
        or by removing the racks that are beyond the specified grade limit. Future development includes an option to highlight racks that are outside of the specified grade limit.
      </p>
      <br />
      <img src={clear_all_button} style={left_align_image} />
      <p>To remove the existing layer or to import another layer with updated grade limit parameters, click the Clear All Topo Data button shown below.</p> */}
    </div>
  );
};

const Generating_Portal_Results = () => {
  return (
    <div>
      <p>Once the site boundaries, products and layout parameters have been defined, results can be generated by clicking the Generate button.</p>
      <img src={generate_results_button} alt="" style={left_align_image} />
      <p>The user can also stop the results from being generated by click the Cancel button.</p>
      <img src={cancel_button} alt="" style={left_align_image} />
      <p>Upon completion, a results table will be generated with all configurations based on the user inputs and site conditions.</p>
    </div>
  );
};

const Portal_Results_Table = () => {
  return (
    <div>
      <p>The results table will generate configurations based on the user inputs and site conditions.</p>
      <br />
      <p>The layout for any given line of results will be plotted on the map by double clicking that individual result. An explanation of the results can be found below.</p>
      <br />
      <p>Racking – RBI or Terrasmart Product</p>
      <p>Module Rating – Rating in Watts</p>
      <p>GCR – Ground Coverage Ratio</p>
      <ul>
        <li>The ratio of total module area to total ground area within the boundary</li>
      </ul>
      <p>Pitch (m)</p>
      <ul>
        <li>Distance from the front of a row to the front of the next row</li>
        <li>See Plot Dimensions description</li>
      </ul>
      <p>Tilt – Structure tilt</p>
      <p>Yield (kWh/kWp)</p>
      <ul>
        <li>Generation (kWh)/Capacity (kWp)</li>
      </ul>
      <p>Generation Yr 1 (MWh)</p>
      <ul>
        <li>Estimated total amount of energy generated in first year</li>
      </ul>
      <p>Capacity (MWp)</p>
      <ul>
        <li>Size of array in DC</li>
      </ul>
      <p>Module Qty – Total amount of modules</p>
      <br />
      <p>
        Each column of results can be sorted from top to bottom by clicking the column header. The generated results can also be copied to the clipboard or downloaded in CSV format (excel
        spreadsheet).
      </p>
      <img src={portal_results_table_one} alt="" style={left_align_image} />
      <p>*When copying to clipboard or downloading to CSV, additional data will be available including Azimuth, Intra-Row Spacing, and Racking Table Quantities for row sizes A, B & C.</p>
      <img src={portal_results_table_two} alt="" style={left_align_image} />
      <p>This message will appear as a reminder to regenerate results if any parameters have been updated since the last results were produced.</p>
    </div>
  );
};

const Portal_Report = () => {
  return (
    <div>
      <p>A customizable PDF report can be created for any given row of results by clicking the Show Report button.</p>
      <img src={portal_report_one} alt="" style={left_align_image} />
      <p>The Report will appear and display all the information used to generate the chosen row of results.</p>
    </div>
  );
};

const Plot_Dims = () => {
  return (
    <div>
      <p>The illustration below defines the Pitch (B) and Intra-Row Spacing (IR) in the results. The collector width (A) is the X-dim for single axis and the Y-dim for ground fixed tilt.</p>
      <img src={plot_dims_one} alt="" style={left_align_image} />
      <br />
      <p>
        Ground Fixed Tilt products are drawn as if ϴ = 0°, flat on the ground. Ground fixed tilt is not drawn as a projected aerial view. This gives an accurate A dimension in all plots, but an
        inaccurate IR dimension.
      </p>
      <img src={plot_dims_two} alt="" style={left_align_image} />
    </div>
  );
};

const Rack_Specs = () => {
  return (
    <div>
      <p>The RBI Portal layout accepts up to four different tracker or ground fixed tilt racking sizes.</p>
      <img src={product_i_1} alt="" style={center_image} />
      <br />
      <p> “A”, “B”, “C”, and “D” are simple differentiators, and the quantity of each are reported in the results.</p>

      <b>Active</b>
      <p>
        If checked, this racking size will be used in the layout. If unchecked, this racking dimension will not be used. This is helpful if you, for example, don’t want to include 1-string trackers in
        an analysis.
      </p>

      <b>X,Y</b>
      <p>X is the width in meters of a ground fixed tilt rack or tracker. Y is the height or length in meters of a ground fixed tilt rack or tracker.</p>

      <p>For ground fixed tilt Y is the “face height” or the distance from the bottom of the lowest panel to the top of the highest panel. </p>

      <img src={rack_spec_2} alt="" style={center_image} />
      <br />

      <b>Module Count</b>
      <p>Total number of modules on that size rack or tracker. </p>

      <b>Tilt Angle Minimum & Maximum</b>
      <p>Tilt angles are optimized for maximum yield when running an analysis.</p>
      <p>
        To fix the tilt angle, set both the minimum and maximum to the same degree. This will force RBI to evaluate only that tilt angle. Example: Tilt Angle Minimum and Maximum = 15° will lock the
        tilt angle to 15° for all scenarios.
      </p>

      <b>Orientation</b>
      <p>All orientation performance effects are managed in RBI Portal automatically according to product and module selection.</p>

      <b>Dimension Calc</b>
      <p>This is an optional tool to help you populate the dimension fields</p>
      <p>
        The RBI tracker and ground mount teams have defined typical dimensions based on module and string size. Input the module dimensions (Mod X, Mod Y) and the number of modules per string
        (Mod/Str) in your design. Click Set and pre-defined dimensions will be populated into the Dimensions table.
      </p>
      <img src={product_i_3} alt="" style={center_image} />
    </div>
  );
};

const Module_Target = () => {
  return (
    <div>
      <p>If a module target is set, the RBI portal will only generate results at or below the module target. Ensure that your module target is divisible by the string length.</p>
      <br />
      <p>Module Target OFF, Site is filled to max capacity according to GCR and product sizing.</p>
      <img src={product_ii_1} alt="" style={center_image} />
      <p>Module Target ON, racking is removed to meet module target. RBI Portal attempts to remove least valuable racks first.</p>
      <img src={product_ii_2} alt="" style={center_image} />
    </div>
  );
};
const Bifacial = () => {
  return (
    <div>
      <img src={bifacial_1} alt="" style={center_image} />
      <br />
      <u>Bifacial Module</u>
      <p>Indicates the use of bifacial modules for analysis.</p>
      <br />
      <u>Bifaciality Factor – Module Tab</u>
      <p>The ratio of the nominal efficiency on the rear side, with respect to the nominal efficiency on the front side. This is typically 0.6-0.8.</p>
      <br />
      <u>Clearance Height (H) – Performance Tab</u>
      <p>For ground fixed tilt, this is the height from ground to the bottom panel edge. For SAT, this is the height from the ground to the rotation axis.</p>
      <br />
      <u>Transmission % (T) – Performance Tab</u>
      <p>A percentage of how much irradiation passes through unimpeded between modules or tables. This variable is typically set at a conservative 0%.</p>
      <br />
      <u>Structure Shade % (S) – Performance Tab</u>
      <p>Percentage of the backside irradiation that is shaded or blocked by the racking structure. This percentage is applied as a direct loss to backside irradiation.</p>
    </div>
  );
};

const Generating_Results_Overview = () => {
  return (
    <div>
      <p>Once the site boundary and layout parameters have been determined, results can be generated for further analysis.</p>
      <img src={generate_button} alt="" style={left_align_image} />
      <p>This button allows the user to generate an optimized results table based on a variety of parameters.</p>
      <br />
      <img src={cancel_button} alt="" style={left_align_image} />
      <p>This button allows the user to cancel the generation of the results table.</p>
      <br />
      <img src={view_results_button} alt="" style={left_align_image} />
      <img src={hide_results_button} alt="" style={left_align_image} />
      <p>These buttons allow the user to view or hide the expanded results table.</p>
      <br />
      <img src={copy_button} alt="" style={left_align_image} />
      <img src={download_button} alt="" style={left_align_image} />
      <p>These buttons allow the user to copy or download the generated results for further analysis</p>
      <br />
      <img src={plot_button} alt="" style={left_align_image} />
      <p>This button allows the user to plot the selected row of results onto the map within the array(s). Results can also be plotted by double clicking the desired row.</p>
      <br />
      <img src={sort_button} alt="" style={left_align_image} />
      <p>This icon allows the user to sort the results within each individual column. The generated results initially sort the GCR values from lowest to highest</p>
      <br />
      <p style={{ color: "red" }}> *Inputs have changed since last results were generated</p>
      <p>This message will appear as a reminder to regenerate results if any Product, Performance or Topography parameters are changed after a set of results have already been generated.</p>
    </div>
  );
};

const Result_Explanations = () => {
  return (
    <div>
      <p>
        <b>Racking</b> – Structure Type
      </p>
      <p>
        <b>Module</b> – Module Type
      </p>
      <p>
        <b>GCR</b> – Ground Cover Ratio
      </p>
      <ul>
        <li>The ratio of total module area to total ground area within the boundary</li>
      </ul>
      <p>
        <b>Intra-Row Spacing (m)</b>
      </p>
      <ul>
        <li>Distance from the back of any row to the front of the next row</li>
        <li>See Plot Dimensions description</li>
      </ul>
      <p>
        <b>Pitch (M)</b>
      </p>
      <ul>
        <li>Distance from the front of any row to the front of the next roww</li>
        <li>See Plot Dimensions description</li>
      </ul>
      <p>
        <b>Tilt</b> – Structure tilt.
      </p>
      <p>
        <b>Yield (kWh/kWp)</b> - Generation Yr 1 (kWh)/Capacity (kWp).
      </p>

      <p>
        <b>Generation Yr 1 (kWh)</b> - Estimated total amount of energy generated in one year.
      </p>
      <p>
        <b>Capacity (kWp)</b> - Size of array(s) in kilowatts.
      </p>
      <p>
        <b>Module Qty </b> – Total amount of modules
      </p>
      <p>
        <b>Table Qty A</b> – Total amount of A sized tables within the array(s)
      </p>
      <p>
        <b>Table Qty B</b> – Total amount of B sized tables within the array(s)
      </p>
      <p>
        <b>Table Qty C</b> – Total amount of C sized tables within the array(s)
      </p>
      <p>
        <b>Table Qty D</b> – Total amount of D sized tables within the array(s)
      </p>
    </div>
  );
};
const Weather_Source = () => {
  return (
    <div>
      <p>Portal automatically imports weather data from the NSRDB dataset referenced below. This automatic import only works within the continental United States. </p>
      <p>If the NSRDB database fails, or a project is drawn outside of the NSRDB range, the NASA SSE database is used.</p>
      <br />
      <b>NSRDB </b>
      <p>
        NREL’s National Solar Radiation Database covers much of North and South America. Based on the latitude and longitude provided, Portal automatically pulls the nearest NSRDB PSM v3 TMY data set.{" "}
      </p>
      {/* <a href="https://maps.nrel.gov/nsrdb-viewer/?aL=f69KzE%255Bv%255D%3Dt%26f69KzE%255Bd%255D%3D1&bL=clight&cE=0&lR=0&mC=18.312810846425442%2C-25.839843749999996&zL=2">NSRDB Data Viewer</a> */}
    </div>
  );
};
const Azimuth = () => {
  return (
    <div>
      <p>In RBI, south is 180°. </p>
      North = 0°
      <br />
      South = 180°
      <br />
      East = 90°
      <br />
      West = 270°
      <br />
      <img src={azimuth_1} alt="" style={center_image} />
    </div>
  );
};
const Import_Topo = () => {
  return (
    <div>
      <p>RBI Portal can automatically import topography from USGS. The platform will also automatically generate slope analysis layers from this data. </p>
      <br />
      <p>The Limit field defines how the slope analysis will look visually.</p>
      <br />
      <p>{`Set limits according to your racking’s construction limits. In the below example, the North – South Slope Limit was set to 8%. The areas out of spec, >8%, are shown in a dark gray. `}</p>
      <br />
      <img src={topo_i_1} alt="" style={center_image} />
      <br />
      <p>The bounding box of the topography data is determined by the polygons in the map. Ensure your boundary is complete before importing topography data.</p>
      <img src={topo_i_2} alt="" style={center_image} />
    </div>
  );
};

const Slope_Analysis = () => {
  return (
    <div>
      <p>Navigate the different slope analysis layers using the buttons at the bottom right of the map.</p>
      <img src={topo_ii_1} alt="" style={center_image} />
      <br />
      From top to bottom:
      <ol>
        <li>Elevation</li>
        <li>Resultant Slope</li>
        <li>North-South Slope</li>
        <li>East-West Slope</li>
        <li>Off</li>
      </ol>
      <br />
      <img src={topo_ii_2} alt="" />
    </div>
  );
};
const Topo_Actions = () => {
  return (
    <div>
      <p>
        By default, any imported topography will have no impact on your layout. Use the topography layers to identify areas that are out of product spec and mark those as areas to avoid with the
        exclusion polygons.
      </p>
      <br />
      <p>
        In the Topo tab you also have the option to ‘Remove Racks out of Grade Spec’. With this option, each tracker or rack will calculate the slope below it. If that grade is above the Grade Limit
        set for that product, that rack will be removed from the layout.
      </p>
    </div>
  );
};

const Rack_Align = () => {
  return (
    <div>
      <p>
        Racking Align On forces a regular alignment for racking layout, typical for larger sites. Racking Align Off allows the racking to shift and accommodate more strings, typical for smaller sites.
      </p>
      <p>
        <u>Ground Fixed Tilt:</u>
      </p>
      Racking Align On (Left)
      <ul>
        <li>Racks are aligned vertically and horizontally</li>
        <li>Horizontal and vertical roadways allowed</li>
      </ul>
      Racking Align Off (Right)
      <ul>
        <li>Rows are shifted East-West to maximize site density.</li>
        <li>Horizontal rows allowed; vertical roadways disabled. </li>
      </ul>
      <img src={rack_align_1} alt="" style={center_image} />
      <br />
      <br />
      <p>
        <u>Single Axis Tracker:</u>
      </p>
      Racking Align On (Left)
      <ul>
        <li>Trackers are aligned vertically and horizontally.</li>
        <li>Horizontal and vertical roadways allowed.</li>
      </ul>
      Racking Align Off (Right)
      <ul>
        <li>Trackers are shifted North-South to maximize site density.</li>
        <li>Horizontal rows disabled; vertical roadways allowed.</li>
      </ul>
      <img src={rack_align_2} alt="" style={center_image} />
    </div>
  );
};
const EW_Roads = () => {
  return (
    <div>
      <p>The layout does not attempt to map road placements to specific site entry points. The purpose is to consume an accurate amount of space within the boundary.</p>

      <p>The current system supports East-West roads only, future developments will include North-South and Perimeter road options.</p>

      <p>There are three Road Spacing options:</p>
      <p>
        <u>Off</u> – No roads are placed
      </p>
      <p>
        <u>On</u> – Road placement is automatically determined by racking type, typical inverter size, and typical road spacing.{" "}
      </p>
    </div>
  );
};

const Canopy_Tab = (props) => {
  return (
    <div className="help">
      <div className="drawer-content-help">
        <p>Welcome to the Canopy Portal Help Menu.</p>
        <div>
          <h4>General</h4>
          <ul>
            <li>
              <a href="javascript:void(0);" onClick={() => props.onOpenDrawer("how_it_works")}>
                How it Works
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" onClick={() => props.onOpenDrawer("side_bar_features")}>
                Sidebar Features
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" onClick={() => props.onOpenDrawer("project_management")}>
                Project Management
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h4>Map</h4>
          <ul>
            <li>
              <u>
                <a href="javascript:void(0);" onClick={() => props.onOpenDrawer("canopy_overview")}>
                  Overview
                </a>
              </u>
            </li>
            <li>
              <u>
                <a href="javascript:void(0);" onClick={() => props.onOpenDrawer("import_image")}>
                  Import Image
                </a>
              </u>
            </li>
            <li>
              <u>
                <a href="javascript:void(0);" onClick={() => props.onOpenDrawer("canopy_controls")}>
                  Controls
                </a>
              </u>
            </li>
            <li>
              <u>
                <a href="javascript:void(0);" onClick={() => props.onOpenDrawer("canopy_hotkeys")}>
                  Canopy Hotkeys
                </a>
              </u>
            </li>
          </ul>
        </div>
        <div>
          <h4>Inputs</h4>
          <ul>
            <li>
              <u>
                <a href="javascript:void(0);" onClick={() => props.onOpenDrawer("module_tab")}>
                  Module Tab
                </a>
              </u>
            </li>
            <li>
              <u>
                <a href="javascript:void(0);" onClick={() => props.onOpenDrawer("canopies_tab")}>
                  Canopies_Tab
                </a>
              </u>
            </li>
            <li>
              <u>
                <a href="javascript:void(0);" onClick={() => props.onOpenDrawer("canopy_inputs_tab")}>
                  Canopy Inputs Tab
                </a>
              </u>
            </li>
            <li>
              <u>
                <a href="javascript:void(0);" onClick={() => props.onOpenDrawer("global_override_tab")}>
                  Global Override Tab
                </a>
              </u>
            </li>
          </ul>
        </div>
        <div>
          <h4>Generating Results</h4>
          <ul>
            <li>
              <u>
                <a href="javascript:void(0);" onClick={() => props.onOpenDrawer("canopy_results_overview")}>
                  Overview
                </a>
              </u>
            </li>
            <li>
              <u>
                <a href="javascript:void(0);" onClick={() => props.onOpenDrawer("results_table")}>
                  Results Tab
                </a>
              </u>
            </li>
            <li>
              <u>
                <a href="javascript:void(0);" onClick={() => props.onOpenDrawer("pdf_reports")}>
                  PDF Reports
                </a>
              </u>
            </li>
          </ul>
        </div>
        <div>
          <h4>Additional Information</h4>
          <ul>
            <li>
              <u>
                <a href="javascript:void(0);" onClick={() => props.onOpenDrawer("additional_canopy_information")}>
                  Weather Source
                </a>
              </u>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const How_It_Works = () => {
  return (
    <div>
      <p>Portal powered by Sunfig is built for internal users to quickly evaluate project sites. Users can customize and model various canopy structures and layouts within the canopy tab.</p>
      <div>
        <b>Step 1: Define Project Location</b>
        <img src={define_location} style={left_align_image} />
        <br />
        <b>Step 2: Select Module</b>
        <img src={select_module} alt="" style={left_align_image} />
        <br />
        <b>Step 3: Place Canopies </b>
        <img src={place_canopies} alt="" style={left_align_image} />
        <br />
        <b>Step 4: Adjust/Customize Canopies </b>
        <img src={place_canopies} alt="" style={left_align_image} />
        <br />
        <b>Step 5: Generate Results </b>
        <img src={generate_results_button} alt="" style={left_align_image} />
        <b>Step 6: Analyze/Download Data </b>
        <img src={analyze_download_one} alt="" style={left_align_image} />
        <img src={analyze_download_two} alt="" style={left_align_image} />
      </div>
    </div>
  );
};

const Sidebar_Features = () => {
  return (
    <div>
      <p>Hover the cursor over any icon within Portal to get a quick description of its functionality.</p>
      <div>
        <b>Ground Mount</b>
        <img src={ground_nav_button} style={left_align_image} />
        <p>Used to create ground mount simulations. It is the default page upon login.</p>
        <br />
        <b>Canopy</b>
        <img src={canopy_nav_button} style={left_align_image} />
        <p>Used to create canopy simulations.</p>
        <br />
        <b>Support Documentation</b>
        <img src={help_nav_button} style={left_align_image} />
        <p>Provides an explanation of all the functions within Portal.</p>
        <br />
        <b>Bug Report</b>
        <img src={bug_nav_button} style={left_align_image} />
        <p>Report any bugs or errors encountered while using Portal. It provides the Sunfig team with an exact replica of the existing project accompanied by a user description of the bug.</p>
        <img src={bug_report_modal} style={left_align_image} />
        <br />
        <b>Logout</b>
        <img src={logout_nav_button} style={left_align_image} />
        <p>Logs the user out of Portal and redirects them to the login page.</p>
        <br />
      </div>
    </div>
  );
};

const Project_Management = () => {
  return (
    <div>
      <b>New</b>
      <br />
      <img src={new_proj_button} syle={left_align_image} />
      <p>Create a new canopy simulation</p>
      <br />
      <b>Save/Save As</b>
      <img src={save_button} syle={left_align_image} />
      <p>Save a new project or additions to an existing project. All existing canopies and inputs will be saved.</p>
      <br />
      <b>Load</b>
      <img src={load_button} style={left_align_image} />
      <p>View, load, delete or share any previously created projects.</p>
    </div>
  );
};

const Canopy_Overview = () => {
  return (
    <div>
      <p>At least one canopy is required to generate results, however there is no limit to the number of canopies that can be analyzed.</p>
      <p>
        Canopy layouts can be manually created by placing canopies on the map and adjusting the structure length, width and orientation using the cursor. They can be further adjusted using the Canopy
        Inputs tab.
      </p>
      <p>Once a canopy is created, the project county will be displayed on the map for reference.</p>
      <img src={map_county_box} style={left_align_image} />
    </div>
  );
};

const Import_Image = () => {
  return (
    <div>
      <p>To import an updated image of a canopy site (i.e. developed parking lot or parking garage), drag and drop an image file (PNG/JPEG) directly onto the map.</p>
      <p>Adjust the image on the map as needed using the translate, rotate, scale, distort, opacity and delete buttons</p>
      <img src={image_import_tool} style={left_align_image} />
      <p>When finished, click the lock button to lock the image in place.</p>
      <p>Once locked, an Imported Images tab will appear to help track and edit any images on the map.</p>
      <img src={import_image_accordian} style={left_align_image} />
    </div>
  );
};

const Canopy_Controls = () => {
  return (
    <div>
      <p>The search, place canopy and delete all buttons are always available for use. The duplicate, alignment and non-rectangular buttons are only available when a canopy is selected.</p>
      <br />
      <b>Search</b>
      <img src={search_tool} style={left_align_image} />
      <p>Search for any location by city, state, zip code, etc.</p>
      <br />
      <b>Place Canopy</b>
      <img src={place_canopy_tool} style={left_align_image} />
      <p>Place a canopy on the map.</p>
      <br />
      <b>Delete All</b>
      <img src={delete_tool} style={left_align_image} />
      <p>Delete all existing canopies. The user will be prompted to confirm their choice.</p>
      <br />
      <b>Duplicate</b>
      <img src={duplicate_tool} style={left_align_image} />
      <p>Duplicate selected canopies up, down, left, or right.</p>
      <br />
      <b>Allignment</b>
      <img src={allignment_tool} style={left_align_image} />
      <p>Align any two canopies as needed.</p>
      <br />
      <b>Non-rectangular</b>
      <img src={non_rectangular_tool} style={left_align_image} />
      <p>Click to remove modules to create non-rectangular canopies. Drag a box for multiple module selection.</p>
      <br />
      <b>Allignment</b>
      <img src={allignment_tool} style={left_align_image} />
      <p>Align any two canopies as needed.</p>
      <br />
      <b>Zoom In</b>
      <img src={zoom_in_tool} style={left_align_image} />
      <p>Zoom in at small intervals.</p>
      <br />
      <b>Zoom Out</b>
      <img src={zoom_out_tool} style={left_align_image} />
      <p>Zoom out at small intervals.</p>
      <br />
      <b>Show Names</b>
      <img src={show_names_tool} style={left_align_image} />
      <p>Display the assigned name of each canopy..</p>
      <br />
      <b>Map Layers</b>
      <img src={layers_tool} style={left_align_image} />
      <p>Adjust the map layer.</p>
      <img src={layers_menu} style={left_align_image} />
      <br />
      <b>Website</b>
      <img src={powered_by_icon} style={left_align_image} />
      <p>powe</p>
    </div>
  );
};

const Canopy_Hotkeys = () => {
  return (
    <div>
      <b>Hotkeys:</b>
      <ul>
        <li>Left click and drag – map pan</li>
        <li>Scroll wheel – zoom in/out</li>
        <li>+/- keys – zoom in/out </li>
      </ul>

      <b>If a canopy is selected</b>
      <ul>
        <li>Copy: CTRL + C</li>
        <li>Paste: CTRL + V</li>
        <li>Toggle Alignment Tool: ALT + A</li>
        <li>Duplicate Up: CTRL + ↑ </li>
        <li>Duplicate Down: CTRL + ↓ </li>
        <li>Duplicate Left: CTRL + ← </li>
        <li>Duplicate Right: CTRL + → </li>
        <li>Translate Up: CTRL + Shift + ↑</li>
        <li>Translate Down: CTRL + Shift + ↓</li>
        <li>Translate Left: CTRL + Shift + ←</li>
        <li>Translate Right: CTRL + Shift + →</li>
        <li>Rotate Left: ALT + Shift + ←</li>
        <li>Rotate Right: ALT + Shift + →</li>
        <li>Delete: CTRL + Delete/Backspace</li>
      </ul>
    </div>
  );
};

const Module_Tab = () => {
  return (
    <div>
      <img src={module_accordian} style={left_align_image} />
      <p>Select from a list of generic modules or import a customer PAN file for maximum design and performance accuracy.</p>
    </div>
  );
};

const Canopies_Tab = () => {
  return (
    <div>
      <img src={canopy_accordian} style={left_align_image} />
      <p>Lists all existing canopies on the map.</p>
      <b>Copy/Delete</b>
      <img src={copy_delete_accordian_tool} style={left_align_image} />
      <p>
        Canopies can be copied or deleted within this tab. To copy a canopy, click the copy button and then click the map to place the copied canopy. To delete a canopy, click the delete button and
        confirm when prompted.
      </p>
    </div>
  );
};

const Canopy_Inputs_Tab = () => {
  return (
    <div>
      <img src={canopy_input_accordian} style={left_align_image} />
      <p>To use this tab, first select a canopy to edit within the Canopies tab or directly on the map.</p>
      <p>
        Customize each canopy structure including module, module orientation, number of modules long, number of modules wide, module height, module width, module gap, azimuth, plane type, and tilt.
        Canopies can also be renamed, copied, and deleted as needed.
      </p>
      <p>For inverted canopies, the user can define separate tilts based on the direction of the module inversion as well as the number of modules on each side of the structure.</p>
      <p>For inverted and dual tilt canopies, a reminder will appear with guidance on how to determine which direction the modules are facing on the structure.</p>
    </div>
  );
};

const Global_Override_Tab = () => {
  return (
    <div>
      <img src={override_accordian} style={left_align_image} />
      <p>Override the module, module orientation, azimuth, tilt, and plane type for all canopies on the map. Click the corresponding Set All button to override the existing canopy inputs.</p>
    </div>
  );
};

const Canopy_Results_Overview = () => {
  return (
    <div>
      <p>Once the canopies have been defined and the layout is complete, results can be generated by clicking the Generate button.</p>
      <img src={generate_results_button} style={left_align_image} />
      <p>The user can also stop the results from being generated by clicking the Cancel button.</p>
      <img src={cancel_button} style={left_align_image} />
      <p>Upon completion, a results table will be generated including all the information for each individual canopy on the map.</p>
    </div>
  );
};

const Results_Table = () => {
  return (
    <div>
      <p>The results table includes all the information for each individual canopy.</p>
      <br />
      <p>Total Module Quantity and Site Capacity are displayed at the top of the results table for reference.</p>
      <br />
      <p>Name – Canopy name</p>
      <p>Module – Module type</p>
      <p>Canopy Type – Plane type</p>
      <p>Azimuth – Structure orientation</p>
      <ul>
        <li>North – 0°</li>
        <li>East – 90°</li>
        <li>South – 180° (Default)</li>
        <li>West – 270°</li>
      </ul>
      <p>Tilt – Structure tilt</p>
      <p>Dimension (LxW) Qty – Number of modules long and wide</p>
      <p>Dimensions (LxW) Ft – Total length and width of canopy in feet</p>
      <p>Module Qty – Total number of modules on canopy Generation (kWh) – Estimated total amount of energy generated in first year</p>
      <p>Yield (kWh/kWp) – Generation (kWh)/Capacity (kWp)</p>
      <br />
      <p>
        Each column of results can be sorted from top to bottom by clicking the column header. The generated results can also be copied to the clipboard, downloaded in CSV format (excel spreadsheet),
        downloaded in a PDF Report, or downloaded as a 30% Drawing Set.
      </p>
      <br />
      <img src={analyze_download_one} style={left_align_image} />
      <img src={analyze_download_two} style={left_align_image} />
    </div>
  );
};

const PDF_Reports = () => {
  return (
    <div>
      <p>Several customizable PDF reports can be created for the entire canopy layout by clicking the Show Report button or the Show 30% Set button.</p>
      <img src={analyze_download_one} style={left_align_image} />
    </div>
  );
};

const Canopy_Weather_Source = () => {
  return (
    <div>
      <p>Portal automatically imports weather data from the NSRDB dataset referenced below. This automatic import only works within the continental United States. </p>
      <br />
      <p>If the NSRDB database fails, or a project is created outside of the NSRDB range, the NASA SSE database is used.</p>
      <br />
      <b>NSRDB</b>
      <p>
        NREL’s National Solar Radiation Database covers much of North and South America. Based on the latitude and longitude provided, Portal automatically pulls the nearest NSRDB PSM v3 TMY data set.
      </p>
    </div>
  );
};

// >========= END RBI ============<

export {
  Root_Tab,
  Sunfig_Support,
  How_Portal_Works,
  Portal_Sidebar,
  Portal_Project_Management,
  // RBI_Intro,
  // Ground_Mount_Features,
  Bug_Report,
  Map_Overview,
  Map_Controls,
  Map_Hotkeys,
  Basic_Inputs,
  Portal_Module_Tab,
  Racking_Tab,
  Map_Layers,
  Import_KMZ,
  Export_Map,
  Plot_Map,
  Select_Product_Module,
  Product_Tab,
  Performance_Tab,
  Topography_Tab,
  Generating_Results_Overview,
  Generating_Portal_Results,
  Portal_Results_Table,
  Portal_Report,
  Plot_Dims,
  Result_Explanations,
  Rack_Specs,
  Module_Target,
  Bifacial,
  Weather_Source,
  Azimuth,
  Import_Topo,
  Slope_Analysis,
  Topo_Actions,
  Rack_Align,
  EW_Roads,
  Canopy_Tab,
  How_It_Works,
  Sidebar_Features,
  Project_Management,
  Canopy_Overview,
  Import_Image,
  Canopy_Controls,
  Canopy_Hotkeys,
  Module_Tab,
  Canopies_Tab,
  Canopy_Inputs_Tab,
  Global_Override_Tab,
  Canopy_Results_Overview,
  Results_Table,
  PDF_Reports,
  Canopy_Weather_Source,
};
