import React, { useCallback, useState, useEffect, useRef, Fragment } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { LeafletControl } from './index'

const StateAndCounty = () => {
  const areThereCanopies = useSelector((state) => Object.values(state.carport.rectangles).length > 0);
  const county = useSelector((state) => state.carport.county);
  const stateAbb = useSelector((state) => state.carport.stateAbb);

  return (
		areThereCanopies && (
			<LeafletControl position="bottomright">
				{county && stateAbb && (
					<div
						style={{
							height: '20px',
							background: '#ffffffb0',
							color: 'black',
							left: '10px',
							top: '10px',
							position: 'relative',
							padding: '2px',
							fontWeight: 'bold',
						}}
					>
						<p>{`${county} County, ${stateAbb}`}</p>
					</div>
				)}
			</LeafletControl>
		)
  );
};

export { StateAndCounty }