import styled from "styled-components";

const LiveReportLayout = styled.section`
  width: 100%;
  height: calc(100% - 50px);
  background: #dadada;
  padding: 5px 40px;
  display: grid;
  overflow-y: auto;
  grid-template-rows: repeat(2, auto);

  .page-wrap {
    height: 1200px;
    width: 900px;
    margin-bottom: 15px;
    display: grid;
    place-self: center;
    padding: 10px;
    background: #fff;

    .page {
      height: 100%;
      width: 100%;
      display: grid;
      border: 1px solid rgba(0, 0, 0, 0.9);
      grid-template-rows: min-content 1fr min-content;
      grid-template-areas:
        "report-header"
        "report-content"
        "report-footer";
    }

    .report-loader {
      grid-area: report-header;
      width: 100%;
      display: grid;
      place-items: center;
    }

    .report-rundown {
      grid-area: report-content;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, min-content) 1fr;
      grid-template-areas:
        "report-title report-title"
        "info-boxes vicinity-map"
        "layout-map layout-map";

      .report-title {
        grid-area: report-title;
        width: 100%;
        height: 80px;
        display: grid;
        place-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.9);
        grid-template-rows: 1fr 1fr;

        h1 {
          font-size: 20px;
          margin: 0px;
        }

        .address-box {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          p {
            margin: 0px;
          }
          input {
            padding: 0;
            border: 1px solid #d9d9d9;
            outline: none;
            border-radius: 4px;

            &:focus {
              border: 2px solid #60de4f;
            }
          }

          svg {
            margin-left: 5px;
          }
        }

        .customer-name-box,
        .customer-address-box {
          position: absolute;
          display: flex;
          align-items: center;
          margin-top: -15px;
          p,
          input {
            margin: 0px 5px 0px 0px;
          }
          input {
            width: 225px;
          }
          svg {
            &:hover {
              opacity: 0.8;
              cursor: pointer;
            }
          }
        }
      }
    }

    .vicinity-map {
      grid-area: vicinity-map;
      padding: 10px;
    }

    .layout-map {
      grid-area: layout-map;
      padding: 10px;
    }

    .large-layout-map {
      grid-area: report-content;
      padding: 10px;
    }

    .sankey-diagram {
      grid-area: report-content;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: min-content 1fr;
      grid-template-areas:
        "title "
        "sankey";

      .sankey-title {
        grid-area: title;
        padding-left: 10px;
        h3 {
          margin: 0px;
        }
      }
    }

    .report-content {
      grid-area: report-content;
      padding: 10px;
    }

    /* this is a generic content page layout */
    .page-content {
      grid-area: report-content;
      padding: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: min-content min-content 1fr;
      grid-column-gap: 20px;
      grid-template-areas:
        "title title"
        "topLeft topRight"
        "bottomLeft bottomRight";
    }

    .title {
      grid-area: title;

      h3 {
        margin: 0px;
      }
    }
    .topLeft {
      grid-area: topLeft;
    }
    .topRight {
      grid-area: topRight;
    }
    .bottomLeft {
      grid-area: bottomLeft;
    }
    .bottom-right {
      grid-area: bottomRight;
    }

    .left-column {
      padding: 10px;
      grid-column: 1;
      grid-row: 2 / -1;
    }
    .right-column {
      padding: 10px;
      grid-column: 2;
      grid-row: 2 / -1;
    }
    .bifacial-column {
      padding: 148px 10px 0px 10px;
      grid-column: 2;
      grid-row: 2 / -1;
    }

    .bottomLeft-and-bottomRight {
      margin-top: 20px;
      grid-row: 3;
      grid-column: 1 / -1;
    }
    .racking-image {
      display: grid;
      place-items: center;
      margin-top: 20px;
      grid-row: 3;
      grid-column: 1 / -1;

      img {
        max-width: 800px;
      }
    }

    .module-content {
      grid-area: report-content;
      padding: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(3, min-content);
      grid-template-areas:
        "title title"
        "module-table iam-graph"
        "module-table iam-table";
    }

    .module-table {
      grid-area: module-table;
    }

    .iam-graph {
      grid-area: iam-graph;
    }

    .iam-table {
      grid-area: iam-table;
      max-width: 175px;
      margin-left: auto;
    }

    .perf-results {
      grid-area: report-content;
      padding: 10px;
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: repeat(3, min-content) 1fr;
      gap: 10px 5px;
      grid-template-areas:
        "title title "
        "perf-table wind-graph "
        "perf-table eArray-graph"
        "perf-graph perf-graph";
    }

    .perf-table {
      grid-area: perf-table;
    }
    .wind-graph {
      grid-area: wind-graph;
    }
    .eArray-graph {
      grid-area: eArray-graph;
    }
    .perf-graph {
      grid-area: perf-graph;
      place-self: center;
    }

    .topo-content {
      grid-area: report-content;
      padding: 10px;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
      gap: 15px 0px;
    }

    .topo-container {
      display: grid;
      grid-template-columns: 3fr 1fr;
      grid-template-rows: min-content 1fr;
      grid-template-areas:
        "topo-title topo-title"
        "topo-map topo-scale";
    }
    .topo-title {
      grid-area: topo-title;
    }
    .topo-map {
      grid-area: topo-map;
    }
    .topo-scale {
      grid-area: topo-scale;
      display: grid;
      place-content: center;
    }
  }
`;

const ReportMapWrap = styled.section`
  height: 100%;
  width: 100%;
  padding: 0px 4px;
  /* margin: 10px auto; */

  .map-toggles {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    h3 {
      margin: 0px;
    }

    .map-toggle-buttons {
      display: flex;
      align-items: center;

      .toggle {
        display: flex;
        align-items: center;
        margin-right: 5px;
        p {
          margin: 0px 3px;
        }
      }

      .switch {
        position: relative;
        display: inline-block;
        width: 38px;
        height: 22px;
      }

      /* Hide default HTML checkbox */
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      /* The slider */
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      input:checked + .slider {
        background-color: #60de4f;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #60de4f;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(15px);
        -ms-transform: translateX(15px);
        transform: translateX(15px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }
  }
`;

const ReportPageHeaderWrap = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: min-content 1fr;
  margin-bottom: 10px;

  .report-header-image {
    grid-column: 1;
    display: grid;
    place-items: center;
    border-right: 1px solid rgba(0, 0, 0, 0.9);
    border-bottom: 1px solid rgba(0, 0, 0, 0.9);
    /* padding: 5px; */

    img {
      width: 110px;
    }
  }

  .report-header-content {
    grid-column: 2;
    display: grid;
    grid-template-rows: repeat(2, 1fr);

    .report-header-row-1,
    .report-header-row-2,
    .report-header-row-3 {
      display: grid;
      padding: 0px 10px;
      border-bottom: 1px solid #333;
      line-height: 30px;

      p {
        margin: 0px;
      }
    }

    .report-header-row-1 {
      width: 100%;
      grid-template-columns: 1fr 120px;

      p:nth-child(2) {
        text-align: center;
        border-left: 1px solid rgba(0, 0, 0, 0.9);
        white-space: nowrap;
        padding: 0px 5px;
      }
    }
    .report-header-row-2 {
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      p:nth-child(2) {
        border-left: 1px solid rgba(0, 0, 0, 0.9);
        padding-left: 10px;
      }
    }
  }
`;

const ReportPageFooterWrap = styled.section`
  width: 100%;
  height: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.9);
  display: grid;
  grid-template-columns: 1fr 6fr 1fr;

  .page-designation {
    grid-column: 1;
    display: grid;
    place-items: center;

    p {
      margin: 0px;
    }
  }

  .powered-by-sunfig {
    grid-column: 2;
    display: grid;
    place-items: center;
    border-left: 1px solid #333;
    border-right: 1px solid #333;
  }

  .page-number {
    grid-column: 3;
    display: grid;
    place-items: center;

    p {
      margin: 0px;
    }
  }
`;

const TableWrap = styled.section`
  height: min-content;
  width: 100%;
  border: 1px solid #333;
  border-radius: 2px;
  margin: 10px 0px;

  &:last-child {
    border-bottom: none;
  }

  .title {
    width: 100%;
    background: #dddddd;
    border-bottom: 1px solid #333;
    padding: 2px 5px;
    p {
      color: #333;
      margin: 0px;
    }
  }

  .table-row {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid #333;

    .table-header {
      grid-column: 1;
      text-align: right;
      padding: 2px 5px;
      background: #dddddd;
      border-right: 1px solid #333;
      margin: 0px;
    }
    .table-text {
      grid-column: 2;
      text-align: left;
      padding: 2px 5px;
      margin: 0px;
    }
  }

  .tracker-breakdown-container {
    margin-top: 20px;

    .tracker-header-row,
    .tracker-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      text-align: left;

      p {
        width: 25%;
        font-weight: normal;
        margin: 3px;
      }
    }
    .tracker-header-row {
      p {
        margin: 0px;
        font-weight: 700;
      }
    }
  }
`;

const TrackerTableWrap = styled.table`
  width: 100%;
  border: 1px solid #333;
  border-radius: 2px;
  thead {
    background: #dddddd;
    border-bottom: 1px solid #333;
    padding: 2px 5px;

    th {
      font-weight: 400;
      border-right: 1px solid #333;
      padding: 0px 5px;
      width: 55px;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #333;
      td {
        border-right: 1px solid #333;
        padding: 0px 5px;
      }
    }
  }
`;

const InfoBoxWrap = styled.section`
  grid-area: info-boxes;
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    "info-box-title"
    "info-box-container ";

  .info-box-title {
    grid-area: info-box-title;

    h3 {
      margin: 0px 5px 5px;
    }
  }

  .info-box-container {
    grid-area: info-box-container;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);

    .info_box {
      height: 100px;
      padding: 5px;
      width: 125px;
      border-radius: 2px;
      display: flex;
      margin: 0px 5px;
      flex-direction: column;
      justify-content: start;
      border: 1px solid #d9d9d9;

      svg {
        height: 20px;
      }

      p {
        margin: 0px;
        opacity: 0.8;
        font-size: 12px;
      }

      p:nth-child(3) {
        opacity: 1;
        font-weight: 600;
      }
    }
  }
`;

const PerfSummaryTableWrap = styled.table`
  border-collapse: collapse;
  &&& {
    th,
    td {
      border: 1px solid #333;
      padding: 3px 5px;
      vertical-align: baseline;
    }
  }

  tr th {
    text-align: center;
  }
  tr td {
    white-space: nowrap;
  }
`;

const LossTableWrap = styled.table`
  border-collapse: collapse;
  width: 100%;
  &&& {
    th,
    td {
      border: 1px solid #333;
      padding: 3px 5px;
      vertical-align: baseline;
    }
  }

  tr th {
    text-align: center;
  }
  tr td {
    white-space: nowrap;
  }
`;
export { LiveReportLayout, ReportMapWrap, ReportPageHeaderWrap, ReportPageFooterWrap, TableWrap, TrackerTableWrap, InfoBoxWrap, PerfSummaryTableWrap, LossTableWrap };
