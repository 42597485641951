import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Map, Marker } from 'react-leaflet';

//styles
import { ReportMapWrap } from '../LiveReport/styles';

// redux
import { useSelector } from 'react-redux';

// components
// components
import { LiveReportZoomAndLayerControl } from './LiveReportZoomAndLayerControl';

import BingLayer from '../Sunfig/Bing';

const bing_key = 'Apg1Wo2sThhv7onPyHncSIy7J3Nn98FFIzAhWYNFDrNLDpCRkZaSic1uuBHRoppM';
const TileSets = {
  Satellite: 'Aerial',
  'Satellite With Labels': 'AerialWithLabels',
  'Satellite With OnDemand Labels': 'AerialWithLabelsOnDemand',
  'Street Map': 'Road',
  // "Street Map Dark": "CanvasDark",
  // "CanvasLight": "CanvasLight",
  // "CanvasGray": "CanvasGray"
};

const LiveCanopyReportVicinityMap = ({ setCanopyMapRefs }) => {
  const carport = useSelector((state) => state.carport);

  const [showControls, setShowControls] = useState(false);
  const mapCenter = useSelector((state) => state.carport.map.mapCenter);
  const [position, setPosition] = useState(mapCenter);
  const [zoom, setZoom] = useState(14);
  const [bingLayerVisible, setBingLayerVisible] = useState(true);
  const [activeTileSet, setActivetileSet] = useState('Street Map');

  const canopyReportVincityMap = useRef();
  const canopyVicinityMarker = useRef();

  useEffect(() => {
    setTimeout(() => {
      setCanopyMapRefs('vicinityMap', canopyReportVincityMap.current);
    }, 500);
  }, []);

  const handleThirtyPercentZoom = (zoom) => {
    setZoom(zoom);
  };

  const handleMarkerDrag = () => {
    if (canopyVicinityMarker != null) {
      let pos = canopyVicinityMarker.current.leafletElement.getLatLng();
      setPosition(pos);
    }
  };
  return (
    <ReportMapWrap onMouseEnter={() => setShowControls(true)} onMouseLeave={() => setShowControls(false)}>
      <Map
        ref={canopyReportVincityMap}
        id="live-canopy-vicinity-map"
        className="live-canopy-vicinity-map"
        animate={true}
        noWrap={true}
        attributionControl={false}
        center={mapCenter}
        zoom={zoom}
        minZoom={0}
        maxZoom={25}
        onzoomend={() => handleThirtyPercentZoom(canopyReportVincityMap.current.leafletElement.getZoom())}
        zoomControl={false}
        zoomSnap={carport.zoomGranularity}
        zoomDelta={carport.zoomGranularity}
        style={{ width: '100%', height: '100%' }}
      >
        {bingLayerVisible && <BingLayer bingkey={bing_key} type={TileSets[activeTileSet]} maxZoom={25} maxNativeZoom={18} />}

        {showControls && canopyReportVincityMap.current && canopyReportVincityMap.current.leafletElement && (
          <LiveReportZoomAndLayerControl mapRef={canopyReportVincityMap.current.leafletElement} position={position} />
        )}

        <Marker ref={canopyVicinityMarker} draggable={true} ondragend={handleMarkerDrag} position={position} />
      </Map>
    </ReportMapWrap>
  );
};

export { LiveCanopyReportVicinityMap };
