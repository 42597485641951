import React, { Component, Fragment } from "react";
import { Row, Table, Button, Modal } from "antd";
import styled from "styled-components";
import { CSVLink } from "react-csv";
import domtoimage from "dom-to-image";

// Redux
import { connect } from "react-redux";
import { portalActions, inputsActions } from "../Redux/actions";
import { message } from "antd";

import { PDFDownloadLink } from "@react-pdf/renderer";
import { GroundMountReport, DownloadPDF } from "../PDFReport";
import { LiveReport, LocationMap, generateLossTable } from "../LiveReport";
import { ResultTable } from "./ResultsPanel";
import { MonthlyGeneration, PerformanceRatio } from "../LiveReport/Graphs";

import { close } from "../../assets";

const TableButtonWrapper = styled.div`
  display: flex;
  height: auto;
  align-items: center;
  justify-content: space-between;

  .table-action-buttons {
    display: grid;
    padding: 10px;
    grid-template-columns: auto auto;
    grid-template-columns: auto auto;
    grid-column-gap: 20px;
    place-items: end;

    .all-results-buttons {
      /* margin-top: -15px; */
      .all-results-text {
        p {
          margin: 0;
          font-size: 12px;
        }
      }

      button {
        margin: 0px 8px 0px 0px;
        color: #60de4f;
        border: 1px solid #60de4f;
        background: #fff;
        box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
        border-radius: 4px;

        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }
  }

  .result-close-button {
    button {
      margin: 0px;
      top: -10px;
      border: none;
      background-color: #fff;
    }
  }
`;

const d = new Date();
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

class GroundMountResults extends Component {
  constructor(props) {
    super(props);
    this.csvRef = React.createRef();
    this.state = {
      liveReportMapRefs: {},
      reportImages: {
        locationMap: undefined,
        smallLayoutMap: undefined,
        largeLayoutMap: undefined,
        // layoutMapNoBackground: undefined,
        iamGraph: undefined,
      },
      userName: undefined,
      role: undefined,
      liveReportData: undefined,
      reportDone: false,
      liveReportPrepared: false,
      preparingLiveReport: false,
      preparingReport: false,
      reportPrepared: false,
      reportData: undefined,

      filteredInfo: null,
      sortedInfo: {
        columnKey: "gcr",
        order: "ascend",
      },
    };
  }

  componentDidMount() {
    let email = localStorage.getItem("email");
    let user = localStorage.getItem("user");
    let role = JSON.parse(user).role;
    // to test the salesforce capture tool, just change role to some other int !== 10
    this.setState({ userName: email, role: role });
  }

  componentDidUpdate(prevProps, prevState) {
    const { portal } = prevProps;
    const { report } = portal;

    if (!this.state.preparingReport && this.state.reportPrepared && this.props.portal.selectedRow.id !== portal.selectedRow.id) {
      this.handleDownloadReportReset();
    }

    if (prevProps.portal.report.reportDone === false && this.props.portal.report.reportDone === true) {
      this.setState({ reportDone: true });
    }

    if (prevProps.download_lock == true && this.props.download_lock == false) {
      if (this.props.download_type == "ground_report") {
        this.captureLiveReport();
      }
      if (this.props.download_type == "ground_csv") {
        setTimeout(() => {
          this.csvRef.current.link.click();
        }, 1000);
      }
    }

    // if (this.props.portal.report.reportDone === true) {
    //   // take screenshots of all canopies
    //   // console.log('images for report complete')
    //   // this.setState({reportPrepared: true})
    //   let reportData = {
    //     reportImages: this.state.reportImages,
    //     results: this.props.portal.report.reportData,
    //     location: this.props.portal.report.location,
    //     county: this.props.portal.uiControls.county,
    //     inputs: this.props.portal.inputs,
    //     selectedModule: this.props.portal.inputs.selectedModule,
    //     selectedProduct: this.props.portal.inputs.selectedProduct,
    //     projectName: this.props.portal.current_project.name,
    //     user: this.state.userName,
    //   };
    //   this.setState({
    //     liveReportData: reportData,
    //     preparingReport: false,
    //     reportPrepared: true,
    //   });
    //   this.props.dispatch(portalActions.prepareGroundReport(null, null, false));
    // }
  }

  captureGroundMap = (row) => {
    if (this.props.portal.uiControls.loading_layout == false && this.props.portal.layout.isLayout) {
      if (!this.state.preparingReport && !this.state.reportPrepared) {
        this.props.dispatch(portalActions.prepareGroundReport(row, null, false));
        this.setState({ ...this.state, preparingReport: true, reportData: undefined, reportPrepared: false });
      }
    } else {
      setTimeout(() => {
        this.captureGroundMap(row);
      }, 200);
    }

    this.handleDownloadReportReset();
  };

  captureGraphImage = (key, imageData) => {
    let tempReportImages = this.props.portal.report.reportData.reportImages.graphImages;
    tempReportImages[key] = imageData;
    this.props.dispatch(portalActions.updateReportData("graphImages", tempReportImages, true));
  };

  captureLiveReport = (keys = undefined) => {
    let tempMapImages = this.props.portal.report.reportData.reportImages.mapImages;
    this.setState({ preparingLiveReport: true, liveReportPrepared: false, reportDone: false }, () => {
      Object.keys(this.state.liveReportMapRefs).map(async (key) => {
        let value = await this.getPngUrl(this.state.liveReportMapRefs[key]);
        let url = { keys: keys || "overall", value };
        tempMapImages[key] = url.value.url;
      });
      this.props.dispatch(portalActions.updateReportData("mapImages", tempMapImages, true));
      setTimeout(() => {
        this.setState({ preparingLiveReport: false, liveReportPrepared: true });
      }, 6000);
    });
  };

  getPngUrl = async (mapRef) => {
    return new Promise((resolve, reject) => {
      let width = mapRef.width;
      let height = mapRef.height;
      let node = document.getElementById(`${mapRef.className}`);
      domtoimage
        .toJpeg(node, { width, height, quality: 0.6, cacheBust: true })
        .then((url) => resolve({ url, width, height }))
        .catch((error) => reject(error));
    });
  };

  //used for capturing the live report map
  setLiveReportMapRefs = (key, ref) => {
    let mapRefs = this.state.liveReportMapRefs;
    mapRefs[key] = ref;
    this.setState({ liveReportMapRefs: mapRefs });
  };

  setLocationMapImage = (image) => {
    this.setState({ LocationMapImage: image });
  };

  generateLiveReport = async (row) => {
    let selected_row = JSON.parse(JSON.stringify(row));

    await this.handleGetRackData(selected_row);
    let date = `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;

    let reportData = {
      ...selected_row,
      loss_stack: generateLossTable(selected_row),
      reportImages: {
        mapImages: {},
        graphImages: {},
      },
      county: this.props.portal.uiControls.county,
      location: undefined,
      // inputs: this.props.portal.inputs,
      // topoData: this.props.portal.topoData,
      // selectedModule: this.props.portal.inputs.selectedModule,
      // selectedProduct: this.props.portal.inputs.selectedProduct,
      // projectName: this.props.portal.current_project.name,
      userName: this.state.userName,
      totalArea: undefined,
      inactiveArea: undefined,
      boundaryArea: undefined,
      exclusionArea: undefined,
      address: "",
      date,
      numOfPages: undefined,
    };
    this.props.dispatch(portalActions.prepareGroundReport(JSON.parse(JSON.stringify(reportData)), true));
    // this.setState({ ...this.state, preparingReport: true, reportData: undefined, reportPrepared: false });
  };

  handleDownloadReportReset = () => {
    this.props.dispatch(portalActions.updateReportData("reset", false));
    this.setState({ reportPrepared: false, liveReportPrepared: false, reportDone: false });
  };

  handleSelectResult = (record) => {
    this.props.dispatch(portalActions.selectResult(record));
  };

  handleGetRackData = async (row) => {
    await this.props.dispatch(portalActions.getLayoutData(row.id));
  };

  handleChange = (pagination, filters, sorter) => {
    let _sorter = sorter;
    if (!sorter.order) {
      // interrupt the default functionality
      _sorter = this.state.sortedInfo;
      _sorter.order = "ascend";
    }
    this.setState({
      filteredInfo: filters,
      sortedInfo: _sorter,
    });
  };

  render() {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    return (
      <Fragment>
        <TableButtonWrapper>
          <div className="table-action-buttons">
            <Button
              type="primary"
              disabled={this.state.preparingReport || this.state.preparingLiveReport}
              ghost
              onClick={() => {
                this.props.dispatch(portalActions.updateUIControls("show_live_report", !this.props.portal.uiControls.show_live_report));
                if (this.props.portal.uiControls.show_live_report) {
                  this.generateLiveReport(this.props.selectedRow);
                  if (this.props.download_lock == false) {
                    this.props.dispatch(inputsActions.reset_sf_capture());
                  }
                } else {
                  this.props.dispatch(inputsActions.reset_sf_capture());
                  this.handleDownloadReportReset();
                }
              }}
            >
              {this.props.portal.uiControls.show_live_report ? "Hide Report" : "Show Report"}
            </Button>
            {!this.props.portal.uiControls.show_live_report ? (
              <section className="all-results-buttons">
                <section className="all-results-text">
                  <p>All Results:</p>
                </section>
                {this.props.download_lock && this.state.role !== 15 ? (
                  <button onClick={() => this.props.dispatch(inputsActions.toggle_sf_modal("show_sf_modal", true, "ground_csv"))}>Unlock CSV</button>
                ) : (
                  <CSVLink ref={this.csvRef} filename={"RBI-Portal-Results-" + this.props.portal.current_project.name + ".csv"} data={this.props.portal.csvResults} target="_blank">
                    <button>CSV</button>
                  </CSVLink>
                )}

                {/* <button
                  onClick={() => {
                    this.refs.results.hidden = false;
                    this.refs.results.select();
                    document.execCommand("copy");
                    this.refs.results.hidden = true;
                    message.success("Copied Results to clipboard");
                  }}
                >
                  Copy
                </button> */}
              </section>
            ) : (
              <Button
                style={{ width: "175px" }}
                loading={this.state.preparingLiveReport || this.props.portal.report.preparingLiveReport}
                disabled={this.props.portal.uiControls.loading_layout || this.state.preparingLiveReport}
                type="primary"
                ghost
                onClick={() => {
                  if (this.props.download_lock && this.state.role !== 15) {
                    this.props.dispatch(inputsActions.toggle_sf_modal("show_sf_modal", true, "ground_report"));
                  } else {
                    this.captureLiveReport();
                  }
                }}
              >
                {this.state.preparingLiveReport ? "Loading Report" : this.props.download_lock && this.state.role !== 15 ? "Unlock Download" : "Download Report"}
              </Button>
            )}

            {/* Default Report */}
            {/* {!this.props.portal.uiControls.show_live_report && this.state.reportPrepared && this.state.reportData && (
              <DownloadPDF data={this.state.reportData} handleDownloadReportReset={this.handleDownloadReportReset} />
            )} */}

            {/* {this.props.portal.selectedResult && !this.props.portal.uiControls.show_live_report && (
              <Button
                style={{ width: '85px' }}
                loading={this.props.portal.uiControls.loading_layout || this.state.preparingLiveReport}
                disabled={this.state.preparingLiveReport}
                type="primary"
                ghost
                onClick={() => {
                  this.handleGetRackData(this.props.portal.selectedRow);
                }}
              >
                PLOT
              </Button>
            )} */}
          </div>

          <div className="result-close-button">
            <Button
              disabled={this.state.preparingReport || this.state.preparingLiveReport}
              onClick={() => {
                if (this.props.portal.uiControls.show_live_report) {
                  this.props.dispatch(portalActions.updateUIControls("show_live_report", false));
                  this.handleDownloadReportReset();
                } else {
                  this.props.dispatch(portalActions.updateUIControls("show_results_table", false));
                }
              }}
            >
              <img src={close} alt="X with a circle around it" />
            </Button>
          </div>
        </TableButtonWrapper>

        {/* Live Report */}
        {this.state.reportDone && this.props.portal.uiControls.show_live_report && this.state.liveReportPrepared && this.props.portal.report.reportData && (
          <DownloadPDF data={this.props.portal.report.reportData} handleDownloadReportReset={this.handleDownloadReportReset} project_name={this.props.project_name} />
        )}

        {this.props.portal.uiControls.show_live_report && this.props.portal.report.reportData ? (
          <LiveReport
            preparingReport={this.props.portal.uiControls.loading_layout || this.props.portal.report.preparingReport}
            data={this.props.portal.report.reportData}
            setLiveReportMapRefs={this.setLiveReportMapRefs}
            captureGraphImage={this.captureGraphImage}
          />
        ) : (
          <ResultTable type="groundmount" />
        )}

        <textarea
          hidden
          ref="results"
          name="TextResults"
          cols="40"
          rows="50"
          value={this.props.portal.tsvResult}
          onChange={(e) => {
            console.log("Copied Results to clipboard");
          }}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { portal, projectManager, inputs } = state;
  const { selectedRow } = portal;
  const { project_name, download_lock, uiState } = inputs;
  const { download_type } = uiState;

  return {
    selectedRow,
    portal,
    projectManager,
    project_name,
    download_lock,
    download_type,
  };
}

const connectedStore = connect(mapStateToProps)(GroundMountResults);
export { connectedStore as GroundMountResults };
