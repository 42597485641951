const results = [
  {
    canopyName: 'Aisle 42',
    moduleName: 'Module 7',
    planeType: 'Single Slope',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '207.29',
  },
  {
    canopyName: 'Aisle 13',
    moduleName: 'Module 100',
    planeType: 'Inverted',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '165.07',
  },
  {
    canopyName: 'Aisle 13',
    moduleName: 'Module 100',
    planeType: 'Inverted',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '165.07',
  },
  {
    canopyName: 'Aisle 13',
    moduleName: 'Module 100',
    planeType: 'Inverted',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '165.07',
  },
  {
    canopyName: 'Aisle 13',
    moduleName: 'Module 100',
    planeType: 'Inverted',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '165.07',
  },
  {
    canopyName: 'Aisle 42',
    moduleName: 'Module 7',
    planeType: 'Single Slope',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '207.29',
  },
  {
    canopyName: 'Aisle 13',
    moduleName: 'Module 100',
    planeType: 'Inverted',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '165.07',
  },
  {
    canopyName: 'Aisle 13',
    moduleName: 'Module 100',
    planeType: 'Inverted',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '165.07',
  },
  {
    canopyName: 'Aisle 13',
    moduleName: 'Module 100',
    planeType: 'Inverted',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '165.07',
  },
  {
    canopyName: 'Aisle 13',
    moduleName: 'Module 100',
    planeType: 'Inverted',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '165.07',
  },
  {
    canopyName: 'Aisle 42',
    moduleName: 'Module 7',
    planeType: 'Single Slope',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '207.29',
  },
  {
    canopyName: 'Aisle 13',
    moduleName: 'Module 100',
    planeType: 'Inverted',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '165.07',
  },
  {
    canopyName: 'Aisle 13',
    moduleName: 'Module 100',
    planeType: 'Inverted',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '165.07',
  },
  {
    canopyName: 'Aisle 13',
    moduleName: 'Module 100',
    planeType: 'Inverted',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '165.07',
  },
  {
    canopyName: 'Aisle 13',
    moduleName: 'Module 100',
    planeType: 'Inverted',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '165.07',
  },
  {
    canopyName: 'Aisle 42',
    moduleName: 'Module 7',
    planeType: 'Single Slope',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '207.29',
  },
  {
    canopyName: 'Aisle 13',
    moduleName: 'Module 100',
    planeType: 'Inverted',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '165.07',
  },
  {
    canopyName: 'Aisle 13',
    moduleName: 'Module 100',
    planeType: 'Inverted',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '165.07',
  },
  {
    canopyName: 'Aisle 13',
    moduleName: 'Module 100',
    planeType: 'Inverted',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '165.07',
  },
  {
    canopyName: 'Aisle 13',
    moduleName: 'Module 100',
    planeType: 'Inverted',
    azimuth: 180,
    tilt: 5,
    towardAzimuth: 6,
    tiltToAzimuth: 7,
    tiltFromAzimuth: 8,
    moduleCount: 20,
    generation: 'X',
    yield: '165.07',
  },
];

export default results;
