import { portalConstants, userConstants } from "../constants";
import defaultProducts from "../../Sunfig/data/defaultProductData";
import defaultModules from "../../Sunfig/data/defaultModules";
import { getLengthSAT } from "../../../utils/SATautoSize";
import { getLengthGFT } from "../../../utils/GFTautoSize";
import { Terratrak_2P_SAT, Standard_GFT } from "../helpers/gmCalculators.js";
import { getBounds, getNewCenterPoint } from "../../../utils/map_helper.js";
import { getFixedResult } from "../../../utils/common";
import * as turf from "@turf/turf";
import { message } from "antd";
// import { alertConstants } from '../constants';
// import { handleAlert } from '../helpers'
// import L from 'leaflet';
import { convert_to_swm } from "../../Sunfig/data/swm_input_sheet";
import { map_to_1_8 } from "./portal_inputs";

const json2csv = require("json2csv").parse;
function create_UUID() {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

const getArea = (coords) => {
  let turfPoly = turf.polygon(coords);
  return turf.area(turfPoly) / 10000;
};

const defaultProject = {
  loaded: false,
  name: "",
  id: undefined,
};

// simple module input
const _defaultModule = JSON.parse(JSON.stringify(defaultModules[0]));
// RBI Solar - 2HP
const _defaultProduct = JSON.parse(JSON.stringify(defaultProducts[0]));

const _defaultLayout = {
  do_wiring: 0,
  wiring_dcac: 1.4,
  wiring_mod_rating: 400,
  wiring_modules_per_string: 28,
  wiring_inv_rating: 2000,
  wiring_spi: 200,
  wiring_max_row_length: 2,

  do_rack_align: 1,
  azimuth: 180,
  do_roads: 1,
  road_spacing: 300,
  road_spacing_option: 2,

  // off - fill - integer
  inverter_grouping: 0,
  // centroids or by_road
  combiner_location: 1,
  strings_per_combiner: 16,
  // center or split string
  wire_stringing: 0,
};

const _defaultPerformance = {
  simple_module: 1,

  latitude: undefined,
  longitude: undefined,
  elevation: undefined,
  timezone: undefined,
  gcr: undefined,

  // readonly
  mod_per_string: 28,
  // new user inputs
  dc_degrade: 0.5,
  dc_thermal_Uc: 29,
  dc_thermal_Uv: 0,
  dc_module_quality_loss: -1.0,
  dc_module_lid_loss: 1.5,
  dc_module_mismatch: 1.5,
  dc_strings_mismatch: 0.1,
  dc_wiring_loss_at_stc: 2.0,
  // hide if bifacial off
  bi_back_mismatch: 10.0,

  ac_aux_kw: 0,
  ac_wiring_loss_at_stc: 2.0,
  ac_transformer_loss_constant: 0,
  ac_transformer_loss_at_stc: 0,
  ac_MV_line_loss_stc: 0,
  ac_transmission_loss: 0,
  ac_other_loss: 0.0,
  simple_inverter_dcac: 1.3,

  do_monthly_losses: 0,
  soiling_single: 2.0,
  albedo_single: 0.2,
  soiling: [2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0],
  albedo: [0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2],

  // hidden
  ac_grid_poi_lim: 0,
  analysis_period: 1,
  simple_inverter: 1,

  // set by product/module selection
  track_mode: 1,
  tilts: [0],
  rlim: 60,
  sazm: 180,
  en_backtrack: 1,
  en_irradtrack: 0,
  shade_mode: 1,
  Fshd_CollWidth: 3.32,
  Fshd_StringSteps: 1,
  Fshd_Celltrav: 0.156,

  en_bifacial: 0,
  bi_groundClearanceHeight: 2.1,
  bi_transmissionFactor: 0,
  bi_structureShadeFactor: 0,
  bi_bifaciality: 0.7,
};

const initialState = {
  loading: true,
  project_loaded: false,
  Projects: [],
  selectedProject: -1,

  selectedResult: undefined,
  selectedRow: undefined,
  result_count: 0,
  isRunning: false,
  isGenerating: false,
  hasResults: false,
  results: [],
  run_state: {
    selectedResult: undefined,
    result_count: 0,
    isRunning: false,
    isGenerating: false,
    hasResults: false,
    results: [],
  },

  current_feature_index: 0,

  // UI STATE
  uiControls: {
    project_name: "",
    saving: false,
    modalMode: 0,
    bug_visible: false,
    bug_sending: false,
    bug_sent: false,
    bud_desc: "",
    filterText: "",
    loading: false,
    project_loading: false,
    isDeleting: false,
    isCreating: false,
    isDrawing: false,
    searchToolActive: false,
    disableEditing: false,
    currentDrawingPolygon: undefined,
    currentlyEditing: undefined,
    county: undefined,
    stateABB: undefined,
    projectLoaded: false,
    showProjectLoader: false,
    advancedOptions: false,
    canceling: false,
    map_ui_visible: true,
    show_results_table: false,
    show_live_report: false,
    offsetToolVisible: false,
    offsetLoading: false,
    tab: "simplified",
  },

  current_project: JSON.parse(JSON.stringify(defaultProject)),

  products: JSON.parse(JSON.stringify(defaultProducts)),
  // product_data: JSON.parse(JSON.stringify(defaultProducts)),
  modules: JSON.parse(JSON.stringify(defaultModules)),
  performance: [{ id: 0, name: "Custom Performance" }],

  csvResults: [],
  tsvResult: [],
  layout: {
    roads: {},
    inverter_groups: {},
    isLayout: false,
  },

  inputs: {
    features: {},
    selectedFeatureId: undefined,
    map_center: { lat: 39.188, lng: -84.47 },
    _map_center: { lat: 39.188, lng: -84.47 },
    map_zoom: 16,
    map_force_zoom: false,
    zoomGranularity: 0.25,

    boundary_bbox: undefined,
    mapBounds: undefined,
    center_point: undefined,

    // selectedProduct: undefined,
    // selectedModule: undefined,
    // selectedProductIndex: -1,
    // selectedModuleIndex: -1,
    selectedModuleIndex: 0,
    selectedModule: JSON.parse(JSON.stringify(_defaultModule)),
    selectedProductIndex: 0,
    selectedProduct: JSON.parse(JSON.stringify(_defaultProduct)),
    current_product: undefined,

    azimuth: 180,
    doRoads: true,
    doAlign: true,
    do_continuous: _defaultModule.continous == 1,
    doMonthlySoiling: 0,

    dc_lock_value: 0,
    do_dc_lock: false,
    dc_degredation: 0.5,

    doOverrideTilt: false,
    OverrideTilt: [10, 30], // [min, max]
    doOverrideGCR: false,
    OverrideGCR: [0.3, 0.7], // [min, max]

    doOverrideDims: false,
    overrideDims: [],

    // these get defined when a module is selected
    mod_width: undefined,
    mod_height: undefined,
    table_count: undefined,
    mod_string: 28,

    // TOPO Stuff. Also a TOPO Object below :116
    doTopo: false,
    grade_limit: _defaultProduct.grade_limit,
    // module_clearance_height: 1.5,
    // gap: 2,

    ele_generated_from: "raw",
    ele_use_graded_data: false,
    ns_grade_limit: 10,
    ns_grade_enabled: false,
    ns_grade_raw_enabled: false,
    ew_grade_limit: 20,
    ew_grade_enabled: false,
    ew_grade_raw_enabled: false,
    u_grade_limit: 15,
    u_grade_enabled: false,
    u_grade_raw_enabled: false,
    topo_action: "nothing",

    // polygon offset
    offsetDistance: 25,
    offsetPolygonKey: undefined,

    // doMonthlySoiling: 0,
    // 'soiling_monthly': [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
    // 'albedo_monthly': [0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2],

    losses: {
      soiling_loss: 2,
      mismatch_loss: 1,
      wiring_loss: 1.5,
      module_lid_loss: 2,
      module_quality_loss: 0,
      inverter_eff: 97,
      combined_ac_loss: 2,
      // dc_loss_stc: 1.5,
      // ac_losses: 4,
      // thermal_uc: 29,
      // thermal_uv: 2,
      // albedo: 0.2,
    },

    // defined when product selected (1.2 for SAT 1.4 for GFT)
    dcac: _defaultProduct.type == 1 ? 1.2 : 1.4,

    is_bifacial: false,
    bifaciality: 0.7,
    bifacial_ground_clearance_height: _defaultProduct.module_clearance_height,
    bifacial_transmission_factor: 0,
    bifacial_structure_shade_factor: 0,

    run_id: undefined,
    doCancel: false,

    mapOptions: -1,
    loading_racks: false,

    map_reset: false,
    map_resize: false,

    toggle_import: false,
    import_loading: false,

    //used for input flag
    inputsHaveChanged: false,

    performance: {
      simple_module: 1,

      latitude: undefined,
      longitude: undefined,
      elevation: undefined,
      timezone: undefined,
      gcr: undefined,

      // readonly
      mod_per_string: 28,
      // new user inputs
      dc_degrade: 0.5,
      dc_thermal_Uc: 29,
      dc_thermal_Uv: 0,
      dc_module_quality_loss: -1.0,
      dc_module_lid_loss: 1.5,
      dc_module_mismatch: 1.5,
      dc_strings_mismatch: 0.1,
      dc_wiring_loss_at_stc: 2.0,
      // hide if bifacial off
      bi_back_mismatch: 10.0,

      ac_aux_kw: 0,
      ac_wiring_loss_at_stc: 2.0,
      ac_transformer_loss_constant: 0,
      ac_transformer_loss_at_stc: 0,
      ac_MV_line_loss_stc: 0,
      ac_transmission_loss: 0,
      ac_other_loss: 0.0,
      simple_inverter_dcac: 1.3,

      do_monthly_losses: 0,
      soiling_single: 2.0,
      albedo_single: 0.2,
      soiling: [2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0],
      albedo: [0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2],

      // hidden
      ac_grid_poi_lim: 0,
      analysis_period: 1,
      simple_inverter: 1,

      // set by product/module selection
      track_mode: 1,
      tilts: [0],
      rlim: 60,
      sazm: 180,
      en_backtrack: 1,
      en_irradtrack: 0,
      shade_mode: 1,
      Fshd_CollWidth: 3.32,
      Fshd_StringSteps: 1,
      Fshd_Celltrav: 0.156,

      en_bifacial: 0,
      bi_groundClearanceHeight: 2.1,
      bi_transmissionFactor: 0,
      bi_structureShadeFactor: 0,
      bi_bifaciality: 0.7,
    },

    layout: {
      do_wiring: 0,
      wiring_dcac: 1.4,
      wiring_mod_rating: 400,
      wiring_modules_per_string: 28,
      wiring_inv_rating: 2000,
      wiring_spi: 200,
      wiring_max_row_length: 2,

      do_rack_align: 1,
      azimuth: 180,
      do_roads: 1,
      road_spacing: 300,
      road_spacing_option: 2,

      // off - fill - integer
      inverter_grouping: 0,
      // centroids or by_road
      combiner_location: 1,
      strings_per_combiner: 16,
      // center or split string
      wire_stringing: 0,
    },
  },

  // topo info
  topoData: {
    topo_id: undefined,
    topo_loading: false,
    topo_live: false,
    topo_url: undefined,
    topo_mode: "Off",
    topo_scale_url: undefined,
    do_generate_layers: false,
    generate_ele: false,
    generate_ns: false,
    generate_ew: false,
    generate_u: false,

    has_layers: false,

    layers_generated: {
      ele: { avail: false, gavail: false },
      NS: { avail: false, gavail: false, limit: 10 },
      EW: { avail: false, gavail: false, limit: 20 },
      U: { avail: false, gavail: false, limit: 15 },
      CF: { avail: false, limit: 10 },
    },

    // adding grading
    grading: {
      enabled: false,
      generated: false,
      ns_grade_target: 0,
      ew_grade_target: 0,
      grade_target_type: 0,
      grade_target: 0,
      cut_amt: 0,
      fill_amt: 0,
    },

    u_grade_input_disable: false,
    ns_grade_input_disable: false,
    ew_grade_input_disable: false,

    topo_action: "nothing", // flag, nothing
  },

  errors: [],

  // report
  report: {
    reportData: undefined,
    preparingReport: false,
    reportDone: false,
  },
};

export function portal(state = JSON.parse(JSON.stringify(initialState)), action) {
  let inputs = state.inputs;
  let topoData = state.topoData;
  let uiControls = state.uiControls;
  let current_project = state.current_project;
  let Projects = state.Projects;
  let layout = state.layout;

  switch (action.type) {
    case userConstants.TOGGLE_BUG_REPORT_VIS:
      return { ...state, uiControls: { ...uiControls, bug_visible: action.bool } };

    case portalConstants.GET_USER_DATA_REQUEST:
      return { ...state, uiControls: { ...initialState.uiControls, loading: true } };

    case portalConstants.GET_USER_DATA_SUCCESS:
      let projects = [];
      Object.values(action.data).map((proj) => {
        if (proj.project_type == 0) {
          let new_inputs = proj;
          // console.log(proj)
          new_inputs = map_to_1_8(proj);
          // console.log(new_inputs)

          projects.push(new_inputs);
        }
      });
      projects.sort(function(a, b) {
        return a.edit_dt - b.edit_dt;
      });

      // inputs.selectedModuleIndex = 0;
      // inputs.selectedModule = JSON.parse(JSON.stringify(defaultModules[0]));
      // inputs.selectedProductIndex = 0;
      // inputs.selectedProduct = JSON.parse(JSON.stringify(defaultProducts[0]));
      // inputs.mod_string = 28;
      // inputs.table_count = 10;

      // inputs.current_product = JSON.parse(JSON.stringify(state.products[inputs.selectedProductIndex].racks));
      // inputs.do_continuous = state.products[inputs.selectedProductIndex]["continous"] == 1;

      // inputs.doOverrideDims = false;
      // inputs.azimuth = 180;
      // inputs.clearance_height = inputs.selectedProduct["module_clearance_height"];
      // inputs.mod_string = 28;
      // inputs.dcac = inputs.selectedProduct.type == 1 ? 1.2 : 1.4;
      // inputs.grade_limit = inputs.selectedProduct["grade_limit"];
      // inputs.ns_grade_limit = 10;
      // inputs.ew_grade_limit = 20;

      // inputs.doRoads = true;
      // inputs.doAlign = true;
      // inputs.doOverrideTilt = false;
      // inputs.doOverrideGCR = false;

      // inputs.current_product.forEach((product, index) => {
      //   let Tracker_XY = Terratrak_2P_SAT(inputs.selectedModule.mlm_Width, inputs.selectedModule.mlm_Length, product.module, inputs.selectedModule.module_isbifacial == 1);
      //   product.xdim = Tracker_XY[0];
      //   product.ydim = Tracker_XY[1];
      // });

      // if (inputs.selectedProductIndex == 4) {
      //   inputs.doOverrideDims = true;
      //   inputs.doOverrideTilt = true;
      //   inputs.do_continuous = false;
      //   inputs.OverrideTilt[0] = 10;
      //   inputs.OverrideTilt[1] = 10;
      // }
      // inputs.mod_height = 0;
      // inputs.mod_width = 0;

      // inputs.mlm_D2MuTau = state.modules[1].mlm_D1MuTau;
      // inputs.mlm_E_g = state.modules[1].mlm_E_g;
      // inputs.mlm_I_mp_ref = state.modules[1].mlm_I_mp_ref;
      // inputs.mlm_I_sc_ref = state.modules[1].mlm_I_sc_ref;
      // inputs.mlm_N_diodes = state.modules[1].mlm_N_diodes;
      // inputs.mlm_N_parallel = state.modules[1].mlm_N_parallel;
      // inputs.mlm_N_series = state.modules[1].mlm_N_series;
      // inputs.mlm_R_s = state.modules[1].mlm_R_s;
      // inputs.mlm_R_sh0 = state.modules[1].mlm_R_sh0;
      // inputs.mlm_R_shexp = state.modules[1].mlm_R_shexp;
      // inputs.mlm_R_shref = state.modules[1].mlm_R_shref;
      // inputs.mlm_S_ref = state.modules[1].mlm_S_ref;
      // inputs.mlm_T_c_fa_alpha = state.modules[1].mlm_T_c_fa_alpha;
      // inputs.mlm_T_ref = state.modules[1].mlm_T_ref;
      // inputs.mlm_V_mp_ref = state.modules[1].mlm_V_mp_ref;
      // inputs.mlm_V_oc_ref = state.modules[1].mlm_V_oc_ref;
      // inputs.mlm_alpha_isc = state.modules[1].mlm_alpha_isc;
      // inputs.mlm_beta_voc_spec = state.modules[1].mlm_beta_voc_spec;
      // inputs.mlm_mu_n = state.modules[1].mlm_mu_n;
      // inputs.mlm_n_0 = state.modules[1].mlm_n_0;
      // inputs.module_area = state.modules[1].module_area;
      // inputs.module_bifaciality = state.modules[1].module_bifaciality;
      // inputs.module_iam_ang = state.modules[1].module_iam_ang;
      // inputs.module_iam_eff = state.modules[1].module_iam_eff;
      // inputs.module_isbifacial = state.modules[1].module_isbifacial;
      // inputs.muPmpReq = state.modules[1].muPmpReq;
      // inputs.name = state.modules[1].name;
      // inputs.rating = state.modules[1].rating;
      // inputs.technology = state.modules[1].technology;

      return {
        ...state,
        loading: false,
        // inputs: { ...state.inputs, inputs },
        // inputs,
        uiControls: { ...state.uiControls, project_loading: false, loading: false },
        Projects: projects,
      };

    case portalConstants.GET_USER_DATA_ERROR:
      return state;

    case portalConstants.INPUT_UPDATE_WIRING:
      // console.log(action.key, action.value)

      let local_layout = {
        ...state.inputs,
        layout: {
          ...state.inputs.layout,
          [action.key]: action.value,
        },
      };
      let dc_approx = local_layout.performance.dcac * local_layout.layout.wiring_inv_rating * 1000;

      // let one_string = local_layout.layout.wiring_modules_per_string * local_layout.layout.wiring_mod_rating
      let one_string = local_layout.performance.mods_per_string * inputs.selectedModule.rating;
      let calc_spi = _.round(dc_approx / one_string, 0);
      // console.log( dc_approx, one_string)
      // console.log(calc_spi)
      local_layout.layout.wiring_spi = calc_spi;

      if (action.key == "do_rack_align" && action.value == 0) {
        // turning rack align off
        if (inputs.selectedProduct.type == 1) {
          // turn roads off if rack align off for SAT
          local_layout.layout.do_roads = 0;
          local_layout.layout.road_spacing_option = 0;
        }
        // set combiner_locations to centroids
        local_layout.layout.combiner_location = 0;
      }

      if (action.key == "do_roads" && action.value == 0) {
        // set combiner_locations to centroids
        local_layout.layout.combiner_location = 0;
      }

      return {
        ...state,
        inputs: local_layout,
      };

    case portalConstants.INPUT_UPDATE_PERFORMANCE:
      let local_perf_update_inputs = {
        ...state.inputs,
        performance: {
          ...state.inputs.performance,
          [action.key]: action.value,
        },
      };

      return {
        ...state,
        inputs: local_perf_update_inputs,
      };

    case portalConstants.INPUT_UPDATE:
      const { products } = state;

      if (action.key == "module") {
        // this is only for groundmount
        inputs.selectedModule[action.value.key] = action.value.value;
        let update_dims = false;
        if (action.value.key == "rating" && inputs.selectedModuleIndex == 0) {
          update_dims = true;
          if (parseInt(action.value.value) <= 390) {
            inputs.selectedModule.mlm_Width = 0.992;
            inputs.selectedModule.mlm_Length = 1.956;
          } else if (parseInt(action.value.value) <= 430) {
            inputs.selectedModule.mlm_Width = 0.998;
            inputs.selectedModule.mlm_Length = 2.067;
          } else if (parseInt(action.value.value) <= 480) {
            inputs.selectedModule.mlm_Width = 1.048;
            inputs.selectedModule.mlm_Length = 2.128;
          } else {
            inputs.selectedModule.mlm_Width = 1.096;
            inputs.selectedModule.mlm_Length = 2.384;
          }
        }

        if (action.value.key == "mlm_Width" || action.value.key == "mlm_Length") {
          update_dims = true;
        }

        if (update_dims) {
          // we need to force update the product dimensions

          if (inputs.selectedProduct.type == 1) {
            inputs.current_product.forEach((product, index) => {
              let mod_count = inputs.mod_string * inputs.selectedProduct.string_counts[index];
              let Tracker_XY = Terratrak_2P_SAT(inputs.selectedModule.mlm_Width, inputs.selectedModule.mlm_Length, mod_count, inputs.selectedModule.module_isbifacial == 1);
              product.module = mod_count;
              product.xdim = Tracker_XY[0];
              product.ydim = Tracker_XY[1];
            });
          } else {
            // GFT
            inputs.current_product.forEach((product, index) => {
              let mod_count = product.module;
              if (inputs.selectedProduct.string_counts.length == 0) {
                // GLIDE products are LIMITED to 24 modules no matter what
                mod_count = 24;
              } else {
                mod_count = inputs.mod_string * inputs.selectedProduct.string_counts[index];
              }

              let Rack_XY = Standard_GFT(
                inputs.selectedModule.mlm_Width,
                inputs.selectedModule.mlm_Length,
                mod_count,
                inputs.selectedProduct.mods_high,
                inputs.selectedProduct.orientation,
                inputs.selectedProduct.vert_gap,
                inputs.selectedProduct.hori_gap
              );
              product.module = mod_count;
              product.xdim = Rack_XY[0];
              product.ydim = Rack_XY[1];
            });
          }
        }
      } else {
        inputs[action.key] = action.value;
      }
      // console.log(action.key, action.value)

      // used for inputs flag
      // check to see if any result have been generated already and if so flag inputsHaveChanged as true
      if (state.hasResults === true && action.key !== "do_advanced_specs") {
        inputs.inputsHaveChanged = true;
      }

      if (action.key == "features") {
        inputs.boundary_bbox = getBounds(action.value);
        state.current_feature_index += 1;
        if (action.value.length > 0) {
          let centers = [];
          for (var feat in action.value) {
            if (action.value[feat]["properties"]["center"]) {
              centers.push(turf.point(action.value[feat]["properties"]["center"]));
            } else {
              let centroid = turf.point(turf.getCoord(turf.centroid(action.value[feat])));
              centers.push(centroid);
            }
          }
          if (centers.length > 0) {
            var center_features = turf.featureCollection(centers);
            var new_center = turf.getCoord(turf.flip(turf.center(center_features)));
            inputs._map_center = new_center;
          }
          // let new_centroid = turf.centroid({ type: 'FeatureCollection', features: action.value });
          // let new_centroid = turf.centroid(turf.polygon(centers));
          // inputs._map_center = [new_centroid.geometry.coordinates[1], new_centroid.geometry.coordinates[0]];
        }
        // calculateTotalArea
        // calculate center?
        // do other stuff
      }

      if (action.key == "selectedProductIndex") {
        inputs.current_product = JSON.parse(JSON.stringify(products[action.value].racks));
        inputs.selectedProduct = JSON.parse(JSON.stringify(products[action.value]));
        inputs.do_continuous = inputs.selectedProduct["continous"] == 1;
        inputs.doOverrideDims = false;
        inputs.azimuth = 180;

        inputs.performance.sazm = 180;
        inputs.performance.track_mode = inputs.selectedProduct["type"];
        inputs.performance.tilts = inputs.selectedProduct["tilts"];
        inputs.performance.en_backtrack = inputs.selectedProduct["en_backtrack"];
        inputs.performance.en_irradtrack = inputs.selectedProduct["en_irradtrack"];
        inputs.performance.rlim = inputs.selectedProduct["rlim"];
        inputs.performance.shade_mode = inputs.selectedProduct["shade_mode"];
        inputs.performance.bi_groundClearanceHeight = inputs.selectedProduct["bi_groundClearanceHeight"];
        inputs.performance.bi_transmissionFactor = inputs.selectedProduct["bi_transmissionFactor"];
        inputs.performance.bi_structureShadeFactor = inputs.selectedProduct["bi_structureShadeFactor"];
        inputs.performance.mod_per_string = inputs.selectedProduct["mod_per_string"];
        inputs.performance.Fshd_CollWidth = inputs.selectedProduct["Fshd_CollWidth"];
        inputs.performance.Fshd_StringSteps = inputs.selectedProduct["Fshd_StringSteps"];
        inputs.performance.Fshd_Celltrav = inputs.selectedProduct["Fshd_Celltrav"];
        inputs.performance.dcac = action.value == 0 ? 1.2 : 1.4;

        // inputs.clearance_height = inputs.selectedProduct["module_clearance_height"];
        // inputs.mod_string = 28;
        // inputs.dcac = action.value == 0 ? 1.2 : 1.4;
        inputs.grade_limit = inputs.selectedProduct["grade_limit"];
        inputs.ns_grade_limit = 10;
        inputs.ew_grade_limit = 20;

        inputs.do_dc_lock = false;
        inputs.doRoads = true;
        inputs.doAlign = true;
        inputs.doOverrideTilt = false;
        inputs.doOverrideGCR = false;

        if (inputs.selectedProduct.type == 1) {
          inputs.current_product.forEach((product, index) => {
            let mod_count = inputs.mod_string * inputs.selectedProduct.string_counts[index];
            let Tracker_XY = Terratrak_2P_SAT(inputs.selectedModule.mlm_Width, inputs.selectedModule.mlm_Length, mod_count, inputs.selectedModule.module_isbifacial == 1);
            product.module = mod_count;
            product.xdim = Tracker_XY[0];
            product.ydim = Tracker_XY[1];
          });
        } else {
          // GFT
          inputs.current_product.forEach((product, index) => {
            let mod_count = product.module;
            if (inputs.selectedProduct.string_counts.length == 0) {
              // GLIDE products are LIMITED to 24 modules no matter what
              mod_count = 24;
            } else {
              mod_count = inputs.mod_string * inputs.selectedProduct.string_counts[index];
            }

            let Rack_XY = Standard_GFT(
              inputs.selectedModule.mlm_Width,
              inputs.selectedModule.mlm_Length,
              mod_count,
              inputs.selectedProduct.mods_high,
              inputs.selectedProduct.orientation,
              inputs.selectedProduct.vert_gap,
              inputs.selectedProduct.hori_gap
            );
            product.module = mod_count;
            product.xdim = Rack_XY[0];
            product.ydim = Rack_XY[1];
          });
        }
      }

      if (action.key == "selectedModuleIndex") {
        inputs.selectedModule = JSON.parse(JSON.stringify(state.modules[action.value]));

        // we need to force update the product dimensions
        if (!inputs.doOverrideDims) {
          if (inputs.selectedProduct.type == 1) {
            inputs.current_product.forEach((product, index) => {
              let mod_count = inputs.mod_string * inputs.selectedProduct.string_counts[index];
              let Tracker_XY = Terratrak_2P_SAT(inputs.selectedModule.mlm_Width, inputs.selectedModule.mlm_Length, mod_count, inputs.selectedModule.module_isbifacial == 1);
              product.module = mod_count;
              product.xdim = Tracker_XY[0];
              product.ydim = Tracker_XY[1];
            });
          } else {
            // GFT
            inputs.current_product.forEach((product, index) => {
              let mod_count = product.module;
              if (inputs.selectedProduct.string_counts.length == 0) {
                // GLIDE products are LIMITED to 24 modules no matter what
                mod_count = 24;
              } else {
                mod_count = inputs.mod_string * inputs.selectedProduct.string_counts[index];
              }

              let Rack_XY = Standard_GFT(
                inputs.selectedModule.mlm_Width,
                inputs.selectedModule.mlm_Length,
                mod_count,
                inputs.selectedProduct.mods_high,
                inputs.selectedProduct.orientation,
                inputs.selectedProduct.vert_gap,
                inputs.selectedProduct.hori_gap
              );
              product.module = mod_count;
              product.xdim = Rack_XY[0];
              product.ydim = Rack_XY[1];
            });
          }
        }
      }

      if (action.key == "mod_string") {
        // we need to calculate the modules
        if (inputs.selectedProduct.type == 1) {
          inputs.current_product.forEach((product, index) => {
            let mod_count = action.value * inputs.selectedProduct.string_counts[index];
            let Tracker_XY = Terratrak_2P_SAT(inputs.selectedModule.mlm_Width, inputs.selectedModule.mlm_Length, mod_count, inputs.selectedModule.module_isbifacial == 1);
            product.module = mod_count;
            product.xdim = Tracker_XY[0];
            product.ydim = Tracker_XY[1];
          });
        } else {
          // GFT
          inputs.current_product.forEach((product, index) => {
            let mod_count = product.module;
            if (inputs.selectedProduct.string_counts.length == 0) {
              // GLIDE products are LIMITED to 24 modules no matter what
              mod_count = 24;
            } else {
              mod_count = action.value * inputs.selectedProduct.string_counts[index];
            }

            let Rack_XY = Standard_GFT(
              inputs.selectedModule.mlm_Width,
              inputs.selectedModule.mlm_Length,
              mod_count,
              inputs.selectedProduct.mods_high,
              inputs.selectedProduct.orientation,
              inputs.selectedProduct.vert_gap,
              inputs.selectedProduct.hori_gap
            );
            product.module = mod_count;
            product.xdim = Rack_XY[0];
            product.ydim = Rack_XY[1];
          });
        }
      }

      if (action.key == "grade_limit") {
        inputs.grade_limit = action.value;
        // inputs.ns_grade_limit = inputs.selectedProduct.type == 1 ? action.value : inputs.ns_grade_limit;
        // inputs.ew_grade_limit = inputs.selectedProduct.type == 0 ? action.value : inputs.ew_grade_limit;
      }

      // ***** Product Tab *****

      if (action.key == "doAlign") {
        inputs.doRoads = inputs.selectedProduct.type == 1 && !action.value ? false : inputs.doRoads;
      }

      if (action.key == "do_dc_lock" && !action.value) {
        inputs.dc_lock_value = 0;
      }
      if (action.key == "do_dc_lock" && action.value) {
        inputs.do_continuous = false;
      }

      if (action.key == "changeMinTilt") {
        inputs.OverrideTilt[0] = action.value;
      }

      if (action.key == "changeMaxTilt") {
        inputs.OverrideTilt[1] = action.value;
      }

      if (action.key == "changeMinGCR") {
        if (!isNaN(parseFloat(action.value))) {
          inputs.OverrideGCR[0] = parseFloat(action.value);
        } else {
          inputs.OverrideGCR[0] = action.value;
        }
      }

      if (action.key == "changeMaxGCR") {
        if (!isNaN(parseFloat(action.value))) {
          inputs.OverrideGCR[1] = parseFloat(action.value);
        } else {
          inputs.OverrideGCR[1] = action.value;
        }
      }

      if (action.key == "setSoilingLosses") {
        inputs.losses[action.value.key] = action.value.payload;
      }

      if (action.key == "adjustTopo_ns_grade_limit") {
        inputs.ns_grade_limit = action.value;
      }

      if (action.key == "adjustTopo_ew_grade_limit") {
        inputs.ew_grade_limit = action.value;
      }

      if (action.key == "adjustTopo_u_grade_limit") {
        inputs.u_grade_limit = action.value;
      }

      if (action.key == "updateOverrideDimsCheckBox") {
        inputs.current_product[action.value].active = inputs.current_product[action.value].active == 0 ? 1 : 0;
      }

      if (action.key == "do_continuous" && action.value) {
        inputs.do_dc_lock = false;
        inputs.dc_lock_value = 0;
      }

      if (action.key == "doOverrideDims" && !action.value) {
        if (inputs.selectedProduct.type == 1) {
          inputs.current_product.forEach((product, index) => {
            let mod_count = inputs.mod_string * inputs.selectedProduct.string_counts[index];
            let Tracker_XY = Terratrak_2P_SAT(inputs.selectedModule.mlm_Width, inputs.selectedModule.mlm_Length, mod_count, inputs.selectedModule.module_isbifacial == 1);
            product.module = mod_count;
            product.xdim = Tracker_XY[0];
            product.ydim = Tracker_XY[1];
            product.active = 1;
          });
        } else {
          // GFT
          inputs.current_product.forEach((product, index) => {
            let mod_count = product.module;
            if (inputs.selectedProduct.string_counts.length == 0) {
              // GLIDE products are LIMITED to 24 modules no matter what
              mod_count = 24;
            } else {
              mod_count = inputs.mod_string * inputs.selectedProduct.string_counts[index];
            }

            let Rack_XY = Standard_GFT(
              inputs.selectedModule.mlm_Width,
              inputs.selectedModule.mlm_Length,
              mod_count,
              inputs.selectedProduct.mods_high,
              inputs.selectedProduct.orientation,
              inputs.selectedProduct.vert_gap,
              inputs.selectedProduct.hori_gap
            );
            product.module = mod_count;
            product.xdim = Rack_XY[0];
            product.ydim = Rack_XY[1];
            product.active = 1;
          });
        }
      }
      // comes from Dimension Override table
      if (action.key == "setOverrideXDims") {
        if (inputs.selectedProduct.type == 1) {
          //
          inputs.current_product.forEach((product) => {
            product.xdim = action.value.dim;
          });
        } else {
          // GFT
          inputs.current_product[action.value.index].xdim = action.value.dim;
        }
      }
      if (action.key == "setOverrideYDims") {
        if (inputs.selectedProduct.type == 1) {
          inputs.current_product[action.value.index].ydim = action.value.dim;
        } else {
          // GFT
          inputs.current_product.forEach((product) => {
            product.ydim = action.value.dim;
          });
        }
      }

      if (action.key == "setYDimensions") {
        inputs.current_product.forEach((product, index) => {
          let Tracker_XY = Terratrak_2P_SAT(inputs.selectedModule.mlm_Width, inputs.selectedModule.mlm_Length, product.module, inputs.selectedModule.module_isbifacial == 1);
          product.xdim = Tracker_XY[0];
          product.ydim = Tracker_XY[1];
        });
      }

      if (action.key == "setXDimensions") {
        inputs.current_product.forEach((product, index) => {
          let mod_count = product.module;
          if (inputs.selectedProduct.string_counts.length == 0) {
            // GLIDE products are LIMITED to 24 modules no matter what
            mod_count = 24;
          }
          let Rack_XY = Standard_GFT(
            inputs.selectedModule.mlm_Width,
            inputs.selectedModule.mlm_Length,
            mod_count,
            inputs.selectedProduct.mods_high,
            inputs.selectedProduct.orientation,
            inputs.selectedProduct.vert_gap,
            inputs.selectedProduct.hori_gap
          );

          product.xdim = Rack_XY[0];
          product.ydim = Rack_XY[1];
        });

        // let newDims = getLengthGFT(inputs.selectedProductIndex, inputs.selectedModule.mlm_Width, inputs.selectedModule.mlm_Length, inputs.mod_string);
        // inputs.current_product[0].xdim = newDims['A'][0];
        // inputs.current_product[0].ydim = newDims['A'][1];
        // inputs.current_product[0].module = newDims['A'][2];

        // inputs.current_product[1].xdim = newDims['B'][0];
        // inputs.current_product[1].ydim = newDims['B'][1];
        // inputs.current_product[1].module = newDims['B'][2];

        // inputs.current_product[2].xdim = newDims['C'][0];
        // inputs.current_product[2].ydim = newDims['C'][1];
        // inputs.current_product[2].module = newDims['C'][2];
      }

      if (action.key == "setOverrideModules") {
        inputs.current_product[action.value.index].module = action.value.module;
      }

      if (action.key == "u_grade_enabled" || action.key == "u_grade_raw_enabled") {
        if (action.key == "u_grade_enabled" && action.value && !topoData.layers_generated.U.avail) {
          inputs.u_grade_raw_enabled = false;
        }
        if (action.key == "u_grade_raw_enabled" && action.value && !topoData.layers_generated.U.gavail) {
          inputs.u_grade_enabled = false;
        }
      }

      if (action.key == "ns_grade_enabled" || action.key == "ns_grade_raw_enabled") {
        if (action.key == "ns_grade_enabled" && action.value && !topoData.layers_generated.NS.avail) {
          inputs.ns_grade_raw_enabled = false;
        }
        if (action.key == "ns_grade_raw_enabled" && action.value && !topoData.layers_generated.NS.gavail) {
          inputs.ns_grade_enabled = false;
        }
      }
      if (action.key == "ew_grade_enabled" || action.key == "ew_grade_raw_enabled") {
        if (action.key == "ew_grade_enabled" && action.value && !topoData.layers_generated.EW.avail) {
          inputs.ew_grade_raw_enabled = false;
        }
        if (action.key == "ew_grade_raw_enabled" && action.value && !topoData.layers_generated.EW.gavail) {
          inputs.ew_grade_enabled = false;
        }
      }

      if (action.key == "clearTopoData") {
        topoData.topo_id = undefined;
        topoData.topo_url = "";
        topoData.topo_scale_url = "";
        topoData.topo_live = false;
        topoData.topo_layers_live = false;
        topoData.layers_generated.ele.avail = false;
        topoData.layers_generated.NS.avail = false;
        topoData.layers_generated.NS.limit = 10;
        topoData.layers_generated.EW.avail = false;
        topoData.layers_generated.EW.limit = 20;
        topoData.layers_generated.U.avail = false;
        topoData.layers_generated.U.limit = 15;
        topoData.has_layers = false;

        inputs.ele_use_graded_data = false;
        inputs.u_grade_limit = 15;
        inputs.u_grade_enabled = false;
        inputs.u_grade_raw_enabled = false;
        inputs.ns_grade_limit = 10;
        inputs.ns_grade_enabled = false;
        inputs.ns_grade_raw_enabled = false;
        inputs.ew_grade_limit = 20;
        inputs.ew_grade_enabled = false;
        inputs.ew_grade_raw_enabled = false;
        inputs.topo_action = "nothing";

        topoData.grading.enabled = false;
        topoData.grading.generated = false;
        topoData.grading.cut_amt = 0;
        topoData.grading.fill_amt = 0;

        topoData.u_grade_input_disable = false;
        topoData.ns_grade_input_disable = false;
        topoData.ew_grade_input_disable = false;

        topoData.topo_mode = "Off";
      }

      if (action.key == "clearLayers") {
        topoData.topo_url = "";
        topoData.topo_scale_url = "";

        topoData.layers_generated.ele.gavail = false;
        topoData.layers_generated.NS.avail = false;
        topoData.layers_generated.NS.gavail = false;
        topoData.layers_generated.NS.limit = 10;
        topoData.layers_generated.EW.avail = false;
        topoData.layers_generated.EW.gavail = false;
        topoData.layers_generated.EW.limit = 20;
        topoData.layers_generated.U.avail = false;
        topoData.layers_generated.U.gavail = false;
        topoData.layers_generated.U.limit = 15;
        topoData.has_layers = false;
        topoData.topo_mode = "Off";

        topoData.u_grade_input_disable = false;
        topoData.ns_grade_inputs_disable = false;
        topoData.ew_grade_input_disable = false;

        inputs.ele_use_graded_data = false;
        inputs.u_grade_limit = 15;
        inputs.u_grade_enabled = false;
        inputs.u_grade_raw_enabled = false;
        inputs.ns_grade_limit = 10;
        inputs.ns_grade_enabled = false;
        inputs.ns_grade_raw_enabled = false;
        inputs.ew_grade_limit = 20;
        inputs.ew_grade_enabled = false;
        inputs.ew_grade_raw_enabled = false;
        inputs.topo_action = "nothing";
      }

      if (action.key == "topoData") {
        if (action.value.key == "topo_action") {
          topoData.topo_action == action.value.payload;
        } else {
          let topo_cat = action.value.key.split(".")[0];
          let cat_key = action.value.key.split(".")[1];
          topoData[topo_cat][cat_key] = action.value.payload;
        }
      }

      // console.log(inputs.current_product)

      return {
        ...state,
        inputs: { ...inputs },
        topoData: { ...topoData },
      };

    // *** PULL TOPOGRAPHY REDUCERS ***
    case portalConstants.GET_TOPO_DATA_REQUEST:
      topoData.layers_generated.NS.limit = action.inputs.grade_limits.ns_grade_limit;
      topoData.layers_generated.EW.limit = action.inputs.grade_limits.ew_grade_limit;
      topoData.layers_generated.U.limit = action.inputs.grade_limits.u_grade_limit;
      topoData.topo_error = undefined;

      return {
        ...state,
        topoData: { ...topoData, topo_loading: true },
      };

    case portalConstants.GET_TOPO_DATA_SUCCESS:
      // let _mode = inputs.selectedProductIndex == 0 ? 'NS' : 'EW';
      // let _mode_ext = `${_mode}/${topoData.layers_generated[_mode].limit}`;
      // topoData.layers_generated.ele.avail = true;
      // topoData.layers_generated.NS.avail = true;
      // topoData.layers_generated.EW.avail = true;
      // topoData.layers_generated.U.avail = true;
      // return {
      //   ...state,
      //   topoData: {
      //     ...topoData,
      //     topo_id: action.data.topo_id,
      //     topo_loading: false,
      //     topo_live: true,
      //     topo_mode: _mode,
      //     // topo_url: `https://topo.xyz.s3-website.us-east-2.amazonaws.com/test/${action.data.topo_id}/${_mode_ext}/{z}/{x}/{y}.png`,
      //     // topo_scale_url: `https://topo.xyz.s3-website.us-east-2.amazonaws.com/test/${action.data.topo_id}/${_mode_ext}/scale.png`,
      //     topo_url: `https://s3.us-east-2.amazonaws.com/topo.xyz/test/${action.data.topo_id}/${_mode_ext}/{z}/{x}/{y}.png`,
      //     topo_scale_url: `https://s3.us-east-2.amazonaws.com/topo.xyz/test/${action.data.topo_id}/${_mode_ext}/scale.png`,
      //   },
      // };

      // console.log(action)
      topoData.topo_live = true;
      topoData.topo_loading = false;
      if (action.data.topo_id !== undefined) topoData.topo_id = action.data.topo_id;
      let ele_generated_from = inputs.ele_generated_from;

      if (action.action == "pull_ele") {
        if (action.data.error) {
          topoData.topo_live = false;
          topoData.topo_error = action.data.error.msg;
        } else {
          topoData.topo_error = undefined;
        }

        topoData.topo_mode = "ele";
        topoData.topo_url = `https://topo-tiles.sunfig.com/test/${topoData.topo_id}/ele/{z}/{x}/{y}.png`;
        topoData.topo_scale_url = `https://topo-tiles.sunfig.com/test/${topoData.topo_id}/ele/scale.png`;
        // topoData.topo_url = `https://s3.us-east-2.amazonaws.com/topo.xyz/test/${topoData.topo_id}/ele/{z}/{x}/{y}.png`;
        // topoData.topo_scale_url = `https://s3.us-east-2.amazonaws.com/topo.xyz/test/${topoData.topo_id}/ele/scale.png`;
      }
      if (action.action == "calc_grade") {
        if (action.data.error) {
          topoData.topo_error = action.data.error.msg;
        } else {
          topoData.topo_error = undefined;

          topoData.topo_mode = "CF";

          topoData.layers_generated.CF.avail = true;
          topoData.layers_generated.CF.limit = topoData.grading.grade_target;
          topoData.grading.generated = true;
          topoData.grading.cut_amt = action.data.output.cut_sum;
          topoData.grading.fill_amt = action.data.output.fill_sum;
          let _mode_ext = `${topoData.topo_mode}/${topoData.layers_generated[topoData.topo_mode].limit}`;
          topoData.topo_url = `https://topo-tiles.sunfig.com/test/${topoData.topo_id}/${_mode_ext}/{z}/{x}/{y}.png`;
          topoData.topo_scale_url = `https://topo-tiles.sunfig.com/test/${topoData.topo_id}/${_mode_ext}/scale.png`;
          // topoData.topo_url = `https://s3.us-east-2.amazonaws.com/topo.xyz/test/${topoData.topo_id}/${_mode_ext}/{z}/{x}/{y}.png`;
          // topoData.topo_scale_url = `https://s3.us-east-2.amazonaws.com/topo.xyz/test/${topoData.topo_id}/${_mode_ext}/scale.png`;
        }
      }
      if (action.action == "gen_layers") {
        // console.log(action)
        if (action.data.error) {
          topoData.topo_error = action.data.error.msg;
          // console.log(action.data.error.msg)
          // console.log(topoData)
        } else {
          topoData.topo_error = undefined;
          if (inputs.u_grade_enabled || inputs.u_grade_raw_enabled) {
            topoData.u_grade_input_disable = true;
          } else {
            topoData.u_grade_input_disable = false;
          }
          if (inputs.ns_grade_enabled || inputs.ns_grade_raw_enabled) {
            topoData.ns_grade_inputs_disable = true;
          } else {
            topoData.ns_grade_inputs_disable = true;
          }
          if (inputs.ew_grade_enabled || inputs.ew_grade_raw_enabled) {
            topoData.ew_grade_input_disable = true;
          } else {
            topoData.ew_grade_input_disable = false;
          }

          topoData.has_layers = true;

          let lyrs = action.inputs.generate_layers;
          // layer visibility
          topoData.layers_generated.ele.gavail = lyrs.generate_ele[1] || inputs.ele_use_graded_data;

          topoData.layers_generated.NS.avail = topoData.layers_generated.NS.avail || lyrs.generate_ns[0];
          topoData.layers_generated.EW.avail = topoData.layers_generated.EW.avail || lyrs.generate_ew[0];
          topoData.layers_generated.U.avail = topoData.layers_generated.U.avail || lyrs.generate_u[0];
          topoData.layers_generated.NS.gavail = topoData.layers_generated.NS.gavail || lyrs.generate_ns[1];
          topoData.layers_generated.EW.gavail = topoData.layers_generated.EW.gavail || lyrs.generate_ew[1];
          topoData.layers_generated.U.gavail = topoData.layers_generated.U.gavail || lyrs.generate_u[1];

          topoData.layers_generated.NS.limit = inputs.ns_grade_limit;
          topoData.layers_generated.EW.limit = inputs.ew_grade_limit;
          topoData.layers_generated.U.limit = inputs.u_grade_limit;

          inputs.ele_use_graded_data = false;
          inputs.u_grade_enabled = false;
          inputs.u_grade_raw_enabled = false;
          inputs.ns_grade_enabled = false;
          inputs.ns_grade_raw_enabled = false;
          inputs.ew_grade_enabled = false;
          inputs.ew_grade_raw_enabled = false;

          let def_layer = inputs.selectedProduct.type == 1 ? "NS" : "EW";

          if (topoData.grading.generated && topoData.layers_generated[def_layer].gavail) {
            // switch it to the graded layers
            topoData.topo_mode = `${def_layer}/G`;
          }

          let _mode_ext = `${topoData.topo_mode}/${topoData.layers_generated[def_layer].limit}`;
          topoData.topo_url = `https://topo-tiles.sunfig.com/test/${topoData.topo_id}/${_mode_ext}/{z}/{x}/{y}.png`;
          topoData.topo_scale_url = `https://topo-tiles.sunfig.com/test/${topoData.topo_id}/${_mode_ext}/scale.png`;
          // topoData.topo_url = `https://s3.us-east-2.amazonaws.com/topo.xyz/test/${topoData.topo_id}/${_mode_ext}/{z}/{x}/{y}.png`;
          // topoData.topo_scale_url = `https://s3.us-east-2.amazonaws.com/topo.xyz/test/${topoData.topo_id}/${_mode_ext}/scale.png`;
        }
      }

      return {
        ...state,
        inputs: { ...inputs, ele_generated_from: ele_generated_from },
        topoData: { ...topoData },
      };

    case portalConstants.GET_TOPO_DATA_ERROR:
      // console.log(action);
      return {
        ...state,
        topoData: { ...topoData, topo_loading: false },
        error: [...action.error],
      };

    case portalConstants.UPDATE_TOPO_MODE:
      return {
        ...state,
        topoData: {
          ...topoData,
          topo_mode: action.data.topo_mode,
          topo_url: action.data.topo_url,
          topo_scale_url: action.data.topo_scale_url,
        },
      };

    case portalConstants.UPDATE_MAP_CENTER:
      inputs.map_center = action.center;
      inputs._map_center = action.center;
      uiControls.searchToolActive = false;
      return {
        ...state,
        inputs: { ...inputs },
        uiControls: { ...uiControls },
      };
    case portalConstants.UPDATE_MAP_ZOOM:
      return {
        ...state,
        inputs: { ...inputs, map_zoom: action.zoom },
      };

    case portalConstants.INIT_RESULTS_REQUEST:
      // set isRunning true

      state.isRunning = true;
      // reset previous results if any
      state.isGenerating = false;
      state.hasResults = false; // this controls if should show results table
      inputs.doCancel = false;
      state.result_count = 0; // this controls loading bar
      inputs.isAutolayout = false; // This turns off the autolayout results panel in map
      inputs.run_id = undefined; // used to track the progress of the in progress run also used to cancel
      // prevent live report from launching if you happen to generate a new dataset while live report is active
      if (uiControls.show_live_report) {
        uiControls.show_live_report = false;
      }

      return {
        ...state,
        inputs,
        uiControls: { ...uiControls },
        layout: { inverter_groups: {}, isLayout: false },
        selectedResult: undefined,
      };

    case portalConstants.INIT_RESULTS_ERROR:
      // handleAlert({
      //   type: alertConstants.ERROR,
      //   message: action.error
      // })
      message.error(action.error, 3);
      state.isRunning = false;
      return {
        ...state,
      };

    case portalConstants.CANCEL_RUN_REQUEST:
      uiControls.canceling = true;
      return {
        ...state,
        uiControls,
      };

    case portalConstants.INIT_RESULTS_SUCCESS:
      // set analysisId // rename to run_id
      inputs.run_id = action.data.run_id;

      return { ...state, inputs };

    case portalConstants.GENERATE_RESULTS_REQUEST:
      // set isGenerating to true
      state.results = []; // this is the content of the results table
      state.isGenerating = true;
      inputs.rackPolygons = []; // this is for any drawings user has live
      inputs.mapOptions = 0; // This sets the map toolbar to nothing

      // state.inputs.analysisId = action.data.runId;

      return {
        ...state,
        inputs,
      };

    case portalConstants.GENERATE_RESULTS_FAILURE:
      // handleAlert({
      //   type: alertConstants.ERROR,
      //   message: action.error
      // })
      // console.log(action)
      message.error(action.data.error.msg, 3);
      // CANCEL BY USER
      inputs.run_id = undefined;
      inputs.resultCount = 0; // this controls loading bar
      state.isRunning = false;
      state.isGenerating = false;
      state.hasResults = false; // this controls if should show results table
      uiControls.canceling = false;

      return {
        ...state,
        inputs,
        uiControls,
      };

    case portalConstants.GENERATE_RESULTS_UPDATE:
      // not done, but have percent complete from backend
      state.result_count = action.complete;
      return {
        ...state,
        inputs,
      };

    case portalConstants.GENERATE_RESULTS_SUCCESS:
      // console.log("GENERATE_RESULTS_SUCCESS action", action);
      state.result_count = action.data.complete; // Finished 100%
      state.isRunning = false;
      inputs.inputsHaveChanged = false;

      state.isGenerating = false;
      state.hasResults = true;
      uiControls.show_results_table = true;
      // console.log("action", action.data.results);

      let j_layout = action.data.layout;

      let j_inputs = action.data.meta;

      let results = [];
      action.data.results.forEach((result) => {
        let resJson = result.results_json;
        let pitch;
        let r2r;
        if (resJson.meta.track_mode == 0) {
          pitch = _.round(resJson.meta.racks[0].ydim / resJson.meta.gcr, 2);
          r2r = resJson.meta.racks[0].ydim / resJson.meta.gcr - resJson.meta.racks[0].ydim * Math.cos(resJson.meta.tilt * (Math.PI / 180));
        } else {
          pitch = _.round(resJson.meta.racks[0].xdim / resJson.meta.gcr, 2);
          r2r = resJson.meta.racks[0].xdim / resJson.meta.gcr - resJson.meta.racks[0].xdim;
        }
        let fixed_result = {
          id: result.id,
          table_a: resJson.meta.rack_breakdown[0],
          table_b: resJson.meta.rack_breakdown[1],
          table_c: resJson.meta.rack_breakdown[2],
          capacity: (resJson.meta.capacity / 1000).toFixed(2),

          // racking_name: resJson.meta.racking_name,
          // module_name: resJson.meta.module_name.replace("Module", ""),

          wire_length_ft: _.round(resJson.meta.combined_wire_length_ft, 4),
          combiner_count: resJson.meta.combined_combiner_count,

          ...resJson.meta,
          // ...resJson.config,
          ...resJson.report,
          ...resJson.lstack,

          // inputs that aren't being calculated the same way but need to be updated
          // both below i believe are in the lstack object named something wonky
          // YIELD: 0,
          generation: _.round(resJson.lstack["eh. Available Energy at Inverter Output (MWh)"], 0),

          // below three were being fed from old performance, need to update calcs in new performance
          // tilit: 0,
          rtr: r2r,
          pitch: pitch,
        };

        // console.log("fixed_result", fixed_result);
        results.push(fixed_result);
      });

      state.results = results;

      let csvObj = [];

      for (let result in state.results) {
        let obj;
        if (inputs.selectedProduct.type == 1) {
          obj = {
            Racking: state.results[result]["racking_name"],
            Module: state.results[result]["mod_rating"],
            Azimuth: state.results[result]["sazm"],
            GCR: state.results[result]["gcr"],
            IntraRow: state.results[result]["rtr"],
            Pitch: state.results[result]["pitch"],
            Tilt: state.results[result]["tilt"],
            "Yield (kWh/kWp)": parseInt(state.results[result]["y. Yield (kWh/kWp)"]),
            "Generation Yr 1 (kWh)": parseInt(state.results[result]["fh. Energy injected into Grid (MWh)"]),
            Capacity: state.results[result]["capacity"] * 1000,
            "Module Qty": state.results[result]["module_count"],
            "Table Qty-A": state.results[result]["table_a"],
            "Table Qty-B": state.results[result]["table_b"],
            "Table Qty-C": state.results[result]["table_c"],
            "Table Qty-D": state.results[result]["table_d"],

            "Wire Length (ft)": j_inputs.do_wiring == 1 ? state.results[result]["wire_length_ft"] : "n/a",
            "Combiner Box Count": j_inputs.do_wiring == 1 ? state.results[result]["combiner_count"] : "n/a",
          };
        } else {
          obj = {
            Racking: state.results[result]["racking_name"],
            Module: state.results[result]["mod_rating"],
            Azimuth: state.results[result]["sazm"],
            GCR: state.results[result]["gcr"],
            IntraRow: state.results[result]["rtr"],
            Pitch: state.results[result]["pitch"],
            Tilt: state.results[result]["tilt"],
            "Yield (kWh/kWp)": parseInt(state.results[result]["y. Yield (kWh/kWp)"]),
            "Generation Yr 1 (kWh)": parseInt(state.results[result]["fh. Energy injected into Grid (MWh)"]),
            Capacity: state.results[result]["capacity"] * 1000,
            "Module Qty": state.results[result]["module_count"],
            "Table Qty": state.results[result]["rack_count"],
            "Table Breakdown": state.results[result]["rack_breakdown"],
            "Table Qty-A": state.results[result]["table_a"],
            "Table Qty-B": state.results[result]["table_b"],
            "Table Qty-C": state.results[result]["table_c"],

            "Wire Length (ft)": j_inputs.do_wiring == 1 ? state.results[result]["wire_length_ft"] : "n/a",
            "Combiner Box Count": j_inputs.do_wiring == 1 ? state.results[result]["combiner_count"] : "n/a",
          };
        }
        csvObj.push(obj);
      }

      let fields;
      let _selectedResult;
      let _selectedRow;

      if (state.results.length > 0) {
        var indexOfLowestGCR = 0;
        var tempValue = state.results[0].gcr;
        for (let i = 1; i < state.results.length; i++) {
          if (state.results[i].gcr < tempValue) {
            tempValue = state.results[i].gcr;
            indexOfLowestGCR = i;
          }
        }
        // let found_index = state.results.findIndex((res) => res.gcr == 0.3);

        if (indexOfLowestGCR >= 0) {
          _selectedResult = getFixedResult(state.results[indexOfLowestGCR]);
          _selectedRow = state.results[indexOfLowestGCR];
        }
        if (inputs.selectedProduct.type == 1) {
          fields = [
            "Racking",
            "Module",
            "GCR",
            "Azimuth",
            "IntraRow",
            "Pitch",
            "Tilt",
            "Yield (kWh/kWp)",
            "Generation Yr 1 (kWh)",
            "Capacity",
            "Module Qty",
            "Table Qty-A",
            "Table Qty-B",
            "Table Qty-C",
            "Wire Length (ft)",
            "Combiner Box Count",
          ];
        } else {
          fields = [
            "Racking",
            "Module",
            "GCR",
            "Azimuth",
            "IntraRow",
            "Pitch",
            "Tilt",
            "Yield (kWh/kWp)",
            "Generation Yr 1 (kWh)",
            "Capacity",
            "Module Qty",
            "Table Qty-A",
            "Table Qty-B",
            "Table Qty-C",
            "Wire Length (ft)",
            "Combiner Box Count",
          ];
        }
      }

      let copy_opts = { fields, delimiter: "\t", eol: "", header: true, withBOM: false };
      let csv_opts = { fields, delimiter: ",", eol: "", header: true, withBOM: false };

      return {
        ...state,
        inputs,
        uiControls,
        csvResults: json2csv(csvObj, csv_opts),
        tsvResult: json2csv(csvObj, copy_opts),
        selectedResult: [..._selectedResult],
        selectedRow: _selectedRow,
        layout: { ...j_layout, isLayout: true },
      };

    case portalConstants.RESULT_SELECT:
      let fixedResults = getFixedResult(action.record);
      return {
        ...state,
        selectedResult: fixedResults,
        selectedRow: action.record,
      };

    case portalConstants.GET_RACK_DATA_REQUEST:
      return {
        ...state,
        loading_racks: true,
        rackPolygons: [],
        roadPolygons: [],
        autolayout: { racks: {}, roads: {} },
      };

    case portalConstants.GET_RACK_DATA_SUCCESS:
      // console.log('get rack data reducer', action.data);

      return {
        ...state,
      };

    case portalConstants.GET_LAYOUT_REQUEST:
      return {
        ...state,
        uiControls: { ...state.uiControls, loading_layout: true },
        layout: { roads: {}, inverter_groups: {}, isLayout: false },
      };
    case portalConstants.GET_LAYOUT_SUCCESS:
      let layout = action.response;
      // console.log("layout", layout);

      return {
        ...state,
        uiControls: { ...state.uiControls, loading_layout: false },
        layout: { ...layout, isLayout: true },
      };
    case portalConstants.GET_LAYOUT_FAILURE:
      return {
        ...state,
        uiControls: { ...state.uiControls, loading_layout: false },
        layout: { roads: {}, inverter_groups: {}, isLayout: false },
      };

    case portalConstants.UPDATE_UI_CONTROLS:
      uiControls[action.key] = action.value;
      if (action.key == "tab" && action.value == "advanced") {
        //modules
        // inputs.selectedModuleIndex = inputs.selectedModuleIndex == 0 ? 0 : inputs.selectedModuleIndex;
        // inputs.selectedModule = state.modules[inputs.selectedModuleIndex];
        // inputs.table_count = 10;
        //products
        // inputs.selectedProductIndex = inputs.selectedProductIndex == -1 ? 0 : inputs.selectedProductIndex;
        // inputs.current_product = JSON.parse(JSON.stringify(state.products[inputs.selectedProductIndex].racks));
        // inputs.selectedProduct = JSON.parse(JSON.stringify(state.products[inputs.selectedProductIndex]));
        // inputs.do_continuous = inputs.selectedProduct['continous'] == 1;
        // inputs.doOverrideDims = false;
        // inputs.azimuth = 180;
        // inputs.clearance_height = inputs.selectedProduct.module_clearance_height;
        // inputs.mod_string = 28;
        // inputs.dcac = inputs.selectedProduct.type == 1 ? 1.2 : 1.4;
        // inputs.grade_limit = inputs.selectedProduct.grade_limit;
        // inputs.ns_grade_limit = 10;
        // inputs.ew_grade_limit = 20;
        // inputs.doRoads = true;
        // inputs.doAlign = true;
        // inputs.doOverrideTilt = false;
        // inputs.doOverrideGCR = false;
        // if (inputs.selectedProductIndex == 4) {
        //   inputs.doOverrideDims = true;
        //   inputs.doOverrideTilt = true;
        //   inputs.do_continuous = false;
        //   inputs.OverrideTilt[0] = 10;
        //   inputs.OverrideTilt[1] = 10;
        // }
      }
      // else if (action.key == 'tab' && action.value == 'simplified') {
      //   inputs.selectedModuleIndex = inputs.selectedModuleIndex == -1 ? -1 : inputs.selectedModuleIndex;
      //   inputs.selectedModule = state.modules[inputs.selectedModuleIndex];
      // }
      return { ...state, uiControls, inputs };

    case portalConstants.RESET_PROJECT_REQUEST:
      // console.log(initialState.current_project)
      let reset_state = JSON.parse(JSON.stringify(initialState));

      // let projects = [];
      // Object.values(action.data).map((proj) => {
      //   if (proj.project_type == 0) {
      //     projects.push(proj);
      //   }
      // });

      reset_state.inputs.current_product = reset_state.inputs.selectedProduct.racks;
      reset_state.inputs.current_product.forEach((product, index) => {
        let Tracker_XY = Terratrak_2P_SAT(
          reset_state.inputs.selectedModule.mlm_Width,
          reset_state.inputs.selectedModule.mlm_Length,
          product.module,
          reset_state.inputs.selectedModule.module_isbifacial == 1
        );
        product.xdim = Tracker_XY[0];
        product.ydim = Tracker_XY[1];
      });

      return {
        ...state,
        ...reset_state,
        Projects,
        current_project: {
          ...reset_state.current_project,
        },
        inputs: {
          ...reset_state.inputs,
          features: {},
          selectedFeatureId: undefined,
        },
        ...reset_state.run_state,
        layout: { ...reset_state.layout },
        topoData: { ...reset_state.topoData },
        loading: true,
        selectedResult: undefined,
        uiControls: { ...reset_state.uiControls, loading: true, project_loading: true },
      };

    case portalConstants.RESET_PROJECT_SUCCESS:
      uiControls.loading = false;
      uiControls.project_loading = false;

      // inputs.selectedModule = {};
      // inputs.selectedModuleIndex = -1;
      // inputs.mod_string = 28;
      // inputs.table_count = 10;

      return { ...state, uiControls, modules: JSON.parse(JSON.stringify(defaultModules)) };

    case portalConstants.PROJECT_SELECT:
      return {
        ...state,
        selectedProject: action.value,
      };

    // show or hide the project modal
    case portalConstants.SHOW_PROJECT_LOAD_MENU:
      return {
        ...state,
        uiControls: { ...uiControls, modalMode: action.modalMode, showProjectLoader: true },
      };

    // case portalConstants.SHOW_PROJECT_SAVE_MENU:
    //   uiControls.modalMode = action.modalMode;
    //   uiControls.saving = false;
    //   uiControls.showProjectLoader = true;
    //   return {
    //     ...state,
    //     uiControls
    //   };

    case portalConstants.TOGGLE_MAP_UI:
      return {
        ...state,
        uiControls: {
          ...state.uiControls,
          map_ui_visible: action.value,
        },
      };

    case portalConstants.TOGGLE_OPEN_CLOSE:
      // uiControls.showProjectLoader = false;
      return {
        ...state,
        uiControls: { ...uiControls, showProjectLoader: false },
      };

    case portalConstants.LOAD_PROJECT_REQUEST:
      // removes the selected feature so it stops editing on the front end to prevent the map from crashing when loading project

      inputs.selectedFeatureId = undefined;
      return {
        ...state,
        uiControls: { ...state.uiControls, project_loading: true, inputs },
      };

    case portalConstants.LOAD_PROJECT_SUCCESS:
      let currentProject = state.Projects.find((project) => project.id == action.project_id);
      // let projectInputs = typeof currentProject.inputs == "string" ? JSON.parse(currentProject.inputs) : currentProject.inputs;
      // let features = typeof currentProject.surface == "string" ? JSON.parse(currentProject.surface) : currentProject.surface;
      let projectInputs = currentProject.inputs;
      // console.log(projectInputs);
      let features = projectInputs.site_features;

      inputs.features = features;
      inputs.project_id = action.project_id;

      // console.log("project", projectInputs);

      // if (features.length > 0) {
      //   // features = typeof site_features == "string" ? JSON.parse(site_features) : site_features;

      //   let new_features = {};
      //   features.forEach((feature, index) => {
      //     // No properties means old format
      //     // if (!features[index].properties) {
      //     //   // REFORMAT OLD FORMAT
      //     //   let coords = features[index]['coordinates'];
      //     //   coords.push(coords[0]);

      //     //   coords.forEach((point, index) => {
      //     //     coords[index] = [coords[index][1], coords[index][0]];
      //     //   });

      //     //   features[index].geometry = {
      //     //     type: 'Polygon',
      //     //     coordinates: [coords],
      //     //   };
      //     //   features[index].properties = {
      //     //     index: create_UUID(),
      //     //     identity: features[index]['identity'],
      //     //     active: features[index]['active'],
      //     //     name: features[index]['name'],
      //     //   };
      //     //   features[index].type = 'Feature';
      //     // }

      //     // let indx = create_UUID();
      //     let indx = feature.properties.index
      //     new_features[indx] = feature;
      //     // new_features[indx].properties.index = indx;
      //   });

      //   inputs.features = new_features;
      //   let featCollection = {
      //     type: "FeatureCollection",
      //     features: features,
      //   };
      //   let centeroid = turf.centroid(featCollection);
      //   inputs.map_center = { lat: centeroid.geometry.coordinates[1], lng: centeroid.geometry.coordinates[0] };
      //   inputs._map_center = { lat: centeroid.geometry.coordinates[1], lng: centeroid.geometry.coordinates[0] };
      //   inputs.map_force_zoom = !inputs.map_force_zoom;
      //   inputs.boundary_bbox = getBounds(features);
      // } else {
      //   inputs.boundary_bbox = undefined;
      //   inputs.features = {};
      // }

      //change feature indexes to be unique id's
      // let featuresWithUniqueIds = {};
      // features.map((feature, index) => {
      //   featuresWithUniqueIds = { ...featuresWithUniqueIds, [feature.properties.index]: feature };
      // });
      // inputs.features = featuresWithUniqueIds;

      current_project = { ...currentProject, loaded: true };

      uiControls.projectLoaded = true;
      uiControls.project_loading = false;
      uiControls.advancedOptions = true;
      uiControls.showProjectLoader = false;
      uiControls.show_results_table = false;
      state.hasResults = false;

      if (projectInputs.tab) {
        uiControls.tab = projectInputs.tab;
      }

      // if (projectInputs.layout) {
      //   inputs.layout = { ...inputs.layout, ...projectInputs.layout };
      // } else {
      //   inputs.layout = { ...JSON.parse(JSON.stringify(_defaultLayout)) };
      // }

      // if (projectInputs.performance) {
      // 	inputs.performance = { ...inputs.performance, ...projectInputs.performance };
      // } else {
      // 	inputs.performance = { ...JSON.parse(JSON.stringify(_defaultPerformance)) };

      // }

      // reset result state
      // state.results = [];
      // state.hasResults = false;
      // // PRODUCT / MODULE
      // inputs.selectedProductIndex = projectInputs.selectedProductIndex;
      // inputs.selectedModuleIndex = projectInputs.selectedModuleIndex;

      // inputs.selectedProduct = projectInputs.selectedProductIndex > -1 ? JSON.parse(JSON.stringify(state.products[projectInputs.selectedProductIndex])) : JSON.parse(JSON.stringify(_defaultProduct));
      // inputs.current_product = inputs.selectedProduct.racks;

      // inputs.selectedModule = {};
      // if (projectInputs.selectedModuleIndex === undefined) {
      //   // NEW MODULE STRUCTURE
      //   inputs.selectedModule = projectInputs.module;
      //   inputs.selectedModuleIndex = state.modules.findIndex((mod) => mod.name == projectInputs.module.name);
      // } else {
      //   // OLD
      //   if (projectInputs.selectedModuleIndex > -1 && projectInputs.selectedModuleIndex < state.modules.length) {
      //     inputs.selectedModule = JSON.parse(JSON.stringify(state.modules[projectInputs.selectedModuleIndex]));
      //   } else {
      //     inputs.selectedModuleIndex = 0;
      //     inputs.selectedModule = JSON.parse(JSON.stringify(_defaultModule));
      //   }
      // }

      // inputs.mod_string = projectInputs.mod_string ? projectInputs.mod_string : 28;
      // inputs.table_count = projectInputs.table_count ? projectInputs.table_count : 10;

      // if (inputs.selectedProductIndex >= 0) {
      //   inputs.current_product = projectInputs.product ? JSON.parse(JSON.stringify(projectInputs.product)) : JSON.parse(JSON.stringify(state.products[inputs.selectedProductIndex].racks));
      // }
      // // Product Tab
      // inputs.azimuth = typeof projectInputs.azimuth !== "undefined" ? projectInputs.azimuth : 180;
      // // module_target
      // inputs.grade_limit = typeof inputs.grade_limit !== "undefined" ? projectInputs.grade_limit : inputs.selectedProduct.grade_limit;

      // inputs.doRoads = typeof projectInputs.doRoads !== "undefined" ? projectInputs.doRoads : true;
      // inputs.doAlign = typeof projectInputs.doAlign !== "undefined" ? projectInputs.doAlign : true;
      // inputs.do_dc_lock = projectInputs.do_dc_lock == undefined || projectInputs.do_continuous ? false : projectInputs.do_dc_lock;
      // inputs.dc_lock_value = projectInputs.dc_lock_value == undefined ? 0 : isNaN(parseInt(projectInputs.dc_lock_value)) ? 0 : parseInt(projectInputs.dc_lock_value);

      // inputs.mod_string = typeof projectInputs.mod_string !== "undefined" ? projectInputs.mod_string : 28;
      // inputs.table_count = 10;
      // inputs.doOverrideTilt = projectInputs.doOverrideTilt;
      // inputs.OverrideTilt[0] = projectInputs.OverrideTilt[0];
      // inputs.OverrideTilt[1] = projectInputs.OverrideTilt[1];
      // inputs.doOverrideGCR = projectInputs.doOverrideGCR;
      // inputs.OverrideGCR[0] = projectInputs.OverrideGCR[0];
      // inputs.OverrideGCR[1] = projectInputs.OverrideGCR[1];
      // inputs.do_continuous = projectInputs.do_dc_lock ? 0 : projectInputs.do_continuous;
      // inputs.doOverrideDims = projectInputs.doOverrideDims;

      // // Performance tab
      // inputs.bifacial_ground_clearance_height = inputs.selectedProduct.module_clearance_height;
      // inputs.losses.soiling_loss = projectInputs.losses.soiling_loss;
      // inputs.losses.mismatch_loss = projectInputs.losses.mismatch_loss;
      // inputs.losses.wiring_loss = projectInputs.losses.wiring_loss;
      // inputs.losses.module_lid_loss = projectInputs.losses.module_lid_loss;
      // inputs.losses.module_quality_loss = projectInputs.losses.module_quality_loss;
      // inputs.losses.inverter_eff = projectInputs.losses.inverter_eff;
      // inputs.losses.combined_ac_loss = projectInputs.losses.combined_ac_loss;
      // inputs.dcac = typeof projectInputs.dcac !== "undefined" ? projectInputs.dcac : inputs.selectedProduct.type == 1 ? 1.2 : 1.4;

      // // console.log(projectInputs)
      // // Topo tab
      // topoData.topo_live = projectInputs.topo_id ? true : false;
      // topoData.topo_id = projectInputs.topo_id;

      // inputs.ns_grade_limit = typeof projectInputs.ns_grade_limit !== "undefined" ? projectInputs.ns_grade_limit : 10;
      // inputs.ew_grade_limit = typeof projectInputs.ew_grade_limit !== "undefined" ? projectInputs.ew_grade_limit : 20;
      // inputs.u_grade_limit = typeof projectInputs.u_grade_limit !== "undefined" ? projectInputs.u_grade_limit : 15;
      // inputs.topo_action = typeof projectInputs.topo_action !== "undefined" ? projectInputs.topo_action : "delete";

      // inputs.grading_enabled = typeof projectInputs.grading_enabled !== "undefined" ? projectInputs.grading_enabled : false;
      // inputs.grade_target = typeof projectInputs.grade_target !== "undefined" ? projectInputs.grade_target : 0;

      // inputs.cut_amt = typeof projectInputs.cut_amt !== "undefined" ? projectInputs.cut_amt : 0;
      // inputs.fill_amt = typeof projectInputs.fill_amt !== "undefined" ? projectInputs.fill_amt : 0;

      // projectInputs.layers_generated = projectInputs.layers_generated ? projectInputs.layers_generated : inputs.topoData.layers_generated;
      // topoData.layers_generated = { ...topoData.layers_generated, ...projectInputs.layers_generated };

      // topoData.grading = {
      //   enabled: inputs.grading_enabled,
      //   generated: topoData.layers_generated.CF.avail || inputs.cut_amt + inputs.fill_amt != 0.0,
      //   ns_grade_target: inputs.grade_target,
      //   ew_grade_target: inputs.grade_target,
      //   grade_target_type: 0,
      //   grade_target: inputs.grade_target,
      //   cut_amt: inputs.cut_amt,
      //   fill_amt: inputs.fill_amt,
      // };

      // topoData.u_grade_input_disable = topoData.layers_generated.U.avail || topoData.layers_generated.U.gavail;
      // topoData.ns_grade_inputs_disable = topoData.layers_generated.NS.avail || topoData.layers_generated.NS.gavail;
      // topoData.ew_grade_input_disable = topoData.layers_generated.EW.avail || topoData.layers_generated.EW.gavail;

      // topoData.has_layers =
      //   topoData.layers_generated.NS.avail ||
      //   topoData.layers_generated.EW.avail ||
      //   topoData.layers_generated.U.avail ||
      //   topoData.layers_generated.NS.gavail ||
      //   topoData.layers_generated.EW.gavail ||
      //   topoData.layers_generated.U.gavail ||
      //   topoData.layers_generated.ele.gavail;

      // topoData.topo_mode = typeof projectInputs.topo_mode !== "undefined" ? projectInputs.topo_mode : "off";
      // let mode = inputs.selectedProduct.type == 1 ? "NS" : "EW";
      // let mode_ext = `${mode}/${topoData.layers_generated[mode].limit}`;
      // topoData.topo_mode = mode;
      // topoData.topo_url = `https://topo-tiles.sunfig.com/test/${topoData.topo_id}/${mode_ext}/{z}/{x}/{y}.png`;
      // topoData.topo_scale_url = `https://topo-tiles.sunfig.com/test/${topoData.topo_id}/${mode_ext}/scale.png`;

      inputs.mapBounds = projectInputs.boundary_bbox && [
        [projectInputs.boundary_bbox[1], projectInputs.boundary_bbox[0]],
        [projectInputs.boundary_bbox[3], projectInputs.boundary_bbox[2]],
      ];
      return {
        ...state,
        current_project,
        inputs: {
          ...state.inputs,
          inputs,
        },
        // topoData,
        uiControls,
        layout: { inverter_groups: {}, isLayout: false },
        selectedResult: undefined,
        project_loaded: !state.project_loaded,
      };

    case portalConstants.PROJECT_NAME_UPDATE:
      // called when typing into the save/save as window
      return {
        ...state,
        inputs: { ...inputs, project_name: action.name },
      };

    case portalConstants.SAVE_PROJECT_REQUEST:
      // called when save requested
      return {
        ...state,
        current_project: action.current_project,
        uiControls: {
          ...uiControls,
          saving: true,
          loading: true,
        },
      };

    case portalConstants.SAVE_PROJECT_SUCCESS:
      // called after save and get_projects completes
      // console.log('save project', action.response, action.inputs)
      let saved_project = {
        edit_dt: action.response.editDate,
        id: action.response.project_id,
        inputs: action.inputs,
        name: action.inputs.project_name,
        loaded: true,
      };

      let _projects = state.Projects;
      let selectedProjectId = saved_project.id;
      let existing_proj_index = _projects.findIndex((p) => p.id == action.response.project_id);
      if (existing_proj_index == -1) {
        _projects.push(saved_project);
      } else {
        if (saved_project.inputs.active == 0) {
          _projects = _projects.filter((p) => p.id != saved_project.id);
          saved_project = JSON.parse(JSON.stringify(defaultProject));
          selectedProjectId = undefined;
        } else {
          _projects[existing_proj_index] = saved_project;
        }
      }
      _projects.sort(function(a, b) {
        return a.edit_dt - b.edit_dt;
      });

      // // console.log(action)
      // Object.values(action.projects).map((proj) => {
      //   if (proj.project_type == 0) {
      //     _projects.push(proj);
      //   }
      // });

      // console.log(saved_project)
      return {
        ...state,
        selectedProject: selectedProjectId,
        loading: false,
        Projects: _projects,
        current_project: saved_project,
        // inputs: { ...inputs, project_name: undefined },
        uiControls: {
          ...uiControls,
          showProjectLoader: false,
          project_loading: false,
          saving: false,
          loading: false,
        },
      };

    // case portalConstants.PROJECT_SAVE_AS_REQUEST:
    //   return {
    //     ...state,
    //     current_project: {
    //       ...state.current_project,
    //       id: undefined,
    //       name: ''
    //     },
    //     uiControls: {
    //       ...state.uiControls,
    //       modalMode: 0,
    //       showProjectLoader: true
    //     }
    //   };

    case portalConstants.DELETE_PROJECT_REQUEST:
      return {
        ...state,
        uiControls: {
          ...uiControls,
          loading: true,
          project_loading: true,
        },
      };

    case portalConstants.UPLOAD_KMZ_FEATURES:
      let actionFeatures = {};

      Object.values(action.features).map((feature, index) => {
        let id = create_UUID();
        feature.properties["index"] = id;
        actionFeatures = { ...actionFeatures, [id]: feature };
      });

      let featCollection = {
        type: "FeatureCollection",
        features: [...Object.values(actionFeatures)],
      };
      let centeroid = turf.centroid(featCollection);
      inputs.map_center = { lat: centeroid.geometry.coordinates[1], lng: centeroid.geometry.coordinates[0] };
      inputs._map_center = { lat: centeroid.geometry.coordinates[1], lng: centeroid.geometry.coordinates[0] };
      inputs.map_force_zoom = !inputs.map_force_zoom;
      inputs.boundary_bbox = getBounds(actionFeatures);
      inputs.features = actionFeatures;

      inputs.mapBounds = [
        [inputs.boundary_bbox[1], inputs.boundary_bbox[0]],
        [inputs.boundary_bbox[3], inputs.boundary_bbox[2]],
      ];

      return {
        ...state,
        inputs: { ...inputs },
      };

    case portalConstants.SELECT_MAP_FEATURE:
      inputs.selectedFeatureId = action.id;

      return {
        ...state,
        inputs,
        uiControls,
      };

    case portalConstants.CREATE_POLYGON:
      inputs.selectedFeatureId = action.id;

      uiControls.isDrawing = false;
      inputs.features[action.id] = action.geoJson;
      inputs.boundary_bbox = getBounds(Object.values(inputs.features));

      if (inputs.features.length > 0) {
        // inputs.map.center_point = getCenterPoint(Object.values(inputs.map.features))
        inputs.center_point = getNewCenterPoint(Object.values(inputs.features));
        inputs._map_center = inputs.center_point;
      }
      // if (inputs.features.length > 0) {
      //   inputs.boundary_bbox = getBounds(Object.values(inputs.features));
      //   // inputs.map.center_point = getCenterPoint(Object.values(inputs.map.features))
      //   inputs.center_point = getNewCenterPoint(Object.values(inputs.features));
      // } else {
      //   inputs.boundary_bbox = undefined;
      // }
      return { ...state, inputs, uiControls };

    case portalConstants.UPDATE_POLYGON:
      if (action.geoJson) {
        inputs.features[action.geoJson.properties.index] = action.geoJson;
      } else {
      }
      if (Object.keys(inputs.features).length > 0) {
        inputs.boundary_bbox = getBounds(Object.values(inputs.features));
      } else {
        inputs.boundary_bbox = undefined;
      }

      return { ...state, inputs };

    case portalConstants.DELETE_FEATURE:
      if (action.id) {
        // delete specific
        delete inputs.features[action.id];
        layout = { ...state.layout };
      } else {
        // delete all
        inputs.features = {};
        layout = {
          racks: {},
          roads: {},
          inverters: {},
          isLayout: false,
        };
      }

      uiControls.isDrawing = false;
      inputs.selectedFeatureId = undefined;

      return { ...state, inputs, uiControls, layout };

    case portalConstants.SET_COUNTY:
      if (action.key == "county") {
        uiControls.county = action.value;
      }

      if (action.key == "stateAbb") {
        uiControls.stateAbb = action.value;
      }

      return {
        ...state,
        uiControls,
      };

    case portalConstants.PREPARE_GROUND_REPORT:
      let tempRepData = action.reportData;
      let boundaryArea = 0;
      let exclusionArea = 0;
      let inactiveArea = 0;

      Object.values(inputs.features).forEach((feature) => {
        if (feature.properties.identity == 1) boundaryArea += getArea(feature.geometry.coordinates);
        if (feature.properties.identity == 2) exclusionArea += getArea(feature.geometry.coordinates);
        if (feature.properties.identity == 0) inactiveArea += getArea(feature.geometry.coordinates);
      });

      tempRepData["boundaryArea"] = boundaryArea;
      tempRepData["exclusionArea"] = exclusionArea;
      tempRepData["inactiveArea"] = inactiveArea;
      tempRepData["totalArea"] = boundaryArea - exclusionArea;

      return { ...state, report: { reportData: tempRepData, preparingReport: action.preparingReport == undefined ? false : true, reportDone: false } };

    case portalConstants.UPDATE_REPORT_DATA:
      let tempReportData = state.report.reportData;
      if (action.image) {
        tempReportData.reportImages[action.key] = action.value;
      } else {
        tempReportData[action.key] = action.value;
      }
      return { ...state, report: { reportData: tempReportData, preparingReport: false, reportDone: action.key == "mapImages" && action.key !== "reset" ? true : false } };

    case portalConstants.IMPORTDATA_REQUEST:
      inputs.import_loading = true;
      // console.log(action);
      return { ...state, inputs: { ...inputs, inputs } };

    case portalConstants.IMPORTDATA_SUCCESS:
      inputs.import_loading = false;
      inputs.toggle_import = false;
      let new_object = JSON.parse(JSON.stringify(action.response.object));

      let newModules = state.modules;
      newModules.push(new_object.data);

      let index = newModules.findIndex((module) => module.name == new_object.data.name);

      inputs.selectedModuleIndex = index == -1 ? 1 : index;
      inputs.selectedModule = { ...new_object.data };

      return { ...state, modules: newModules, inputs: { ...inputs, inputs } };

    case portalConstants.IMPORTDATA_FAILURE:
      inputs.import_loading = false;
      inputs.toggle_import = false;
      return { ...state, inputs: { ...inputs, inputs } };

    case portalConstants.UPDATE_REPORT_DATA:
      return { ...state };

    case portalConstants.TOGGLE_OFFSET_TOOLS:
      if (action.showTool) {
        uiControls.offsetToolVisible = true;
        inputs.offsetPolygonKey = action.polyId;
      } else {
        uiControls.offsetToolVisible = false;
        inputs.offsetPolygonKey = undefined;
      }
      return { ...state, uiControls: { ...uiControls }, inputs: { ...inputs } };

    case portalConstants.OFFSETPOLYGON_REQUEST:
      uiControls.offsetLoading = true;
      return { ...state, uiControls: { ...uiControls } };

    case portalConstants.OFFSETPOLYGON_SUCCESS:
      uiControls.offsetLoading = false;
      uiControls.offsetToolVisible = false;
      inputs.offsetPolygonKey = undefined;
      inputs.offsetDistance = 25;
      return { ...state, uiControls: { ...uiControls }, inputs: { ...inputs } };

    case portalConstants.OFFSETPOLYGON_FAILURE:
      uiControls.offsetLoading = false;
      uiControls.offsetToolVisible = false;
      inputs.offsetPolygonKey = undefined;
      inputs.offsetDistance = 25;
      return { ...state, uiControls: { ...uiControls }, inputs: { ...inputs } };

    default:
      return state;
  }
}

export function collectInputs(state, inc_features = true) {
  return {
    features: inc_features ? Object.values(state.inputs.site_features) : undefined,

    center: [state.inputs._map_center.lat, state.inputs._map_center.lng],

    tab: state.uiControls.tab,
    // below moved to layout tab
    // azimuth: state.inputs.azimuth,
    // doRoads: state.inputs.doRoads,
    // doAlign: state.inputs.doAlign,

    selectedProductIndex: state.inputs.selectedProductIndex,
    racking: state.inputs.selectedProduct,

    // selectedModuleIndex: state.inputs.selectedModuleIndex,
    selectedModuleIndex: undefined,
    module: state.inputs.selectedModule,

    doOverrideTilt: state.inputs.doOverrideTilt,
    OverrideTilt: [state.inputs.OverrideTilt[0], state.inputs.OverrideTilt[1]],
    doOverrideGCR: state.inputs.doOverrideGCR,
    OverrideGCR: [state.inputs.OverrideGCR[0], state.inputs.OverrideGCR[1]],
    doEWShifting: true,
    doOverrideDims: state.inputs.doOverrideDims,
    // overrideDims: [state.inputs.current_product.filter(product => product.active)],
    product: state.inputs.current_product,
    do_continuous: state.inputs.do_continuous,

    do_dc_lock: state.inputs.do_dc_lock,
    dc_lock_value: isNaN(state.inputs.dc_lock_value) == true ? 0 : state.inputs.dc_lock_value,

    mod_width: state.inputs.selectedModule.mlm_Width,
    mod_height: state.inputs.selectedModule.mlm_Length,
    mod_string: state.inputs.mod_string,
    table_count: state.inputs.table_count,

    doTopo: state.topoData.topo_live && (state.inputs.topo_action == "delete" || state.inputs.topo_action == "flag"),
    doGrade: state.topoData.grading.enabled && state.topoData.grading.generated,
    topo_id: state.topoData.topo_id,
    // grade_limit: state.inputs.selectedProductIndex == 0 ? 10 : 20,

    grade_limit: state.inputs.grade_limit,
    // dcac: state.inputs.dcac,

    ns_grade_limit: state.inputs.ns_grade_limit,
    ew_grade_limit: state.inputs.ew_grade_limit,
    u_grade_limit: state.inputs.u_grade_limit,
    do_generate_layers: state.topoData.do_generate_layers,
    generate_ns: state.topoData.layers_generated.NS.avail,
    generate_ew: state.topoData.layers_generated.EW.avail,
    generate_u: state.topoData.layers_generated.U.avail,
    layers_generated: state.topoData.layers_generated,
    topo_action: state.inputs.topo_action,

    // is_bifacial: state.inputs.is_bifacial,
    // bifaciality: state.inputs.bifaciality,
    // bifacial_transmission_factor: state.inputs.bifacial_transmission_factor,
    // bifacial_ground_clearance_height: state.inputs.bifacial_ground_clearance_height,
    // bifacial_structure_shade_factor: state.inputs.bifacial_structure_shade_factor,

    // losses: {
    //   soiling_loss: state.inputs.losses.soiling_loss,
    //   mismatch_loss: state.inputs.losses.mismatch_loss,
    //   wiring_loss: state.inputs.losses.wiring_loss,
    //   module_lid_loss: state.inputs.losses.module_lid_loss,
    //   module_quality_loss: state.inputs.losses.module_quality_loss,
    //   inverter_eff: state.inputs.losses.inverter_eff,
    //   combined_ac_loss: state.inputs.losses.combined_ac_loss,
    // },

    performance: { ...state.inputs.performance },
    layout: { ...state.inputs.layout },
  };
}

export function getInputs(state, inc_features = true) {
  let rbi_inputs = collectInputs(state, inc_features);

  // convert to swm inputs
  return convert_to_swm(rbi_inputs);
}
