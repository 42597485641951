import React, { useEffect, useState, Fragment } from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";

import canopy_test from "./canopy_test.png";

// Create styles
const styles = StyleSheet.create({
  tableRows: {
    marginHorizontal: 21,

    borderLeftColor: "#333",
    borderLeftWidth: 2,
    borderRightColor: "#333",
    borderRightWidth: 2,
    borderBottomColor: "#333",
    borderBottomWidth: 2,
  },

  infoContainer: {
    // flexDirection: 'row',
    // flexGrow: 1,
    marginTop: 12,
    height: 230,
    borderBottomColor: "#333",
    borderBottomWidth: 1,
  },
  infoBox: {
    flexGrow: 8,
    // backgroundColor: '#f2f2f2',
  },
  infoName: {
    fontSize: 10,
  },
  infoText: {
    color: "#333",
    fontSize: 10,
    textAlign: "justify",
    marginVertical: 4.5,
    marginHorizontal: 2,
    // textAlign: 'center',
    // Segoe UI
  },

  row: {
    flexGrow: 1,
    flexDirection: "row",
    // backgroundColor: '#f2f2f2',
  },

  small: {
    flexGrow: 8,
    width: 45,
    borderRightColor: "#333",
    borderRightWidth: 1,
  },
  medium: {
    flexGrow: 8,
    width: 65,
    borderRightColor: "#333",
    borderRightWidth: 1,
  },
  large: {
    flexGrow: 8,
    width: 95,
    borderRightColor: "#333",
    borderRightWidth: 1,
  },
  end: {
    flexGrow: 8,
    // height: 40,
    width: 65,
  },

  details: {
    // marginTop: 2,
    // marginBottom: 40,
    marginHorizontal: 20,
  },
  container: {
    flexDirection: "row",
    borderBottomWidth: 2,
    borderBottomColor: "#112131",
    borderBottomStyle: "solid",
    alignItems: "stretch",
  },
  nameColumn: {
    flexGrow: 3,
    width: 100,
    margin: "8px 0px",
  },
  dataColumn: {
    flexGrow: 3,
    width: 160,
    margin: "8px 0px",
  },
  imageColumn: {
    flexGrow: 3,
    width: 250,
    height: 200,
    marginHorizontal: 15,
  },
  indImage: {
    width: 250,
    height: 200,
  },
});

function prettifyText(text) {
  // takes in the ugly text from the product data planeType and makes it a little more presentable
  switch (text) {
    case "single_slope":
      return "Single Slope";
    case "inverted":
      return "Inverted";
    case "dual_tilt":
      return "Dual Tilt";
    default:
      return "undefined";
  }
}

// Create Document Component
const SummaryDetail = ({ results, images }) => {
  return (
    <View style={styles.details} break>
      {results &&
        images &&
        Object.values(results).map((row, index) => {
          return (
            <View style={styles.infoContainer} key={`detail_${index}`}>
              <Text style={styles.infoName}>{row.Name}</Text>
              <View style={styles.row}>
                <View style={styles.nameColumn}>
                  <Text style={styles.infoText}>Canopy Type:</Text>
                  <Text style={styles.infoText}>Module:</Text>
                  <Text style={styles.infoText}>Module Qty:</Text>
                  <Text style={styles.infoText}>Capacity:</Text>
                  <Text style={styles.infoText}>Azimuth:</Text>
                  {row["Canopy Type"] == "inverted" ? (
                    <Fragment>
                      <Text style={styles.infoText}>Tilt To Azimuth:</Text>
                      <Text style={styles.infoText}>Tilt From Azimuth:</Text>
                    </Fragment>
                  ) : (
                    <Text style={styles.infoText}>Tilt:</Text>
                  )}
                  <Text style={styles.infoText}>Dimensions (mod):</Text>
                  <Text style={styles.infoText}>Dimensions (ft):</Text>
                </View>
                <View style={styles.dataColumn}>
                  <Text style={styles.infoText}>{prettifyText(row["Canopy Type"])}</Text>
                  <Text style={styles.infoText}>{row.Module}</Text>
                  <Text style={styles.infoText}>{row["Module Qty"]}</Text>
                  <Text style={styles.infoText}>{row["Capacity (kw)"]}kW</Text>
                  <Text style={styles.infoText}>{row["Azimuth (°)"]}°</Text>
                  {row["Canopy Type"] == "inverted" ? (
                    <Fragment>
                      <Text style={styles.infoText}>{row["Tilt To Azimuth"]}°</Text>
                      <Text style={styles.infoText}>{row["Tilt From Azimuth"]}°</Text>
                    </Fragment>
                  ) : (
                    <Text style={styles.infoText}>{row["Tilt (°)"]}°</Text>
                  )}
                  <Text style={styles.infoText}>{`${row["Mod Dimension X Qty"]} modules by ${row["Mod Dimension Y Qty"]} modules`}</Text>
                  <Text style={styles.infoText}>{`${row["Mod Dimension X Ft"].toFixed(1)} ft by ${row["Mod Dimension Y Ft"].toFixed(1)} ft`}</Text>
                </View>
                {images[`liveMap_${row.Key}`] && (
                  <View style={styles.imageColumn}>
                    <Image src={images[`liveMap_${row.Key}`]} style={styles.indImage} />
                  </View>
                )}
              </View>
            </View>
          );
        })}
    </View>
  );
};

export { SummaryDetail };
