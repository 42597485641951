import { authConstants } from '../constants';
import jwtDecode from 'jwt-decode';

let user = JSON.parse(localStorage.getItem('user')) || undefined;
const initialState = user
  ? user
  : {
      loggingIn: false,
      loggedIn: false,
      access: {},
      token: {},
      error: undefined,
      error_msg: undefined,
      reset_pw: false,
    };

export function authentication(state = initialState, action) {
  let user = state;

  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggedIn: false,
        loggingIn: true
      };

    case authConstants.LOGIN_SUCCESS:
      user = {
        access: {
          token: action.user.access,
          ...jwtDecode(action.user.access)
        },
        refresh: {
          token: action.user.refresh,
          ...jwtDecode(action.user.refresh)
        },
        role: action.user.role
      };
      localStorage.setItem('user', JSON.stringify(user));
      return { ...user, loggingIn: false, loggedIn: true, reset_pw: false };

    case authConstants.LOGIN_RESET_PW:
      user = {
        access: {
          token: action.user.access,
          ...jwtDecode(action.user.access)
        },
        refresh: {
          token: action.user.refresh,
          ...jwtDecode(action.user.refresh)        
        },
        role: action.user.role
      };
      localStorage.setItem('user', JSON.stringify(user));
      return { ...user, loggingIn: false, loggedIn: false, reset_pw: true };

    case authConstants.LOGIN_FAILURE:
      let error = action.error;
      return { error: true, error_msg: error };

    case authConstants.LOGIN_CONTINUE:
      return { ...state };



    case authConstants.PW_RESET_REQUEST: 
      return { ...state, loggingIn: true, loggedIn: false};

    case authConstants.PW_RESET_SUCCESS:
      return { ...state, loggingIn: false, loggedIn: true, reset_pw: false };

    case authConstants.PW_RESET_FAILURE:
      return { error: true, error_msg: action.error };


    case authConstants.REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
        error: false,
        error_msg: undefined
      };

    case authConstants.REFRESH_TOKEN_SUCCESS:
      let refreshUser = {
        ...state,
        loggingIn: false,
        loggedIn: true,
        error: false,
        error_msg: undefined,
        access: {
          token: action.access,
          ...jwtDecode(action.access)
        }
      };
      // store user object for later
      localStorage.setItem('user', JSON.stringify(refreshUser));
      return refreshUser;

    case authConstants.REFRESH_TOKEN_FAILURE:
      let refreshError = action.error;
      return { error: true, error_msg: refreshError };

    case authConstants.LOGOUT:
      localStorage.setItem('user', JSON.stringify({}));
      user = {
        loggingIn: false,
        loggedIn: false,
        access: {},
        token: {},
        error: undefined,
        error_msg: undefined,
        reset_pw: false,
      }
      return user;

    default:
      return state;
  }
}

export function isRefreshValid(state) {
  if (state.refresh && state.refresh.exp) {
    // console.log(`Current time left on refresh ${1000* state.refresh.exp - (new Date()).getTime()}`)
    // return false when token expires in less than 12h
    return 1000 * state.refresh.exp - new Date().getTime() > 43200000;
  }
  return false;
}

export function isAuthenticated(state) {
  // return !isRefreshTokenExpired(state)
  // return isAccessValid(state) && isRefreshValid(state);
  return isRefreshValid(state);
}
