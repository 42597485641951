import React, { useState } from "react";

import { useSelector } from "react-redux";

import { ZoomAndLayerControlLayout } from "../Canopy/styles/ZoomAndLayerControlLayout";
import { TileSets, zoomGranularity } from "../Canopy/CanopyConstants";
import { LeafletControl } from "../Canopy/components/index";

import { getFeaturesBounds } from "../Redux";

import { add_song, minus, expand, layer } from "../../assets";
import { Menu, Tooltip, Button, Dropdown } from "antd";

const LiveReportZoomAndLayerControl = ({ mapRef, activeTileSet, position }) => {
  const map = useSelector((state) => state.carport.map);
  const visual = useSelector((state) => state.carport.visual);

  const tileMenu = () => (
    <Menu
      onClick={(e) => {
        // setActiveTileSet(e.item.props.children[1]);
      }}
    >
      {Object.keys(TileSets).map((key, index) => (
        <Menu.Item key={index} eventKey={key} disabled={key == activeTileSet}>
          {key}
        </Menu.Item>
      ))}
    </Menu>
  );

  const zoomExtents = () => {
    if (position) {
      mapRef.flyTo(position, 14);
    } else {
      if (Object.values(visual).length == 0) {
        mapRef.panTo(map.mapCenter);
      } else {
        // define canopy feature array
        let allCanopyFeatures = [];
        // loop through all the canopies and push the geoJson into allCanopyFeatures array
        Object.values(visual).map((vis) => {
          allCanopyFeatures.push(vis.structure);
        });

        // get the bounds of the features collection
        let bounds = getFeaturesBounds(allCanopyFeatures);

        // adjust the map to fit all canopies inside of the viewing area
        mapRef.fitBounds([
          [bounds[1], bounds[0]],
          [bounds[3], bounds[2]],
        ]);
      }
    }
  };

  const zoomIn = () => {
    mapRef.zoomIn(zoomGranularity);
  };
  const zoomOut = () => {
    mapRef.zoomOut(zoomGranularity);
  };

  return (
    <LeafletControl position="topright">
      <ZoomAndLayerControlLayout>
        <Tooltip title="Zoom In" placement="left">
          <Button className="zoom-control-layer-button" shape="circle" onClick={() => zoomIn()}>
            <img src={add_song} style={{ width: 20, height: 20 }} />
          </Button>
        </Tooltip>
        <Tooltip title="Zoom Out" placement="left">
          <Button className="zoom-control-layer-button" shape="circle" size="large" onClick={() => zoomOut()}>
            <img src={minus} style={{ width: 20, height: 20 }} />
          </Button>
        </Tooltip>
        <Tooltip title="Zoom Extents" placement="left">
          <Button className="zoom-control-layer-button" shape="circle" size="large" onClick={() => zoomExtents()}>
            <img style={{ width: 20, height: 20, marginBottom: 2 }} src={expand} />
          </Button>
        </Tooltip>
        {/* <Tooltip title="Layer Select" placement="left">
          <Dropdown overlay={tileMenu} placement="bottomRight" trigger={['click']}>
            <Button className="zoom-control-layer-button" shape="circle" size="large">
              <img src={layer} />
            </Button>
          </Dropdown>
        </Tooltip> */}
      </ZoomAndLayerControlLayout>
    </LeafletControl>
  );
};

export { LiveReportZoomAndLayerControl };
