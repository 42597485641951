import React, { useState, useEffect, Fragment } from "react";
import { LiveReportLayout } from "./styles";

//test data
import { weather_data } from "./perfTestData";

// components
import {
  LiveReportMap,
  LayoutMapSmall,
  LocationMap,
  ReportPageHeader,
  SiteOverviewTable,
  TrackerBreakdownTable,
  ModuleTable,
  PerformanceTable,
  InfoBoxes,
  MonthlyGeneration,
  PerformanceRatio,
  IAMGraph,
  IAMTable,
  InverterTable,
  LayoutTable,
  LossesTable,
  TopoMap,
  PerfGraph,
  PerformanceSummaryTable,
  WindAndTemp,
  EArrayEGridGraph,
  SankeyDiagram,
  InverterEffGraph,
  BOMTable,
  SoilingAlbedoTable,
  LossTable,
} from "./";

//image helpers
import { getTopoScale } from "./";

//images
import { glide_front, glide_rear, gm_2, rbi_gm_2, terratrack, dahlia_fuse } from "../../assets";

// redux
import { useSelector, useDispatch } from "react-redux";
import { portalActions } from "../Redux/actions/portal.actions";
//antd
import { Spin } from "antd";
import { RackingTable } from "./Tables";
import { ReportPageFooter } from "./ReportPageFooter";

const LiveReport = (props) => {
  const portal = useSelector((state) => state.portal);
  const dispatch = useDispatch();

  const [reportData, setReportData] = useState(undefined);
  const [addressInputToggle, setAddressInputToggle] = useState(false);

  useEffect(() => {
    if (props.data) {
      setReportData(props.data);
    }
    // console.log("reportData", reportData);
  }, [props]);

  useEffect(() => {
    if (addressInputToggle) {
      document.querySelector("#address-input").focus();
    }
  }, [addressInputToggle]);

  const getProductImage = () => {
    switch (reportData.racking_name) {
      case "RBI Solar - 2HP":
        return gm_2;
        break;
      // we have no image for 3HP
      // case "RBI Solar - 3HP":
      //   console.log("RBI Solar - 3HP");
      //   break;
      case "RBI Solar - Dahlia - 4HP":
        return dahlia_fuse;
        break;
      case "Terrasmart - TerraTrak - 2HT":
        return terratrack;
        break;
      case "Terrasmart - GLIDE - 2HP":
        return glide_rear;
        break;
      case "Terrasmart - GLIDE - 4HL":
        return glide_rear;
        break;
      default:
        break;
    }
  };

  return (
    <>
      {!props.preparingReport && reportData ? (
        <LiveReportLayout>
          <section className="page-wrap">
            <section className="page">
              <ReportPageHeader location={portal.report.reportData.location} user={reportData.userName} date={reportData.date} />
              <section className="report-rundown">
                <section className="report-title">
                  <h1>{reportData.project_name ? reportData.project_name : "Solar Project"}</h1>
                  <div className="address-box">
                    {addressInputToggle ? (
                      <input
                        id="address-input"
                        type="text"
                        value={reportData.address}
                        onBlur={(e) => setAddressInputToggle(!addressInputToggle)}
                        onChange={(e) => dispatch(portalActions.updateReportData("address", e.target.value))}
                        onKeyDown={(e) => e.keyCode === 13 && setAddressInputToggle(!addressInputToggle)}
                      />
                    ) : reportData.address ? (
                      <p>{reportData.address}</p>
                    ) : (
                      <p>Add Address</p>
                    )}
                    <svg onClick={() => setAddressInputToggle(!addressInputToggle)} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20 18V11H18V18H2V2H9V0H2C0.89543 0 0 0.89543 0 2V18C0 19.1046 0.89543 20 2 20H18C19.1046 20 20 19.1046 20 18ZM17.1781 0.723423C16.7197 0.26142 16.0921 0 15.4374 0C14.7834 0 14.1564 0.26083 13.6954 0.724628L5.3265 9.09342C4.57867 9.75233 4.08844 10.7328 4.00325 11.7873L4 15.0023V16.0023H8.1346C9.2689 15.9245 10.259 15.4295 10.9575 14.6238L19.279 6.30584C19.7407 5.84416 20.0001 5.21799 20.0001 4.56508C20.0001 3.91217 19.7407 3.286 19.279 2.82432L17.1781 0.723423ZM8.06398 14.0048C8.59821 13.967 9.09549 13.7184 9.49479 13.2616L15.5567 7.19972L12.8024 4.44527L6.6961 10.5496C6.29095 10.9079 6.04031 11.4092 6 11.8678V14.0029L8.06398 14.0048ZM14.2169 3.03128L16.9709 5.78551L17.8648 4.89162C17.9514 4.80502 18.0001 4.68756 18.0001 4.56508C18.0001 4.4426 17.9514 4.32514 17.8648 4.23854L15.7611 2.13486C15.6755 2.04855 15.5589 2 15.4374 2C15.3158 2 15.1992 2.04855 15.1136 2.13486L14.2169 3.03128Z"
                        fill="#1BAC51"
                      />
                    </svg>
                  </div>
                </section>
                <InfoBoxes data={reportData} location={portal.report.reportData.location} />
                <section className="vicinity-map">
                  <h3>Vicinity Map</h3>
                  <LocationMap mapName={"locationMap"} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                </section>
                <section className="layout-map">
                  <LayoutMapSmall mapName={"layoutMapSmall"} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                </section>
              </section>
              <ReportPageFooter page={1} designation={"A001"} />
            </section>
          </section>

          <section className="page-wrap">
            <section className="page">
              <section className="report-header">
                <ReportPageHeader location={portal.report.reportData.location} user={reportData.userName} date={reportData.date} />
              </section>
              <section className="large-layout-map">
                <LiveReportMap mapName={"layoutMapLarge"} setLiveReportMapRefs={props.setLiveReportMapRefs} />
              </section>
              <section className="report-footer">
                <ReportPageFooter page={2} designation={"A002"} />
              </section>
            </section>
          </section>

          <section className="page-wrap">
            <section className="page">
              <section className="report-header">
                <ReportPageHeader location={portal.report.reportData.location} user={reportData.userName} date={reportData.date} />
              </section>
              <section className="page-content">
                <section className="title">
                  <h3>Racking</h3>
                </section>
                <section className="topLeft">
                  <RackingTable data={reportData} />
                  <TrackerBreakdownTable data={reportData} />
                </section>
                <section className="racking-image">
                  <img src={getProductImage()} />
                </section>
              </section>
              <section className="report-footer">
                <ReportPageFooter page={3} designation={"B001"} />
              </section>
            </section>
          </section>

          <section className="page-wrap">
            <section className="page">
              <section className="report-header">
                <ReportPageHeader location={portal.report.reportData.location} user={reportData.userName} date={reportData.date} />
              </section>
              <section className="module-content">
                <section className="title">
                  <h3>Module</h3>
                </section>
                <section className="module-table">
                  <ModuleTable data={reportData} />
                </section>
                <div className="iam-graph">
                  <IAMGraph data={reportData} captureGraphImage={props.captureGraphImage} />
                  <div className="iam-table">
                    <IAMTable data={reportData} />
                  </div>
                </div>
              </section>
              <section className="report-footer">
                <ReportPageFooter page={4} designation={"B002"} />
              </section>
            </section>
          </section>

          <section className="page-wrap">
            <section className="page">
              <section className="report-header">
                <ReportPageHeader location={portal.report.reportData.location} user={reportData.userName} date={reportData.date} />
              </section>
              <section className="page-content">
                <section className="title">
                  <h3>Inverter</h3>
                </section>
                <section className="topLeft">
                  <InverterTable data={reportData} />
                </section>
                <section className="topRight">
                  <InverterEffGraph captureGraphImage={props.captureGraphImage} />
                </section>
              </section>
              <section className="report-footer">
                <ReportPageFooter page={5} designation={"B003"} />
              </section>
            </section>
          </section>

          <section className="page-wrap">
            <section className="page">
              <section className="report-header">
                <ReportPageHeader location={portal.report.reportData.location} user={reportData.userName} date={reportData.date} />
              </section>
              <section className="page-content">
                <section className="title">
                  <h3>Performance</h3>
                </section>
                <section className="topLeft">
                  <PerformanceTable data={reportData} />
                </section>
                <section className="bottomLeft-and-bottomRight ">
                  <SoilingAlbedoTable data={reportData} />
                </section>
              </section>
              <section className="report-footer">
                <ReportPageFooter page={6} designation={"B004"} />
              </section>
            </section>
          </section>

          <section className="page-wrap">
            <section className="page">
              <section className="report-header">
                <ReportPageHeader location={portal.report.reportData.location} user={reportData.userName} date={reportData.date} />
              </section>
              <section className="page-content">
                <section className="title">
                  <h3>Layout</h3>
                </section>
                <section className="topLeft">
                  <LayoutTable data={reportData} />
                </section>
              </section>
              <section className="report-footer">
                <ReportPageFooter page={7} designation={"B005"} />
              </section>
            </section>
          </section>

          <section className="page-wrap">
            <section className="page">
              <section className="report-header">
                <ReportPageHeader location={portal.report.reportData.location} user={reportData.userName} date={reportData.date} />
              </section>
              <section className="page-content">
                <section className="title">
                  <h3>Bill of Materials</h3>
                </section>
                <section className="topLeft">
                  <BOMTable data={reportData} />
                  <p>*Bill of materials is presented here for budgetary and general guidance purposes only.</p>
                </section>
              </section>
              <section className="report-footer">
                <ReportPageFooter page={8} designation={"R001"} />
              </section>
            </section>
          </section>

          <section className="page-wrap">
            <section className="page">
              <section className="report-header">
                <ReportPageHeader location={portal.report.reportData.location} user={reportData.userName} date={reportData.date} />
              </section>
              <section className="perf-results">
                <h3>Performance Results</h3>
                <section className="perf-table">
                  <PerformanceSummaryTable data={reportData} />
                </section>
                <section className="wind-graph">
                  <WindAndTemp data={reportData} captureGraphImage={props.captureGraphImage} />
                </section>
                <section className="eArray-graph">
                  <EArrayEGridGraph data={reportData} captureGraphImage={props.captureGraphImage} />
                </section>
                <section className="perf-graph">
                  <PerfGraph data={reportData} captureGraphImage={props.captureGraphImage} />
                </section>
              </section>
              <section className="report-footer">
                <ReportPageFooter page={document.querySelectorAll(".topo-content").length + 9} designation={"R002"} />
              </section>
            </section>
          </section>

          <section className="page-wrap">
            <section className="page">
              <section className="report-header">
                <ReportPageHeader location={portal.report.reportData.location} user={reportData.userName} date={reportData.date} />
              </section>
              <section className="page-content">
                <section className="title">
                  <h3>Loss Tables</h3>
                </section>
                <section className="left-column">
                  <LossTable data={reportData} />
                </section>
                <section className="bifacial-column">{reportData.en_bifacial == 1 && <LossTable data={reportData} bifacial={true} />}</section>
              </section>
              <section className="report-footer">
                <ReportPageFooter page={10} designation={"R003"} />
              </section>
            </section>
          </section>

          {/* ********************* Sankey Graph WIP **************************** */}

          {/* <section className="page-wrap">
            <section className="page">
              <section className="report-header">
                <ReportPageHeader location={portal.report.reportData.location} user={reportData.userName} date={reportData.date} />
              </section>
              <section className="sankey-diagram">
                <section className="sankey-title">
                  <h3>Loss Diagram</h3>
                </section>
                <SankeyDiagram data={data}/>
              </section>
              <section className="report-footer">
                <ReportPageFooter page={10} designation={"A002"} />
              </section>
            </section>
          </section> */}

          {reportData?.topo_id && (
            <>
              <section className="page-wrap">
                <section className="page">
                  <section className="report-header">
                    <ReportPageHeader location={portal.report.reportData.location} user={reportData.userName} date={reportData.date} />
                  </section>
                  <section className="topo-content">
                    <section className="topo-container">
                      <section className="topo-title">
                        <h3>{`Elevation (Ungraded)`}</h3>
                      </section>
                      <secion className="topo-map">
                        <TopoMap mapName={"ele"} topoMode={"ele"} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                      </secion>
                      <section className="topo-scale">
                        <img src={getTopoScale(reportData.topo_id, "ele")} />
                      </section>
                    </section>
                    {reportData.vis_ele.graded_avail && (
                      <section className="topo-container">
                        <section className="topo-title">
                          <h3>{`Elevation (Graded)`}</h3>
                        </section>
                        <secion className="topo-map">
                          <TopoMap mapName={"ele-g"} topoMode={`ele/G/${reportData.vis_ele.graded_percent}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                        </secion>
                        <section className="topo-scale">
                          <img src={getTopoScale(reportData.topo_id, `ele/G/${reportData.vis_ele.graded_percent}`)} />
                        </section>
                      </section>
                    )}
                  </section>
                  <section className="report-footer">
                    <ReportPageFooter page={8} designation={"T001"} />
                  </section>
                </section>
              </section>

              {reportData.vis_g.graded_avail && (
                <section className="page-wrap">
                  <section className="page">
                    <section className="report-header">
                      <ReportPageHeader location={portal.report.reportData.location} user={reportData.userName} date={reportData.date} />
                    </section>
                    <section className="topo-content">
                      <section className="topo-container">
                        <section className="topo-title">
                          <h3>{`Cut and Fill`}</h3>
                        </section>
                        <secion className="topo-map">
                          <TopoMap mapName={"CF"} topoMode={`CF/${reportData.vis_g.ungraded_percent}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                        </secion>
                        <section className="topo-scale">
                          <img src={getTopoScale(reportData.topo_id, `CF/${reportData.vis_g.ungraded_percent}`)} />
                        </section>
                      </section>
                    </section>
                    <section className="report-footer">
                      <ReportPageFooter page={9} designation={"T002"} />
                    </section>
                  </section>
                </section>
              )}

              {reportData && (reportData.vis_max.ungraded_avail || reportData.vis_max.graded_avail) && (
                <section className="page-wrap">
                  <section className="page">
                    <section className="report-header">
                      <ReportPageHeader location={portal.report.reportData.location} user={reportData.userName} date={reportData.date} />
                    </section>
                    <section className="topo-content">
                      <section className="topo-container">
                        <section className="topo-title">
                          <h3>{`Max Slope (Ungraded)`}</h3>
                        </section>
                        <secion className="topo-map">
                          <TopoMap mapName={"U"} topoMode={`U/${reportData.vis_max.ungraded_percent}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                        </secion>
                        <section className="topo-scale">
                          <img src={getTopoScale(reportData.topo_id, `U/${reportData.vis_max.ungraded_percent}`)} />
                        </section>
                      </section>
                      {reportData.vis_max.graded_avail && (
                        <section className="topo-container">
                          <section className="topo-title">
                            <h3>{`Max Slope (Graded)`}</h3>
                          </section>
                          <secion className="topo-map">
                            <TopoMap mapName={"U-G"} topoMode={`U/G/${reportData.vis_max.graded_percent}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                          </secion>
                          <section className="topo-scale">
                            <img src={getTopoScale(reportData.topo_id, `U/G/${reportData.vis_max.graded_percent}`)} />
                          </section>
                        </section>
                      )}
                    </section>
                    <section className="report-footer">
                      <ReportPageFooter page={10} designation={"T003"} />
                    </section>
                  </section>
                </section>
              )}

              {reportData && (reportData.vis_ns.ungraded_avail || reportData.vis_ns.graded_avail) && (
                <section className="page-wrap">
                  <section className="page">
                    <section className="report-header">
                      <ReportPageHeader location={portal.report.reportData.location} user={reportData.userName} date={reportData.date} />
                    </section>
                    <section className="topo-content">
                      {reportData.vis_ns.ungraded_avail && (
                        <section className="topo-container">
                          <section className="topo-title">
                            <h3>{`NS Grade (Ungraded)`}</h3>
                          </section>
                          <secion className="topo-map">
                            <TopoMap mapName={"NS"} topoMode={`NS/${reportData.vis_ns.ungraded_percent}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                          </secion>
                          <section className="topo-scale">
                            <img src={getTopoScale(reportData.topo_id, `NS/${reportData.vis_ns.ungraded_percent}`)} />
                          </section>
                        </section>
                      )}

                      {reportData.vis_ns.graded_avail && (
                        <section className="topo-container">
                          <section className="topo-title">
                            <h3>{`NS Grade (Graded)`}</h3>
                          </section>
                          <secion className="topo-map">
                            <TopoMap mapName={"NS-G"} topoMode={`NS/G/${reportData.vis_ns.graded_percent}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                          </secion>
                          <section className="topo-scale">
                            <img src={getTopoScale(reportData.topo_id, `NS/G/${reportData.vis_ns.graded_percent}`)} />
                          </section>
                        </section>
                      )}
                    </section>
                    <section className="report-footer">
                      <ReportPageFooter page={11} designation={"T004"} />
                    </section>
                  </section>
                </section>
              )}
              {reportData && (reportData.vis_ew.ungraded_avail || reportData.vis_ew.graded_avail) && (
                <section className="page-wrap">
                  <section className="page">
                    <section className="report-header">
                      <ReportPageHeader location={portal.report.reportData.location} user={reportData.userName} date={reportData.date} />
                    </section>
                    <section className="topo-content">
                      {reportData.vis_ew.ungraded_avail && (
                        <section className="topo-container">
                          <section className="topo-title">
                            <h3>{`EW Grade (Ungraded)`}</h3>
                          </section>
                          <secion className="topo-map">
                            <TopoMap mapName={"EW"} topoMode={`EW/${reportData.vis_ew.ungraded_percent}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                          </secion>
                          <section className="topo-scale">
                            <img src={getTopoScale(reportData.topo_id, `EW/${reportData.vis_ew.ungraded_percent}`)} />
                          </section>
                        </section>
                      )}

                      {reportData.vis_ew.graded_avail && (
                        <section className="topo-container">
                          <section className="topo-title">
                            <h3>{`EW Grade (Graded)`}</h3>
                          </section>
                          <secion className="topo-map">
                            <TopoMap mapName={"EW-G"} topoMode={`EW/G/${reportData.vis_ew.graded_percent}`} setLiveReportMapRefs={props.setLiveReportMapRefs} />
                          </secion>
                          <section className="topo-scale">
                            <img src={getTopoScale(reportData.topo_id, `EW/G/${reportData.vis_ew.graded_percent}`)} />
                          </section>
                        </section>
                      )}
                    </section>
                    <section className="report-footer">
                      <ReportPageFooter page={12} designation={"T005"} />
                    </section>
                  </section>
                </section>
              )}
            </>
          )}

          {/* <section className="page-wrap">
            <section className="page">
              <section className="report-header">
                <ReportPageHeader location={portal.report.reportData.location} user={reportData.userName} date={reportData.date} />
              </section>
              <section className="perf-results">
                <h3>Performance Results</h3>
                <section className="perf-table">
                  <PerformanceSummaryTable data={weather_data} />
                </section>
                <section className="wind-graph">
                  <WindAndTemp data={weather_data} captureGraphImage={props.captureGraphImage} />
                </section>
                <section className="eArray-graph">
                  <EArrayEGridGraph data={weather_data} captureGraphImage={props.captureGraphImage} />
                </section>
                <section className="perf-graph">
                  <PerfGraph captureGraphImage={props.captureGraphImage} />
                </section>
              </section>
              <section className="report-footer">
                <ReportPageFooter page={document.querySelectorAll(".topo-content").length + 9} designation={"R003"} />
              </section>
            </section>
          </section> */}

          {/* <section className="page-wrap">
            <section className="page">
              <section className="report-header">
                <ReportPageHeader location={portal.report.reportData.location} user={reportData.userName} date={reportData.date} />
              </section>
              <section className="sankey-diagram">
                <section className="sankey-title">
                  <h3>Loss Diagram</h3>
                </section>
                <SankeyDiagram />
              </section>
              <section className="report-footer">
                <ReportPageFooter page={10} designation={"A002"} />
              </section>
            </section>
          </section> */}
        </LiveReportLayout>
      ) : (
        <LiveReportLayout>
          <section className="page-wrap">
            <section className="page">
              <section className="report-loader">
                <Spin size="large" tip="Loading Report" style={{ marginTop: "50px" }} />
              </section>
            </section>
          </section>
        </LiveReportLayout>
      )}
    </>
  );
};

export { LiveReport };
