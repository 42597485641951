import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { carportActions } from '../../Redux';


import { Button, Input, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
const suffix_loading = <LoadingOutlined style={{ fontSize: 'x-small' }} spin />;
import { InputRow, SelectRow } from './styles'


function GenerateWireDiagram() {
	const dispatch = useDispatch();
	const canopy_id = useSelector((state) => state.carport.selectedInputs.id)
	const azimuth = useSelector((state) => state.carport.selectedInputs.azimuth);
	const canopy = useSelector((state) => state.carport.visual[canopy_id]);
	const wiring_loading = useSelector((state) => state.carport.wiring_loading)
	const [local_mps, set_local_mps] = useState(12)
	// console.log([...canopy.modules])
	// console.log(canopy.structure)

	function onClick() {
		let inputs = {
			"name": canopy_id,
			"azimuth": azimuth,
			"orientation": 1,
			"modules_per_string": parseInt(local_mps),
			"strings_per_combiner": 6,
			"Modules": [...canopy.modules],
			"Structure": canopy.structure
		}
		dispatch(carportActions.generateWireDiagram(canopy_id, inputs))
	}

	function onChangeInput(e) {
		set_local_mps(e.target.value)
	}

	return (
		<>
			<InputRow>
				<label>Modules per String</label>
				<Input
					size="small"
					value={local_mps}
					type="number"
					step={1}
					min={0}
					max={359}
					suffix={
						wiring_loading ? <Spin indicator={suffix_loading} />
						: <span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>#</span>
					}				
					max="999"
					onChange={onChangeInput}
				></Input>		
			</InputRow>		
			<SelectRow>
				<Button style={{ width: "200px" }} onClick={onClick} loading={wiring_loading}>
					Generate Wire Diagram		
				</Button>
			</SelectRow>
		</>
	)
}

export { GenerateWireDiagram }