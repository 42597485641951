import styled from 'styled-components';

export const InputRow = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	place-items: center;
	padding: 0px;
	margin: 2px auto;

	label {
    grid-column: 1 / auto;
    margin-right: auto;		
	}
`;