import { inputsConstants, portalConstants } from "../constants";
import { portalServices, userServices } from "../services";
import { inputsActions } from "../actions";
import { create_UUID } from "../helpers";
import * as turf from "@turf/turf";

export const portalActions = {
  getProjects,
  updateInput,
  updateWiring,
  updatePerformance,
  updateUIControls,
  runTopo,
  getTopoData,
  updateTopoMode,
  updateMapCenter,
  updateMapZoom,
  initResults,
  generateResults,
  cancelRun,
  selectResult,
  getCounty,
  clearLayout,
  getRackData,
  handleNewProject,
  showProjectLoadMenu,
  showProjectSaveMenu,
  toggleMapUI,
  loadProject,
  toggleOpenClose,
  projectSelect,
  projectNameUpdate,
  saveProject,
  deleteProject,
  getLayoutData,
  uploadKMZFeatures,
  selectFeature,
  createPolygon,
  updatePolygon,
  deleteFeature,
  setCounty,
  prepareGroundReport,
  importData,
  updateReportData,
  toggleOffsetTools,
  offsetPolygon,

  save_project_inputs,
};

function getProjects() {
  return (dispatch) => {
    dispatch(request());

    portalServices.getProjects().then(
      (response) => {
        if (response.url) {
          // download projects from s3 first
          portalServices.downloadFromS3(response.url).then((_response) => dispatch(success(_response)));
        } else {
          dispatch(success(response.data));
        }
      },
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: portalConstants.GET_USER_DATA_REQUEST };
  }
  function success(data) {
    return { type: portalConstants.GET_USER_DATA_SUCCESS, data };
  }
  function failure(error) {
    return { type: portalConstants.GET_USER_DATA_FAILURE, error };
  }
}
function getCounty(coord1, coord2) {
  return (dispatch) => {
    portalServices.getCounty(coord1, coord2).then((response) => {
      let results = response.results[0];
      if (results) {
        dispatch(request("county", results.county_name));
        dispatch(request("stateAbb", results.state_code));
      }
    });
  };
  function request(key, value) {
    return {
      type: portalConstants.INPUT_UPDATE,
      key,
      value,
    };
  }
}

function updateInput(key, value = undefined) {
  return (dispatch) => {
    dispatch(request(key, value));

    // little additional 3rd party call
    if (key == "features") {
      if (value.length == 1) {
        let first_coord = value[0].geometry.coordinates[0][0];
        dispatch(portalActions.getCounty(first_coord[1], first_coord[0]));
      } else if (value.length == 0) {
        dispatch(request("county", undefined));
        dispatch(request("stateAbb", undefined));
      }
    }
  };

  function request(key, value) {
    return {
      type: portalConstants.INPUT_UPDATE,
      key,
      value,
    };
  }
}
function updateWiring(key, value) {
  return (dispatch) => {
    dispatch(request(key, value));
  };

  function request(key, value) {
    return {
      type: portalConstants.INPUT_UPDATE_WIRING,
      key,
      value,
    };
  }
}
function updatePerformance(key, value) {
  return (dispatch) => {
    dispatch(request(key, value));
  };

  function request(key, value) {
    return {
      type: portalConstants.INPUT_UPDATE_PERFORMANCE,
      key,
      value,
    };
  }
}

function updateUIControls(key, value = undefined) {
  return {
    type: portalConstants.UPDATE_UI_CONTROLS,
    key,
    value,
  };
}

function runTopo(inputs, action) {
  return (dispatch) => {
    dispatch(request(inputs, action)),
      portalServices
        .runTopo(inputs)
        .then((response) => pollTopoUpdate(response["topo_id"]))
        .then((response) => dispatch(success(response, action, inputs)));
  };

  function request(inputs, action) {
    return { type: portalConstants.GET_TOPO_DATA_REQUEST, inputs, action };
  }
  function success(data, action, inputs) {
    return { type: portalConstants.GET_TOPO_DATA_SUCCESS, data, action, inputs };
  }
  function failure(error) {
    return { type: portalConstants.GET_TOPO_DATA_ERROR, error };
  }
}
async function pollTopoUpdate(id) {
  let topo_id = id;
  var loopBool = true;
  let run_count = 0;

  // polling loop
  while (loopBool) {
    var topo_check = await portalServices.checkTopoId2(topo_id);
    // console.log(topo_check)
    if (topo_check["code"] == 100) {
      loopBool = false;
      return {
        topo_id: topo_id,
        output: JSON.parse(topo_check.output),
      };
    } else if (topo_check["code"] == 97) {
      // ERROR
      loopBool = false;
    } else {
      setTimeout(async () => {}, 10000);
      run_count += 1;
      if (run_count > 10000) {
        // RUN COUNT REACHED
        loopBool = false;
      }
    }
  }

  return {
    topo_loading: false,
    error: {
      msg: "Error Connecting to Topography Server",
      code: 2,
    },
  };
}

function getTopoData(inputs) {
  return (dispatch) => {
    dispatch(request(inputs)),
      portalServices
        .getTopoId(inputs.boundary_bbox, inputs.generate_layers, inputs.grade_limits)
        .then((response) => portalServices.checkTopoLoop(response, inputs))
        .then((response) => dispatch(success(response)));
  };

  function request(inputs) {
    return { type: portalConstants.GET_TOPO_DATA_REQUEST, inputs };
  }
  function success(data) {
    return { type: portalConstants.GET_TOPO_DATA_SUCCESS, data };
  }
  function failure(error) {
    return { type: portalConstants.GET_TOPO_DATA_ERROR, error };
  }
}

function updateTopoMode(data) {
  return { type: portalConstants.UPDATE_TOPO_MODE, data };
}
function updateMapCenter(center) {
  return { type: portalConstants.UPDATE_MAP_CENTER, center };
}
function updateMapZoom(zoom) {
  return { type: portalConstants.UPDATE_MAP_ZOOM, zoom };
}

function initResults(inputs) {
  return (dispatch) => {
    dispatch(request());
    portalServices._init(inputs).then((response) => {
      if (response.run_id) {
        dispatch(success(response));
        dispatch(portalActions.generateResults(response.run_id, 10, 0));
      } else {
        dispatch(failure("Unable to contact Weather API. Please try again."));
        // dispatch(alertActions.error('Unable to contact Weather API. Please try again.'));
      }
    });
  };

  function request() {
    return { type: portalConstants.INIT_RESULTS_REQUEST };
  }
  function success(data) {
    return { type: portalConstants.INIT_RESULTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: portalConstants.INIT_RESULTS_ERROR, error };
  }
}

function generateResults(run_id, delay, count) {
  return (dispatch) => {
    dispatch(request());
    if (count > 500) {
      // force exit this
    }

    portalServices._getResults(run_id).then((response) => {
      // console.log("polling response", response);
      if (response.status == 100) {
        // COMPLETE
        // Pull the top GCR result
        // let plotRecord = {
        //   id: response.data[0].id,
        //   gcr: response.data[0].gcr,
        //   dcac: response.data[0].dcac,
        // };
        dispatch(layout_request(response.data[0].id));
        portalServices.getLayout(response.data[0].id).then(
          (layout_response) => {
            // dispatch(portalActions.selectResult(response.data[0]));
            // dispatch(success(response));
            // dispatch(alertActions.success('Automatic Layout Drawn'));
            portalServices.downloadFromS3(layout_response).then(
              (_response) => dispatch(layout_success(_response)),
              // COMPLETE
              // dispatch(complete()),
              // WAIT TO CALL SUCCESS SO THE COMPLETE :) SHOWS UP FOR A SECOND(1000ms)
              setTimeout(() => dispatch(success(response)), 500)
            );
          },
          (error) => {
            dispatch(failure(error.toString()));
            // dispatch(alertActions.error(error.toString()));
          }
        );

        // dispatch(success(response));
      } else if (response.status == 50) {
        // CANCEL
        dispatch(failure("Simulation Canceled"));
      } else if (response.status == 97) {
        // ERROR
        dispatch(failure(response.error.error[0]));
        // dispatch(alertActions.error('Please try again or send a bug report.'));
      } else {
        dispatch(update(response.complete));
        setTimeout(dispatch(portalActions.generateResults(run_id, Math.pow(delay, 2), count + 1)), delay);
      }
    });
  };

  function request(data) {
    return { type: portalConstants.GENERATE_RESULTS_REQUEST, data };
  }
  function update(complete) {
    return { type: portalConstants.GENERATE_RESULTS_UPDATE, complete };
  }
  function success(data) {
    return { type: portalConstants.GENERATE_RESULTS_SUCCESS, data };
  }
  function layout_request(id) {
    return { type: portalConstants.GET_LAYOUT_REQUEST, id };
  }
  function layout_success(response) {
    return { type: portalConstants.GET_LAYOUT_SUCCESS, response };
  }
  function failure(error) {
    return { type: portalConstants.GENERATE_RESULTS_FAILURE, error };
  }
}

function cancelRun(id) {
  return (dispatch) => {
    dispatch(request());
    portalServices._cancelRun(id);
  };
  function request() {
    return { type: portalConstants.CANCEL_RUN_REQUEST };
  }
}

function selectResult(record) {
  return {
    type: portalConstants.RESULT_SELECT,
    record,
  };
}

function clearLayout() {
  return {
    type: portalConstants.GET_LAYOUT_FAILURE,
  };
}

function getRackData(row) {
  return (dispatch) => {
    dispatch(request());
    portalServices._getRacks(row.layout_id).then((response) => {
      if (response.code == 100) {
        dispatch(success(response));
      } else if (response.code == 97) {
        dispatch(failure("Error in the backend"));
      }
    });
  };

  function request() {
    return { type: portalConstants.GET_RACK_DATA_REQUEST };
  }

  function success(data) {
    return { type: portalConstants.GET_RACK_DATA_SUCCESS, data };
  }
  function failure(error) {
    return { type: portalConstants.GET_RACK_DATA_FAILURE, error };
  }
}

function getLayoutData(id) {
  return (dispatch) => {
    dispatch(request(id));

    portalServices.getLayout(id).then(
      (response) => {
        // dispatch(success(response));
        // dispatch(alertActions.success('Automatic Layout Drawn'));
        portalServices.downloadFromS3(response).then((_response) => dispatch(success(_response)));
      },
      (error) => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id) {
    return { type: portalConstants.GET_LAYOUT_REQUEST, id };
  }
  function success(response) {
    return { type: portalConstants.GET_LAYOUT_SUCCESS, response };
  }
  function failure(error) {
    return { type: portalConstants.GET_LAYOUT_FAILURE, error };
  }
}

function handleNewProject() {
  // RESET STATE BACK TO INITIAL STATE
  return (dispatch) => {
    dispatch(request());
    setTimeout(() => dispatch(success()), 500);
  };

  function request() {
    return { type: portalConstants.RESET_PROJECT_REQUEST };
  }

  function success() {
    return { type: portalConstants.RESET_PROJECT_SUCCESS };
  }
}

function showProjectLoadMenu(modalMode) {
  // called when project manager button was pressed, save,saveas,load
  return {
    type: portalConstants.SHOW_PROJECT_LOAD_MENU,
    modalMode,
  };
}

function showProjectSaveMenu(modalMode) {
  return {
    type: portalConstants.SHOW_PROJECT_SAVE_MENU,
    modalMode,
  };
}

function toggleMapUI(value) {
  return {
    type: portalConstants.TOGGLE_MAP_UI,
    value,
  };
}

function toggleOpenClose(value) {
  return {
    type: portalConstants.TOGGLE_OPEN_CLOSE,
    value,
  };
}
function loadProject(project_id) {
  return (dispatch) => {
    dispatch(request());

    setTimeout(() => dispatch(success(project_id)), 350);
  };

  function request() {
    return { type: portalConstants.LOAD_PROJECT_REQUEST };
  }
  function success(project_id) {
    return { type: portalConstants.LOAD_PROJECT_SUCCESS, project_id };
  }
}

function projectSelect(value) {
  return {
    type: portalConstants.PROJECT_SELECT,
    value,
  };
}

function projectNameUpdate(name) {
  return { type: portalConstants.PROJECT_NAME_UPDATE, name };
}

function save_project_inputs(inputs) {
  return (dispatch) => {
    dispatch(request(inputs));
    portalServices.save_project_inputs(inputs).then((save_response) => {
      dispatch(success(inputs, save_response));
      dispatch(update(save_response.project_id));
    });
  };
  function request(current_project) {
    return { type: portalConstants.SAVE_PROJECT_REQUEST, current_project };
  }
  function success(inputs, response) {
    return { type: portalConstants.SAVE_PROJECT_SUCCESS, inputs, response };
  }
  function update(project_id) {
    return {
      type: inputsConstants.UPDATE_INPUT_BY_NAME,
      key: "project_id",
      value: project_id,
    };
  }
}

function saveProject(current_project) {
  return (dispatch) => {
    dispatch(request(current_project));

    portalServices.saveProject(current_project).then((save_response) => {
      portalServices.getProjects().then(
        // (response) => {
        //   dispatch(success(response.data, save_response.project_id));
        // },
        (response) => {
          if (response.url) {
            // download projects from s3 first
            portalServices.downloadFromS3(response.url).then((_response) => dispatch(success(_response, save_response.project_id)));
          } else {
            dispatch(success(response.data, save_response.project_id));
          }
        },
        (error) => dispatch(failure(error.toString()))
      );
    });
  };

  function request(current_project) {
    return { type: portalConstants.SAVE_PROJECT_REQUEST, current_project };
  }
  function success(projects, project_id) {
    return { type: portalConstants.SAVE_PROJECT_SUCCESS, projects, project_id };
  }
}

// function saveProjectAs() {
//   return dispatch => {
//     dispatch(request());
//   };
//   function request() {
//     return { type: portalConstants.PROJECT_SAVE_AS_REQUEST };
//   }
// }

// deactivates project by assigning a 0 to active, project is still available in the DB
function deleteProject(project_to_delete) {
  return (dispatch) => {
    dispatch(request());

    portalServices.saveProject(project_to_delete).then(() => {
      portalServices.getProjects().then(
        // (response) => dispatch(success(response.data)),
        (response) => {
          if (response.url) {
            // download projects from s3 first
            portalServices.downloadFromS3(response.url).then((_response) => dispatch(success(_response)));
          } else {
            dispatch(success(response.data));
          }
        },
        (error) => dispatch(failure(error.toString()))
      );
    });
  };

  function request() {
    return { type: portalConstants.DELETE_PROJECT_REQUEST };
  }
  function success(data) {
    return { type: portalConstants.GET_USER_DATA_SUCCESS, data };
  }
  function failure(error) {
    return { type: portalConstants.GET_USER_DATA_FAILURE, error };
  }
}

function uploadKMZFeatures(features) {
  return {
    type: portalConstants.UPLOAD_KMZ_FEATURES,
    features,
  };
}

function selectFeature(id) {
  return {
    type: portalConstants.SELECT_MAP_FEATURE,
    id,
  };
}

function createPolygon(geoJson, id) {
  return {
    type: portalConstants.CREATE_POLYGON,
    geoJson,
    id,
  };
}

function updatePolygon(geoJson) {
  return {
    type: portalConstants.UPDATE_POLYGON,
    geoJson,
  };
}

function deleteFeature(id) {
  return {
    type: portalConstants.DELETE_FEATURE,
    id,
  };
}

function setCounty(coord1, coord2) {
  return (dispatch) => {
    portalServices.getCounty(coord1, coord2).then((response) => {
      let results = response.results[0];
      if (results) {
        dispatch(request("county", results.county_name));
        dispatch(request("stateAbb", results.state_code));
      }
    });
  };
  function request(key, value) {
    return {
      type: portalConstants.SET_COUNTY,
      key,
      value,
    };
  }
}

function prepareGroundReport(report_data = undefined, preparingReport = undefined) {
  // console.log("before", report_data);
  // let reportData = JSON.parse(JSON.stringify(report_data));
  // console.log("report", reportData);
  return {
    type: portalConstants.PREPARE_GROUND_REPORT,
    reportData: JSON.parse(JSON.stringify(report_data)),
    preparingReport,
    // url,
    // reportDone,
    // location,
  };
}

function importData(data) {
  return (dispatch) => {
    dispatch(request(data));

    userServices.importData(data).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request(data) {
    return { type: portalConstants.IMPORTDATA_REQUEST, data };
  }
  function success(response) {
    return { type: portalConstants.IMPORTDATA_SUCCESS, response };
  }
  function failure(error) {
    return { type: portalConstants.IMPORTDATA_FAILURE, error };
  }
}

function updateReportData(key, value, image = undefined) {
  return { type: portalConstants.UPDATE_REPORT_DATA, key, value, image };
}

function toggleOffsetTools(showTool, polyId) {
  return { type: portalConstants.TOGGLE_OFFSET_TOOLS, showTool, polyId };
}

function offsetPolygon(polygon, offset) {
  return (dispatch) => {
    dispatch(request());
    portalServices.offsetPolygon(polygon, offset).then(
      (response) => {
        // we could get multiple polygons back if the offset had to split
        Object.values(response["offsetPolygon"]).map((result) => {
          let new_id = create_UUID();
          result.properties["index"] = new_id;
          result.properties["identity"] = 0;
          result.properties["active"] = true;

          let turfPoly = turf.polygon(result.geometry.coordinates);
          let real_poly = turf.flip(turfPoly);

          result.properties["area"] = (turf.area(turfPoly) / 1000000) * 100;
          result.properties["center"] = turf.getCoords(turf.centroid(real_poly));

          dispatch(inputsActions.update_site_input("add_site_feature", result));
          // console.log(result, new_id)

          dispatch(createPolygon(result, new_id));
        });
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: portalConstants.OFFSETPOLYGON_REQUEST };
  }
  function success(response) {
    return { type: portalConstants.OFFSETPOLYGON_SUCCESS, response };
  }
  function failure(error) {
    return { type: portalConstants.OFFSETPOLYGON_FAILURE, error };
  }
}
