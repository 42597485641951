import { authConstants } from '../constants';
import { authServices } from '../services';
import { history } from '../helpers';

// import { userActions } from './user.actions';
import { portalActions, projectManagerActions } from '../actions';

import LogRocket from "logrocket";

export const authActions = {
  login,
  logout,
  continueLogin,
  refreshToken,
  reset_pw,
	identifyUser,
};

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));

    authServices.login(username, password).then(
      (user) => {
        let reset_pw = user['reset_password'] == 'reset_password';
        if (reset_pw) {
          dispatch(reset_password(user));
        } else {
          dispatch(success(user));
          history.push('/');
          dispatch(portalActions.getProjects());
					dispatch(authActions.identifyUser(user.access.user_id, username));
          // dispatch(projectManagerActions.getProjects());
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error('Incorrect email or password'));
      }
    );
  };

  function request(user) {
    return { type: authConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: authConstants.LOGIN_SUCCESS, user };
  }
  function reset_password(user) {
    return { type: authConstants.LOGIN_RESET_PW, user };
  }
  function failure(error) {
    return { type: authConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  return (dispatch) => {
    dispatch(request());
    history.push('/login');
    // userService.logout();
  };

  function request() {
    return { type: authConstants.LOGOUT };
  }
}

function continueLogin(user) {
  return (dispatch) => {
    // dispatch(success(user));
    dispatch(portalActions.getProjects());
		dispatch(authActions.identifyUser(user.access.user_id));
  };
  function success(user) {
    return { type: authConstants.CONTINUE_LOGIN, user };
  }
}

function refreshToken(refresh) {
  return (dispatch) => {
    dispatch(request({ access }));

    authServices.refreshToken(refresh).then(
      (access) => {
        dispatch(success(access));
      },
      (error) => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error('Incorrect email or password'));
      }
    );
  };

  function request(refresh) {
    return { type: authConstants.REFRESH_TOKEN_REQUEST, refresh };
  }
  function success(access) {
    return { type: authConstants.REFRESH_TOKEN_SUCCESS, access };
  }
  function failure(error) {
    return { type: authConstants.REFRESH_TOKEN_FAILURE, error };
  }
}

function reset_pw(email, old_password, new_password) {
  return (dispatch) => {
    dispatch(request());

    authServices.updatePassword(email, old_password, new_password).then(
      (response) => {
        dispatch(success());
        history.push('/');
        dispatch(portalActions.getProjects());
      },
      (error) => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error('Incorrect email or password'));
      }
    );
  };

  function request() {
    return { type: authConstants.PW_RESET_REQUEST };
  }
  function success() {
    return { type: authConstants.PW_RESET_SUCCESS };
  }
  function failure() {
    return { type: authConstants.PW_RESET_FAILURE };
  }
}

function identifyUser(user_id, email = undefined) {
  return (dispatch) => {
    if (process.env.NODE_ENV === "production") {
      if (email) {
        LogRocket.identify(user_id, { email: email });
      } else {
        LogRocket.identify(user_id);
      }
    }
  };
}