import React, { Component, Fragment } from "react";
import styled, { css } from "styled-components";
import { CSVLink } from "react-csv";
import domtoimage from "dom-to-image";

// redux
import { connect } from "react-redux";
import { carportActions, inputsActions } from "../Redux/actions";

// components
import { energy, modules } from "../../assets";
import { ResultTable } from "./ResultsPanel";
import { DownloadPDF } from "../PDFReport";
import { LiveCanopyReport, ThirtyPercentSet } from "../LiveCanopyReport";

// antd
import { Row, Col, Button, Table, PageHeader, message, Modal } from "antd";

const CanopyResultsWrapper = styled.section`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;

  .ant-page-header.canopy {
    padding: 5px 15px;
  }

  .results-table-header {
    grid-row: 1;
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;

    .results-stats {
      grid-column: 1;
      display: flex;

      .stat {
        margin: 0 20px;
        display: flex;

        .stat-img {
          margin: 0 10px;
          background: #dce0e8;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          display: grid;
          place-items: center;
          img {
            filter: sepia(100%) hue-rotate(-165deg) saturate(100); // changed #1bac54 to blue
          }
        }
      }
    }

    .results-buttons {
      grid-column: 2;
      margin-left: auto;
      margin-right: 20px;
      display: grid;
      place-items: center;
      grid-template-columns: ${(props) => (props.showCanopyReport || props.showThirtyPercentSet ? "repeat(2, 1fr)" : "repeat(3, 1fr)")};
      grid-template-rows: 1fr;

      @media (max-width: 1420px) {
        grid-template-columns: ${(props) => (props.showCanopyReport || props.showThirtyPercentSet ? "repeat(2, 1fr)" : "repeat(2, 1fr)")};
        grid-template-rows: ${(props) => (props.showCanopyReport || props.showThirtyPercentSet ? "repeat(1, 1fr)" : "repeat(1, 1fr)")};
      }

      & > button {
        margin: 0 10px;
      }
    }
  }
`;
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CanopyReport } from "../PDFReport";
import { portal } from "../Redux/reducers/portal.reducer";
import { nodeName } from "jquery";

const d = new Date();
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

class CanopyResults extends Component {
  constructor(props) {
    super(props);
    this.canopy_csv_ref = React.createRef();
    this.state = {
      loadingCanopyLiveReport: false,
      reportData: undefined,
      preparingCanopyReport: false,
      canopyReportPrepaired: false,
      reportPrepared: false,
      liveCanopyReportRefs: {},
      userName: undefined,
      role: undefined,
    };

    this.setCanopyMapRefs = this.setCanopyMapRefs.bind(this);
    this.saveThirtyPercentInputs = this.saveThirtyPercentInputs.bind(this);
  }

  componentDidMount() {
    let email = localStorage.getItem("email");

    // to test the salesforce capture tool, just change role to some other int !== 15
    this.setState({ userName: email, role: 3 });
  }

  componentDidUpdate(prevProps, prevState) {
    const { carport } = prevProps;

    // console.log("report", this.state.preparingCanopyReport);

    if (prevProps.carport.uiState.show_canopy_report == false && this.props.carport.uiState.show_canopy_report == true) {
      this.props.setShowCanopyNameLabels(false);
      this.displayReport();
      // this.captureMap();
    }
    if (prevProps.carport.uiState.show_thirty_percent_set == false && this.props.carport.uiState.show_thirty_percent_set == true) {
      this.props.setShowCanopyNameLabels(false);
      this.displayReport();
      // this.captureMap();
    }

    if (prevProps.carport.uiState.show_canopy_report == true && this.props.carport.uiState.show_canopy_report == false) {
      this.setState({ reportPrepared: false, liveCanopyReportRefs: {} });
    }

    if (prevProps.carport.uiState.show_thirty_percent_set == true && this.props.carport.uiState.show_thirty_percent_set == false) {
      this.setState({ reportPrepared: false, liveCanopyReportRefs: {} });
    }

    if (prevProps.carport.image_keys.length !== this.props.carport.image_keys.length && this.props.carport.image_keys.length > 0) {
      this.handleCaptureReportImages(this.props.carport.image_keys[0], this.props.carport.image_keys);
    }

    if (prevProps.download_lock == true && this.props.download_lock == false) {
      if (this.props.download_type == "canopy_report" || this.props.download_type == "thirty_percent_report") {
        this.props.setShowCanopyNameLabels(false);
        this.generateLiveCanopyReport();
      }
      if (this.props.download_type == "canopy_csv") {
        // No idea why this only works when there is a timeout.
        setTimeout(() => {
          this.canopy_csv_ref.current.link.click();
        }, 1000);
      }
    }

    // if (this.props.carport.prepareReport == undefined && this.props.carport.prepareReport != prepareReport) {
    //   // take screenshots of all canopies
    //   // console.log('images for report complete')
    //   // this.setState({reportPrepared: true})
    //   let reportData = {
    //     images: this.props.carport.reportImages,
    //     results: this.props.carport.results,
    //     overview: {
    //       rating: this.props.carport.module_wattage,
    //       mod_qty: this.props.carport.totalModules,
    //       capacity: this.props.carport.total_capacity,
    //       count: Object.keys(this.props.carport.results).length,
    //       loc: this.props.carport.center,
    //       project: this.props.carport.project_name,
    //       center: this.props.carport.center,
    //     },
    //   };

    //   this.setState({
    //     reportData: reportData,
    //     preparingReport: false,
    //     reportPrepared: true,
    //   });
    // }
  }

  saveThirtyPercentInputs(key, value) {
    this.setState({ reportData: { ...this.state.reportData, [key]: value } });
  }

  setCanopyMapRefs(key, theMapRef) {
    this.setState({
      liveCanopyReportRefs: { ...this.state.liveCanopyReportRefs, [key]: { sizes: { x: theMapRef.leafletElement._size.x, y: theMapRef.leafletElement._size.y }, className: theMapRef.className } },
    });
  }

  handleCaptureReportImages = async (_key, _keys) => {
    let value = await this.getPngUrl(this.state.liveCanopyReportRefs[_key]);
    let new_list = _keys.filter((key) => key != _key);

    this.setState({ reportData: { ...this.state.reportData, images: { ...this.state.reportData.images, [_key]: value.url } } }, () => {
      this.props.dispatch(carportActions.collectImages(new_list));
    });

    if (new_list.length == 0) {
      this.setState({ preparingCanopyReport: false, reportPrepared: true });
    }
  };

  generateLiveCanopyReport = (keys = undefined) => {
    this.setState({ preparingCanopyReport: true, reportPrepared: false }, () => {
      this.props.dispatch(carportActions.collectImages(Object.keys(this.state.liveCanopyReportRefs)));
    });
  };

  getPngUrl = (mapRef) => {
    return new Promise((resolve, reject) => {
      let width = mapRef.sizes.x;
      let height = mapRef.sizes.y;
      let node = document.getElementById(`${mapRef.className}`);
      console.log("name", mapRef.className);
      domtoimage
        .toPng(node, { width, height, quality: mapRef.className == "live-canopy-report-map" ? 1 : 0.4 })
        .then((url) => resolve({ url, width, height }))
        .catch((error) => reject(error));
    });
  };

  getModuleSum = () => {
    let moduleSum = [];
    if (Object.values(this.props.carport.rectangles).length > 0) {
      Object.values(this.props.carport.rectangles).map((rectangle) => {
        moduleSum.push(rectangle.dimensions.modX * rectangle.dimensions.modY);
      });
    }
    return moduleSum.reduce((a, b) => a + b);
  };

  prettifyText = (text) => {
    // takes in the ugly text from the product data planeType and makes it a little more presentable
    switch (text) {
      case "single_slope":
        return "Single Slope";
      case "inverted":
        return "Inverted";
      case "dual_tilt":
        return "Dual Tilt";
      default:
        return "undefined";
    }
  };

  displayReport = () => {
    let date = `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;

    this.setState({ loadingCanopyLiveReport: true }, () => {
      let canopyReportData = {
        date,
        results: this.props.carport.results,
        images: undefined,
        thirtyPercentInputs: undefined,
        overview: {
          rating: this.props.carport.module_wattage,
          mod_qty: this.props.carport.totalModules,
          capacity: this.props.carport.total_capacity,
          count: Object.keys(this.props.carport.results).length,
          loc: this.props.carport.center,
          project: this.props.carport.project_name,
          center: this.props.carport.center,
        },
      };

      setTimeout(() => {
        this.setState({ reportData: canopyReportData, loadingCanopyLiveReport: false });
      }, 2000);
    });
  };

  // captureMap = () => {
  //   if (!this.state.preparingReport && !this.state.reportPrepared) {
  //     this.props.dispatch(carportActions.prepareReport(Object.keys(this.props.carport.rectangles)));
  //     this.setState({ preparingReport: true, reportData: undefined, reportPrepared: false });
  //   }
  //   // for testing/ toggling
  //   // if (this.state.preparingReport) {
  //   //   this.props.dispatch(carportActions.prepareReport(undefined))
  //   //   this.setState({preparingReport: false})
  //   // } else {
  //   //   this.props.dispatch(carportActions.prepareReport(Object.keys(this.props.carport.rectangles)))
  //   //   this.setState({preparingReport: true, reportData: undefined, reportPrepared: false})
  //   // }

  //   // console.log(this.state.image1)
  //   // if (this.state.image1) {
  //   //   window.saveAs(this.state.image1, `RBI-Portal-Export-${this.props.project_name}.png`);
  //   // }
  //   // let width = this.refs.map.leafletElement._size.x;
  //   // let height = this.refs.map.leafletElement._size.y;
  //   // let width = 1200;
  //   // let height = 500;
  //   // var node = document.getElementById('leaflet-map');
  //   // console.log(node)
  //   // domtoimage.toPng(node, { width, height }).then(
  //   //   function (dataUrl) {
  //   //   this.setState({image1: dataUrl})
  //   // }.bind(this)).catch(function (error) {
  //   //     console.error('oops, something went wrong!', error);
  //   // })

  //   // domtoimage.toBlob(node, { width, height }).then(
  //   //   function(blob) {
  //   //     // window.saveAs(blob, `RBI-Portal-Export-${this.props.project_name}.png`);
  //   //     this.setState({image1: blob})
  //   //   }.bind(this)
  //   // );
  // };

  handleDownloadReportReset = () => {
    this.setState({ reportPrepared: false });
  };

  render() {
    return (
      <div>
        <CanopyResultsWrapper showCanopyReport={this.props.carport.uiState.show_canopy_report} showThirtyPercentSet={this.props.carport.uiState.show_thirty_percent_set}>
          <PageHeader ghost={true} className="canopy">
            <div className="results-table-header">
              <div className="results-stats">
                <div className="stat">
                  <div className="stat-img">
                    <img src={modules} alt="solar panel modules" />
                  </div>
                  <div className="stat-text">
                    <strong>Module Qty:</strong>
                    <p>{this.props.carport.totalModules}</p>
                  </div>
                </div>
                <div className="stat">
                  <div className="stat-img">
                    <img src={energy} alt="Solar energy" />
                  </div>
                  <div className="stat-text">
                    <strong>Site Capacity:</strong>
                    <p>{`${(this.props.carport.total_capacity / 1000).toFixed(2)} kW`}</p>
                  </div>
                </div>
              </div>

              <div className="results-buttons">
                {!this.props.carport.uiState.show_thirty_percent_set && (
                  <Button
                    type="primary"
                    ghost
                    style={{ width: "150px", marginTop: "4px", marginBottom: "4px" }}
                    disabled={this.state.preparingCanopyReport}
                    onClick={() => {
                      this.props.dispatch(carportActions.setUIState("show_canopy_report", !this.props.carport.uiState.show_canopy_report));
                      if (!this.props.download_lock) {
                        this.props.dispatch(inputsActions.reset_sf_capture());
                      }
                      if (!this.props.carport.uiState.show_canopy_report == false) {
                        this.props.dispatch(inputsActions.reset_sf_capture());
                      }
                    }}
                  >
                    {this.props.carport.uiState.show_canopy_report ? "Hide Report" : "Show Report"}
                  </Button>
                )}

                {!this.props.carport.uiState.show_canopy_report && (
                  <Button
                    type="primary"
                    ghost
                    style={{ width: "150px", marginTop: "4px", marginBottom: "4px" }}
                    disabled={this.state.role < 10 && this.state.preparingCanopyReport}
                    onClick={() => {
                      this.props.dispatch(carportActions.setUIState("show_thirty_percent_set", !this.props.carport.uiState.show_thirty_percent_set));
                      if (!this.props.download_lock) {
                        this.props.dispatch(inputsActions.reset_sf_capture());
                      }
                      if (!this.props.carport.uiState.show_thirty_percent_set == false) {
                        this.props.dispatch(inputsActions.reset_sf_capture());
                      }
                    }}
                  >
                    {this.props.carport.uiState.show_thirty_percent_set ? "Hide 30% Set" : "Show 30% Set"}
                  </Button>
                )}

                {this.props.carport.uiState.show_canopy_report || this.props.carport.uiState.show_thirty_percent_set ? (
                  <Button
                    type="primary"
                    ghost
                    style={{ width: "160px", marginTop: "4px", marginBottom: "4px", padding: 5 }}
                    onClick={() => {
                      if (this.props.download_lock && this.state.role !== 15) {
                        this.props.dispatch(inputsActions.toggle_sf_modal("show_sf_modal", true, this.props.carport.uiState.show_canopy_report ? "canopy_report" : "thirty_percent_report"));
                      } else {
                        this.props.setShowCanopyNameLabels(false);
                        this.generateLiveCanopyReport();
                      }
                    }}
                    disabled={this.state.preparingCanopyReport}
                    loading={this.state.preparingCanopyReport}
                  >
                    {this.props.carport.uiState.show_canopy_report && this.props.download_lock && this.state.role !== 15
                      ? "Unlock Download"
                      : this.props.carport.uiState.show_thirty_percent_set && this.props.download_lock && this.state.role !== 15
                      ? "Unlock Download"
                      : this.props.carport.uiState.show_canopy_report
                      ? "Download Report"
                      : "Download 30% Set"}
                  </Button>
                ) : (
                  <Fragment>
                    {/* <Button
                      type="primary"
                      style={{ width: "150px", marginTop: "4px", marginBottom: "4px" }}
                      ghost
                      onClick={() => {
                        this.refs.canopyResults.hidden = false;
                        this.refs.canopyResults.select();
                        document.execCommand("copy");
                        this.refs.canopyResults.hidden = true;
                        message.success("Copied Results to clipboard");
                      }}
                    >
                      Copy to Clipboard
                    </Button> */}
                    {this.props.download_lock && this.state.role !== 15 ? (
                      <Button
                        ghost
                        type="primary"
                        style={{ width: "150px", margin: "0px 10px" }}
                        onClick={() => {
                          this.props.dispatch(inputsActions.toggle_sf_modal("show_sf_modal", true, "canopy_csv"));
                        }}
                      >
                        Unlock CSV
                      </Button>
                    ) : (
                      <CSVLink ref={this.canopy_csv_ref} filename={"RBI-Carport-Results.csv"} data={this.props.carport.csvResult}>
                        <Button ghost type="primary" style={{ width: "150px", margin: "0px 10px" }}>
                          Download to CSV
                        </Button>
                      </CSVLink>
                    )}
                  </Fragment>
                )}

                {this.state.reportPrepared && this.state.reportData && (
                  <DownloadPDF
                    data={this.state.reportData}
                    handleDownloadReportReset={this.handleDownloadReportReset}
                    mode={this.props.carport.uiState.show_canopy_report ? "canopy" : "thirtyPercent"}
                  />
                )}
              </div>
            </div>
          </PageHeader>

          <div
            style={{
              overflowY: "auto",
              overflowX: "auto",
              width: "100%",
              height: "100%",
            }}
          >
            {this.props.carport.uiState.show_canopy_report && (
              <LiveCanopyReport
                loadingCanopyLiveReport={this.state.loadingCanopyLiveReport}
                reportData={this.state.reportData}
                setCanopyMapRefs={this.setCanopyMapRefs}
                saveThirtyPercentInputs={this.saveThirtyPercentInputs}
              />
            )}

            {this.props.carport.uiState.show_thirty_percent_set && (
              <ThirtyPercentSet
                loadingCanopyLiveReport={this.state.loadingCanopyLiveReport}
                reportData={this.state.reportData}
                setCanopyMapRefs={this.setCanopyMapRefs}
                saveThirtyPercentInputs={this.saveThirtyPercentInputs}
              />
            )}

            {!this.props.carport.uiState.show_thirty_percent_set && !this.props.carport.uiState.show_thirty_percent_set && <ResultTable type="canopy" />}
          </div>
        </CanopyResultsWrapper>
        <textarea
          hidden
          ref="canopyResults"
          name="TextResults"
          cols="40"
          rows="50"
          value={this.props.carport.tsvResult}
          onChange={(e) => {
            console.log("copied to clipboard");
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { carport, inputs } = state;
  const { role } = state.authentication;
  const { download_lock, uiState } = inputs;
  const { download_type } = uiState;
  return {
    carport,
    role,
    download_lock,
    download_type,
  };
}

const connectedStore = connect(mapStateToProps)(CanopyResults);
export { connectedStore as CanopyResults };
