/*eslint no-undef: "error"*/

import React from "react";
import { connect } from "react-redux";

import { obtainToken, _updatePassword } from "../utils/authenticate";
import { AuthContext } from "../components/Auth/Authentication";
import Swal from "sweetalert2";
import { ts_logo_hori } from "../assets";

import { Row, Button, Form, Input, message } from "antd";
const FormItem = Form.Item;
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import { authActions } from "../components/Redux/actions";
import { isAuthenticated } from "../components/Redux/reducers/authentication.reducer";

import "./login.less";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      createPassword: false,
      username: "",
      password: "",
      confirm_password: "",
      saved_password: "",
      token: undefined,

      old_password: "Password2019",
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = (authValues) => {
    // event.preventDefault();

    this.setState({ isLoading: true });

    if (authValues.email && authValues.password) {
      if (this.props.reset_pw) {
        if (authValues.password != authValues.confirm_password) {
          message.error("Passwords do not match", 5);
          return;
        }
        if (authValues.confirm_password.length < 7) {
          message.error("Password must be great than 6 characters", 5);
          return;
        }
        // call reset pw
        this.props.dispatch(authActions.reset_pw(authValues.email, this.state.old_password, authValues.confirm_password));
      } else {
        this.setState({ old_password: authValues.password });
        // login normally
        this.props.dispatch(authActions.login(authValues.email, authValues.password));
      }
    }

    // old form from antd
    // this.props.form.validateFields(async (err, values) => {
    //   // TODO: Check for err here before trying to login
    //   if (this.props.reset_pw) {
    //     // validate the two passwords first
    //     if (values.password != values.confirm_password) {
    //       message.error('Passwords do not match', 5)
    //       return
    //     }
    //     if (values.confirm_password.length < 7) {
    //       message.error('Password must be great than 6 characters', 5)
    //       return
    //     }
    //     // call reset pw
    //     this.props.dispatch(authActions.reset_pw(values.email, this.state.old_password, values.confirm_password));
    //   } else {
    //     this.setState({old_password: values.password})
    //     // login normally
    //     this.props.dispatch(authActions.login(values.email, values.password));
    //     this.props.form.setFieldsValue({
    //       password: undefined
    //     })

    //   }
    // });
  };

  render() {
    return (
      <Row justify="center" type="flex" style={{ padding: "25px" }}>
        <AuthContext.Consumer>
          {(value) => (
            <div>
              {/* START panel */}
              <div className="login-panel">
                <div className="login-header">
                  <a href="#">
                    <img src={ts_logo_hori} alt="RBI Portal" className="center" />
                  </a>
                </div>
                <div className="portal-header">
                  <p>Sales Portal</p>
                </div>
                <div className="login-panel-body">
                  <Form
                    // onSubmit={this.handleSubmit(value)}
                    onFinish={this.handleSubmit}
                    className="login-form"
                  >
                    <div className="login-label">Email</div>
                    <FormItem name="email" className="input" rules={[{ required: true, message: "Please input your email!" }]}>
                      <Input ref={(input) => input && input.focus()} prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Email" className="inner-input" />
                    </FormItem>
                    {this.props.reset_pw && (
                      <div>
                        <div style={{ height: 13 }}></div>
                        <div style={{ height: 13 }}></div>
                        <div className="login-label reset">Reset Password required for account.</div>
                        <div className="login-label reset">Please enter and confirm a new password.</div>
                      </div>
                    )}

                    <div style={{ height: 13 }}></div>
                    <div className="login-label">Password</div>
                    <FormItem name="password" className="input" rules={[{ required: true, message: "Please input your password!" }]}>
                      <Input prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />} type="password" placeholder="Password" className="inner-input" />
                    </FormItem>

                    {/* if password needs to be updated */}
                    {this.props.reset_pw && (
                      <div>
                        <div style={{ height: 13 }}></div>
                        <div className="login-label"> Confirm Password</div>
                        <FormItem name="confirm_password" className="input" rules={[{ required: true, message: "Please input your password!" }]}>
                          <Input prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />} type="password" placeholder="Confirm Password" className="inner-input" />
                        </FormItem>
                      </div>
                    )}

                    <FormItem>
                      <span>{this.props.error}</span>
                    </FormItem>
                    {/* <FormItem>
                <a className="login-form-forgot" href="javascript:void(0);" onClick={this.handleForgotPassword}>Forgot password</a>
              </FormItem> */}

                    <div style={{ height: 13 }}></div>
                    <FormItem>
                      <Button
                        block
                        type="primary"
                        htmlType="submit"
                        className="login-button"
                        // disabled={this.state.isLoading}
                        loading={this.props.loggingIn}
                      >
                        {this.props.loggingIn ? "LOGGING IN..." : "LOGIN"}
                      </Button>
                    </FormItem>
                  </Form>
                </div>
              </div>
              {/* END panel */}
            </div>
          )}
        </AuthContext.Consumer>
      </Row>
    );
  }
}

export { Login };
