import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { portalActions } from "../Redux/actions/portal.actions";

import { ReportPageFooterWrap } from "./styles";

const ReportPageFooter = ({ page, designation }) => {
  const dispatch = useDispatch();
  const [numOfPages, setNumOfPages] = useState(undefined);

  useEffect(() => {
    setTimeout(() => {
      let numberOfPages = document.querySelectorAll(".page-wrap").length;
      setNumOfPages(numberOfPages);
      dispatch(portalActions.updateReportData("numOfPages", numberOfPages, false));
    }, 500);
  }, []);

  return (
    <ReportPageFooterWrap>
      <div className="page-designation">
        <p>{designation}</p>
      </div>
      <div className="powered-by-sunfig"></div>
      <div className="page-number">
        <p>{`Page ${page} of ${numOfPages}`}</p>
      </div>
    </ReportPageFooterWrap>
  );
};

export { ReportPageFooter };
