import React from 'react';
import { useSelector } from 'react-redux';

import { InputRow } from './styles'

function CanopyDimensionInput() {

	const dimensions = useSelector((state) => state.carport.selectedInputs.dimensions);

	return (
		<InputRow>
			<span>
				{`Width: ${parseFloat(
					dimensions.x * 3.281 // meters -> feet
				).toFixed(1)} ft`}
			</span>
			<span>
				{`Length: ${parseFloat(
					dimensions.y * 3.281 // meters -> feet
				).toFixed(1)} ft`}
			</span>	
		</InputRow>
	)
}

export { CanopyDimensionInput }