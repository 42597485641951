import React, { useState, useEffect } from "react";
import { ThirtyPercentInputLayout } from "./styles";

// component

const ThirtyPercentInput = ({ id, labelText, input_value, updateThirtyPercentInputs }) => {
  return (
    <ThirtyPercentInputLayout key={id}>
      <label>{labelText}</label>
      <input id={id} value={input_value} onChange={(e) => updateThirtyPercentInputs(e.target.id, e.target.value)} autocomplete="off" />
    </ThirtyPercentInputLayout>
  );
};

export { ThirtyPercentInput };
