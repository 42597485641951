// use these to append strings to selectedResults, or replace labels/values with predefined strings
// format: key, formatTrailing, formatPreceding, displayValue, displayLabel
const selectedResultFormat = {
  keysformats: [
    { key: "racking_name", displayLabel: "Racking" },
    { key: "mod_rating", displayLabel: "Module" },
    { key: "gcr", displayLabel: "GCR" },
    { key: "tilt", displayLabel: "Tilt", formatTrailing: "º" },
    { key: "module_count", displayLabel: "Module Qty" },
    { key: "modules_per_string", displayLabel: "Mods/Str" },
    { key: "capacity", displayLabel: "Capacity", formatTrailing: " (MWp)" },
    { key: "YIELD", displayLabel: "Yield", formatTrailing: " (kWh/kWp)" },
    { key: "generation", displayLabel: "Year 1", formatTrailing: " (MWh)" },
    { key: "rtr", displayLabel: "Intra-row Spacing", formatTrailing: " m" },
    { key: "azimuth", displayLabel: "Azimuth", formatTrailing: "º" },

    { key: "pitch", displayLabel: "Pitch", formatTrailing: " m" },

    // 'id',
    // 'layout_id',

    { key: "rack_count", displayLabel: "Racking Qty" },
    { key: "string_count", displayLabel: "String Qty" },
    { key: "rack_breakdown", displayLabel: "Table Qtys" },
    // { key: 'removed_modules', displayLabel: 'Azimuth', formatTrailing: 'º'},
    { key: "table_a", displayLabel: "Table A Qty" },
    { key: "table_b", displayLabel: "Table B Qty" },
    { key: "table_c", displayLabel: "Table C Qty" },
    { key: "table_d", displayLabel: "Table D Qty" },
    { key: "wire_length_ft", displayLabel: "Wire Length (ft)" },
    { key: "combiner_count", displayLabel: "Combiner Box Count" },

    // 'dc_degradation',
    // 'horizontal_global_irradiance',
    // 'global_incident_in_col_plane',
    // 'near_shadings_irradiance_loss',
    // 'iam_factor_on_global',
    // 'soiling_loss',
    // 'bifacial_gain',
    // 'global_effective_irradiation_on_col',
    // 'array_nominal_energy',
    // 'pv_loss_irrad',
    // 'pv_loss_temp',
    // 'pv_elec_loss',
    // 'lid_loss',
    // 'quality_loss',
    // 'dc_mismatch_loss',
    // 'ohmic_wiring_loss',
    // 'e_array',
    // 'inverter_loss',
    // 'ac_loss',
    // 'interconnect_lim_loss',
  ],
};

export default selectedResultFormat;
