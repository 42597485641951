export function debounce(a,b,c){var d,e;return function(){function h(){d=null,c||(e=a.apply(f,g))}var f=this,g=arguments;return clearTimeout(d),d=setTimeout(h,b),c&&!d&&(e=a.apply(f,g)),e}}


export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function getTime(editDate) {

	var date = new Date(editDate*1000)
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;

	let format_d = `${strTime} - ${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`

	return format_d.toString();
}



import selectedResultFilter from './defaultSelectedResultFilter';
import selectedResultFormat from './defaultSelectedResultFormat';
import selectedResultOrder from './defaultSelectedResultOrder';
export function getFixedResult(selectedResult) {
  let items = [];
  Object.keys(selectedResult).map(key => {
    // see if we have formatting
    const formatEntry = selectedResultFormat.keysformats.find(entry => {
      return entry.key === key;
    });
    
    // WE ONLY WANT ENTRIES IN OUR RESULTFORMAT FILE
    if (formatEntry !== undefined) {    
      // do our initial default filter
      let obj2Add = {
        key: key,
        value: selectedResult[key],
        hide: selectedResultFilter.keys.some(filterKey => {
          return key === filterKey;
        })
      };
      if (formatEntry) {
        if (formatEntry.displayValue) {
          obj2Add.displayValue = formatEntry.displayValue;
        }
        if (formatEntry.displayLabel) {
          obj2Add.displayLabel = formatEntry.displayLabel;
        }
        if (formatEntry.formatPreceding) {
          obj2Add.formatPreceding = formatEntry.formatPreceding;
        }
        if (formatEntry.formatTrailing) {
          obj2Add.formatTrailing = formatEntry.formatTrailing;
        }
      }
  
      items.push(obj2Add);
    }
  });

  // sort this based on our default sorting method
  let result = []
  selectedResultOrder.forEach(function(key) {
    var found = false;
    items = items.filter(function(item) {
        if(!found && item.key == key) {
            result.push(item);
            found = true;
            return false;
        } else 
            return true;
    })
  })

  return result;
};