import React, { useState, useEffect } from "react";
import { Tooltip } from "react-leaflet";

import { Spinner } from "../../../Spinner";
import { OffsetPolygonWrap } from "./styles";
//redux
import { useDispatch, useSelector } from "react-redux";
import { portalActions, isNumber } from "../../../Redux";

const OffsetPolygonTool = ({}) => {
  const dispatch = useDispatch();

  const offsetLoading = useSelector((state) => state.portal.uiControls.offsetLoading);
  const offsetDistance = useSelector((state) => state.portal.inputs.offsetDistance);
  const features = useSelector((state) => state.portal.inputs.features);
  const offsetPolygonKey = useSelector((state) => state.portal.inputs.offsetPolygonKey);

  const [offsetDist, setOffsetDist] = useState(offsetDistance);

  useEffect(() => {
    setOffsetDist(offsetDistance);
  }, [offsetDistance]);

  const changeOffsetDistance = (e) => {
    setOffsetDist(e.target.value);

    let changed_value = parseFloat(e.target.value);

    if (!isNumber(changed_value)) return;

    dispatch(portalActions.updateInput("offsetDistance", changed_value));
  };

  const offsetPolygon = (offsetDirection, saveOffset = false) => {
    let originPolygon = features[offsetPolygonKey];
    dispatch(portalActions.offsetPolygon(originPolygon, offsetDist * (offsetDirection == "inside" ? -1 : 1)));
  };

  return (
    <Tooltip sticky direction="top" opacity={1} offset={[0, -100]} interactive={true} permanent style={{ width: "min-content", padding: "0px 10px" }}>
      <OffsetPolygonWrap className="offset-tool">
        {offsetLoading ? (
          <section className="offset-loading">
            <Spinner color={"#60de4f"} width={"20px"} height={"20px"} />
            <p>Loading Offset Polygon...</p>
          </section>
        ) : (
          <>
            <section className="offset-tool-input">
              <label>Offset Distance (m):</label>
              <input type="number" step="1" value={offsetDist} onClick={(e) => e.stopPropagation()} onChange={(e) => changeOffsetDistance(e)} />
            </section>
            <section className="offset-tool-buttons">
              <button onClick={() => offsetPolygon("inside", true)}>Inside</button>
              <button onClick={() => offsetPolygon("outside", true)}>Outside</button>
            </section>
            <section className="offset-tool-close">
              <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => dispatch(portalActions.updateUIControls("offsetToolVisible", false))}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5C25 19.4036 19.4036 25 12.5 25ZM12.4999 22.7272C18.1483 22.7272 22.7272 18.1483 22.7272 12.4999C22.7272 6.85158 18.1483 2.27267 12.4999 2.27267C6.85155 2.27267 2.27264 6.85158 2.27264 12.4999C2.27264 18.1483 6.85155 22.7272 12.4999 22.7272ZM8.75794 17.849L12.4999 14.1071L16.2418 17.849L17.8488 16.242L14.1069 12.5L17.8488 8.75812L16.2418 7.15106L12.4999 10.893L8.75794 7.15106L7.15088 8.75812L10.8928 12.5L7.15088 16.242L8.75794 17.849Z"
                  fill="#60de4f"
                />
              </svg>
            </section>
          </>
        )}
      </OffsetPolygonWrap>
    </Tooltip>
  );
};

export { OffsetPolygonTool };
