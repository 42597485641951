import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { portalActions, carportActions } from '../Redux';

import { Modal, Upload, Tabs, Button, Input } from 'antd';

const Dragger = Upload.Dragger;
const TabPane = Tabs.TabPane;
const { TextArea } = Input;

import { CheckCircleOutlined, InboxOutlined, LoadingOutlined } from '@ant-design/icons';

import * as XLSX from 'xlsx';

const ImportTool = ( {mode} ) => {
  const [importType, setImportType] = useState('file');
  const [fileType, setFileType] = useState('');
  const [uploadFile, setUploadFile] = useState(undefined);
  const [uploadData, setUploadData] = useState(undefined);
  const [uploadText, setUploadText] = useState(undefined);
  const [fileName, setFileName] = useState(undefined);
  // could come from store
  const [uploadError, setUploadError] = useState(undefined);

  const portal = useSelector((state) => state.portal);
  const import_loading = useSelector((state) => state.portal.inputs.import_loading);

  const dispatch = useDispatch();

  function handleLoadFile(file, fileList) {
    // console.log(file.name.toUpperCase().slice(-3))
    let type = file.name.toUpperCase().slice(-3);
    if (type == 'PAN' || type == 'OND' || type == 'CSV' || type == 'XLS' || type == 'LSX') {
      setFileType(type);
      setUploadFile(file);
      setFileName(file.name);
      setUploadError(undefined);
      // this.setState({upload_file: file, upload_name: file.name, file_type: type, upload_error: undefined});
    } else {
      setUploadFile(undefined);
      setUploadError('Cannot Parse this file type.');
      // this.setState({upload_file: undefined, upload_error: 'Cannot Parse this file type.'})
    }
  }

  function handleUpload() {
    let options = {
      'data_type': importType,
      'file_type': fileType,
      'data': importType == 'file' ? uploadData : uploadText,
    };
    if (options.data == undefined) {
      // console.log('cannot import data.')
      return;
    }

    // props.onUpload(options)
    if (mode == "portal") {
      dispatch(portalActions.importData(options));
    } else {
      dispatch(carportActions.importData(options));
    }


    setFileType('');
    setFileName(undefined);
    setImportType('file');
    setUploadFile(undefined);
    setUploadError(undefined);
    setUploadData(undefined);
    setUploadText(undefined);
    // this.setState({uploading:true, just_imported: true}, () => {
    // 	this.props.dispatch(accountActions.importData(options));
    // })
  }

  function handleImportFile() {
    if (uploadFile == undefined) return;
    // console.log('handling file upload')
    var reader = new FileReader();
    reader.onloadend = function(evt) {
      if (evt.target.readyState == FileReader.DONE) {
        // DONE == 2
        var upload_data = undefined;
        var file_type = fileType;
        if (fileType == 'XLS' || fileType == 'LSX') {
          /* Parse data */
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
          /* Update state */
          upload_data = data;
          file_type = 'CSV';
        } else {
          upload_data = evt.target.result;
        }

        setUploadData(upload_data);
        setFileType(file_type);
        // this.setState({
        // 	upload_data: upload_data,
        // 	file_type: file_type
        // })
      }
    }.bind(this);
    reader.readAsBinaryString(uploadFile);
  }

  function handlePasteText(value) {
    setUploadText(value);
    let type = 'PAN';
    setFileType(type);
  }

  return (
    <Modal visible={portal.inputs.toggle_import} onCancel={() => dispatch(portalActions.updateInput('toggle_import', false))} footer={null} width={350} className="sift-modal" destroyOnClose={true}>
      <Tabs type="small" defaultActiveKey="file" onChange={setImportType}>
        <TabPane tab={<span>File</span>} key="file">
          <div className="file-import-body">
            {uploadData || import_loading ? (
              <div className="file-import-ready">
                <div>
                  <p className="file-import-image">{import_loading ? <LoadingOutlined /> : <CheckCircleOutlined />}</p>

                  <p className="file-upload-text">
                    {fileName && <span>{fileName} ready to import</span>}
                    {import_loading && <span>Importing file...</span>}
                  </p>
                </div>
              </div>
            ) : (
              <Dragger action={null} customRequest={handleImportFile} beforeUpload={handleLoadFile} multiple={false} name="file-import" accept={'.pan'} className="sift-dragger">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>

                {uploadError && <p className="file-upload-error">{uploadError}</p>}
              </Dragger>
            )}
          </div>

          <Button type="primary" htmlType="submit" style={{ width: '100%', marginTop: '5px' }} onClick={handleUpload} loading={import_loading}>
            Import
          </Button>
        </TabPane>
        <TabPane tab="PVSyst Text" key="text">
          <div className="file-import-body">
            <TextArea
              rows={9}
              // autosize={true}
              placeholder='Paste PVSyst "Copy to Table" text here.'
              value={uploadText}
              onChange={(e) => handlePasteText(e.target.value)}
            />
          </div>

          <Button type="primary" htmlType="submit" style={{ width: '100%', marginTop: '5px' }} onClick={handleUpload} loading={import_loading}>
            Import
          </Button>
        </TabPane>
      </Tabs>

      {/* {tab == 'weather' && (
        <div>
          <div>
            <div className="tmy-instruction">
              <span>
                If your weather file fails to load, copy your data into
                <a href="https://sift-template-files.s3.us-east-2.amazonaws.com/sift_met_template.xls" download>
                  {' '}
                  this excel template
                </a>{' '}
                and then import.
              </span>
            </div>
          </div>
          <div className="file-import-body">
            {uploadData || import_loading ? (
              <div className="file-import-ready">
                <div>
                  <p className="file-import-image">{import_loading ? <LoadingOutlined /> : <CheckCircleOutlined />}</p>

                  <p className="file-upload-text">
                    {fileName && <span>{fileName} ready to import</span>}
                    {import_loading && <span>Importing file...</span>}
                  </p>
                </div>
              </div>
            ) : (
              <Dragger action={null} customRequest={handleImportFile} beforeUpload={handleLoadFile} multiple={false} name="file-import" accept={'.csv,.xls,.xlsx'} className="sift-dragger">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>

                {uploadError && <p className="file-upload-error">{uploadError}</p>}
              </Dragger>
            )}
          </div>
          <Button type="primary" htmlType="submit" className="login-form-button" onClick={handleUpload} loading={import_loading}>
            Import
          </Button>
        </div>
      )} */}
    </Modal>
  );
};

export { ImportTool };
