import React, { useState, useEffect, useRef, useMemo } from "react";
import { Map, TileLayer, Marker } from "react-leaflet";

//styles
import { ReportMapWrap } from "./styles";

// components
import { ZoomControl } from "./ZoomControl";

// redux
import { useSelector, useDispatch } from "react-redux";
import { portalActions } from "../Redux/actions/portal.actions";

import BingLayer from "../Sunfig/Bing";

const bing_key = "Apg1Wo2sThhv7onPyHncSIy7J3Nn98FFIzAhWYNFDrNLDpCRkZaSic1uuBHRoppM";
const TileSets = {
  Satellite: "Aerial",
  "Satellite With Labels": "AerialWithLabels",
  "Satellite With OnDemand Labels": "AerialWithLabelsOnDemand",
  "Street Map": "Road",
  // "Street Map Dark": "CanvasDark",
  // "CanvasLight": "CanvasLight",
  // "CanvasGray": "CanvasGray"
};
const LocationMap = ({ mapName, setLiveReportMapRefs }) => {
  const inputs = useSelector((state) => state.inputs);
  const zoomGranularity = useSelector((state) => state.portal.inputs.zoomGranularity);

  const dispatch = useDispatch();

  const [activeTileSet, setActivetileSet] = useState("Street Map");
  const [showControls, setShowControls] = useState(false);
  const [zoom, setZoom] = useState(12);
  const [position, setPosition] = useState([inputs.latitude, inputs.longitude]);
  const [bingLayerVisible, setBingLayerVisible] = useState(true);

  const locationMapRef = useRef(null);
  const markerRef = useRef(null);
  useEffect(() => {
    setBingLayerVisible(true);
  }, [activeTileSet]);

  useEffect(() => {
    setLiveReportMapRefs(`${mapName}`, { height: locationMapRef.current.leafletElement._size.y, width: locationMapRef.current.leafletElement._size.x, className: mapName });
  }, []);

  const handleMarkerDrag = () => {
    if (markerRef != null) {
      let pos = markerRef.current.leafletElement.getLatLng();
      setPosition(pos);
      dispatch(portalActions.updateReportData("location", pos));
    }
  };

  const handleLocationZoom = (zoom) => {
    setZoom(zoom);
  };

  const handleLocationZoomExtents = () => {
    locationMapRef.current.leafletElement.flyTo(position, 14);
  };

  return (
    <ReportMapWrap onMouseEnter={() => setShowControls(true)} onMouseLeave={() => setShowControls(false)}>
      <Map
        ref={locationMapRef}
        className={mapName}
        id={mapName}
        animate={true}
        noWrap={true}
        attributionControl={false}
        center={[inputs.latitude, inputs.longitude]}
        zoom={zoom}
        minZoom={0}
        maxZoom={25}
        zoomControl={false}
        zoomSnap={zoomGranularity}
        zoomDelta={zoomGranularity}
        style={{ width: 400, height: 308 }}
      >
        {bingLayerVisible && <BingLayer bingkey={bing_key} type={TileSets[activeTileSet]} maxZoom={25} maxNativeZoom={18} />}

        {showControls && locationMapRef.current && locationMapRef.current.leafletElement && <ZoomControl mapRef={locationMapRef.current.leafletElement} position={position} />}

        <Marker ref={markerRef} draggable={true} ondragend={handleMarkerDrag} position={position} />
      </Map>
    </ReportMapWrap>
  );
};

export { LocationMap };
