import React from 'react';
import { useSelector } from 'react-redux';

import { Popup } from 'react-leaflet';

import * as turf from '@turf/turf';

const CanopyNames = () => {
  const names = useSelector((state) => state.carport.names);
  const visual = useSelector((state) => state.carport.visual);

  if (!names.visible) {
    return <></>;
  }

  const getCenter = (geoJson) => {
    let centerPoint = turf.center(geoJson);
    return { lat: centerPoint.geometry.coordinates[1], lng: centerPoint.geometry.coordinates[0] };
  };

  return (
    <>
      {Object.values(visual).map((vis, index) => {
        return (
          <Popup key={`nameLabel_${index}`} closeOnClick={false} draggable={false} className="canopy-label" position={getCenter(vis.structure)} autoClose={false}>
            <p style={{ margin: '0px' }}>{vis.name}</p>
          </Popup>
        );
      })}
    </>
  );
};

export { CanopyNames };
