import config from "config";
import { authHeader } from "../helpers";
import { verifyToken, refreshToken } from "../../../utils/authenticate";

export const portalServices = {
  save_project_inputs,

  getProjects,
  runTopo,
  getTopoId,
  checkTopoId2,
  checkTopoId,
  checkTopoLoop,
  getCounty,
  _init,
  _getResults,
  _cancelRun,
  _getRacks,
  saveProject,
  getLayout,
  downloadFromS3,
  downloadFromS3toPC,
  offsetPolygon,
  log_downlaod,
};

function getProjects() {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/platform/rbi/project/`, requestOptions).then(handleResponse);
}

async function runTopo(inputs) {
  let token = await localStorage.getItem("access");
  const requestOptions = {
    method: "POST",
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
    body: JSON.stringify(inputs),
  };
  let response = fetch(`${config.apiUrl}/dash/swm/topo/`, requestOptions).then((response) => response.json());
  return response;
}

async function getTopoId(bbox, generate_layers, grade_limits) {
  let token = await localStorage.getItem("access");
  const requestOptions = {
    method: "POST",
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
    body: JSON.stringify({ bbox, generate_layers, grade_limits }),
  };
  let response = fetch(`${config.apiUrl}/dash/topo/`, requestOptions).then((response) => response.json());
  return response;
}

async function checkTopoLoop(topo, inputs) {
  if (topo["topo_id"]) {
    let topo_id = topo["topo_id"];
    var loopBool = true;
    let run_count = 0;

    // polling loop
    while (loopBool) {
      var topo_check = await checkTopoId(topo_id);
      // console.log(topo_check)

      if (topo_check["code"] == 100) {
        loopBool = false;
        let layers_gen = {
          ele: { avail: true },
          NS: {
            avail: true,
            limit: parseFloat(inputs.grade_limits.ns_grade_limit),
          },
          EW: {
            avail: true,
            limit: parseFloat(inputs.grade_limits.ew_grade_limit),
          },
          U: { avail: true, limit: parseFloat(inputs.grade_limits.u_grade_limit) },
        };
        let mode = inputs.selectedProduct.type == 0 ? "EW" : "NS";
        let mode_ext = `${mode}/${layers_gen[mode].limit}`;
        return {
          topo_id: topo_id,
          topo_mode: mode,
          // topo_url: `https://s3.us-east-2.amazonaws.com/topo.xyz/test/${topo_id}/${mode_ext}/{z}/{x}/{y}.png`,
          // topo_scale_url: `https://s3.us-east-2.amazonaws.com/topo.xyz/test/${topo_id}/${mode_ext}/scale.png`,
          topo_url: `https://topo-tiles.sunfig.com/test/${topo_id}/${mode_ext}/{z}/{x}/{y}.png`,
          topo_scale_url: `https://topo-tiles.sunfig.com/test/${topo_id}/${mode_ext}/scale.png`,
          topo_loading: false,
          topo_live: true,
          topo_layers_live: true,
          layers_generated: layers_gen,
        };
      } else if (topo_check["code"] == 97) {
        // ERROR
        loopBool = false;
        return {
          topo_loading: false,
          error: {
            msg: "Error Connecting to Topography Server",
            code: 2,
          },
        };
      } else {
        setTimeout(async () => {}, 10000);
        run_count += 1;
        if (run_count > 10000) {
          loopBool = false;
        }
      }
    }
  }
}

async function checkTopoId2(id) {
  let token = await localStorage.getItem("access");
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
  };
  let response = await fetch(`${config.apiUrl}/dash/swm/topo/?topo_id=${id}`, requestOptions);
  let responseJson = await response.json();
  return responseJson;
}

async function checkTopoId(id) {
  let token = await localStorage.getItem("access");
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
  };
  let response = await fetch(`${config.apiUrl}/dash/topo/?topo_id=${id}`, requestOptions);
  let responseJson = await response.json();
  return responseJson;
}

function handleResponse(response) {
  return response.text().then((text) => {
    // console.log(text)
    let data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
async function getCounty(lat, lon) {
  return fetch(`https://geo.fcc.gov/api/census/area?lat=${lat}&lon=${lon}&format=json`, {
    method: "GET",
  }).then(handleResponse);
}

// used to initialize a run
async function _init(inputs) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      ...inputs,
    }),
  };
  return fetch(`${config.apiUrl}/dash/swm/`, requestOptions).then(handleResponse);

  // let response = await fetch(`${config.apiUrl}/dash/swm/`, {
  //   method: "POST",
  //   headers: { ...authHeader(), "Content-Type": "application/json" },
  //   body: JSON.stringify({
  //     ...inputs,
  //   }),
  // });

  // let responseJson = await response.json();
  // return responseJson;
}

async function _getResults(id) {
  // console.log('_init ID', id);
  let response = await fetch(`${config.apiUrl}/dash/swm/?runId=${id}`, {
    // mode: 'no-cors',
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  });

  let responseJson = await response.json();
  return responseJson;
}

async function _cancelRun(run_id) {
  let response = await fetch(`${config.apiUrl}/dash/swm/`, {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      run_id,
      cancel: true,
    }),
  });

  let responseJson = await response.json();
  return responseJson;
}

async function _getRacks(id) {
  let token = await localStorage.getItem("access");
  if (await verifyToken(token)) {
    // GET http://localhost:8000/platform/rbi/result/?runId=a34ae313fc87420295e9417d61d18543&action=results
    let response = await fetch(`${config.apiUrl}/platform/rbi/result/?runId=${id}&action=racks`, {
      method: "GET",
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    let responseJson = await response.json();
    return responseJson;
  } else {
    let refresh = localStorage.getItem("refresh");
    let access = await refreshToken(refresh);
    if (access) {
      let response = await fetch(`${config.apiUrl}/platform/rbi/result/?runId=${id}&action=racks`, {
        method: "GET",
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${access}`,
        },
      });

      let responseJson = await response.json();
      return responseJson;
    }
  }
}

function getLayout(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${config.apiUrl}/dash/swm/layout?resultId=${id}`, requestOptions).then(handleResponse);
}

function downloadFromS3(url) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "attachment" },
  };
  return fetch(url, requestOptions).then(handleResponse);
}
function downloadFromS3toPC(url) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Disposition": "attachment" },
  };
  return fetch(url, requestOptions);
}
async function save_project_inputs(inputs) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(inputs),
  };
  let responseJson = await fetch(`${config.apiUrl}/platform/rbi/project/`, requestOptions).then((response) => response.json());
  return responseJson;
}

// used to create new and save previously existing projects
async function saveProject(project) {
  let token = await localStorage.getItem("access");
  if (await verifyToken(token)) {
    let response = await fetch(`${config.apiUrl}/platform/rbi/project/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: project.id,
        name: project.name,
        active: project.active,
        weather: project.weather,
        inputs: project.inputs,
        surface: project.surface,
        project_type: project.project_type,
      }),
    });

    let responseJson = await response.json();
    return responseJson;
  } else {
    let refresh = localStorage.getItem("refresh");
    let access = await refreshToken(refresh);
    if (access) {
      let response = await fetch(`${config.apiUrl}/platform/rbi/project/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          id: project.id,
          name: project.name,
          active: project.active,
          weather: project.weather,
          inputs: project.inputs,
          surface: project.surface,
          project_type: project.project_type,
        }),
      });

      let responseJson = await response.json();
      return responseJson;
    }
  }
}

async function offsetPolygon(polygon, offset) {
  let response = await fetch(`${config.apiUrl}/dash/swm/offset`, {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ polygon, offset }),
  });

  let responseJson = await response.json();
  return responseJson;
}

async function log_downlaod(download_type, url, project_id) {
  let response = await fetch(`${config.apiUrl}/dash/log_download/`, {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ download_type, url, project_id }),
  });

  let responseJson = await response.json();
  return responseJson;
}
