import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import { Table, Row, Checkbox, Input, Button, Tooltip } from "antd";
import { CaretLeftOutlined } from "@ant-design/icons";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { portalActions, inputsActions } from "../Redux/actions";
import { Terratrak_2P_SAT, Standard_GFT } from "../Redux/helpers/gmCalculators";

import { close } from "../../assets";

//table letters
const letters = ["A", "B", "C", "D", "E"];

// favorite timing curves 🍌
// Enter: cubic-bezier(0, .5, .5, 1)
// Exit: cubic-bezier(.5, 0, 1, .5)
// Move: cubic-bezier(.5, 0, .5, 1)

const DimsTableWrapper = styled.section`
  width: 100%;
  height: 170px;
  position: relative;
  margin-bottom: 20px;
`;

const DimsReadOnlyTable = styled.section`
  width: 250px;
  background-color: #fff;
  margin: 0px auto;
  inset: 0;
  z-index: 100;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: absolute;
  z-index: 1000;

  .read-only-table {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
    padding: 10px 0px;
    cursor: pointer;

    h2 {
      grid-row: 1;
    }
    p {
      text-align: center;
    }
    table {
      text-align: center;
    }
    table > tbody > tr > td {
      border: 1px solid rgb(217, 217, 217);
    }

    span {
      grid-row: 2;
      margin-left: auto;
      font-size: 18px;
    }
  }
`;

const DimsOverrideTable = styled.section`
  width: 600px;
  position: fixed;
  left: 420px;
  top: ${(props) => props.tableY}px;
  background-color: #fff;
  margin: 0px auto;
  z-index: 500;

  .dims-table {
    width: 100%;
    height: 175px;
    position: relative;
    padding: 10px 7px;
    background-color: #fff;
    border: 1px solid #444444a4;
    display: grid;
    grid-template-columns: repeat(2, auto);
    z-index: 500;

    tra .dims-table-body {
      grid-column: 1;
    }

    .dims-table-calc {
      grid-column: 2;
    }

    .dims-close-button {
      grid-column: 1/ -1;
      position: absolute;
      z-index: 500;
      right: 40px;

      button {
        left: 90%;
      }
    }

    .ant-table-cell {
      padding: 0px 2px !important;
    }

    .dims-share-info {
      position: absolute;
      bottom: 5px;
      left: 10px;
    }
  }
`;

const DimensionOverrideTable = (props) => {
  const portal = useSelector((state) => state.portal);
  const inputs = useSelector((state) => state.inputs);
  const dispatch = useDispatch();

  const [toggleDimsTable, setToggleDimsTable] = useState(false);
  const [tableY, setTableY] = useState(0);

  // const dimsTable = useRef(null);
  const tableWrapper = useRef(null);

	const [local_inputs, set_local_inputs] = useState(inputs)
	useEffect(() => {
		set_local_inputs(inputs)
	}, [inputs]);	

  const calcDimensions = () => {
		let new_racks = JSON.parse(JSON.stringify(local_inputs.racks))

    if (inputs.track_mode == 1) {
      // SAT
			new_racks.forEach( rack => {
				let Tracker_XY = Terratrak_2P_SAT(local_inputs.mod_width, local_inputs.mod_height, rack.module, local_inputs.en_bifacial == 1);
				rack.xdim = _.round(Tracker_XY[0],3);
				rack.ydim = _.round(Tracker_XY[1],3);
			});
      // dispatch(portalActions.updateInput("setYDimensions", undefined));
    } else {
      // GFT
			new_racks.forEach( rack => {
				let mod_count = rack.module;
				if (local_inputs.string_counts.length == 0) {
					// GLIDE products are LIMITED to 24 modules no matter what
					mod_count = 24;
				}
				let Rack_XY = Standard_GFT(
					local_inputs.mod_width,
					local_inputs.mod_height,
					mod_count,
					local_inputs.mods_high,
					local_inputs.orientation,
					local_inputs.vert_gap,
					local_inputs.hori_gap
				);
	
				rack.xdim = _.round(Rack_XY[0],3);
				rack.ydim = _.round(Rack_XY[1],3);
			});		
      // dispatch(portalActions.updateInput("setXDimensions", undefined));
    }

		// 
		updateInput("racks", new_racks)
  };

	const updateInput = (key, value) => {
		set_local_inputs({
			...local_inputs,
			[key]: value
		})
		// do some input validation here
		let fixed_value = value
		// console.log(key, fixed_value)

		if (key == 'mod_width' || key == 'mod_height') {
			if (_.isEmpty(value) || _.isUndefined(value) || _.isNaN(value)) {
				return
			}
			fixed_value = parseFloat(value)
		}

		// console.log(key,fixed_value)
		// dispatch(portalActions.updatePerformance(key,fixed_value));
		dispatch(inputsActions.update_input(key,fixed_value));
	}

	const updateRack = (index, key, value) => {
		let new_racks = JSON.parse(JSON.stringify(local_inputs.racks))
		let fixed_value = value
		if (key == 'xdim' || key == 'ydim') {
			if (!_.isEmpty(value) && !_.isUndefined(value) && !_.isNaN(value)) {
				fixed_value = parseFloat(value)
			} else {
				// should we add an error object to the rack so it's easier to check later?
			}
		}
		if (key == 'module') {
			if (!_.isEmpty(value) && !_.isUndefined(value) && !_.isNaN(value)) {
				fixed_value = parseInt(value)
			} else {
				// should we add an error object to the rack so it's easier to check later?
			}			
		}

		if (inputs.track_mode == 0 && key == 'ydim') {
			// GFT -- loop through and set all ydims to the same
			new_racks.forEach( rack => {
				rack.ydim = fixed_value
			});
		}
		else if (inputs.track_mode == 1 && key == 'xdim') {
			// SAT -- loop through and set all xdims to the same
			new_racks.forEach( rack => {
				rack.xdim = fixed_value
			});
		} else {
			new_racks[index][key] = fixed_value;
		}

		// dispatch(inputsActions.update_input("racks", new_racks));
		updateInput("racks", new_racks)

	}

  const expandTable = (e) => {
    let cy = e.clientY;
    let pageHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    setTableY(Math.min(pageHeight - 217, cy) - 40);
    setToggleDimsTable(!toggleDimsTable);
  };


  return (
    <DimsTableWrapper ref={tableWrapper}>
      <DimsReadOnlyTable>
        <div className="read-only-table" onClick={expandTable}>
          <table>
            <tbody>
              <tr>
                <td> </td>
                <td>Active</td>
                <td>X(m)</td>
                <td>Y(m)</td>
                <td>Mods</td>
              </tr>
              {local_inputs.racks.map((rack) => {
                return (
                  <tr key={rack.key}>
                    <td>{letters[rack.key]}</td>
                    <td>{rack.active == 1 ? "On" : "Off"}</td>
                    <td>{rack.active == 1 && _.round(rack.xdim,2)}</td>
                    <td>{rack.active == 1 && _.round(rack.ydim,2)}</td>
                    <td>{rack.active == 1 && rack.module}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <p>**Click table to edit dimensions**</p>
          <span>•••</span>
        </div>
      </DimsReadOnlyTable>

      <DimsOverrideTable tableY={tableY}>
        {toggleDimsTable && (
          <div className="dims-table">
            <div className="dims-close-button">
              <Button type="link" onClick={() => setToggleDimsTable(!toggleDimsTable)}>
                <img src={close} alt="" />
              </Button>
            </div>
            <div className="dims-table-body">
              <Table
                columns={[
                  {
                    title: "#",
                    dataIndex: "key",
                    align: "center",
                    width: "5px",
                    render: (text, record, index) => <span>{letters[index]}</span>,
                  },
                  {
                    title: "Active",
                    dataIndex: "active",
                    align: "center",
                    width: "55px",
                    render: (text, record, index) => (
                      // TODO INPUTS
                      <Checkbox
                        checked={local_inputs.racks[index].active == 1}
                        disabled={!local_inputs.uiState.do_dimension_override}
                        style={{
                          marginTop: "0px",
                          marginBottom: "0px",
                        }}
                        onChange={(checked) => {
													updateRack(index, "active", checked ? 1 : 0)
                        }}
                      />
                    ),
                  },
                  {
                    title: "X (m)",
                    dataIndex: "xdim",
                    align: "center",
                    width: local_inputs.track_mode == 1 ? "90px" : "110px",
                    render: (text, record, index) => {
                      const obj = {
                        children: text,
                        props: {},
                      };
                      obj.children = (
                        <div style={{ width: local_inputs.track_mode == 1 ? "90px" : "110px" }}>
                          <Input
                            size="small"
                            type="number"
                            disabled={local_inputs.track_mode == 1 && index > 0}
                            value={record.xdim}
                            onChange={(e) => {
															updateRack(index, "xdim", e.target.value)                
                            }}
                            suffix={<span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>m</span>}
                          />
                        </div>
                      );
                      return obj;
                    },
                  },
                  {
                    title: "Y (m)",
                    dataIndex: "ydim",
                    align: "center",
                    width: local_inputs.track_mode == 1 ? "110px" : "90px",
                    render: (text, record, index) => {
                      const obj = {
                        children: text,
                        props: {},
                      };
                      obj.children = (
                        <div style={{ width: local_inputs.track_mode == 1 ? "110px" : "90px" }}>
                          <Input
                            size="small"
                            type="number"
                            disabled={local_inputs.track_mode == 0 && index > 0}
                            value={record.ydim}
                            onChange={(e) => {
															updateRack(index, "ydim", e.target.value)
                            }}
                            suffix={<span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>m</span>}
                          />
                        </div>
                      );

                      return obj;
                    },
                  },
                  {
                    title: "Modules",
                    dataIndex: "modules",
                    align: "center",
                    width: "75px",
                    render: (text, record, index) => (
                      <div style={{ width: "75px" }}>
                        <Input
                          size="small"
                          type="number"
                          value={record.module}
                          onChange={(e) => {
														updateRack(index, "module", e.target.value)          
                          }}
                          suffix={<span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>mod</span>}
                        />
                      </div>
                    ),
                  },
                ]}
                dataSource={local_inputs.racks}
                size="small"
                className="dims-row"
                pagination={false}
                rowKey="key"
              />
            </div>
            <div className="dims-table-calc">
              <Table
                columns={[
                  {
                    title: "Dimension Calc",
                    dataIndex: "calc",
                    key: "calc",
                    render: (text, record, index) => {
                      const obj = {
                        children: text,
                        props: {},
                      };
                      // [⠀]<---INVIS CHARACTER
                      obj.children = (
                        <div style={{ width: "170px" }}>
                          <div
                            style={{
                              lineHeight: "31px",
                            }}
                          >
                            <div
                              style={{
                                float: "left",
                                position: "relative",
                              }}
                            >
                              Mod X
                            </div>
                            <div
                              style={{
                                float: "right",
                              }}
                            >
                              <Input
                                size="small"
                                style={{
                                  width: "100px",
                                }}
                                type="number"
                                value={local_inputs.mod_width}
                                onChange={(e) => {
																	updateInput("mod_width", e.target.value)
                                }}
                                suffix={<span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>m</span>}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              lineHeight: "31px",
                            }}
                          >
                            <div
                              style={{
                                float: "left",
                                position: "relative",
                              }}
                            >
                              Mod Y
                            </div>
                            <div
                              style={{
                                float: "right",
                              }}
                            >
                              <Input
                                size="small"
                                style={{
                                  width: "100px",
                                }}
                                value={local_inputs.mod_height}
                                type="number"
                                onChange={(e) => {
																	updateInput("mod_height", e.target.value)
                                }}
                                suffix={<span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>m</span>}
                              />
                            </div>
                          </div>

                          <div
                            style={{
                              lineHeight: "31px",
                            }}
                          >
                            <div
                              style={{
                                float: "left",
                                position: "relative",
                              }}
                            >
                              Bifacial
                            </div>
                            <div
                              style={{
                                float: "left",
                                margin: "0px 38px",
                              }}
                            >
                              <p>{local_inputs.en_bifacial==1 ? "Yes" : "No"}</p>
                            </div>
                          </div>

                          {/* <div
                            style={{
                              lineHeight: '31px',
                            }}
                          >
                            <div
                              style={{
                                float: 'left',
                                position: 'relative',
                              }}
                            >
                              Mod/Str
                            </div>
                            <div
                              style={{
                                float: 'right',
                              }}
                            >
                              <Input
                                size="small"
                                style={{
                                  width: '100px',
                                }}
                                value={portal.local_inputs.mod_string}
                                type="number"
                                onChange={(e) => {
                                  dispatch(portalActions.updateInput('mod_string', parseInt(e.target.value)));
                                }}
                                // addonAfter={' '}
                                suffix={<span style={{ color: 'rgba(0,0,0,.45)', fontSize: 'x-small' }}>mod</span>}
                              />
                            </div>
                          </div> */}
                          {/* {local_inputs.track_mode == 1 && (
                            <div
                              style={{
                                lineHeight: '31px',
                              }}
                            >
                              <div
                                style={{
                                  float: 'left',
                                  position: 'relative',
                                }}
                              >
                                Table
                              </div>
                              <div
                                style={{
                                  float: 'right',
                                }}
                              >
                                <Input
                                  size="small"
                                  style={{
                                    width: '100px',
                                  }}
                                  value={portal.local_inputs.table_count}
                                  type="number"
                                  onChange={(e) => {
                                    dispatch(portalActions.updateInput('table_count', parseInt(e.target.value)));
                                  }}
                                  // addonAfter={' '}
                                  suffix={<span style={{ color: 'rgba(0,0,0,.45)', fontSize: 'x-small' }}>tab</span>}
                                />
                              </div>
                            </div>
                          )} */}
                          {(local_inputs.uiState.selected_module_index === -1) ? (
                            <Tooltip position="Bottom" title="Please select a module to calculate against">
                              <Button
                                size="small"
                                type="ghost"
                                disabled={true}
                                style={{ width: "75px", float: "right", clear: "both", }}
                              >
                                <span> <CaretLeftOutlined /> Set </span>
                              </Button>
                            </Tooltip>
                          ) : (
                            <Button
                              onClick={calcDimensions}
                              size="small"
                              type="primary"
                              style={{ width: "100px", float: "right", clear: "both", }}
                            >
															<span> <CaretLeftOutlined /> Set </span>
                            </Button>
                          )}
                        </div>
                      );

                      return obj;
                    },
                  },
                ]}
                size="small"
                className="dims-row"
                pagination={false}
                rowKey="calc"
                dataSource={[{ calc: "SAT" }]}
              />
            </div>

            <div className="dims-share-info">{`**All racks share the same ${local_inputs.track_mode == 1 ? "X" : "Y"} dimension**`}</div>
          </div>
        )}
      </DimsOverrideTable>
    </DimsTableWrapper>
  );
};

export { DimensionOverrideTable };
