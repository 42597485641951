import React from "react";

export function getColumns(code = undefined, sortedInfo = undefined) {
  return [
    {
      title: "GCR",
      dataIndex: "gcr",
      key: "gcr",
      width: "85px",
      sorter: (a, b) => a["gcr"] - b["gcr"],
      render: (text, record, index) => parseFloat(text).toFixed(5),
      // sortOrder: sortedInfo.columnKey === 'gcr' && sortedInfo.order,
    },
    {
      title: "Pitch (m)",
      dataIndex: "pitch",
      key: "pitch",
      width: "85px",
      sorter: (a, b) => a["pitch"] - b["pitch"],
      // sortOrder: sortedInfo.columnKey === 'pitch' && sortedInfo.order,
    },
    {
      title: "Tilt (°)",
      dataIndex: "tilt",
      key: "tilt",
      width: "85px",
      sorter: (a, b) => a["tilt"] - b["tilt"],
      // sortOrder: sortedInfo.columnKey === 'tilt' && sortedInfo.order,
    },
    {
      title: "Azimuth (°)",
      dataIndex: "azimuth",
      key: "azimuth",
      width: "85px",
      sorter: (a, b) => a["azimuth"] - b["azimuth"],
      // sortOrder: sortedInfo.columnKey === 'azimuth' && sortedInfo.order,
    },
    {
      title: "Yield (kWh/kWp)",
      dataIndex: "YIELD",
      key: "YIELD",
      width: "120px",
      sorter: (a, b) => a["YIELD"] - b["YIELD"],
      // sortOrder: sortedInfo.columnKey === 'YIELD' && sortedInfo.order,
    },
    {
      title: "DC:AC",
      dataIndex: "dcac",
      key: "dcac",
      width: "120px",
      sorter: (a, b) => a["dcac"] - b["dcac"],
      render: (text, record, index) => parseFloat(text.toFixed(5)),
      // sortOrder: sortedInfo.columnKey === 'dcac' && sortedInfo.order,
    },
    {
      title: "DC Capacity (MWp)",
      dataIndex: "mw_peak",
      key: "mw_peak",
      width: "120px",
      sorter: (a, b) => a["mw_peak"] - b["mw_peak"],
      // sortOrder: sortedInfo.columnKey === 'mw_peak' && sortedInfo.order,
    },
    {
      title: "AC Capacity (MW)",
      dataIndex: "mw_ac",
      key: "mw_ac",
      width: "120px",
      sorter: (a, b) => a["mw_ac"] - b["mw_ac"],
      // sortOrder: sortedInfo.columnKey === 'mw_ac' && sortedInfo.order,
    },
    {
      title: "Generation Yr1 (MWh)",
      dataIndex: "generation",
      key: "generation",
      width: "200px",
      sorter: (a, b) => a["generation"] - b["generation"],
      // sortOrder: sortedInfo.columnKey === 'generation' && sortedInfo.order,
    },
    {
      title: "Module Qty",
      dataIndex: "module_count",
      key: "module_count",
      width: "120px",
      sorter: (a, b) => a["module_count"] - b["module_count"],
      // sortOrder: sortedInfo.columnKey === 'module_count' && sortedInfo.order,
    },
    {
      title: "Inverter Qty",
      dataIndex: "inverter_count",
      key: "inverter_count",
      width: "85px",
      sorter: (a, b) => a["inverter_count"] - b["inverter_count"],
      // sortOrder: sortedInfo.columnKey === 'inverter_count' && sortedInfo.order,
    },
    {
      title: "Strings in Calculation",
      dataIndex: "string_count",
      key: "string_count",
      width: "120px",
      sorter: (a, b) => a["string_count"] - b["string_count"],
      // sortOrder: sortedInfo.columnKey === 'string_count' && sortedInfo.order,
    },
    {
      title: "Strings in Plot",
      dataIndex: "string_count_in_plot",
      key: "string_count_in_plot",
      width: "120px",
      sorter: (a, b) => a["string_count_in_plot"] - b["string_count_in_plot"],
      // sortOrder: sortedInfo.columnKey === 'string_count_in_plot' && sortedInfo.order,
    },
  ];
}

export function resultColumns(type, inputs) {
  if (type == "groundmount") {
    return [
      {
        title: "Racking",
        dataIndex: "racking_name",
        key: "racking_name",
        width: "140px",
        visible: true,
        // render: (result) => <div>{text}</div>,
      },
      {
        title: "Module Rating",
        dataIndex: "mod_rating",
        key: "mod_rating",
        width: "140px",
        visible: true,
        // render: (result) => <div>{text}</div>,
      },
      {
        title: "GCR",
        dataIndex: "gcr",
        key: "gcr",
        width: "50px",
        visible: true,
        sorter: (a, b) => a["gcr"] - b["gcr"],
        render: (result) => parseFloat(result.gcr).toFixed(2),

        // render: (result) => parseFloat(text.toFixed(5)),
        // sortOrder: sortedInfo.columnKey === 'gcr' && sortedInfo.order,
      },
      // {
      //   title: 'Intra-row Spacing (m)',
      //   dataIndex: 'rtr',
      //   key: 'rtr',
      //   width: '85px',
      //   visible: true,
      //   sorter: (a, b) => a['rtr'] - b['rtr'],
      //   // render: (result) => parseFloat(text.toFixed(5)),
      // },
      {
        title: "Pitch (m)",
        dataIndex: "pitch",
        key: "pitch",
        width: "65px",
        visible: true,
        sorter: (a, b) => a["pitch"] - b["pitch"],
        // render: (result) => {
        // 	let pitch;

        // 	if (inputs.track_mode == 0) {
        // 		pitch = _.round(inputs.racks[0].ydim / inputs.gcr, 2)
        // 	} else {
        // 		pitch = _.round(inputs.racks[0].xdim / inputs.gcr, 2)
        // 	}
        // 	return pitch
        // }
      },
      {
        title: `Tilt °`,
        dataIndex: "tilt",
        key: "tilt",
        width: "75px",
        visible: true,
        sorter: (a, b) => a["tilt"] - b["tilt"],
      },
      {
        title: "Yield (kWh/kWp)",
        dataIndex: "y. Yield (kWh/kWp)",
        key: "y. Yield (kWh/kWp)",
        width: "130px",
        visible: true,
        sorter: (a, b) => a["y. Yield (kWh/kWp)"] - b["y. Yield (kWh/kWp)"],
        render: (result) => {
          return <div>{`${parseFloat(result["y. Yield (kWh/kWp)"]).toFixed(2)}`}</div>;
        },
      },
      {
        title: "Generation Yr 1 (MWh)",
        dataIndex: "fh. Energy injected into Grid (MWh)",
        key: "fh. Energy injected into Grid (MWh)",
        width: "185px",
        visible: true,
        sorter: (a, b) => a["fh. Energy injected into Grid (MWh)"] - b["fh. Energy injected into Grid (MWh)"],
        render: (result) => {
          return <div>{`${parseInt(result["fh. Energy injected into Grid (MWh)"])}`}</div>;
        },
      },
      {
        title: "Capacity (MWp)",
        dataIndex: "capacity",
        key: "capacity",
        width: "125px",
        visible: true,
        sorter: (a, b) => a["capacity"] - b["capacity"],
        render: (result) => <div>{parseFloat(result.capacity / 1000).toFixed(2)}</div>,
      },
      {
        title: "Module Qty",
        dataIndex: "module_count",
        key: "module_count",
        width: "90px",
        visible: true,
        sorter: (a, b) => a["module_count"] - b["module_count"],
      },
      {
        title: "Wire Length (ft)",
        dataIndex: "wire_length_ft",
        key: "wire_length_ft",
        width: "90px",
        visible: inputs.do_wiring == 1,
        sorter: (a, b) => a["wire_length_ft"] - b["wire_length_ft"],
      },
      {
        title: "Combiner Box Count",
        dataIndex: "combiner_count",
        key: "combiner_count",
        width: "90px",
        visible: inputs.do_wiring == 1,
        sorter: (a, b) => a["combiner_count"] - b["combiner_count"],
      },
      // {
      //   title: 'Table Qty A',
      //   dataIndex: 'table_a',
      //   key: 'table_a',
      //   width: '100px',
      //   visible: true,
      //   sorter: (a, b) => a['table_a'] - b['table_a'],
      //   // render: (result) => <div>{text}</div>,
      // },
      // {
      //   title: 'Table Qty B',
      //   dataIndex: 'table_b',
      //   key: 'table_b',
      //   width: '100px',
      //   visible: true,
      //   sorter: (a, b) => a['table_b'] - b['table_b'],
      //   // render: (result) => <div>{text}</div>,
      // },
      // {
      //   title: 'Table Qty C',
      //   dataIndex: 'table_c',
      //   key: 'table_c',
      //   width: '100px',
      //   visible: true,
      //   sorter: (a, b) => a['table_c'] - b['table_c'],
      //   // render: (result) => <div>{text}</div>,
      // },
      // {
      //   title: 'Table Qty D',
      //   dataIndex: 'table_d',
      //   key: 'table_d',
      //   width: '100px',
      //   visible: true,
      //   sorter: (a, b) => a['table_d'] - b['table_d'],
      //   // render: (result) => <div>{text}</div>,
      // },
    ];
  }

  if (type == "canopy") {
    return [
      {
        title: "Name",
        key: "Name",
        width: "125px",
        visible: true,
      },
      {
        title: "Module",
        key: "Module",
        width: "120px",
        visible: true,
      },
      {
        title: "Canopy Type",
        key: "Canopy Type",
        width: "120px",
        render: (record) => <div>{prettifyText(record["Canopy Type"])}</div>,
        visible: true,
      },
      {
        title: "Azimuth (°)",
        key: "Azimuth (°)",
        width: "75px",
        visible: true,
      },
      {
        title: "Tilt (°)",
        key: "Tilt (°)",
        width: "70px",
        visible: true,
      },
      {
        title: "Dimensions (LxW) Qty",
        key: "Mod Dimension X Qty",
        width: "160px",
        render: (record) => {
          return <div>{`${parseInt(record["Mod Dimension X Qty"])}mod by ${parseInt(record["Mod Dimension Y Qty"])}mod`}</div>;
        },
        visible: true,
      },
      {
        title: "Dimensions (LxW) ft",
        key: "Mod Dimension X Ft",
        width: "150px",
        render: (record) => {
          return <div>{`${parseFloat(record["Mod Dimension X Ft"]).toFixed(1)} ft by ${parseFloat(record["Mod Dimension Y Ft"]).toFixed(1)} ft`}</div>;
        },
        visible: true,
      },
      {
        title: "Module Qty",
        key: "Module Qty",
        width: "95px",
        visible: true,
      },

      {
        title: "Generation (kWh)",
        key: "Generation (KWh)",
        width: "130px",
        visible: true,
      },
      {
        title: "Yield (kWh/kWp)",
        key: "Yield (kWh/kWp)",
        width: "85px",
        visible: true,
      },
      {
        title: "Lead Quantity",
        key: "Lead Quantity",
        width: "85px",
        visible: true,
      },
      {
        title: "Linear Feet",
        key: "Linear Feet",
        width: "85px",
        visible: true,
      },
      {
        title: "MC4 Connectors",
        key: "MC4 Connectors",
        width: "85px",
        visible: true,
      },
    ];
  }
}

function prettifyText(text) {
  // takes in the ugly text from the product data planeType and makes it a little more presentable
  switch (text) {
    case "single_slope":
      return "Single Slope";
    case "inverted":
      return "Inverted";
    case "dual_tilt":
      return "Dual Tilt";
    default:
      return "undefined";
  }
}
