import React, { useState, useEffect, Fragment } from "react";
import config from "config";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { portalActions, inputsActions, execute_job, topo_job } from "../../Redux";

// antd
import { Button, Menu, Dropdown, Input, Switch, Collapse, Empty, Radio, Popconfirm, Tooltip, Typography, Checkbox, message, Divider } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { jobsConstants } from "../../Redux/constants";

const TopographyInputs = () => {
  // const portal = useSelector((state) => state.portal);
  const inputs = useSelector((state) => state.inputs);
  const dispatch = useDispatch();
  const [local_inputs, set_local_inputs] = useState(inputs);

  useEffect(() => {
    set_local_inputs(inputs);
  }, [inputs]);

  // all or layers
  const clearTopoData = (which_data = "all") => {
    dispatch(inputsActions.clear_topo_data(which_data));
  };
  const updateVisData = (key, value) => {
    // for updating the visual percent inputs boxes
    let key_split = key.split(".");

    let key_copy = local_inputs[key_split[0]];
    key_copy[key_split[1]] = value;

    let local_key = key_split[0];

    set_local_inputs({
      ...local_inputs,
      [local_key]: key_copy,
    });

    if (_.isEmpty(value) || _.isUndefined(value) || _.isNaN(value)) {
      return;
    }
    let fixed_value = parseInt(value);

    key_copy[key_split[1]] = fixed_value;
    dispatch(inputsActions.update_input(key_split[0], key_copy));
  };
  const updateInput = (key, value) => {
    set_local_inputs({
      ...local_inputs,
      [key]: value,
    });
    // do some input validation here
    let fixed_value = value;
    // console.log(key, fixed_value)

    if (key == "grade_target") {
      if (_.isEmpty(value) || _.isUndefined(value) || _.isNaN(value)) {
        return;
      }
      fixed_value = parseFloat(value);
    }

    // console.log(key,fixed_value)
    // dispatch(portalActions.updatePerformance(key,fixed_value));
    dispatch(inputsActions.update_input(key, fixed_value));
  };
  const handleTopoJob = (action) => {
    if (action == "pull_ele") {
      if (!local_inputs.boundary_bbox || !local_inputs.boundary_bbox.every((x) => isFinite(x))) {
        message.error("Draw a boundary in the map before importing Topography");
        return;
      }
    }
    if (action == "calc_grade") {
      if (local_inputs.grade_target <= 0 || local_inputs.grade_target > 100) {
        message.error("Grade Target needs to be a number between 1-100");
        return;
      }
    }
    if (action == "gen_layers") {
      if (
        !local_inputs.uiState.ns_graded_checked &&
        !local_inputs.uiState.ew_graded_checked &&
        !local_inputs.uiState.max_graded_checked &&
        !local_inputs.uiState.ns_ungraded_checked &&
        !local_inputs.uiState.ew_ungraded_checked &&
        !local_inputs.uiState.max_ungraded_checked &&
        !local_inputs.uiState.ele_graded_checked
      ) {
        message.error("At least 1 Visual Layer must be checked before Creating Layers.");
        return;
      }
    }

    //
    dispatch(execute_job(topo_job(local_inputs, action)));
  };

  const hasLayers =
    local_inputs.vis_ele.ungraded_avail ||
    local_inputs.vis_ele.graded_avail ||
    local_inputs.vis_max.ungraded_avail ||
    local_inputs.vis_max.graded__avail ||
    local_inputs.vis_ns.ungraded_avail ||
    local_inputs.vis_ns.graded__avail ||
    local_inputs.vis_ew.ungraded_avail ||
    local_inputs.vis_ew.graded__avail;
  return (
    <div className="topo-input-box">
      {local_inputs.uiState.topo_error && (
        <div className="input-row">
          <label style={{ color: "red" }}>{local_inputs.uiState.topo_error}</label>
        </div>
      )}

      <h3>Elevation</h3>
      <div className="input-row three">
        <label>Source:</label>

        <Radio.Group value={"USGS"}>
          <Radio value={"USGS"}>USGS</Radio>
        </Radio.Group>

        {local_inputs.topo_id ? (
          <div>
            <div className="topo-button">
              <Popconfirm title="Are you sure you want to remove all topography data, including visual layers, from this project?" onConfirm={() => clearTopoData()} okText="Yes" cancelText="No">
                <Button type="link" loading={local_inputs.uiState.topo_loading}>
                  <span>Clear All Topo Data</span>
                </Button>
              </Popconfirm>
            </div>
          </div>
        ) : (
          <div>
            <Tooltip
              placement="right"
              mouseEnterDelay={0.5}
              title={
                <span style={{ width: "300px" }}>
                  It can take up to 2 minutes to import the requested elevation data from USGS. Elevation request will only cover areas on the map marked as boundaries (red). Please ensure your
                  boundaries are complete before proceeding.
                </span>
              }
            >
              <Button onClick={() => handleTopoJob("pull_ele")} type="primary" loading={local_inputs.uiState.topo_loading} ghost>
                <span>Import Elevation</span>
              </Button>
            </Tooltip>
          </div>
        )}
      </div>

      {local_inputs.topo_id ? (
        <Fragment>
          <h3>Grading</h3>
          <Fragment>
            <div className="input-row two">
              <label>Grade Target</label>
              <Input
                className="input-box"
                size="small"
                type="number"
                value={local_inputs.grade_target}
                onChange={(e) => updateInput("grade_target", e.target.value)}
                min="0"
                max="100"
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
              />
            </div>
            <div className="input-row right">
              <div>
                <label>
                  <i>
                    Cut: {local_inputs.grade_cut_amt.toFixed(0)} yd³
                    {"   "}
                    Fill: {local_inputs.grade_fill_amt.toFixed(0)} yd³
                  </i>
                </label>
              </div>
            </div>
            <div className="input-row right">
              <div>
                <Button onClick={() => handleTopoJob("calc_grade")} type="link" loading={local_inputs.uiState.topo_loading}>
                  <span>Calculate Cut/Fill</span>
                </Button>
              </div>
            </div>
          </Fragment>

          <h3>Visual Layers</h3>
          <div className="input-row two">
            <Tooltip
              placement="top"
              title="Visualize the different map layers here. You cannot generate a 
                                Ungraded and Graded layer at the same time but you can generate them separately"
            >
              <label>
                <u>Layer</u>
              </label>
            </Tooltip>
            <div style={{ margin: "auto" }}>
              <Tooltip placement="top" title="Use Ungraded Elevation data">
                <label style={{ padding: "0 4px" }}>
                  <u>U</u>
                </label>
              </Tooltip>
              <Tooltip placement="top" title="Use Graded Elevation data">
                <label style={{ padding: "0 15px" }}>
                  <u>G</u>
                </label>
              </Tooltip>
              {/* <div style={{ width: '125px' }}> */}
              <Tooltip placement="top" title="Values set scale of visual layers">
                <label style={{}}>
                  <u>Visual Scale</u>
                </label>
              </Tooltip>
              {/* </div> */}
            </div>
          </div>

          <div className="input-row two">
            <label>Elevation</label>
            <div style={{ margin: "auto" }}>
              <Tooltip placement="left" title="Ungraded Elevation is generated when Topography is imported">
                {/* this is always generated when use imports elevation */}
                <Checkbox checked={true} disabled />
              </Tooltip>

              <Tooltip placement="right" title="Generate Elevation layer using Graded data">
                <Checkbox
                  style={{ marginLeft: "8px" }}
                  checked={local_inputs.vis_ele.graded_avail || local_inputs.uiState.ele_graded_checked}
                  disabled={local_inputs.uiState.topo_loading || local_inputs.vis_ele.graded_avail || !local_inputs.graded_avail}
                  // onChange={() => dispatch(portalActions.updateInput('ele_use_graded_data', !local_inputs.uiState.ele_graded_checked))}
                  onChange={(e) => dispatch(inputsActions.update_ui_state("ele_graded_checked", e.target.checked))}
                />
              </Tooltip>

              <div style={{ width: "125px" }}></div>
            </div>
          </div>

          <div className="input-row two">
            <label>Max Grade</label>
            <div style={{ margin: "auto" }}>
              <Tooltip placement="left" title="Generate Max Grade layers with Ungraded data">
                <Checkbox
                  checked={local_inputs.vis_max.ungraded_avail || local_inputs.uiState.max_ungraded_checked}
                  disabled={local_inputs.uiState.topo_loading || local_inputs.vis_max.ungraded_avail || (!local_inputs.vis_max.ungraded_avail && local_inputs.uiState.max_graded_checked)}
                  // onChange={() => dispatch(portalActions.updateInput('u_grade_raw_enabled', !local_inputs.uiState.max_ungraded_checked))}
                  onChange={(e) => dispatch(inputsActions.update_ui_state("max_ungraded_checked", e.target.checked))}
                />
              </Tooltip>
              <Tooltip placement="right" title="Generate Resultant layer with Graded data">
                <Checkbox
                  style={{ marginLeft: "8px" }}
                  checked={local_inputs.vis_max.graded_avail || local_inputs.uiState.max_graded_checked}
                  disabled={
                    !local_inputs.graded_avail ||
                    local_inputs.uiState.topo_loading ||
                    local_inputs.vis_max.graded_avail ||
                    (!local_inputs.vis_max.graded_avail && local_inputs.uiState.max_ungraded_checked)
                  }
                  // onChange={() => dispatch(portalActions.updateInput('u_grade_enabled', !local_inputs.uiState.max_graded_checked))}
                  onChange={(e) => dispatch(inputsActions.update_ui_state("max_graded_checked", e.target.checked))}
                />
              </Tooltip>
              <Input
                className="input-box"
                size="small"
                min="0"
                max="100"
                value={local_inputs.vis_max.ungraded_percent}
                disabled={local_inputs.uiState.topo_loading || local_inputs.vis_max.ungraded_avail || local_inputs.vis_max.graded_avail}
                onChange={(e) => {
                  updateVisData("vis_max.ungraded_percent", e.target.value);
                }}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
              />
            </div>
          </div>

          <div className="input-row two">
            <label>NS Grade</label>
            <div style={{ margin: "auto" }}>
              <Tooltip placement="left" title="Generate North-South layers with Ungraded data">
                {/* this is always generated when use imports elevation */}
                <Checkbox
                  checked={local_inputs.vis_ns.ungraded_avail || local_inputs.uiState.ns_ungraded_checked}
                  disabled={local_inputs.uiState.topo_loading || local_inputs.vis_ns.ungraded_avail || (!local_inputs.vis_ns.ungraded_avail && local_inputs.uiState.ns_graded_checked)}
                  // onChange={() => dispatch(portalActions.updateInput('ns_grade_raw_enabled', !local_inputs.uiState.ns_ungraded_checked))}
                  onChange={(e) => dispatch(inputsActions.update_ui_state("ns_ungraded_checked", e.target.checked))}
                />
              </Tooltip>
              <Tooltip placement="right" title="Generate North-South layer with Graded data">
                <Checkbox
                  style={{ marginLeft: "8px" }}
                  checked={local_inputs.vis_ns.graded_avail || local_inputs.uiState.ns_graded_checked}
                  disabled={
                    !local_inputs.graded_avail ||
                    local_inputs.uiState.topo_loading ||
                    local_inputs.vis_ns.graded_avail ||
                    (!local_inputs.vis_ns.graded_avail && local_inputs.uiState.ns_ungraded_checked)
                  }
                  // onChange={() => dispatch(portalActions.updateInput('ns_grade_enabled', !local_inputs.uiState.ns_graded_checked))}
                  onChange={(e) => dispatch(inputsActions.update_ui_state("ns_graded_checked", e.target.checked))}
                />
              </Tooltip>

              <Input
                className="input-box"
                size="small"
                min="0"
                max="100"
                value={local_inputs.vis_ns.ungraded_percent}
                disabled={local_inputs.uiState.topo_loading || local_inputs.vis_ns.ungraded_avail || local_inputs.vis_ns.graded_avail}
                onChange={(e) => {
                  updateVisData("vis_ns.ungraded_percent", e.target.value);
                }}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
              />
            </div>
          </div>

          <div className="input-row two">
            <label>EW Grade</label>
            <div style={{ margin: "auto" }}>
              <Tooltip placement="left" title="Generate East-West layers with Ungraded data">
                {/* this is always generated when use imports elevation */}
                <Checkbox
                  checked={local_inputs.vis_ew.ungraded_avail || local_inputs.uiState.ew_ungraded_checked}
                  disabled={local_inputs.uiState.topo_loading || local_inputs.vis_ew.ungraded_avail || (!local_inputs.vis_ew.ungraded_avail && local_inputs.uiState.ew_graded_checked)}
                  // onChange={() => dispatch(portalActions.updateInput('ew_grade_raw_enabled', !local_inputs.uiState.ew_ungraded_checked))}
                  onChange={(e) => dispatch(inputsActions.update_ui_state("ew_ungraded_checked", e.target.checked))}
                />
              </Tooltip>
              <Tooltip placement="right" title="Generate East-West layer with Graded data">
                <Checkbox
                  style={{ marginLeft: "8px" }}
                  checked={local_inputs.vis_ew.graded_avail || local_inputs.uiState.ew_graded_checked}
                  disabled={
                    !local_inputs.graded_avail ||
                    local_inputs.uiState.topo_loading ||
                    local_inputs.vis_ew.graded_avail ||
                    (!local_inputs.vis_ew.graded_avail && local_inputs.uiState.ew_ungraded_checked)
                  }
                  // onChange={() => dispatch(portalActions.updateInput('ew_grade_enabled', !local_inputs.uiState.ew_graded_checked))}
                  onChange={(e) => dispatch(inputsActions.update_ui_state("ew_graded_checked", e.target.checked))}
                />
              </Tooltip>

              <Input
                className="input-box"
                size="small"
                min="0"
                max="100"
                value={local_inputs.vis_ew.ungraded_percent}
                disabled={local_inputs.uiState.topo_loading || local_inputs.vis_ew.ungraded_avail || local_inputs.vis_ew.graded_avail}
                onChange={(e) => {
                  updateVisData("vis_ew.ungraded_percent", e.target.value);
                }}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
              />
            </div>
          </div>

          <div className="input-row right">
            <div>
              {hasLayers && (
                <Popconfirm
                  title="Are you sure you want to remove all slope analysis visual layers (except elevation and cut/fill), from this project?"
                  onConfirm={() => clearTopoData("layers")}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link" loading={local_inputs.uiState.topo_loading}>
                    <span>Delete Layers</span>
                  </Button>
                </Popconfirm>
              )}

              <Tooltip placement="right" mouseEnterDelay={0.5} title={<span style={{ width: "300px" }}>This creates visual layers for each of the selected slope analysis options.</span>}>
                <Button
                  onClick={() => handleTopoJob("gen_layers")}
                  type="link"
                  loading={local_inputs.uiState.topo_loading}
                  // put disabled logic here if there are no layers that can be created
                >
                  <span>Create Layers</span>
                </Button>
              </Tooltip>
            </div>
          </div>

          <h3>Layout Options:</h3>

          <div className="input-row">
            <label>Act on Surface:</label>
          </div>
          <div className="input-row">
            <div style={{ marginRight: "auto", marginLeft: "10px" }}>
              <Radio.Group
                value={local_inputs.topo_layout_surface}
                onChange={(e) => {
                  dispatch(inputsActions.update_input("topo_layout_surface", e.target.value));
                }}
              >
                <Radio value={"ungraded"}>Ungraded</Radio>
                <Radio value={"graded"} disabled={!local_inputs.graded_avail}>
                  Graded
                </Radio>
              </Radio.Group>
            </div>
          </div>

          <div className="input-row">
            <label>Racking on Topography Actions:</label>
          </div>
          <div className="input-row">
            <div style={{ marginRight: "auto", marginLeft: "10px" }}>
              <Radio.Group
                value={local_inputs.topo_layout_action}
                onChange={(e) => {
                  dispatch(inputsActions.update_input("topo_layout_action", e.target.value));
                }}
              >
                <Radio value={"nothing"}>No Action, Ignore Grade</Radio>
                <Radio value={"delete"}>{`Remove if Grade > Racking Grade Limit`}</Radio>
              </Radio.Group>
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="empty-box" style={{ marginTop: "20px" }}>
          <Empty description="Import Elevation data to view actions" />
        </div>
      )}
    </div>
  );
};

export { TopographyInputs };
