import React, { useEffect, useState, Fragment } from "react";
import { useDynamicRefs } from "../../utils";

import { LiveCanopyReportHeader, LiveCanopyReportMap, LiveCanopyReportSummary, LiveCanopySummaryTable, LiveCanopyReportFooter, LiveCanopyReportDetails } from "./";
import { LiveCanopyReportWrapper } from "./styles";

import { Spin } from "antd";

const d = new Date();
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const LiveCanopyReport = ({ reportData, loadingCanopyLiveReport, setCanopyMapRefs, updateNameAndAddress }) => {
  const [details, setDetails] = useState();
  const [getRef, setRef] = useDynamicRefs();

  const [nameToggle, setNameToggle] = useState(false);
  const [addressToggle, setAddressToggle] = useState(false);

  useEffect(() => {
    if (nameToggle) {
      document.querySelector("#customer-name-input").focus();
    }
  }, [nameToggle]);

  useEffect(() => {
    if (addressToggle) {
      document.querySelector("#project-address-input").focus();
    }
  }, [addressToggle]);

  // this just takes in an array and chunks it up. Using it to create seperate pages for the report details
  const chunkArr = (arr, size) => arr.reduceRight((r, i, _, s) => (r.push(s.splice(0, size)), r), []);

  useEffect(() => {
    // console.log('props', props.reportData);
    if (reportData) {
      setDetails(chunkArr(Object.values(reportData.results), 3));
    }
  }, [reportData]);

  return (
    <LiveCanopyReportWrapper nameToggle={nameToggle} addressToggle={addressToggle}>
      {!loadingCanopyLiveReport && reportData ? (
        <Fragment>
          <section className="canopy-report-page page-1">
            <section className="page-1">
              <div className="date">
                <p>{`${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`}</p>
              </div>
              <LiveCanopyReportHeader data={reportData} />
              <section className="map">{<LiveCanopyReportMap interactiveMap={true} setCanopyMapRefs={setCanopyMapRefs} />}</section>
              <section className="summary-header">
                <LiveCanopyReportSummary reportData={reportData} />
              </section>
              <section className="summary-table">
                <LiveCanopySummaryTable reportData={reportData} />
              </section>
              <section className="footer">{/* <LiveCanopyReportFooter /> */}</section>
            </section>
          </section>
          {details &&
            Object.values(details).map((results, index) => {
              return (
                <section className="canopy-report-details-page" key={index}>
                  <div className="date">
                    <p>{`${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`}</p>
                  </div>
                  {Object.values(results).map((result, index) => {
                    return (
                      <section className="detail-row" key={index}>
                        <LiveCanopyReportDetails result={result} />
                        <LiveCanopyReportMap canopyId={result.id} interactiveMap={false} setRef={setRef} getRef={getRef} setCanopyMapRefs={setCanopyMapRefs} />
                      </section>
                    );
                  })}
                </section>
              );
            })}
        </Fragment>
      ) : (
        <section className="spin-page">
          <Spin size="large" tip="Loading" />
        </section>
      )}
    </LiveCanopyReportWrapper>
  );
};

export { LiveCanopyReport };
