import React, { Fragment, useState, useEffect } from "react";

import { LiveCanopyReportDetailsWrapper } from "./styles";

const LiveCanopyReportDetails = ({ result }) => {
  // console.log("results", result);
  const prettifyText = (text) => {
    // takes in the ugly text from the product data planeType and makes it a little more presentable
    switch (text) {
      case "single_slope":
        return "Single Slope";
      case "inverted":
        return "Inverted";
      case "dual_tilt":
        return "Dual Tilt";
      default:
        return "undefined";
    }
  };
  return (
    <LiveCanopyReportDetailsWrapper>
      <p className="canopy-name-header">{result.Name}</p>
      <section className="canopy-detail-row">
        <p className="canopy-detail-row-label">Canopy Type:</p>
        <p className="canopy-detail-row-value">{prettifyText(result["Canopy Type"])}</p>
      </section>
      <section className="canopy-detail-row">
        <p className="canopy-detail-row-label">Module:</p>
        <p className="canopy-detail-row-value">{result.Module}</p>
      </section>
      <section className="canopy-detail-row">
        <p className="canopy-detail-row-label">Module Qty:</p>
        <p className="canopy-detail-row-value">{result["Module Qty"]}</p>
      </section>
      <section className="canopy-detail-row">
        <p className="canopy-detail-row-label">Capacity:</p>
        <p className="canopy-detail-row-value">{`${result["Capacity (kw)"]}kW`}</p>
      </section>
      <section className="canopy-detail-row">
        <p className="canopy-detail-row-label">Azimuth:</p>
        <p className="canopy-detail-row-value">{`${result["Azimuth (°)"]}°`}</p>
      </section>
      {result["Canopy Type"] == "inverted" ? (
        <Fragment>
          <section className="canopy-detail-row">
            <p className="canopy-detail-row-label">Tilt To Azimuth:</p>
            <p className="canopy-detail-row-value">{`${result["Tilt To Azimuth"]}°`}</p>
          </section>
          <section className="canopy-detail-row">
            <p className="canopy-detail-row-label">Tilt From Azimuth:</p>
            <p className="canopy-detail-row-value">{`${result["Tilt From Azimuth"]}°`}</p>
          </section>
        </Fragment>
      ) : (
        <section className="canopy-detail-row">
          <p className="canopy-detail-row-label">Tilt:</p>
          <p className="canopy-detail-row-value">{`${result["Tilt (°)"]}°`}</p>
        </section>
      )}
      <section className="canopy-detail-row">
        <p className="canopy-detail-row-label">Dimensions (mod):</p>
        <p className="canopy-detail-row-value">{`${result["Mod Dimension X Qty"]} modules by ${result["Mod Dimension Y Qty"]} modules`}</p>
      </section>
      <section className="canopy-detail-row">
        <p className="canopy-detail-row-label">Dimensions (ft):</p>
        <p className="canopy-detail-row-value">{`${result["Mod Dimension X Ft"].toFixed(1)} ft by ${result["Mod Dimension Y Ft"].toFixed(1)} ft`}</p>
      </section>
    </LiveCanopyReportDetailsWrapper>
  );
};

export { LiveCanopyReportDetails };
