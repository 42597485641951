import React, { Fragment } from "react";

import { ts_logo_hori } from "../../assets";

const LiveCanopyReportHeader = (props) => {
  return (
    <Fragment>
      <div className="logo">
        <img src={ts_logo_hori} />
      </div>
      <div className="header_text">
        <h1>{`Canopy Design Summary: ${props.data && props.data.overview.project ? props.data.overview.project : "Solar Canopy"}`}</h1>
      </div>
    </Fragment>
  );
};

export { LiveCanopyReportHeader };
