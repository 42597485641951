import React, { useState, useEffect, useRef, Fragment } from "react";
import { Map, LayerGroup, GeoJSON, TileLayer } from "react-leaflet";
import L from "leaflet";

//styles
import { ReportMapWrap } from "../LiveReport/styles";

// redux
import { useSelector } from "react-redux";

// components
import { zoomControl } from "./";
// helpers
import { getBounds } from "../../utils/map_helper";
import { getTopoImages } from "./";

import BingLayer from "../Sunfig/Bing";
import { ZoomControl } from "./ZoomControl/ZoomControl";

const bing_key = "Apg1Wo2sThhv7onPyHncSIy7J3Nn98FFIzAhWYNFDrNLDpCRkZaSic1uuBHRoppM";
const TileSets = {
  Satellite: "Aerial",
  "Satellite With Labels": "AerialWithLabels",
  "Satellite With OnDemand Labels": "AerialWithLabelsOnDemand",
  "Street Map": "Road",
  // "Street Map Dark": "CanvasDark",
  // "CanvasLight": "CanvasLight",
  // "CanvasGray": "CanvasGray"
};

const TopoMap = ({ mapName, topoMode, setLiveReportMapRefs }) => {
  const portal = useSelector((state) => state.portal);
  const inputs = useSelector((state) => state.inputs);
  const features = useSelector((state) => state.inputs.site_features);
  // const features = useSelector((state) => state.portal.inputs.features);
	
  const [activeTileSet, setActivetileSet] = useState("Satellite");
  const [showControls, setShowControls] = useState(false);
  const [zoom, setZoom] = useState(16);
  const [bingLayerVisible, setBingLayerVisible] = useState(true);

  const topoMap = useRef();

  useEffect(() => {
    setBingLayerVisible(true);
  }, [activeTileSet]);

  const onChangeTileset = (tileset) => {
    setBingLayerVisible(false);
    setActivetileSet(tileset);
  };

  useEffect(() => {
    setTimeout(() => {
      setLiveReportMapRefs(mapName, { height: topoMap.current.leafletElement._size.y, width: topoMap.current.leafletElement._size.x, className: mapName });
      handleTopoMapExtents(16);
    }, 500);
  }, []);

  const handleTopoZoom = (zoom) => {
    setZoom(zoom);
  };

  const handleTopoMapExtents = (zoomLevel = undefined) => {
    // check to make sure there are canopies present on the map
    if (Object.values(features).length == 0) return;
    // define canopy feature array
    let allFeatures = [];

    // loop through all the canopies and push the geoJson into allCanopyFeatures array
    Object.values(features).map((feature) => {
      allFeatures.push(feature);
    });

    // get the bounds of the features collection
    let bounds = getBounds(allFeatures);

    if (zoomLevel) {
      topoMap.current.leafletElement.fitBounds(
        [
          [bounds[1], bounds[0]],
          [bounds[3], bounds[2]],
        ],
        { maxZoom: zoomLevel }
      );
    } else {
      topoMap.current.leafletElement.fitBounds([
        [bounds[1], bounds[0]],
        [bounds[3], bounds[2]],
      ]);
    }
  };

  let southWest = inputs.boundary_bbox && L.latLng(inputs.boundary_bbox[1] - 0.0009, inputs.boundary_bbox[0] - 0.0009),
    northEast = inputs.boundary_bbox && L.latLng(inputs.boundary_bbox[3] + 0.0009, inputs.boundary_bbox[2] + 0.0009),
    boundary_bbox = inputs.boundary_bbox && L.latLngBounds(southWest, northEast);

  return (
    <ReportMapWrap onMouseEnter={() => setShowControls(true)} onMouseLeave={() => setShowControls(false)}>
      <Map
        ref={topoMap}
        id={mapName}
        className={mapName}
        animate={true}
        noWrap={true}
        attributionControl={false}
        center={[inputs.latitude, inputs.longitude]}
        zoom={zoom}
        minZoom={0}
        maxZoom={25}
        onzoomend={() => handleTopoZoom(topoMap.current.leafletElement.getZoom())}
        zoomControl={false}
        zoomSnap={portal.inputs.zoomGranularity}
        zoomDelta={portal.inputs.zoomGranularity}
        style={{ width: "100%", height: "100%" }}
      >
        {bingLayerVisible && <BingLayer bingkey={bing_key} type={TileSets[activeTileSet]} maxZoom={25} maxNativeZoom={18} />}

        {showControls && topoMap.current && topoMap.current.leafletElement && <ZoomControl mapRef={topoMap.current.leafletElement} />}

        <LayerGroup>
          {features &&
            Object.values(features).map((poly) => {
              return (
                <GeoJSON
                  data={poly}
                  key={poly.properties.index}
                  style={{
                    fillColor: "#ffffff",
                    fillOpacity: 0.01,
                    weight: 1,
                    color: poly.properties.identity == 0 ? "white" : poly.properties.identity == 1 ? "red" : poly.properties.identity == 2 ? "yellow" : "white",
                  }}
                ></GeoJSON>
              );
            })}
        </LayerGroup>

        {inputs.topo_id && (
          <TileLayer
            url={getTopoImages(inputs.topo_id, topoMode)}
            opacity={0.7}
            tms
            zIndex={10}
            bounds={boundary_bbox}
            errorTileUrl="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
            crossOrgin
          />
        )}
      </Map>
    </ReportMapWrap>
  );
};

export { TopoMap };
