import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Button } from "antd";

// assets
import { sunfigLogo } from "../../assets";

const PoweredByWrap = styled.section`
  position: absolute;
  width: 100%;
  z-index: 505;
  bottom: 5px;
  display: grid;
  place-content: center;
  pointer-events: none;

  .powered-by-box {
    width: 170px;
    pointer-events: all;

    .powered-by-link {
      display: flex;
      align-items: center;

      p {
        font-size: 11px;
        margin: 0 6px;
        color: #ffffffa7;
      }
    }
  }

  @media (max-width: 1100px) {
    bottom: 25px;
    .powered-by-box {
      place-items: start;
    }
  }
`;

const PoweredBySunfig = () => {
  return (
    <PoweredByWrap>
      <div className="powered-by-box">
        <Button className="powered-by-link" type="link" href="https://sunfig.com/" target="_blank" rel="noopener">
          <p>Powered By</p>
          <img src={sunfigLogo} />
        </Button>
      </div>
    </PoweredByWrap>
  );
};

export { PoweredBySunfig };
