import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import {Authentication} from './components/Auth/Authentication';
import {Routes} from './Routes';

// Redux stuff
import { Provider } from 'react-redux';
// import store from './components/Redux/store';

import { store, history } from './components/Redux/helpers'

import Leaflet from 'leaflet';

// Application Styles
// import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import './app.less';

Leaflet.Icon.Default.imagePath =
  '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/';

// Disable warning "Synchronous XMLHttpRequest on the main thread is deprecated.."
$.ajaxPrefilter(function(options) {
  options.async = true;
});

import LogRocket from 'logrocket';
if (process.env.NODE_ENV === 'production') {
	LogRocket.init('ppao5i/sift-portal');
}

if (module.hot) module.hot.accept();

ReactDOM.render(
  // ADD REDUX PROVIDER HERE
  <Provider store={store}>
    {/* // specify basename below if running // in a subdirectory or set as "/" if
    app runs in root */}
    <Router basename={'/'} history={history}>
      {/* <Authentication> */}
        <Routes />
      {/* </Authentication> */}
    </Router>
    {/* // CLOSE PROVIDER */}
  </Provider>,
  document.getElementById('app')
);
