const products = {
  Aisle1: {
    // module size x/y 1modx, 2mody
    name: 'Canopy 1',
    base_dimension: { modX: 4, modY: 2 },

    // modXQty: 2,
    // modYQty: 1,

    modXGap: 0.02, // in meters
    modYGap: 0.02, // in meters
    edgeOffset: 0.02, // in meters

    orientation: 1, // 1 = portrait (default), 2 = landscape
    planeType: 'single_slope',
    tilt: 7,
    towardAzimuth: 0,
    tiltToAzimuth: 0,
    tiltFromAzimuth: 0,
  },
  //   test2: {
  //     // module size x/y 1modx, 2mody
  //     name: 'Aisle 2',
  //     base_dimension: { modX: 9, modY: 16 },
  //     // modXQty: 2,
  //     // modYQty: 1,

  //     modXGap: 0,
  //     modYGap: 0,
  //     edgeOffset: 0.2,

  //     orientation: 2, // 1 = portrait (default), 2 = landscape
  //     planeType: 'single_slope',
  //     tilt: 7,
  //     towardAzimuth: 0,
  //     tiltToAzimuth: 0,
  //     tiltFromAzimuth: 0,
  //   },
  //   test3: {
  //     // module size x/y 1modx, 2mody
  //     name: 'Aisle 3',
  //     base_dimension: { modX: 15, modY: 10 },
  //     // modXQty: 2,
  //     // modYQty: 1,

  //     modXGap: 0,
  //     modYGap: 0,
  //     edgeOffset: 0.2,

  //     orientation: 1, // 1 = portrait (default), 2 = landscape
  //     planeType: 'single_slope',
  //     tilt: 7,
  //     towardAzimuth: 0,
  //     tiltToAzimuth: 0,
  //     tiltFromAzimuth: 0,
  //   },
  //   test4: {
  //     // module size x/y 1modx, 2mody
  //     name: 'Aisle 4',
  //     base_dimension: { modX: 20, modY: 10 },
  //     // modXQty: 2,
  //     // modYQty: 1,
  //     modXGap: 0,
  //     modYGap: 0,
  //     edgeOffset: 0.001,
  //     orientation: 2, // 1 = portrait (default), 2 = landscape
  //     // planeType: 'inverted',
  //     planeType: 'single_slope',
  //     tilt: 7,
  //     towardAzimuth: 0,
  //     tiltToAzimuth: 0,
  //     tiltFromAzimuth: 0,
  //   },
  //   test5: {
  //     // module size x/y 1modx, 2mody
  //     name: 'Aisle 5',
  //     base_dimension: { modX: 30, modY: 40 },
  //     modXQty: 2,
  //     modYQty: 1,
  //     modXGap: 0,
  //     modYGap: 0,
  //     edgeOffset: 0.4,
  //     orientation: 2, // 1 = portrait (default), 2 = landscape
  //     // planeType: 'dual_tilt',
  //     planeType: 'single_slope',

  //     tilt: 7,
  //     towardAzimuth: 0,
  //     tiltToAzimuth: 0,
  //     tiltFromAzimuth: 0,
  //   },
};

// Product A: "Aisle"
// Default modules width 6 (using RBI terminology, width = short edge)
// Default modules length 10
// Width (y) base_dimension: Module height * Modules Qty Width * (Modules Qty Width - 1) * gap
// Width (x) base_dimension: Module width * Modules Qty Length * (Modules Qty Length - 1) * gap
// Tilt = 5deg

export default products;
