/*eslint no-undef: "error"*/

import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { checkToken, verifyToken } from '../../utils/authenticate';
import PropTypes from 'prop-types';
// import Swal from 'sweetalert2';

import {Spin} from 'antd';
import { isAuthenticated } from '../Redux/reducers'

export const AuthContext = React.createContext();

class Authentication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userAuthenticated: false,
      isLoading: true
    };

    this.login = callback => {
      this.setState({ userAuthenticated: true });
      setTimeout(callback, 100);
    };

    this.logout = callback => {
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      this.setState({ userAuthenticated: false });
      setTimeout(callback, 100);
    };
  }

  async componentDidMount() {
    console.log(this.props)

    // let token = await localStorage.getItem('access');
    // // console.log(token);

    // if (token) {
    //   try {
    //     console.log('checking access token')
    //     if (await verifyToken(token)) {
    //       this.setState({
    //         userAuthenticated: true,
    //         isLoading: false
    //       });
    //     } 
    //     else {
    //       console.log('checking refresh token')
    //       this.setState({
    //         userAuthenticated: false,
    //         isLoading: false
    //       });
    //     }
    //   } catch (e) {
    //     Swal('Error', e.message, 'warning');
    //     this.setState({
    //       userAuthenticated: false,
    //       isLoading: false
    //     });
    //   }
    // } else {
    //   this.setState({
    //     userAuthenticated: false,
    //     isLoading: false
    //   });
    // }
  }

  render() {
    const loader = (
      <React.Fragment>

        <div className='loader'>          
          <Spin/>
          <h3> Loading... </h3>
        </div>

      </React.Fragment>
    );

    const authenticatedComponent = (
      <AuthContext.Provider
        value={{
          state: this.state,
          actions: {
            login: this.login,
            logout: this.logout
          }
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );

    return this.props.isAuthenticated ? loader : authenticatedComponent;
  }
}


function mapStateToProps(state) {
  return {
    isAuthenticated: isAuthenticated(state),
  };
}

const connectedAuthentication = withRouter(connect(mapStateToProps)(Authentication));
export { connectedAuthentication as Authentication };