import React from 'react';
import { Modal, Input, Button, Checkbox, Table, Popconfirm, Select } from 'antd';
const { Option } = Select;
import { UserAddOutlined, ProjectOutlined } from '@ant-design/icons';

import { getTime } from '../../utils';
import matchSorter from 'match-sorter';

export const NewUserModal = (props) => (
  <Modal
    visible={props.visible}
    footer={null}
    closable={true}
    destroyOnClose={true}
    maskClosable={false}
    width={'30%'}
    style={{ maxWidth: '1300px' }}
    onCancel={props.cancel}
  >
    <div>
      <div className="login-label">New User:</div>
      <Input
        id="new_user"
        className="login-input"
        ref={(input) => input && input.focus()}
        prefix={<UserAddOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder="Email"
        onChange={(e) => props.handleChange(e.target.id, e.target.value)}
      />
      <div style={{ paddingTop: '5px', paddingBottom: '10px' }}>
        <Checkbox id="is_admin" onChange={(e) => props.handleChange(e.target.id, e.target.checked)}>
          Admin?
        </Checkbox>
      </div>
      <Button block type="primary" onClick={props.handleSubmit}>
        CREATE
      </Button>

      <div style={{ height: '10px', color: 'red' }}>{props.error}</div>
    </div>
  </Modal>
);

const emails = [
  'jbartosch@rbisolar.com',
  'mmideros@rbisolar.com',
  'kwright@rbisolar.com',
  'lpalmer@rbisolar.com',
  'skneipp@rbisolar.com',
  'eschnese@rbisolar.com',
  'clantz@rbisolar.com',
  'mfreshwater@rbisolar.com',
  'dcompaan@rbisolar.com',
  'jbethel@rbisolar.com',
  'aheid@rbisolar.com',
  'mslack@rbisolar.com',
  'rhartman@rbisolar.com',
  'jcormican@rbisolar.com',
  'dcampbell@rbisolar.com',
  'dvaughn@rbisolar.com',
  'mbeck@rbisolar.com',
  'sborman@rbisolar.com',
  'avanvactor@rbisolar.com',
  'dvukovic@gibraltar1.com',
  'admin@sunfig.com',
  'nicholas.sanderson@sunfig.com',
  'joshua.belknap@sunfig.com',
  'ashton.vandemark@sunfig.com',
  // "mcooke@rbisolar.com",
  // "burbanek@roughbros.com",
  // "bvietas@rbisolar.com",
  // "dvukovic@rbisolar.com",
  // "ashton.vandemark@sunfig.com",
  // "matt.hayes@solarbos.com",
  // "rnixson@rbisolar.com",
];

export class ProjectManagerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sharing: false,
      share_email: undefined,
      project_share: undefined,

      projects: undefined,
      filteredProjects: this.props.FilteredProject,
      filterText: '',
    };

    this.shareModal = undefined;
    this.modalContent = (loading, message) => (
      <div>
        <Select
          showSearch
          disables={loading}
          style={{ width: '100%' }}
          placeholder="Select an Email to share with..."
          optionFilterProp="children"
          onChange={this.onChange}
          onSearch={this.onSearch}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {emails.map((email, index) => {
            return (
              <Option key={index} value={email}>
                {email}
              </Option>
            );
          })}
        </Select>

        <div style={{ marginTop: '5px' }}> {message} </div>

        <div style={{ marginTop: '15px', float: 'right' }}>
          <Button type="primary" onClick={this.onShare} loading={loading}>
            {' '}
            Share
          </Button>
        </div>
      </div>
    );
  }

  componentDidMount = () => {
    // this.setState({
    //   projects: this.props.FilteredProject,
    //   filteredProjects: this.props.FilteredProject,
    // });
  };

  componentDidUpdate(prevProps, prevState) {
    const { FilteredProject } = prevProps;

    if (this.props.FilteredProject != FilteredProject) {
      this.setState({
        projects: this.props.FilteredProject,
        filteredProjects: this.props.FilteredProject,
      });
    }
  }

  onChange = (value) => {
    // console.log(`selected ${value}`);
    this.setState({ share_email: value });
  };

  onSearch = (val) => {
    // console.log('search:', val);
    // this.setState({ share_email: val });
  };

  onShare = async () => {
    // console.log('sharing project with ', this.state.share_email)
    let found_index = emails.findIndex((email) => email == this.state.share_email);
    // console.log(found_index)

    if (found_index >= 0) {
      this.shareModal.update({
        content: this.modalContent(true, undefined),
      });
      this.setState({ sharing: true }, async () => {
        let resp = await this.props.onShareProject(this.state.share_email, this.state.project_share);
        // console.log(resp)

        let message = 'Share complete. Window will close in 2 seconds.';
        this.shareModal.update({
          content: this.modalContent(true, message),
        });

        setTimeout(() => {
          this.setState({ sharing: false, share_email: undefined });
          this.shareModal.destroy();
        }, 2000);
      });
    } else {
      this.setState({ sharing: false });
      // not found
      this.shareModal.update({
        content: this.modalContent(false, 'Share failed. Check email.'),
      });
    }
  };

  filterProjectsByName = () => {
    let filteredProjects =
      matchSorter(Object.values(this.state.projects), this.state.filterText, {
        keys: ['name'],
      }) || Object.values(this.state.projects);
    this.setState({ filteredProjects });

    // Old vanilla javascript code to filter projects. Still works if we need to fall back to it!

    // let filteredProjects = this.state.projects;
    // filteredProjects = filteredProjects.filter((project) => project.name.toLowerCase().includes(this.state.filterText.toLowerCase()));
    // this.setState({ filteredProjects });
  };

  showShareModal = (record) => {
    this.setState({ project_share: record }, () => {
      this.shareModal = Modal.success({
        title: '',
        icon: null,
        okText: 'Share',
        maskClosable: true,
        content: this.modalContent(false, undefined),
        okButtonProps: { style: { display: 'none' } },
      });
    });
  };
  render() {
    return (
      <div>
        <Modal
          closable={false}
          style={{ height: '375px' }}
          // style={this.props.modalMode == 'save' ? {} : {}}
          width={this.props.modalMode == 'save' || this.props.modalMode == 'saveas' ? '20%' : '65%'}
          visible={this.props.visible}
          onCancel={this.props.handleClose}
          footer={
            <div>
              {this.props.modalMode == 'load' && (
                <span style={{ position: 'absolute', left: '15px' }}>
                  <input
                    type="text"
                    value={this.state.filterText && this.state.filterText}
                    onChange={(e) => {
                      this.setState({ filterText: e.target.value }, this.filterProjectsByName);
                      // doing it like this doesn't work sometimes as the filterProjectsByName
                      // was being called before the state was being updated, and filterProjectsByName
                      // requires the state to be updated to be able to filter against what was typed.
                      // this.filterProjectsByName();
                    }}
                    placeholder="Filter projects by name"
                    style={{ width: '200px' }}
                  />
                </span>
              )}

              <Button onClick={this.props.handleClose} disabled={this.props.loading}>
                Close
              </Button>
              {this.props.modalMode == 'save' || this.props.modalMode == 'saveas' ? (
                <Button onClick={this.props.saveProject} loading={this.props.loading} type="primary">
                  Save
                </Button>
              ) : (
                <Button onClick={this.props.loadProject} loading={this.props.loading} type="primary">
                  Load
                </Button>
              )}
            </div>
          }
        >
          <div>
            {this.props.modalMode == 'save' || this.props.modalMode == 'saveas' ? (
              <div style={{ padding: '2%' }}>
                {/* <input style={{ height: 25 }} type="text" onChange={this.props.handleProjectNameUpdate} placeholder="Enter file name here." autoFocus /> */}
                <Input
                  id="project_name"
                  disabled={this.props.loading}
                  className="login-input"
                  ref={(input) => input && input.focus()}
                  prefix={<ProjectOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Enter project name."
                  onChange={(e) => this.props.handleProjectNameUpdate(e.target.value)}
                  onPressEnter={this.props.saveProject}
                />
                
              </div>
            ) : (
              <Table
                loading={this.props.loading}
                dataSource={this.state.filteredProjects}
                size="small"
                rowClassName={(record, index) => (record.id === this.props.selectedProject ? 'editable-row-selected' : 'editable-row')}
                pagination={false}
                rowKey="id"
                useFixedHeader={false}
                bordered
                scroll={{ x: 'max-content', y: 400 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      this.props.handleProjectSelect(event, record, rowIndex);
                    }, // click row
                    onDoubleClick: (event) => {
                      this.props.handleLoadProjectRow(event, record, rowIndex);
                    }, // double click row
                    onContextMenu: (event) => {}, // right button click row
                    onMouseEnter: (event) => {}, // mouse enter row
                    onMouseLeave: (event) => {}, // mouse leave row
                  };
                }}
                onChange={this.props.handleChangeSort}
                columns={[
                  {
                    title: 'Project Name',
                    dataIndex: 'name',
                    sorter: (a, b) => a.name > b.name,
                    sortOrder: this.props.project_sortedInfo.columnKey === 'name' && this.props.project_sortedInfo.order,
                  },
                  {
                    title: 'Modified Date',
                    dataIndex: 'edit_dt',
                    sorter: (a, b) => a.edit_dt - b.edit_dt,
                    sortOrder: this.props.project_sortedInfo.columnKey === 'edit_dt' && this.props.project_sortedInfo.order,
                    render: (text, record) => <span>{getTime(record.edit_dt)}</span>,
                  },
                  {
                    title: 'Actions',
                    render: (text, record, index) => (
                      <div>
                        <Button
                          size="small"
                          type="link"
                          style={{ marginLeft: '5px', marginRight: '5px' }}
                          onClick={(e) => {
                            this.props.handleLoadProjectRow(text, record, index);
                          }}
                        >
                          Load
                        </Button>
                        <span> - </span>
                        <Button
                          type="link"
                          size="small"
                          style={{ marginLeft: '5px', marginRight: '5px' }}
                          onClick={(e) => {
                            this.showShareModal(record);
                          }}
                        >
                          Share
                        </Button>
                        <span> - </span>
                        <Popconfirm
                          title="Delete Project?"
                          onConfirm={() => {
                            this.props.handleDeleteProject(record);
                          }}
                        >
                          <Button type="danger" size="small" style={{ marginLeft: '5px', marginRight: '5px' }}>
                            Delete
                          </Button>
                        </Popconfirm>
                      </div>
                    ),
                  },
                ]}
              />
            )}
          </div>
        </Modal>
      </div>
    );
  }
}
