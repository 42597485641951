import React, { useState, useEffect, useRef, Fragment } from "react";
import { Map, LayerGroup, GeoJSON } from "react-leaflet";

//styles
import { ReportMapWrap } from "../LiveReport/styles";

// redux
import { useSelector } from "react-redux";

// components
import { LiveReportZoomAndLayerControl } from "./LiveReportZoomAndLayerControl";

// helpers
import { getBounds } from "../../utils/map_helper";

import BingLayer from "../Sunfig/Bing";

const bing_key = "Apg1Wo2sThhv7onPyHncSIy7J3Nn98FFIzAhWYNFDrNLDpCRkZaSic1uuBHRoppM";
const TileSets = {
  Satellite: "Aerial",
  "Satellite With Labels": "AerialWithLabels",
  "Satellite With OnDemand Labels": "AerialWithLabelsOnDemand",
  "Street Map": "Road",
  // "Street Map Dark": "CanvasDark",
  // "CanvasLight": "CanvasLight",
  // "CanvasGray": "CanvasGray"
};

const LiveThirtyPercentMap = ({ setCanopyMapRefs }) => {
  const carport = useSelector((state) => state.carport);

  const [activeTileSet, setActivetileSet] = useState("Satellite");
  const [showControls, setShowControls] = useState(false);
  const [zoom, setZoom] = useState(16);
  const [bingLayerVisible, setBingLayerVisible] = useState(true);

  const thirtyPercentLayoutMap = useRef();
  const canopies = useRef({});

  useEffect(() => {
    setBingLayerVisible(true);
  }, [activeTileSet]);

  const onChangeTileset = (tileset) => {
    setBingLayerVisible(false);
    setActivetileSet(tileset);
  };

  useEffect(() => {
    setCanopyMapRefs("thirtyPercentLayoutMap", thirtyPercentLayoutMap.current);
    setTimeout(() => {
      handleThirtyPercentZoomExtents();
    }, 500);
  }, []);

  useEffect(() => {
    let fixed_data = {};
    Object.values(carport.visual).map((vis) => {
      fixed_data[vis.id] = { ...vis };
    });

    canopies.current = fixed_data;
  }, []);

  const handleThirtyPercentMapZoom = (zoom) => {
    setZoom(zoom);
  };

  const handleThirtyPercentZoomExtents = (zoomLevel = undefined) => {
    // check to make sure there are canopies present on the map
    if (Object.values(canopies.current).length == 0) return;
    // define canopy feature array
    let allCanopyFeatures = [];
    // loop through all the canopies and push the geoJson into allCanopyFeatures array
    Object.values(canopies.current).map((canopy) => {
      if (canopy.structure.features == undefined) {
        // polygon structure -- meaning no modules have been turned off
        allCanopyFeatures.push(canopy.structure);
      } else {
        allCanopyFeatures.push(canopy.structure.features[0]);
      }
    });

    // get the bounds of the features collection
    let bounds = getBounds(allCanopyFeatures);

    if (zoomLevel) {
      thirtyPercentLayoutMap?.current?.leafletElement.fitBounds(
        [
          [bounds[1], bounds[0]],
          [bounds[3], bounds[2]],
        ],
        { maxZoom: zoomLevel }
      );
    } else {
      thirtyPercentLayoutMap?.current?.leafletElement.fitBounds([
        [bounds[1], bounds[0]],
        [bounds[3], bounds[2]],
      ]);
    }
  };

  return (
    <ReportMapWrap onMouseEnter={() => setShowControls(true)} onMouseLeave={() => setShowControls(false)}>
      <Map
        ref={thirtyPercentLayoutMap}
        id="thirty-percent-layout"
        className="thirty-percent-layout"
        animate={true}
        noWrap={true}
        attributionControl={false}
        center={[carport.lat, carport.lng]}
        zoom={zoom}
        minZoom={0}
        maxZoom={25}
        onzoomend={() => handleThirtyPercentMapZoom(thirtyPercentLayoutMap.current.leafletElement.getZoom())}
        zoomControl={false}
        zoomSnap={carport.zoomGranularity}
        zoomDelta={carport.zoomGranularity}
        style={{ width: "100%", height: "100%" }}
      >
        {bingLayerVisible && <BingLayer bingkey={bing_key} type={TileSets[activeTileSet]} maxZoom={25} maxNativeZoom={18} />}

        {showControls && thirtyPercentLayoutMap.current && thirtyPercentLayoutMap.current.leafletElement && <LiveReportZoomAndLayerControl mapRef={thirtyPercentLayoutMap.current.leafletElement} />}

        {Object.values(canopies.current).length > 0 && (
          <LayerGroup>
            {Object.values(canopies.current).map((rectangle) => {
              return (
                <Fragment key={`frag_${rectangle.id}`}>
                  {Object.values(rectangle.structure).length > 0 && (
                    <GeoJSON
                      style={{
                        color: "#60de4f",
                        fillColor: "#60de4f",
                        fillOpacity: 0.2,
                        interactive: false,
                      }}
                      transform={false}
                      draggable={false}
                      scaling={false}
                      data={rectangle.structure}
                      key={`cell_id_(${rectangle.id})`}
                    />
                  )}

                  {/* cells */}
                  {Object.values(rectangle.modules).length > 0 &&
                    Object.values(rectangle.modules).map((cell, index) => (
                      <GeoJSON
                        style={{
                          fillColor: cell.properties.enabled ? "#ffffff" : "none",
                          fillOpacity: 0.1,
                          weight: 1,
                          color: cell.properties.enabled ? (cell.properties.override_color ? cell.properties.override_color : "#666") : "none",
                        }}
                        transform={false}
                        draggable={false}
                        scaling={false}
                        data={cell}
                        key={`cell_${rectangle.id}-${index}`}
                      />
                    ))}

                  <GeoJSON style={{ color: "none", fillColor: "none", opacity: "0.8" }} transform={true} draggable={true} scaling={true} data={rectangle.geoJson} key={rectangle.id} />
                </Fragment>
              );
            })}
          </LayerGroup>
        )}
      </Map>
    </ReportMapWrap>
  );
};

export { LiveThirtyPercentMap };
