export const authConstants = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_RESET_PW: 'LOGIN_RESET_PW',

  LOGIN_CONTINUE: 'LOGIN_CONTINUE',

  REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILURE: 'REFRESH_TOKEN_FAILURE',

  PW_RESET_REQUEST: 'PW_RESET_REQUEST',
  PW_RESET_SUCCESS: 'PW_RESET_SUCCESS',
  PW_RESET_FAILURE: 'PW_RESET_FAILURE',

  LOGOUT: 'LOGOUT'
};
