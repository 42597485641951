import React from 'react'
import { Input, } from 'antd';

const EditableRow = ({ index, ...props }) => (
  <tr {...props} />
);

class EditableCell extends React.Component {

  
  state = {
    editing: false,
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  }

  componentDidUpdate() {
    if (this.input) {
      this.input.focus()
    }
  }

  update = (e) => {
    const { record, handeUpdateProjectName } = this.props;
    handeUpdateProjectName({ ...record, projectName: e.target.value });
  }
  save = (e) => {
    const { record, handleSave } = this.props;
    this.toggleEdit();
    handleSave({ ...record, projectName: e.target.value });
  }

  render() {
    // const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      handeUpdateProjectName,
      editing,
      ...restProps
    } = this.props;
    
    return (
      <td ref={node => (this.cell = node)} {...restProps}>
        {editable ? 
          editing ? 
            <Input
              defaultValue={record.projectName}
              style={{maxWidth:250}}
              // ref={input => input && input.focus()}
              ref={node => (this.input = node)}
              onPressEnter={this.save}
              onChange={this.update}
            />
          : 
            <div
              className="editable-cell-value-wrap"
              style={{ paddingRight: 24 }}
            >
              {record.projectName}
            </div>              
        : 
          restProps.children
        }
      </td>
    );
  }
}
  

export { EditableCell, EditableRow }