import styled from "styled-components";

const OffsetPolygonWrap = styled.section`
  width: 246px;
  height: 68px;
  display: grid;
  box-sizing: border-box;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: repeat(2, 1fr);
  gap: 0px 10px;
  padding: 5px;
  grid-template-areas:
    "offset-input close-offset-tool"
    "offset-input offset-buttons";

  .offset-tool-input {
    grid-area: offset-input;
    display: grid;
    grid-template-rows: repeat(2, 1fr);

    label {
      color: #333;
    }

    input {
      width: 100px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      transition: all 0.5s var(--easing-spring);

      &:hover,
      &:focus {
        border: 1px solid #60de4f;
      }
    }
  }

  .offset-tool-buttons {
    grid-area: offset-buttons;
    display: grid;
    grid-template-columns: min-content min-content;
    gap: 0px 5px;

    button {
      width: 60px;
      color: #60de4f;
      border: 1px solid #60de4f;
      background: #fff;
      box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
      border-radius: 4px;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }

  .offset-tool-close {
    width: 100%;
    grid-area: close-offset-tool;
    position: relative;

    svg {
      position: absolute;
      top: -8px;
      right: 0px;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }

  .offset-loading {
    width: 100%;
    height: 100%;
    display: flex;
    place-items: center;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    margin-left: 18px;

    p {
      color: #333;
      margin: 0px 0px 0px 8px;
    }
  }
`;

export { OffsetPolygonWrap };
