import React, { useState, useEffect, Fragment } from "react";
import { basicSearch } from "../../assets";
import { Tooltip, Button, Input, Menu, Dropdown, message } from "antd";

// Geocode for search
import Geocode from "react-geocode";

// redux
import { useSelector, useDispatch } from "react-redux";
import { carportActions } from "../Redux/actions/carport.actions";
import { portalActions } from "../Redux/actions/portal.actions";

const SearchTool = ({ mode }) => {
  const portal = useSelector((state) => state.portal);
  const carport = useSelector((state) => state.carport);
  const dispatch = useDispatch();

  useEffect(() => {
    // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
    Geocode.setApiKey("AIzaSyDFwy0txveMzEYIi42SOSlD2hwq9h9Xqb8");
    // Enable or disable logs. Its optional.
    Geocode.enableDebug();
  });

  const handleSearch = (value) => {
    if (value.length < 3) {
      return;
    }
    // search for the value
    Geocode.fromAddress(value).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;

        if (mode == "canopy") {
          dispatch(carportActions.changeMapCoords({ lng, lat }));
          dispatch(carportActions.setActiveTool(undefined));
        } else if (mode == "ground") {
          dispatch(portalActions.updateMapCenter({ lng, lat }));
        }
      },
      (error) => {
        message.warning("Sorry, that Location was not found");
        console.error(`💥🔥🔥🔥🔥🔥${error}🔥🔥🔥🔥🔥💥`);
      }
    );
  };

  return (
    <Fragment>
      <Tooltip title="Search">
        <Button
          shape="circle"
          size="large"
          style={{
            margin: "0 4px",
            background: mode == "ground" ? (portal.uiControls.searchToolActive ? "#60de4f" : null) : carport.activeTool == "search" ? "#60de4f" : null,
          }}
          onClick={() => {
            if (mode == "canopy") {
              carport.activeTool == "search" ? dispatch(carportActions.setActiveTool(undefined)) : dispatch(carportActions.setActiveTool("search"));
            } else if (mode == "ground") {
              portal.uiControls.isDrawing && dispatch(portalActions.updateUIControls("isDrawing", false));
              portal.uiControls.searchToolActive === true ? dispatch(portalActions.updateUIControls("searchToolActive", false)) : dispatch(portalActions.updateUIControls("searchToolActive", true));
            }
          }}
        >
          <img style={{ marginBottom: "2px" }} src={basicSearch} />
        </Button>
      </Tooltip>

      {mode == "ground" && portal.uiControls.searchToolActive === true ? (
        <Input.Search
          ref={(input) => input && input.focus()}
          style={{ position: "absolute", left: "5px", top: "45px", width: "300px" }}
          placement="buttom"
          placeholder="Latitude, Longitude or Address"
          onSearch={(value) => handleSearch(value)}
        ></Input.Search>
      ) : null}

      {mode == "canopy" && carport.activeTool == "search" ? (
        <Input.Search
          ref={(input) => input && input.focus()}
          style={{ position: "absolute", left: "5px", top: "45px", width: "300px" }}
          placement="buttom"
          placeholder="Latitude, Longitude or Address"
          onSearch={(value) => handleSearch(value)}
        ></Input.Search>
      ) : null}
    </Fragment>
  );
};

export { SearchTool };
