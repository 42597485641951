const defaultModules = [
  {
    do_pvWatts: 1,
    simple_module: 1,
    name: "Simple Module Input",
    mod_name: "Simple Module Input",
    // 'rating': 380,
    mod_rating: 380,
    bi_bifaciality: 0.7,
    // 'mlm_Width': 0.992,
    mod_width: 0.992,
    // 'mlm_Length': 1.658,
    mod_height: 1.658,
    // 'mod_id': "simple"
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 8.25,
    mlm_I_sc_ref: 8.87,
    // 'mlm_Length': 1.658,
    mod_height: 1.658,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 60,
    mlm_R_s: 0.374,
    mlm_R_sh0: 1500,
    mlm_R_shexp: 16,
    mlm_R_shref: 550,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 29.7,
    mlm_V_oc_ref: 37.4,
    // 'mlm_Width': 0.992,
    mod_width: 0.992,
    mlm_alpha_isc: 4.524,
    mlm_beta_voc_spec: -112.948,
    mlm_mu_n: 0,
    mlm_n_0: 1.091,
    // 'module_area': 1.645,
    mod_area: 1.645,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0, 30, 50, 60, 70, 75, 80, 85, 90],
    module_iam_eff: [1, 0.992, 0.972, 0.95, 0.904, 0.857, 0.762, 0.476, 0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.41,
    name: "245W Generic Module",
    mod_name: "245W Generic Module",
    // 'rating': 245,
    mod_rating: 245,
    technology: 1,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 8.251,
    mlm_I_sc_ref: 8.79,
    // 'mlm_Length': 1.65,
    mod_height: 1.65,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 60,
    mlm_R_s: 0.361,
    mlm_R_sh0: 1600,
    mlm_R_shexp: 16,
    mlm_R_shref: 550,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 30.3,
    mlm_V_oc_ref: 38,
    // 'mlm_Width': 0.992,
    mod_width: 0.992,
    mlm_alpha_isc: 4.395,
    mlm_beta_voc_spec: -118.18,
    mlm_mu_n: 0,
    mlm_n_0: 1.06,
    // 'module_area': 1.637,
    mod_area: 1.637,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0, 30, 50, 60, 70, 75, 80, 85, 90],
    module_iam_eff: [1, 0.992, 0.972, 0.95, 0.904, 0.857, 0.762, 0.476, 0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.41,
    name: "250W Generic Module",
    mod_name: "250W Generic Module",
    // 'rating': 250,
    mod_rating: 250,
    technology: 1,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 8.55,
    mlm_I_sc_ref: 9.03,
    // 'mlm_Length': 1.956,
    mod_height: 1.956,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 72,
    mlm_R_s: 0.355,
    mlm_R_sh0: 1000,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 400,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 38.02,
    mlm_V_oc_ref: 46.5,
    // 'mlm_Width': 0.992,
    mod_width: 0.992,
    mlm_alpha_isc: 4.33,
    mlm_beta_voc_spec: -135,
    mlm_mu_n: -0.0005,
    mlm_n_0: 0.938,
    // 'module_area': 1.94,
    mod_area: 1.94,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
    module_iam_eff: [1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.4,
    name: "325W Generic Module",
    mod_name: "325W Generic Module",
    // 'rating': 325,
    mod_rating: 325,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 8.64,
    mlm_I_sc_ref: 9.11,
    // 'mlm_Length': 1.956,
    mod_height: 1.956,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 72,
    mlm_R_s: 0.326,
    mlm_R_sh0: 1800,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 400,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 38.2,
    mlm_V_oc_ref: 46.7,
    // 'mlm_Width': 0.992,
    mod_width: 0.992,
    mlm_alpha_isc: 4.37,
    mlm_beta_voc_spec: -131,
    mlm_mu_n: -0.0005,
    mlm_n_0: 0.967,
    // 'module_area': 1.94,
    mod_area: 1.94,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
    module_iam_eff: [1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.4,
    name: "330W Generic Module",
    mod_name: "330W Generic Module",
    // 'rating': 330,
    mod_rating: 330,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 8.726,
    mlm_I_sc_ref: 9.18,
    // 'mlm_Length': 1.956,
    mod_height: 1.956,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 72,
    mlm_R_s: 0.318,
    mlm_R_sh0: 1800,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 400,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 38.4,
    mlm_V_oc_ref: 46.9,
    // 'mlm_Width': 0.992,
    mod_width: 0.992,
    mlm_alpha_isc: 4.37,
    mlm_beta_voc_spec: -131,
    mlm_mu_n: -0.0005,
    mlm_n_0: 0.958,
    // 'module_area': 1.94,
    mod_area: 1.94,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
    module_iam_eff: [1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.4,
    name: "335W Generic Module",
    mod_name: "335W Generic Module",
    // 'rating': 335,
    mod_rating: 335,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 8.79,
    mlm_I_sc_ref: 9.24,
    // 'mlm_Length': 1.956,
    mod_height: 1.956,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 72,
    mlm_R_s: 0.313,
    mlm_R_sh0: 1800,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 450,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 38.7,
    mlm_V_oc_ref: 47.1,
    // 'mlm_Width': 0.992,
    mod_width: 0.992,
    mlm_alpha_isc: 4.44,
    mlm_beta_voc_spec: -131,
    mlm_mu_n: -0.0006,
    mlm_n_0: 0.948,
    // 'module_area': 1.94,
    mod_area: 1.94,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
    module_iam_eff: [1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.4,
    name: "340W Generic Module",
    mod_name: "340W Generic Module",
    // 'rating': 340,
    mod_rating: 340,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 8.87,
    mlm_I_sc_ref: 9.31,
    // 'mlm_Length': 1.956,
    mod_height: 1.956,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 72,
    mlm_R_s: 0.302,
    mlm_R_sh0: 1800,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 450,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 38.9,
    mlm_V_oc_ref: 47.3,
    // 'mlm_Width': 0.992,
    mod_width: 0.992,
    mlm_alpha_isc: 4.47,
    mlm_beta_voc_spec: -135,
    mlm_mu_n: -0.0006,
    mlm_n_0: 0.949,
    // 'module_area': 1.94,
    mod_area: 1.94,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
    module_iam_eff: [1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.4,
    name: "345W Generic Module",
    mod_name: "345W Generic Module",
    // 'rating': 345,
    mod_rating: 345,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 8.95,
    mlm_I_sc_ref: 9.38,
    // 'mlm_Length': 1.956,
    mod_height: 1.956,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 72,
    mlm_R_s: 0.29,
    mlm_R_sh0: 1800,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 450,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 39.1,
    mlm_V_oc_ref: 47.5,
    // 'mlm_Width': 0.992,
    mod_width: 0.992,
    mlm_alpha_isc: 4.5,
    mlm_beta_voc_spec: -135,
    mlm_mu_n: -0.0006,
    mlm_n_0: 0.952,
    // 'module_area': 1.94,
    mod_area: 1.94,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
    module_iam_eff: [1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.4,
    name: "350W Generic Module",
    mod_name: "350W Generic Module",
    // 'rating': 350,
    mod_rating: 350,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 9.04,
    mlm_I_sc_ref: 9.45,
    // 'mlm_Length': 1.956,
    mod_height: 1.956,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 72,
    mlm_R_s: 0.286,
    mlm_R_sh0: 1800,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 500,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 39.3,
    mlm_V_oc_ref: 47.8,
    // 'mlm_Width': 0.992,
    mod_width: 0.992,
    mlm_alpha_isc: 4.54,
    mlm_beta_voc_spec: -135,
    mlm_mu_n: -0.0006,
    mlm_n_0: 0.958,
    // 'module_area': 1.94,
    mod_area: 1.94,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
    module_iam_eff: [1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.39,
    name: "355W Generic Module",
    mod_name: "355W Generic Module",
    // 'rating': 355,
    mod_rating: 355,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 9.12,
    mlm_I_sc_ref: 9.51,
    // 'mlm_Length': 1.956,
    mod_height: 1.956,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 72,
    mlm_R_s: 0.268,
    mlm_R_sh0: 1800,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 500,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 39.5,
    mlm_V_oc_ref: 48,
    // 'mlm_Width': 0.992,
    mod_width: 0.992,
    mlm_alpha_isc: 4.56,
    mlm_beta_voc_spec: -135,
    mlm_mu_n: -0.0006,
    mlm_n_0: 0.96,
    // 'module_area': 1.94,
    mod_area: 1.94,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
    module_iam_eff: [1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.39,
    name: "360W Generic Module",
    mod_name: "360W Generic Module",
    // 'rating': 360,
    mod_rating: 360,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 9.2,
    mlm_I_sc_ref: 9.57,
    // 'mlm_Length': 1.956,
    mod_height: 1.956,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 72,
    mlm_R_s: 0.254,
    mlm_R_sh0: 1800,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 550,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 39.7,
    mlm_V_oc_ref: 48.2,
    // 'mlm_Width': 0.992,
    mod_width: 0.992,
    mlm_alpha_isc: 4.59,
    mlm_beta_voc_spec: -135,
    mlm_mu_n: -0.0007,
    mlm_n_0: 0.963,
    // 'module_area': 1.94,
    mod_area: 1.94,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
    module_iam_eff: [1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.39,
    name: "365W Generic Module",
    mod_name: "365W Generic Module",
    // 'rating': 365,
    mod_rating: 365,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 9.28,
    mlm_I_sc_ref: 9.61,
    // 'mlm_Length': 1.956,
    mod_height: 1.956,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 72,
    mlm_R_s: 0.236,
    mlm_R_sh0: 1800,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 600,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 39.9,
    mlm_V_oc_ref: 48.5,
    // 'mlm_Width': 0.992,
    mod_width: 0.992,
    mlm_alpha_isc: 4.61,
    mlm_beta_voc_spec: -135,
    mlm_mu_n: -0.0007,
    mlm_n_0: 0.967,
    // 'module_area': 1.94,
    mod_area: 1.94,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
    module_iam_eff: [1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.39,
    name: "370W Generic Module",
    mod_name: "370W Generic Module",
    // 'rating': 370,
    mod_rating: 370,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 9.33,
    mlm_I_sc_ref: 9.68,
    // 'mlm_Length': 1.956,
    mod_height: 1.956,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 72,
    mlm_R_s: 0.229,
    mlm_R_sh0: 1800,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 550,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 40.2,
    mlm_V_oc_ref: 48.7,
    // 'mlm_Width': 0.992,
    mod_width: 0.992,
    mlm_alpha_isc: 4.61,
    mlm_beta_voc_spec: -135,
    mlm_mu_n: -0.0007,
    mlm_n_0: 0.97,
    // 'module_area': 1.94,
    mod_area: 1.94,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
    module_iam_eff: [1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.39,
    name: "375W Generic Module",
    mod_name: "375W Generic Module",
    // 'rating': 375,
    mod_rating: 375,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 9.44,
    mlm_I_sc_ref: 9.75,
    // 'mlm_Length': 1.956,
    mod_height: 1.956,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 72,
    mlm_R_s: 0.213,
    mlm_R_sh0: 1800,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 650,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 40.3,
    mlm_V_oc_ref: 48.9,
    // 'mlm_Width': 0.992,
    mod_width: 0.992,
    mlm_alpha_isc: 4.61,
    mlm_beta_voc_spec: -135,
    mlm_mu_n: -0.0008,
    mlm_n_0: 0.973,
    // 'module_area': 1.94,
    mod_area: 1.94,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
    module_iam_eff: [1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.39,
    name: "380W Generic Module",
    mod_name: "380W Generic Module",
    // 'rating': 380,
    mod_rating: 380,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 9.53,
    mlm_I_sc_ref: 9.89,
    // 'mlm_Length': 1.956,
    mod_height: 1.956,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 72,
    mlm_R_s: 0.242,
    mlm_R_sh0: 1800,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 550,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 40.4,
    mlm_V_oc_ref: 49.1,
    // 'mlm_Width': 0.992,
    mod_width: 0.992,
    mlm_alpha_isc: 4.61,
    mlm_beta_voc_spec: -135,
    mlm_mu_n: -0.0008,
    mlm_n_0: 0.979,
    // 'module_area': 1.94,
    mod_area: 1.94,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
    module_iam_eff: [1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.39,
    name: "385W Generic Module",
    mod_name: "385W Generic Module",
    // 'rating': 385,
    mod_rating: 385,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 9.54,
    mlm_I_sc_ref: 9.93,
    // 'mlm_Length': 1.956,
    mod_height: 1.956,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 72,
    mlm_R_s: 0.274,
    mlm_R_sh0: 2000,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 500,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 40.59,
    mlm_V_oc_ref: 49.55,
    // 'mlm_Width': 0.992,
    mod_width: 0.992,
    mlm_alpha_isc: 4.87,
    mlm_beta_voc_spec: -140.6,
    mlm_mu_n: -0.0008,
    mlm_n_0: 0.991,
    // 'module_area': 1.94,
    mod_area: 1.94,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
    module_iam_eff: [1.0, 1.0, 1.0, 1.0, 1.0, 0.96, 0.923, 0.81, 0.0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.39,
    name: "390W Generic Module",
    mod_name: "390W Generic Module",
    // 'rating': 390,
    mod_rating: 390,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 9.46,
    mlm_I_sc_ref: 10.19,
    // 'mlm_Length': 2.064,
    mod_height: 2.064,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 72,
    mlm_R_s: 0.364,
    mlm_R_sh0: 3500,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 1200,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 41.8,
    mlm_V_oc_ref: 50.5,
    // 'mlm_Width': 1.024,
    mod_width: 1.024,
    mlm_alpha_isc: 3.057,
    mlm_beta_voc_spec: -131.3,
    mlm_mu_n: 0,
    mlm_n_0: 1.06,
    // 'module_area': 2.114,
    mod_area: 2.114,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0, 30, 50, 60, 70, 75, 80, 85, 90],
    module_iam_eff: [1, 0.992, 0.972, 0.95, 0.904, 0.857, 0.762, 0.476, 0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.36,
    name: "395W Generic Module",
    mod_name: "395W Generic Module",
    // 'rating': 395,
    mod_rating: 395,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 9.49,
    mlm_I_sc_ref: 10.23,
    // 'mlm_Length': 2.064,
    mod_height: 2.064,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 72,
    mlm_R_s: 0.345,
    mlm_R_sh0: 3500,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 1500,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 42.2,
    mlm_V_oc_ref: 50.5,
    // 'mlm_Width': 1.024,
    mod_width: 1.024,
    mlm_alpha_isc: 3.069,
    mlm_beta_voc_spec: -131.3,
    mlm_mu_n: 0,
    mlm_n_0: 1.021,
    // 'module_area': 2.114,
    mod_area: 2.114,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0, 30, 50, 60, 70, 75, 80, 85, 90],
    module_iam_eff: [1, 0.992, 0.972, 0.95, 0.904, 0.857, 0.762, 0.476, 0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.36,
    name: "400W Generic Module",
    mod_name: "400W Generic Module",
    // 'rating': 400,
    mod_rating: 400,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 10.83,
    mlm_I_sc_ref: 11.39,
    // 'mlm_Length': 1.942,
    mod_height: 1.942,
    mlm_N_diodes: 3,
    mlm_N_parallel: 6,
    mlm_N_series: 68,
    mlm_R_s: 0.231,
    mlm_R_sh0: 1400,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 350,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 37.4,
    mlm_V_oc_ref: 45.3,
    // 'mlm_Width': 1.069,
    mod_width: 1.069,
    mlm_alpha_isc: 6.834,
    mlm_beta_voc_spec: -135.9,
    mlm_mu_n: 0,
    mlm_n_0: 0.962,
    // 'module_area': 2.076,
    mod_area: 2.076,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0, 30, 50, 60, 70, 75, 80, 85, 90],
    module_iam_eff: [1, 0.992, 0.972, 0.95, 0.904, 0.857, 0.762, 0.476, 0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.38,
    name: "405W Generic Module",
    mod_name: "405W Generic Module",
    // 'rating': 405,
    mod_rating: 405,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 10.93,
    mlm_I_sc_ref: 11.43,
    // 'mlm_Length': 1.942,
    mod_height: 1.942,
    mlm_N_diodes: 3,
    mlm_N_parallel: 6,
    mlm_N_series: 68,
    mlm_R_s: 0.204,
    mlm_R_sh0: 1600,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 400,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 37.5,
    mlm_V_oc_ref: 45.3,
    // 'mlm_Width': 1.069,
    mod_width: 1.069,
    mlm_alpha_isc: 6.858,
    mlm_beta_voc_spec: -135.9,
    mlm_mu_n: 0,
    mlm_n_0: 0.959,
    // 'module_area': 2.076,
    mod_area: 2.076,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0, 30, 50, 60, 70, 75, 80, 85, 90],
    module_iam_eff: [1, 0.992, 0.972, 0.95, 0.904, 0.857, 0.762, 0.476, 0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.38,
    name: "410W Generic Module",
    mod_name: "410W Generic Module",
    // 'rating': 410,
    mod_rating: 410,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 11.04,
    mlm_I_sc_ref: 11.47,
    // 'mlm_Length': 1.942,
    mod_height: 1.942,
    mlm_N_diodes: 3,
    mlm_N_parallel: 6,
    mlm_N_series: 68,
    mlm_R_s: 0.187,
    mlm_R_sh0: 1800,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 450,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 37.6,
    mlm_V_oc_ref: 45.4,
    // 'mlm_Width': 1.069,
    mod_width: 1.069,
    mlm_alpha_isc: 6.882,
    mlm_beta_voc_spec: -136.2,
    mlm_mu_n: 0,
    mlm_n_0: 0.941,
    // 'module_area': 2.076,
    mod_area: 2.076,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0, 30, 50, 60, 70, 75, 80, 85, 90],
    module_iam_eff: [1, 0.992, 0.972, 0.95, 0.904, 0.857, 0.762, 0.476, 0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.38,
    name: "415W Generic Module",
    mod_name: "415W Generic Module",
    // 'rating': 415,
    mod_rating: 415,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 11.14,
    mlm_I_sc_ref: 11.51,
    // 'mlm_Length': 1.942,
    mod_height: 1.942,
    mlm_N_diodes: 3,
    mlm_N_parallel: 6,
    mlm_N_series: 68,
    mlm_R_s: 0.152,
    mlm_R_sh0: 2000,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 500,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 37.7,
    mlm_V_oc_ref: 45.4,
    // 'mlm_Width': 1.069,
    mod_width: 1.069,
    mlm_alpha_isc: 6.906,
    mlm_beta_voc_spec: -136.2,
    mlm_mu_n: 0,
    mlm_n_0: 0.941,
    // 'module_area': 2.076,
    mod_area: 2.076,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0, 30, 50, 60, 70, 75, 80, 85, 90],
    module_iam_eff: [1, 0.992, 0.972, 0.95, 0.904, 0.857, 0.762, 0.476, 0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.38,
    name: "420W Generic Module",
    mod_name: "420W Generic Module",
    // 'rating': 420,
    mod_rating: 420,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 11.25,
    mlm_I_sc_ref: 11.55,
    // 'mlm_Length': 1.942,
    mod_height: 1.942,
    mlm_N_diodes: 3,
    mlm_N_parallel: 6,
    mlm_N_series: 68,
    mlm_R_s: 0.119,
    mlm_R_sh0: 2500,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 650,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 37.8,
    mlm_V_oc_ref: 45.5,
    // 'mlm_Width': 1.069,
    mod_width: 1.069,
    mlm_alpha_isc: 6.93,
    mlm_beta_voc_spec: -136.5,
    mlm_mu_n: 0,
    mlm_n_0: 0.943,
    // 'module_area': 2.076,
    mod_area: 2.076,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0, 30, 50, 60, 70, 75, 80, 85, 90],
    module_iam_eff: [1, 0.992, 0.972, 0.95, 0.904, 0.857, 0.762, 0.476, 0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.38,
    name: "425W Generic Module",
    mod_name: "425W Generic Module",
    // 'rating': 425,
    mod_rating: 425,
    technology: 0,
  },
  {
    default: 0,
    name: "430W Generic Module",
    mod_name: "430W Generic Module",
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 9.9,
    mlm_I_sc_ref: 10.4,
    // 'mlm_Length': 2.067,
    mod_height: 2.067,
    mlm_N_diodes: 4,
    mlm_N_parallel: 6,
    mlm_N_series: 82,
    mlm_R_s: 0.156,
    mlm_R_sh0: 934,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 240,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 43.4,
    mlm_V_oc_ref: 52.7,
    // 'mlm_Width': 0.998,
    mod_width: 0.998,
    mlm_alpha_isc: 4.7,
    mlm_beta_voc_spec: -150,
    mlm_mu_n: -0.0003,
    mlm_n_0: 0.94,
    // 'module_area': 2.063,
    mod_area: 2.063,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0, 30, 50, 60, 70, 75, 80, 85, 90],
    module_iam_eff: [1, 0.992, 0.972, 0.95, 0.904, 0.857, 0.762, 0.476, 0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.38,
    // 'rating': 430,
    mod_rating: 430,
    technology: 0,
  },
  {
    default: 0,
    name: "435W Generic Module",
    mod_name: "435W Generic Module",
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 5.97,
    mlm_I_sc_ref: 6.43,
    // 'mlm_Length': 2.067,
    mod_height: 2.067,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 128,
    mlm_R_s: 0.6,
    mlm_R_sh0: 18500,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 4591,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 72.9,
    mlm_V_oc_ref: 85.6,
    // 'mlm_Width': 1.046,
    mod_width: 1.046,
    mlm_alpha_isc: 3.472,
    mlm_beta_voc_spec: -235.4,
    mlm_mu_n: 0,
    mlm_n_0: 1.039,
    // 'module_area': 2.162,
    mod_area: 2.162,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0, 30, 50, 60, 70, 75, 80, 85, 90],
    module_iam_eff: [1, 0.992, 0.972, 0.95, 0.904, 0.857, 0.762, 0.476, 0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.38,
    // 'rating': 435,
    mod_rating: 435,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0,
    simple_module: 0,
    mlm_E_g: 1.121,
    mlm_I_mp_ref: 5.97,
    mlm_I_sc_ref: 6.43,
    // 'mlm_Length': 2.067,
    mod_height: 2.067,
    mlm_N_diodes: 3,
    mlm_N_parallel: 1,
    mlm_N_series: 128,
    mlm_R_s: 0.6,
    mlm_R_sh0: 18500,
    mlm_R_shexp: 5.5,
    mlm_R_shref: 4591,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 72.9,
    mlm_V_oc_ref: 85.6,
    // 'mlm_Width': 1.046,
    mod_width: 1.046,
    mlm_alpha_isc: 3.472,
    mlm_beta_voc_spec: -235.4,
    mlm_mu_n: 0,
    mlm_n_0: 1.039,
    // 'module_area': 2.162,
    mod_area: 2.162,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0, 30, 50, 60, 70, 75, 80, 85, 90],
    module_iam_eff: [1, 0.992, 0.972, 0.95, 0.904, 0.857, 0.762, 0.476, 0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.38,
    name: "SunPower 435W",
    mod_name: "SunPower 435W",
    // 'rating': 435,
    mod_rating: 435,
    technology: 0,
  },
  {
    mlm_D2MuTau: 0.7,
    simple_module: 0,
    mlm_E_g: 1.5,
    mlm_I_mp_ref: 2.432,
    mlm_I_sc_ref: 2.652,
    // 'mlm_Length': 2.46,
    mod_height: 2.46,
    mlm_N_diodes: 1,
    mlm_N_parallel: 1,
    mlm_N_series: 260,
    mlm_R_s: 4.791,
    mlm_R_sh0: 7200,
    mlm_R_shexp: 8,
    mlm_R_shref: 6800,
    mlm_S_ref: 1000,
    mlm_T_c_fa_alpha: 0.9,
    mlm_T_ref: 25,
    mlm_V_mp_ref: 182.91,
    mlm_V_oc_ref: 216.45,
    // 'mlm_Width': 1,
    mod_width: 1,
    mlm_alpha_isc: 1.06,
    mlm_beta_voc_spec: -603,
    mlm_mu_n: 0,
    mlm_n_0: 1.1731,
    // 'module_area': 2.46,
    mod_area: 2.46,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    module_iam_ang: [0.0, 30.0, 55.0, 60.0, 65.0, 70.0, 75.0, 80.0, 90.0],
    module_iam_eff: [1.0, 1.0, 0.99, 0.98, 0.96, 0.92, 0.85, 0.72, 0.0],
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    muPmpReq: -0.28,
    name: "445W First Solar S6",
    mod_name: "445W First Solar S6",
    // 'rating': 445,
    mod_rating: 445,
    technology: 2,
  },
  {
    mlm_N_series: 72.0,
    mlm_N_parallel: 2.0,
    mlm_N_diodes: 3.0,
    // 'mlm_Width': 1.048,
    mod_width: 1.048,
    // 'mlm_Length': 2.128,
    mod_height: 2.128,
    mlm_V_mp_ref: 41.13,
    mlm_I_mp_ref: 10.7,
    mlm_V_oc_ref: 49.5,
    mlm_I_sc_ref: 11.3,
    mlm_S_ref: 1000.0,
    mlm_T_ref: 25.0,
    mlm_R_shref: 750.0,
    mlm_R_sh0: 3000.0,
    mlm_R_shexp: 5.5,
    mlm_R_s: 0.264,
    mlm_alpha_isc: 5.65,
    mlm_beta_voc_spec: -145.0,
    mlm_n_0: 0.98,
    mlm_mu_n: -0.0006,
    mlm_T_c_fa_alpha: 0.9,
    mlm_D2MuTau: 0.0,
    simple_module: 0,
    // 'module_bifaciality': 0.75,
    bi_bifaciality: 0.75,
    // 'module_isbifacial': 1,
    en_bifacial: 1,
    mlm_E_g: 1.121,
    // 'module_area': 2.23,
    mod_area: 2.23,
    module_iam_ang: [0, 10.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
    module_iam_eff: [1, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 0.963, 0.892, 0.0],
    // 'rating': 440,
    mod_rating: 440,
    name: "440W Generic Module",
    mod_name: "440W Generic Module",
    technology: 0,
    muPmpReq: -0.36,
    default: 0,
  },
  {
    mlm_N_series: 72.0,
    mlm_N_parallel: 2.0,
    mlm_N_diodes: 3.0,
    // 'mlm_Width': 1.048,
    mod_width: 1.048,
    // 'mlm_Length': 2.128,
    mod_height: 2.128,
    mlm_V_mp_ref: 41.25,
    mlm_I_mp_ref: 10.8,
    mlm_V_oc_ref: 49.6,
    mlm_I_sc_ref: 11.4,
    mlm_S_ref: 1000.0,
    mlm_T_ref: 25.0,
    mlm_R_shref: 750.0,
    mlm_R_sh0: 3000.0,
    mlm_R_shexp: 5.5,
    mlm_R_s: 0.264,
    mlm_alpha_isc: 5.65,
    mlm_beta_voc_spec: -145.0,
    mlm_n_0: 0.98,
    mlm_mu_n: -0.0006,
    mlm_T_c_fa_alpha: 0.9,
    mlm_D2MuTau: 0.0,
    simple_module: 0,
    // 'module_bifaciality': 0.75,
    bi_bifaciality: 0.75,
    // 'module_isbifacial': 1,
    en_bifacial: 1,
    mlm_E_g: 1.121,
    // 'module_area': 2.23,
    mod_area: 2.23,
    module_iam_ang: [0, 10.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
    module_iam_eff: [1, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 0.963, 0.892, 0.0],
    // 'rating': 445,
    mod_rating: 445,
    name: "445W Generic Module",
    mod_name: "445W Generic Module",
    technology: 0,
    muPmpReq: -0.36,
    default: 0,
  },
  {
    mlm_N_series: 72.0,
    mlm_N_parallel: 2.0,
    mlm_N_diodes: 3.0,
    // 'mlm_Width': 1.048,
    mod_width: 1.048,
    // 'mlm_Length': 2.128,
    mod_height: 2.128,
    mlm_V_mp_ref: 41.3,
    mlm_I_mp_ref: 10.9,
    mlm_V_oc_ref: 49.7,
    mlm_I_sc_ref: 11.5,
    mlm_S_ref: 1000.0,
    mlm_T_ref: 25.0,
    mlm_R_shref: 750.0,
    mlm_R_sh0: 3000.0,
    mlm_R_shexp: 5.5,
    mlm_R_s: 0.264,
    mlm_alpha_isc: 5.65,
    mlm_beta_voc_spec: -145.0,
    mlm_n_0: 0.98,
    mlm_mu_n: -0.0006,
    mlm_T_c_fa_alpha: 0.9,
    mlm_D2MuTau: 0.0,
    simple_module: 0,
    // 'module_bifaciality': 0.75,
    bi_bifaciality: 0.75,
    // 'module_isbifacial': 1,
    en_bifacial: 1,
    mlm_E_g: 1.121,
    // 'module_area': 2.23,
    mod_area: 2.23,
    module_iam_ang: [0, 10.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
    module_iam_eff: [1, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 0.963, 0.892, 0.0],
    // 'rating': 450,
    mod_rating: 450,
    name: "450W Generic Module",
    mod_name: "450W Generic Module",
    technology: 0,
    muPmpReq: -0.36,
    default: 0,
  },
  {
    mlm_N_series: 72.0,
    mlm_N_parallel: 2.0,
    mlm_N_diodes: 3.0,
    // 'mlm_Width': 1.048,
    mod_width: 1.048,
    // 'mlm_Length': 2.128,
    mod_height: 2.128,
    mlm_V_mp_ref: 41.4,
    mlm_I_mp_ref: 11.0,
    mlm_V_oc_ref: 49.8,
    mlm_I_sc_ref: 11.6,
    mlm_S_ref: 1000.0,
    mlm_T_ref: 25.0,
    mlm_R_shref: 750.0,
    mlm_R_sh0: 3000.0,
    mlm_R_shexp: 5.5,
    mlm_R_s: 0.264,
    mlm_alpha_isc: 5.65,
    mlm_beta_voc_spec: -145.0,
    mlm_n_0: 0.98,
    mlm_mu_n: -0.0006,
    mlm_T_c_fa_alpha: 0.9,
    mlm_D2MuTau: 0.0,
    simple_module: 0,
    // 'module_bifaciality': 0.75,
    bi_bifaciality: 0.75,
    // 'module_isbifacial': 1,
    en_bifacial: 1,
    mlm_E_g: 1.121,
    // 'module_area': 2.23,
    mod_area: 2.23,
    module_iam_ang: [0, 10.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
    module_iam_eff: [1, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 0.963, 0.892, 0.0],
    // 'rating': 455,
    mod_rating: 455,
    name: "455W Generic Module",
    mod_name: "455W Generic Module",
    technology: 0,
    muPmpReq: -0.36,
    default: 0,
  },
  {
    mlm_N_series: 72.0,
    mlm_N_parallel: 2.0,
    mlm_N_diodes: 3.0,
    // 'mlm_Width': 1.048,
    mod_width: 1.048,
    // 'mlm_Length': 2.128,
    mod_height: 2.128,
    mlm_V_mp_ref: 41.5,
    mlm_I_mp_ref: 11.1,
    mlm_V_oc_ref: 49.9,
    mlm_I_sc_ref: 11.7,
    mlm_S_ref: 1000.0,
    mlm_T_ref: 25.0,
    mlm_R_shref: 750.0,
    mlm_R_sh0: 3000.0,
    mlm_R_shexp: 5.5,
    mlm_R_s: 0.264,
    mlm_alpha_isc: 5.65,
    mlm_beta_voc_spec: -145.0,
    mlm_n_0: 0.98,
    mlm_mu_n: -0.0006,
    mlm_T_c_fa_alpha: 0.9,
    mlm_D2MuTau: 0.0,
    simple_module: 0,
    // 'module_bifaciality': 0.75,
    bi_bifaciality: 0.75,
    // 'module_isbifacial': 1,
    en_bifacial: 1,
    mlm_E_g: 1.121,
    // 'module_area': 2.23,
    mod_area: 2.23,
    module_iam_ang: [0, 10.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0],
    module_iam_eff: [1, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 0.963, 0.892, 0.0],
    // 'rating': 460,
    mod_rating: 460,
    name: "460W Generic Module",
    mod_name: "460W Generic Module",
    technology: 0,
    muPmpReq: -0.36,
    default: 0,
  },
  {
    mlm_N_series: 72,
    mlm_N_parallel: 2,
    mlm_N_diodes: 3,
    // 'mlm_Width': 1.131,
    mod_width: 1.131,
    // 'mlm_Length': 2.293,
    mod_height: 2.293,
    mlm_V_mp_ref: 41.69,
    mlm_I_mp_ref: 12.6,
    mlm_V_oc_ref: 49.52,
    mlm_I_sc_ref: 13.37,
    mlm_S_ref: 1000,
    mlm_T_ref: 25,
    mlm_R_shref: 800,
    mlm_R_sh0: 3200,
    mlm_R_shexp: 5.5,
    mlm_R_s: 0.195,
    mlm_alpha_isc: 6,
    mlm_beta_voc_spec: -153,
    mlm_n_0: 0.995,
    mlm_mu_n: -0.0005,
    mlm_T_c_fa_alpha: 0.9,
    mlm_D2MuTau: 0,
    simple_module: 0,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    // 'module_isbifacial': 1,
    en_bifacial: 1,
    mlm_E_g: 1.121,
    // 'module_area': 2.593,
    mod_area: 2.593,
    module_iam_ang: [0, 30, 50, 60, 70, 80, 90],
    module_iam_eff: [1, 1, 1, 1, 0.95, 0.76, 0],
    // 'rating': 525,
    mod_rating: 525,
    name: "525W Generic Module",
    mod_name: "525W Generic Module",
    technology: 0,
    muPmpReq: -0.36,
    default: 0,
  },
  {
    mlm_N_series: 55,
    mlm_N_parallel: 2,
    mlm_N_diodes: 3,
    // 'mlm_Width': 1.096,
    mod_width: 1.096,
    // 'mlm_Length': 2.384,
    mod_height: 2.384,
    mlm_V_mp_ref: 31,
    mlm_I_mp_ref: 17.28,
    mlm_V_oc_ref: 37.3,
    mlm_I_sc_ref: 18.36,
    mlm_S_ref: 1000,
    mlm_T_ref: 25,
    mlm_R_shref: 140,
    mlm_R_sh0: 3200,
    mlm_R_shexp: 3.2,
    mlm_R_s: 0.112,
    mlm_alpha_isc: 10.47,
    mlm_beta_voc_spec: -115.8,
    mlm_n_0: 1.014,
    mlm_mu_n: -0.0005,
    mlm_T_c_fa_alpha: 0.9,
    mlm_D2MuTau: 0,
    simple_module: 0,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    mlm_E_g: 1.121,
    // 'module_area': 2.613,
    mod_area: 2.613,
    module_iam_ang: [0, 30, 50, 60, 70, 75, 80, 85, 90],
    module_iam_eff: [1, 1, 0.999, 0.996, 0.978, 0.944, 0.856, 0.622, 0],
    // 'rating': 535,
    mod_rating: 535,
    name: "535W Generic Module",
    mod_name: "535W Generic Module",
    technology: 0,
    muPmpReq: -0.352,
    default: 0,
  },
  {
    mlm_N_series: 55,
    mlm_N_parallel: 2,
    mlm_N_diodes: 3,
    // 'mlm_Width': 1.096,
    mod_width: 1.096,
    // 'mlm_Length': 2.384,
    mod_height: 2.384,
    mlm_V_mp_ref: 31.2,
    mlm_I_mp_ref: 17.33,
    mlm_V_oc_ref: 37.5,
    mlm_I_sc_ref: 18.41,
    mlm_S_ref: 1000,
    mlm_T_ref: 25,
    mlm_R_shref: 140,
    mlm_R_sh0: 3200,
    mlm_R_shexp: 3.2,
    mlm_R_s: 0.111,
    mlm_alpha_isc: 10.49,
    mlm_beta_voc_spec: -115.8,
    mlm_n_0: 1.015,
    mlm_mu_n: -0.0005,
    mlm_T_c_fa_alpha: 0.9,
    mlm_D2MuTau: 0,
    simple_module: 0,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    mlm_E_g: 1.121,
    // 'module_area': 2.613,
    mod_area: 2.613,
    module_iam_ang: [0, 30, 50, 60, 70, 75, 80, 85, 90],
    module_iam_eff: [1, 1, 0.999, 0.996, 0.978, 0.944, 0.856, 0.622, 0],
    // 'rating': 540,
    mod_rating: 540,
    name: "540W Generic Module",
    mod_name: "540W Generic Module",
    technology: 0,
    muPmpReq: -0.352,
    default: 0,
  },
  {
    mlm_N_series: 55,
    mlm_N_parallel: 2,
    mlm_N_diodes: 3,
    // 'mlm_Width': 1.096,
    mod_width: 1.096,
    // 'mlm_Length': 2.384,
    mod_height: 2.384,
    mlm_V_mp_ref: 31.4,
    mlm_I_mp_ref: 17.37,
    mlm_V_oc_ref: 37.7,
    mlm_I_sc_ref: 18.47,
    mlm_S_ref: 1000,
    mlm_T_ref: 25,
    mlm_R_shref: 130,
    mlm_R_sh0: 3200,
    mlm_R_shexp: 3.2,
    mlm_R_s: 0.109,
    mlm_alpha_isc: 10.53,
    mlm_beta_voc_spec: -115.8,
    mlm_n_0: 1.022,
    mlm_mu_n: -0.0006,
    mlm_T_c_fa_alpha: 0.9,
    mlm_D2MuTau: 0,
    simple_module: 0,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    mlm_E_g: 1.121,
    // 'module_area': 2.613,
    mod_area: 2.613,
    module_iam_ang: [0, 30, 50, 60, 70, 75, 80, 85, 90],
    module_iam_eff: [1, 1, 0.999, 0.996, 0.978, 0.944, 0.856, 0.622, 0],
    // 'rating': 545,
    mod_rating: 545,
    name: "545W Generic Module",
    mod_name: "545W Generic Module",
    technology: 0,
    muPmpReq: -0.352,
    default: 0,
  },
  {
    mlm_N_series: 55,
    mlm_N_parallel: 2,
    mlm_N_diodes: 3,
    // 'mlm_Width': 1.096,
    mod_width: 1.096,
    // 'mlm_Length': 2.384,
    mod_height: 2.384,
    mlm_V_mp_ref: 31.6,
    mlm_I_mp_ref: 17.4,
    mlm_V_oc_ref: 37.9,
    mlm_I_sc_ref: 18.52,
    mlm_S_ref: 1000,
    mlm_T_ref: 25,
    mlm_R_shref: 120,
    mlm_R_sh0: 3200,
    mlm_R_shexp: 3.2,
    mlm_R_s: 0.109,
    mlm_alpha_isc: 10.56,
    mlm_beta_voc_spec: -115.8,
    mlm_n_0: 1.02,
    mlm_mu_n: -0.0006,
    mlm_T_c_fa_alpha: 0.9,
    mlm_D2MuTau: 0,
    simple_module: 0,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    mlm_E_g: 1.121,
    // 'module_area': 2.613,
    mod_area: 2.613,
    module_iam_ang: [0, 30, 50, 60, 70, 75, 80, 85, 90],
    module_iam_eff: [1, 1, 0.999, 0.996, 0.978, 0.944, 0.856, 0.622, 0],
    // 'rating': 550,
    mod_rating: 550,
    name: "550W Generic Module",
    mod_name: "550W Generic Module",
    technology: 0,
    muPmpReq: -0.352,
    default: 0,
  },
  {
    mlm_N_series: 55,
    mlm_N_parallel: 2,
    mlm_N_diodes: 3,
    // 'mlm_Width': 1.096,
    mod_width: 1.096,
    // 'mlm_Length': 2.384,
    mod_height: 2.384,
    mlm_V_mp_ref: 31.8,
    mlm_I_mp_ref: 17.45,
    mlm_V_oc_ref: 38.1,
    mlm_I_sc_ref: 18.56,
    mlm_S_ref: 1000,
    mlm_T_ref: 25,
    mlm_R_shref: 120,
    mlm_R_sh0: 3200,
    mlm_R_shexp: 3.2,
    mlm_R_s: 0.108,
    mlm_alpha_isc: 10.58,
    mlm_beta_voc_spec: -115.8,
    mlm_n_0: 1.017,
    mlm_mu_n: -0.0006,
    mlm_T_c_fa_alpha: 0.9,
    mlm_D2MuTau: 0,
    simple_module: 0,
    // 'module_bifaciality': 0.7,
    bi_bifaciality: 0.7,
    // 'module_isbifacial': 0,
    en_bifacial: 0,
    mlm_E_g: 1.121,
    // 'module_area': 2.613,
    mod_area: 2.613,
    module_iam_ang: [0, 30, 50, 60, 70, 75, 80, 85, 90],
    module_iam_eff: [1, 1, 0.999, 0.996, 0.978, 0.944, 0.856, 0.622, 0],
    // 'rating': 555,
    mod_rating: 555,
    name: "555W Generic Module",
    mod_name: "555W Generic Module",
    technology: 0,
    muPmpReq: -0.352,
    default: 0,
  },
];

export default defaultModules;
