import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

import { useSelector, useDispatch } from "react-redux";
import { carportActions } from "../Redux/actions/carport.actions";

import { infoIcon } from "../../assets";

import { Button, Menu } from "antd";

const OverLapWarningWrap = styled(motion.div)`
  z-index: 500;
  position: absolute;
  width: 420px;
  padding: 5px;
  height: auto;
  background-color: rgba(0, 0, 0, 0.7);
  top: 15px;
  right: 65px;
  border: 1px solid #60de4f;
  border-radius: 3px;
  display: grid;
  grid-template-columns: 50px 1fr;
  cursor: default;

  .overlap-img {
    grid-column: 1;
    display: grid;
    place-items: center;

    img {
      width: 25px;
    }
  }

  .overlap-text {
    grid-column: 2;
    margin-left: 10px;

    p {
      color: #fff;
      font-size: 16px;
      margin: 0;
    }

    .overlap-buttons {
      display: grid;
      place-items: start;

      ul {
        background-color: transparent;
      }
    }
  }
`;

const OverLapWarning = ({ overlappingCanopies }) => {
  const carport = useSelector((state) => state.carport);
  const dispatch = useDispatch();

  return (
    <AnimatePresence>
      <OverLapWarningWrap
        key="OverLapWarning"
        initial={{ scale: 0.7, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.7, opacity: 0 }}
        transition={{ duration: 0.5, ease: [0.78, 0.14, 0.15, 0.86] }}
      >
        <div className="overlap-img">
          <img src={infoIcon} />
        </div>
        <div className="overlap-text">
          <p>The Following Canopies are Overlapping:</p>
          <div className="overlap-buttons">
            <Menu style={{ border: "none" }} onClick={(e) => dispatch(carportActions.selectRectangle(e.key))}>
              {Object.values(overlappingCanopies).map((id) => {
                return <Menu.Item style={{ margin: "5px 0px", color: "#60de4f" }} key={id}>{`▶  ${carport.rectangles[id] && carport.rectangles[id].name}`}</Menu.Item>;
              })}
            </Menu>
          </div>
        </div>
      </OverLapWarningWrap>
    </AnimatePresence>
  );
};

export { OverLapWarning };
