/*
	This is the DEFAULT input set used by portal to initialize the inputs state 
	and to be used when hitting New Project

	really the only inputs in here should be inputs that can be changed through portal
	so that we can easily spread this into the master_inputs.json to end with the final_inputs.json

	final_inputs = {
		...master_inputs,
		...inputs
	}
*/
// const topo_tab_inputs = [
// 	'topo_id',
// 	'boundary_bbox',
// 	'topo_source',
// 	'graded_avail',
// 	'grade_target',
// 	'grade_cut_amt',
// 	'grade_fill_amt',
// 	'vis_ele',
// 	'vis_max',
// 	'vis_ns',
// 	'vis_ew',
// 	'topo_layout_surface',
// 	'topo_layout_action',
// 	'topo_mode',
// 	'topo_url',
// 	'topo_scale_url',
// ]
// req_inupts = {

// 	inputs[topo_tab_inputs[index]]
// }
import config from "config";
import { getBounds, getNewCenterPoint, uuid } from "../../../utils/map_helper.js";
import defaultProducts from "../../Sunfig/data/defaultProductData";
import defaultModules from "../../Sunfig/data/defaultModules";
import { Terratrak_2P_SAT, Standard_GFT } from "../helpers/gmCalculators.js";
//
const default_ui_state = {
  do_module_target: false,
  do_gcr_override: false,
  do_tilt_override: false,
  do_dimension_override: false,
  do_advanced_mod_specs: false,
  tab: "simplified",
  selected_module_index: 0,
  selected_product_index: 0,
  topo_error: undefined,
  topo_loading: false,
  ele_graded_checked: false,
  max_ungraded_checked: false,
  max_graded_checked: false,
  ns_ungraded_checked: false,
  ns_graded_checked: false,
  ew_ungraded_checked: false,
  ew_graded_checked: false,
  show_sf_modal: false,
  loading: false,
  download_type: undefined,
};

export const default_inputs = () => {
  return {
    version: "1.8.0",
    platform: "rbi",

    job_type: "portal-ground",
    job_id: "",

    gcr: 0.5,
    result_handler: 1,
    api_key: "",

    site_features: [],

    project_name: "",
    project_id: undefined,
    active: 1,
    local_weather: 0,

    // managed in portal through do_override_gcr toggle that unlocks inputs for min/max
    gcr_min: 0.3,
    gcr_max: 0.7,
    gcr_inc: 0.01,
    spi_min: 100,
    spi_max: 200,
    spi_inc: 5,

    // somewhat racking specific inputs
    racking_name: "Terrasmart - TerraTrak - 2HT",
    racks: [
      { key: 1, active: 1, xdim: 4.141, ydim: 44.621, module: 84 },
      { key: 2, active: 1, xdim: 4.141, ydim: 30.02, module: 56 },
      { key: 3, active: 1, xdim: 4.141, ydim: 15.419, module: 28 },
    ],
    mods_high: 2,
    gap: 0.3,
    tilt_min: 0,
    tilt_inc: 0,
    tilt_max: 0,
    continuous: 0,
    can_continuous: 0,
    rack_grade_limit: 20,
    orientation: "portrait",
    vert_gap: 0.02,
    hori_gap: 0.01,
    string_counts: [3, 2, 1],

    // set elsewhere
    latitude: undefined,
    longitude: undefined,
    // elevation: undefined,
    // timezone: undefined,
    // weather: undefined,
    // weather_source: undefined,
    // gcr: undefined,

    // readonly unless simplified tab selected
    mod_per_string: 28,

    // hidden/hardcoded
    ac_grid_poi_lim: 0,
    analysis_period: 1,
    simple_inverter: 1,

    // new user inputs
    // DC losses
    dc_degrade: 0.5,
    dc_thermal_Uc: 29,
    dc_thermal_Uv: 0,
    dc_module_quality_loss: -1.0,
    dc_module_lid_loss: 1.5,
    dc_module_mismatch: 1.5,
    dc_strings_mismatch: 0.1,
    dc_wiring_loss_at_stc: 2.0,
    // hide if bifacial off
    bi_back_mismatch: 10.0,
    // AC losses
    ac_aux_kw: 0,
    ac_wiring_loss_at_stc: 2.0,
    ac_transformer_loss_constant: 0,
    ac_transformer_loss_at_stc: 0,
    ac_MV_line_loss_stc: 0,
    ac_transmission_loss: 0,
    ac_other_loss: 0.0,
    simple_inverter_dcac: 1.3,
    // single vs monthly
    do_monthly_losses: 0,
    soiling_single: 2.0,
    albedo_single: 0.2,
    soiling: [2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0],
    albedo: [0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2],

    // set by product selection
    track_mode: 1,
    tilts: [0],
    rlim: 60,
    sazm: 180,
    en_backtrack: 1,
    en_irradtrack: 0,
    shade_mode: 1,
    Fshd_CollWidth: 4.141,
    Fshd_StringSteps: 2,
    Fshd_Celltrav: 0.156,
    bi_groundClearanceHeight: 2.1,
    bi_transmissionFactor: 0,
    bi_structureShadeFactor: 10,
    // set by module selection
    simple_module: 1,
    en_bifacial: 0,
    bi_bifaciality: 0.7,
    // Name of module
    mod_name: "Simple Module Input",
    // Set manually by input or when selecting a preset module
    mod_rating: 380,
    mod_width: 0.992,
    mod_height: 1.658,
    mod_area: 1.64,
    module_iam_ang: [0, 30, 50, 60, 70, 75, 80, 85, 90],
    module_iam_eff: [1, 0.999, 0.987, 0.962, 0.892, 0.816, 0.681, 0.44, 0],

    do_wiring: 0,
    // wiring_dcac: 1.4,
    // wiring_mod_rating: 400,
    // wiring_modules_per_string: 28,
    wiring_inv_rating: 2000,
    wiring_spi: 200,
    // wiring_max_row_length: 2,
    do_rack_align: 1,
    // azimuth: 180,
    do_roads: 1,
    road_spacing: 300,
    road_spacing_option: 2,
    // off - fill - integer
    inverter_grouping: 0,
    // centroids or by_road
    combiner_location: 1,
    strings_per_combiner: 16,
    // center or split string
    wire_stringing: 0,

    // controlled by toggle in ui -> do_dc_lock: false -- remember to set back to 0 when toggling off
    do_dc_lock: 0,
    dc_lock_value: 0,

    do_dual_tilt: 0,

    topo_id: undefined,
    boundary_bbox: undefined,
    topo_source: "USGS",
    // what should the elevation be graded to % wise
    graded_avail: false,
    grade_target: 0,
    grade_cut_amt: 0,
    grade_fill_amt: 0,

    vis_ele: { ungraded_percent: 0, ungraded_avail: false, graded_percent: 0, graded_avail: false },
    vis_max: { ungraded_percent: 15, ungraded_avail: false, graded_percent: 15, graded_avail: false },
    vis_ns: { ungraded_percent: 10, ungraded_avail: false, graded_percent: 10, graded_avail: false },
    vis_ew: { ungraded_percent: 20, ungraded_avail: false, graded_percent: 20, graded_avail: false },
    vis_g: { ungraded_percent: 20, ungraded_avail: false, graded_percent: 20, graded_avail: false },
    // ungraded || graded
    topo_layout_surface: "ungraded",
    // nothing || delete
    topo_layout_action: "nothing",
    // off || ele || u || ns || ew
    topo_mode: "Off",
    topo_url: "",
    topo_scale_url: "",

    do_designer_margin: 0,
    designer_margin: 0,
    inverter_per_cut: 1,
    epsg: 0,
    do_inverter_limit_lock: 0,
    inverter_limit: 0,

    download_lock: true,
  };
};
export const inputsInitialState = {
  ...JSON.parse(JSON.stringify(default_inputs())),
  uiState: { ...JSON.parse(JSON.stringify(default_ui_state)) },
  env: config.env,
};

export const default_topo = () => {
  return {
    topo_id: undefined,
    boundary_bbox: undefined,
    topo_source: "USGS",
    graded_avail: false,
    grade_target: 0,
    grade_cut_amt: 0,
    grade_fill_amt: 0,
    vis_ele: { ungraded: false, ungraded_percent: 0, ungraded_avail: false, graded: false, graded_percent: 0, graded_avail: false },
    vis_max: { ungraded: false, ungraded_percent: 15, ungraded_avail: false, graded: false, graded_percent: 15, graded_avail: false },
    vis_ns: { ungraded: false, ungraded_percent: 10, ungraded_avail: false, graded: false, graded_percent: 10, graded_avail: false },
    vis_ew: { ungraded: false, ungraded_percent: 20, ungraded_avail: false, graded: false, graded_percent: 20, graded_avail: false },
    topo_layout_surface: "ungraded",
    topo_layout_action: "nothing",
    topo_mode: "off",
    topo_url: "",
    topo_scale_url: "",
    uiState: {
      ele_graded_checked: false,
      max_ungraded_checked: false,
      max_graded_checked: false,
      ns_ungraded_checked: false,
      ns_graded_checked: false,
      ew_ungraded_checked: false,
      ew_graded_checked: false,
    },
  };
};

const fixBools = (proj) => {
  let fix_proj = proj;

  if (typeof fix_proj.inputs.do_wiring == "boolean") {
    fix_proj.inputs.do_wiring = fix_proj.inputs.do_wiring ? 1 : 0;
  }
  if (typeof fix_proj.inputs.do_roads == "boolean") {
    fix_proj.inputs.do_roads = fix_proj.inputs.do_roads ? 1 : 0;
  }
  if (typeof fix_proj.inputs.do_rack_align == "boolean") {
    fix_proj.inputs.do_rack_align = fix_proj.inputs.do_rack_align ? 1 : 0;
  }
  if (typeof fix_proj.inputs.en_bifacial == "boolean") {
    fix_proj.inputs.en_bifacial = fix_proj.inputs.en_bifacial ? 1 : 0;
  }
  if (typeof fix_proj.inputs.can_continuous == "boolean") {
    fix_proj.inputs.can_continuous = fix_proj.inputs.can_continuous ? 1 : 0;
  }
  if (typeof fix_proj.inputs.continuous == "boolean") {
    fix_proj.inputs.continuous = fix_proj.inputs.continuous ? 1 : 0;
  }

  return fix_proj;
};
export const map_to_1_8 = (project) => {
  let new_inputs = { ...JSON.parse(JSON.stringify(inputsInitialState)) };

  if (project.inputs.version === "1.8.0") {
    // project is already on new project structure and should be good to go
    // name correction so that the correct project name is displayed in the project manager, and the report
    console.log("inputs", project.inputs);
    project.inputs.project_name = project.name ? project.name : project.inputs.project_name;
    project.inputs.boundary_bbox = project.inputs.boundary_bbox ? project.inputs.boundary_bbox : getBounds(project.inputs.site_features);
    return fixBools(project);
  }

  let fixed_project = {
    ...project,
    inputs: new_inputs,
  };

  fixed_project.inputs.project_id = project.id;
  fixed_project.inputs.project_name = project.name;
  if (Object.keys(project.inputs) == 0) {
    // literally empty project inputs, just return fixed project
    return fixBools(fixed_project);
  } else {
    let old_inputs = project.inputs.old_inputs;
    if (old_inputs.search("'") >= 0) {
      old_inputs = old_inputs.replace(/'/g, '"');
      old_inputs = old_inputs.replace(/None/g, undefined);
    }
    try {
      old_inputs = { ...JSON.parse(old_inputs) };
      if (project.inputs.old_features != "") {
        let old_features = {};
        Object.values(JSON.parse(project.inputs.old_features)).map((feature) => {
          if (feature.properties && feature.properties.index) {
            old_features[feature.properties.index] = feature;
          } else {
            feature.properties.index = uuid();
            old_features[feature.properties.index] = feature;
          }
        });
        fixed_project.inputs.site_features = old_features;
        if (Object.keys(old_features).length > 0) {
          let new_bounds = getBounds(fixed_project.inputs.site_features);
          fixed_project.inputs.boundary_bbox = new_bounds;
          // set latitude / longitude -- which are the "site_center lat/lng"
          fixed_project.inputs.latitude = new_bounds[1] + Math.abs(new_bounds[3] - new_bounds[1]) / 2;
          fixed_project.inputs.longitude = new_bounds[0] + Math.abs(new_bounds[2] - new_bounds[0]) / 2;
        }
      }
    } catch {
      return fixBools(fixed_project);
    }

    fixed_project.inputs.sazm = 180;

    if (old_inputs.doRoads != undefined) {
      fixed_project.inputs.do_roads = old_inputs.doRoads ? 1 : 0;
      fixed_project.inputs.road_spacing_option = fixed_project.inputs.do_roads;
    }
    if (old_inputs.doRoads != undefined) {
      fixed_project.inputs.do_rack_align = old_inputs.doAlign ? 1 : 0;
    }
    if (old_inputs.selectedProductIndex != undefined) {
      // use Math.max here to make sure at least the 0 index is selected for Portal
      fixed_project.inputs.uiState.selected_product_index = Math.min(Math.max(old_inputs.selectedProductIndex, 0), defaultProducts.length - 1);
      fixed_project.inputs = {
        ...fixed_project.inputs,
        ...JSON.parse(JSON.stringify(defaultProducts[fixed_project.inputs.uiState.selected_product_index])),
      };
    }
    if (old_inputs.selectedModuleIndex != undefined) {
      // use Math.max here to make sure at least the 0 index is selected for Portal
      fixed_project.inputs.uiState.selected_module_index = Math.min(Math.max(old_inputs.selectedModuleIndex, 0), defaultModules.length - 1);
      fixed_project.inputs = {
        ...fixed_project.inputs,
        ...JSON.parse(JSON.stringify(defaultModules[fixed_project.inputs.uiState.selected_module_index])),
      };
      if (fixed_project.inputs.uiState.selected_module_index > 0) {
        fixed_project.inputs.simple_module = 0;
      }
    }
    if (old_inputs.tab != undefined) {
      fixed_project.inputs.uiState.tab = old_inputs.tab;
    }

    // if old_inputs['doOverrideTilt']:
    if (old_inputs.doOverrideTilt != undefined && old_inputs.OverrideTilt != undefined) {
      fixed_project.inputs.tilt_min = old_inputs.OverrideTilt[0];
      fixed_project.inputs.tilt_max = old_inputs.OverrideTilt[1];
    }
    // if old_inputs['doOverrideGCR']:
    if (old_inputs.doOverrideGCR != undefined && old_inputs.OverrideGCR != undefined) {
      fixed_project.inputs.gcr_min = old_inputs.OverrideGCR[0];
      fixed_project.inputs.gcr_max = old_inputs.OverrideGCR[1];
    }

    if (old_inputs.doOverrideDims != undefined) {
      fixed_project.inputs.uiState.do_dimension_override = old_inputs.doOverrideDims;
    }
    if (old_inputs.do_continuous != undefined) {
      fixed_project.inputs.continuous = old_inputs.do_continuous ? 1 : 0;
    }
    if (old_inputs.do_dc_lock != undefined) {
      fixed_project.inputs.uiState.do_module_target = old_inputs.do_dc_lock;
    }
    if (old_inputs.dc_lock_value != undefined) {
      fixed_project.inputs.dc_lock_value = parseInt(old_inputs.dc_lock_value);
    }
    if (old_inputs.mod_string != undefined) {
      fixed_project.inputs.mod_per_string = parseInt(old_inputs.mod_string);
    }

    if (old_inputs.is_bifacial != undefined) {
      fixed_project.inputs.en_bifacial = old_inputs.is_bifacial;
    }
    if (old_inputs.bifaciality != undefined) {
      fixed_project.inputs.bi_bifaciality = parseFloat(old_inputs.bifaciality);
    }
    if (old_inputs.bifacial_transmission_factor != undefined) {
      fixed_project.inputs.bi_transmissionFactor = old_inputs.bifacial_transmission_factor;
    }
    if (old_inputs.bifacial_ground_clearance_height != undefined) {
      fixed_project.inputs.bi_groundClearanceHeight = old_inputs.bifacial_ground_clearance_height;
    }
    if (old_inputs.bifacial_structure_shade_factor != undefined) {
      fixed_project.inputs.bi_structureShadeFactor = old_inputs.bifacial_structure_shade_factor;
    }

    if (old_inputs.layout) {
      if (old_inputs.layout.do_wiring != undefined) {
        fixed_project.inputs.do_wiring = old_inputs.layout.do_wiring;
      }
      if (old_inputs.layout.wiring_inv_rating != undefined) {
        fixed_project.inputs.wiring_inv_rating = parseInt(old_inputs.layout.wiring_inv_rating);
      }
      if (old_inputs.layout.wiring_spi != undefined) {
        fixed_project.inputs.wiring_spi = parseInt(old_inputs.layout.wiring_spi);
      }
      if (old_inputs.layout.do_rack_align != undefined) {
        fixed_project.inputs.do_rack_align = old_inputs.layout.do_rack_align;
      }
      if (old_inputs.layout.do_roads != undefined) {
        fixed_project.inputs.do_roads = old_inputs.layout.do_roads;
      }
      if (old_inputs.layout.road_spacing != undefined) {
        fixed_project.inputs.road_spacing = parseInt(old_inputs.layout.road_spacing);
      }
      if (old_inputs.layout.road_spacing_option != undefined) {
        fixed_project.inputs.road_spacing_option = old_inputs.layout.road_spacing_option;
      }
      if (old_inputs.layout.inverter_grouping != undefined) {
        fixed_project.inputs.inverter_grouping = old_inputs.layout.inverter_grouping;
      }
      if (old_inputs.layout.combiner_location != undefined) {
        fixed_project.inputs.combiner_location = old_inputs.layout.combiner_location;
      }
      if (old_inputs.layout.strings_per_combiner != undefined) {
        fixed_project.inputs.strings_per_combiner = parseInt(old_inputs.layout.strings_per_combiner);
      }
      if (old_inputs.layout.wire_stringing != undefined) {
        fixed_project.inputs.wire_stringing = old_inputs.layout.wire_stringing;
      }
    }

    if (old_inputs.topo_id != undefined) {
      fixed_project.inputs.topo_id = old_inputs.topo_id;
    }
    if (old_inputs.topo_action != undefined) {
      fixed_project.inputs.topo_layout_action = old_inputs.topo_action;
    }
    if (old_inputs.grading_enabled != undefined) {
      fixed_project.inputs.graded_avail = old_inputs.grading_enabled;
    }
    if (old_inputs.grade_target != undefined) {
      fixed_project.inputs.grade_target = parseInt(old_inputs.grade_target);
    }
    if (old_inputs.grade_limit != undefined) {
      fixed_project.inputs.rack_grade_limit = parseInt(old_inputs.grade_limit);
    }
    if (old_inputs.cut_amt != undefined) {
      fixed_project.inputs.grade_cut_amt = old_inputs.cut_amt;
    }
    if (old_inputs.fill_amt != undefined) {
      fixed_project.inputs.grade_fill_amt = old_inputs.fill_amt;
    }
    // if old_inputs['doTopo']:
    if (old_inputs.layers_generated != undefined) {
      (fixed_project.inputs.vis_ele = {
        ...fixed_project.inputs.vis_ele,
        ungraded_avail: old_inputs.layers_generated["ele"]["avail"] || false,
        graded_avail: old_inputs.layers_generated["ele"]["gavail"] || false,
      }),
        (fixed_project.inputs.vis_max = {
          ...fixed_project.inputs.vis_max,
          ungraded_percent: parseInt(old_inputs.layers_generated["U"]["limit"]),
          ungraded_avail: old_inputs.layers_generated["U"]["avail"] || false,
          graded_percent: parseInt(old_inputs.layers_generated["U"]["limit"]),
          graded_avail: old_inputs.layers_generated["U"]["gavail"] || false,
        }),
        (fixed_project.inputs.vis_ns = {
          ...fixed_project.inputs.vis_ns,
          ungraded_percent: parseInt(old_inputs.layers_generated["NS"]["limit"]),
          ungraded_avail: old_inputs.layers_generated["NS"]["avail"] || false,
          graded_percent: parseInt(old_inputs.layers_generated["NS"]["limit"]),
          graded_avail: old_inputs.layers_generated["NS"]["gavail"] || false,
        }),
        (fixed_project.inputs.vis_ew = {
          ...fixed_project.inputs.vis_ew,
          ungraded_percent: parseInt(old_inputs.layers_generated["EW"]["limit"]),
          ungraded_avail: old_inputs.layers_generated["EW"]["avail"] || false,
          graded_percent: parseInt(old_inputs.layers_generated["EW"]["limit"]),
          graded_avail: old_inputs.layers_generated["EW"]["gavail"] || false,
        });
      fixed_project.inputs.vis_g = {
        ...fixed_project.inputs.vis_g,
        ungraded_percent: parseInt(old_inputs.grade_target),
        ungraded_avail: old_inputs.grading_enabled || false,
        graded_percent: parseInt(old_inputs.grade_target),
        graded_avail: old_inputs.grading_enabled || false,
      };
    }

    if (fixed_project.inputs.track_mode == 0) {
      fixed_project.inputs.racks.forEach((rack, index) => {
        rack.module = parseInt(rack.module);

        let mod_count = rack.module;
        if (fixed_project.inputs.string_counts.length == 0) {
          // GLIDE products are LIMITED to 24 modules no matter what
          mod_count = 24;
        } else {
          mod_count = fixed_project.inputs.mod_per_string * fixed_project.inputs.string_counts[index];
        }

        let Rack_XY = Standard_GFT(
          fixed_project.inputs.mod_width,
          fixed_project.inputs.mod_height,
          mod_count,
          fixed_project.inputs.mods_high,
          fixed_project.inputs.orientation,
          fixed_project.inputs.vert_gap,
          fixed_project.inputs.hori_gap
        );
        rack.module = mod_count;
        rack.xdim = Rack_XY[0];
        rack.ydim = Rack_XY[1];
      });
    } else {
      fixed_project.inputs.racks.forEach((rack, index) => {
        rack.module = parseInt(rack.module);

        let Tracker_XY = Terratrak_2P_SAT(fixed_project.inputs.mod_width, fixed_project.inputs.mod_height, rack.module, fixed_project.inputs.en_bifacial == 1);
        rack.xdim = Tracker_XY[0];
        rack.ydim = Tracker_XY[1];
      });
    }

    return fixBools(fixed_project);
  }
};
