import React from 'react';
import { _listUsers, _manageUsers } from '../utils/authenticate';
import { debounce, validateEmail } from '../utils/common';
import { Button, Table, Popconfirm, message } from 'antd';
const ButtonGroup = Button.Group;

import { NewUserModal } from '../components/Sunfig/modals';
import { withRouter } from 'react-router';

class UserManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      new_user: '',
      modal_visible: false,
      modal_error: '',

      data: [],
      loading: true,
      userCount: 0
    };

    this.fetchData = this.fetchData.bind(this);
    this.action = this.action.bind(this);
  }

  componentDidMount() {
    // fetch after mounted.
    this.fetchData();
  }

  async fetchData() {
    this.setState({ loading: true }, async () => {
      let token = localStorage.getItem('access');
      let results = await _listUsers(token);

      this.setState({
        data: results['data'],
        userCount: results['data'].length,
        loading: false
      });
    });
  }

  async action(type, record) {
    let token = localStorage.getItem('access');
    let email = record['email'];
    let admin = record['admin'];

    switch (type) {
      case 'reset':
        let reset = await _manageUsers(token, {
          'email': email,
          'action': 'resetPassword'
        });
        if (reset) {
          message.success(`User: ${email}'s password successfully reset.`, 2);
          this.fetchData();
        } else {
          message.error(
            'There was a problem reseting this users password, try again later.',
            2
          );
        }
        break;
      case 'admin':
        let updated = await _manageUsers(token, {
          'email': email,
          'action': 'updateRole',
          'is_platform_admin': admin == 1 ? 0 : 1
        });
        if (updated) {
          message.success(`User: ${email} successfully updated.`, 2);
          this.fetchData();
        } else {
          message.error(
            'There was a problem updating this user, try again later.',
            2
          );
        }
        break;
      case 'delete':
        let deleted = await _manageUsers(token, {
          'email': email,
          'action': 'updateActive',
          'is_active': 0
        });
        if (deleted) {
          message.success(`User: ${email} successfully deleted.`, 2);
          this.fetchData();
        } else {
          message.error(
            'There was a problem deleting this user, try again later.',
            2
          );
        }
        break;
    }
  }

  handleChange = debounce((key, value) => {
    this.setState({
      [key]: value,
      modal_error: undefined
    });
  }, 200);

  handleSubmit = async () => {
    if (!validateEmail(this.state.new_user)) {
      this.setState({ modal_error: 'Email address not valid.' });
      return;
    }

    let token = localStorage.getItem('access');
    let responseJson = await _manageUsers(token, {
      'email': this.state.new_user,
      'action': 'createUser',
      'is_platform_admin': this.state.is_admin ? 1 : 0
    });
    // let responseJson = 'Failed.'

    if (responseJson == 'Success.') {
      console.log(
        `User: ${this.state.new_user} successfully created with admin ${this.state.is_admin}.`
      );
      this.setState({
        new_user: '',
        is_admin: false,
        modal_visible: false,
        modal_error: undefined
      });
      this.fetchData();
    } else {
      this.setState({
        modal_error: `Problem creating user: ${JSON.stringify(responseJson)}`
      });
    }
  };

  render() {
    const { data, loading, userCount } = this.state;

    return (
      <div>
        <div style={{ paddingLeft: '1%', paddingRight: '1%' }}>
          <Button
            type="primary"
            onClick={() => this.setState({ modal_visible: true })}
          >
            Create User
          </Button>

          <div
            style={{
              overflowY: 'auto',
              overflowX: 'auto',
              maxHeight: 800,
              maxWidth: '100%'
            }}
          >
            <Table
              dataSource={data}
              useFixedHeader={false}
              scroll={{ x: 'max-content', y: 700 }}
              pagination={userCount > 10 ? true : false}
              bordered
              size="small"
              onChange={this.handleChange}
              rowKey="email"
              loading={loading} // Display the loading overlay when we need it
              columns={[
                {
                  title: 'Email',
                  dataIndex: 'email',
                  render: (text, record, index) => (
                    <div style={{ width: '200px' }}>{text}</div>
                  )
                },
                {
                  title: 'Admin',
                  dataIndex: 'admin',
                  render: (text, record, index) => (
                    <div style={{ width: '35px' }}>
                      {text == 1 ? 'Yes' : 'No'}
                    </div>
                  )
                },
                {
                  title: 'Actions',
                  render: (text, record, index) => (
                    <div style={{ width: '100%' }}>
                      <ButtonGroup>
                        <Popconfirm
                          title="Reset Password?"
                          onConfirm={e => this.action('reset', record)}
                        >
                          <Button size="small" type="warning">
                            Reset Password
                          </Button>
                        </Popconfirm>

                        <Popconfirm
                          title="Toggle Admin?"
                          onConfirm={e => this.action('admin', record)}
                        >
                          <Button size="small" type="warning">
                            Toggle Admin
                          </Button>
                        </Popconfirm>

                        <Popconfirm
                          title="Delete User?"
                          onConfirm={e => this.action('delete', record)}
                        >
                          <Button size="small" type="danger">
                            Delete User
                          </Button>
                        </Popconfirm>
                      </ButtonGroup>
                    </div>
                  )
                }
              ]}
            />
          </div>
        </div>
        <NewUserModal
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          visible={this.state.modal_visible}
          error={this.state.modal_error}
          cancel={() =>
            this.setState({ modal_visible: false, modal_error: undefined })
          }
        />
      </div>
    );
  }
}

export { UserManagement };
