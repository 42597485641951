import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { carportActions } from '../../Redux';

import { Radio } from "antd";

import { InputRadios } from './styles'

function OrientationInput() {
	const dispatch = useDispatch();

	const orientation = useSelector((state) => state.carport.selectedInputs.orientation);

	function onSelectOrientation(e) {
		dispatch(carportActions.updateInputByName('orientation', e.target.value));
	}

	return (
		<InputRadios>
			<Radio.Group
				value={orientation}
				onChange={onSelectOrientation}
			>
				<Radio value={1}>Portrait</Radio>
				<Radio value={2}>Landscape</Radio>
			</Radio.Group>	
		</InputRadios>
	)
}

export { OrientationInput }