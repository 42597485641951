import React, { Component } from 'react';

import { trashCan, translate, distort, drop, scale, rotate, lock } from '../../assets';

// antd
import { Tooltip, Button, Popover, Popconfirm, Menu, Slider, Row } from 'antd';

// redux
import { connect } from 'react-redux';
import { carportActions } from '../Redux/actions/carport.actions';

// Made this a seperate component so that I could define the height and width. Popconfirm was much too wide with this much text in it.
const LockToolContent = () => <p style={{ width: '300px', height: '35px' }}>After locking this image, you can reselect and edit it via the "Imported Images" menu in the control panel.</p>;

class ImageToolbar extends Component {
  render() {
    const opacitySlider = (
      <div style={{ width: '175px', padding: '0' }}>
        <Slider
          disabled={this.props.carport.selectedImageId ? false : true}
          value={this.props.carport.selectedImageId && this.props.carport.images[this.props.carport.selectedImageId].opacity * 100}
          type="number"
          min={30}
          max={100}
          step={1}
          onChange={(e) => this.props.updateImage('changeOpacity', parseInt(e))}
        />
      </div>
    );

    return (
      <Menu
        // this prevents dropzone from trying to import toolbar icons
        onDragStart={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{ background: '#fff', display: 'flex' }}
      >
        <Menu.Item style={{ margin: '2px', padding: '0' }}>
          <Tooltip placement="top" title="Lock Images">
            <Popconfirm placement="top" title={LockToolContent} onConfirm={() => this.props.dispatch(carportActions.setActiveImageEditTool('none'))}>
              <Button size="small">
                <img style={{ height: '20px', width: '20px' }} src={lock} alt="" className="toolbar-control" />
              </Button>
            </Popconfirm>
          </Tooltip>
        </Menu.Item>
        <Menu.Item style={{ margin: '2px', padding: '0' }}>
          <Tooltip placement="top" title="Translate">
            <Button size="small" onClick={() => this.props.dispatch(carportActions.setActiveImageEditTool('translate'))}>
              <img style={{ height: '20px', width: '20px' }} src={translate} alt="" className="toolbar-control" />
            </Button>
          </Tooltip>
        </Menu.Item>
        <Menu.Item style={{ margin: '2px', padding: '0' }}>
          <Tooltip placement="top" title="Rotate">
            <Button size="small" onClick={() => this.props.dispatch(carportActions.setActiveImageEditTool('rotate'))}>
              <img style={{ height: '20px', width: '20px' }} src={rotate} alt="" className="toolbar-control" />
            </Button>
          </Tooltip>
        </Menu.Item>
        <Menu.Item style={{ margin: '2px', padding: '0' }}>
          <Tooltip placement="top" title="Scale">
            <Button size="small" onClick={() => this.props.dispatch(carportActions.setActiveImageEditTool('scale'))}>
              <img style={{ height: '20px', width: '20px' }} src={scale} alt="" className="toolbar-control" />
            </Button>
          </Tooltip>
        </Menu.Item>
        <Menu.Item style={{ margin: '2px', padding: '0' }}>
          <Tooltip placement="top" title="Distort">
            <Button size="small" onClick={() => this.props.dispatch(carportActions.setActiveImageEditTool('distort'))}>
              <img style={{ height: '20px', width: '20px' }} src={distort} alt="" className="toolbar-control" />
            </Button>
          </Tooltip>
        </Menu.Item>
        <Menu.Item style={{ margin: '2px', padding: '0' }}>
          <Popover content={opacitySlider} triger="hover" placement="top" disabled={this.props.carport.selectedImageId ? false : true}>
            <Tooltip placement="bottom" title="Opacity">
              <Button size="small">
                <img style={{ height: '20px', width: '20px' }} src={drop} alt="" className="toolbar-control" />
              </Button>
            </Tooltip>
          </Popover>
        </Menu.Item>

        <Menu.Item style={{ margin: '2px', padding: '0' }}>
          <Tooltip placement="top" title="Delete">
            <Popconfirm
              title="Delete this Image?"
              placement="bottom"
              onConfirm={(e) => {
                let id = this.props.carport.selectedImageId;
                this.props.dispatch(carportActions.setActiveImageEditTool('delete'));
                setTimeout(() => {
                  this.props.dispatch(carportActions.deleteImage(id));
                }, 250);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small">
                <img style={{ filter: 'brightness(0.1) sepia(1) hue-rotate(180deg) saturate(2)', height: '20px', width: '20px' }} src={trashCan} alt="" className="toolbar-control" />
              </Button>
            </Popconfirm>
          </Tooltip>
        </Menu.Item>
      </Menu>
    );
  }
}

function mapStateToProps(state) {
  const { carport } = state;
  return {
    carport,
  };
}

const connectedStore = connect(mapStateToProps)(ImageToolbar);

export { connectedStore as ImageToolbar };
