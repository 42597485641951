import React, { Fragment } from 'react';

import { canopyInputs } from './CanopyInputsData'


function CanopyInputs () {

	return (
		<>
		{
			Object.values(canopyInputs).map((input,index) => {
				return (
					<Fragment key={index}>
						<label>{input.label}</label>
						{input.component}
					</Fragment>
				)
			})
		}
		</>
	)

}



export { CanopyInputs }