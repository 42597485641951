import styled from "styled-components";

const ZoomAndLayerControlLayout = styled.section`
  display: flex;
  flex-direction: column;

  .zoom-control-layer-button {
    margin: 4px 0;
    height: 40px;
    width: 40px;

    img {
      width: 20px;
      height: 20px;
    }
  }
`;

export { ZoomAndLayerControlLayout };
