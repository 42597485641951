import React, { Fragment, useState, useEffect } from "react";

//components
import ReactDataSheet from "react-datasheet";
import { renderSheet } from "../SheetRenderer";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { portalActions, carportActions, inputsActions } from "../../Redux";

// ant design
// documentation here ====> https://ant.design/
import { Input, Select, Switch, Tooltip, Button, Empty } from "antd";
import { MenuFoldOutlined, MinusCircleOutlined } from "@ant-design/icons";

const technology = ["Si-mono", "Si-poly", "CdTe"];
const module_floats = [
  "bi_bifaciality",
  "mod_width",
  "mod_height",
  "mlm_S_ref",
  "mlm_T_ref",
  "mlm_I_sc_ref",
  "mlm_V_oc_ref",
  "mlm_I_mp_ref",
  "mlm_V_mp_ref",
  "mlm_alpha_isc",
  "mlm_beta_voc_spec",
  "mlm_R_shref",
  "mlm_R_sh0",
  "mlm_R_s",
  "mlm_R_shexp",
  "mlm_T_c_fa_alpha",
  "muPmpReq",
  "mlm_n_0",
  "mlm_mu_n",
];
const ModuleInputs = ({ mode }) => {
  const store = useSelector((state) => state[mode]);
  const portal = useSelector((state) => state.portal);
  const inputs = mode == "carport" ? store.prodModSelection.selectedCarportModule : useSelector((state) => state.inputs);
  const dispatch = useDispatch();
  const uiState = useSelector((state) => state.inputs.uiState);

  const module = mode == "carport" ? store.prodModSelection.selectedCarportModule : portal.inputs.selectedModule;

  const loaded = Object.keys(module).length > 0;
  const isSimple = module.do_pvWatts && module.do_pvWatts == 1;

  const [local_inputs, set_local_inputs] = useState(inputs);
  useEffect(() => {
    set_local_inputs(inputs);
  }, [inputs]);
  // canopy inputs
  const canopies = mode == "carport" ? store.rectangles : undefined;
  const selectedRectId = mode == "carport" ? store.selectedRectId : undefined;
  // const module = mode == 'carport' ? canopies[selectedRectId].module : portal.inputs.selectedModule;

  let iam_dt_data = [];
  let iam_index = 0;

  // ////////////////////////////////////////////
  //	IAM DATA TABLE FUNCTION
  function handleCreateIAMData() {
    var dt_data = [
      [
        { value: "Angle", readOnly: true },
        { value: "IAM", readOnly: true },
      ],
    ];
    let module_iam_ang = inputs.module_iam_ang;
    let module_iam_eff = inputs.module_iam_eff;
    var def = false;

    for (var index in module_iam_ang) {
      if (def) {
        dt_data.push([
          { readOnly: def, value: module_iam_ang[index] },
          { readOnly: def, value: module_iam_eff[index] },
        ]);
      } else {
        dt_data.push([
          { readOnly: def, value: module_iam_ang[index] },
          { readOnly: def, value: module_iam_eff[index] },
          {
            readOnly: true,
            value: "",
            component: (
              <Tooltip placement="right" title="(Insert Below) / (Delete)" mouseEnterDelay={0.5}>
                <div style={{ width: 60 }}>
                  <Button style={{ marginRight: 2, borderColor: "#ffffff" }} shape="circle" id={index} size="small" type="primary" ghost onClick={onInsertRow}>
                    <MenuFoldOutlined />
                  </Button>
                  <Button style={{ marginRight: 2, borderColor: "#ffffff" }} shape="circle" id={(parseInt(index) + 1) * 10} size="small" type="primary" ghost onClick={onRemoveRow}>
                    <MinusCircleOutlined />
                  </Button>
                </div>
              </Tooltip>
            ),
            forceComponent: true,
          },
        ]);
      }
    }

    if (module_iam_ang && module_iam_ang.length == 0) {
      dt_data.splice(1, 0, [
        { readOnly: def, value: 0 },
        { readOnly: def, value: 0 },
        {
          readOnly: true,
          value: "",
          component: (
            <Tooltip placement="right" title="(Insert Below) / (Delete)" mouseEnterDelay={0.5}>
              <div style={{ width: 60 }}>
                <Button style={{ marginRight: 2, borderColor: "#ffffff" }} shape="circle" id={0} size="small" type="primary" ghost onClick={this.onInsertRow}>
                  <MenuFoldOutlined />
                </Button>
                <Button style={{ marginRight: 2, borderColor: "#ffffff" }} shape="circle" id={10} size="small" type="primary" ghost onClick={this.onRemoveRow}>
                  <MinusCircleOutlined />
                </Button>
              </div>
            </Tooltip>
          ),
          forceComponent: true,
        },
      ]);
    }

    iam_dt_data = dt_data;
    iam_index = index;
  }

  function onInsertRow(e) {
    let event = Object.assign({}, e);
    let data = inputs;
    let index = parseInt(event.currentTarget.id);
    data.module_iam_ang.splice(index + 1, 0, 0);
    data.module_iam_eff.splice(index + 1, 0, 1);
    updateInput("module_iam_ang", data.module_iam_ang);
    updateInput("module_iam_eff", data.module_iam_eff);
    handleCreateIAMData();
  }

  function onRemoveRow(e) {
    let event = Object.assign({}, e);
    let data = inputs;
    if (data.module_iam_ang.length == 1) return;
    let index = event.currentTarget.id / 10 - 1;
    data.module_iam_ang.splice(index, 1);
    data.module_iam_eff.splice(index, 1);
    updateInput("module_iam_ang", data.module_iam_ang);
    updateInput("module_iam_eff", data.module_iam_eff);
    handleCreateIAMData();
  }

  function handleChanges(changes) {
    const data = iam_dt_data.map((row) => [...row]);
    changes.forEach(({ cell, row, col, value }) => {
      if (data[row] && data[row][col]) {
        data[row][col] = { ...data[row][col], value };
      }
    });
    let newModData = data.slice(1, data.length);
    let Angle = [];
    let Eff = [];
    newModData.forEach((row) => {
      let angle = parseFloat(row[0].value) == row[0].value ? parseFloat(row[0].value) : 0;
      let eff = parseFloat(row[1].value) == row[1].value ? parseFloat(row[1].value) : 0;
      Angle.push(angle);
      Eff.push(eff);
    });
    updateInput("module_iam_ang", Angle);
    updateInput("module_iam_eff", Eff);
  }
  // create the IAM data table
  handleCreateIAMData();
  // ////////////////////////////////////////////
  const updateInput = (key, value) => {
    set_local_inputs({
      ...local_inputs,
      [key]: value,
    });

    let fixed_value = value;
    if (key == "mod_rating" || key == "mlm_N_series" || key == "mlm_N_parallel") {
      if (_.isEmpty(value) || _.isUndefined(value) || _.isNaN(value)) {
        return;
      }
      // console.log('parsing as int')
      fixed_value = parseInt(value);
    }
    if (module_floats.findIndex((k) => k == key) >= 0) {
      if (_.isEmpty(value) || _.isUndefined(value) || _.isNaN(value)) {
        return;
      }
      // console.log('parsing as float')
      fixed_value = parseFloat(value);
    }

    if (mode == "portal") {
      // dispatch(portalActions.updateInput('module', { key, value }));
      dispatch(inputsActions.update_input(key, fixed_value));
    } else {
      dispatch(carportActions.updateModule(key, fixed_value));
    }
  };

  if (!loaded) {
    return (
      <div className="inputs-content-box">
        <div className="empty-box" style={{ marginTop: "20px" }}>
          <Empty description="Select Module to view inputs" />
        </div>
      </div>
    );
  } else {
    return (
      <div className="inputs-content-box">
        <Fragment>
          <div className="module-input">
            <label>Module Rating</label>
            <Input
              id="mod_rating"
              value={local_inputs.mod_rating}
              className="gm-input"
              onChange={(e) => {
                updateInput(e.target.id, e.target.value);
              }}
              size="small"
              disabled={false}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>W</span>}
            />
          </div>
          <Fragment>
            <div className="module-input">
              <label>Bifacial Module:</label>
              <Switch checked={local_inputs.en_bifacial} onChange={(checked) => updateInput("en_bifacial", checked ? 1 : 0)} />
            </div>
            <div className="module-input">
              <label>Bifaciality Factor:</label>
              <Input
                className="gm-input"
                id="bi_bifaciality"
                size="small"
                disabled={local_inputs.en_bifacial == 0}
                value={local_inputs.bi_bifaciality}
                onChange={(e) => {
                  updateInput(e.target.id, e.target.value);
                }}
                min="0"
                max="100"
                type="number"
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}> </span>}
              />
            </div>
          </Fragment>
          <div className="module-input">
            <label>Module Width X</label>
            <Input
              id="mod_width"
              value={isNaN(local_inputs.mod_width) ? "" : local_inputs.mod_width}
              className="gm-input"
              onChange={(e) => {
                updateInput(e.target.id, e.target.value);
              }}
              size="small"
              step="0.01"
              type="number"
              disabled={false}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
            />
          </div>
          <div className="module-input">
            <label>Module Height Y</label>
            <Input
              id="mod_height"
              value={isNaN(local_inputs.mod_height) ? "" : local_inputs.mod_height}
              className="gm-input"
              onChange={(e) => {
                updateInput(e.target.id, e.target.value);
              }}
              type="number"
              step="0.01"
              size="small"
              disabled={false}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
            />
          </div>
          <div className="module-input">
            <label>Module Area</label>
            <div className="module-area-info">
              <p>{`${parseFloat(local_inputs.mod_width * local_inputs.mod_height).toFixed(2) || 0}`}</p>
              <p>m²</p>
            </div>
          </div>
          {local_inputs.simple_module == 1 ? (
            <div className="warning-text">
              {uiState.tab == "advanced" && <p>Note: Use Simple Module Only if no generic module is available. This Input generates less accurate performance results.</p>}
            </div>
          ) : (
            <Fragment>
              <div className="module-input">
                <label>Advanced Specs</label>
                {mode == "carport" ? (
                  <Switch checked={store.uiState.do_advanced_mod_specs} onChange={(checked) => dispatch(carportActions.setUIState("do_advanced_mod_specs", checked))} />
                ) : (
                  <Switch checked={uiState.do_advanced_mod_specs} onChange={(checked) => dispatch(inputsActions.update_ui_state("do_advanced_mod_specs", checked))} />
                )}
              </div>
              {(mode == "carport" && store.uiState.do_advanced_mod_specs) ||
                (mode != "carport" && uiState.do_advanced_mod_specs && (
                  <Fragment>
                    <div className="spacer"></div>
                    <div className="module-input">
                      <label>Technology</label>
                      <Select
                        className="gm-input"
                        id="technology"
                        value={technology[local_inputs.technology]}
                        size="small"
                        // style={{ width: '100%' }}
                        onChange={(value) => {
                          updateInput("technology", value);
                        }}
                        disabled={false}
                      >
                        <Select.Option key="technology1" value="0">
                          {" "}
                          Si-mono{" "}
                        </Select.Option>
                        <Select.Option key="technology2" value="1">
                          {" "}
                          Si-poly{" "}
                        </Select.Option>
                        <Select.Option key="technology3" value="2">
                          {" "}
                          CdTe{" "}
                        </Select.Option>
                      </Select>
                    </div>
                    <div className="module-input">
                      <label>Gref</label>
                      <Input
                        id="mlm_S_ref"
                        className="gm-input"
                        onChange={(e) => {
                          updateInput(e.target.id, e.target.value);
                        }}
                        value={local_inputs.mlm_S_ref}
                        size="small"
                        type="number"
                        // disabled={saving}
                        suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>W/m²</span>}
                      />
                    </div>
                    <div className="module-input">
                      <label>Tref</label>
                      <Input
                        id="mlm_T_ref"
                        className="gm-input"
                        onChange={(e) => {
                          updateInput(e.target.id, e.target.value);
                        }}
                        value={local_inputs.mlm_T_ref}
                        size="small"
                        type="number"
                        // disabled={saving}
                        suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>°C</span>}
                      />
                    </div>
                    {/* ISC && VOC */}
                    <div className="module-input">
                      <label>Isc</label>
                      <Input
                        id="mlm_I_sc_ref"
                        className="gm-input"
                        onChange={(e) => {
                          updateInput(e.target.id, e.target.value);
                        }}
                        value={local_inputs.mlm_I_sc_ref}
                        size="small"
                        type="number"
                        // disabled={saving}
                        suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>A</span>}
                      />
                    </div>
                    <div className="module-input">
                      <label>Voc</label>
                      <Input
                        id="mlm_V_oc_ref"
                        className="gm-input"
                        onChange={(e) => {
                          updateInput(e.target.id, e.target.value);
                        }}
                        value={local_inputs.mlm_V_oc_ref}
                        size="small"
                        type="number"
                        // disabled={saving}
                        suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>V</span>}
                      />
                    </div>
                    {/* IMPP && VMP */}
                    <div className="module-input">
                      <label>Imp</label>
                      <Input
                        id="mlm_I_mp_ref"
                        className="gm-input"
                        onChange={(e) => {
                          updateInput(e.target.id, e.target.value);
                        }}
                        value={local_inputs.mlm_I_mp_ref}
                        size="small"
                        type="number"
                        // disabled={saving}
                        suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>A</span>}
                      />
                    </div>
                    <div className="module-input">
                      <label>Vmp</label>
                      <Input
                        id="mlm_V_mp_ref"
                        className="gm-input"
                        onChange={(e) => {
                          updateInput(e.target.id, e.target.value);
                        }}
                        value={local_inputs.mlm_V_mp_ref}
                        size="small"
                        type="number"
                        // disabled={saving}
                        suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>V</span>}
                      />
                    </div>
                    {/* TCOEF OF ISC && TCOEF OF VOC */}
                    <div className="module-input">
                      <label>Tcoef of Isc</label>
                      <Input
                        id="mlm_alpha_isc"
                        className="gm-input"
                        onChange={(e) => {
                          updateInput(e.target.id, e.target.value);
                        }}
                        value={local_inputs.mlm_alpha_isc}
                        size="small"
                        type="number"
                        // disabled={saving}
                        suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>mA/°C</span>}
                      />
                    </div>
                    <div className="module-input">
                      <label>Tcoef of Voc</label>
                      <Input
                        id="mlm_beta_voc_spec"
                        className="gm-input"
                        onChange={(e) => {
                          updateInput(e.target.id, e.target.value);
                        }}
                        value={local_inputs.mlm_beta_voc_spec}
                        size="small"
                        type="number"
                        // disabled={saving}
                        suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>mV/°C</span>}
                      />
                    </div>

                    <div className="module-input">
                      <label>Cells in Series</label>
                      <Input
                        id="mlm_N_series"
                        className="gm-input"
                        onChange={(e) => {
                          updateInput(e.target.id, e.target.value);
                        }}
                        value={local_inputs.mlm_N_series}
                        size="small"
                        // disabled={saving}
                        // suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>mV/°C</span>}
                      />
                    </div>

                    {/* CELLS IN PARALLEL */}
                    <div className="module-input">
                      <label>Cells in Parallel</label>
                      <Input
                        id="mlm_N_parallel"
                        className="gm-input"
                        onChange={(e) => {
                          updateInput(e.target.id, e.target.value);
                        }}
                        value={local_inputs.mlm_N_parallel}
                        size="small"
                        // disabled={saving}
                        // suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>mV/°C</span>}
                      />
                    </div>

                    <div className="spacer"></div>

                    <div className="module-input">
                      <label>Rsh</label>
                      <Input
                        id="mlm_R_shref"
                        className="gm-input"
                        onChange={(e) => {
                          updateInput(e.target.id, e.target.value);
                        }}
                        value={local_inputs.mlm_R_shref}
                        size="small"
                        type="number"
                        // disabled={saving}
                        suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>Ω</span>}
                      />
                    </div>
                    <div className="module-input">
                      <label>Rshunt at G=0</label>
                      <Input
                        id="mlm_R_sh0"
                        className="gm-input"
                        onChange={(e) => {
                          updateInput(e.target.id, e.target.value);
                        }}
                        value={local_inputs.mlm_R_sh0}
                        size="small"
                        type="number"
                        // disabled={saving}
                        suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>Ω</span>}
                      />
                    </div>

                    {/* RS && SHUNT EXP */}
                    <div className="module-input">
                      <label>Rs</label>
                      <Input
                        id="mlm_R_s"
                        className="gm-input"
                        onChange={(e) => {
                          updateInput(e.target.id, e.target.value);
                        }}
                        value={local_inputs.mlm_R_s}
                        size="small"
                        type="number"
                        // disabled={saving}
                        suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>Ω</span>}
                      />
                    </div>
                    <div className="module-input">
                      <label>Shunt Exp</label>
                      <Input
                        id="mlm_R_shexp"
                        className="gm-input"
                        onChange={(e) => {
                          updateInput(e.target.id, e.target.value);
                        }}
                        value={local_inputs.mlm_R_shexp}
                        size="small"
                        type="number"
                        // disabled={saving}
                        // suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>Ω</span>}
                      />
                    </div>

                    <div className="spacer"></div>

                    <div className="module-input">
                      <label>Absorb Coef</label>
                      <Input
                        id="mlm_T_c_fa_alpha"
                        className="gm-input"
                        onChange={(e) => {
                          updateInput(e.target.id, e.target.value);
                        }}
                        value={local_inputs.mlm_T_c_fa_alpha}
                        size="small"
                        type="number"
                        // disabled={saving}
                        // suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>Ω</span>}
                      />
                    </div>
                    <div className="module-input">
                      <label>Tcoef of Pmpp</label>
                      <Input
                        id="muPmpReq"
                        className="gm-input"
                        onChange={(e) => {
                          updateInput(e.target.id, e.target.value);
                        }}
                        value={local_inputs.muPmpReq}
                        size="small"
                        type="number"
                        // disabled={saving}
                        suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%/°C</span>}
                      />
                    </div>

                    {/* GAMMA && TCOEF OF GAMME */}
                    <div className="module-input">
                      <label>Gamma</label>
                      <Input
                        id="mlm_n_0"
                        className="gm-input"
                        onChange={(e) => {
                          updateInput(e.target.id, e.target.value);
                        }}
                        value={local_inputs.mlm_n_0}
                        size="small"
                        type="number"
                        // disabled={saving}
                        // suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>Ω</span>}
                      />
                    </div>
                    <div className="module-input">
                      <label>Tcoef of Gamma</label>
                      <Input
                        id="mlm_mu_n"
                        className="gm-input"
                        onChange={(e) => {
                          updateInput(e.target.id, e.target.value);
                        }}
                        value={local_inputs.mlm_mu_n}
                        size="small"
                        type="number"
                        // disabled={saving}
                        suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>1/°C</span>}
                      />
                    </div>
                    <div className="module-input">
                      <label>IAM</label>
                    </div>
                    <div className="spacer"></div>

                    <div className="input-table" style={{ float: "left", width: 180, marginBottom: "16px" }}>
                      <ReactDataSheet
                        data={iam_dt_data}
                        sheetRenderer={renderSheet}
                        valueRenderer={(cell) => cell.value}
                        onContextMenu={(e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null)}
                        onCellsChanged={handleChanges}
                      />
                    </div>
                    <div className="spacer"></div>
                  </Fragment>
                ))}
            </Fragment>
          )}
        </Fragment>
      </div>
    );
  }
};

export { ModuleInputs };
