import defaultProducts from "./defaultProductData";
import defaultModules from "./defaultModsCanopy";
import { curveBumpX } from "d3-shape";

// prettier-ignore
const base_inputs = {
  "features": [],
  "racking": {
    name: "RBI Solar - 2HP",
    icon: 2,
    default: 0,
    type: 0,
    racks: [
      {
        key: 1,
        active: 1,
        xdim: 28.807,
        ydim: 4.133,
        module: 56,
      },
      {
        key: 2,
        active: 1,
        xdim: 14.401,
        ydim: 4.133,
        module: 28,
      },
      {
        key: 3,
        active: 1,
        xdim: 7.198,
        ydim: 4.133,
        module: 14,
      },
    ],
    mods_high: 2,
    gap: 0,
    tilt_min: 5,
    tilt_max: 35,
    tilts: [5, 10, 15, 20, 25, 30, 35],
    continous: 1,
    backtrack: 0,
    track_angle: 5,
    shade_mode: 1,
    string_steps: 2,
    cell_trav: 0.156,
    module_clearance_height: 0.5,
    module_transmission_perc: 0,
    module_structure_shade_perc: 0,
    grade_limit: 20,
    orientation: "portrait",
    vert_gap: 0.025,
    hori_gap: 0.025,
    string_counts: [2, 1, 0.5],
    id: "b0f147ff394e404ba9977eccc4708f46",
  },
  "module": {
    "mlm_N_series": 72,
    "mlm_N_parallel": 2,
    "mlm_N_diodes": 3,
    "mod_width": 1.038,
    "mod_height": 2.094,
    "mlm_V_mp_ref": 41,
    "mlm_I_mp_ref": 10.73,
    "mlm_V_oc_ref": 49.2,
    "mlm_I_sc_ref": 11.45,
    "mlm_S_ref": 1000,
    "mlm_T_ref": 25,
    "mlm_R_shref": 1000,
    "mlm_R_sh0": 500000,
    "mlm_R_shexp": 5.5,
    "mlm_R_s": 0.263,
    "mlm_alpha_isc": 6.73,
    "mlm_beta_voc_spec": -139.9,
    "mlm_n_0": 1.031,
    "mlm_mu_n": -0.0006,
    "mlm_T_c_fa_alpha": 0.9,
    "mlm_D2MuTau": 0,
    "module_bifaciality": 0.7,
    "module_isbifacial": 1,
    "mlm_E_g": 1.121,
    "module_area": 2.174,
    "module_iam_ang": [
      0,
      20,
      30,
      40,
      50,
      60,
      70,
      80,
      90
    ],
    "module_iam_eff": [
      1,
      1,
      1,
      1,
      1,
      1,
      0.95,
      0.76,
      0
    ],
    "rating": 440,
    "name": "Generic Module",
    "technology": 0,
    "muPmpReq": -0.37,
    "default": 0,
    "modules_per_string": 28
  },
  "inverter": {
    "derate_temps": [30, 40, 60, 62],
    "derate_maxoutput": [66, 60, 36, 0],
    "derate_toggle": 1,
    "inverterRating": 66,
    "pnom": 66,
    "inv_pd_pacokw": 66,
    "maxEff": 100,
    "mppt_low_inverter": 600,
    "mppt_hi_inverter": 1480,
    "inv_pd_vdcmax": 1500,
    "inv_pd_pnt": 2,
    "name": "Generic Inverter",

    "default": 0,
    "inv_pd_eff_pout": [8.8, 44.488400000000006, 80.1779, 115.8663, 151.5558, 187.2442, 222.9337, 258.6221, 294.3116, 330.0, 330.0, 513.3337, 696.6663, 880.0, 1063.3337000000001, 1246.6663, 1430.0, 1613.3337, 1796.6663, 1980.0, 1980.0, 2566.6663, 3153.3337, 3740.0, 4326.6663, 4913.3337, 5500.0, 6086.666299999999, 6673.3337, 7260.0],
    "inv_pd_efficiency": [0, 68.89, 84.50999999999999, 90.55, 93.46, 95.07, 96.05, 96.69, 97.14, 97.46000000000001, 98.24000000000001, 98.52, 98.65, 98.72, 98.76, 98.79, 98.81, 98.83, 98.83999999999999, 98.95, 98.96000000000001, 98.94, 98.91, 98.88, 98.86, 98.83, 98.77, 98.72],
    "dc_max_pwr": 0
  },
  "performance": {
    "default": 0,
    "name": "",
    "performance_model": 0,
    "doGridPower": 0,
    "grid_power_limit": 0,
    "modules_per_string": 28,
    "dc_degradation": 0.5,
    "irrad_mode": 2,
    "mismatch_loss": 1,
    "sky_model": 2,
    "dc_loss_stc": 2,
    "quality_loss": -0.3,
    "lid_loss": 2,
    "ac_losses": 4,
    "thermal_uc": 29,
    "thermal_uv": 0,
    "albedo": 0.2,
    "doMonthlyAlbedo": 0,
    "albedo_monthly": [
      0.2,
      0.2,
      0.2,
      0.2,
      0.2,
      0.2,
      0.2,
      0.2,
      0.2,
      0.2,
      0.2,
      0.2
    ],
    "soiling": 1.5,
    "doMonthlySoiling": 0,
    "soiling_monthly": [
      1.5,
      1.5,
      1.5,
      1.5,
      1.5,
      1.5,
      1.5,
      1.5,
      1.5,
      1.5,
      1.5,
      1.5
    ],
    "g_input": 0.5,

    "weather": "",
    "weather_source": 0,    
    "lat": 33.2164,
    "lng": -97.1292,
    "tz": 10,
    "ele": 350    
  },
  "finance": {
    "default": 0,
    "name": "",
    "metric": 0,
    "analysis_period": 0,
    "discount_rate": 0,
    "module_cost": 0,
    "other_dc_cost": 0,
    "ac_cost": 0,
    "fixed_cost": 0,
    "dc_op_cost": 0,
    "ac_op_cost": 0,
    "fixed_op_cost": 0,
    "escalation": 0,
    "doCustomSchedule": 0,
    "customSchedule": []
  },
  "config": {
    "platform": "rbi",
    "gcr_range": [0.2,0.7],
    "gcr_step": 0.01,
    "pitch_range": [0,0],
    "pitch_step": 0,
    "pitch_min_max": [0,0],
    "pitch_count": 0,
    "gcr_count": 51,
    "gcr_pitch_count": 0,
    "dcac_range": [1.2,1.4],
    "selected_gcr_pitch": 0,
    "selected_slider_fixed": 0,
    "spi_range": [250,250],
    "spi_min_max": [250,250],
    "spi_step": 1,
    "spi_count": 1,
    "azimuth": 180,
    "layout_margin": 100,
    "layout_fill": 1,

    "do_finance": 0,
    "do_continuous": 0,
    "do_dc_lock": 0,
    "dc_lock_value": 0,
    
    "do_inverter_qty_lock": 0,
    "inverter_qty": 0,
    
    "do_rack_align": 1,
    "do_roads": 1,
    "road_spacing_option": 2,
    "road_spacing": 200,

    "do_inv_spacing": 1,
    "inverter_per_cut": 1,

    "worker_count": 0,
    "worker_limit": 500
    
  },
  "topo": {
    "do_topo": 0,
    "topo_id": "",
    "topo_action": "",
    "grade_limit": 0,
  }
}

export function convert_to_swm(rbi_inputs) {
  // copy swm inputs
  let swm_inputs = JSON.parse(JSON.stringify(base_inputs));
  // default modules
  let modules = defaultModules;
  let racking = defaultProducts;

  // plug the RBI inputs into this swm_base_input object
  // console.log(rbi_inputs)
  swm_inputs['features'] = rbi_inputs['features'];
  swm_inputs['layout'] = rbi_inputs['layout'];
	swm_inputs['layout']['combiner_location'] = swm_inputs['layout']['combiner_location'] == 0 ? 'centroids' : 'by_road'

  let _racks = [];
  Object.values(rbi_inputs["product"]).map((rack) => {
    _racks.push({
      key: rack.key,
      xdim: parseFloat(rack.xdim),
      ydim: parseFloat(rack.ydim),
      module: parseInt(rack.module),
      active: rack.active,
    });
  });

  swm_inputs["racking"] = {
    ...swm_inputs["racking"],
    ...rbi_inputs["racking"],
    racks: _racks,

    // module_transmission_perc: parseFloat(rbi_inputs["bifacial_transmission_factor"]),
    // module_structure_shade_perc: parseFloat(rbi_inputs["bifacial_structure_shade_factor"]),
    // module_clearance_height: parseFloat(rbi_inputs["bifacial_ground_clearance_height"]),

    tilt_min: parseFloat(rbi_inputs["OverrideTilt"][0]),
    tilt_max: parseFloat(rbi_inputs["OverrideTilt"][1]),

    // need to plug in override dims but need to swap up names and variables
    // racks: _racks,
    // racks: rbi_inputs['product'],
  };

  swm_inputs["module"] = {
    ...swm_inputs["module"],
    ...rbi_inputs["module"],

    // ...modules[rbi_inputs['selectedModuleIndex']],
    // module_isbifacial: rbi_inputs['is_bifacial'] ? 1 : 0,
    // module_bifaciality: parseInt(rbi_inputs['bifaciality']),
  };
  // MODULE  VALIDATION
  if (swm_inputs.module.rating <= 0 || isNaN(swm_inputs.module.rating)) {
    // errors.push('Invalid module rating. Select or import a module in the Module Tab.')
  }
  swm_inputs.module.rating = parseFloat(swm_inputs.module.rating);
  swm_inputs.module.mlm_D2MuTau = parseFloat(swm_inputs.module.mlm_D2MuTau);
  swm_inputs.module.mlm_E_g = parseFloat(swm_inputs.module.mlm_E_g);
  swm_inputs.module.mlm_I_mp_ref = parseFloat(swm_inputs.module.mlm_I_mp_ref);
  swm_inputs.module.mlm_I_sc_ref = parseFloat(swm_inputs.module.mlm_I_sc_ref);
  swm_inputs.module.mlm_Length = parseFloat(swm_inputs.module.mlm_Length);
  swm_inputs.module.mlm_N_diodes = parseFloat(swm_inputs.module.mlm_N_diodes);
  swm_inputs.module.mlm_N_parallel = parseFloat(swm_inputs.module.mlm_N_parallel);
  swm_inputs.module.mlm_N_series = parseFloat(swm_inputs.module.mlm_N_series);
  swm_inputs.module.mlm_R_s = parseFloat(swm_inputs.module.mlm_R_s);
  swm_inputs.module.mlm_R_sh0 = parseFloat(swm_inputs.module.mlm_R_sh0);
  swm_inputs.module.mlm_R_shexp = parseFloat(swm_inputs.module.mlm_R_shexp);
  swm_inputs.module.mlm_R_shref = parseFloat(swm_inputs.module.mlm_R_shref);
  swm_inputs.module.mlm_S_ref = parseFloat(swm_inputs.module.mlm_S_ref);
  swm_inputs.module.mlm_T_c_fa_alpha = parseFloat(swm_inputs.module.mlm_T_c_fa_alpha);
  swm_inputs.module.mlm_T_ref = parseFloat(swm_inputs.module.mlm_T_ref);
  swm_inputs.module.mlm_V_mp_ref = parseFloat(swm_inputs.module.mlm_V_mp_ref);
  swm_inputs.module.mlm_V_oc_ref = parseFloat(swm_inputs.module.mlm_V_oc_ref);
  swm_inputs.module.mlm_Width = parseFloat(swm_inputs.module.mlm_Width);
  swm_inputs.module.mlm_alpha_isc = parseFloat(swm_inputs.module.mlm_alpha_isc);
  swm_inputs.module.mlm_beta_voc_spec = parseFloat(swm_inputs.module.mlm_beta_voc_spec);
  swm_inputs.module.mlm_mu_n = parseFloat(swm_inputs.module.mlm_mu_n);
  swm_inputs.module.mlm_n_0 = parseFloat(swm_inputs.module.mlm_n_0);

	swm_inputs.module.width = swm_inputs.module.mlm_Width
	swm_inputs.module.height = swm_inputs.module.mlm_Length

  let combine_iam = [];
  swm_inputs.module.module_iam_ang.forEach((ang, index) => {
    swm_inputs.module.module_iam_ang[index] = parseFloat(ang);
  });
  swm_inputs.module.module_iam_eff.forEach((eff, index) => {
    swm_inputs.module.module_iam_eff[index] = parseFloat(eff);

    combine_iam.push([swm_inputs.module.module_iam_ang[index], swm_inputs.module.module_iam_eff[index]]);
  });
  // now that we have the IAM arrays fixed as floats, lets combine, sort, and split again
  combine_iam.sort(function(a, b) {
    if (a[0] == b[0]) {
      return a[1] - b[1];
    }
    return a[0] - b[0];
  });
  let stringArray = combine_iam.map(JSON.stringify);
  let uniqueStringArray = new Set(stringArray);
  swm_inputs.module.module_iam_ang = [];
  swm_inputs.module.module_iam_eff = [];
  Array.from(uniqueStringArray, JSON.parse).forEach((row, index) => {
    swm_inputs.module.module_iam_ang.push(row[0]);
    swm_inputs.module.module_iam_eff.push(row[1]);
  });

  // field for bifacial patch
  swm_inputs.module.module_bifaciality = parseFloat(swm_inputs.module.module_bifaciality);
  swm_inputs.module.module_area = parseFloat(swm_inputs.module.module_area);

  // console.log('module', swm_inputs.module);
	let simple_module = 0;
  if (swm_inputs.module.name == "Simple Module Input") {
    swm_inputs.module.name = `${swm_inputs.module.rating}W ${swm_inputs.module.name}`;
		simple_module = 1;
  }

	let mods_per_string = parseInt(rbi_inputs["performance"]["mod_per_string"])

	if (swm_inputs["racking"]["string_counts"].length == 0) {
		// GLIDE product
		mods_per_string = parseInt(swm_inputs["racking"]["racks"][0]["module"])
	}
	if (rbi_inputs['tab'] == 'advanced'){
		if (swm_inputs["racking"]["string_counts"].length > 0) {
			mods_per_string = parseInt(swm_inputs["racking"]["racks"][0]["module"] / swm_inputs["racking"]["string_counts"][0])
		}
	}

  swm_inputs["performance"] = {
    ...rbi_inputs["performance"],
		// simple_module: simple_module,
		// simple_inverter: 1,
		// simple_inverter_dcac: parseFloat(rbi_inputs["dcac"]),

    mods_per_string: mods_per_string,

    latitude: rbi_inputs["center"][0],
    longitude: rbi_inputs["center"][1],
		en_bifacial: swm_inputs.module.module_isbifacial,
		bi_bifaciality: parseFloat(swm_inputs.module.module_bifaciality),
    // losses
    // soiling: Array.from({ length: 12 }, (v, k) => parseFloat(rbi_inputs["losses"]["soiling_loss"])/100),
    // albedo: Array.from({ length: 12 }, (v, k) => parseFloat(swm_inputs["performance"]["albedo"])/100),
    // mismatch_loss: parseFloat(rbi_inputs["losses"]["mismatch_loss"]),
    // dc_loss_stc: parseFloat(rbi_inputs["losses"]["wiring_loss"]),
    // quality_loss: parseFloat(rbi_inputs["losses"]["module_quality_loss"]),
    // lid_loss: parseFloat(rbi_inputs["losses"]["module_lid_loss"]),
    // ac_losses: parseFloat(rbi_inputs["losses"]["combined_ac_loss"]),
    // inverter_eff: parseFloat(rbi_inputs["losses"]["inverter_eff"]),
  };
  swm_inputs["config"] = {
    ...swm_inputs["config"],

    dcac: parseFloat(rbi_inputs["dcac"]),
    gcr_range: rbi_inputs["doOverrideGCR"] ? [parseFloat(rbi_inputs["OverrideGCR"][0]), parseFloat(rbi_inputs["OverrideGCR"][1])] : [0.3, 0.7],
    // azimuth: parseInt(rbi_inputs["azimuth"]),
    azimuth: parseInt(swm_inputs['layout']["azimuth"]),

    product_index: rbi_inputs["selectedProductIndex"],
    module_index: rbi_inputs["selectedModuleIndex"],

    do_override_tilt: rbi_inputs["doOverrideTilt"] ? 1 : 0,

    do_roads: swm_inputs['layout']["do_roads"] ? 1 : 0,
    do_rack_align: swm_inputs['layout']["do_rack_align"] ? 1 : 0,

    do_continuous: rbi_inputs["do_continuous"] ? 1 : 0,

    dc_lock_value: parseInt(rbi_inputs["dc_lock_value"]),
    do_dc_lock: rbi_inputs["do_dc_lock"] ? 1 : 0,
  };

  swm_inputs["topo"] = {
    ...swm_inputs["topo"],

    do_topo: rbi_inputs["doTopo"],
    do_grade: rbi_inputs["doGrade"],
    topo_id: rbi_inputs["topo_id"],
    topo_action: rbi_inputs["topo_action"],
    grade_limit: parseInt(rbi_inputs["grade_limit"]),
  };

  // console.log(swm_inputs)
  return swm_inputs;
}

export function convert_to_swm_canopy(canopies, center) {
  // console.log('Working with these canopies for simulation:', canopies);
  let swm_inputs = JSON.parse(JSON.stringify(base_inputs));

  delete swm_inputs["topo"];
  delete swm_inputs["finance"];
  delete swm_inputs["features"];

  (swm_inputs["config"]["dcac"] = parseFloat(1.4)), (swm_inputs["config"]["product_index"] = 0);
  swm_inputs["config"]["module_index"] = 0;

  swm_inputs["performance"] = {
    ...swm_inputs["performance"],

    modules_per_string: 1,

    lat: center[0],
    lng: center[1],
    // lat: 0,
    // lng: 0,

    // losses
    soiling_monthly: Array.from({ length: 12 }, (v, k) => parseFloat(2)),
    mismatch_loss: parseFloat(1),
    dc_loss_stc: parseFloat(1.5),
    quality_loss: parseFloat(0),
    lid_loss: parseFloat(2),
    ac_losses: parseFloat(2),
    inverter_eff: parseFloat(97),
  };

  let final_canopy = [];

  Object.values(canopies).map((canopy) => {
    // console.log(canopy)
    swm_inputs["racking"]["tilt_min"] = canopy.tilt;
    swm_inputs["racking"]["tilt_max"] = canopy.tilt;
    // swm_inputs['racking']['do_dualTilt'] = canopy.planeType=='inverted'||canopy.planeType=='dual_tilt'?1:0;
    let do_dualTilt = 0;
    let away_from_azimuth = 0;
    let towards_azimuth = 0;
    // if inverted, make sure there's a row away from azimuth
    if (canopy.planeType == "inverted") {
      if (canopy.dimensions.modY - canopy.towardAzimuth > 0) {
        do_dualTilt = 1;
        away_from_azimuth = canopy.editCellsGeoJson.filter((cell) => cell.properties.enabled && cell.properties.awayFromAzimuth).length;
        towards_azimuth = canopy.editCellsGeoJson.filter((cell) => cell.properties.enabled && !cell.properties.awayFromAzimuth).length;

      } else {
        canopy.planeType = "single_slope";
      }
    }
    if (canopy.planeType == "dual_tilt") {
      do_dualTilt = 1;
      away_from_azimuth = canopy.editCellsGeoJson.filter((cell) => cell.properties.enabled && cell.properties.awayFromAzimuth).length;
      towards_azimuth = canopy.editCellsGeoJson.filter((cell) => cell.properties.enabled && !cell.properties.awayFromAzimuth).length;
			canopy.tiltFromAzimuth = canopy.tilt
			canopy.tiltToAzimuth = canopy.tilt
    }

    // MODULE  VALIDATION
    canopy.module.rating = parseFloat(canopy.module.rating);
    canopy.module.mlm_Length = parseFloat(canopy.module.mod_height);
    canopy.module.mlm_Width = parseFloat(canopy.module.mod_width);

    if (canopy.module.do_pvWatts == undefined) {
      canopy.module.mlm_D2MuTau = parseFloat(canopy.module.mlm_D2MuTau);
      canopy.module.mlm_E_g = parseFloat(canopy.module.mlm_E_g);
      canopy.module.mlm_I_mp_ref = parseFloat(canopy.module.mlm_I_mp_ref);
      canopy.module.mlm_I_sc_ref = parseFloat(canopy.module.mlm_I_sc_ref);
      canopy.module.mlm_N_diodes = parseFloat(canopy.module.mlm_N_diodes);
      canopy.module.mlm_N_parallel = parseFloat(canopy.module.mlm_N_parallel);
      canopy.module.mlm_N_series = parseFloat(canopy.module.mlm_N_series);
      canopy.module.mlm_R_s = parseFloat(canopy.module.mlm_R_s);
      canopy.module.mlm_R_sh0 = parseFloat(canopy.module.mlm_R_sh0);
      canopy.module.mlm_R_shexp = parseFloat(canopy.module.mlm_R_shexp);
      canopy.module.mlm_R_shref = parseFloat(canopy.module.mlm_R_shref);
      canopy.module.mlm_S_ref = parseFloat(canopy.module.mlm_S_ref);
      canopy.module.mlm_T_c_fa_alpha = parseFloat(canopy.module.mlm_T_c_fa_alpha);
      canopy.module.mlm_T_ref = parseFloat(canopy.module.mlm_T_ref);
      canopy.module.mlm_V_mp_ref = parseFloat(canopy.module.mlm_V_mp_ref);
      canopy.module.mlm_V_oc_ref = parseFloat(canopy.module.mlm_V_oc_ref);
      canopy.module.mlm_alpha_isc = parseFloat(canopy.module.mlm_alpha_isc);
      canopy.module.mlm_beta_voc_spec = parseFloat(canopy.module.mlm_beta_voc_spec);
      canopy.module.mlm_mu_n = parseFloat(canopy.module.mlm_mu_n);
      canopy.module.mlm_n_0 = parseFloat(canopy.module.mlm_n_0);

			canopy.module.module_bifaciality = parseFloat(canopy.module.bi_bifaciality);
			

      let combine_iam = [];
      canopy.module.module_iam_ang.forEach((ang, index) => {
        canopy.module.module_iam_ang[index] = parseFloat(ang);
      });
      canopy.module.module_iam_eff.forEach((eff, index) => {
        canopy.module.module_iam_eff[index] = parseFloat(eff);

        combine_iam.push([canopy.module.module_iam_ang[index], canopy.module.module_iam_eff[index]]);
      });
      // now that we have the IAM arrays fixed as floats, lets combine, sort, and split again
      combine_iam.sort(function(a, b) {
        if (a[0] == b[0]) {
          return a[1] - b[1];
        }
        return a[0] - b[0];
      });
      let stringArray = combine_iam.map(JSON.stringify);
      let uniqueStringArray = new Set(stringArray);
      canopy.module.module_iam_ang = [];
      canopy.module.module_iam_eff = [];
      Array.from(uniqueStringArray, JSON.parse).forEach((row, index) => {
        canopy.module.module_iam_ang.push(row[0]);
        canopy.module.module_iam_eff.push(row[1]);
      });
    }

    if ((canopy.module.name = "Simple Module Input")) {
      canopy.module.name = `${canopy.module.rating}W ${canopy.module.name}`;
    }

    let module_count = 0;
    module_count = canopy.editCellsGeoJson.filter((cell) => cell.properties.enabled).length;

    final_canopy.push({
      id: canopy.id.replace("-", ""),
      // moduleCount: parseInt(canopy.dimensions.modX * canopy.dimensions.modY),
      moduleCount: module_count,
      tilt: canopy.tilt,
      azimuth: canopy.azimuth,
      name: canopy.name,
      modules_per_string: 28,
      module: canopy.module,
      dimensions: canopy.dimensions,
      planeType: canopy.planeType,
      tiltFromAzimuth: canopy.tiltFromAzimuth,
      tiltToAzimuth: canopy.tiltToAzimuth,
      towardAzimuth: canopy.towardAzimuth,
      do_dualTilt: do_dualTilt,
      awayFromAzimuthCount: away_from_azimuth,
      towardsFromAzimuthCount: towards_azimuth,
    });
  });

  swm_inputs["canopies"] = final_canopy;

  // console.log('Inputs to be sent to backend:', swm_inputs);
  return swm_inputs;
}
