import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import matchSorter from "match-sorter";

// components
import { EditableCell, EditableRow } from "./EditableTable";

// antd
import { Modal, Button, Input, Menu, Dropdown, Table, Popconfirm, Select, Checkbox, Spin } from "antd";
import { SaveOutlined, PlusOutlined, EllipsisOutlined, FolderOpenOutlined, BookOutlined, NotificationOutlined, ConsoleSqlOutlined } from "@ant-design/icons";
import { ProjectOutlined } from "@ant-design/icons";
//redux
import { useSelector, useDispatch } from "react-redux";
import { carportActions, portalActions, inputsActions } from "../Redux";
import { isAuthenticated } from "../Redux";

// utils
import { shareProject } from "../../utils/analysis";

const emails = [
  "jbartosch@rbisolar.com",
  "dcompaan@rbisolar.com",
  "clantz@rbisolar.com",
  "mfreshwater@rbisolar.com",
  "mmideros@rbisolar.com",
  "kwright@rbisolar.com",
  "jbethel@rbisolar.com",
  "burbanek@roughbros.com",
  "matt.hayes@solarbos.com",
  "lpalmer@rbisolar.com",
  "aheid@rbisolar.com",
  "eschnese@rbisolar.com",
  "skneipp@rbisolar.com",
  "mslack@rbisolar.com",
  "rhartman@rbisolar.com",
  "jcormican@rbisolar.com",
  "rnixson@rbisolar.com",
  "dcampbell@rbisolar.com",
  "dvaughn@rbisolar.com",
  "mbeck@rbisolar.com",
  "sborman@rbisolar.com",
  "avanvactor@rbisolar.com",
  "dbulen@rbisolar.com",
  "ABertke@rbisolar.com",
  "kanderson@rbisolar.com",
  "sspiker@rbisolar.com",
  "jfernando@rbisolar.com",
  "jhinde@rbisolar.com",
  "abrescia@rbisolar.com",
  "gvarney@rbisolar.com",
  "ewford@rbisolar.com",
  "JStolz@rbisolar.com",
  "MRWalsh@rbisolar.com",
  "lstrassburger@rbisolar.com",
  "mconstable@rbisolar.com",

  "dvukovic@gibraltar1.com",
  "admin@sunfig.com",
  "bugs@sunfig.solar",
  "nicholas.sanderson@sunfig.com",
  "joshua.belknap@sunfig.com",
  "ashton.vandemark@sunfig.com",
  // "mcooke@rbisolar.com",
  // "burbanek@roughbros.com",
  // "bvietas@rbisolar.com",
  // "dvukovic@rbisolar.com",
  // "ashton.vandemark@sunfig.com",
  // "matt.hayes@solarbos.com",
  // "rnixson@rbisolar.com",
];

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

let shareModal = undefined;

const getTime = (editDate) => {
  let date = new Date(editDate * 1000);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  let format_d = `${strTime} - ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

  return format_d.toString();
};

// project mananger style
const ProjectManagerWrap = styled.section`
  padding: 15px 0;
  width: inherit;
  height: 100%;
  display: grid;
  grid-template-rows: 40px auto;

  .project-manager-buttons {
    grid-row: 1 / auto;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .project-title {
    grid-row: 2;
    height: 100%;
    margin: 0 15px;

    p {
      font-size: 14px;
      margin: 0;
    }
  }
`;

const ProjectManager = ({ mode, stopEditing, collectInputs, handleCanopyZoomExtents }) => {
  const portal = useSelector((state) => state.portal);
  const inputs = useSelector((state) => state.inputs);
  const carport = useSelector((state) => state.carport);

  // const projects = mode == "ground" ? portal.Projects : mode == "canopy" && Object.values(carport.projectManager.localProjects)
  const dispatch = useDispatch();

  const [modal_type, set_modal_type] = useState("save");
  const [temp_projectName, set_temp_projectName] = useState("");
  const [project_sortedInfo, set_project_sortedInfo] = useState({
    columnKey: "edit_dt",
    field: "edit_dt",
    order: "descend",
  });
  const [filterText, setFilterText] = useState("");
  const [filteredInfo, setFilteredInfo] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);

  // portal specific
  const [selectedProject, setSelectedProject] = useState(undefined);
  // const [projectShare, setProjectShare] = useState(undefined);
  // const [shareEmail, setShareEmail] = useState('');
  const [sharing, setSharing] = useState(false);

  // const [shareEmail, setShareEmail] = useState('');
  // const [projectShare, setProjectShare] = useState(undefined);
  const shareEmail = useRef("");
  const projectShare = useRef(undefined);
  const notifyEmail = useRef(false);

  const removeImageLayer = (key) => {
    var image_x = document.getElementsByClassName(key);
    let deleting = true;
    let runCount = 0;
    while (deleting) {
      // tried using a for loop, but whenever you removeChild
      // it also removes that from the image_x list
      image_x[0].parentNode.removeChild(image_x[0]);
      runCount++;
      // exit loop when image_x list is depleted or has ran more than 4 times (prevent inf loops)
      if (image_x.length == 0 || runCount > 4) {
        deleting = false;
      }
    }
  };

  // wrapper
  const useForceUpdate = () => {
    const [, setState] = useState();
    return () => setState({});
  };
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (mode == "ground") {
      setFilteredProjects(portal.Projects);
    } else if (mode == "canopy") {
      setFilteredProjects(Object.values(carport.projectManager.localProjects));
    }
  }, [portal.Projects, carport.projectManager.localProjects]);

  // this effect runs whenever the filterText changes. Used to filterting projects
  useEffect(() => {
    handleFilterProjects();
  }, [filterText]);

  const handleSaveProject = (saveas = false) => {
    if (mode == "canopy") {
      // called by either save new project or save as,
      let new_input_structure = {};
      Object.values(carport.rectangles).map((rect) => {
        new_input_structure[rect.id] = {
          ...rect,
          visibleGeoJson: undefined,
          editCellsGeoJson: undefined,
        };
      });

      let save_project = {
        project_id: !saveas && carport.projectManager.currentProjectId ? carport.projectManager.localProjects[carport.projectManager.currentProjectId].id : undefined,
        rectangles: new_input_structure,
        images: carport.images,
        project_name: !saveas && carport.projectManager.currentProjectId ? carport.projectManager.localProjects[carport.projectManager.currentProjectId].name : temp_projectName,
        active: 1,
        project_type: 1,
      };
      // console.log(save_project)
      // dispatch(carportActions.saveProject(save_project));
      dispatch(carportActions.save_project_inputs(save_project));
      set_temp_projectName(undefined);
    } else if (mode == "ground") {
      let new_project_inputs = {
        ...inputs,
        // 0 for groundmount 1 for canopy
        project_type: 0,
      };

      if (portal.uiControls.modalMode == "saveas") {
        new_project_inputs.project_id = undefined;
      }

      dispatch(portalActions.save_project_inputs(new_project_inputs));

      // if (portal.uiControls.modalMode == "save") {
      //   //
      // } else if (portal.uiControls.modalMode == "saveas") {
      //   //
      //   dispatch(portalActions.save_project_inputs(new_project_inputs));
      //   // old_format_project.edit_dt = portal.current_project.edit_dt;
      //   // old_format_project.id = undefined;
      //   // old_format_project.edit_dt = portal.current_project.edit_dt;
      //   // dispatch(portalActions.saveProject(old_format_project));
      // }
    }
  };

  const handleFilterProjects = () => {
    if (mode == "canopy") {
      if (filterText == "") {
        setFilteredProjects(Object.values(carport.projectManager.localProjects));
        return;
      }
      let filteredProjects =
        matchSorter(Object.values(carport.projectManager.localProjects), filterText, {
          keys: ["name"],
        }) || Object.values(carport.projectManager.localProjects);
      setFilteredProjects(filteredProjects);
    } else if (mode == "ground") {
      if (filterText == "") {
        setFilteredProjects(portal.Projects);
        return;
      }
      let filteredProjects =
        matchSorter(Object.values(portal.Projects), filterText, {
          keys: ["name"],
        }) || Object.values(portal.Projects);
      setFilteredProjects(filteredProjects);
    }
  };

  const handleLoadProjectRow = (event, record, rowIndex) => {
    setSelectedProject(record.id);
    handleLoadProject();
  };

  const handleLoadProject = (id) => {
    // check to see Project manager mode and act accordingly
    if (mode == "ground") {
      // make sure all layers are not editing when loading a project
      let project_to_load = portal.Projects.find((project) => project.id == selectedProject);
      // console.log(project_to_load)
      dispatch(inputsActions.update_bulk_inputs(project_to_load.inputs));
      dispatch(portalActions.loadProject(selectedProject));
      return;
    }
    // deselect and stop editing any canopies that may be selected so as not to leave an editing layer on the map when a new project is requested
    // if (carport.selectedRectId) {
    //   stopEditing(carport.selectedRectId);
    // }
    // remove image layers if there are any
    if (Object.values(carport.images).length > 0) {
      Object.keys(carport.images).map((key) => removeImageLayer(key));
    }

    // dispatch(carportActions.loadProjectById(id));
    // by passing an ID to deleteAllRectangles, it will clear out the
    // map and then load the project
    // console.log(id, selectedProject)
    dispatch(carportActions.deleteAllRectangles(id));
  };

  const handleProjectNameUpdate = (value) => {
    dispatch(inputsActions.update_input("project_name", value));
    // dispatch(portalActions.projectNameUpdate(value));
  };

  // TODO: make sure this works
  const showShareModal = (record) => {
    // setProjectShare(record);
    projectShare.current = record;
    forceUpdate();

    shareModal = Modal.success({
      title: "",
      icon: null,
      okText: "Share",
      maskClosable: true,
      content: modalContent(false, undefined),
      okButtonProps: { style: { display: "none" } },
    });
  };

  const handleProjectManagerFeedback = (type) => {};

  const handleProjectShare = async (email, project_share, notifyShare) => {
    let params = {
      message: `Project Shared with you.`,
      email: email,
      name: project_share.project_name,
      // surface: project_share.surface,
      inputs: project_share,
      project_type: mode == "ground" ? 0 : 1,
      notify: notifyShare,
    };

    let resp = await shareProject({ ...params });

    return resp;
  };

  const handleToolbarButtonPressed = (type) => {
    if (mode == "canopy") {
      if (type == "new") {
        // hitting new project
        dispatch(carportActions.newProject());
        // deselect and stop editing any canopies that may be selected so as not to leave an editing layer on the map when a new project is requested
        if (carport.selectedRectId) {
          stopEditing(carport.selectedRectId);
        }
        // remove image layers if there are any
        if (Object.values(carport.images).length > 0) {
          Object.keys(carport.images).map((key) => removeImageLayer(key));
        }

        return;
      }

      if (type == "save" && carport.projectManager.currentProjectId) {
        // hitting save with a project loaded
        handleSaveProject();
        return;
      }

      // save+no project loaded, saveas, or load button pressed
      set_modal_type(type);
      dispatch(carportActions.toggleProjectManagerModal(true));
    } else if (mode == "ground") {
      if (type == "new") {
        dispatch(inputsActions.reset_inputs_to_default());
        dispatch(portalActions.handleNewProject());
        return;
      }

      if (type == "load") {
        dispatch(portalActions.showProjectLoadMenu("load"));
        return;
      }

      // we've got to form the project save obj as the old structure for now...
      // let old_format_project = {
      //   surface: JSON.stringify(Object.values(portal.inputs.features)),
      //   inputs: JSON.stringify(collectInputs(false)),
      //   name: portal.current_project.name,
      //   id: portal.current_project.id,
      //   active: 1,
      //   project_type: 0,
      // };

      let new_project_inputs = {
        ...inputs,
        // 0 for groundmount 1 for canopy
        project_type: 0,
      };

      if (!portal.current_project.loaded) {
        // hit save or save as and no project loaded.. open modal
        dispatch(portalActions.showProjectLoadMenu("save"));
        return;
      } else if (type == "save" && portal.current_project.loaded) {
        // hit save and project IS loaded.. save

        // dispatch project to save to backend
        // dispatch(portalActions.saveProject(old_format_project));
        // console.log(portal.current_project)
        dispatch(portalActions.save_project_inputs(new_project_inputs));
        return;
      } else {
        // hit save as
        dispatch(portalActions.showProjectLoadMenu("saveas"));
        return;
      }
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    let _sorter = sorter;
    if (!sorter.order) {
      // interrupt the default functionality
      _sorter = project_sortedInfo;
      _sorter.order = "ascend";
    }
    _sorter.columnKey = _sorter.field;
    setFilteredInfo({ ...filters });
    set_project_sortedInfo(_sorter);
  };

  const handleProjectSelect = (event, record, rowIndex) => {
    setSelectedProject(record.id);
    // dispatch(portalActions.projectSelect(record.id));
  };

  const handleProjectSelected = (record) => {
    if (record.id == carport.projectManager.selectedProjectId) {
      return;
    }
    dispatch(carportActions.selectProjectById(record.id));
  };

  const handleCloseModal = () => {
    if (mode == "canopy") {
      dispatch(carportActions.toggleProjectManagerModal(false));
    } else {
      dispatch(portalActions.toggleOpenClose(false));
    }
  };

  const handleDeleteProject = (id) => {
    let del_proj;
    if (mode == "canopy") {
      del_proj = carport.projectManager.localProjects[id];
      del_proj.active = 0;
      del_proj.project_id = del_proj.id;
      del_proj.project_name = del_proj.name;
      // dispatch(carportActions.saveProject(del_proj));
      dispatch(carportActions.save_project_inputs(del_proj));
    } else if (mode == "ground") {
      let project_to_delete = portal.Projects.find((project) => id == project.id);
      del_proj = {
        ...project_to_delete.inputs,
        // 0 for groundmount 1 for canopy
        project_type: 0,
        active: 0,
        project_id: id,
      };
      // console.log(del_proj)
      dispatch(portalActions.save_project_inputs(del_proj));
      // dispatch(portalActions.deleteProject(project_to_delete));
    }
  };

  const onShare = async () => {
    // console.log('sharing project with ', this.state.shareEmail
    let found_index = emails.findIndex((email) => email == shareEmail.current);

    if (found_index >= 0) {
      shareModal.update({
        content: modalContent(true, undefined),
      });
      setSharing(true);

      let resp = await handleProjectShare(shareEmail.current, projectShare.current, notifyEmail.current);
      // console.log('resp', resp);

      shareModal.update({
        content: modalContent(true, "Share complete. Window will close in 2 seconds."),
      });

      setTimeout(() => {
        setSharing(false);
        // setShareEmail(undefined);
        projectShare.current = undefined;
        shareEmail.current = undefined;
        forceUpdate();
        shareModal.destroy();
      }, 2000);
    } else {
      setSharing(false);
      // not found
      shareModal.update({
        content: modalContent(false, "Share failed. Check email."),
      });
    }
  };

  const onSearch = (val) => {
    // console.log('search:', val);
    // this.setState({ share_email: val });
  };

  const onUpdateNotify = () => {
    notifyEmail.current = !notifyEmail.current;
    forceUpdate();
    shareModal.update({
      content: modalContent(false, undefined),
    });
  };

  let modalContent = (loading, message) => (
    <div>
      <Select
        showSearch
        disabled={loading}
        style={{ width: "100%" }}
        placeholder="Select an Email to share with..."
        optionFilterProp="children"
        // onChange={(value) => setShareEmail(value)}
        onChange={(value) => {
          shareEmail.current = value;
          forceUpdate();
        }}
        onSearch={onSearch}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {emails.map((email, index) => {
          return (
            <Select.Option key={index} value={email}>
              {email}
            </Select.Option>
          );
        })}
      </Select>

      <div style={{ marginTop: "5px" }}> {message} </div>

      <div style={{ marginTop: "15px", float: "right" }}>
        <Checkbox onChange={onUpdateNotify} checked={notifyEmail.current}>
          Notify by Email?
        </Checkbox>
        <Button type="primary" onClick={onShare} loading={loading}>
          {" "}
          Share
        </Button>
      </div>
    </div>
  );

  const menu = (
    <Menu onClick={() => handleToolbarButtonPressed("saveas")}>
      <Menu.Item disabled={usable} key="1">
        <SaveOutlined />
        Save As
      </Menu.Item>
    </Menu>
  );

  const loadable = (Object.values(carport.projectManager.localProjects).length == 0 && Object.values(portal.Projects).length == 0) || !isAuthenticated;

  const usable = mode == "canopy" ? !isAuthenticated || carport.projectManager.loading : null;

  return (
    <ProjectManagerWrap>
      <div className="project-manager-buttons">
        <Popconfirm title="Start a new project?" onConfirm={() => handleToolbarButtonPressed("new")}>
          <Button disabled={mode == "canopy" ? carport.projectManager.loading : portal.uiControls.loading} style={{ marginRight: "5px" }}>
            <span>
              <PlusOutlined /> New
            </span>
          </Button>
        </Popconfirm>
        <Button.Group>
          <Button loading={mode == "canopy" ? carport.projectManager.loading : portal.uiControls.loading} disabled={usable} id="save" onClick={() => handleToolbarButtonPressed("save")}>
            <span>
              <SaveOutlined /> Save
            </span>
          </Button>
          <Dropdown trigger={["click"]} overlay={menu} placement="bottomRight" disabled={usable}>
            <Button style={{ paddingLeft: "8px", paddingRight: "8px" }}>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </Button.Group>
        <Button
          // TODO: FIX THIS AFTER SPI
          disabled={loadable}
          loading={mode == "canopy" ? carport.projectManager.loading : portal.uiControls.loading}
          style={{ marginRight: "5px", marginLeft: "5px" }}
          onClick={() => handleToolbarButtonPressed("load")}
        >
          <span>
            <FolderOpenOutlined /> Load
          </span>
        </Button>

        {mode == "canopy" && (
          <Modal
            title=""
            className="sift-modal"
            closable={false}
            onCancel={handleCloseModal}
            width={modal_type == "save" || modal_type == "saveas" ? "20%" : "65%"}
            visible={carport.projectManager.modal_visible}
            footer={
              <div>
                {modal_type == "load" && (
                  <span style={{ position: "absolute", left: "15px" }}>
                    <Input type="text" value={filterText} onChange={(e) => setFilterText(e.target.value)} placeholder="Filter projects by name" style={{ width: "200px" }} />
                  </span>
                )}

                <Button onClick={handleCloseModal} disabled={usable}>
                  Close
                </Button>
                {modal_type == "save" || modal_type == "saveas" ? (
                  <Button onClick={() => handleSaveProject(modal_type == "saveas")} loading={usable} type="primary">
                    Save
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      handleLoadProject(carport.projectManager.selectedProjectId);
                    }}
                    loading={carport.projectManager.loading}
                    type="primary"
                  >
                    Load
                  </Button>
                )}
              </div>
            }
          >
            <div>
              <Spin tip="Loading..." spinning={carport.projectManager.loading}>
                {modal_type == "save" || modal_type == "saveas" ? (
                  <Input
                    id="project_name"
                    disabled={usable}
                    value={temp_projectName}
                    ref={(input) => input && input.focus()}
                    prefix={<BookOutlined type="project" style={{ color: "rgba(0,0,0,.25)" }} />}
                    placeholder="Enter project name."
                    onChange={(e) => set_temp_projectName(e.target.value)}
                    onPressEnter={() => handleSaveProject(modal_type == "saveas")}
                  />
                ) : (
                  <div style={{ height: "445px" }}>
                    <Table
                      loading={carport.projectManager.loading}
                      dataSource={filteredProjects}
                      useFixedHeader={false}
                      scroll={{ x: "max-content", y: 385 }}
                      className="project-table"
                      components={components}
                      rowClassName={(record, index) => (record.id === selectedProject ? "editable-row-selected" : "editable-row")}
                      rowKey="id"
                      size="small"
                      pagination={{ pageSize: 20 }}
                      onChange={handleSortChange}
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: (event) => {
                            handleProjectSelected(record);
                          }, // click row
                          onDoubleClick: (event) => {
                            handleLoadProject(record.id);
                          }, // double click row
                          // onContextMenu: (event) => {}, // right button click row
                          // onMouseEnter: (event) => {}, // mouse enter row
                          // onMouseLeave: (event) => {}, // mouse leave row
                        };
                      }}
                      columns={[
                        {
                          title: "Name",
                          dataIndex: "name",
                          sorter: (a, b) => a.name > b.name,
                          sortOrder: project_sortedInfo.field === "name" && project_sortedInfo.order,
                          render: (text, record) => <span>{record.name}</span>,
                        },
                        {
                          title: "Modified Date",
                          dataIndex: "edit_dt",
                          align: "center",
                          width: "200px",
                          sorter: (a, b) => a.edit_dt - b.edit_dt,
                          sortOrder: project_sortedInfo.field === "edit_dt" && project_sortedInfo.order,
                          render: (text, record) => <span>{getTime(record.edit_dt)}</span>,
                        },
                        {
                          title: "Actions",
                          align: "center",
                          width: "250px",
                          render: (text, record, index) => (
                            <div>
                              <span>
                                <a onClick={() => handleLoadProject(record.id)}>Load</a>
                              </span>
                              <span> - </span>
                              <Button
                                type="link"
                                size="small"
                                style={{ marginRight: "5px" }}
                                onClick={(e) => {
                                  showShareModal(record);
                                }}
                              >
                                Share
                              </Button>
                              <span> - </span>
                              <Popconfirm title="Are you sure?" onConfirm={() => handleDeleteProject(record.id)}>
                                <Button type="danger" size="small">
                                  Delete
                                </Button>
                              </Popconfirm>
                            </div>
                          ),
                        },
                      ]}
                    />
                  </div>
                )}
              </Spin>
            </div>
          </Modal>
        )}

        {mode == "ground" && (
          <Modal
            className="sift-modal"
            closable={false}
            width={portal.uiControls.modalMode == "save" || portal.uiControls.modalMode == "saveas" ? "20%" : "65%"}
            visible={portal.uiControls.showProjectLoader}
            onCancel={handleCloseModal}
            footer={
              <div>
                {portal.uiControls.modalMode == "load" && (
                  <span style={{ position: "absolute", left: "15px" }}>
                    <Input type="text" value={filterText} onChange={(e) => setFilterText(e.target.value)} placeholder="Filter projects by name" style={{ width: "200px" }} />
                  </span>
                )}

                <Button onClick={handleCloseModal} disabled={portal.uiControls.project_loading || portal.uiControls.loading}>
                  Close
                </Button>
                {portal.uiControls.modalMode == "save" || portal.uiControls.modalMode == "saveas" ? (
                  <Button onClick={handleSaveProject} loading={portal.uiControls.project_loading || portal.uiControls.loading} type="primary">
                    Save
                  </Button>
                ) : (
                  <Button onClick={handleLoadProject} loading={portal.uiControls.project_loading || portal.uiControls.loading} type="primary">
                    Load
                  </Button>
                )}
              </div>
            }
          >
            <div>
              {portal.uiControls.modalMode == "save" || portal.uiControls.modalMode == "saveas" ? (
                <div style={{ padding: "2%" }}>
                  <Input
                    id="project_name"
                    disabled={portal.uiControls.project_loading || portal.uiControls.loading}
                    className="login-input"
                    ref={(input) => input && input.focus()}
                    prefix={<ProjectOutlined type="project" style={{ color: "rgba(0,0,0,.25)" }} />}
                    placeholder="Enter project name."
                    onChange={(e) => handleProjectNameUpdate(e.target.value)}
                    onPressEnter={handleSaveProject}
                  />
                </div>
              ) : (
                <div style={{ height: "445px" }}>
                  <Table
                    // style={{ height: '400px' }}
                    loading={portal.uiControls.project_loading || portal.uiControls.loading}
                    dataSource={filteredProjects}
                    size="small"
                    rowClassName={(record, index) => (record.id === selectedProject ? "editable-row-selected" : "editable-row")}
                    pagination={{ pageSize: 20 }}
                    rowKey="id"
                    useFixedHeader={false}
                    bordered
                    scroll={{ x: "max-content", y: 385 }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          handleProjectSelect(event, record, rowIndex);
                        }, // click row
                        onDoubleClick: (event) => {
                          handleLoadProjectRow(event, record, rowIndex);
                        }, // double click row
                        // onContextMenu: (event) => {}, // right button click row
                        // onMouseEnter: (event) => {}, // mouse enter row
                        // onMouseLeave: (event) => {}, // mouse leave row
                      };
                    }}
                    onChange={handleSortChange}
                    columns={[
                      {
                        title: "Project Name",
                        dataIndex: "name",
                        sorter: (a, b) => a.name > b.name,
                        sortOrder: project_sortedInfo.columnKey === "name" && project_sortedInfo.order,
                      },
                      {
                        title: "Modified Date",
                        align: "center",
                        width: "200px",
                        dataIndex: "edit_dt",
                        sorter: (a, b) => a.edit_dt - b.edit_dt,
                        sortOrder: project_sortedInfo.columnKey === "edit_dt" && project_sortedInfo.order,
                        render: (text, record) => <span>{getTime(record.edit_dt)}</span>,
                      },
                      {
                        title: "Actions",
                        align: "center",
                        width: "250px",
                        render: (text, record, index) => (
                          <div>
                            <Button
                              size="small"
                              type="link"
                              style={{ marginRight: "5px" }}
                              onClick={(e) => {
                                handleLoadProjectRow(text, record, index);
                              }}
                            >
                              Load
                            </Button>
                            <span> - </span>
                            <Button
                              type="link"
                              size="small"
                              style={{ marginRight: "5px" }}
                              onClick={(e) => {
                                showShareModal(record);
                              }}
                            >
                              Share
                            </Button>
                            <span> - </span>
                            <Popconfirm
                              title="Delete Project?"
                              onConfirm={() => {
                                handleDeleteProject(record.id);
                              }}
                            >
                              <Button type="danger" size="small">
                                Delete
                              </Button>
                            </Popconfirm>
                          </div>
                        ),
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </Modal>
        )}
      </div>
      <div className="project-title">
        {mode == "canopy" && carport.projectManager.currentProjectId && <p>{carport.projectManager.localProjects[carport.projectManager.currentProjectId].name}</p>}
        {/* {mode == "ground" && portal.current_project.name && <p>{portal.current_project.name}</p>} */}
        {mode == "ground" && inputs.project_name && <p>{inputs.project_name}</p>}
      </div>
    </ProjectManagerWrap>
  );
};

export { ProjectManager };
