import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { carportActions } from "../../Redux";

import { Input, Radio, Tooltip, Typography } from "antd";
import { isNumber } from "../../Redux";

import { singleSlope, inverted, dual_tilt } from "../../../assets";

function PlaneTypeInput() {
  const dispatch = useDispatch();

  const planeType = useSelector((state) => state.carport.selectedInputs.planeType);
  const modY = useSelector((state) => state.carport.selectedInputs.modY);
  const [planeType_error, set_planeType_error] = useState(undefined);

  function onChangeInput(e) {
    let changed_value;
    let changed_key;
    if (e.target.name) {
      changed_value = e.target.value;
      changed_key = e.target.name;
    } else {
      changed_value = parseFloat(e.target.value);
      changed_key = e.target.id;
    }

    // if (!isNumber(changed_value)) {
    // 	set_planeType_error('Invalid Input')
    // 	return
    // } else if (changed_value < 0 || changed_value > 359) {
    // 	set_planeType_error('Invalid Input')
    // 	return
    // } else if (planeType_error != undefined) {
    // 	set_planeType_error(undefined);
    // }

    dispatch(carportActions.updateInputByName(`planeType.${changed_key}`, changed_value));
  }

  return (
    <>
      <Radio.Group style={{ display: "flex", justifyContent: "space-around" }} value={planeType.type} onChange={onChangeInput} name="type">
        <Radio
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "center",
            alignItems: "center",
          }}
          value={"single_slope"}
        >
          <Tooltip title="Single Slope">
            <img src={singleSlope} alt="Single Slope" />
          </Tooltip>
        </Radio>
        <Radio
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "center",
            alignItems: "center",
          }}
          value={"inverted"}
        >
          <Tooltip title="Inverted">
            <img src={inverted} alt="inverted" />
          </Tooltip>
        </Radio>
        <Radio
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "center",
            alignItems: "center",
          }}
          value={"dual_tilt"}
        >
          <Tooltip title="Dual Tilt">
            <img src={dual_tilt} alt="dual_tilt" />
          </Tooltip>
        </Radio>
      </Radio.Group>
      {planeType.type != "inverted" && (
        <div className="canopy-input">
          <label>Tilt:</label>
          <Input
            id="tilt"
            size="small"
            value={planeType.tilt}
            type="number"
            min="0"
            max="15"
            suffix={<span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>°</span>}
            onChange={onChangeInput}
          />
        </div>
      )}
      {(planeType.type == "single_slope" || planeType.type == "dual_tilt") && planeType.tilt > 7 && (
        <div className="canopy-warning-text">
          <Typography.Text type="danger">Tilt has exceeded 7°</Typography.Text>
        </div>
      )}
      {planeType.type == "dual_tilt" && (
        <div className="canopy-warning-text">
          <Typography.Text>{`Towards Azimuth (Black)`}</Typography.Text>
          <br />
          <Typography.Text>{`Away From Azimuth (White)`}</Typography.Text>
        </div>
      )}
      {planeType.type == "inverted" && (
        <Fragment>
          <div className="canopy-input">
            <label>Toward Azimuth:</label>
            <Input
              id="towardAzimuth"
              size="small"
              value={planeType.towardAzimuth}
              type="number"
              min="0"
              max={modY}
              suffix={<span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>mod</span>}
              onChange={onChangeInput}
            />
          </div>

          <div className="canopy-warning-text">
            <label>{`Away from Azimuth(White): ${modY - planeType.towardAzimuth}`}</label>
          </div>
          <div className="canopy-input">
            <label>Tilt to Azimuth:</label>
            <Input
              id="tiltToAzimuth"
              size="small"
              value={planeType.tiltToAzimuth}
              type="number"
              min="0"
              max="25"
              suffix={<span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>°</span>}
              onChange={onChangeInput}
            />
          </div>
          <div className="canopy-input">
            <label>Tilt from Azimuth:</label>
            <Input
              id="tiltFromAzimuth"
              size="small"
              value={planeType.tiltFromAzimuth}
              type="number"
              min="0"
              max="25"
              suffix={<span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>°</span>}
              onChange={onChangeInput}
            />
          </div>
          {planeType.tiltToAzimuth > 7 && (
            <div className="canopy-warning-text">
              <Typography.Text type="danger">Tilt to Azimuth has exceeded 7°</Typography.Text>
            </div>
          )}
          {planeType.tiltFromAzimuth > 7 && (
            <div className="canopy-warning-text">
              <Typography.Text type="danger">Tilt from Azimuth has exceeded 7°</Typography.Text>
            </div>
          )}
        </Fragment>
      )}
    </>
  );
}

export { PlaneTypeInput };
