import React from "react";

import { useSelector } from "react-redux";

import { ZoomAndLayerControlLayout } from "./styles";

import LeafletControl from "../../Sunfig/MapControl";
import { getBounds } from "../../../utils/map_helper";

import { add_song, minus, expand } from "../../../assets/";
import { Tooltip, Button } from "antd";

const zoomGranularity = 0.25;

const ZoomControl = ({ mapRef, position = undefined }) => {
  const features = useSelector((state) => state.inputs.site_features);

  const zoomExtents = (zoomLevel = undefined) => {
    if (position) {
      mapRef.flyTo(position, 14);
    } else {
      let allFeatures = [];
      if (Object.values(features).length == 0) return;

      Object.values(features).map((feature) => allFeatures.push(feature));

      let bounds = getBounds(allFeatures);

      if (zoomLevel) {
        mapRef.fitBounds(
          [
            [bounds[1], bounds[0]],
            [bounds[3], bounds[2]],
          ],
          { maxZoom: zoomLevel }
        );
      } else {
        mapRef.fitBounds([
          [bounds[1], bounds[0]],
          [bounds[3], bounds[2]],
        ]);
      }
    }
  };

  const zoomIn = () => {
    mapRef.zoomIn(zoomGranularity);
  };
  const zoomOut = () => {
    mapRef.zoomOut(zoomGranularity);
  };

  return (
    <LeafletControl position="topright">
      <ZoomAndLayerControlLayout>
        <Tooltip title="Zoom In" placement="left">
          <Button className="zoom-control-layer-button" shape="circle" onClick={() => zoomIn()}>
            <img src={add_song} />
          </Button>
        </Tooltip>
        <Tooltip title="Zoom Out" placement="left">
          <Button className="zoom-control-layer-button" shape="circle" size="large" onClick={() => zoomOut()}>
            <img src={minus} />
          </Button>
        </Tooltip>
        <Tooltip title="Zoom Extents" placement="left">
          <Button className="zoom-control-layer-button" shape="circle" size="large" onClick={() => zoomExtents()}>
            <img style={{ marginBottom: "2px" }} src={expand} />
          </Button>
        </Tooltip>
      </ZoomAndLayerControlLayout>
    </LeafletControl>
  );
};

export { ZoomControl };
