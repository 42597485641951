import { combineReducers } from "redux";

// Reducers
import { authentication } from "./authentication.reducer";
import { portal } from "./portal.reducer";
import { carport } from "./carport.reducer";
// import { projectManager } from './projectManager.reducer';
import { inputs } from "./inputs.reducer";
// import { alert } from './alert.reducer';

import * as fromAuth from "./authentication.reducer.js";
import * as fromGM from "./portal.reducer.js";
import * as fromCanopy from "./carport.reducer.js";

export default combineReducers({
  portal,
  authentication,
  carport,
  // projectManager,
  inputs,
});

export const isAuthenticated = (state) => fromAuth.isAuthenticated(state.authentication);

export const collectGMInputs = (state) => fromGM.collectInputs(state);
export const getGroundMountInputs = (state) => fromGM.getInputs(state);
export const getCanopyInputs = (state) => fromCanopy.getInputs(state);

export const getCanopyProps = (state) => fromCanopy.getCanopyProps(state);
export const getCellsAndBlocks = (state) => fromCanopy.getCellsAndBlocks(state);
export const getVisualGeoJsonLayer = (state) => fromCanopy.getVisualGeoJsonLayer(state);
