import React, { useState, useEffect } from "react";
import styled from "styled-components";
// component
import { trashCan, pencil, exportIcon, expand, add_song, minus, layer, rectWithOffsetVerts, duplicate, upArrow, downArrow, leftArrow, rightArrow, ruler, textBubble } from "../../assets";

import { SearchTool } from "../Sunfig";

// antd
import { Tooltip, Button, Row, Popconfirm, Menu, Dropdown, message, Col } from "antd";

// redux
import { useSelector, useDispatch } from "react-redux";
import { portalActions, carportActions } from "../Redux/actions/";
import center from "@turf/center";
import { Switch } from "react-router";

const ZoomAndLayerControlWrapper = styled.section`
  display: flex;
  flex-direction: column;

  .zoom-control-layer-button {
    margin: 4px 0;
    height: 40px;
    width: 40px;

    img {
      width: 20px;
      height: 20px;
    }
  }
`;

const GroundMountToolbar = ({ handleDraw, handleDrawTree, cancelDraw, stopEditing, handleExportKML, printMap, setSearchToolActive, searchToolActive }) => {
  const portal = useSelector((state) => state.portal);

  const dispatch = useDispatch();

  const exportMenu = () => (
    <Menu
      onClick={(e) => {
        if (e.key == "exportKMZ" && Object.values(portal.inputs.features).length > 0) {
          cancelDraw();
          handleExportKML();
        } else if (e.key == "exportImage") {
          printMap();
        }
      }}
    >
      <Menu.Item key="exportKMZ" eventKey="kmz" disabled={Object.values(portal.inputs.features).length == 0}>
        Export KMZ
      </Menu.Item>
      <Menu.Item key="exportImage" eventKey="image">
        Export Image
      </Menu.Item>
    </Menu>
  );

  return (
    <Row
      onDragStart={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <SearchTool mode={"ground"} />
      <Tooltip title="Draw (D)">
        <Button
          shape="circle"
          size="large"
          style={{ margin: "0 4px", backgroundColor: portal.uiControls.isDrawing ? "#60de4f" : "#fff" }}
          onClick={() => {
            if (portal.uiControls.searchToolActive) {
              dispatch(portalActions.updateUIControls("searchToolActive", false));
            }
            handleDraw();
          }}
        >
          <img style={{ marginBottom: "2px" }} src={pencil} />
        </Button>
      </Tooltip>
      {/* <Tooltip title="Draw Tree">
        <Button
          shape="circle"
          size="large"
          style={{ margin: '0 4px' }}
          onClick={() => {
            if (portal.uiControls.searchToolActive) {
              dispatch(portalActions.updateUIControls('searchToolActive', false));
            }
            handleDrawTree();
          }}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
              <path
                d="M21.1463 8.81878C21.1463 6.30731 19.2557 4.22906 16.823 3.93225C16.6193 2.93475 16.11 2.02312 15.3537 1.31902C14.44 0.468469 13.2489 0 12 0C10.7511 0 9.56003 0.468469 8.64634 1.31906C7.89006 2.02317 7.38071 2.9348 7.17699 3.9323C4.74432 4.22906 2.85376 6.30731 2.85376 8.81878C2.85376 9.97744 3.26387 11.0931 3.99737 11.9727C3.26387 12.8522 2.85376 13.9679 2.85376 15.1266C2.85376 17.841 5.06213 20.0494 7.77657 20.0494C8.46273 20.0494 9.24132 19.8812 9.93479 19.6037L9.68148 24H14.3185L14.0695 19.6723C14.794 19.9293 15.5872 20.0494 16.2235 20.0494C18.9379 20.0494 21.1463 17.841 21.1463 15.1266C21.1463 13.9679 20.7362 12.8522 20.0027 11.9727C20.7362 11.0931 21.1463 9.97744 21.1463 8.81878V8.81878ZM11.169 22.5957L11.5469 16.0371C11.6956 16.0549 11.8466 16.0652 12 16.0652C12.1536 16.0652 12.3048 16.0549 12.4537 16.037L12.8311 22.5956H11.169V22.5957ZM18.5612 12.4973C19.3116 13.165 19.742 14.1233 19.742 15.1266C19.742 17.0667 18.1636 18.645 16.2235 18.645C15.4355 18.645 14.5493 18.4229 13.9789 18.0976L13.8345 15.5881C14.9915 14.9421 15.776 13.7059 15.776 12.2893H14.3717C14.3717 13.597 13.3077 14.661 12 14.661C10.6922 14.661 9.62828 13.597 9.62828 12.2893H8.22395C8.22395 13.7061 9.00873 14.9425 10.166 15.5884L10.0273 17.9963C9.41481 18.3703 8.4841 18.645 7.77648 18.645C5.83637 18.645 4.25799 17.0667 4.25799 15.1266C4.25799 14.1233 4.6884 13.165 5.43882 12.4973L6.02832 11.9727L5.43887 11.4481C4.6884 10.7803 4.25799 9.82195 4.25799 8.81878C4.25799 6.87947 5.83492 5.30166 7.77385 5.3003L8.44238 5.30048L8.49174 4.65155C8.63017 2.83064 10.1711 1.40433 12 1.40433C13.8288 1.40433 15.3698 2.83069 15.5082 4.65155L15.5575 5.30048H16.2084L16.2253 5.3003C18.1646 5.30128 19.7419 6.87928 19.7419 8.81878C19.7419 9.82195 19.3115 10.7803 18.5611 11.4481L17.9716 11.9727L18.5612 12.4973Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Button>
      </Tooltip> */}
      <Tooltip title="Delete">
        <Popconfirm
          title="Confirm Delete All?"
          placement="bottom"
          onConfirm={(e) => {
            // JUST DELETE THIS ARRAY
            cancelDraw();
            // stopEditing();
            dispatch(portalActions.deleteFeature(undefined));
          }}
          onCancel={() => console.log("canceled")}
          okText="Yes"
          cancelText="No"
        >
          <Button shape="circle" size="large" style={{ margin: "0 4px" }}>
            <img style={{ marginBottom: "2px", filter: "brightness(0.1) sepia(1) hue-rotate(180deg) saturate(2)" }} src={trashCan} />
          </Button>
        </Popconfirm>
      </Tooltip>
      <Tooltip title="Export" placement="right">
        <Dropdown overlay={exportMenu} trigger={["click"]}>
          <Button className="zoom-control-layer-button" shape="circle" size="large" style={{ margin: "0 4px" }}>
            <img style={{ marginBottom: "2px" }} src={exportIcon} />
          </Button>
        </Dropdown>
      </Tooltip>
    </Row>
  );
};

{
  /* zoom, zoom extends, and layers toolbar */
}
const ZoomAndLayerControl = ({
  tileSets,
  activeTileSet,
  setActivetileSet,
  handleZoomExtents,
  handleCanopyZoomExtents,
  mode,
  setShowCanopyNameLabels,
  showCanopyNameLabels,
  handleLiveMapZoomExtents,
  liveMapZoom,
  setLiveMapZoom,
  layoutMapZoom,
  setLayoutMapZoom,
  handleLayoutMapZoomExtents,
  handleLocationZoomExtents,
  locationZoom,
  setLocationZoom,
  liveCanopyReport,
  handleThirtyPercentVicinityMapExtents,
  handleThirtyPercentZoom,
  thirtyPercentVicinityZoom,
  thirtyPercentZoom,
  handleThirtyPercentMapZoom,
  handleThirtyPercentZoomExtents,
}) => {
  const portal = useSelector((state) => state.portal);
  const carport = useSelector((state) => state.carport);
  const dispatch = useDispatch();

  const tileMenu = () => (
    <Menu
      onClick={(e) => {
        setActivetileSet(e.item.props.children[1]);
      }}
    >
      {Object.keys(tileSets).map((key, index) => (
        <Menu.Item key={index} eventKey={key} disabled={key == activeTileSet}>
          {key}
        </Menu.Item>
      ))}
    </Menu>
  );

  const zoomExtents = (mode) => {
    switch (mode) {
      case "Ground":
        handleZoomExtents();
        break;
      case "canopy":
        handleCanopyZoomExtents();
        break;
      case "liveReport":
        handleLiveMapZoomExtents();
        break;
      case "locationMap":
        handleLocationZoomExtents();
        break;
      case "thirtyPercentVicinity":
        handleThirtyPercentVicinityMapExtents();
        break;
      case "thirtyPercentMap":
        handleThirtyPercentZoomExtents();
        break;
      default:
        handleLayoutMapZoomExtents();
    }
  };

  const zoomIn = (mode) => {
    switch (mode) {
      case "Ground":
        dispatch(portalActions.updateMapZoom(portal.inputs.map_zoom + portal.inputs.zoomGranularity));
        break;
      case "canopy":
        dispatch(carportActions.setZoom(carport.zoom + carport.zoomGranularity));
        break;
      case "liveReport":
        setLiveMapZoom(liveMapZoom + 0.25);
        break;
      case "locationMap":
        setLocationZoom(locationZoom + 0.25);
        break;
      case "thirtyPercentVicinity":
        handleThirtyPercentZoom(thirtyPercentVicinityZoom + 0.25);
        break;
      case "thirtyPercentMap":
        handleThirtyPercentMapZoom(thirtyPercentZoom + 0.25);
        break;
      default:
        setLayoutMapZoom(layoutMapZoom + 0.25);
    }
  };
  const zoomOut = (mode) => {
    switch (mode) {
      case "Ground":
        dispatch(portalActions.updateMapZoom(portal.inputs.map_zoom - portal.inputs.zoomGranularity));
        break;
      case "canopy":
        dispatch(carportActions.setZoom(carport.zoom - carport.zoomGranularity));
        break;
      case "liveReport":
        setLiveMapZoom(liveMapZoom - 0.25);
        break;
      case "locationMap":
        setLocationZoom(locationZoom - 0.25);
        break;
      case "thirtyPercentVicinity":
        handleThirtyPercentZoom(thirtyPercentVicinityZoom - 0.25);
        break;
      case "thirtyPercentMap":
        handleThirtyPercentMapZoom(thirtyPercentZoom - 0.25);
        break;
      default:
        setLayoutMapZoom(layoutMapZoom - 0.25);
    }
  };

  return (
    <ZoomAndLayerControlWrapper
      onDragStart={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Tooltip title="Zoom In" placement="left">
        <Button className="zoom-control-layer-button" shape="circle" onClick={() => zoomIn(mode)}>
          <img src={add_song} />
        </Button>
      </Tooltip>
      <Tooltip title="Zoom Out" placement="left">
        <Button className="zoom-control-layer-button" shape="circle" size="large" onClick={() => zoomOut(mode)}>
          <img src={minus} />
        </Button>
      </Tooltip>
      <Tooltip title="Zoom Extents" placement="left">
        <Button className="zoom-control-layer-button" shape="circle" size="large" onClick={() => zoomExtents(mode)}>
          <img style={{ marginBottom: "2px" }} src={expand} />
        </Button>
      </Tooltip>
      {mode == "canopy" && !liveCanopyReport && (
        <Tooltip title={showCanopyNameLabels == true ? "Hide Names" : "Show Names"} placement="left">
          <Button
            disabled={Object.values(carport.rectangles).length == 0 || carport.uiState.view_results_table === true}
            className="zoom-control-layer-button"
            style={{ backgroundColor: showCanopyNameLabels === true ? "#60de4f" : null }}
            shape="circle"
            size="large"
            onClick={() => setShowCanopyNameLabels(!showCanopyNameLabels)}
          >
            <img src={textBubble} />
          </Button>
        </Tooltip>
      )}
      <Tooltip title="Layer Select" placement="left">
        <Dropdown overlay={tileMenu} placement="bottomRight" trigger={["click"]}>
          <Button className="zoom-control-layer-button" shape="circle" size="large">
            <img src={layer} />
          </Button>
        </Dropdown>
      </Tooltip>
    </ZoomAndLayerControlWrapper>
  );
};

const CanopyToolbar = ({ duplicateRectangle, stopEditing, toggleAlignmentTool, alignmentToolActive, toggleNPEditor, nonPolygonalEditOn }) => {
  const carport = useSelector((state) => state.carport);
  const dispatch = useDispatch();

  const duplicateDropdown = () => (
    <Menu style={{ background: "none" }}>
      <Menu.Item style={{ margin: "2px", padding: "0" }}>
        <Tooltip placement="left" title="Duplicate Up">
          <Button shape="circle" size="large" onClick={() => duplicateRectangle(carport.selectedRectId, "up")}>
            <img src={upArrow} alt="" className="toolbar-control" />
          </Button>
        </Tooltip>
      </Menu.Item>
      <Menu.Item style={{ margin: "2px", padding: "0" }}>
        <Tooltip placement="left" title="Duplicate Down">
          <Button shape="circle" size="large" onClick={() => duplicateRectangle(carport.selectedRectId, "down")}>
            <img src={downArrow} alt="" className="toolbar-control" />
          </Button>
        </Tooltip>
      </Menu.Item>
      <Menu.Item style={{ margin: "2px", padding: "0" }}>
        <Tooltip placement="left" title="Duplicate Left">
          <Button shape="circle" size="large" onClick={() => duplicateRectangle(carport.selectedRectId, "left")}>
            <img src={leftArrow} alt="" className="toolbar-control" />
          </Button>
        </Tooltip>
      </Menu.Item>
      <Menu.Item style={{ margin: "2px", padding: "0" }}>
        <Tooltip placement="left" title="Duplicate Right">
          <Button shape="circle" size="large" onClick={() => duplicateRectangle(carport.selectedRectId, "right")}>
            <img src={rightArrow} alt="" className="toolbar-control" />
          </Button>
        </Tooltip>
      </Menu.Item>
    </Menu>
  );

  return (
    <Row
      onDragStart={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <SearchTool mode={"canopy"} />
      <Tooltip title="Place Canopy">
        <Button
          shape="circle"
          size="large"
          style={{ margin: "0 4px", background: carport.activeTool == "draw" ? "#60de4f" : null }}
          onClick={() => {
            if (carport.activeTool == "draw") {
              dispatch(carportActions.setActiveTool(undefined));
            } else {
              dispatch(carportActions.setActiveTool("draw"));
              // message.info('Click Map to Place Canopy');
            }
          }}
        >
          <img style={{ marginBottom: "2px" }} src={rectWithOffsetVerts} />
        </Button>
      </Tooltip>
      <Tooltip>
        <Dropdown disabled={Object.values(carport.rectangles).length > 0 && carport.selectedRectId ? false : true} overlay={() => duplicateDropdown(duplicateRectangle)} placement="topCenter">
          <Button shape="circle" size="large" style={{ margin: "0 4px" }} onClick={() => duplicateRectangle(carport.selectedRectId)}>
            <img src={duplicate} alt="" />
          </Button>
        </Dropdown>
      </Tooltip>
      <Tooltip title="Alignment">
        <Button
          shape="circle"
          size="large"
          disabled={Object.values(carport.rectangles).length < 2}
          style={{ margin: "0 4px", backgroundColor: alignmentToolActive === true ? "#60de4f" : null }}
          onClick={toggleAlignmentTool}
        >
          <img style={{ marginBottom: "2px" }} src={ruler} />
        </Button>
      </Tooltip>
      <Tooltip title="Non-Polygonal Editor">
        <Button
          shape="circle"
          size="large"
          disabled={carport.selectedRectId && Object.values(carport.rectangles).length == 0}
          style={{ margin: "0 4px", backgroundColor: nonPolygonalEditOn === true ? "#60de4f" : null }}
          onClick={toggleNPEditor}
        >
          <img style={{ marginBottom: "2px" }} src={pencil} />
        </Button>
      </Tooltip>
      <Tooltip title="Delete All" placement="right">
        <Popconfirm
          title="Confirm Delete All?"
          placement="bottom"
          onConfirm={(e) => {
            dispatch(carportActions.deleteAllRectangles());
          }}
          onCancel={() => console.log("canceled")}
          okText="Yes"
          cancelText="No"
        >
          <Button shape="circle" size="large" style={{ margin: "0 4px" }} onClick={(e) => dispatch(carportActions.selectRectangle(undefined))}>
            <img style={{ marginBottom: "2px", filter: "brightness(0.1) sepia(1) hue-rotate(180deg) saturate(2)" }} src={trashCan} />
          </Button>
        </Popconfirm>
      </Tooltip>
    </Row>
  );
};

export { GroundMountToolbar, CanopyToolbar, ZoomAndLayerControl };
