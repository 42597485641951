export const POSITION_CLASSES = {
	bottomleft: 'leaflet-bottom leaflet-left',
	bottomright: 'leaflet-bottom leaflet-right',
	topleft: 'leaflet-top leaflet-left',
	topright: 'leaflet-top leaflet-right',
}

export const zoomGranularity = 0.25;

export const TileSets = {
  Satellite: 'Aerial',
  'Satellite With Labels': 'AerialWithLabels',
  'Satellite With OnDemand Labels': 'AerialWithLabelsOnDemand',
  'Street Map': 'Road',
  // "Street Map Dark": "CanvasDark",
  // "CanvasLight": "CanvasLight",
  // "CanvasGray": "CanvasGray"
};
export const GoogleMapTypes = {
	terrain: 'terrain',
	road: 'roadmap',
	satellite: 'satellite',
	hydrid: 'hybrid'
}


export const create_UUID = () => {
	var dt = new Date().getTime();
	var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = (dt + Math.random() * 16) % 16 | 0;
		dt = Math.floor(dt / 16);
		return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
	return uuid;
};