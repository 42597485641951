import styled from "styled-components";

export const MapWrapper = styled.section`
  grid-column: 2;
  position: relative;
  width: 100%;
  height: 100vh;

  .generating-box {
    width: 100%;
    height: 100px;
    position: absolute;
    display: grid;
    place-items: center;
    bottom: 0;
    z-index: 600;

    .generating-box-contents,
    .initializing-box {
      color: #60de4f;
      height: 80px;
      width: 250px;
      background-color: #fff;
      border-radius: 2px;
      border: 1px solid #60de4f;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      p {
        font-size: 16px;
        margin: 0;
      }
    }
  }

  .results {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1000;
    display: grid;
    place-items: center;

    .results-box {
      width: 100%;
      height: ${(props) => (props.showCanopyReport || props.showThiryPercentSet ? "100vh" : "375px")};
      background-color: #fff;
      grid-template-rows: auto 1fr;
      border: 1px solid #60de4f;
      transition: height 0.5s cubic-bezier(0.23, 1, 0.32, 1.05);
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
      overflow-y: hidden;

      .results-close-button {
        z-index: 1;
        right: 0px;
        position: absolute;
        display: flex;
        justify-content: flex-end;
      }
    }

    .results-table {
      grid-row: 2;
    }
  }
`;
