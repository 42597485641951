export const portalConstants = {
  //
  GET_USER_DATA_REQUEST: "GET_USER_DATA_REQUEST",
  GET_USER_DATA_SUCCESS: "GET_USER_DATA_SUCCESS",
  GET_USER_DATA_FAILURE: "GET_USER_DATA_FAILURE",

  INPUT_UPDATE: "INPUT_UPDATE",
  INPUT_UPDATE_WIRING: "INPUT_UPDATE_WIRING",
  INPUT_UPDATE_PERFORMANCE: "INPUT_UPDATE_PERFORMANCE",
  COUNTY_UPDATE: "COUNTY_UPDATE",
  UPDATE_UI_CONTROLS: " UPDATE_UI_CONTROLS",

  GET_TOPO_DATA_REQUEST: "GET_TOPO_DATA_REQUEST",
  GET_TOPO_DATA_SUCCESS: "GET_TOPO_DATA_SUCCESS",
  GET_TOPO_DATA_FAILURE: "GET_TOPO_DATA_FAILURE",
  UPDATE_TOPO_MODE: "UPDATE_TOPO_MODE",

  UPDATE_MAP_CENTER: "UPDATE_MAP_CENTER",
  UPDATE_MAP_ZOOM: "UPDATE_MAP_ZOOM",

  INIT_RESULTS_REQUEST: "INIT_RESULTS_REQUEST",
  INIT_RESULTS_SUCCESS: "INIT_RESULTS_SUCCESS",
  INIT_RESULTS_ERROR: "INIT_RESULTS_ERROR",

  GENERATE_RESULTS_REQUEST: "GENERATE_RESULTS_REQUEST",
  GENERATE_RESULTS_SUCCESS: "GENERATE_RESULTS_SUCCESS",
  GENERATE_RESULTS_UPDATE: "GENERATE_RESULTS_UPDATE",
  GENERATE_RESULTS_FAILURE: "GENERATE_RESULTS_FAILURE",

  CANCEL_RUN_REQUEST: "CANCEL_RUN_REQUEST",
  CANCEL_RUN_SUCCESS: "CANCEL_RUN_SUCCESS",

  GET_RACK_DATA_REQUEST: "GET_RACK_DATA_REQUEST",
  GET_RACK_DATA_SUCCESS: "GET_RACK_DATA_SUCCESS",
  GET_RACK_DATA_FAILURE: "GET_RACK_DATA_FAILURE",

  RESULT_SELECT: "RESULT_SELECT",

  GET_LAYOUT_REQUEST: "GET_LAYOUT_REQUEST",
  GET_LAYOUT_SUCCESS: "GET_LAYOUT_SUCCESS",
  GET_LAYOUT_FAILURE: "GET_LAYOUT_FAILURE",

  // Project manager
  SHOW_PROJECT_LOAD_MENU: "SHOW_PROJECT_LOAD_MENU",
  SHOW_PROJECT_SAVE_MENU: "SHOW_PROJECT_SAVE_MENU",

  TOGGLE_MAP_UI: "TOGGLE_MAP_UI",
  TOGGLE_OPEN_CLOSE: "TOGGLE_OPEN_CLOSE",

  PROJECT_SELECT: "PROJECT_SELECT",

  RESET_PROJECT_REQUEST: "RESET_PROJECT_REQUEST",
  RESET_PROJECT_SUCCESS: "RESET_PROJECT_SUCCESS",

  LOAD_PROJECT_ROW: "LOAD_PROJECT_ROW",
  LOAD_PROJECT_REQUEST: "LOAD_PROJECT_REQUEST",
  LOAD_PROJECT_SUCCESS: "LOAD_PROJECT_SUCCESS",

  PROJECT_NAME_UPDATE: "PROJECT_NAME_UPDATE",

  SAVE_PROJECT_REQUEST: "SAVE_PROJECT_REQUEST",
  SAVE_PROJECT_SUCCESS: "SAVE_PROJECT_SUCCESS",

  PROJECT_SAVE_AS_REQUEST: "PROJECT_SAVE_AS_REQUEST",

  DELETE_PROJECT_REQUEST: "DELETE_PROJECT_REQUEST",
  DELETE_PROJECT_SUCCESS: "DELETE_PROJECT_SUCCESS",

  FILTER_PROJECTS: "FILTER_PROJECTS",

  UPLOAD_KMZ_FEATURES: "UPLOAD_KMZ_FEATURES",

  SELECT_MAP_FEATURE: "SELECT_MAP_FEATURE",
  CREATE_POLYGON: "CREATE_POLYGON",
  UPDATE_POLYGON: "UPDATE_POLYGON",
  DELETE_FEATURE: "DELETE_FEATURE",
  SET_COUNTY: "SET_COUNTY",
  PREPARE_GROUND_REPORT: "PREPARE_GROUND_REPORT",
  UPDATE_REPORT_DATA: "UPDATE_REPORT_DATA",
  IMPORTDATA_REQUEST: "IMPORTDATA_REQUEST",
  IMPORTDATA_SUCCESS: "IMPORTDATA_SUCCESS",
  IMPORTDATA_FAILURE: "IMPORTDATA_FAILURE",

  TOGGLE_OFFSET_TOOLS: "TOGGLE_OFFSET_TOOLS",
  OFFSETPOLYGON_REQUEST: "OFFSETPOLYGON_REQUEST",
  OFFSETPOLYGON_SUCCESS: "OFFSETPOLYGON_SUCCESS",
  OFFSETPOLYGON_FAILURE: "OFFSETPOLYGON_FAILURE",
};
