import React from "react";
import { Page, Text, View, Image, Document, StyleSheet } from "@react-pdf/renderer";

import ground from "./ground.png";
import sunPower from "./sunPower.png";
import lightbulb from "./lightbulb.png";
import counting from "./counting.png";
import icon from "./icon.png";
import rbilogo from "./rbilogo.png";
import sunfig_header_logo from "./sunfig_header_logo.png";
import { performance } from "../../assets";

// Create styles
const styles = StyleSheet.create({
  infoContainer: {
    // flexGrow: 1,
    marginHorizontal: 20,
    height: 40,
    // flexDirection: 'row',
    // backgroundColor: '#e29e37',
  },
  infoBox: {
    flexGrow: 5,
    // backgroundColor: '#f2f2f2',
    height: 40,
  },
  infoImage: {
    width: 20,
    marginHorizontal: 10,
    marginVertical: 7.5,
    backgroundColor: "#f2f2f2",
  },
  infoHeader: {
    fontSize: 10,
    textAlign: "justify",
    marginVertical: 2.5,
    marginHorizontal: 2,
    height: 13,
  },
  infoText: {
    fontSize: 9,
    // textAlign: 'center',
    marginVertical: 2.5,
    marginHorizontal: 2,
    height: 11,
    // Segoe UI
  },

  row: {
    flexGrow: 1,
    flexDirection: "row",
    height: 40,
  },

  leftInfo: {
    // flexGrow: 1,
    width: 40,
    // backgroundColor: '#f22332',
    backgroundColor: "#f2f2f2",
    height: 40,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
    borderBottomLeftRadius: 25,
  },
  rightInfo: {
    // flexGrow: 1,
    // backgroundColor: '#f212f2',
    height: 40,
  },
});

// Create Document Component
const SummaryHeader = (props) => (
  <View style={styles.infoContainer}>
    <View style={styles.row}>
      <View style={styles.infoBox}>
        <View style={styles.row}>
          <View style={styles.leftInfo}>
            <Image style={styles.infoImage} src={lightbulb} />
          </View>
          <View style={styles.rightInfo}>
            <Text style={styles.infoHeader}>Module Rating:</Text>
            <Text style={styles.infoText}>{props.overview.rating}W</Text>
          </View>
        </View>
      </View>

      <View style={styles.infoBox}>
        <View style={styles.row}>
          <View style={styles.leftInfo}>
            <Image style={styles.infoImage} src={counting} />
          </View>
          <View style={styles.rightInfo}>
            <Text style={styles.infoHeader}>Module Qty:</Text>
            <Text style={styles.infoText}>{props.overview.mod_qty}</Text>
          </View>
        </View>
      </View>

      <View style={styles.infoBox}>
        <View style={styles.row}>
          <View style={styles.leftInfo}>
            <Image style={styles.infoImage} src={sunPower} />
          </View>
          <View style={styles.rightInfo}>
            <Text style={styles.infoHeader}>Site Capacity:</Text>
            <Text style={styles.infoText}>{(props.overview.capacity / 1000).toFixed(2)}kW</Text>
          </View>
        </View>
      </View>

      <View style={styles.infoBox}>
        <View style={styles.row}>
          <View style={styles.leftInfo}>
            <Image style={styles.infoImage} src={ground} />
          </View>
          <View style={styles.rightInfo}>
            <Text style={styles.infoHeader}>Canopy Count:</Text>
            <Text style={styles.infoText}>{props.overview.count}</Text>
          </View>
        </View>
      </View>
      <View style={styles.infoBox}>
        <View style={styles.row}>
          <View style={styles.leftInfo}>
            <Image style={styles.infoImage} src={icon} />
          </View>
          <View style={styles.rightInfo}>
            <Text style={styles.infoHeader}>Location:</Text>
            <Text style={styles.infoText}>
              {props.overview.center[0].toFixed(3)}°, {props.overview.center[1].toFixed(3)}°
            </Text>
          </View>
        </View>
      </View>
    </View>
  </View>
);

const GMHeader = StyleSheet.create({
  headerContainer: {
    left: 0,
    right: 0,
    top: 10,
    width: 450,
    marginLeft: "auto",
    marginRight: "auto",
  },
  bulletPoints: {
    flexDirection: "row",
    flexGrow: 1,
    paddingLeft: 5,
    paddingRight: 5,
  },
  bulletPoint: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginRight: 8,
  },
  bulletPointImageBackdrop: {
    width: 25,
    backgroundColor: "#E4E4E4",
    height: 25,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
    borderBottomLeftRadius: 25,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 5,
  },
  bulletPointImage: {
    height: 15,
    width: 15,
  },
  bulletPointTitleText: {
    fontSize: 9,
    color: "#333",
    marginBottom: 5,
  },
  bulletPointSubText: {
    fontSize: 9,
    color: "#333",
  },
});

// reg for pulling the raiting out of the
const ratingRegEx = /^\d{3}[a-zA-Z\d]/i;

const GroundMountHeader = ({ data }) => {
  return (
    <View style={GMHeader.headerContainer}>
      <View style={GMHeader.bulletPoints}>
        <View style={styles.infoBox}>
          <View style={styles.row}>
            <View style={styles.leftInfo}>
              <Image style={styles.infoImage} src={lightbulb} />
            </View>
            <View style={styles.rightInfo}>
              <Text style={styles.infoHeader}>Module Rating:</Text>
              <Text style={styles.infoText}>{`${data.results.module_name.match(ratingRegEx)}`}</Text>
            </View>
          </View>
        </View>

        <View style={styles.infoBox}>
          <View style={styles.row}>
            <View style={styles.leftInfo}>
              <Image style={styles.infoImage} src={counting} />
            </View>
            <View style={styles.rightInfo}>
              <Text style={styles.infoHeader}>Module Qty:</Text>
              <Text style={styles.infoText}>{`${data.results.module_count}`}</Text>
            </View>
          </View>
        </View>

        <View style={styles.infoBox}>
          <View style={styles.row}>
            <View style={styles.leftInfo}>
              <Image style={styles.infoImage} src={sunPower} />
            </View>
            <View style={styles.rightInfo}>
              <Text style={styles.infoHeader}>Site Capacity:</Text>
              <Text style={styles.infoText}>{`${data.results.capacity}kW`}</Text>
            </View>
          </View>
        </View>

        <View style={styles.infoBox}>
          <View style={styles.row}>
            <View style={styles.leftInfo}>
              <Image style={styles.infoImage} src={icon} />
            </View>
            <View style={styles.rightInfo}>
              <Text style={styles.infoHeader}>Location:</Text>
              <Text style={styles.infoText}>
                {data.location.lat.toFixed(3)}°, {data.location.lng.toFixed(3)}°
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

// const GM_Header_Styles = StyleSheet.create({
//   header_row: {
//     width: '100%',
//     display: 'flex',
//     flexDirection: 'row',
//     alignItems: 'flex-end',
//     justifyContent: 'space-between',
//     borderBottomColor: '#333',
//     borderBottomWidth: 1,
//   },
//   logo_img: {
//     width: 100,
//     marginBottom: 10,
//   },
// });

// const GM_Header = () => (
//   <View style={GM_Header_Styles.header_row}>
//     <Image style={GM_Header_Styles.logo_img} src={rbiLogo} />
//   </View>
// );

export { SummaryHeader, GroundMountHeader };
