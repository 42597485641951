import React, { Fragment } from "react";
import { PDFViewer, Page, Text, View, Image, Document, StyleSheet, Svg, Font } from "@react-pdf/renderer";

// topo image helper
import { getTopoImages, getTopoScale } from "../LiveReport";

import { poweredBySunfig } from "../../assets";

import { ts_logo_hori, glide_front, glide_rear, gm_2, rbi_gm_2, terratrack, dahlia_fuse } from "../../assets/";

import thirtyPercentBg from "../LiveCanopyReport/thirtyPercentBg.jpg";
import thirtyPercentBg2 from "../LiveCanopyReport/thirtyPercentBg2.jpg";
import thirtyPercentBg3 from "../LiveCanopyReport/thirtyPercentBg3.jpg";
import thirtyPercentBg4 from "../LiveCanopyReport/thirtyPercentBg4.jpg";
import northIndicator from "../LiveCanopyReport/northIndicator.png";

import { SummaryHeader, GroundMountHeader } from "./SummaryHeader";
import {
  SummaryTable,
  Tracker_Breakdown,
  ModuleSection,
  Racking,
  Performance,
  Rundown,
  IAMTable_report,
  InverterTable,
  LayoutTable,
  LossesTable,
  PerfSummaryTable,
  SoilingAlbedoTable,
  BOMTable,
  LossTable,
} from "./SummaryTable";
import { SummaryDetail } from "./SummaryDetail";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const d = new Date();

const sampleData = [
  { Name: "Slope 1", Module: "385w Generic Module", Capacity: 25.41, Type: "Single Slope", Azimuth: 180, Tilt: 7, Dims: { modX: 24, modY: 3, dimX: 94.5, dimY: 21.9 }, ModQty: 72 },
  { Name: "Slope 2", Module: "385w Generic Module", Capacity: 25.41, Type: "Single Slope", Azimuth: 180, Tilt: 7, Dims: { modX: 24, modY: 3, dimX: 94.5, dimY: 21.9 }, ModQty: 72 },
  { Name: "Slope 3", Module: "385w Generic Module", Capacity: 25.41, Type: "Single Slope", Azimuth: 180, Tilt: 7, Dims: { modX: 24, modY: 3, dimX: 94.5, dimY: 21.9 }, ModQty: 72 },
  { Name: "Slope 4", Module: "385w Generic Module", Capacity: 25.41, Type: "Single Slope", Azimuth: 180, Tilt: 7, Dims: { modX: 24, modY: 3, dimX: 94.5, dimY: 21.9 }, ModQty: 72 },
  { Name: "Perimeter", Module: "385w Generic Module", Capacity: 25.41, Type: "Single Slope", Azimuth: 180, Tilt: 7, Dims: { modX: 24, modY: 3, dimX: 94.5, dimY: 21.9 }, ModQty: 72 },
  { Name: "Canopy West 1", Module: "385w Generic Module", Capacity: 25.41, Type: "Single Slope", Azimuth: 180, Tilt: 7, Dims: { modX: 24, modY: 3, dimX: 94.5, dimY: 21.9 }, ModQty: 72 },
  { Name: "Canopy West 2", Module: "385w Generic Module", Capacity: 25.41, Type: "Single Slope", Azimuth: 180, Tilt: 7, Dims: { modX: 24, modY: 3, dimX: 94.5, dimY: 21.9 }, ModQty: 72 },
  { Name: "Canopy West 3", Module: "385w Generic Module", Capacity: 25.41, Type: "Single Slope", Azimuth: 180, Tilt: 7, Dims: { modX: 24, modY: 3, dimX: 94.5, dimY: 21.9 }, ModQty: 72 },
  { Name: "Canopy West 4", Module: "385w Generic Module", Capacity: 25.41, Type: "Single Slope", Azimuth: 180, Tilt: 7, Dims: { modX: 24, modY: 3, dimX: 94.5, dimY: 21.9 }, ModQty: 72 },
];
const sampleGMData = {
  name: "Lot A",
  module_name: "Generaic Sat 28mod/tr",
  type: "Single Axis Tracker",
  backtracking: "Yes",
  tracking_angle: 60,
  moduleRating: "245W Generic Module",
  rating: 245,
  capacity: 130.34,
  module_qty: 532,
  location: { lat: 39.191, lng: -84.475 },
  gcr: 0,
  pitch: 6.67,
  row_spacing: 4.67,
  azimuth: 180,
  mw_dc: 13.91,
  generation: 210707,
  bifacial: "no",
};

const cur_product = {
  0: { active: 1, key: 0, module: 112, xdim: 2, ydim: 114.578404 },
  1: { active: 1, key: 1, module: 112, xdim: 2, ydim: 114.578404 },
  2: { active: 1, key: 2, module: 112, xdim: 2, ydim: 114.578404 },
  3: { active: 1, key: 3, module: 112, xdim: 2, ydim: 114.578404 },
};

const results_data = {
  YIELD: 1625.75,
  ac_loss: 2,
  array_nominal_energy: 0,
  azimuth: 180,
  bifacial_gain: 0,
  capacity: 2503.9,
  cashflow_after_tax: "[]",
  dc_degradation: 0.5,
  dc_mismatch_loss: 0,
  dcac: 37.9378787878788,
  e_array: 0,
  gcr: 0.32,
  generation: 4070726,
  generation_array: "[]",
  global_effective_irradiation_on_col: 0,
  global_incident_in_col_plane: 0,
  horizontal_global_irradiance: 1523.38305664062,
  iam_factor_on_global: 0,
  id: "ed6cc6e9-3619-4ebb-8398-2a3c155bf031",
  interconnect_lim_loss: 0,
  inverter_count: 1,
  inverter_loss: 0,
  irr: 0,
  layout_id: "981c87d5-dd13-4099-9c8b-e7383005f104",
  lcoe: 0,
  lid_loss: 0,
  module_count: 10220,
  module_name: "245W Generic Module",
  modules_in_layout: 10220,
  modules_per_string: 28,
  mw_ac: 0.066,
  mw_peak: 2.5,
  near_shadings_irradiance_loss: 0,
  npv: 0,
  ohmic_wiring_loss: 0,
  op_cost_array: "[]",
  pitch: 6.25,
  pv_elec_loss: 0,
  pv_loss_irrad: 0,
  pv_loss_temp: 0,
  quality_loss: 0,
  rack_breakdown: "[46, 37, 28, 14]",
  rack_count: 125,
  racking_footprint: 6.68653518125,
  racking_name: "Single Axis",
  removed_modules: 0,
  rtr: 4.25,
  soiling_loss: 0,
  spi: 365,
  string_count: 365,
  table_a: 46,
  table_b: 37,
  table_c: 28,
  table_d: 14,
  tilt: 0,
  total_install_cost: 0,
  total_modules: 10220,
};

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 44,
    paddingBottom: 65,
  },
  date: {
    position: "absolute",
    fontSize: 12,
    top: 15,
    right: 15,
    color: "grey",
  },

  logo: {
    marginHorizontal: 212,
    width: 176,
  },
  header: {
    color: "#333",
    textAlign: "center",
    marginTop: 7.5,
  },
  topImage: {
    marginVertical: 15,
    marginHorizontal: 20,
    height: 300,
  },
  overviewImageContainer: {
    left: 0,
    right: 0,
    top: 0,
    marginLeft: "auto",
    marginRight: "auto",
    marginVertical: 15,
    width: 550,
    height: 385,
  },
  overviewImage: {
    width: "100%",
    height: "100%",
  },
  bySunfig: {
    position: "absolute",
    height: 10,
    right: 20,
    bottom: 20,
  },

  infoContainer: {
    flexGrow: 1,
    marginHorizontal: 20,
  },
  infoBox: {
    flexGrow: 5,
    backgroundColor: "#f2f2f2",
    height: 40,
  },
  infoImage: {
    width: 25,
    marginHorizontal: 10,
    marginVertical: 7.5,
  },
  infoHeader: {
    fontSize: 10,
    textAlign: "justify",
    marginVertical: 2.5,
  },
  infoText: {
    fontSize: 8,
    textAlign: "justify",
    marginVertical: 2.5,
    // Segoe UI
  },

  row: {
    flexGrow: 1,
    flexDirection: "row",
    height: 40,
  },

  fill1: {
    flexGrow: 5,
    backgroundColor: "#e14427",
    height: 70,
  },
  fill2: {
    flexGrow: 5,
    backgroundColor: "#e6672d",
    height: 70,
  },
  fill3: {
    flexGrow: 5,
    backgroundColor: "#e78632",
    height: 70,
  },
  fill4: {
    flexGrow: 5,
    backgroundColor: "#e29e37",
    height: 70,
  },
  fill5: {
    flexGrow: 5,
    backgroundColor: "#f2f2f2",
    height: 70,
  },

  leftInfo: {
    // flexGrow: 1,
    width: 45,
    // backgroundColor: '#f22332',
    backgroundColor: "#f2f2f2",
    height: 40,
  },
  rightInfo: {
    // flexGrow: 1,
    // backgroundColor: '#f212f2',
    height: 40,
  },

  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  date: {
    position: "absolute",
    fontSize: 12,
    top: 15,
    right: 15,
    color: "grey",
  },
  logo: {
    marginHorizontal: 212,
    width: 176,
  },
  thirty_percent_container: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  thirt_percent_bg: {
    width: "100%",
    height: "100%",
  },
  vicinityMapContainer: {
    position: "absolute",
    height: 485,
    width: 475,
    top: 335,
    left: 300,
  },
  customer_name_container: {
    position: "absolute",
    width: 1095,
    textAlign: "center",
    top: 130,
  },
  customer_title: {
    position: "absolute",
    width: 1095,
    textAlign: "center",
    top: 190,
  },
  address_one: {
    position: "absolute",
    width: 1095,
    textAlign: "center",
    top: 230,
  },
  address_two: {
    position: "absolute",
    width: 1095,
    textAlign: "center",
    top: 250,
  },
  customer_name_right: {
    position: "absolute",
    transform: "rotate(-90deg)",
    width: 190,
    height: 40,
    textAlign: "center",
    top: 360,
    left: 1065,
  },
  customer_title_right: {
    position: "absolute",
    width: 90,
    textAlign: "center",
    top: 680,
    left: 1085,
  },
  customer_title_text: {
    fontSize: 8,
    marginBottom: 3,
  },
  job_number: {
    position: "absolute",
    width: 80,
    textAlign: "center",
    top: 733,
    left: 1085,
  },
  drawn_by: {
    position: "absolute",
    width: 20,
    textAlign: "center",
    top: 751,
    left: 1085,
  },
  reviewed_by: {
    position: "absolute",
    width: 20,
    textAlign: "center",
    top: 751,
    left: 1120,
  },
  array_type: {
    position: "absolute",
    fontSize: 7,
    bottom: 26,
    left: 85,
    color: "#333",
  },
  active_pv_modules: {
    position: "absolute",
    fontSize: 7,
    bottom: 38,
    left: 220,
    color: "#333",
  },
  active_rating: {
    position: "absolute",
    fontSize: 7,
    bottom: 38,
    left: 250,
    color: "#333",
  },
  mod_qty_container: {
    position: "absolute",
    fontSize: 7,
    bottom: 26,
    left: 220,
    color: "#333",
  },
  total_watts: {
    position: "absolute",
    fontSize: 7,
    bottom: 20,
    left: 220,
    color: "#333",
  },
  thirty_percent_layout_map_container: {
    position: "absolute",
    height: 755,
    width: 1035,
    top: 40,
    left: 35,
  },
  thirty_percent_map_image: {
    height: "100%",
    width: "100%",
  },
  north_indicator_container: {
    position: "absolute",
    height: 30,
    width: 35,
    bottom: 50,
    right: 130,
    borderRadius: 2,
    opacity: 0.7,
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  north_indicator_img: {
    height: 25,
    width: 30,
    borderRadius: 2,
    opacity: 0.7,
  },
});

// Create Document Component
const CanopyReport = (props) => (
  <Document title={`${props.data.overview.project ? props.data.overview.project : "Solar Canopy"}`}>
    {/* ruler verticalRulerSteps="10%" horizontalRulerSteps="10%" */}
    <Page size="A4" style={styles.body}>
      {/* top fixed */}
      <Text style={styles.date} fixed>{`${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`}</Text>
      {/* content */}
      <Image style={styles.logo} src={ts_logo_hori} />
      <Text style={styles.header}>Canopy Design Summary{`: ${props.data.overview.project ? props.data.overview.project : "Solar Canopy"}`}</Text>

      <View style={styles.overviewImageContainer}>{props.data.images["mainMap"] && <Image src={props.data.images["mainMap"]} style={styles.overviewImage} />}</View>

      {/* summary header */}
      <SummaryHeader overview={props.data.overview} />
      {/* summary table */}
      <SummaryTable data={Object.values(props.data.results)} />
      {/* summary detail list */}
      <SummaryDetail results={props.data.results} images={props.data.images} />
      {/* bottom fixed */}
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} fixed />
    </Page>
  </Document>
);

const ThirtyPercentReport = (props) => {
  return (
    <Document>
      <Page size="A3" orientation="landscape">
        <View style={styles.thirty_percent_container}>
          <Image src={thirtyPercentBg} style={styles.thirt_percent_bg} />
          <View style={styles.vicinityMapContainer}>{props.data.images["vicinityMap"] && <Image src={props.data.images["vicinityMap"]} style={styles.overviewImage} />}</View>
          <View style={styles.customer_name_container}>{props.data.thirtyPercentInputs?.customer_for && <Text>{props.data.thirtyPercentInputs?.customer_for}</Text>}</View>
          <View style={styles.customer_title}>{props.data.thirtyPercentInputs?.customer_at && <Text>{props.data.thirtyPercentInputs?.customer_at}</Text>}</View>
          <View style={styles.address_one}>{props.data.thirtyPercentInputs?.address_one && <Text>{props.data.thirtyPercentInputs?.address_one}</Text>}</View>
          <View style={styles.address_two}>{props.data.thirtyPercentInputs?.address_two && <Text>{props.data.thirtyPercentInputs?.address_two}</Text>}</View>
          <View style={styles.customer_name_right}>{props.data.thirtyPercentInputs?.customer_for && <Text>{props.data.thirtyPercentInputs?.customer_for}</Text>}</View>
          <View style={styles.customer_title_right}>
            {props.data.thirtyPercentInputs?.customer_at && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.customer_at}</Text>}
            {props.data.thirtyPercentInputs?.address_one && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.address_one}</Text>}
            {props.data.thirtyPercentInputs?.address_two && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.address_two}</Text>}
          </View>
          <View style={styles.job_number}>{props.data.thirtyPercentInputs?.project_number && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.project_number}</Text>}</View>
          <View style={styles.drawn_by}>{props.data.thirtyPercentInputs?.drawn_by && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.drawn_by}</Text>}</View>
          <View style={styles.reviewed_by}>{props.data.thirtyPercentInputs?.reviewed_by && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.reviewed_by}</Text>}</View>
          <View style={styles.array_type}>{props.data.thirtyPercentInputs?.array_type && <Text>{props.data.thirtyPercentInputs?.array_type}</Text>}</View>
          <View style={styles.active_pv_modules}>{props.data.overview.mod_qty && <Text>{props.data.overview.mod_qty}</Text>}</View>
          <View style={styles.active_rating}>{props.data.overview.rating && <Text>{props.data.overview.rating}</Text>}</View>
          <View style={styles.mod_qty_container}>{props.data.overview.mod_qty && <Text>{props.data.overview.mod_qty}</Text>}</View>
          <View style={styles.total_watts}>{props.data.overview.capacity && <Text>{props.data.overview.mod_qty * props.data.overview.rating}</Text>}</View>
        </View>
      </Page>
      <Page size="A3" orientation="landscape">
        <View style={styles.thirty_percent_container}>
          <Image src={thirtyPercentBg2} style={styles.thirt_percent_bg} />
          <View style={styles.customer_name_right}>{props.data.thirtyPercentInputs?.customer_for && <Text>{props.data.thirtyPercentInputs?.customer_for}</Text>}</View>
          <View style={styles.customer_title_right}>
            {props.data.thirtyPercentInputs?.customer_at && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.customer_at}</Text>}
            {props.data.thirtyPercentInputs?.address_one && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.address_one}</Text>}
            {props.data.thirtyPercentInputs?.address_two && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.address_two}</Text>}
          </View>
          <View style={styles.job_number}>{props.data.thirtyPercentInputs?.project_number && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.project_number}</Text>}</View>
          <View style={styles.drawn_by}>{props.data.thirtyPercentInputs?.drawn_by && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.drawn_by}</Text>}</View>
          <View style={styles.reviewed_by}>{props.data.thirtyPercentInputs?.reviewed_by && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.reviewed_by}</Text>}</View>
        </View>
      </Page>
      <Page size="A3" orientation="landscape">
        <View style={styles.thirty_percent_container}>
          <Image src={thirtyPercentBg3} style={styles.thirt_percent_bg} />
          <View style={styles.thirty_percent_layout_map_container}>
            {props.data.images["thirtyPercentLayoutMap"] && <Image src={props.data.images["thirtyPercentLayoutMap"]} style={styles.thirty_percent_map_image} />}
          </View>
          <View style={styles.north_indicator_container}>
            <Image src={northIndicator} style={styles.north_indicator_img} />
          </View>
          <View style={styles.customer_name_right}>{props.data.thirtyPercentInputs?.customer_for && <Text>{props.data.thirtyPercentInputs?.customer_for}</Text>}</View>
          <View style={styles.customer_title_right}>
            {props.data.thirtyPercentInputs?.customer_at && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.customer_at}</Text>}
            {props.data.thirtyPercentInputs?.address_one && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.address_one}</Text>}
            {props.data.thirtyPercentInputs?.address_two && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.address_two}</Text>}
          </View>
          <View style={styles.job_number}>{props.data.thirtyPercentInputs?.project_number && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.project_number}</Text>}</View>
          <View style={styles.drawn_by}>{props.data.thirtyPercentInputs?.drawn_by && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.drawn_by}</Text>}</View>
          <View style={styles.reviewed_by}>{props.data.thirtyPercentInputs?.reviewed_by && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.reviewed_by}</Text>}</View>
        </View>
      </Page>
      <Page size="A3" orientation="landscape">
        <View style={styles.thirty_percent_container}>
          <Image src={thirtyPercentBg4} style={styles.thirt_percent_bg} />
          <View style={styles.customer_name_right}>{props.data.thirtyPercentInputs?.customer_for && <Text>{props.data.thirtyPercentInputs?.customer_for}</Text>}</View>
          <View style={styles.customer_title_right}>
            {props.data.thirtyPercentInputs?.customer_at && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.customer_at}</Text>}
            {props.data.thirtyPercentInputs?.address_one && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.address_one}</Text>}
            {props.data.thirtyPercentInputs?.address_two && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.address_two}</Text>}
          </View>
          <View style={styles.job_number}>{props.data.thirtyPercentInputs?.project_number && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.project_number}</Text>}</View>
          <View style={styles.drawn_by}>{props.data.thirtyPercentInputs?.drawn_by && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.drawn_by}</Text>}</View>
          <View style={styles.reviewed_by}>{props.data.thirtyPercentInputs?.reviewed_by && <Text style={styles.customer_title_text}>{props.data.thirtyPercentInputs?.reviewed_by}</Text>}</View>
        </View>
      </Page>
    </Document>
  );
};

//  ************** Ground Mount Report

// New Ground Mount Report Stuff

const GM_Header_Styles = StyleSheet.create({
  row: {
    width: "100%",
    height: 50,
    display: "flex",
    flexDirection: "row",
    borderBottomColor: "#333",
    borderBottomWidth: 1,
  },
  logo: {
    width: "17%",
    // padding: 5,
    borderRightColor: "#333",
    borderRightWidth: 1,
  },
  report_header: {
    width: "83%",
    display: "flex",
    flexDirection: "column",
  },
  report_header_row_1: {
    width: "100%",
    height: "50%",
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#333",
  },
  project_name_container: {
    width: "80%",
    borderRightWidth: 1,
    borderRightColor: "#333",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  project_name: {
    color: "#333",
    marginLeft: 5,
  },
  date_container: {
    width: "20%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  date: {
    width: "100%",
    textAlign: "center",
    color: "#333",
    marginLeft: 5,
  },
  report_header_row_2: {
    width: "100%",
    height: "50%",
    display: "flex",
    flexDirection: "row",
  },
  location_container: {
    width: "50%",
    borderRightWidth: 1,
    color: "#333",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  location: {
    color: "#333",
    marginLeft: 5,
  },
  user_container: {
    width: "50%",
    color: "#333",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  user: {
    color: "#333",
    marginLeft: 5,
  },
});

const GM_Header = ({ data }) => (
  <View style={GM_Header_Styles.row}>
    <View style={GM_Header_Styles.logo}>
      <Image src={ts_logo_hori} />
    </View>
    <View style={GM_Header_Styles.report_header}>
      <View style={GM_Header_Styles.report_header_row_1}>
        <View style={GM_Header_Styles.project_name_container}>
          <Text style={GM_Header_Styles.project_name}>{`Project Name: ${data.projectName ? data.projectName : "Solar Project"}`}</Text>
        </View>
        <View style={GM_Header_Styles.date_container}>
          <Text style={GM_Header_Styles.date}>{`${data.date}`}</Text>
        </View>
      </View>
      <View style={GM_Header_Styles.report_header_row_2}>
        <View style={GM_Header_Styles.location_container}>
          <Text style={GM_Header_Styles.location}>{`Location: ${data.latitude.toFixed(3)}°, ${data.longitude.toFixed(3)}°`}</Text>
        </View>
        <View style={GM_Header_Styles.user_container}>
          <Text style={GM_Header_Styles.user}>{`User: ${data.userName ? data.userName : "n/a"}`}</Text>
        </View>
      </View>
    </View>
  </View>
);

const GMFooterStyles = StyleSheet.create({
  footer_container: {
    width: "100%",
    height: 30,
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    bottom: 0,
    borderTopColor: "#333",
    borderTopWidth: 1,
  },
  footer_column: {
    width: "33%",
  },
  page_designation: {
    width: "15%",
    height: "100%",
    borderRightColor: "#333",
    borderRightWidth: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  powered_by_box: {
    width: "70%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  footer_image: {
    width: 90,
  },
  page_box: {
    width: "15%",
    height: "100%",
    borderLefttColor: "#333",
    borderLeftWidth: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  page_text: {
    width: "100%",
    color: "#333",
    fontSize: 10,
    textAlign: "right",
    paddingRight: 5,
  },
});

const GM_Footer = ({ page, designation, numOfPages }) => {
  return (
    <View style={GMFooterStyles.footer_container}>
      <View style={GMFooterStyles.page_designation}>
        <Text>{designation}</Text>
      </View>
      <View style={GMFooterStyles.powered_by_box}>{/* <Image style={GMFooterStyles.footer_image} src={poweredBySunfig} /> */}</View>
      <View style={GMFooterStyles.page_box}>
        <Text>{`Page ${page} of ${numOfPages}`}</Text>
      </View>
    </View>
  );
};

const NewGMStyles = StyleSheet.create({
  page: {
    flexDirection: "column",
    orientation: "portrait",
    width: "100%",
    height: "100%",
    fontSize: 10,
    padding: 10,
  },
  section: {
    position: "relative",
    flexGrow: 1,
    borderTopColor: "#333",
    borderTopWidth: 1,
    borderLeftColor: "#333",
    borderLeftWidth: 1,
    borderRightColor: "#333",
    borderRightWidth: 1,
    borderBottomColor: "#333",
    borderBottomWidth: 1,
    height: "100%",
    width: "100%",
  },
  full_page_map: {
    width: "100%",
  },
  title_container: {
    height: 50,
    width: "100%",
    textAlign: "center",
    paddingVertical: 10,
    borderBottomColor: "#333",
    borderBottomWidth: 1,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: "#333",
    marginBottom: 3,
  },
  address: {
    color: "#333",
  },
  content: {
    width: "100%",
    padding: 10,
    marginBottom: 10,
  },
  layout_view: {
    width: "100%",
    display: "flex",
    padding: 10,
    flexDirection: "column",
  },
  layout_map_view: {
    width: 550,
    height: 390,
  },
  large_map_view: {
    width: 550,
    height: 700,
  },
  module_section: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  column: {
    width: "50%",
  },
  racking_row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  racking_column: {
    width: "55%",
  },
  racking_product_image: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 15,
  },
  product_image: {
    width: 500,
    marginHorizontal: "auto",
  },
  module_column: {
    width: "50%",
  },
  IAM_column: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    paddingTop: 18,
  },

  losses_table: {
    width: "52%",
    display: "flex",
    flexDirection: "column",
    paddingTop: 18,
  },
  Iam_graph_style: {
    width: "100%",
    height: 500,
  },
  header_text: {
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 5,
  },
  layoutMapNoBackground: {
    height: 280,
    width: 330,
    marginTop: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#333",
    borderTopWidth: 2,
    borderTopColor: "#333",
    borderLeftWidth: 2,
    borderLeftColor: "#333",
    borderRightWidth: 1,
    borderRightColor: "#333",
  },
  topo_image_container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  topo_image: {
    height: 300,
  },
  topo_scale: {
    height: 300,
    marginLeft: 60,
  },
  perf_section: {
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
  },
  perf_soiling: {
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    marginTop: 15,
  },
  perf_column: {
    width: "45%",
    display: "flex",
    flexDirection: "column",
  },
  perf_row: {
    width: "100%",
    height: 330,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  perf_row_table: {
    width: "65%",
    display: "flex",
  },
  perf_row_graphs: {
    width: "33%",
    // height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  perf_row_graph_images: {
    height: 125,
    marginBottom: 10,
  },
  perf_graph_image: {
    height: 340,
  },
  loss_table_column: {
    width: "48%",
    display: "flex",
    flexDirection: "column",
    paddingVertical: 10,
    marginRight: 10,
  },
  loss_table_bifacial_column: {
    width: "48%",
    display: "flex",
    flexDirection: "column",
    paddingVertical: 138,
  },
});

const GroundMountReport = ({ data }) => {
  const getProductImage = () => {
    switch (data.racking_name) {
      case "RBI Solar - 2HP":
        return gm_2;
        break;
      // we have no image for 3HP
      // case "RBI Solar - 3HP":
      //   console.log("RBI Solar - 3HP");
      //   break;
      case "RBI Solar - Dahlia - 4HP":
        return dahlia_fuse;
        break;
      case "Terrasmart - TerraTrak - 2HT":
        return terratrack;
        break;
      case "Terrasmart - GLIDE - 2HP":
        return glide_rear;
        break;
      case "Terrasmart - GLIDE - 4HL":
        return glide_rear;
        break;
      default:
        break;
    }
  };

  return (
    <Document title={`${data.projectName ? data.projectName : "Solar Project"}`}>
      <Page size="A4" style={NewGMStyles.page} wrap={false}>
        <View style={NewGMStyles.section}>
          <GM_Header data={data} />
          <View style={NewGMStyles.title_container}>
            <Text style={NewGMStyles.title}>{`${data.projectName ? data.projectName : "Solar Project"}`}</Text>
            <Text style={NewGMStyles.address}>{data.address ? data.address : ""}</Text>
          </View>
          <Rundown data={data} />
          <View style={NewGMStyles.layout_view}>
            <Text style={NewGMStyles.header_text}>Small Layout Map</Text>
            {data.reportImages.mapImages.layoutMapSmall && <Image style={NewGMStyles.layout_map_view} src={data.reportImages.mapImages.layoutMapSmall} />}
          </View>
          <GM_Footer page={1} numOfPages={data.numOfPages} designation={"A001"} />
        </View>
      </Page>

      <Page size="A4" style={NewGMStyles.page}>
        <View style={NewGMStyles.section}>
          <GM_Header data={data} />
          <View style={NewGMStyles.content}>
            <Text style={NewGMStyles.header_text}>Large Layout Map</Text>
            {data.reportImages.mapImages.layoutMapLarge && <Image style={NewGMStyles.large_map_view} src={data.reportImages.mapImages.layoutMapLarge} />}
          </View>
          <GM_Footer page={2} numOfPages={data.numOfPages} designation={"A002"} />
        </View>
      </Page>

      <Page size="A4" style={NewGMStyles.page}>
        <View style={NewGMStyles.section}>
          <GM_Header data={data} />
          <View style={NewGMStyles.content}>
            <View style={NewGMStyles.racking_row}>
              <View style={NewGMStyles.racking_column}>
                <Text style={NewGMStyles.header_text}>Racking</Text>
                <Racking data={data} />
                <Tracker_Breakdown data={data} />
              </View>
            </View>
            <View style={NewGMStyles.racking_product_image}>{data.racking_name != "RBI Solar - 3HP" && <Image style={NewGMStyles.product_image} src={getProductImage()} />}</View>
          </View>
          <GM_Footer page={3} numOfPages={data.numOfPages} designation={"B001"} />
        </View>
      </Page>

      <Page size="A4" style={NewGMStyles.page}>
        <View style={NewGMStyles.section}>
          <GM_Header data={data} />
          <View style={NewGMStyles.content}>
            <View style={NewGMStyles.module_section}>
              <View style={data.simple_module !== 0 ? NewGMStyles.module_column : NewGMStyles.column}>
                <View>
                  <Text style={NewGMStyles.header_text}>Module</Text>
                  <ModuleSection data={data} />
                </View>
              </View>
              {data.simple_module !== 0 && (
                <View style={NewGMStyles.IAM_column}>
                  <Image style={NewGMStyles.IAM_graph_style} src={data.reportImages.graphImages.iamGraph} />
                  <View style={{ width: "50%" }}>
                    <IAMTable_report data={data} />
                  </View>
                </View>
              )}
            </View>
          </View>
          <GM_Footer page={4} numOfPages={data.numOfPages} designation={"B002"} />
        </View>
      </Page>

      <Page size="A4" style={NewGMStyles.page}>
        <View style={NewGMStyles.section}>
          <GM_Header data={data} />
          <View style={NewGMStyles.content}>
            <View style={NewGMStyles.module_section}>
              <View style={NewGMStyles.column}>
                <Text style={NewGMStyles.header_text}>Inverter</Text>
                <InverterTable data={data} />
              </View>
              <View style={NewGMStyles.column}>
                <Image style={NewGMStyles.IAM_graph_style} src={data.reportImages.graphImages.inverterEffGraph} />
              </View>
            </View>
          </View>
          <GM_Footer page={5} numOfPages={data.numOfPages} designation={"B003"} />
        </View>
      </Page>

      <Page size="A4" style={NewGMStyles.page}>
        <View style={NewGMStyles.section}>
          <GM_Header data={data} />
          <View style={NewGMStyles.content}>
            <View style={NewGMStyles.perf_section}>
              <View style={NewGMStyles.perf_column}>
                <Text style={NewGMStyles.header_text}>Perfomance</Text>
                <Performance data={data} />
              </View>
            </View>
            <View style={NewGMStyles.perf_soiling}>
              <SoilingAlbedoTable data={data} />
            </View>
          </View>
          <GM_Footer page={6} numOfPages={data.numOfPages} designation={"B004"} />
        </View>
      </Page>

      <Page size="A4" style={NewGMStyles.page}>
        <View style={NewGMStyles.section}>
          <GM_Header data={data} />
          <View style={NewGMStyles.content}>
            <View style={NewGMStyles.module_section}>
              <View style={NewGMStyles.column}>
                <Text style={NewGMStyles.header_text}>Layout</Text>
                <LayoutTable data={data} />
              </View>
            </View>
          </View>
          <GM_Footer page={7} numOfPages={data.numOfPages} designation={"B005"} />
        </View>
      </Page>

      <Page size="A4" style={NewGMStyles.page}>
        <View style={NewGMStyles.section}>
          <GM_Header data={data} />
          <View style={NewGMStyles.content}>
            <View style={NewGMStyles.perf_section}>
              <View style={NewGMStyles.perf_column}>
                <Text style={NewGMStyles.header_text}>Bill of Materials</Text>
                <BOMTable data={data} />
                <Text>*Bill of materials is presented here for budgetary and general guidance purposes only.</Text>
              </View>
            </View>
          </View>
          <GM_Footer page={8} numOfPages={data.numOfPages} designation={"B005"} />
        </View>
      </Page>

      <Page size="A4" style={NewGMStyles.page}>
        <View style={NewGMStyles.section}>
          <GM_Header data={data} />
          <View style={NewGMStyles.content}>
            <Text style={NewGMStyles.header_text}>Perfomance Results</Text>
            <View style={NewGMStyles.perf_row}>
              <View style={NewGMStyles.perf_row_table}>
                <PerfSummaryTable data={data} />
              </View>
              <View style={NewGMStyles.perf_row_graphs}>
                <Image style={NewGMStyles.perf_row_graph_images} src={data.reportImages.graphImages["windAndTempGraph"]} />
                <Image style={NewGMStyles.perf_row_graph_images} src={data.reportImages.graphImages["eArrayGraph"]} />
              </View>
            </View>
            <View style={NewGMStyles.perf_row}>
              <Image style={NewGMStyles.perf_graph_image} src={data.reportImages.graphImages["perfGraph"]} />
            </View>
          </View>
          <GM_Footer page={9} numOfPages={data.numOfPages} designation={"B004"} />
        </View>
      </Page>

      <Page size="A4" style={NewGMStyles.page}>
        <View style={NewGMStyles.section}>
          <GM_Header data={data} />
          <View style={NewGMStyles.content}>
            <View style={NewGMStyles.perf_section}>
              <View style={NewGMStyles.loss_table_column}>
                <Text style={NewGMStyles.header_text}>Loss Tables</Text>
                <LossTable data={data} />
              </View>
              <View style={NewGMStyles.loss_table_bifacial_column}>{data.en_bifacial == 1 && <LossTable data={data} bifacial={true} />}</View>
            </View>
          </View>
          <GM_Footer page={10} numOfPages={data.numOfPages} designation={"B005"} />
        </View>
      </Page>

      {data.topo_id && (
        <>
          <Page size="A4" style={NewGMStyles.page}>
            <View style={NewGMStyles.section}>
              <GM_Header data={data} />
              <View style={NewGMStyles.content}>
                <Text>{`Elevation (Ungraded)`}</Text>
                <View style={NewGMStyles.topo_image_container}>
                  {data.reportImages.mapImages["ele"] && <Image style={NewGMStyles.topo_image} src={data.reportImages.mapImages["ele"]} />}
                  <Image style={NewGMStyles.topo_scale} src={getTopoScale(data.topo_id, "ele")} />
                </View>
              </View>
              {data.vis_ele.graded_avail && (
                <View style={NewGMStyles.content}>
                  <Text>{`Elevation (Graded)`}</Text>
                  <View style={NewGMStyles.topo_image_container}>
                    {data.reportImages.mapImages["ele-g"] && <Image style={NewGMStyles.topo_image} src={data.reportImages.mapImages["ele-g"]} />}
                    <Image style={NewGMStyles.topo_scale} src={getTopoScale(data.topo_id, `ele/G/${data.vis_ele.graded_percent}`)} />
                  </View>
                </View>
              )}
              <GM_Footer page={8} numOfPages={data.numOfPages} designation={"T001"} />
            </View>
          </Page>

          {data.vis_g.graded_avail && (
            <Page size="A4" style={NewGMStyles.page}>
              <View style={NewGMStyles.section}>
                <GM_Header data={data} />
                <View style={NewGMStyles.content}>
                  <Text>{`Cut and Fill `}</Text>
                  <View style={NewGMStyles.topo_image_container}>
                    {data.reportImages.mapImages["CF"] && <Image style={NewGMStyles.topo_image} src={data.reportImages.mapImages["CF"]} />}
                    <Image style={NewGMStyles.topo_scale} src={getTopoScale(data.topo_id, `CF/${data.vis_g.ungraded_percent}`)} />
                  </View>
                </View>
                <GM_Footer page={9} numOfPages={data.numOfPages} designation={"T002"} />
              </View>
            </Page>
          )}

          {(data.vis_max.ungraded_avail || data.vis_max.graded_avail) && (
            <Page size="A4" style={NewGMStyles.page}>
              <View style={NewGMStyles.section}>
                <GM_Header data={data} />
                {data.vis_max.ungraded_avail && (
                  <View style={NewGMStyles.content}>
                    <Text>{`Max Slope (Ungraded)`}</Text>
                    <View style={NewGMStyles.topo_image_container}>
                      {data.reportImages.mapImages["U"] && <Image style={NewGMStyles.topo_image} src={data.reportImages.mapImages["U"]} />}
                      <Image style={NewGMStyles.topo_scale} src={getTopoScale(data.topo_id, `U/${data.vis_max.ungraded_percent}`)} />
                    </View>
                  </View>
                )}
                {data.vis_max.graded_avail && (
                  <View style={NewGMStyles.content}>
                    <Text>{`Max Slope (Graded)`}</Text>
                    <View style={NewGMStyles.topo_image_container}>
                      {data.reportImages.mapImages["U-G"] && <Image style={NewGMStyles.topo_image} src={data.reportImages.mapImages["U-G"]} />}
                      <Image style={NewGMStyles.topo_scale} src={getTopoScale(data.topo_id, `U/G/${data.vis_max.graded_percent}`)} />
                    </View>
                  </View>
                )}
                <GM_Footer page={10} numOfPages={data.numOfPages} designation={"T003"} />
              </View>
            </Page>
          )}

          {(data.vis_ns.ungraded_avail || data.vis_ns.graded_avail) && (
            <Page size="A4" style={NewGMStyles.page}>
              <View style={NewGMStyles.section}>
                <GM_Header data={data} />
                {data.vis_ns.ungraded_avail && (
                  <View style={NewGMStyles.content}>
                    <Text>{`NS Grade (Ungraded)`}</Text>
                    <View style={NewGMStyles.topo_image_container}>
                      {data.reportImages.mapImages["NS"] && <Image style={NewGMStyles.topo_image} src={data.reportImages.mapImages["NS"]} />}
                      <Image style={NewGMStyles.topo_scale} src={getTopoScale(data.topo_id, `NS/${data.vis_ns.ungraded_percent}`)} />
                    </View>
                  </View>
                )}
                {data.vis_ns.graded_avail && (
                  <View style={NewGMStyles.content}>
                    <Text>{`NS Grade (Graded)`}</Text>
                    <View style={NewGMStyles.topo_image_container}>
                      {data.reportImages.mapImages["NS-G"] && <Image style={NewGMStyles.topo_image} src={data.reportImages.mapImages["NS-G"]} />}
                      <Image style={NewGMStyles.topo_scale} src={getTopoScale(data.topo_id, `NS/G/${data.vis_ns.graded_percent}`)} />
                    </View>
                  </View>
                )}
                <GM_Footer page={11} numOfPages={data.numOfPages} designation={"T004"} />
              </View>
            </Page>
          )}

          {(data.vis_ew.ungraded_avail || data.vis_ew.graded_avail) && (
            <Page size="A4" style={NewGMStyles.page}>
              <View style={NewGMStyles.section}>
                <GM_Header data={data} />
                {data.vis_ew.ungraded_avail && (
                  <View style={NewGMStyles.content}>
                    <Text>{`EW Grade (Ungraded)`}</Text>
                    <View style={NewGMStyles.topo_image_container}>
                      {data.reportImages.mapImages["EW"] && <Image style={NewGMStyles.topo_image} src={data.reportImages.mapImages["EW"]} />}
                      <Image style={NewGMStyles.topo_scale} src={getTopoScale(data.topo_id, `EW/${data.vis_ew.ungraded_percent}`)} />
                    </View>
                  </View>
                )}
                {data.vis_ew.graded_avail && (
                  <View style={NewGMStyles.content}>
                    <Text>{`EW Grade (Graded)`}</Text>
                    <View style={NewGMStyles.topo_image_container}>
                      {data.reportImages.mapImages["EW-G"] && <Image style={NewGMStyles.topo_image} src={data.reportImages.mapImages["EW-G"]} />}
                      <Image style={NewGMStyles.topo_scale} src={getTopoScale(data.topo_id, `EW/G/${data.vis_ew.graded_percent}`)} />
                    </View>
                  </View>
                )}
                <GM_Footer page={12} numOfPages={data.numOfPages} designation={"T004"} />
              </View>
            </Page>
          )}
        </>
      )}
    </Document>
  );
};

const Report = (props) => (
  <PDFViewer style={{ width: "100%", height: "1200px" }}>
    <GroundMountReport props />
  </PDFViewer>
);

export { Report, CanopyReport, ThirtyPercentReport, GroundMountReport };
