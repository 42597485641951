import * as turf from '@turf/turf';

function createFeature(index, identity, coordinates) {
  let feature = {};
  let properties = {};
  let geometry = {};

  properties.index = index;
  properties.identity = identity;
  properties.active = true;
  properties.area = getArea([coordinates]);
  properties.oversized = false;

  geometry.type = 'Polygon';
  geometry.coordinates = [coordinates];

  feature.type = 'Feature';
  feature.properties = properties;
  feature.geometry = geometry;

  return feature;
}

function validateFeature(feature, features) {
  let poly_count = feature.geometry.coordinates[0].length;

  let str_coord = feature.geometry.coordinates[0].toString();
  var valid = features.length == 0 ? true : false;
  var match = false;
  for (var feat in features) {
    if (features[feat].geometry.coordinates[0].length == poly_count) {
      let str_coord_compare = features[feat].geometry.coordinates[0].toString();
      // compare the strings
      var is_equal = str_coord === str_coord_compare;
      // if the strings are equal, then this should NOT be added
      if (is_equal) {
        match = true;
        break;
      }
    }
  }
  // if there was a match within the above loop, we need to set valid to false so this isn't added
  if (match) {
    valid = false;
  } else {
    valid = true;
  }
  if (feature.properties.area == -1) {
    valid = false;
  }
  return valid;
}

export function loadSurfaceFromXML(xml) {
  // console.log(xml);
  // pull all the polygons from the xml
  var features = [];
  var points = [];
  // Time to dig into the XML to pull out the coordinates
  // Doing so this way ensures we only pull out valid coords
  var nodes = xml.getElementsByTagName('Placemark');
  // console.log(xml)
  // console.log(nodes)
  var index = 0;

  for (var n in nodes) {
    var save = true;
    var is_lines = false;
    var is_multi = false;
    var identity = 0;
    var polys = nodes[n].getElementsByTagName('Polygon');
    var name = nodes[n].getElementsByTagName('name');
    // console.log(polys.length, nodes[n]['name'])
    if (polys.length == 0) {
      polys = nodes[n].getElementsByTagName('MultiGeometry');
      if (polys.length > 0) is_multi = true;
    }
    if (polys.length == 0) {
      polys = nodes[n].getElementsByTagName('LineString');
      is_lines = true;
    }

    if (name.length > 0) {
      name = name[0]['value'];
    } else {
      name = 'no name provided';
    }

    // SunfigData comes from exporting from SIFT
    var extData = nodes[n].getElementsByTagName('ExtendedData');
    if (extData.length > 0) {
      var data = extData[0].getElementsByTagName('Data');

      for (var d in data) {
        if (data[d].attributes.name == 'title') {
          if (data[d].children[0].value == 'Autolayout' || data[d].children[0].value == 'Road') {
            save = false;
          } else if (data[d].children[0].value == 'Boundary') {
            identity = 1;
          } else if (data[d].children[0].value == 'Exclusion') {
            identity = 2;
          }
        }
      }
      if (!save) continue;
    }
    for (var p in polys) {
      var coordinates = [];
      // if (polys[p]['name'] == 'LineString'){
      //   is_lines = true;
      // }
      // var coords=polys[p].getElementsByTagName('coordinates')
      var coords = nodes[n].getElementsByTagName('coordinates');
      // console.log(coords)
      for (var c in coords) {
        if (!is_multi) {
          coordinates = [];
        }

        let poly = coords[c].value.toString();
        let points = poly.split(' ');
        let last_lonlat = null;
        for (var p in points) {
          var lonlat = points[p].split(',');
          if (!isNaN(parseFloat(lonlat[1]))) {
            // coordinates.push([parseFloat(lonlat[1]), parseFloat(lonlat[0])])
            coordinates.push([parseFloat(lonlat[0]), parseFloat(lonlat[1])]);
            last_lonlat = lonlat;
          }
        }

        if (is_lines) {
          var first_lonlat = points[0].split(',');
          // var last_lonlat = points[points.length-1].split(",")
          if (!isNaN(parseFloat(first_lonlat[1]))) {
            let first_point = turf.point([parseFloat(first_lonlat[0]), parseFloat(first_lonlat[1])]);
            let last_point = turf.point([parseFloat(last_lonlat[0]), parseFloat(last_lonlat[1])]);
            var options = { units: 'kilometers' };
            var distance = turf.distance(first_point, last_point, options);
            if (distance > 0.085) {
              // console.log(distance)
              // distance too far
              continue;
            }
            coordinates.push([parseFloat(first_lonlat[0]), parseFloat(first_lonlat[1])]);
          }
        }

        if (coordinates.length < 4) {
          continue;
        }

        if (!is_multi) {
          coordinates = checkFirstandLast(coordinates);

          let feature = createFeature(index, identity, coordinates);
          let valid = validateFeature(feature, features);
          // only add this if it's valid
          if (valid) {
            features.push(feature);
          }
          // increment
          index++;
        }
      }

      if (is_multi) {
        coordinates.push(coordinates[0]);
        let feature = createFeature(index, identity, coordinates);
        let valid = validateFeature(feature, features);
        // only add this if it's valid
        if (valid) {
          features.push(feature);
        }
        index++;
      }
    }
    // console.log(polys.length)
    // if (polys.length == 0) {
    //   var point=nodes[n].getElementsByTagName('Point')
    //   for (var p in point) {
    //     var coords=nodes[n].getElementsByTagName('coordinates')
    //     for (c in coords) {
    //         var lonlat = coords[c].value.split(",")
    //         if (!isNaN(parseFloat(lonlat[1]))) {
    //           points.push([parseFloat(lonlat[1]), parseFloat(lonlat[0])])
    //         }
    //     }
    //   }
    // }
  }

  return features;

  if (surfaces.length > 0) {
    // Update state with new surfaces
    this.setState(
      {
        Surfaces: surfaces,
      },
      () => {
        // Call our update method that will add surfaces to our map
        this.forceUpdate();
        this.onSurfacesUpdates();
        // this.handleZoomExtents();
      }
    );
  } else {
    if (points.length == 0) {
      Swal({ title: 'Error', text: 'No polygons or markers found in KMZ.', type: 'warning', confirmButtonColor: '#d9534f' });
    } else {
      Swal({ title: 'Error', text: 'No polygons found in KMZ - centering on first found marker.', type: 'warning', confirmButtonColor: '#d9534f' });
      this.setState({ center: points[0] });
    }
  }
}

function getArea(coords) {
  // console.log(coords[0])
  // make sure first and last coord
  // if (coords[0][0] != coords[0][coords.length-1]){
  //   console.log(coords[0][0], coords[0][coords.length-1])
  //   coords[0].push(coords[0][0])
  // }
  let area = 0;
  try {
    let turfPoly = turf.polygon(coords);
    area = (turf.area(turfPoly) / 1000000) * 100;
  } catch (error) {
    console.log(error);
    // console.log(coords[0][0] == coords[0][coords[0].length-1])
    // console.log(coords[0][0], coords[0][coords[0].length-1])
    area = -1;
  }
  return area;
}
function checkFirstandLast(coords) {
  if (coords[0] != coords[coords.length - 1]) {
    // var options = {units: 'kilometers'};
    // var distance = turf.distance(coords[0], coords[coords.length-1], options);
    // console.log(distance)
    coords.push(coords[0]);
  }
  return coords;
}

export function getBounds(features) {
  let _features = [];
  for (var feat in features) {
    _features.push(features[feat]);
  }
  var featCollection = {
    type: 'FeatureCollection',
    features: _features,
  };
  var bbox = turf.bbox(featCollection);
  return bbox;
}

export function getNewCenterPoint(features) {
  let centers = [];
  Object.values(features).map((feat) => {
    if (feat['properties']['center']) {
      centers.push(turf.point(feat['properties']['center']));
    }
  });
  var center_features = turf.featureCollection(centers);
  return turf.getCoord(turf.flip(turf.center(center_features)));
}


export function uuid() {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}