import React from "react";
import ReactDOM from "react-dom";
import { Map, TileLayer, Polygon, GeoJSON, FeatureGroup, LayerGroup, withLeaflet, LayersControl } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import L from "leaflet";
import tokml from "tokml";
import LeafletControl from "./MapControl";
import { Toolbar } from "./Toolbar";
import { Button, Menu, Tooltip, Card } from "antd";
// import { Image } from 'react-dep-bootstrap';
// import Panel from 'react-dep-bootstrap/lib/Panel';

import { getBounds } from "../../utils/map_helper";
import Geocode from "react-geocode";
import * as turf from "@turf/turf";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
// import selectedResultFilter from './data/defaultSelectedResultFilter';
import selectedResultFormat from "../../utils/defaultSelectedResultFormat";
// import selectedResultOrder from './data/defaultSelectedResultOrder';
import "leaflet-easyprint";
import domtoimage from "dom-to-image";

import { portalActions } from "../Redux/actions";

import { add_song, minus, expand, vertex_white, vertex_green, rbilogo, bysunfig } from "../../assets";

const ButtonGroup = Button.Group;
const FileSaver = require("file-saver");
const TileSets = {
  OpenStreet: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  Esri_WorldStreet: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
  Esri_Topo: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
  Esri_ArcGIS: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
  MapTiler_SAT: "https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=tdZKmEGOeC3kM451zVDC",
};
const zoomGranularity = 0.25;
const colors = ["white", "red", "yellow"];
const TopoTranslations = {
  Elevation: "ele",
  "NS Grade": "NS",
  "EW Grade": "EW",
  "Univeral Grade": "U",
  "Cut/Fill": "CF",
  Off: "Off",
};

class SunfigMap extends React.Component {
  constructor(props) {
    super(props);
    // REFS
    this.refmaker = null;
    this.setMarkerRef = (element) => {
      this.refmaker = element;
    };

    this.printPlugin = null;
    this.setPrintPlugin = (element) => {
      this.printPlugin = element;
    };

    // STATE
    this.state = {
      center: [39.188, -84.47],
      // map state
      lat: 39.188,
      lng: -84.47,
      search_lat: 34.6964398,
      search_lng: -118.2827397,
      markerVisible: false,
      marker: {
        lat: 34.6964398,
        lng: -118.2827397,
      },
      // If search_moved == true, then don't update search_lat/lng because it's being overridden
      // allow user to reset this within marker popup?
      search_moved: false,
      markerSet: false,
      draggable: true,

      showDims: false,
      showAzi: false,

      clicked: 0,
      dropzoneActive: false,

      zoom: 13,
      tileSet: "Esri_ArcGIS", // 'OpenStreet'

      activeTool: 0,

      isDrawing: false,
      currentDrawingPolygon: undefined,
      isEditing: false,
      curEditingLayer: undefined,

      Polygons: [],
      rackPolygons: [],
      roadPolygons: [],
      menuPolygons: [],
      activePolygon: -1,

      menu_x: 0,
      menu_y: 0,
      menuVisible: false,
      submenuVisible: false,
      menuLegendVisible: false,

      boundary_size_limit: 2.0,
      total_area: 0,

      currentIndex: 0,
      activeIndex: -1,
      logMessage: "",

      takingPic: false,
      showBoundary: true,
      showExclusion: true,
      showInactive: true,
      showPolygons: true,

      PolygonStates: {
        Boundary: true,
        Exclusions: true,
        Inactive: true,
        Layout: true,
      },
      TopoLayerStates: {
        Elevation: false,
        "NS Grade": false,
        "EW Grade": false,
        "Univeral Grade": false,
        "Cut/Fill": false,
        Off: true,
      },
      topo_on: false,
      topo_mode: "Off",

      selectedResults: [],
    };

    // BINDINGS
    this.handleToolbarPressed = this.handleToolbarPressed.bind(this);
    this.handleEditVertex = this.handleEditVertex.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onMouseClick = this.onMouseClick.bind(this);
    this.onContextMenu = this.onContextMenu.bind(this);
  }

  componentDidMount() {
    L.DomUtil.removeClass(document.body, "azimuth-mode");
    L.DomUtil.removeClass(document.body, "dimension-mode");

    // override the leaflet createMarker function to implement our custom icon
    L.Edit.PolyVerticesEdit.prototype._createMarker = function(latlng, index) {
      // Extending L.Marker in TouchEvents.js to include touch.
      let marker = new L.Marker.Touch(latlng, {
        draggable: true,
        icon: new L.Icon({
          iconUrl: vertex_white,
          iconSize: [12, 12],
          iconAnchor: [6, 6],
        }),
      });

      marker._origLatLng = latlng;
      marker._index = index;
      marker
        .on("dragstart", this._onMarkerDragStart, this)
        .on("drag", this._onMarkerDrag, this)
        .on("dragend", this._fireEdit, this)
        .on("touchmove", this._onTouchMove, this)
        .on("touchend", this._fireEdit, this)
        .on("MSPointerMove", this._onTouchMove, this)
        .on("MSPointerUp", this._fireEdit, this);

      this._markerGroup.addLayer(marker);

      return marker;
    };

    // override the leaflet endPoint function to prevent it from happening from middle mouse
    L.Draw.Polygon.prototype._endPoint = function(clientX, clientY, e) {
      e.originalEvent.preventDefault();
      if (this._mouseDownOrigin) {
        let dragCheckDistance = L.point(clientX, clientY).distanceTo(this._mouseDownOrigin);
        let lastPtDistance = this._calculateFinishDistance(e.latlng);
        if (this.options.maxPoints > 1 && this.options.maxPoints == this._markers.length + 1) {
          this.addVertex(e.latlng);
          this._finishShape();
        } else if (lastPtDistance < 10 && L.Browser.touch) {
          this._finishShape();
        } else if (Math.abs(dragCheckDistance) < 9 * (window.devicePixelRatio || 1)) {
          if (e.originalEvent.which != 2) {
            this.addVertex(e.latlng);
          }
        }
        this._enableNewMarkers(); // after a short pause, enable new markers
      }
      this._mouseDownOrigin = null;
    };
    // overide the leaflet updateTooltip function to insert our text directly into lib
    L.Draw.Polygon.prototype._updateTooltip = function(latLng) {
      if (this._markers) {
        if (this._markers.length == 0) {
          // console.log(this._markers.length)
          // text.text = "Click to start drawing";
          let text = this._getTooltipText();
          text.text = "";

          if (latLng) {
            this._tooltip.updatePosition(latLng);
          }

          if (!this._errorShown) {
            this._tooltip.updateContent(text);
          }
        }
      }
    };
    L.Draw.Polygon.prototype._getTooltipText = function() {
      let showLength = this.options.showLength,
        labelText,
        distanceStr;
      if (L.Browser.touch) {
        showLength = false; // if there's a better place to put this, feel free to move it
      }
      if (this._markers.length === 0) {
        labelText = {
          text: "",
        };
      } else {
        distanceStr = showLength ? this._getMeasurementString() : "";

        if (this._markers.length === 1) {
          labelText = {
            text: "",
            subtext: distanceStr,
          };
        } else {
          labelText = {
            text: "",
            subtext: distanceStr,
          };
        }
      }
      return labelText;
    };

    // MEASUREMENTS OVERRIDES

    let override = function(method, fn, hookAfter) {
      if (!hookAfter) {
        return function() {
          let originalReturnValue = method.apply(this, arguments);
          let args = Array.prototype.slice.call(arguments);
          args.push(originalReturnValue);
          return fn.apply(this, args);
        };
      } else {
        return function() {
          fn.apply(this, arguments);
          return method.apply(this, arguments);
        };
      }
    };
    L.Edit.PolyVerticesEdit.include({
      addHooks: override(L.Edit.PolyVerticesEdit.prototype.addHooks, function(originalReturnValue) {
        this.showMeasurements();
        return originalReturnValue;
      }),
      removeHooks: override(L.Edit.PolyVerticesEdit.prototype.removeHooks, function(originalReturnValue) {
        this.hideMeasurements();
        return originalReturnValue;
      }),
      updateMarkers: override(L.Edit.PolyVerticesEdit.prototype.updateMarkers, function(originalReturnValue) {
        this.updateMeasurements();
        return originalReturnValue;
      }),
      _onMarkerDrag: override(L.Edit.PolyVerticesEdit.prototype._onMarkerDrag, function(originalReturnValue) {
        this.updateMeasurements();
        return originalReturnValue;
      }),
      _onMarkerClick: override(L.Edit.PolyVerticesEdit.prototype._onMarkerClick, function(originalReturnValue) {
        this.updateMeasurements();
        return originalReturnValue;
      }),

      showMeasurements: function(options) {
        if (!this._map || this._measurementLayer) return this;

        this._measurementOptions = L.extend(
          {
            // this can be true as well
            imperial: true,
            showOnHover: false,
            minPixelDistance: 30,
            showDistances: true,
            showArea: true,
            lang: {
              totalLength: "Total length",
              totalArea: "Total area",
              segmentLength: "Segment length",
              segmentAzimuth: "Segment Azimuth",
            },
          },
          options || {}
        );

        this._measurementLayer = L.layerGroup().addTo(this._map);
        this.updateMeasurements();

        this._map.on("zoomend", this.updateMeasurements, this);

        return this;
      },
      hideMeasurements: function() {
        if (!this._map) return this;

        this._map.off("zoomend", this.updateMeasurements, this);

        if (!this._measurementLayer) return this;
        this._map.removeLayer(this._measurementLayer);
        this._measurementLayer = null;

        return this;
      },

      updateMeasurements: function() {
        if (!this._measurementLayer) return this;
        let latLngs = this._poly.getLatLngs(),
          isPolygon = true,
          options = this._measurementOptions,
          totalDist = 0,
          formatter,
          ll1,
          ll2,
          p1,
          p2,
          pixelDist,
          dist;
        if (latLngs && latLngs.length && L.Util.isArray(latLngs[0])) {
          // Outer ring is stored as an array in the first element,
          // use that instead.
          latLngs = latLngs[0];
        }

        // check which mode we're in
        let isDimension = L.DomUtil.hasClass(document.body, "dimension-mode");
        let isAzimuth = L.DomUtil.hasClass(document.body, "azimuth-mode");
        // console.log(`isDimension?:${isDimension} - isAzimuth?:${isAzimuth}`)

        this._measurementLayer.clearLayers();
        if (latLngs.length > 1 && (isDimension || isAzimuth)) {
          formatter = L.bind(this.formatDistance, this);
          let sumEdges = 0;
          for (let i = 1, len = latLngs.length; i <= len; i++) {
            ll1 = latLngs[i - 1];
            ll2 = latLngs[i % len];
            let _p1 = this._map.project(ll1),
              _p2 = this._map.project(ll2);

            let sum = (_p2.x - _p1.x) * (_p2.y + _p1.y);
            sumEdges += sum;
          }
          let isClockWise = sumEdges <= 0;

          for (let i = 1, len = latLngs.length; i <= len; i++) {
            ll1 = latLngs[i - 1];
            ll2 = latLngs[i % len];
            dist = ll1.distanceTo(ll2);
            totalDist += dist;

            p1 = this._map.latLngToLayerPoint(ll1);
            p2 = this._map.latLngToLayerPoint(ll2);

            pixelDist = p1.distanceTo(p2);

            if (pixelDist >= options.minPixelDistance) {
              let _p1 = this._map.project(ll1),
                _p2 = this._map.project(ll2);
              let rotation = Math.atan((_p2.y - _p1.y) / (_p2.x - _p1.x));
              let mode = isDimension ? "dimension" : "azimuth";
              let title = isDimension ? options.lang.segmentLength : options.lang.segmentAzimuth;
              let sp = this._map.layerPointToLatLng([(p1.x + p2.x) / 2, (p1.y + p2.y) / 2]);
              L.marker.measurement(sp, formatter(dist), title, rotation, options, _p2.x > _p1.x, _p2.y > _p1.y, mode, isClockWise).addTo(this._measurementLayer);
            }
          }

          // Show total length for polylines
          if (!isPolygon) {
            L.marker.measurement(ll2, formatter(totalDist), options.lang.totalLength, 0, options).addTo(this._measurementLayer);
          }
        }
        return this;
      },

      formatDistance: function(d) {
        let unit, feet;

        if (this._measurementOptions.imperial) {
          feet = d / 0.3048;
          d = feet;
          unit = "ft";
        } else {
          if (d > 1000) {
            d = d / 1000;
            unit = "km";
          } else {
            unit = "m";
          }
        }

        if (d < 100) {
          return d.toFixed(1) + " " + unit;
        } else {
          return Math.round(d) + " " + unit;
        }
      },

      formatArea: function(a) {
        let unit;

        if (this._measurementOptions.imperial) {
          if (a > 404.685642) {
            a = a / 4046.85642;
            unit = "ac";
          } else {
            a = a / 0.09290304;
            unit = "ft²";
          }
        } else {
          if (a > 1000000) {
            a = a / 1000000;
            unit = "km²";
          } else {
            unit = "m²";
          }
        }

        if (a < 100) {
          return a.toFixed(1) + " " + unit;
        } else {
          return Math.round(a) + " " + unit;
        }
      },

      ringArea: function ringArea(coords) {
        let rad = function rad(_) {
          return (_ * Math.PI) / 180;
        };
        let p1,
          p2,
          p3,
          lowerIndex,
          middleIndex,
          upperIndex,
          area = 0,
          coordsLength = coords.length;

        if (coordsLength > 2) {
          for (let i = 0; i < coordsLength; i++) {
            if (i === coordsLength - 2) {
              // i = N-2
              lowerIndex = coordsLength - 2;
              middleIndex = coordsLength - 1;
              upperIndex = 0;
            } else if (i === coordsLength - 1) {
              // i = N-1
              lowerIndex = coordsLength - 1;
              middleIndex = 0;
              upperIndex = 1;
            } else {
              // i = 0 to N-3
              lowerIndex = i;
              middleIndex = i + 1;
              upperIndex = i + 2;
            }
            p1 = coords[lowerIndex];
            p2 = coords[middleIndex];
            p3 = coords[upperIndex];
            area += (rad(p3.lng) - rad(p1.lng)) * Math.sin(rad(p2.lat));
          }

          area = (area * 6378137 * 6378137) / 2;
        }

        return Math.abs(area);
      },
    });

    const jsx = (azimuth) => (
      // PUT YOUR JSX FOR THE COMPONENT HERE:
      <div className="azimuth-holder">
        <img
          id={azimuth}
          src={vertex_white}
          className="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive leaflet-marker-draggable azimuth-holder"
          style={{
            width: "100%",
            height: "20px",
            opacity: "0",
            zIndex: "10000",
          }}
        />
        <div className="azimuth-centered">
          {azimuth}
          <span>°</span>
        </div>
        {/* <div> */}
        {/* <div style={{ color: 'black', textAlign: 'right', right: '5px', position: 'relative' }}>°</div> */}
        {/* </div> */}
        {/* <span>{azimuth}°</span> */}
      </div>
    );

    L.Marker.Measurement = L.Marker.extend({
      options: {
        pane: "markerPane",
      },

      initialize: function(latlng, measurement, title, rotation, options, inside, movingUp, mode, isClockWise) {
        L.setOptions(this, options);
        this._latlng = latlng;
        this._measurement = measurement;
        this._title = title;
        this._inside = inside;
        this._movingUp = movingUp;
        this._rotation = rotation;
        this._rot_def = Math.round(180 + (rotation * 180) / Math.PI).toFixed(0);
        this._mode = mode;
        this._clockwise = isClockWise;
      },

      addTo: function(map) {
        map.addLayer(this);
        return this;
      },

      onAdd: function(map) {
        this._map = map;
        let pane = this.getPane ? this.getPane() : map.getPanes().markerPane;
        let el = (this._element = L.DomUtil.create("div", "leaflet-zoom-animated leaflet-measure-path-measurement", pane));
        el.style["position"] = "absolute";
        el.style["fontSize"] = "12px";
        el.style["color"] = "black";
        el.style["font-weight"] = "bold";
        el.style["white-space"] = "nowrap";
        el.style["transform-origin"] = "0";
        el.style["pointer-events"] = "none";
        el.style["width"] = "40px";
        el.style["text-align"] = "center";

        let inner = L.DomUtil.create("div", "leaflet-interactive", el);
        inner.title = this._title;

        if (this._mode == "dimension") {
          inner.innerHTML = this._measurement;
        } else if (this._mode == "azimuth") {
          inner.innerHTML = this._rot_def;
          ReactDOM.render(jsx(this._rot_def), inner);
        }

        inner.style["position"] = "relative";
        inner.style["background"] = "#ffffffb0";
        inner.style["color"] = "black";
        inner.style["font-weight"] = "bold";

        if (this._inside && !this._movingUp) {
          inner.style["left"] = `${-35}%`;
          inner.style["top"] = !this._clockwise ? "15px" : "-25px";
        } else if (this._inside && this._movingUp) {
          inner.style["left"] = `${-55}%`;
          inner.style["top"] = !this._clockwise ? "15px" : "-25px";
        } else if (!this._inside && !this._movingUp) {
          inner.style["left"] = `${-55}%`;
          inner.style["top"] = !this._clockwise ? "-25px" : "15px";
        } else {
          inner.style["left"] = `${-55}%`;
          inner.style["top"] = !this._clockwise ? "-25px" : "15px";
        }

        map.on("zoomanim", this._animateZoom, this);

        this._setPosition();
      },

      onRemove: function(map) {
        map.off("zoomanim", this._animateZoom, this);
        let pane = this.getPane ? this.getPane() : map.getPanes().markerPane;
        pane.removeChild(this._element);
        this._map = null;
      },

      _setPosition: function() {
        let point = this._map.latLngToLayerPoint(this._latlng);

        L.DomUtil.setPosition(this._element, point);
        this._element.style.transform += " rotate(" + this._rotation + "rad)";
      },

      _animateZoom: function(opt) {
        let pos = this._map._latLngToNewLayerPoint(this._latlng, opt.zoom, opt.center).round();
        L.DomUtil.setPosition(this._element, pos);
        this._element.style.transform += " rotate(" + this._rotation + "rad)";
      },
    });

    L.marker.measurement = function(latLng, measurement, title, rotation, options, inside, movingUp, mode, isClockWise) {
      return new L.Marker.Measurement(latLng, measurement, title, rotation, options, inside, movingUp, mode, isClockWise);
    };

    // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
    Geocode.setApiKey("AIzaSyDFwy0txveMzEYIi42SOSlD2hwq9h9Xqb8");
    // Enable or disable logs. Its optional.
    Geocode.enableDebug();

    // Event listeners
    L.DomEvent.addListener(document, "keydown", this.onKeyDown, this.refs.map.leafletElement);
    L.DomEvent.addListener(document, "mousedown", this.onMouseClick, this.refs.map.leafletElement);
    L.DomEvent.addListener(document, "contextmenu", this.onContextMenu, this.refs.map.leafletElement);

    // add extra control placeholders for our menu and log
    this.addControlPlaceholders();

    this.setPrintPlugin(
      L.easyPrint({
        hidden: true,
        hideControlContainer: true,
        exportOnly: true,
        sizeModes: ["Current", "A4Portrait", "A4Landscape"],
        // 'leaflet-bottom leaflet-right'
        hideClasses: ["leaflet-top leaflet-right", "leaflet-top leaflet-left"],
      }).addTo(this.refs.map.leafletElement)
    );

    // this.props.OnSaveMapRef(this.refs.map)
    // console.log(this.refs.map.leafletElement)
  }

  // componentDidUpdate(prevProps, prevState) {
  //   const { selectedFeatureId } = prevProps;

  //   if (this.props.selectedFeatureId != selectedFeatureId) {
  //     // something new was selected
  //     // console.log('selectedFeatureId', this.props.selectedFeatureId);
  //     if (selectedFeatureId) this.stopEditing(selectedFeatureId);
  //     if (this.props.selectedFeatureId) this.startEditing(this.getLayer(this.props.selectedFeatureId));
  //   }
  // }

  componentDidUpdate(props) {
    const { force_zoom, resize, features, selectedFeatureId } = props;
    // update selectedResults
    // if (selectedResult && selectedResult !== this.props.selectedResult) {
    //   // this.setState({selectedResults:selectedResult})
    //   let fixedResults = this.getFixedResult(selectedResult);

    //   this.setState({
    //     selectedResults: fixedResults
    //   });
    // }
    if (this.props.selectedFeatureId != selectedFeatureId) {
      // something new was selected
      // console.log('selectedFeatureId', this.props.selectedFeatureId);
      if (selectedFeatureId) this.stopEditing(selectedFeatureId);
      if (this.props.selectedFeatureId) this.startEditing(this.getLayer(this.props.selectedFeatureId));
    }

    if (resize !== this.props.resize) {
      this.refs.map.leafletElement.invalidateSize();
    }

    if (features !== this.props.features && force_zoom !== this.props.force_zoom) {
      console.log("zooming", this.props.features.length);
      if (this.props.features.length > 0) {
        let first_coord = this.props.features[0].geometry.coordinates[0][0];

        this.props.dispatch(portalActions.getCounty(first_coord[1], first_coord[0]));
      }
      // 0: -104.699364 lng min
      // 1: 39.760791 lat min
      // 2: -104.677367 lng max
      // 3: 39.769983 lng min
      setTimeout(() => {
        let bounds = getBounds(this.props.features);
        this.refs.map.leafletElement.fitBounds([
          [bounds[1], bounds[0]],
          [bounds[3], bounds[2]],
        ]);
      }, 300);
    }

    // //
    // if (LoadedFeatures !== this.props.LoadedFeatures || (LoadedFeatures && reset !== this.props.reset)) {
    //   // We need to fix the indexes so they will force the GeoJSON component to re-render
    //   if (LoadedFeatures.length == 0) {
    //     return;
    //   }
    //   let index = this.state.currentIndex;
    //   let newFeatures = [];
    //   // default lat/lng - change to whatever the lat/lon currently is - so only update center if features.length >0
    //   let center = [34.6964398, -118.2827397];
    //   let total_area = 0;
    //   for (let poly in LoadedFeatures) {
    //     LoadedFeatures[poly].properties.index = index;
    //     index++;
    //     let area = this.getArea(LoadedFeatures[poly].geometry.coordinates);
    //     total_area += area;
    //     newFeatures.push(LoadedFeatures[poly]);
    //   }

    //   if (LoadedFeatures.length > 0) {
    //     let featCollection = {
    //       type: 'FeatureCollection',
    //       features: newFeatures
    //     };
    //     let centeroid = turf.centroid(featCollection);
    //     center = [centeroid.geometry.coordinates[1], centeroid.geometry.coordinates[0]];
    //     this.setState({
    //       lat: center[0],
    //       lng: center[1],
    //       search_lat: center[0],
    //       search_lng: center[1]
    //     });
    //   }

    //   this.setState(
    //     {
    //       Polygons: newFeatures,
    //       activeTool: 0,
    //       currentIndex: index,
    //       total_area: total_area,
    //       showPolygons: true
    //     },
    //     () => {
    //       this.props.OnCenterUpdated(center);
    //       this.props.OnPolygonUpdated(newFeatures);
    //       if (newFeatures.length > 0) this.handleZoomExtents(-1);
    //       this.calculateTotalArea();
    //     }
    //   );
    // }
  }

  addControlPlaceholders() {
    // Setup the map the way we want it
    let corners = this.refs.map.leafletElement._controlCorners,
      l = "leaflet-",
      container = this.refs.map.leafletElement._controlContainer;

    function createCorner(vSide, hSide) {
      let className = l + vSide + " " + l + hSide;
      corners[vSide + hSide] = L.DomUtil.create("div", className, container);
    }

    createCorner("control", "menu");
  }

  // add the zoom amount to our current zoom
  handleZoom(_zoom) {
    this.props.OnUpdateZoom(_zoom);
    this.setState({ zoom: _zoom });
  }
  // groups all the polygons in all layers, then fitsBounds
  handleZoomExtents() {
    //  TODO: Add this back after I've added all the proper event hooks
    // if (!this.props.displaySurface && forced > 0) return;
    if (this.props.features.length == 0) return;

    let bounds = getBounds(this.props.features);
    this.refs.map.leafletElement.fitBounds([
      [bounds[1], bounds[0]],
      [bounds[3], bounds[2]],
    ]);
    // let bounds = L.latLngBounds();
    // this.refs.map.leafletElement.eachLayer(layer => {
    //   if (layer instanceof L.Polygon) {
    //     bounds.extend(layer.getBounds());
    //   }
    // });
    // this.refs.map.leafletElement.fitBounds(bounds);
  }

  updateIdentity = (identity, feature = undefined, e = undefined) => {
    if (e) {
      e.nativeEvent.view.L.DomEvent.stopPropagation(e);
      e.nativeEvent.preventDefault();
    }

    if (feature == undefined) {
      feature = this.props.features.find((f) => f.properties.index == this.props.selectedFeatureId);
    }

    feature.properties.identity = identity;
    if (identity == 0) feature.properties.active = !feature.properties.active;
    else feature.properties.active = true;

    let layer = this.getLayer(feature.properties.index);
    layer.setStyle({ color: colors[identity] });

    this.props.OnPolygonUpdated(feature);
  };

  handleToggleDims = () => {
    let checked = !this.state.showDims;
    this.setState({ showDims: checked, showAzi: checked ? false : this.state.showAzi }, () => {
      if (checked) {
        L.DomUtil.addClass(document.body, "dimension-mode");
        L.DomUtil.removeClass(document.body, "azimuth-mode");
      } else {
        L.DomUtil.removeClass(document.body, "dimension-mode");
      }
      if (this.props.selectedFeatureId) {
        let layer = this.state.curEditingLayer;
        this.stopEditing(layer);
      }
    });
  };

  handleToggleAzi = () => {
    let checked = !this.state.showAzi;
    this.setState({ showAzi: checked, showDims: checked ? false : this.state.showDims }, () => {
      if (checked) {
        L.DomUtil.addClass(document.body, "azimuth-mode");
        L.DomUtil.removeClass(document.body, "dimension-mode");
      } else {
        L.DomUtil.removeClass(document.body, "azimuth-mode");
      }
      if (this.props.selectedFeatureId) {
        let layer = this.state.curEditingLayer;
        this.stopEditing(layer);
      }
    });
  };

  // Toolbar reaction functions that effect the map
  handleToolbarPressed(option, data = null, event = null) {
    // if data is not null, then it's a SUBMENU press
    let submenu_click = data != null;
    let close_click = option == this.state.activeTool && !submenu_click;
    let _tool = close_click ? 0 : option;
    // console.log(option, data, submenu_click, close_click, _tool)
    this.setState({ activeTool: _tool }, () => {
      if (!close_click) {
        this.setState({ activeTool: option }, () => {
          /*  
            , menuVisible: false
              option 1 = Search location
              option 2 = Layers menu (tilesets + polygon layer select)
              option 3 = Draw polygon
              option 4 = Delete all
              option 5 = Export PNG
          */
          switch (option) {
            case 1: // Search for location
              this.cancelDraw();
              // do something
              break;

            case 2: // Toggle tile set
              // console.log(data)
              // this.setState({ tileSet: this.state.tileSet == 'ArcGIS' ? 'OpenStreet' : 'ArcGIS' });
              if (data) this.setState({ tileSet: data });
              break;

            case 3: // Draw a polygon
              // do something
              this.handleDraw();
              break;

            case 4: // Trash all polygons
              // JUST DELETE THIS ARRAY
              this.cancelDraw();
              this.stopEditing();
              this.props.OnPolygonUpdated([], true);

              break;

            case 5: // Export map
              this.cancelDraw();
              if (this.props.features.length > 0) this.handleExportKML();
              // do something
              break;

            case 6: // toggle topo
              if (data) {
                let new_topo_layer = this.state.TopoLayerStates;
                new_topo_layer[this.state.topo_mode] = !new_topo_layer[this.state.topo_mode];
                new_topo_layer[data] = !new_topo_layer[data];
                this.setState({
                  topo_on: data != "Off",
                  TopoLayerStates: new_topo_layer,
                  topo_mode: data,
                });
                this.props.OnUpdateTopoMode(TopoTranslations[data]);
              }

              break;
          }
        });
      }
    });
  }

  // either starts a polygon.draw or ends the current polygon.draw
  handleDraw() {
    if (!this.state.isDrawing) {
      let options = {
        shapeOptions: {
          stroke: true,
          color: "white",
          opacity: 1.0,
          weight: 4,
          fillColor: "#FFFFFF",
        },
        icon: new L.Icon({
          iconUrl: vertex_green,
          iconSize: [16, 16],
          iconAnchor: [7, 7],
        }),
        showMeasurements: this.state.showDims,
      };

      let polygon = new L.Draw.Polygon(this.refs.map.leafletElement, options);

      polygon.enable();

      this.setState({
        isDrawing: true,
        currentDrawingPolygon: polygon,
        menuVisible: false,
      });
    } else {
      this.handleToolbarPressed(0);
      this.cancelDraw();
    }
  }
  cancelDraw = () => {
    if (this.state.isDrawing) {
      this.state.currentDrawingPolygon.disable();
      this.setState({
        isDrawing: false,
        currentDrawingPolygon: undefined,
        menuVisible: false,
      });
    }
  };

  getCenterPoint = (coords) => {
    let turfPoly = turf.polygon(coords);
    let centroid = turf.centroid(turfPoly);
    return centroid;
  };
  getArea = (coords) => {
    let turfPoly = turf.polygon(coords);
    return turf.area(turfPoly) / 1000000;
  };
  calculateTotalArea = () => {
    let total_area = 0;
    this.refs.map.leafletElement.eachLayer((layer) => {
      if (layer.options && layer.options.interactive && layer.options.data) {
        let poly = layer.toGeoJSON();
        if (poly.properties.identity == 1) {
          total_area += this.getArea(poly.geometry.coordinates);
        }
      }
    });
    this.setState({ total_area: total_area });
    this.props.OnUpdateArea(total_area);
  };
  create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }
  getLayer = (id) => {
    if (this.refs[id]) {
      let leaflet_index = this.refs[id].leafletElement._leaflet_id - 1;
      return this.refs[id].leafletElement._layers[leaflet_index];
    } else {
      return undefined;
    }
  };
  // called from leaflet when a polygon is finished drawing
  handlePolygonCreated(e) {
    // CHECK THE SIZE AGAINST LIMITS TO STOP USER FROM FINISHING POLYGON IF IT'S TOO LARGE

    // isDrawing is true here, so call handleDraw to reset drawing
    // this.handleDraw();

    // let featCollection = {
    //   type: 'FeatureCollection',
    //   features: [...this.state.Polygons, poly]
    // };
    // let centeroid = turf.centroid(featCollection);
    // let center = [centeroid.geometry.coordinates[1], centeroid.geometry.coordinates[0]];
    // let all_indexes = [];
    // let index = 0;
    // if (this.props.features.length > 0) {
    //   this.props.features.forEach(feature => {
    //     all_indexes.push(feature.properties.index)
    //   })
    //   index = Math.max(...all_indexes)+1
    // }

    let new_id = this.create_UUID();
    let latlngs = e.layer.getLatLngs()[0];
    let coords = [];
    latlngs.forEach((latlng) => {
      let wrapped = latlng.wrap();
      coords.push([wrapped.lat, wrapped.lng]);
    });
    coords.push(coords[0]);
    let real_poly = turf.flip(turf.polygon([coords]));

    let poly = e.layer.toGeoJSON();
    poly.properties["index"] = new_id;
    poly.properties["identity"] = 1;
    poly.properties["active"] = true;
    poly.properties["area"] = this.getArea(poly.geometry.coordinates);
    poly.properties["center"] = turf.getCoords(turf.centroid(real_poly));

    // let fixed_coords = []
    // poly.geometry.coordinates[0].forEach(coord => {
    //   fixed_coords.push([coord[1],coord[0]])
    // })
    // poly.geometry.coordinates[0] = fixed_coords;

    // this.handleToolbarPressed(0);

    // Update state - Map uses this.state.Polygons to create editable polygons
    // this.setState({
    //   // Polygons: [...this.state.Polygons, poly],
    //   currentIndex: this.state.currentIndex + 1,
    //   activeIndex: 0,
    //   search_lat: center[0],
    //   search_lng: center[1]
    // });
    // this.stopEditing();

    // this.props.OnCenterUpdated([center[0], center[1]]);
    this.props.OnPolygonUpdated(poly);
    this.refs.map.leafletElement.removeLayer(e.layer);
    this.setState({ activeTool: 0, menuVisible: false, isDrawing: false });

    // setTimeout( () => {
    //   // Remove this layer - we have a different styling for creating polygons

    //   this.startEditing(this.getLayer(poly));
    //   this.refs.map.leafletElement.removeLayer(e.layer);

    // }, 200)
  }
  handleDeleteFeature = (feature) => {
    this.props.OnPolygonRemoved(feature);
    this.setState({ menuVisible: false });
  };

  // Handles user clicking on surface in leaflet map
  handleLayerClicked(e, id) {
    if (this.props.selectedFeatureId == id) return;
    // finish editing if applicable
    this.stopEditing();
    // start editing this layer
    this.startEditing(e.layer);
    // prevent trying to select the same rectangle

    this.props.dispatch(portalActions.selectFeature(id));

    // stop other mouse events on leaflet map
    if (e.originalEvent) e.originalEvent.view.L.DomEvent.stopPropagation(e);
    if (e.nativeEvent) e.nativeEvent.view.L.DomEvent.stopPropagation(e);
  }

  // Editing functions
  stopEditing(id = undefined) {
    let polyId = id || this.props.selectedFeatureId;

    let layer = this.getLayer(polyId);

    if (layer) layer.editing.disable();

    if (this.props.selectedFeatureId) this.props.dispatch(portalActions.selectFeature(undefined));

    this.setState({ isEditing: false });

    // if (id) {
    //   // id provided, disable it
    //   let layer = this.getLayer(id);
    //   if (layer) layer.editing.disable();
    //   this.setState({ isEditing: false });
    // } else if (this.props.selectedFeatureId) {
    //   let layer = this.getLayer(this.props.selectedFeatureId);
    //   if (layer) layer.editing.disable();
    //   // if something is selected, deselect it
    //   this.props.dispatch(portalActions.selectFeature(undefined));
    //   this.setState({ isEditing: false });
    // }

    // if (this.state.isDrawing) {
    //   return;
    // }

    // if (this.state.curEditingLayer) {
    //   this.handleEdit(this.state.curEditingLayer);
    //   this.state.curEditingLayer.editing.disable();
    //   this.unhighlightLayer(this.state.curEditingLayer);
    //   this.setState({ isEditing: false, activeTool: 0, menuVisible: false }, () => {
    //     if (layer) {
    //       this.startEditing(layer);
    //     }
    //   });
    // }
    // this.setState({ curEditingLayer: undefined, activeTool: 0 });
  }

  //
  startEditing(layer) {
    if (layer == undefined) return;
    layer.editing.enable();
    // layer.transform.enable();
    // layer.dragging.enable();
    this.setState({ curEditingLayer: layer, isEditing: true });
    // only do this is not editing already and the map has this layer
    // if (!this.props.selectedFeatureId && this.refs.map.leafletElement.hasLayer(layer)) {
    //   this.setState({
    //     isEditing: true,
    //     isDrawing: false,
    //     menuVisible: false,
    //     activeTool: 0,
    //     activeIndex: layer.feature.properties.index,
    //     curEditingLayer: layer,
    //     activePolygon: this.props.features.findIndex(p => p.properties.index == layer.feature.properties.index)
    //   });
    //   // enable editing
    //   layer.editing.enable();
    // } else {
    //   this.stopEditing();
    // }
  }

  //
  unhighlightLayer = (poly = undefined) => {
    if (poly) {
      let layer = this.getLayer(poly.properties.index);
      if (layer) layer.setStyle({ weight: 1, fillOpacity: 0.01, fillColor: "#ffffff" });
    } else if (this.state.highlightPoly) {
      this.state.highlightPoly.setStyle({
        weight: 1,
        fillOpacity: 0.01,
        fillColor: "#ffffff",
      });
    }
  };
  //
  highlightLayer = (poly) => {
    let layer = this.getLayer(poly.properties.index);
    if (layer) layer.setStyle({ weight: 3, fillOpacity: 0.5, fillColor: "#60de4f" });
    this.setState({ highlightPoly: poly });
  };

  // called when finished editing a polygon
  handleEdit(layer) {
    // let layerIndex = this.state.Polygons.findIndex(p => p.properties.index == layer.options.data.properties.index);
    // let polys = this.state.Polygons;
    // polys[layerIndex] = layer.toGeoJSON();
    // this.setState({ Polygon: polys }, () => {
    //   this.props.OnPolygonUpdated(this.state.Polygons);
    //   // this.forceUpdate();
    // });
  }

  breakMultiPolygon(mp) {
    let polys = [];
    mp[0].map((poly) => {
      polys.push(poly);
    });
    return polys;
  }

  //
  _onDrawVertex() {
    let options = {
      shapeOptions: {
        stroke: true,
        color: "white",
        opacity: 1.0,
        weight: 4,
        fillColor: "#FFFFFF",
      },
      icon: new L.Icon({
        iconUrl: vertex_white,
        iconSize: [12, 12],
        iconAnchor: [6, 6],
      }),
    };
    this.state.currentDrawingPolygon.setOptions(options);
  }
  // event called after editing a polygon's vertex.. need to make sure they stay within size limits
  handleEditVertex(e) {
    // console.log(e.poly.toGeoJSON())
    // // the current feature... don't need this we have it in state
    // let feature = e.poly.editing._poly.feature;
    // let latlngs = e.poly.editing.latlngs[0][0];
    // let coords = [];
    // for (let p in latlngs) {
    //   coords.push([latlngs[p]['lng'], latlngs[p]['lat']]);
    // }
    // // append the first point to complete the polygon.
    // coords.push([latlngs[0]['lng'], latlngs[0]['lat']]);

    // // get new area
    // let area = this.getArea([coords]);

    // let index = feature.properties.index;
    // let real_index = this.state.Polygons.findIndex(poly => poly.properties.index == index);

    // this.calculateTotalArea();

    // // TODO: fix this direct mutation?
    // // eslint-disable-next-line react/no-direct-mutation-state
    // this.state.Polygons[real_index].properties.area = area;
    // this.forceUpdate();
    let updated_layer = e.poly.toGeoJSON();
    this.props.OnPolygonUpdated(updated_layer);
  }

  handleUndoVertex() {
    this.state.currentDrawingPolygon.deleteLastVertex();
  }

  handleSearch(value) {
    if (value.length < 3) {
      return;
    }
    // search for the value
    Geocode.fromAddress(value).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setState({ lat: lat, lng: lng, zoom: this.state.zoom });
        this.props.OnMapSearch([lat, lng]);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  onKeyDown(e) {
    if (this.props.project_visible || this.props.bugReportVisible) {
      return;
    }

    let keyCode = e.keyCode || e.which;

    if (keyCode == 68) {
      // if search bar is active ignore keypress 'D'
      if (this.state.activeTool === 1) return;
      // initiate draw tool when 'D' is pressed
      this.handleToolbarPressed(3);
    }

    if (keyCode == 27) {
      // ESC key hit - clear selected toolbar
      this.unhighlightLayer();
      if (this.props.selectedFeatureId) this.stopEditing();
      if (this.state.isDrawing) this.cancelDraw();
      this.handleToolbarPressed(0);
      return;
    }

    if (this.props.selectedFeatureId) {
      if (keyCode == 66) {
        // B pressed - set as boundary
        this.updateIdentity(1);
      }
      if (keyCode == 69) {
        // E pressed - set as exclusion
        this.updateIdentity(2);
      }
      if (keyCode == 73) {
        // I pressed - set as inactive
        this.updateIdentity(0);
      }
      if (keyCode == 78) {
        // N key pressed - cycle through boundaries
        if (this.props.features.length > 1) {
          // let ids = Object.keys(this.props.map.features)
          let current_index = this.props.features.findIndex((f) => f.properties.index == this.props.selectedFeatureId);
          let next_index = current_index + 1 == this.props.features.length ? 0 : current_index + 1;
          this.props.dispatch(portalActions.selectFeature(this.props.features[next_index].properties.index));
        }
      }
      if (keyCode == 46) {
        // delete key pressed while selected
        // let poly = this.props.features.find(f=>f.properties.index = this.props.selectedFeatureId)
        this.props.OnPolygonRemoved(this.props.selectedFeatureId);
        // this.stopEditing();
        // let poly = this.props.features.find(p => p.properties.index == index);
        // this.stopEditing();
        // this.setState({ Polygons: polys }, () => {
        //   this.calculateTotalArea();
        //   this.props.OnPolygonUpdated(polys);
        // });
      }
    }

    if (!this.state.isDrawing) return;

    if (keyCode == 90) {
      if (this.state.currentDrawingPolygon == "undefined") return;
      if (e.ctrlKey) {
        this.state.currentDrawingPolygon.deleteLastVertex();
      }
    }
  }
  onMouseClick(e) {
    if (e.which == 1 && this.state.menuVisible && e.target.className && !e.target.classList.contains("ant-menu-item")) {
      this.setState({ menuVisible: false });
      return false;
    }

    if (e.which == 2) {
      e.view.L.DomEvent.stopPropagation(e);
      e.preventDefault();
      if (this.props.features.length > 0 && !this.state.isDrawing) {
        let click = Date.now();
        let diff = click - this.state.clicked;
        this.setState({ clicked: click });
        if (diff < 200) {
          this.handleZoomExtents();
        }
      }
      return false;
    }

    return true;
  }
  onContextMenu(e) {
    let map = document.getElementById("map");
    let menuHeight = 100;
    let menuWidth = 140;
    //pollyfill
    let path = e.path || (e.composedPath && e.composedPath());
    // determine area clicked for context
    const insideBLC = path.find(function(element) {
      return element.className === "leaflet-bottom leaflet-left";
    });

    // control panel display col menu
    if (e.which === 3 && insideBLC) {
      // stop default context menu
      e.view.L.DomEvent.stopPropagation(e);
      e.preventDefault();

      // generate our html string
      let html = "";
      this.props.selectedResult.forEach((item) => {
        // see if we have formatting
        const formatEntry = selectedResultFormat.keysformats.find((entry) => {
          return entry.key === item.key;
        });
        html = `${html}
        <div class="form-check">
            <input class="form-check-input" type="checkbox" ${item.hide ? "" : "checked"} id="checkbox_${item.key}">
            <label class="form-check-label" for="checkbox_${item.key}">
                ${formatEntry && formatEntry.displayLabel ? formatEntry.displayLabel : item.key}
            </label>
        </div>
        `;
      });

      Swal.fire({
        title: "Select Display Columns",
        html: html,
        focusConfirm: false,
        preConfirm: () => {
          let newSelectedResults = [];
          this.props.selectedResult.map((item) => {
            const hide = document.getElementById(`checkbox_${item.key}`).checked !== true;
            const filter2Edit = {};
            item.hide = hide;
            const adjustedFilter = Object.assign(filter2Edit, item);
            newSelectedResults.push(adjustedFilter);
          });

          // console.log(newSelectedResults)
          this.setState({ selectedResults: newSelectedResults });
        },
      });
      return false;
    }
    // normal map context menu
    else if (e.which === 3 && !insideBLC) {
      let openSunfigMenu = false;
      let clickedPolygon = false;
      let azimuth = 0;

      if (e.target.classList.contains("azimuth-holder")) {
        openSunfigMenu = true;
        azimuth = parseInt(e.target.id);
      }
      this.setState({ selectedAzimuth: azimuth });

      if (e.target.nodeName == "DIV") {
        if (e.target.className.includes("leaflet")) {
          openSunfigMenu = true;
        }
      }

      if (e.target.nodeName == "path") {
        if (e.target.outerHTML.includes("leaflet")) {
          openSunfigMenu = true;
          clickedPolygon = true;
        }
      }

      if (openSunfigMenu) {
        menuHeight = 125;
        if (clickedPolygon && this.refs.map) {
          // get latlng of mouse click
          let latlng = this.refs.map.leafletElement.mouseEventToLatLng(e);
          // turn into bounds
          let clickBounds = L.latLngBounds(latlng, latlng);
          let intersectingLayers = [];

          this.props.features.forEach((feature) => {
            var featureBounds = this.getLayer(feature.properties.index).getBounds();
            if (featureBounds.contains(clickBounds)) {
              intersectingLayers.push(feature);
            }
          });

          this.setState({ menuPolygons: intersectingLayers });
          this.forceUpdate();
        } else {
          this.setState({ menuPolygons: [] });
        }

        e.view.L.DomEvent.stopPropagation(e);
        e.preventDefault();
        // let x = e.layerX;
        // let y = e.layerY;
        let mapWidth = map.clientWidth;
        let mapHeight = map.clientHeight;
        let menuXPos = e.clientX;
        let menuYPos = e.clientY;

        if (menuYPos + menuHeight > mapHeight) {
          menuYPos = menuYPos - menuHeight;
        }

        if (menuXPos + menuWidth > mapWidth) {
          menuXPos = menuXPos - menuWidth;
        }

        this.setState({
          menu_x: menuXPos,
          menu_y: menuYPos,
          menuVisible: !this.state.menuVisible,
        });
        return false;
      }
    }
    return false;
  }

  // Marker functions
  handleUpdateMarker = () => {
    let mark = this.refs.map.leafletElement.getBounds().getCenter();
    this.setState({
      marker: mark,
      markerVisible: true,
      markerSet: true,
    });
    this.props.OnMarkerUpdated(mark);
  };
  toggleDraggable = () => {
    this.setState({ draggable: !this.state.draggable });
  };
  updatePosition = () => {
    const marker = this.refmarker.current;
    if (marker != null) {
      this.setState({
        marker: marker.leafletElement.getLatLng(),
      });
    }
  };

  handleExportKML = () => {
    let layers = [];
    this.refs.map.leafletElement.eachLayer((layer) => {
      let feature = (layer.feature = layer.feature || {});
      feature.type = "Feature";
      feature.properties = feature.properties || {};
      if (layer.options && layer.options.interactive && layer.options.data) {
        // Boundary or Exclusion
        if (layer.options.data.properties.identity == 1) {
          // Boundary
          feature.name = "Boundary";
          feature.properties["title"] = "Boundary";
          feature.properties["fill-opacity"] = 0.0;
          feature.properties["fill"] = "#000000";
          feature.properties["stroke-width"] = 3;
          feature.properties["stroke"] = "#ff0001";
        } else if (layer.options.data.properties.identity == 2) {
          // Exclusion
          feature.name = "Exclusion";
          feature.properties["title"] = "Exclusion";
          feature.properties["fill-opacity"] = 0.0;
          feature.properties["fill"] = "#000000";
          feature.properties["stroke-width"] = 3;
          feature.properties["stroke"] = "ffff03";
        } else if (layer.options.data.properties.identity == 0) {
          // Boundary
          feature.name = "Boundary-Unused";
          feature.properties["title"] = "Boundary-Unused";
          feature.properties["fill-opacity"] = 0.0;
          feature.properties["fill"] = "#000000";
          feature.properties["stroke-width"] = 3;
          feature.properties["stroke"] = "#ffffff";
        }
        layers.push(layer);
      } else {
        if (layer.options.id < 0) {
          if (layer.options.id == -999) {
            feature.name = "Autolayout";
            // Autolayout
            feature.properties["title"] = "Autolayout";
            Object.keys(this.state.selectedResults).forEach((key) => {
              feature.properties[key] = this.state.selectedResults[key];
            });
            feature.properties["fill-opacity"] = 0.0;
            feature.properties["fill"] = "#000000";
            feature.properties["stroke-width"] = 3;
            feature.properties["stroke"] = "#32a2ff";
          } else {
            // Road
            feature.properties["title"] = "Road";
            feature.properties["description"] = "This is an auto placed road";
            feature.properties["fill-opacity"] = 0.0;
            feature.properties["fill"] = "#000000";
            feature.properties["stroke-width"] = 3;
            feature.properties["stroke"] = "#e07111";
          }
          if (layer._latlngs.length > 1) {
            layers.push(layer);
          }
        }
      }
    });

    let allLayers = L.layerGroup(layers);
    let featureCollection = allLayers.toGeoJSON();

    let kml = tokml(featureCollection, {
      documentName: "SIFT KML Export",
      documentDescription: "Export from SIFT Map",
      simplestyle: true,
    });

    let blob = new Blob([kml], { type: "xml;encoding=UTF-8" });
    FileSaver.saveAs(blob, `RBI-Portal-Map-${this.props.project_name}.kml`);
  };
  printMap = async () => {
    this.setState({ menuVisible: false, takingPicture: true }, () => {
      //   this.forceUpdate();
      //   setTimeout(() => {
      //     this.printPlugin.printMap('CurrentSize', `RBI-Portal-Export-${this.props.project_name}`);
      //   }, 100);
      let width = this.refs.map.leafletElement._size.x;
      let height = this.refs.map.leafletElement._size.y;
      var node = document.getElementById("leaflet-map");
      domtoimage.toBlob(node, { width, height }).then(
        function(blob) {
          window.saveAs(blob, `RBI-Portal-Export-${this.props.project_name}.png`);
          this.setState({ takingPicture: false });
        }.bind(this)
      );
    });

    // setTimeout(
    //   function() {
    //     this.setState({ takingPic: false });
    //   }.bind(this),
    //   2000
    // );
  };
  printMapNow() {
    // let a3Size = {
    //   width: 1240,
    //   height: 874,
    //   className: 'a3CssClass',
    //   tooltip: 'A custom A3 size'
    // };
    // let printPlugin = L.easyPrint({
    //   hidden: true,
    //   hideControlContainer: true,
    //   exportOnly: true,
    //   sizeModes: ['Current', 'A4Portrait', 'A4Landscape', a3Size],
    //   hideClasses: ['leaflet-top leaflet-right', 'leaflet-top leaflet-left', 'leaflet-bottom leaflet-right']
    // }).addTo(this.refs.map.leafletElement);
    // console.log(this.printPlugin)
    this.printPlugin.printMap("CurrentSize", `RBI-Portal-Export-${this.props.project_name}`);
  }

  handleToggleSurfaces = () => {
    this.setState({ showPolygons: !this.state.showPolygons });
  };
  handleTogglePolygon = (key) => {
    let poly_set = this.state.PolygonStates;

    // flip the toggle
    poly_set[key] = !poly_set[key];
    this.setState({ PolygonStates: poly_set });
  };
  handleToggleTopoLayer = (key) => {
    let new_topo_layer = this.state.TopoLayerStates;
    new_topo_layer[this.state.topo_mode] = !new_topo_layer[this.state.topo_mode];
    new_topo_layer[key] = !new_topo_layer[key];
    this.setState({
      topo_on: key != "Off",
      TopoLayerStates: new_topo_layer,
      topo_mode: key,
    });
    this.props.OnUpdateTopoMode(TopoTranslations[key]);
  };

  handleSetAzimuth = () => {
    if (!isNaN(this.state.selectedAzimuth)) {
      this.setState({ menuVisible: false });
      this.props.OnSetAzimuth(this.state.selectedAzimuth);
    }
  };

  //
  render() {
    const position = this.props.center;
    const { menu_x, menu_y } = this.state;
    const labelStyle2 = {
      display: "block",
      position: "relative",
      paddingLeft: "75px" /* adjust if necessary */,
      marginBottom: "0px",
    };
    const spanStyle = {
      position: "absolute",
      left: 0,
    };
    const menuStyle = {
      // ...style,
      left: menu_x,
      top: menu_y + 1,
      opacity: 1,
      position: "fixed",
    };

    // create topo_bounds if available
    var southWest = this.props.boundary_bbox && L.latLng(this.props.boundary_bbox[1], this.props.boundary_bbox[0]),
      northEast = this.props.boundary_bbox && L.latLng(this.props.boundary_bbox[3], this.props.boundary_bbox[2]),
      boundary_bbox = this.props.boundary_bbox && L.latLngBounds(southWest, northEast);

    const selectedFeature = this.props.features.find((f) => f.properties.index == this.props.selectedFeatureId);

    return (
      <Map
        showDims={this.state.showDims}
        animate={true}
        // useFlyTo={true}
        attributionControl={false}
        // center={position}
        noWrap={true}
        center={position}
        zoom={this.props.zoom}
        minZoom={2}
        maxZoom={19.25}
        zoomControl={false}
        ref="map"
        id="leaflet-map"
        onClick={() => {
          this.stopEditing();
        }}
        onzoomend={(e) => {
          this.handleZoom(e.target._zoom);
        }}
        zoomSnap={zoomGranularity}
        zoomDelta={zoomGranularity}
        style={{ height: "100%", width: "100%" }}
      >
        {!this.state.takingPicture && this.props.topo_live && this.props.topo_mode != "Off" && (
          <TileLayer
            // url='http://topo.xyz.s3-website.us-east-2.amazonaws.com/test/e5f0ab4e3c1c45d9b38412126ef43b83/{z}/{x}/{y}.png'
            url={this.props.topo_url}
            opacity={0.7}
            tms
            zIndex={10}
            bounds={boundary_bbox}
            errorTileUrl="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
            crossOrgin
          />
        )}

        <TileLayer url={TileSets[this.state.tileSet]} />

        <FeatureGroup
          ref={(group) => {
            this.featureGroup = group;
          }}
        >
          <EditControl
            position="bottomright"
            onCreated={(e) => {
              this.handlePolygonCreated(e);
            }}
            onDrawVertex={() => {
              this._onDrawVertex();
            }}
            onEditVertex={(e) => this.handleEditVertex(e)}
            draw={{
              polygon: false,
              polyline: false,
              rectangle: false,
              circle: false,
              marker: false,
              circlemarker: false,
            }}
            edit={{
              featureGroup: this.featureGroup,
              remove: false,
              edit: false,
            }}
          />
          {/* Boundary */}

          <LayerGroup>
            {this.props.features.map((poly, index) => {
              if (!this.state.PolygonStates.Boundary && poly.properties.identity == 1) return;
              if (!this.state.PolygonStates.Exclusions && poly.properties.identity == 2) return;
              if (!this.state.PolygonStates.Inactive && poly.properties.identity == 0) return;
              return (
                <GeoJSON
                  data={poly}
                  ref={poly.properties.index}
                  key={poly.properties.index}
                  style={{
                    fillColor: "#ffffff",
                    fillOpacity: 0.01,
                    weight: 1,
                    color: poly.properties.identity == 0 ? "white" : poly.properties.identity == 1 ? "red" : poly.properties.identity == 2 ? "yellow" : "white",
                  }}
                  onClick={(e) => {
                    this.handleLayerClicked(e, poly.properties.index);
                  }}
                ></GeoJSON>
              );
            })}
          </LayerGroup>

          {/* Racks */}
          {this.props.layout.isLayout && (
            <LayerGroup>
              {this.state.PolygonStates.Layout && <Polygon positions={this.props.layout.racks.geometry.coordinates} key={599} id={-999} color="#33A2FF" fillColor="none" weight={1} />}
            </LayerGroup>
          )}

          {/* Roads */}
          {this.props.layout.isLayout && (
            <LayerGroup>
              {this.state.PolygonStates.Layout && <Polygon positions={this.props.layout.roads.geometry.coordinates} key={598} id={-998} color="#e0710f" fillColor="none" weight={1} />}
            </LayerGroup>
          )}
        </FeatureGroup>

        {/* Toolbar */}
        {this.props.map_ui_visible && (
          <LeafletControl position="topleft">
            {!this.state.takingPicture && (
              <div>
                <Toolbar
                  className="toolbar"
                  onToolBarPressed={this.handleToolbarPressed}
                  OnSearchLocation={this.handleSearch}
                  OnUpdatePolygon={this.handleTogglePolygon}
                  OnToggleTopoLayer={this.handleToggleTopoLayer}
                  PolygonStates={this.state.PolygonStates}
                  TopoLayerStates={this.state.TopoLayerStates}
                  tileSet={this.state.tileSet}
                  isEditing={this.props.selectedFeatureId}
                  activeTool={this.state.activeTool}
                  topo_mode={this.props.topo_mode}
                  layers_generated={this.props.layers_generated}
                />
              </div>
            )}
          </LeafletControl>
        )}

        {/* User log */}
        {this.props.map_ui_visible && (
          <LeafletControl position="bottomleft">
            {!this.state.takingPicture && (
              <div
                style={{
                  left: -10,
                  bottom: -12,
                  position: "relative",
                  textAlign: "left",
                }}
              >
                {this.props.selectedFeatureId && (
                  <div className="map-message-box">
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      {selectedFeature.properties.identity == 1 ? "Boundary" : selectedFeature.properties.identity == 2 ? "Exclusion" : "Inactive"} polygon. Set as: [
                    </span>
                    {selectedFeature.properties.identity != 1 && (
                      <span>
                        <a
                          // href="javascript:void(0);"
                          onClick={() => {
                            this.updateIdentity(1);
                          }}
                        >
                          <span style={{ color: "#60de4f" }}>B</span>
                          <span style={{ color: "rgb(0, 0, 0)" }}>oundary, </span>
                        </a>
                      </span>
                    )}
                    {selectedFeature.properties.identity != 2 && (
                      <span>
                        <a
                          // href="javascript:void(0);"
                          onClick={() => {
                            this.updateIdentity(2);
                          }}
                        >
                          <span style={{ color: "#60de4f" }}>E</span>
                          <span style={{ color: "rgb(0, 0, 0)" }}>xclusion</span>
                        </a>
                      </span>
                    )}
                    {selectedFeature.properties.identity == 1 && <span style={{ color: "rgb(0, 0, 0)" }}>, </span>}
                    {selectedFeature.properties.identity != 0 && (
                      <span>
                        <a
                          // href="javascript:void(0);"
                          onClick={() => {
                            this.updateIdentity(0);
                          }}
                        >
                          <span style={{ color: "#60de4f" }}>I</span>
                          <span style={{ color: "rgb(0, 0, 0)" }}>nactive</span>
                        </a>
                      </span>
                    )}
                    <span style={{ color: "rgb(0, 0, 0)" }}>]</span>
                  </div>
                )}
              </div>
            )}
          </LeafletControl>
        )}

        {/* Sunfig Watermark */}
        {/* Make tooltips smaller */}
        {this.props.map_ui_visible && (
          <LeafletControl position="topright">
            {!this.state.takingPicture && (
              <div>
                <ButtonGroup>
                  <Tooltip placement="right" title="Zoom In" mouseEnterDelay={0.5}>
                    <div className="map-zoom">
                      <Button
                        className="zoom-button"
                        onClick={() => {
                          this.handleZoom(this.state.zoom + zoomGranularity);
                        }}
                      >
                        <img src={add_song} className="zoom-control" />
                      </Button>
                    </div>
                  </Tooltip>
                  <Tooltip placement="right" title="Zoom Out" mouseEnterDelay={0.5}>
                    <div>
                      <Button
                        className="zoom-button"
                        onClick={() => {
                          this.handleZoom(this.state.zoom - zoomGranularity);
                        }}
                      >
                        <img src={minus} className="zoom-control" />
                      </Button>
                    </div>
                  </Tooltip>
                  <Tooltip placement="right" title="Zoom Extents" mouseEnterDelay={0.5}>
                    <div>
                      <Button
                        className="zoom-button"
                        onClick={() => {
                          this.handleZoomExtents();
                        }}
                      >
                        <img src={expand} className="zoom-control" />
                      </Button>
                    </div>
                  </Tooltip>
                </ButtonGroup>
              </div>
            )}
          </LeafletControl>
        )}

        <LayersControl position="bottomright">
          {this.props.county && (
            <div
              style={{
                height: "20px",
                background: "#ffffffb0",
                color: "black",
                left: "10px",
                top: "20px",
                position: "relative",
                padding: "2px",
                fontWeight: "bold",
              }}
            >
              {this.props.county} <label> County, </label> {this.props.stateAbb}
            </div>
          )}
        </LayersControl>

        {/* topo scale */}
        {!this.state.takingPicture && this.props.topo_live && this.props.topo_mode != "Off" && (
          <LeafletControl position="topright">
            <div style={{ marginRight: "35px", marginBottom: "-13px" }}>
              <img src={this.props.topo_scale_url} style={{ height: "350px" }}></img>
            </div>
          </LeafletControl>
        )}

        <LeafletControl position="controlmenu">
          {this.state.menuVisible && (
            <div style={menuStyle}>
              <Menu defaultSelectedKeys={[]} defaultOpenKeys={[]} mode="vertical" theme="light">
                {this.state.selectedAzimuth > 0 && (
                  <Menu.Item
                    key="0"
                    onClick={() => {
                      this.handleSetAzimuth();
                    }}
                  >
                    <span>Set Project Azimuth to {this.state.selectedAzimuth} °</span>
                  </Menu.Item>
                )}

                <Menu.Item
                  key="3"
                  onClick={() => {
                    this.handleToolbarPressed(3);
                  }}
                >
                  <span>Draw</span>
                </Menu.Item>

                <Menu.Item
                  key="1"
                  onClick={() => {
                    this.handleToggleDims();
                  }}
                >
                  <span>Toggle Dimensions {this.state.showDims ? "Off" : "On"}</span>
                </Menu.Item>
                <Menu.Item
                  key="2"
                  onClick={() => {
                    this.handleToggleAzi();
                  }}
                >
                  <span>Toggle Azimuth {this.state.showAzi ? "Off" : "On"}</span>
                </Menu.Item>
                {/* <Menu.Item
                  key="6"
                  onClick={() => {
                    this.handleToggleSurfaces();
                  }}
                >
                  <span>Toggle Polygons {this.state.showPolygons ? 'Off' : 'On'}</span>
                </Menu.Item> */}
                <Menu.Item
                  key="4"
                  onClick={() => {
                    this.printMap();
                  }}
                >
                  <span>Export Image</span>
                </Menu.Item>
                <Menu.Item
                  key="5"
                  onClick={() => {
                    this.handleToolbarPressed(5);
                  }}
                >
                  <span>Export Map</span>
                </Menu.Item>

                {this.state.menuPolygons.map((poly, index) => {
                  return (
                    // console.log(poly.options.data.properties.index)
                    <Menu.SubMenu
                      onMouseEnter={() => this.highlightLayer(poly)}
                      onMouseLeave={() => this.unhighlightLayer(poly)}
                      onTitleClick={({ key, domEvent }) => {
                        this.handleLayerClicked(domEvent, poly.properties.index);
                      }}
                      // onTitleClick={() => {
                      //   this.unhighlightLayer(poly);
                      //   this.stopEditing();
                      //   this.startEditing(poly);
                      // }}
                      key={poly.properties.index}
                      title={
                        <span>
                          <span>Polygon {index + 1}</span>
                        </span>
                      }
                    >
                      <Menu.Item
                        onClick={(e) => {
                          this.updateIdentity(1, poly, e.domEvent);
                        }}
                        key={`bound_${poly.properties.index}`}
                      >
                        Set as Boundary
                      </Menu.Item>
                      <Menu.Item
                        key={`exclusion_${poly.properties.index}`}
                        onClick={(e) => {
                          this.updateIdentity(2, poly, e.domEvent);
                        }}
                      >
                        Set as Exclusion
                      </Menu.Item>
                      <Menu.Item
                        key={`inactive_${poly.properties.index}`}
                        onClick={(e) => {
                          this.updateIdentity(0, poly, e.domEvent);
                        }}
                      >
                        Set as Inactive
                      </Menu.Item>
                      <Menu.Item
                        key={`delete_${poly.properties.index}`}
                        onClick={() => {
                          this.handleDeleteFeature(poly.properties.index);
                        }}
                      >
                        Delete
                      </Menu.Item>
                    </Menu.SubMenu>
                  );
                })}
              </Menu>
            </div>
          )}
        </LeafletControl>

        {this.props.layout.isLayout && (
          <LeafletControl position="bottomleft">
            <div
              style={{
                left: 33,
                bottom: -15,
                position: "relative",
                textAlign: "left",
              }}
            >
              {/*  style={{ width: 300 }} */}
              <Card>
                <div style={{ marginBottom: "10px", height: "33px" }}>
                  <img
                    style={{
                      width: "92px",
                      display: "block",
                      margin: "0 auto",
                      background: "#ffffffb0",
                    }}
                    src={rbilogo}
                  />
                </div>
                {this.props.selectedResult.map((item, index) => {
                  if (item.hide !== true) {
                    return (
                      <label style={labelStyle2} key={`${index}_selectedResultsItem`}>
                        <span style={spanStyle}>{item.displayLabel ? `${item.displayLabel}`.substring(0, 11) : `${item.key}`.substring(0, 11)}</span>
                        {`${item.formatPreceding ? item.formatPreceding : ""}${item.displayValue ? item.displayValue : item.value}${item.formatTrailing ? item.formatTrailing : ""}`}
                      </label>
                    );
                  }
                })}
                <div style={{ position: "relative", height: "5px" }}>
                  <img
                    style={{
                      position: "absolute",
                      right: "-12px",
                      background: "#ffffffb0",
                    }}
                    src={bysunfig}
                  />
                </div>
              </Card>
            </div>
          </LeafletControl>
        )}
      </Map>
    );
  }
}

export { SunfigMap };
