import React, { Component, Fragment } from "react";
import { Row, Col, Card, Input, Button, Popconfirm, Tooltip, Menu, Radio, Spin, Empty, Dropdown, Typography, Meta } from "antd";
import { DownOutlined } from "@ant-design/icons";

// icons
import { trashCan, copy, singleSlope, inverted, dual_tilt } from "../../assets";

// Test Data
import products from "../Sunfig/data/carportTestData";
import defaultModules from "../Sunfig/data/defaultModsCanopy";

// redux
import { connect } from "react-redux";
import { carportActions } from "../Redux/actions/carport.actions";

// components
import { CanopyResults } from "./";

import { debounce } from "../../utils/common";
import { createPath } from "history";

class RectanglePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      azimuthValue: undefined,
      dimXValue: undefined,
      dimYValue: undefined,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    let { selectedRectId } = prevProps;

    let currentCanopy = this.props.carport.rectangles[this.props.carport.selectedRectId];
    // for controlled inputs in modX and modY
    // if something has changed from previous props/state update the dimx and dimy value.
    if (this.props.carport.selectedRectId && this.props.carport.selectedRectId != selectedRectId) {
      this.setState({ dimXValue: currentCanopy.dimensions.modX, dimYValue: currentCanopy.dimensions.modY });
    }
  }

  handleUpdateInput = debounce((key, value) => {
    this.props.updateFromInputs(key, value);
  }, 300);

  handleGlobalOverride = debounce((key, value) => {
    this.props.applyGlobalOverrides(key, value);
  }, 500);

  render() {
    const globalProductOverlay = () => (
      <Menu
        onClick={async (e) => {
          // async await as checkProductAndModuleSelect would run before the store was updated and the check within said function would fail
          await this.props.dispatch(carportActions.setGlobalOverrideInputs("selectGlobalProduct", parseInt(e.key)));
        }}
      >
        {Object.values(products).map((product, index) => (
          <Menu.Item key={index} eventKey={index}>
            {product.name}
          </Menu.Item>
        ))}
      </Menu>
    );

    const globalModulesOverlay = () => (
      <Menu
        onClick={async (e) => {
          // async await as checkProductAndModuleSelect would run before the store was updated and the check within said function would fail
          await this.props.dispatch(carportActions.setGlobalOverrideInputs("selectGlobalModule", parseInt(e.key)));
        }}
        style={{ height: "200px", overflowY: "auto" }}
      >
        {Object.values(defaultModules).map((module, index) => (
          <Menu.Item key={index} eventKey={index}>
            {module.name}
          </Menu.Item>
        ))}
      </Menu>
    );
    return (
      <section className="rectangles" style={{ margin: "0 auto", height: "700px" }}>
        <div className="rectangleArea" style={{ height: "700px" }}>
          <Row gutter={[8, 8]} style={{ display: "flex" }}>
            <Col span={5}>
              <Menu
                style={{ border: "1px solid #60de4f", minHeight: "700px", borderRadius: "2px" }}
                selectedKeys={this.props.carport.selectGlobalOverride ? ["global"] : [`${this.props.carport.selectedRectId}`]}
                onClick={(eventKey) => {
                  if (eventKey.key == "global") {
                    this.props.stopEditing(this.props.carport.selectedRectId);
                    this.props.dispatch(carportActions.selectGlobalOverride(true));
                  } else {
                    this.props.dispatch(carportActions.selectRectangle(eventKey.key));
                  }
                }}
              >
                <Menu.Item disabled={Object.values(this.props.carport.rectangles).length == 0} key={"global"} eventKey={"global"}>
                  Global Overrides
                </Menu.Item>
                {Object.values(this.props.carport.rectangles)
                  .sort((a, b) => a.index - b.index)
                  .map((rectangle, index) => (
                    <Menu.Item key={rectangle.id} eventKey={rectangle.id}>
                      {rectangle.copiedFrom ? `${rectangle.name} (Copy)` : `${rectangle.name}`}
                    </Menu.Item>
                  ))}
              </Menu>
            </Col>
            {this.props.carport.rectangles && (
              <Col span={7}>
                {this.props.selectedRectId && !this.props.carport.selectGlobalOverride && !this.props.inputUpdateFromMap ? (
                  <Card
                    style={{ border: "1px solid #60de4f", height: "700px" }}
                    title={this.props.carport.selectedRectId && this.props.carport.rectangles[this.props.carport.selectedRectId].name}
                    extra={
                      this.props.carport.selectedRectId && this.props.carport.rectangles[this.props.carport.selectedRectId].id != "global" ? (
                        <div>
                          <Tooltip title="Copy">
                            <Button
                              style={{
                                border: this.props.carport.activeTool == "pasteFromRectanglePanel" ? "1px solid #fff" : "1px solid transparent",
                                background: "transparent",
                              }}
                              onClick={() => {
                                this.props.copyRectangle(`Click map to paste Canopy`);
                                this.props.dispatch(carportActions.setActiveTool("pasteFromRectanglePanel"));
                              }}
                            >
                              <img src={copy} />
                            </Button>
                          </Tooltip>
                          <Popconfirm
                            title={`Delete ${this.props.carport.rectangles[this.props.carport.selectedRectId].name}`}
                            onConfirm={() => {
                              // stop editing before deleting the rectangle or the id is undefined when the map updates and then it explodes 🔥
                              this.props.stopEditing(this.props.carport.selectedRectId);
                              this.props.dispatch(carportActions.deleteRectangle(this.props.carport.selectedRectId));
                            }}
                            onCancel={() => console.log("canceled")}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Tooltip title="Delete">
                              <Button style={{ border: "none", background: "transparent" }}>
                                <img src={trashCan} />
                              </Button>
                            </Tooltip>
                          </Popconfirm>
                        </div>
                      ) : null
                    }
                    headStyle={{ backgroundColor: "#60de4f", color: "#fff", height: "65px", lineHeight: "30px" }}
                  >
                    <div style={{ width: "inherit", margin: "0 auto 0 12px" }}>
                      <Card.Meta
                        description={
                          <div style={{ width: "100%", margin: "5px auto 10px auto" }}>
                            <strong style={{ color: "rgba(0, 0,0,0.65)", fontSize: "13px" }}>{`Product: ${this.props.carport.rectangles[this.props.carport.selectedRectId].product_name}`}</strong>
                          </div>
                        }
                      ></Card.Meta>
                      <Card.Meta
                        description={
                          <div style={{ width: "100%", margin: "5px auto 10px auto" }}>
                            <strong style={{ color: "rgba(0, 0,0,0.65)", fontSize: "13px" }}>{`Module: ${this.props.carport.rectangles[this.props.carport.selectedRectId].module.name}`}</strong>
                          </div>
                        }
                      ></Card.Meta>
                    </div>

                    <Col style={{ width: "100%" }}>
                      <Row style={{ display: "flex", alignItems: "center", margin: "15px" }}>
                        <Col>
                          <label>
                            <strong>Name:</strong>
                          </label>
                        </Col>
                        <Col>
                          <Input
                            style={{ marginLeft: "10px" }}
                            ref="name"
                            allowClear={true}
                            value={this.props.carport.canopyName}
                            onChange={(e) => this.props.dispatch(carportActions.updateRectangleName(e.target.value))}
                            onPressEnter={(e) => {
                              if (e.target.value) {
                                this.props.dispatch(carportActions.saveRectangleName(e.target.value));
                              } else {
                                this.setState({ namePlaceholder: "Canopy" });
                                this.props.dispatch(carportActions.saveRectangleName("Canopy"));
                              }
                            }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ margin: "10px" }}>
                        <Radio.Group
                          value={
                            this.props.carport.selectedRectId
                              ? this.props.carport.rectangles[this.props.carport.selectedRectId].orientation || this.props.carport.prodModSelection.selectedCarportProduct.orientation
                              : 1
                          }
                          onChange={(e) => this.handleUpdateInput("changeModuleOrientation", e.target.value)}
                        >
                          <Radio value={1}>Portrait</Radio>
                          <Radio value={2}>Landscape</Radio>
                        </Radio.Group>
                      </Row>
                      <Row gutter={[8, 8]} style={{ display: "flex", alignItems: "center", margin: "5px" }}>
                        <Col span={11}>
                          <label>
                            <strong>Modules, Width:</strong>
                          </label>
                        </Col>
                        <Col span={10}>
                          <Input
                            ref="yDim"
                            value={this.state.dimYValue}
                            // defaultValue={
                            //   this.props.carport.selectedRectId && this.props.carport.rectangles[this.props.carport.selectedRectId].dimensions.modY
                            // }
                            type="number"
                            step={1}
                            min={
                              0
                              // this.props.carport.selectedRectId &&
                              // this.props.carport.rectangles[this.props.carport.selectedRectId].base_dimension.modY
                            }
                            suffix={<span style={{ fontSize: "10px" }}>mod</span>}
                            max="999"
                            onChange={(e) => {
                              let inputValue = parseInt(e.target.value);
                              this.setState({ dimYValue: inputValue });
                              this.handleUpdateInput("updateDimension", {
                                xDim: this.props.carport.rectangles[this.props.carport.selectedRectId].dimensions.modX,
                                yDim:
                                  inputValue == 0 || inputValue == null || inputValue < this.props.carport.rectangles[this.props.carport.selectedRectId].base_dimension.modY
                                    ? this.props.carport.rectangles[this.props.carport.selectedRectId].base_dimension.modY
                                    : inputValue,
                              });
                            }}
                          ></Input>
                        </Col>
                        {this.state.dimYValue < this.props.carport.rectangles[this.props.carport.selectedRectId].base_dimension.modY && (
                          <div>
                            <Typography.Text>{` > ${this.props.carport.rectangles[this.props.carport.selectedRectId].base_dimension.modY} `}</Typography.Text>
                          </div>
                        )}
                      </Row>
                      <Row gutter={[8, 8]} style={{ display: "flex", alignItems: "center", margin: "5px" }}>
                        <Col span={11}>
                          <label>
                            <strong>Modules, Length:</strong>
                          </label>
                        </Col>
                        <Col span={10}>
                          <Input
                            ref="xDim"
                            value={this.state.dimXValue}
                            // defaultValue={
                            //   this.props.carport.selectedRectId &&
                            //   parseInt(this.props.carport.rectangles[this.props.carport.selectedRectId].dimensions.modX)
                            // }
                            type="number"
                            min={
                              0
                              // this.props.carport.selectedRectId &&
                              // this.props.carport.rectangles[this.props.carport.selectedRectId].base_dimension.modX
                            }
                            max="999"
                            suffix={<span style={{ fontSize: "10px" }}>mod</span>}
                            step={1}
                            onChange={(e) => {
                              let inputValue = parseInt(e.target.value);
                              this.setState({ dimXValue: inputValue });
                              this.handleUpdateInput("updateDimension", {
                                xDim:
                                  inputValue == 0 || inputValue == null || inputValue < this.props.carport.rectangles[this.props.carport.selectedRectId].base_dimension.modX
                                    ? this.props.carport.rectangles[this.props.carport.selectedRectId].base_dimension.modX
                                    : inputValue,
                                yDim: this.props.carport.rectangles[this.props.carport.selectedRectId].dimensions.modY,
                              });
                            }}
                          ></Input>
                        </Col>
                        {this.state.dimXValue < this.props.carport.rectangles[this.props.carport.selectedRectId].base_dimension.modX && (
                          <div>
                            <Typography.Text>{` > ${this.props.carport.rectangles[this.props.carport.selectedRectId].base_dimension.modX} `}</Typography.Text>
                          </div>
                        )}
                      </Row>
                      <Row gutter={[8, 8]} style={{ display: "flex", alignItems: "center", margin: "5px" }}>
                        <Col span={11}>
                          <label>
                            <strong>Modules Gap:</strong>
                          </label>
                        </Col>
                        <Col span={10}>
                          <Input
                            ref="modGap"
                            type="number"
                            min="0"
                            suffix={<span style={{ fontSize: "10px" }}>m</span>}
                            step={0.1}
                            defaultValue={this.props.carport.selectedRectId && this.props.carport.rectangles[this.props.carport.selectedRectId].modXGap}
                            max="359"
                            onChange={(e) => this.handleUpdateInput("modGap", parseFloat(e.target.value))}
                          />
                        </Col>
                      </Row>
                      <Row gutter={[8, 8]} style={{ display: "flex", alignItems: "center", margin: "8px 0 8px 6px" }}>
                        <Col span={12}>
                          <p style={{ margin: "5px 0" }}>{`Width: ${parseFloat(
                            this.props.carport.rectangles[this.props.carport.selectedRectId].dimensions.x * 3.281 // meters -> feet
                          ).toFixed(1)} ft`}</p>
                        </Col>
                        <Col span={12}>
                          <p style={{ margin: "5px 0" }}>{`Length: ${parseFloat(
                            this.props.carport.rectangles[this.props.carport.selectedRectId].dimensions.y * 3.281 // meters -> feet
                          ).toFixed(1)} ft`}</p>
                        </Col>
                      </Row>
                      <Row gutter={[8, 8]} style={{ display: "flex", alignItems: "center", margin: "5px" }}>
                        <Col span={12}>
                          <label>
                            <strong>Azimuth:</strong>
                          </label>
                        </Col>
                        <Col span={13}>
                          <Input
                            ref="azimuth"
                            defaultValue={this.props.carport.selectedRectId && this.props.carport.rectangles[this.props.carport.selectedRectId].azimuth}
                            type="number"
                            min="0"
                            max="359"
                            suffix={<span>°</span>}
                            onChange={(e) =>
                              this.handleUpdateInput("updateAzimuth", {
                                azimuth: parseInt(e.target.value),
                              })
                            }
                          />
                        </Col>
                      </Row>
                      <Row style={{ display: "flex", justifyContent: "space-around", width: "100%", margin: "15px 0" }}>
                        <Radio.Group
                          style={{ display: "flex", justifyContent: "space-evenly", width: "80%" }}
                          value={this.props.carport.selectedRectId ? this.props.carport.rectangles[this.props.carport.selectedRectId].planeType : "single_slope"}
                          onChange={(e) => this.handleUpdateInput("changePlaneType", e.target.value)}
                        >
                          <Radio
                            style={{
                              display: "flex",
                              flexDirection: "column-reverse",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            value={"single_slope"}
                          >
                            <Tooltip title="Single Slope">
                              <img src={singleSlope} alt="Single Slope" />
                            </Tooltip>
                          </Radio>
                          <Radio style={{ display: "flex", flexDirection: "column-reverse", justifyContent: "center", alignItems: "center" }} value={"inverted"}>
                            <Tooltip title="Inverted">
                              <img src={inverted} alt="inverted" />
                            </Tooltip>
                          </Radio>
                          <Radio style={{ display: "flex", flexDirection: "column-reverse", justifyContent: "center", alignItems: "center" }} value={"dual_tilt"}>
                            <Tooltip title="Dual Tilt">
                              <img src={dual_tilt} alt="dual_tilt" />
                            </Tooltip>
                          </Radio>
                        </Radio.Group>
                      </Row>
                      {this.props.carport.selectedRectId && this.props.carport.rectangles[this.props.carport.selectedRectId].planeType == "inverted" ? null : (
                        <Row gutter={[8, 8]} style={{ display: "flex", alignItems: "center", margin: "5px" }}>
                          <Col span={12}>
                            <label>
                              <strong>Tilt:</strong>
                            </label>
                          </Col>
                          <Col span={13}>
                            <Input
                              ref="tilt"
                              defaultValue={(this.props.carport.selectedRectId && this.props.carport.rectangles[this.props.carport.selectedRectId].tilt) || 7}
                              type="number"
                              min="0"
                              max="15"
                              suffix={<span>°</span>}
                              onChange={(e) => this.handleUpdateInput("changeTilt", parseInt(e.target.value))}
                            />
                          </Col>
                        </Row>
                      )}
                      <Row style={{ width: "100%", textAlign: "center" }}>
                        {(this.props.carport.rectangles[this.props.carport.selectedRectId].planeType == "single_slope" ||
                          this.props.carport.rectangles[this.props.carport.selectedRectId].planeType == "dual_tilt") &&
                          this.props.carport.rectangles[this.props.carport.selectedRectId].tilt > 7 && <Typography.Text type="danger">Tilt has exceeded 7°</Typography.Text>}
                      </Row>
                      {this.props.carport.selectedRectId && this.props.carport.rectangles[this.props.carport.selectedRectId].planeType == "dual_tilt" && (
                        <Row style={{ width: "100%", textAlign: "center" }}>
                          <Typography.Text>{`East(Black) West(White)`}</Typography.Text>
                        </Row>
                      )}
                      {this.props.carport.selectedRectId && this.props.carport.rectangles[this.props.carport.selectedRectId].planeType == "inverted" && (
                        <Fragment>
                          <Row gutter={[8, 8]} style={{ display: "flex", alignItems: "center", margin: "5px" }}>
                            <Col span={16}>
                              <label>
                                <strong>Toward Azimuth(Black):</strong>
                              </label>
                            </Col>
                            <Col span={8}>
                              <Input
                                ref="towardAzimuth"
                                defaultValue={this.props.carport.selectedRectId && this.props.carport.rectangles[this.props.carport.selectedRectId].towardAzimuth}
                                type="number"
                                min="0"
                                max={this.props.carport.rectangles[this.props.carport.selectedRectId].dimensions.modY}
                                suffix={<span style={{ fontSize: "10px" }}>mod</span>}
                                onChange={(e) => this.handleUpdateInput("changeTowardAzimuth", parseInt(e.target.value))}
                              />
                            </Col>
                          </Row>
                          <Row style={{ width: "100%", textAlign: "center" }}>
                            <Typography.Text>{`Away from Azimuth(White): ${this.props.carport.rectangles[this.props.carport.selectedRectId].dimensions.modY -
                              this.props.carport.rectangles[this.props.carport.selectedRectId].towardAzimuth}`}</Typography.Text>
                          </Row>
                          <Row gutter={[8, 8]} style={{ display: "flex", alignItems: "center", margin: "5px" }}>
                            <Col span={16}>
                              <label>
                                <strong>Tilt to Azimuth:</strong>
                              </label>
                            </Col>
                            <Col span={8}>
                              <Input
                                ref="tiltToAzimuth"
                                defaultValue={this.props.carport.selectedRectId && this.props.carport.rectangles[this.props.carport.selectedRectId].tiltToAzimuth}
                                type="number"
                                min="0"
                                max="25"
                                suffix={<span>°</span>}
                                onChange={(e) => this.handleUpdateInput("changeTiltToAzimuth", parseInt(e.target.value))}
                              />
                            </Col>
                          </Row>
                          <Row gutter={[8, 8]} style={{ display: "flex", alignItems: "center", margin: "5px" }}>
                            <Col span={16}>
                              <label>
                                <strong>Tilt from Azimuth:</strong>
                              </label>
                            </Col>
                            <Col span={8}>
                              <Input
                                ref="tiltFromAzimuth"
                                defaultValue={this.props.carport.selectedRectId && this.props.carport.rectangles[this.props.carport.selectedRectId].tiltFromAzimuth}
                                type="number"
                                min="0"
                                max="25"
                                suffix={<span>°</span>}
                                onChange={(e) => {
                                  this.handleUpdateInput("changeTiltFromAzimuth", parseInt(e.target.value));
                                }}
                              />
                            </Col>
                          </Row>
                        </Fragment>
                      )}
                    </Col>
                  </Card>
                ) : !this.props.carport.selectGlobalOverride || Object.values(this.props.carport.rectangles).length < 0 ? (
                  <Card
                    style={{
                      border: "1px solid #60de4f",
                      height: "700px",
                    }}
                    // to keep styles consistent, we pass true to the title prop so that the header is displayed. If it is left empty, the header is not displayed
                    title={true}
                    headStyle={{ backgroundColor: "#60de4f", height: "65px" }}
                  >
                    <Empty
                      imageStyle={{ height: "100%", width: "100%", marginTop: "75px" }}
                      description={Object.values(this.props.carport.rectangles).length > 0 ? "Select Canopy to populate inputs." : "No Canopies have been created"}
                    />
                  </Card>
                ) : (
                  <Card
                    style={{
                      border: "1px solid #60de4f",
                      height: "700px",
                    }}
                    // to keep styles consistent, we pass true to the title prop so that the header is displayed. If it is left empty, the header is not displayed
                    title="Global Overrides"
                    headStyle={{ backgroundColor: "#60de4f", color: "#fff", height: "65px", lineHeight: "30px" }}
                  >
                    <Col style={{ width: "100%" }}>
                      <Row>
                        <label>
                          <strong>Template:</strong>
                        </label>
                      </Row>
                      <Row gutter={[8, 8]} style={{ margin: "10px 0 20px 0", display: "flex", alignItems: "center" }}>
                        <Col span={18}>
                          <Dropdown overlay={globalProductOverlay}>
                            <Button style={{ width: "200px", marginRight: "10px" }}>
                              {this.props.carport.globalOverrides.template.productIndex == -1 ? "Select Template" : this.props.carport.globalOverrides.template.globalProduct.name} <DownOutlined />
                            </Button>
                          </Dropdown>
                        </Col>
                        <Col span={8}>
                          <Button type="primary" onClick={(e) => this.handleGlobalOverride("setGlobalTemplate", this.props.carport.globalOverrides.template)}>
                            Set All
                          </Button>
                        </Col>
                      </Row>
                      <div style={{ display: "block", width: "85%", height: "2px", backgroundColor: "#d8d8d8", margin: "0 auto" }}></div>
                      <Row style={{ marginTop: "10px" }}>
                        <label>
                          <strong>Module:</strong>
                        </label>
                      </Row>
                      <Row gutter={[8, 8]} style={{ margin: "10px 0 20px 0", display: "flex", alignItems: "center" }}>
                        <Col span={18}>
                          <Dropdown overlay={globalModulesOverlay}>
                            <Button style={{ width: "200px", marginRight: "10px" }}>
                              {this.props.carport.globalOverrides.module.moduleIndex == -1 ? "Select Module" : this.props.carport.globalOverrides.module.globalModule.name} <DownOutlined />
                            </Button>
                          </Dropdown>
                        </Col>
                        <Col span={8}>
                          <Button type="primary" onClick={(e) => this.handleGlobalOverride("setGlobalModule", this.props.carport.globalOverrides.module)}>
                            Set All
                          </Button>
                        </Col>
                      </Row>
                      <div style={{ display: "block", width: "85%", height: "2px", backgroundColor: "#d8d8d8", margin: "0 auto" }}></div>
                      <Row style={{ marginTop: "10px" }}>
                        <label>
                          <strong>Orientation:</strong>
                        </label>
                      </Row>
                      <Row gutter={[8, 8]} style={{ margin: "10px 0 20px 0", display: "flex", alignItems: "center" }}>
                        <Col span={18}>
                          <Row style={{ width: "100%", display: "flex" }}>
                            <Radio.Group
                              value={this.props.carport.globalOverrides.orientation}
                              onChange={(e) => this.props.dispatch(carportActions.setGlobalOverrideInputs("orientation", parseInt(e.target.value)))}
                            >
                              <Radio value={1}>Portrait</Radio>
                              <Radio value={2}>Landscape</Radio>
                            </Radio.Group>
                          </Row>
                        </Col>
                        <Col span={8}>
                          <Button type="primary" onClick={(e) => this.handleGlobalOverride("setGlobalOrientation", parseInt(this.props.carport.globalOverrides.orientation))}>
                            Set All
                          </Button>
                        </Col>
                      </Row>
                      <div style={{ display: "block", width: "85%", height: "2px", backgroundColor: "#d8d8d8", margin: "0 auto" }}></div>
                      <Row gutter={[8, 8]} style={{ margin: "20px 0", display: "flex", alignItems: "center" }}>
                        <Col span={9} style={{ display: "flex", alignItems: "center" }}>
                          <label>
                            <strong>Azimuth:</strong>
                          </label>
                        </Col>
                        <Col span={9}>
                          <Input
                            ref="globalAzimuth"
                            defaultValue={this.props.carport.selectGlobalOverride ? this.props.carport.globalOverrides.azimuth : 180}
                            type="number"
                            min="0"
                            max="359"
                            suffix={<span>°</span>}
                            onChange={(e) => {
                              e.target.value && e.target.value < 359 && e.target.value >= 0 && this.props.dispatch(carportActions.setGlobalOverrideInputs("azimuth", parseInt(e.target.value)));
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <Button type="primary" onClick={() => this.handleGlobalOverride("setGlobalAzimuth", this.props.carport.globalOverrides.azimuth)}>
                            Set All
                          </Button>
                        </Col>
                      </Row>
                      <div style={{ display: "block", width: "85%", height: "2px", backgroundColor: "#d8d8d8", margin: "0 auto" }}></div>
                      <Row gutter={[8, 8]} style={{ margin: "20px 0", display: "flex", alignItems: "center" }}>
                        <Col span={9} style={{ display: "flex", alignItems: "center" }}>
                          <label>
                            <strong>Tilt:</strong>
                          </label>
                        </Col>
                        <Col span={9}>
                          <Input
                            ref="globalTilt"
                            defaultValue={this.props.carport.selectGlobalOverride && this.props.carport.globalOverrides.tilt}
                            type="number"
                            min="0"
                            max="359"
                            suffix={<span>°</span>}
                            onChange={(e) => {
                              e.target.value && e.target.value < 15 && e.target.value >= 0 && this.props.dispatch(carportActions.setGlobalOverrideInputs("tilt", parseInt(e.target.value)));
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <Button type="primary" onClick={() => this.handleGlobalOverride("setGlobalTilt", this.props.carport.globalOverrides.tilt)}>
                            Set All
                          </Button>
                        </Col>
                      </Row>
                      <Row style={{ width: "100%", textAlign: "center" }}>{this.props.carport.globalOverrides.tilt > 7 && <Typography.Text type="danger">Tilt has exceeded 7°</Typography.Text>}</Row>

                      {/* **** This is the global override for planeType. Since we are only using single slope right now, this has not been implemented but I decided to leave this here for when we do implement it. All the styling is there, it just needs hooked up to have some functionality *** */}

                      <div style={{ display: "block", width: "85%", height: "2px", backgroundColor: "#d8d8d8", margin: "0 auto" }}></div>
                      <Col span={9} style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                        <label>
                          <strong>Plane Type:</strong>
                        </label>
                      </Col>
                      <Row gutter={[8, 8]} style={{ display: "flex", justifyContent: "space-around", width: "100%", margin: "15px 0" }}>
                        <Col span={18}>
                          <Radio.Group
                            style={{ display: "flex", width: "100%" }}
                            value={this.props.carport.selectGlobalOverride && this.props.carport.globalOverrides.planeType}
                            onChange={(e) => this.props.dispatch(carportActions.setGlobalOverrideInputs("setGlobalPlaneType", e.target.value))}
                          >
                            <Radio
                              style={{
                                display: "flex",
                                flexDirection: "column-reverse",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              value={"single_slope"}
                            >
                              <Tooltip title="Single Slope">
                                <img src={singleSlope} alt="Single Slope" />
                              </Tooltip>
                            </Radio>
                            <Radio style={{ display: "flex", flexDirection: "column-reverse", justifyContent: "center", alignItems: "center" }} value={"inverted"}>
                              <Tooltip title="Inverted">
                                <img src={inverted} alt="inverted" />
                              </Tooltip>
                            </Radio>
                            <Radio style={{ display: "flex", flexDirection: "column-reverse", justifyContent: "center", alignItems: "center" }} value={"dual_tilt"}>
                              <Tooltip title="Dual Tilt">
                                <img src={dual_tilt} alt="dual_tilt" />
                              </Tooltip>
                            </Radio>
                          </Radio.Group>
                        </Col>
                        <Col span={6}>
                          <Button type="primary" onClick={(e) => this.handleGlobalOverride("setGlobalPlaneType", this.props.carport.globalOverrides.planeType)}>
                            Set All
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Card>
                )}
              </Col>
            )}
            {/* RESULTS PANEL */}
            {this.props.carport.generatingCanopyResults ? (
              <Col style={{ width: "100%", height: "500px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <Spin size="large" />
                <h3>Generating...</h3>
              </Col>
            ) : (
              <Col>{this.props.carport.hasCanopyResults ? <CanopyResults /> : null}</Col>
            )}
          </Row>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  const { carport } = state;
  return {
    carport,
  };
}

const connectedStore = connect(mapStateToProps)(RectanglePanel);
export { connectedStore as RectanglePanel };
