import React, { Fragment, useState, useEffect, useRef } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { carportActions, create_UUID } from "../Redux";

// STYLE COMPONENTS / LEAFLET CSS
import { CanopyLayout, ControlPanelWrapper, MapWrapper, DropZoneOverlay } from "./styles";
import "./styles/leaflet.css";

// REACT LEAFLET COMPONENTS
import { Map, FeatureGroup, useMapEvents, LayersControl, Rectangle, LayerGroup, Polygon } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";

// CONSTANTS
import { zoomGranularity } from "./CanopyConstants";

import Dropzone from "react-dropzone";
import { Spin } from "antd";

// CUSTOM COMPONENTS
import { MapTileLayer } from "./MapTileLayer";
import { CanopyToolbar, LeafletControl, ZoomAndLayerControl, StateAndCounty, VisualGeoJsonLayer, ImageLayer, CanopyNames } from "./components";
import { SelectedGeoJsonLayer, NonRectangularLayer, OverlapCheck, OverlapWarningBox } from "./components";
import { SalesForceCapture } from "../SalesForceCapture";
import { CanopyControlPanel, ImportTool } from "../index";
import { Results } from "../Results";

const Canopy = (props) => {
  const dispatch = useDispatch();

  const canopyMap = useRef();
  // below is a trick to get the map to re-render if user navigates to ground mount then back to canopy
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  });

  useEffect(() => {
    canopyMap.current.leafletElement.invalidateSize();
  }, [canopyMap.current]);

  const map = useSelector((state) => state.carport.map);
  const mapCenter = useSelector((state) => state.carport.map.mapCenter);
  const zoom = useSelector((state) => state.carport.map.zoom);
  const uiState = useSelector((state) => state.carport.uiState);
  const show_sf_modal = useSelector((state) => state.inputs.uiState.show_sf_modal);

  const canopy_wires = useSelector((state) => state.carport.canopy_wires);
  const show_canopy_wires = useSelector((state) => state.carport.show_canopy_wires);

  // default map center
  const canopy_ui_visible = true;

  useEffect(() => {
    if (map.mapBounds) {
      canopyMap.current.leafletElement.fitBounds([map.mapBounds]);
    }
  }, [map.mapBounds]);

  useEffect(() => {
    if (zoom) {
      canopyMap.current.leafletElement.setZoom(zoom);
    }
  }, [zoom]);

  useEffect(() => {
    if (mapCenter) {
      canopyMap.current.leafletElement.setView(mapCenter, zoom);
    }
  }, [mapCenter]);

  useEffect(() => {
    L.Map.BoxZoom.prototype._onMouseUp = function(e) {
      if (e.which !== 1 && e.button !== 1) {
        return;
      }
      this._finish();
      if (!this._moved) {
        return;
      }
      this._clearDeferredResetState();
      this._resetStateTimeout = setTimeout(L.Util.bind(this._resetState, this), 0);

      let bounds = new L.LatLngBounds(this._map.containerPointToLatLng(this._startPoint), this._map.containerPointToLatLng(this._point));
      this._map.fire("boxDragEnd", { boxZoomBounds: bounds });

      if (!this._map.options.noFit) {
        this._map.fitBounds(bounds);
      }
    };
  }, []);

  // DROPZONE Functions
  const [dropzoneActive, setDropzoneActive] = useState(false);
  const [dropzoneLoading, setDropzoneLoading] = useState(false);
  const getImageCorners = (width = undefined, height = undefined) => {
    // get center of map to use as origin
    let ratio = height / width;
    let origin = canopyMap.current.leafletElement.getCenter();
    // the react-leaflet-distortable-imageoverlay takes 4 points as the corners for the image and it handles points a little strangely. It goes 1 -> 2 -> 0 -> 3
    let corners = [
      new L.latLng(origin.lat + 0.0002 * ratio, origin.lng - 0.0002),
      new L.latLng(origin.lat + 0.0002 * ratio, origin.lng + 0.0002),
      new L.latLng(origin.lat - 0.0002 * ratio, origin.lng - 0.0002),
      new L.latLng(origin.lat - 0.0002 * ratio, origin.lng + 0.0002),
    ];
    return corners;
  };
  const handleOnDrop = (files) => {
    setDropzoneActive(true);

    files.forEach((file) => {
      let imageReader = new FileReader();
      // reader.onprogress = (evt) => {
      //   console.log('evt', evt);
      // };

      imageReader.addEventListener("load", (e) => {
        let image = new Image();
        image.src = imageReader.result;
        image.onload = function() {
          let imgWidth = image.naturalWidth;
          let imgHeight = image.naturalHeight;
          let imageFile = {
            imgSrc: imageReader.result,
            name: file.name,
            type: file.type,
            size: file.size,
            opacity: 1,
            corners: getImageCorners(imgWidth, imgHeight),
            editMode: "translate",
          };
          // console.log('imagefile', imageFile);
          dispatch(carportActions.importImage(create_UUID(), imageFile));
        };
        // create object with image data in it
      });
      imageReader.readAsDataURL(file);
      setDropzoneLoading(false);
    }, false);
  };

  return (
    <>
      <CanopyLayout>
        <ControlPanelWrapper>
          <CanopyControlPanel />
          <ImportTool mode="carport" />
        </ControlPanelWrapper>

        <Dropzone
          accept="image/*"
          style={{}}
          multiple
          minSize={0}
          maxSize={10000000}
          onDrop={(files) => {
            handleOnDrop(files);
            setDropzoneActive(false);
          }}
          onDragEnter={() => setDropzoneActive(true)}
          onDragLeave={() => setDropzoneActive(true)}
          disableClick={true}
        >
          {(dropzoneActive || dropzoneLoading) && (
            <DropZoneOverlay>
              {dropzoneActive && "Drop Image..."}
              {dropzoneLoading && (
                <>
                  <h2 style={{ color: "#fff" }}>Images Loading...</h2>
                  <Spin size="large" />
                </>
              )}
            </DropZoneOverlay>
          )}

          <MapWrapper showCanopyReport={uiState.show_canopy_report} showThiryPercentSet={uiState.show_thirty_percent_set}>
            <Map
              ref={canopyMap}
              center={mapCenter}
              zoom={zoom}
              minZoom={2}
              noFit={true}
              zoomSnap={zoomGranularity}
              zoomDelta={zoomGranularity}
              onzoomend={() => dispatch(carportActions.setZoom(canopyMap.current.leafletElement.getZoom()))}
              animate={true}
              doubleClickZoom={false}
              zoomControl={false}
              worldCopyJump={true}
              attributionControl={false}
              onClick={(e) => {
                dispatch(carportActions.mapClicked(canopyMap.current && canopyMap.current.leafletElement, e.latlng));
              }}
            >
              <FeatureGroup>
                <EditControl
                  position="topleft"
                  // onEditMove={e => this.updateRectangle(e)}
                  // onEditResize={e => this.updateRectangle(e)}
                  onDrawVertex={(e) => console.log(e)}
                  onEditVertex={(e) => console.log(e)}
                  // onCreated={(e) => {
                  //   handleRectangleCreated(e);
                  // }}
                  draw={{
                    rectangle: false,
                    marker: false,
                    circle: false,
                    circlemarker: false,
                    polygon: false,
                    polyline: false,
                  }}
                  edit={{
                    remove: false,
                    edit: false,
                  }}
                />
              </FeatureGroup>

              {/* Canopy tools */}
              {canopy_ui_visible && <CanopyToolbar />}

              {/* Zoom, extent and layer control */}
              {canopyMap.current && canopyMap.current.leafletElement && <ZoomAndLayerControl mapRef={canopyMap.current.leafletElement} />}

              <MapTileLayer />

              <StateAndCounty />

              {canopyMap.current && canopyMap.current.leafletElement && (
                <Fragment>
                  <ImageLayer />
                  <VisualGeoJsonLayer mapRef={canopyMap.current.leafletElement} />
                  <SelectedGeoJsonLayer mapRef={canopyMap.current.leafletElement} />
                  <NonRectangularLayer mapRef={canopyMap.current.leafletElement} />
                </Fragment>
              )}

              {/* {show_canopy_wires && 
								<LayerGroup>
									<Polygon positions={canopy_wires.geometry.coordinates} color='#FFFF00' weight={2}>
									</Polygon>
								</LayerGroup>
							} */}

              <CanopyNames />
              <OverlapCheck />
              <OverlapWarningBox />

              {show_sf_modal && <SalesForceCapture />}
            </Map>

            <Results />
          </MapWrapper>
        </Dropzone>
      </CanopyLayout>
    </>
  );
};

export { Canopy };
