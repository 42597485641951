import config from 'config';

// const authUrl = 'http://localhost:3100';
// const authUrl = 'https://6v8qtfdsf4.execute-api.us-east-2.amazonaws.com/dev';

// const config.apiUrl = 'http://localhost:8000';

// const config.apiUrl = 'https://api.sunfig.com/swa'
// const config.apiUrl = 'https://api.sunfig.solar/swa-test'
// const config.apiUrl = 'https://api.sunfig.solar/swa-dev'

// const config.apiUrl = 'https://zcpnpvpk2k.execute-api.us-east-2.amazonaws.com/test'
// const config.apiUrl = 'https://w7g2h8m9dj.execute-api.us-east-2.amazonaws.com/dev'

// export async function getToken(myName, myPassword, regbool, savedPassword) {
//   let response = await fetch(`${authUrl}/api/getToken`, {
//     method: 'post',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       username: myName,
//       password: myPassword,
//       regbool: regbool,
//       savedPassword: savedPassword
//     })
//   });

//   let responseJson = await response.json();
//   return responseJson;
// }

// export async function checkToken(token) {
//   let response = await fetch(`${authUrl}/api/checkToken`, {
//     method: 'post',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       accessToken: token
//     })
//   });

//   let responseJson = await response.json();
//   if (typeof responseJson.expiration !== 'undefined' && responseJson.expiration !== null && responseJson.expiration > 0) {
//     return true;
//   }

//   return false;
// }

// export async function listUsers(token) {
//   let response = await fetch(`${authUrl}/api/listUsers`, {
//     method: 'post',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       accessToken: token
//     })
//   });

//   let responseJson = await response.json();
//   return responseJson;
// }

// export async function createUser(token, email, username, password, admin) {
//   let response = await fetch(`${authUrl}/api/createUser`, {
//     method: 'post',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       accessToken: token,
//       email: email,
//       username: username,
//       // password: password,
//       admin: admin ? 1 : 0
//     })
//   });

//   let responseJson = await response.json();
//   return responseJson;
// }

// export async function deleteUser(token, username) {
//   let response = await fetch(`${authUrl}/api/deleteUser`, {
//     method: 'post',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       accessToken: token,
//       username: username
//     })
//   });

//   let responseStatus = await response.status;
//   if (responseStatus === 200) {
//     return true;
//   }
//   return false;
// }

// export async function resetPassword(token, username, email) {
//   let response = await fetch(`${authUrl}/api/resetPassword`, {
//     method: 'post',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       accessToken: token,
//       username: username,
//       email: email
//     })
//   });

//   let responseStatus = await response.status;
//   if (responseStatus === 200) {
//     return true;
//   }
//   return false;
// }

// export async function updateRole(token, username, admin) {
//   let response = await fetch(`${authUrl}/api/updateRole`, {
//     method: 'post',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       accessToken: token,
//       username: username,
//       role: admin
//     })
//   });

//   let responseStatus = await response.status;
//   if (responseStatus === 200) {
//     return true;
//   }
//   return false;
// }

export async function login(email, password) {
  let requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password })
  };

  return fetch(`${config.apiUrl}/rest-auth/login/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a jwt access token in the response
      if (user.access) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
      }

      return user;
    });
}

export async function obtainToken(email, password) {
  let requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password })
  };

  return fetch(`${config.apiUrl}/platform/rbi/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a jwt access token in the response
      if (user.access) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // console.log(user.refresh)
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('access', JSON.stringify(user.access));
        localStorage.setItem('refresh', JSON.stringify(user.refresh));
      }

      return user;
    });
}

export async function refreshToken(refreshToken) {
  let requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ refresh: refreshToken })
  };
  // console.log(requestOptions)
  return fetch(`${config.apiUrl}/api/auth/token/refresh/`, requestOptions)
    .then(handleResponse)
    .then(data => {
      // login successful if there's a jwt access token in the response
      if (data.access) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('access', JSON.stringify(data.access));
      }

      return data.access;
    });
}

export async function verifyToken(token) {
  let requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token: token })
  };

  let response = await fetch(
    `${config.apiUrl}/api/auth/token/verify/`,
    requestOptions
  );
  // .then(handleResponse)
  // login successful if there's a jwt access token in the response
  // console.log(response)
  if (!response.ok) {
    console.log('token is not valid or expired - logging out');
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    // localStorage.setItem('user', JSON.stringify(resp));
    return false;
  } else {
    // console.log('token valid')
    return true;
  }
}

export async function _listUsers(token) {
  let response = await fetch(`${config.apiUrl}/platform/rbi/manage/`, {
    method: 'get',
    headers: {
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  let responseJson = await response.json();
  return responseJson;
}
export async function _updatePassword(
  token,
  email,
  old_password,
  new_password
) {
  let response = await fetch(`${config.apiUrl}/platform/rbi/update/`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      email: email,
      old_password: old_password,
      new_password: new_password
    })
  });

  let responseJson = await response.json();
  return responseJson;
}
export async function _manageUsers(token, body) {
  // console.log(body)

  let response = await fetch(`${config.apiUrl}/platform/rbi/manage/`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      body
    })
  });

  let responseJson = await response.json();
  return responseJson;
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    // TODO: add this back in?
    if (!response.ok) {
      if (response.status === 401 || response.status === 400) {
        // auto logout if 401 response returned from api
        logout();
        console.log('logging out');
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
// export async function signUp(newUser) {
//   let response = await fetch(`${authUrl}/api/signUp`, {
//     method: 'post',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       username: newUser.username,
//       email: newUser.email,
//       password: newUser.password
//     })
//   });
//   let responseJson = await response.json();
//   if (typeof responseJson !== 'undefined' && responseJson.message === 'Successfully signed up.') {
//     return true;
//   } else if (typeof responseJson !== 'undefined' && responseJson.code === 'UsernameExistsException') {
//     return false;
//   }

//   throw new Error(responseJson.errorMessage);
// }

// export async function confirmSignUp(username, confirmationCode) {
//   let response = await fetch(`${authUrl}/api/confirmSignUp`, {
//     method: 'post',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       ConfirmationCode: confirmationCode,
//       Username: username
//     })
//   });
//   let responseJson = await response.json();
//   if (typeof responseJson !== 'undefined' && responseJson.error) {
//     throw new Error(responseJson.errorMessage);
//   }
// }

// export async function resendConfirmationCode(username) {
//   let response = await fetch(`${authUrl}/api/resendConfirmationCode`, {
//     method: 'post',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       Username: username
//     })
//   });
//   let responseJson = await response.json();
//   if (typeof responseJson !== 'undefined' && responseJson.message === 'Confirmation code resent.') {
//     return true;
//   }

//   throw new Error(responseJson.errorMessage);
// }
