import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { OverlapWarningBoxLayout } from '../styles';
import { AnimatePresence } from 'framer-motion';

import { infoIcon } from '../../../assets';

const OverlapWarningBox = () => {
  const overlapping = useSelector((state) => state.carport.overlapping);
  const visual = useSelector((state) => state.carport.visual);

  return (
    <>
      {Object.values(overlapping).length > 0 && (
        <AnimatePresence key={`OverLapWarningBox`}>
          <OverlapWarningBoxLayout
            key={`OverLapWarningBox`}
            initial={{ scale: 0.7, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.7, opacity: 0 }}
            transition={{ duration: 0.5, ease: [0.78, 0.14, 0.15, 0.86] }}
          >
            <div className="overlap-img">
              <img src={infoIcon} />
            </div>
            <div className="overlap-text">
              <p>The Following Canopies are Overlapping:</p>
              <ul className="overlap-list">
                {Object.values(overlapping).map((id, index) => (
                  <li key={index}>{visual[id].name}</li>
                ))}
              </ul>
            </div>
          </OverlapWarningBoxLayout>
        </AnimatePresence>
      )}
    </>
  );
};

export { OverlapWarningBox };
