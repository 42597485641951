import React, { useState, useEffect, useRef, Fragment } from "react";
import styled from "styled-components";
import { divStyle, overlayStyle } from "../components/Sunfig/data/defaultStyles";
import { EditControl } from "react-leaflet-draw";
import LeafletControl from "../components/Sunfig/MapControl";
import Dropzone from "react-dropzone";
import { Map, TileLayer, Polygon, GeoJSON, FeatureGroup, LayerGroup, Popup, Rectangle } from "react-leaflet";
import L from "leaflet";
import * as turf from "@turf/turf";
import "../components/Sunfig/Path.Transform";
// modules
import defaultModules from "../components/Sunfig/data/defaultModsCanopy";

// components
import { CanopyControlPanel, ImportTool } from "../components";
import { CanopyResults, ZoomAndLayerControl, CanopyToolbar, ImageOverlay, ImageToolbar, OverLapWarning } from "../components/Sunfig";

// assets
import { close, rotateCursor } from "../assets";
// antd
import { message, Progress, Spin, Button, Menu } from "antd";

// helpers
import { useDynamicRefs } from "../utils";
import { getBounds as getBoundingBox } from "../utils/map_helper";
import { fixProductDimensions, getModuleDimsByOrientation, calculateModuleDimensions } from "../components/Redux/helpers";
import { debounce } from "../utils/common";
import domtoimage from "dom-to-image";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { carportActions } from "../components/Redux/actions/carport.actions";
import { containsNumber } from "@turf/turf";
import { motion } from "framer-motion";
import { portal } from "../components/Redux/reducers/portal.reducer";
// import Menu from 'react-select/src/components/Menu';

// **** styles ***
const CanopyWrapper = styled.section`
  display: grid;
  position: relative;
  grid-template-columns: 325px 1fr;
`;

const CanopyControlPanelWrapper = styled.section`
  grid-column: 1;
`;

const CanopyMapWrapper = styled.section`
  grid-column: 2;
  position: relative;

  .generating-box {
    width: 100%;
    height: 100px;
    position: absolute;
    display: grid;
    place-items: center;
    bottom: 0;
    z-index: 600;

    .generating-box-contents,
    .initializing-box {
      color: #60de4f;
      height: 80px;
      width: 250px;
      background-color: #fff;
      border-radius: 2px;
      border: 1px solid #60de4f;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      p {
        font-size: 16px;
        margin: 0;
      }
    }
  }

  .results {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1501;
    display: grid;
    place-items: center;

    .results-box {
      width: 100%;
      height: ${(props) => (props.showCanopyReport || props.showThiryPercentSet ? "100vh" : "375px")};
      background-color: #fff;
      grid-template-rows: auto 1fr;
      border: 1px solid #60de4f;
      transition: height 0.5s cubic-bezier(0.23, 1, 0.32, 1.05);
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
      overflow-y: hidden;

      .results-close-button {
        z-index: 1;
        right: 0px;
        position: absolute;
        display: flex;
        justify-content: flex-end;
      }
    }

    .results-table {
      grid-row: 2;
    }
  }
`;

// const TileSets = {
//   OpenStreet: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
//   Esri_WorldStreet: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
//   Esri_Topo: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
//   Esri_ArcGIS: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
//   MapTiler_SAT: 'https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=tdZKmEGOeC3kM451zVDC',
// };
import BingLayer from "../components/Sunfig/Bing";
import { ConsoleSqlOutlined } from "@ant-design/icons";
const bing_key = "Apg1Wo2sThhv7onPyHncSIy7J3Nn98FFIzAhWYNFDrNLDpCRkZaSic1uuBHRoppM";
const TileSets = {
  Satellite: "Aerial",
  "Satellite With Labels": "AerialWithLabels",
  "Satellite With OnDemand Labels": "AerialWithLabelsOnDemand",
  "Street Map": "Road",
  // "Street Map Dark": "CanvasDark",
  // "CanvasLight": "CanvasLight",
  // "CanvasGray": "CanvasGray"
};

const checks_og = {
  p1_x: false,
  p1_y: false,
  p2_x: false,
  p2_y: false,
  p3_x: false,
  p3_y: false,
  p4_x: false,
  p4_y: false,
};

const Canopy = () => {
  const carport = useSelector((state) => getCanopyProps(state.carport));
  const non_poly_cells_and_blocks = useSelector((state) => getCellsAndBlocks(state.carport.non_poly_cells_and_blocks));
  // const portal = useSelector((state) => state.portal);
  const dispatch = useDispatch();

  //map ref
  const canopyMap = useRef(null);
  const rightClickMenu = useRef(false);
  const menuX = useRef(0);
  const menuY = useRef(0);
  const cellLayerRef = useRef();
  const rectRef = useRef();
  const currentlySelectedCanopy = useRef(null);
  const currentRectangles = useRef();

  // helper function to setup dynamic refs
  const [getRef, setRef] = useDynamicRefs();

  // state
  const [activeTileSet, setActivetileSet] = useState("Satellite");
  const [bingLayerVisible, setBingLayerVisible] = useState(true);
  const [cursorPointer, setCursorPointer] = useState(undefined);
  const [dropzoneActive, setDropzoneActive] = useState(false);
  const [dropzoneLoading, setDropzoneLoading] = useState(false);
  const [movingRectId, setMovingRectId] = useState(undefined);
  const [inputUpdateFromMap, setInputUpdateFromMap] = useState(false);
  const [toolbarPosition, setToolbarPosition] = useState(undefined);
  const [showCanopyNameLabels, setShowCanopyNameLabels] = useState(false);
  const [currentEditingLayer, setCurrentEditingLayer] = useState();

  const [currentNonPolyRect, setCurrentNonPolyRect] = useState();
  const [do_update_rect, set_do_update_rect] = useState(false);

  const [overlappingCanopies, setOverlappingCanopies] = useState([]);

  const [refreshKey, setRefreshKey] = useState(undefined);

  // const cells = useSelector(state => state.non_poly_cells)
  // const blocks = useSelector(state => state.non_poly_blocks)

  // const [alignmentToolActive, setAlignmentToolActive] = useState(false);
  // const [alignmentCanopyIds, setAlignmentCanopyIds] = useState(undefined);
  // const [filteredCanopies, setFilteredCanopies] = useState([]);
  // replaced above with useRef, which can be passed to event listeners and read correctly
  const alignmentToolOn = useRef(false);
  const nonPolygonalEditOn = useRef(false);
  const availableAlignmentCanopies = useRef([]);
  const alignmentCanopyIds = useRef(undefined);
  const canopy_ui_visible = useRef(true);
  const imageTarget = useRef(undefined);

  useEffect(() => {
    L.Map.BoxZoom.prototype._onMouseUp = function(e) {
      if (e.which !== 1 && e.button !== 1) {
        return;
      }
      this._finish();
      if (!this._moved) {
        return;
      }
      this._clearDeferredResetState();
      this._resetStateTimeout = setTimeout(L.Util.bind(this._resetState, this), 0);

      let bounds = new L.LatLngBounds(this._map.containerPointToLatLng(this._startPoint), this._map.containerPointToLatLng(this._point));
      this._map.fire("boxDragEnd", { boxZoomBounds: bounds });

      if (!this._map.options.noFit) {
        this._map.fitBounds(bounds);
      }
    };

    canopyMap.current.leafletElement.on("boxDragEnd", (e) => {
      // console.log('boxDragEnd', e);
      if (nonPolygonalEditOn.current) {
        let box_bounds = e.boxZoomBounds;
        let bbox = [box_bounds._southWest.lng, box_bounds._southWest.lat, box_bounds._northEast.lng, box_bounds._northEast.lat];
        let zoom_box_poly = turf.bboxPolygon(bbox);
        moduleSelectBoxCheck(zoom_box_poly);
      }
    });
  }, []);

  // useEffect(() => {
  //   currentRectangles.current = carport.rectangles;
  // 	console.log('update to rectangles')
  // }, [carport.rectangles]);

  useEffect(() => {
    if (carport.project_loading && carport.rectangles) {
      Object.values(carport.rectangles).map((rectangle) => {
        if (Object.values(rectangle.visibleGeoJson).length == 0 || Object.values(rectangle.editCellsGeoJson).length == 0) {
          // make sure no string values in here
          rectangle.module_dimensions = {
            x: parseFloat(rectangle.module_dimensions.x),
            y: parseFloat(rectangle.module_dimensions.y),
            mod_width: parseFloat(rectangle.module_dimensions.mod_width),
            mod_height: parseFloat(rectangle.module_dimensions.mod_height),
          };

          addEditableCells(rectangle);
        }
      });
    }
  }, [carport.project_loading, carport.rectangles]);

  // useEffect(() => {
  //   currentlySelectedCanopy.current = carport.selectedRectId;
  // }, [carport.selectedRectId]);

  useEffect(() => {
    // forces the polygon to refresh
    setRefreshKey(create_UUID());
  }, [nonPolygonalEditOn.current]);

  useEffect(() => {
    setBingLayerVisible(true);
  }, [activeTileSet]);

  const onChangeTileset = (tileset) => {
    setBingLayerVisible(false);
    setActivetileSet(tileset);
  };

  // this keeps a ref to the previous id of a canopy that is passed to it.
  const usePrevious = (id) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = id;
    });
    return ref.current;
  };

  const prevCanopyId = usePrevious(carport.selectedRectId);
  const prevPrepareReport = usePrevious(carport.prepareReport);

  // this keeps track when selectedRectId is updated and reacts accordingly. basically a ComponentDidUpdate lifecycle method
  useEffect(() => {
    if (carport.selectedRectId != prevCanopyId && nonPolygonalEditOn.current === false) {
      // something new was selected

      if (prevCanopyId && carport.rectangles[prevCanopyId]) {
        stopEditing(prevCanopyId);
      }
      if (carport.selectedRectId) {
        let layer = getLayer(carport.selectedRectId);
        layer?.bringToFront();
        startEditing(carport.selectedRectId);
      }
      setInputUpdateFromMap(false);
      checkCanopyOverlap();
    }
  }, [carport.selectedRectId]);

  useEffect(() => {
    if (carport.prepareReport && carport.prepareReport != prevPrepareReport) {
      //
      let targetKey = carport.prepareReport[0];
      imageTarget.current = undefined;
      canopy_ui_visible.current = false;
      forceUpdate();
      handleCaptureReportImages(targetKey);
    }
  }, [carport.prepareReport]);

  // useEffect for message popup config
  useEffect(() => {
    message.config({
      duration: 2.5,
    });
  });

  // useEffect(() => {
  //   imageId.current = carport.selectedImageId;
  //   if (prevImageId && imageId.current && prevImageId !== imageId.current) {
  //     setImageToolbarPosition(carport.images[imageId.current].corners);
  //   }
  // }, [carport.selectedImageId]);

  // this handles zoom extents when a project is laoded
  useEffect(() => {
    if (carport.mapBounds) {
      canopyMap.current.leafletElement.fitBounds([carport.mapBounds]);
    }
  }, [carport.mapBounds]);

  useEffect(() => {
    if (!Object.values(carport.rectangles).length > 0) {
      setOverlappingCanopies({});
    }
  }, [carport.rectangles]);

  // useEffect for event listeners
  useEffect(() => {
    // key down listener for hotkey operations
    L.DomEvent.addListener(document, "keydown", onKeyDown, canopyMap.current.leafletElement);
    // L.DomEvent.addListener(document, 'contextmenu', onContextMenu, canopyMap.current.leafletElement);
    // L.DomEvent.addListener(document, 'click', setCursorLocation, canopyMap.current.leafletElement);
    return () => {
      L.DomEvent.removeListener(document, "keydown", onKeyDown, canopyMap.current.leafletElement);
      // L.DomEvent.removeListener(document, 'contextmenu', onContextMenu, canopyMap.current.leafletElement);

      // L.DomEvent.removeListener(document, 'click', setCursorLocation, canopyMap.current.leafletElement);
    };
  });

  // janky fix. This turns off canopy name labels when the results window is open so that if you prepare a report the name labels wont show up in the report.
  useEffect(() => {
    if (carport.view_results_table === true) {
      setShowCanopyNameLabels(false);
    }
  }, [carport.view_results_table]);

  // wrapper
  const useForceUpdate = () => {
    const [, setState] = useState();
    return () => setState({});
  };
  // call forceUpdate() after any update to one of the above useRef variables if you NEED that update to be visible in the render
  // example: alignmentCanopyIds is updated within an event listener function, and when Id's are added, they are then read in
  //  the render function in order to toggle alignment lines on/off.. so you would need to call forceUpdate after updating alignmentCanopyIds
  const forceUpdate = useForceUpdate();

  const onKeyDown = (e) => {
    // if the search bar is active disable hotkey presses so that one may type stuff into the search bar without firing off other events
    if (carport.activeTool == "search" && !e.keyCode == 27) return;

    if (e.keyCode == 27) {
      dispatch(carportActions.setActiveTool(undefined));
      alignmentToolOn.current = false;
    }

    // alignment tool hotey functonality
    if (e.altKey) {
      if (e.keyCode == 65) {
        toggleAlignmentTool();
      }
    }
    if (e.altKey && e.shiftKey) {
      // Rotation right
      if (e.keyCode == 39) {
        handleCanopyRotation("right");
      }
      // Rotation left
      if (e.keyCode == 37) {
        handleCanopyRotation("left");
      }
    }

    // check to see if control or CMD is pressed
    if ((e.metaKey && e.shiftKey == false) || (e.ctrlKey && e.shiftKey == false)) {
      // delete canopy
      if (e.keyCode == 8) {
        setInputUpdateFromMap(true);
        stopEditing(carport.selectedRectId);
        currentlySelectedCanopy.current = undefined;
        dispatch(carportActions.selectRectangle(undefined));
        dispatch(carportActions.deleteRectangle(carport.selectedRectId));
        setInputUpdateFromMap(false);
      }
      // duplicate default
      // disabled as this also causes the bookbark tool to fire in browsers.
      // if (e.keyCode == 68) {
      //   duplicateRectangle(carport.selectedRectId);
      // }
      // duplicate right
      if (e.keyCode == 39) {
        duplicateRectangle(carport.selectedRectId, "right");
      }
      // duplicate left
      if (e.keyCode == 37) {
        // the prevent default has to be here on the duplicate left as the browser will just go back a page(befault browser behavior)
        e.preventDefault();
        duplicateRectangle(carport.selectedRectId, "left");
      }
      // duplicate up
      if (e.keyCode == 38) {
        duplicateRectangle(carport.selectedRectId, "up");
      }
      // duplicate down
      if (e.keyCode == 40) {
        duplicateRectangle(carport.selectedRectId, "down");
      }
      if (e.keyCode == 67) {
        if (carport.selectGlobalOverride) return;
        copyRectangle(`${carport.rectangles[carport.selectedRectId].name} Copied. CMD/Ctrl+V to Paste`);
      }
      if (e.keyCode == 86) {
        if (!carport.copiedRectangle) return; // if there is no copied rectangle, just return
        // document.querySelector('#leaflet-map').click();
        canopyMap.current.leafletElement.on("mousemove", (e) => pasteRectangle(e.latlng));
      }
    }

    if ((e.metaKey && e.shiftKey) || (e.ctrlKey && e.shiftKey)) {
      // translate right
      if (e.keyCode == 39) {
        handleCanopyTranslate("right");
      }
      // translate left
      if (e.keyCode == 37) {
        handleCanopyTranslate("left");
      }
      // translate up
      if (e.keyCode == 38) {
        handleCanopyTranslate("up");
      }
      // translate down
      if (e.keyCode == 40) {
        handleCanopyTranslate("down");
      }
    }
  };

  const onContextMenu = (e) => {
    if (e.target.nodeName == "DIV") {
      if (e.target.className.includes("leaflet") && e.which == 3) {
        e.view.L.DomEvent.stopPropagation(e);
        e.preventDefault();
        let x = e.clientX;
        let y = e.clientY;
        menuX.current = x;
        menuY.current = y;
        rightClickMenu.current = !rightClickMenu.current;
        forceUpdate();
      }
    }
  };

  const handleCaptureReportImages = async (key = undefined) => {
    // handleCanopyZoomExtents(18);
    let poly = key && turf.getCoords(turf.flip(carport.rectangles[key].geoJson));

    imageTarget.current = poly;
    forceUpdate();

    let value = await getPngUrl();
    let url = { key: key || "overall", value };
    let new_list = key && carport.prepareReport.filter((id) => id != key);
    dispatch(carportActions.prepareReport(new_list, url));

    if (key == undefined) {
      canopy_ui_visible.current = true;
      forceUpdate();
    }
  };
  const getPngUrl = async () => {
    return new Promise((resolve, reject) => {
      let width = canopyMap.current.leafletElement._size.x;
      let height = canopyMap.current.leafletElement._size.y;
      let node = document.getElementById("leaflet-map");
      domtoimage
        .toJpeg(node, { width, height, quality: 0.5 })
        .then((url) => resolve(url))
        .catch((error) => reject(error));
    });
  };

  const create_UUID = () => {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  };

  const prettifyText = (text) => {
    // takes in the ugly text from the product data planeType and makes it a little more presentable
    switch (text) {
      case "single_slope":
        return "Single Slope";
      case "inverted":
        return "Inverted";
      case "dual_tilt":
        return "Dual Tilt";
      default:
        return "undefined";
    }
  };

  const canopyNameLabelPosition = (geoJson) => {
    let centerPoint = turf.center(geoJson);
    return { lat: centerPoint.geometry.coordinates[1], lng: centerPoint.geometry.coordinates[0] };
  };

  const getImageCorners = (width = undefined, height = undefined) => {
    // get center of map to use as origin

    let ratio = height / width;
    let origin = canopyMap.current.leafletElement.getCenter();
    // the react-leaflet-distortable-imageoverlay takes 4 points as the corners for the image and it handles points a little strangely. It goes 1 -> 2 -> 0 -> 3
    let corners = [
      new L.latLng(origin.lat + 0.0002 * ratio, origin.lng - 0.0002),
      new L.latLng(origin.lat + 0.0002 * ratio, origin.lng + 0.0002),
      new L.latLng(origin.lat - 0.0002 * ratio, origin.lng - 0.0002),
      new L.latLng(origin.lat - 0.0002 * ratio, origin.lng + 0.0002),
    ];
    return corners;
  };

  const setImageToolbarPosition = (coords) => {
    // creat a turf poly so we hae more control over coordinate manipulation which lets us move the toolbar exactly where we want it
    let polyPoints = [
      [coords[2].lng, coords[2].lat],
      [coords[0].lng, coords[0].lat],
      [coords[1].lng, coords[1].lat],
      [coords[3].lng, coords[3].lat],
      [coords[2].lng, coords[2].lat],
    ];

    let poly = turf.polygon([polyPoints]);
    let center = turf.center(poly);

    let lineString = turf.lineString(poly.geometry.coordinates[0]);
    let bbox = turf.bbox(lineString);

    let toolbarPosition = { lat: bbox[3], lng: center.geometry.coordinates[0] };
    setToolbarPosition(toolbarPosition);
  };

  const handleOnDrop = (files) => {
    setDropzoneActive(true);

    files.forEach((file) => {
      let imageReader = new FileReader();
      // reader.onprogress = (evt) => {
      //   console.log('evt', evt);
      // };

      imageReader.addEventListener("load", (e) => {
        let image = new Image();
        image.src = imageReader.result;
        image.onload = function() {
          let imgWidth = image.naturalWidth;
          let imgHeight = image.naturalHeight;
          let imageFile = {
            imgSrc: imageReader.result,
            name: file.name,
            type: file.type,
            size: file.size,
            opacity: 1,
            corners: getImageCorners(imgWidth, imgHeight),
            editMode: "translate",
          };
          console.log("imagefile", imageFile);
          dispatch(carportActions.importImage(create_UUID(), imageFile));
        };
        // create object with image data in it
      });
      imageReader.readAsDataURL(file);
      setDropzoneLoading(false);
    }, false);
  };

  const getBounds = (coords) => {
    return {
      min_x: coords[0][0],
      min_y: coords[0][1],
      max_x: coords[2][0],
      max_y: coords[2][1],
    };
  };

  const cleanLatLng = (array) => {
    let cleaned_points = [];
    let first_coord = [];

    array.map((latlng, index) => {
      if (index == 0) first_coord = [latlng.lng, latlng.lat];
      cleaned_points.push([latlng.lng, latlng.lat]);
    });
    cleaned_points.push(first_coord);

    return [cleaned_points];
  };

  const getPoint = (dist, origin, bearing) => {
    let lat1 = (origin.lat * Math.PI) / 180;
    let lon1 = (origin.lng * Math.PI) / 180;
    let distRadians = dist / 6372797.6; // earth radius in meters
    let bearingRad = (bearing * Math.PI) / 180;

    let lat2 = Math.asin(Math.sin(lat1) * Math.cos(distRadians) + Math.cos(lat1) * Math.sin(distRadians) * Math.cos(bearingRad));

    let lon2 = lon1 + Math.atan2(Math.sin(bearingRad) * Math.sin(distRadians) * Math.cos(lat1), Math.cos(distRadians) - Math.sin(lat1) * Math.sin(lat2));

    lat2 = lat2 * (180 / Math.PI);
    lon2 = lon2 * (180 / Math.PI);

    return { lng: lon2, lat: lat2 };
  };

  const getRectFromOrigin = (origin, dimension, rotation = 0) => {
    //  origin in param
    let bottomRight = getPoint(dimension.x, origin, 90 + rotation);
    let topLeft = getPoint(dimension.y, origin, 0 + rotation);
    let topRight = getPoint(dimension.y, bottomRight, 0 + rotation);

    let latLngs = [
      [origin.lng, origin.lat],
      [topLeft.lng, topLeft.lat],
      [topRight.lng, topRight.lat],
      [bottomRight.lng, bottomRight.lat],
      [origin.lng, origin.lat],
    ];

    return latLngs;
  };
  const getRectFromCounterOrigin = (counter_origin, dimension, rotation = 0) => {
    //  counter_origin in param
    let topLeft = getPoint(dimension.x, counter_origin, -90 + rotation);
    let bottomRight = getPoint(dimension.y, counter_origin, 180 + rotation);
    let bottomLeft = getPoint(dimension.x, bottomRight, -90 + rotation);

    let latLngs = [
      [bottomLeft.lng, bottomLeft.lat],
      [topLeft.lng, topLeft.lat],
      [counter_origin.lng, counter_origin.lat],
      [bottomRight.lng, bottomRight.lat],
      [bottomLeft.lng, bottomLeft.lat],
    ];

    return latLngs;
  };

  const handleCanopyRotation = (direction) => {
    setInputUpdateFromMap(true);
    let layer = getLayer(carport.selectedRectId);
    let old_id = carport.rectangles[carport.selectedRectId].id;
    let canopy = {
      ...JSON.parse(JSON.stringify(carport.rectangles[carport.selectedRectId])),
      id: create_UUID(),
    };
    // get the center of the polygon so that we can use it as the pivot point
    let center = turf.center(canopy.geoJson);

    let pivotOptions = { pivot: [center.geometry.coordinates[0], center.geometry.coordinates[1]] };
    let rotation_direction = direction == "right" ? 1 : direction == "left" ? -1 : null;
    // add this rotation to the canopy rotation so that everything below updates properly
    canopy.rotation += rotation_direction;

    // rotate canopy
    let translatedPolygon = turf.transformRotate(canopy.geoJson, rotation_direction, pivotOptions);
    canopy.geoJson = translatedPolygon;

    // grab new origin
    canopy.origin = { lat: translatedPolygon.geometry.coordinates[0][0][1], lng: translatedPolygon.geometry.coordinates[0][0][0] };
    // rebuild canopy/recangle
    canopy.coords = getRectFromOrigin(canopy.origin, canopy.dimensions, canopy.rotation);

    layer.transform.disable();
    layer.dragging.disable();

    canopy.rotation = canopy.rotation > 180 ? canopy.rotation - 360 : canopy.rotation;
    canopy.azimuth += rotation_direction;
    canopy.azimuth = canopy.azimuth > 360 ? canopy.azimuth - 360 : canopy.azimuth;

    let block_cells = buildRectangleCells(canopy.origin, canopy, true);
    canopy.editCellsGeoJson = block_cells.cell_arr;
    canopy.block_arr = block_cells.block_arr;
    canopy.visibleGeoJson = buildVisibleRectangle(block_cells.block_arr);
    canopy.alignmentLines = drawAlignmentLines(canopy);
    canopy.alignmentCircles = drawAlignmentCircles(canopy.coords);
    canopy.azimuthHolder = getAzimuthRectPosition(canopy.geoJson.geometry.coordinates, canopy.rotation);
    dispatch(carportActions.updateCanopy(old_id, canopy));
    setInputUpdateFromMap(false);
  };

  const handleCanopyTranslate = (direction) => {
    setInputUpdateFromMap(true);
    let layer = getLayer(carport.selectedRectId);
    let old_id = carport.rectangles[carport.selectedRectId].id;
    let canopy = {
      ...JSON.parse(JSON.stringify(carport.rectangles[carport.selectedRectId])),
      id: create_UUID(),
    };
    let fix_rotation_degrees = 0;
    let options = { units: "meters" };
    let distance = 1 / 3.281; // convert ft > meters

    let shift_direction = direction == "right" ? 90 : direction == "left" ? -90 : direction == "up" ? 0 : direction == "down" ? -180 : null;

    // shift canopy
    let translatedPolygon = turf.transformTranslate(canopy.geoJson, distance, shift_direction, options);

    // grab new origin
    canopy.origin = { lat: translatedPolygon.geometry.coordinates[0][0][1], lng: translatedPolygon.geometry.coordinates[0][0][0] };
    // rebuild canopy/recangle
    canopy.coords = getRectFromOrigin(canopy.origin, canopy.dimensions, canopy.rotation);

    layer.transform.disable();
    layer.dragging.disable();

    canopy.rotation += fix_rotation_degrees;
    canopy.rotation = canopy.rotation > 180 ? canopy.rotation - 360 : canopy.rotation;
    canopy.azimuth += fix_rotation_degrees;
    canopy.azimuth = canopy.azimuth > 360 ? canopy.azimuth - 360 : canopy.azimuth;
    canopy.geoJson = turf.polygon([canopy.coords]);
    // canopy.modules = drawModules(canopy);
    let block_cells = buildRectangleCells(canopy.origin, canopy, true);
    canopy.editCellsGeoJson = block_cells.cell_arr;
    canopy.block_arr = block_cells.block_arr;
    canopy.visibleGeoJson = buildVisibleRectangle(block_cells.block_arr);
    canopy.alignmentLines = drawAlignmentLines(canopy);
    canopy.alignmentCircles = drawAlignmentCircles(canopy.coords);
    canopy.azimuthHolder = getAzimuthRectPosition(canopy.geoJson.geometry.coordinates, canopy.rotation);
    dispatch(carportActions.updateCanopy(old_id, canopy));
    setInputUpdateFromMap(true);
  };

  const drawModules = (canopy) => {
    let mod_arr_1 = [];
    let mod_arr_2 = [];
    // console.log(canopy.dimensions.modY, canopy.dimensions.modX);
    let awayFromAzimuth = canopy.dimensions.modY - canopy.towardAzimuth; //number of modules away from azimuth
    let default_mod_array = true;

    let coords = getRectFromOrigin(canopy.origin, canopy.dimensions);
    let canopyBounds = getBounds(coords);

    let moduleCoords = getRectFromOrigin({ lat: canopyBounds.min_y, lng: canopyBounds.min_x }, canopy.module_dimensions);
    let moduleBounds = getBounds(moduleCoords);
    let xIncrement = moduleBounds.max_x - moduleBounds.min_x;
    let yIncrement = moduleBounds.max_y - moduleBounds.min_y;

    let gapCoords = getRectFromOrigin({ lat: canopyBounds.min_y, lng: canopyBounds.min_x }, { x: canopy.modXGap, y: canopy.modYGap });
    let gapBounds = getBounds(gapCoords);
    let xModGap = gapBounds.max_x - gapBounds.min_x;
    let yModGap = gapBounds.max_y - gapBounds.min_y;

    let edgeCoords = getRectFromOrigin({ lat: canopyBounds.min_y, lng: canopyBounds.min_x }, { x: canopy.edgeOffset, y: canopy.edgeOffset });
    let edgeBounds = getBounds(edgeCoords);
    let xEdgeGap = edgeBounds.max_x - edgeBounds.min_x;
    let yEdgeGap = edgeBounds.max_y - edgeBounds.min_y;

    // if a modules is a 1x1 box
    let current_y = canopyBounds.min_y + yEdgeGap;
    let row_count = 0;
    for (var y = 0; y < canopy.dimensions.modY; y++) {
      let col_count = 0;
      let current_x = canopyBounds.min_x + xEdgeGap;
      if (canopy.planeType == "inverted") {
        if (row_count >= awayFromAzimuth) {
          default_mod_array = false;
        }
      }
      for (var x = 0; x < canopy.dimensions.modX; x++) {
        let origin = { lat: current_y, lng: current_x };
        let mod_latLng = [getRectFromOrigin(origin, canopy.module_dimensions)];

        if (canopy.planeType == "dual_tilt") {
          default_mod_array = row_count % 2 == 0;
        }
        // add module to array
        if (default_mod_array) {
          mod_arr_2.push(mod_latLng);
        } else {
          mod_arr_1.push(mod_latLng);
        }
        // increments
        current_x += xIncrement + xModGap;
        col_count++;
      }
      // increments
      current_y += yIncrement + yModGap;
      row_count++;
    }
    let module_1, module_2;

    if (canopy.planeType == "inverted" || canopy.planeType == "dual_tilt") {
      // let turfModules = turf.multiPolygon([[coords], ...modules]); // **** draw modules
      module_1 = turf.multiPolygon([...mod_arr_1], { color: "#fff" }); // **** draw modules
      module_2 = turf.multiPolygon([...mod_arr_2], { color: "#333" }); // **** draw modules
    } else {
      // let turfModules = turf.multiPolygon([[coords], ...modules]); // **** draw modules
      module_1 = turf.multiPolygon([...mod_arr_1], { color: "#333" }); // **** draw modules
      module_2 = turf.multiPolygon([...mod_arr_2], { color: "#333" }); // **** draw modules
    }

    var turfOptions = { pivot: [canopy.origin.lng, canopy.origin.lat] };
    var rotatedPoly_1 = turf.transformRotate(module_1, canopy.rotation, turfOptions);
    var rotatedPoly_2 = turf.transformRotate(module_2, canopy.rotation, turfOptions);
    return [rotatedPoly_1, rotatedPoly_2];
  };

  const getAzimuthRectPosition = (coords, rotation) => {
    let point1 = coords[0][0];
    let point2 = coords[0][3];
    let pointsObj = turf.midpoint(point1, point2);
    let middlePoint = [pointsObj.geometry.coordinates[0], pointsObj.geometry.coordinates[1]];
    let poly = turf.polygon([
      [
        [middlePoint[0] - 0.00003, middlePoint[1] - 0.00002],
        [middlePoint[0] - 0.00001, middlePoint[1] - 0.00002],
        [middlePoint[0] - 0.00001, middlePoint[1]],
        [middlePoint[0] + 0.00001, middlePoint[1]],
        [middlePoint[0] + 0.00001, middlePoint[1] - 0.00002],
        [middlePoint[0] + 0.00003, middlePoint[1] - 0.00002],
        [middlePoint[0], middlePoint[1] - 0.00004],
        [middlePoint[0] - 0.00003, middlePoint[1] - 0.00002],
      ],
    ]);

    let turfOptions = { pivot: middlePoint };
    let rotatedPoly = turf.transformRotate(poly, rotation, turfOptions);
    return rotatedPoly;
  };

  const checkCanopyOverlap = () => {
    let overlappedCanopies = [];

    Object.values(carport.rectangles).map((canopy) => {
      Object.values(carport.rectangles).map((canopyToCompare) => {
        if (canopy.id !== canopyToCompare.id) {
          if (turf.booleanOverlap(canopy.geoJson, canopyToCompare.geoJson) || turf.booleanContains(canopyToCompare.geoJson, canopy.geoJson)) {
            overlappedCanopies.push(canopy.id);
          }
        }
      });
    });
    if (overlappedCanopies.length > 0) {
      setOverlappingCanopies([...new Set(overlappedCanopies)]);
      // dispatch(carportActions.setCanopyOverlap([...new Set(overlappedCanopies)]));
    } else {
      setOverlappingCanopies([]);
    }
  };

  const drawAlignmentLines = (canopy) => {
    let center = turf.center(canopy.geoJson).geometry.coordinates;
    var pivotOrigin = { pivot: [canopy.origin.lng, canopy.origin.lat] };

    let coords = getRectFromOrigin(canopy.origin, canopy.dimensions);
    let canopyBounds = getBounds(coords);

    let line_botleft_y = turf.lineString([
      [canopyBounds.min_x, canopyBounds.min_y],
      [canopyBounds.min_x - 0.0003, canopyBounds.min_y],
    ]);
    line_botleft_y = turf.transformRotate(line_botleft_y, canopy.rotation, pivotOrigin);
    let line_botleft_x = turf.lineString([
      [canopyBounds.min_x, canopyBounds.min_y],
      [canopyBounds.min_x, canopyBounds.min_y - 0.0003],
    ]);
    line_botleft_x = turf.transformRotate(line_botleft_x, canopy.rotation, pivotOrigin);
    let line_botright_y = turf.lineString([
      [canopyBounds.max_x, canopyBounds.min_y],
      [canopyBounds.max_x + 0.0003, canopyBounds.min_y],
    ]);
    line_botright_y = turf.transformRotate(line_botright_y, canopy.rotation, pivotOrigin);
    let line_botright_x = turf.lineString([
      [canopyBounds.max_x, canopyBounds.min_y],
      [canopyBounds.max_x, canopyBounds.min_y - 0.0003],
    ]);
    line_botright_x = turf.transformRotate(line_botright_x, canopy.rotation, pivotOrigin);
    let line_topleft_y = turf.lineString([
      [canopyBounds.min_x, canopyBounds.max_y],
      [canopyBounds.min_x - 0.0003, canopyBounds.max_y],
    ]);
    line_topleft_y = turf.transformRotate(line_topleft_y, canopy.rotation, pivotOrigin);
    let line_topleft_x = turf.lineString([
      [canopyBounds.min_x, canopyBounds.max_y],
      [canopyBounds.min_x, canopyBounds.max_y + 0.0003],
    ]);
    line_topleft_x = turf.transformRotate(line_topleft_x, canopy.rotation, pivotOrigin);
    let line_topright_y = turf.lineString([
      [canopyBounds.max_x, canopyBounds.max_y],
      [canopyBounds.max_x + 0.0003, canopyBounds.max_y],
    ]);
    line_topright_y = turf.transformRotate(line_topright_y, canopy.rotation, pivotOrigin);
    let line_topright_x = turf.lineString([
      [canopyBounds.max_x, canopyBounds.max_y],
      [canopyBounds.max_x, canopyBounds.max_y + 0.0003],
    ]);
    line_topright_x = turf.transformRotate(line_topright_x, canopy.rotation, pivotOrigin);

    let alignmentLines = {
      p1_x: line_botleft_x,
      p1_y: line_botleft_y,
      p2_x: line_topleft_x,
      p2_y: line_topleft_y,
      p3_x: line_topright_x,
      p3_y: line_topright_y,
      p4_x: line_botright_x,
      p4_y: line_botright_y,
    };
    return alignmentLines;
  };

  const drawAlignmentCircles = (coords) => {
    let radius = 0.5 / 1000;
    let options = { steps: 8, units: "kilometers" };
    let alignmentCircles = {
      p1: turf.circle([coords[0][0], coords[0][1]], radius, options),
      p2: turf.circle([coords[1][0], coords[1][1]], radius, options),
      p3: turf.circle([coords[2][0], coords[2][1]], radius, options),
      p4: turf.circle([coords[3][0], coords[3][1]], radius, options),
    };
    return alignmentCircles;
  };

  // used when an old project is loaded that doesn't have the editCellsGeoJson, or visibleGeoJson object
  const addEditableCells = (rectangle) => {
    let old_id = rectangle.id;
    let new_canopy = {
      ...JSON.parse(JSON.stringify(rectangle)),
      id: create_UUID(),
    };

    let block_cells = buildRectangleCells(new_canopy.origin, new_canopy);
    new_canopy.editCellsGeoJson = block_cells.cell_arr;
    new_canopy.block_arr = block_cells.block_arr;
    new_canopy.visibleGeoJson = buildVisibleRectangle(block_cells.block_arr);
    dispatch(carportActions.updateCanopy(old_id, new_canopy));
  };

  const buildRectangleCells = (origin, canopy, redraw = false) => {
    let cell_size = { x: canopy.dimensions.x / canopy.dimensions.modX, y: canopy.dimensions.y / canopy.dimensions.modY };
    let cell_arr = [];
    let block_arr = [];
    let do_override_color = false;
    let awayFromAzimuth = canopy.dimensions.modY - canopy.towardAzimuth; //number of modules away from azimuth

    let origin_rect = getRectFromOrigin(origin, canopy.dimensions);
    let origin_bounds = getBounds(origin_rect);
    let current_position = origin;
    // adjust for edge gaps

    let moduleCoords = getRectFromOrigin({ lat: origin_bounds.min_y, lng: origin_bounds.min_x }, canopy.module_dimensions);
    let moduleBounds = getBounds(moduleCoords);
    let xIncrement = moduleBounds.max_x - moduleBounds.min_x;
    let yIncrement = moduleBounds.max_y - moduleBounds.min_y;

    let last_max_y = 0;

    let gapCoords = getRectFromOrigin({ lat: origin_bounds.min_y, lng: origin_bounds.min_x }, { x: canopy.modXGap, y: canopy.modYGap });
    let gapBounds = getBounds(gapCoords);
    let xModGap = gapBounds.max_x - gapBounds.min_x;
    let yModGap = gapBounds.max_y - gapBounds.min_y;

    let edgeCoords = getRectFromOrigin({ lat: origin_bounds.min_y, lng: origin_bounds.min_x }, { x: canopy.edgeOffset, y: canopy.edgeOffset });
    let edgeBounds = getBounds(edgeCoords);
    let xEdgeGap = edgeBounds.max_x - edgeBounds.min_x;
    let yEdgeGap = edgeBounds.max_y - edgeBounds.min_y;

    current_position = { lat: current_position.lat + yEdgeGap, lng: current_position.lng + xEdgeGap };
    let block_position = origin;
    let block_size = {
      x: canopy.edgeOffset + canopy.module_dimensions.x + canopy.modXGap,
      y: canopy.edgeOffset + canopy.module_dimensions.y + canopy.modYGap,
    };
    for (var y = 0; y < canopy.dimensions.modY; y++) {
      if (canopy.planeType == "inverted") {
        //
        do_override_color = y < awayFromAzimuth;
      }
      if (canopy.planeType == "dual_tilt") {
        //
        do_override_color = y % 2 != 0;
      }
      for (var x = 0; x < canopy.dimensions.modX; x++) {
        // console.log(current_position, canopy.module_dimensions);
        let block_latLng = getRectFromOrigin(block_position, block_size);
        let block_poly = turf.polygon([block_latLng]);

        if (canopy.rotation !== 0) {
          let turfOptions = { pivot: [canopy.origin.lng, canopy.origin.lat] };
          block_poly = turf.transformRotate(block_poly, canopy.rotation, turfOptions);
        }

        block_poly.properties.indexes = [x, y];
        block_poly.properties.enabled = true;
        block_arr.push(block_poly);

        let cell_latLng = getRectFromOrigin(current_position, canopy.module_dimensions);
        let cell_poly = turf.polygon([cell_latLng]);

        if (canopy.rotation !== 0) {
          let turfOptions = { pivot: [canopy.origin.lng, canopy.origin.lat] };
          cell_poly = turf.transformRotate(cell_poly, canopy.rotation, turfOptions);
        }

        cell_poly.properties.indexes = [x, y];
        cell_poly.properties.enabled = true;
        cell_poly.properties.override_color = undefined;

        if (do_override_color) {
          cell_poly.properties.override_color = "#fff";
          cell_poly.properties.awayFromAzimuth = true;
        } else {
          cell_poly.properties.override_color = "#333";
          cell_poly.properties.awayFromAzimuth = false;
        }

        cell_arr.push(cell_poly);
        let cell_bounds = getBounds(cell_latLng);

        last_max_y = cell_bounds.max_y;
        current_position = { ...current_position, lng: cell_bounds.max_x + xModGap };
        block_position = { ...block_position, lng: cell_bounds.max_x };
      }
      current_position = { lat: last_max_y + yModGap, lng: origin.lng + xEdgeGap };
      block_position = { ...origin, lat: last_max_y };
    }

    if (redraw) {
      let disabled_indexes = canopy.block_arr.filter((block) => !block.properties.enabled);
      for (let i = 0; i < disabled_indexes.length; i++) {
        let found_cell = block_arr.findIndex((_block) => arraysMatch(_block.properties.indexes, disabled_indexes[i].properties.indexes));
        if (found_cell >= 0) {
          block_arr[found_cell].properties.enabled = false;
          cell_arr[found_cell].properties.enabled = false;
        }
      }
    }

    return { cell_arr, block_arr };
  };

  const handleRectangleDraw = (e) => {
    let origin = e.latlng;
    /*
      using this origin point, template selected, and module selected
      we can create a brand new canopy object and pass to the store
      eventually would be nice to dispatch the origin to store and 
      have everything done there (single source of truth for logic and data)      
      */
    let num = Object.keys(carport.rectangles).length + 1;
    let canopy_object = {
      id: create_UUID(),
      index: carport.currentCanopyIndex,
      origin: origin,
      geoJson: undefined,
      product_name: carport.prodModSelection.selectedCarportProduct.name,
      module: carport.prodModSelection.selectedCarportModule,
      ...JSON.parse(JSON.stringify(carport.prodModSelection.selectedCarportProduct)),
      module_dimensions: getModuleDimsByOrientation(carport.prodModSelection.selectedCarportProduct.orientation, carport.prodModSelection.selectedCarportModule),
      rotation: 0,
      azimuth: 180,
      towardAzimuth: 1,
      tiltToAzimuth: 7,
      tiltFromAzimuth: 7,
      dimensions: undefined,
      name: `Canopy ${num}`,
    };

    canopy_object.dimensions = { ...canopy_object.base_dimension };
    canopy_object.dimensions = fixProductDimensions(canopy_object);
    canopy_object.coords = getRectFromOrigin(origin, canopy_object.dimensions);
    canopy_object.geoJson = turf.polygon([canopy_object.coords]);
    canopy_object.geoJson.properties["rotation"] = 0;
    // creating visible geoJSON -- which is the underlying cells that makeup the canopy
    let block_cells = buildRectangleCells(origin, canopy_object);
    canopy_object.editCellsGeoJson = block_cells.cell_arr;
    canopy_object.block_arr = block_cells.block_arr;
    canopy_object.visibleGeoJson = buildVisibleRectangle(block_cells.block_arr);
    // canopy_object.modules = drawModules(canopy_object);
    canopy_object.azimuthHolder = getAzimuthRectPosition(canopy_object.geoJson.geometry.coordinates, canopy_object.rotation);
    canopy_object.alignmentLines = drawAlignmentLines(canopy_object);
    canopy_object.alignmentCircles = drawAlignmentCircles(canopy_object.coords);

    // only send canopy_obect
    dispatch(carportActions.createCanopy(canopy_object));
    // set the county and state location when a canopy os drawn. This seemed much more performant then putting it the componentDidUpdate lifecycle method
    dispatch(carportActions.getCounty(canopy_object.coords[0][1], canopy_object.coords[0][0]));
  };

  // const getModuleFromInputs = () => {
  //   return {
  //     mlm_D2MuTau: portal.inputs.mlm_D2MuTau,
  //     mlm_E_g: portal.inputs.mlm_E_g,
  //     mlm_I_mp_ref: portal.inputs.mlm_I_mp_ref,
  //     mlm_I_sc_ref: portal.inputs.mlm_I_sc_ref,
  //     mod_height: portal.inputs.mod_height,
  //     mlm_N_diodes: portal.inputs.mlm_N_diodes,
  //     mlm_N_parallel: portal.inputs.mlm_N_parallel,
  //     mlm_N_series: portal.inputs.mlm_N_series,
  //     mlm_R_s: portal.inputs.mlm_R_s,
  //     mlm_R_sh0: portal.inputs.mlm_R_sh0,
  //     mlm_R_shexp: portal.inputs.mlm_R_shexp,
  //     mlm_R_shref: portal.inputs.mlm_R_shref,
  //     mlm_S_ref: portal.inputs.mlm_S_ref,
  //     mlm_T_c_fa_alpha: portal.inputs.mlm_T_c_fa_alpha,
  //     mlm_T_ref: portal.inputs.mlm_T_ref,
  //     mlm_V_mp_ref: portal.inputs.mlm_V_mp_ref,
  //     mlm_V_oc_ref: portal.inputs.mlm_V_oc_ref,
  //     mod_width: portal.inputs.mod_width,
  //     mlm_alpha_isc: portal.inputs.mlm_alpha_isc,
  //     mlm_beta_voc_spec: portal.inputs.mlm_beta_voc_spec,
  //     mlm_mu_n: portal.inputs.mlm_mu_n,
  //     mlm_n_0: portal.inputs.mlm_n_0,
  //     module_area: portal.inputs.module_area,
  //     module_bifaciality: portal.inputs.module_bifaciality,
  //     module_isbifacial: portal.inputs.module_isbifacial,
  //     muPmpReq: portal.inputs.muPmpReq,
  //     name: portal.inputs.name,
  //     rating: portal.inputs.rating,
  //     technology: portal.inputs.technology,
  //   };
  // };

  const duplicateRectangle = (id, direction = undefined) => {
    // get selected rectangle
    let copied_canopy = {
      ...JSON.parse(JSON.stringify(carport.rectangles[id])),
      id: create_UUID(),
    };
    // update name & index
    copied_canopy.copiedFrom = copied_canopy.name;
    copied_canopy.name = `${copied_canopy.name} (Copy)`;
    copied_canopy.index = carport.currentCanopyIndex;
    // figure out which way this canopy is being shifted
    let shift_angle = direction == "right" ? 90 : direction == "left" ? -90 : direction == "up" ? 0 : direction == "down" ? 180 : 0;
    // account for previous rotation
    shift_angle = shift_angle + copied_canopy.rotation;
    // figure out the distance canopy will be shifted
    let shift_dist = direction == "right" || direction == "left" ? copied_canopy.dimensions.x : direction == "up" || direction == "down" ? copied_canopy.dimensions.y : 5;
    // translate the canopy to new location
    let turfOptions = { units: "meters" };
    copied_canopy.geoJson = turf.transformTranslate(copied_canopy.geoJson, shift_dist, shift_angle, turfOptions);
    // grab new coords & update canopy properties
    let coords = turf.getCoords(copied_canopy.geoJson);
    copied_canopy.origin = { lat: coords[0][0][1], lng: coords[0][0][0] };
    copied_canopy.coords = coords[0];
    // redraw modules
    // copied_canopy.modules = drawModules(copied_canopy);
    let block_cells = buildRectangleCells(copied_canopy.origin, copied_canopy, true);
    copied_canopy.editCellsGeoJson = block_cells.cell_arr;
    copied_canopy.block_arr = block_cells.block_arr;
    copied_canopy.visibleGeoJson = buildVisibleRectangle(block_cells.block_arr);
    copied_canopy.alignmentLines = drawAlignmentLines(copied_canopy);
    copied_canopy.alignmentCircles = drawAlignmentCircles(copied_canopy.coords);
    copied_canopy.azimuthHolder = getAzimuthRectPosition(copied_canopy.geoJson.geometry.coordinates, copied_canopy.rotation);

    dispatch(carportActions.createCanopy(copied_canopy));
  };

  const getLayer = (id = undefined) => {
    let refLayer = getRef(id);
    if (refLayer.current) {
      let leaflet_index = refLayer.current.leafletElement._leaflet_id - 1;

      return refLayer.current.leafletElement._layers[leaflet_index];
    } else {
      return undefined;
    }
  };

  const updateRectangle = debounce((e, rotation = 0, canopies = undefined) => {
    setInputUpdateFromMap(true);
    let coords = undefined;
    let old_id = carport.rectangles[carport.selectedRectId].id;
    let new_canopy = {
      ...JSON.parse(JSON.stringify(carport.rectangles[carport.selectedRectId])),
      id: create_UUID(),
    };
    let fix_rotation_degrees = 0;

    if (e.type == "dragend") {
      // If this.state.alignmentCanopyIds, then we need to look to see which points are set to align from that
      // canopy and build this new canopy off of those coords

      if (canopies) {
        let canopyWithMostActiveAlginmentLines;
        let numberOfActiveLines = 0;
        Object.values(canopies).map((canopy) => {
          if (Object.values(canopy.checks).length > numberOfActiveLines) {
            numberOfActiveLines = Object.values(canopy.checks).length;
            canopyWithMostActiveAlginmentLines = canopy.id;
          }
        });

        let targetCanopy = carport.rectangles[canopyWithMostActiveAlginmentLines];

        let snap_azimuth = Math.abs(targetCanopy.azimuth - new_canopy.azimuth) <= 10;
        if (snap_azimuth) {
          new_canopy.azimuth = targetCanopy.azimuth;
          new_canopy.rotation = targetCanopy.rotation;
        }

        coords = cleanLatLng(e.target._latlngs[0]);
        let points = canopies[canopyWithMostActiveAlginmentLines].checks;
        let corner_circles = drawAlignmentCircles(coords[0]);
        let options = { units: "meters" };

        let point_keys = Object.keys(points);
        // check if this is a primary/easy case.. meaning its easy left/right/above/below with 2 matching points
        let easy_case = false;
        if (point_keys.length >= 2) {
          // could be easy case
          if ((points.p1_y && points.p2_y) || (points.p2_x && points.p3_x) || (points.p3_y && points.p4_y) || (points.p1_x && points.p4_x)) {
            easy_case = true;
          }
        }

        if (easy_case) {
          // This is the easiest possible case, obviously same sized canopy
          // and it's aligned perfectly on one face of the stationary/target canopy
          //
          let linePoint = undefined;
          if (points.p1_y && points.p2_y) {
            // TO THE LEFT
            linePoint = turf.getCoord(turf.flip(turf.nearestPointOnLine(targetCanopy.alignmentLines.p1_y, coords[0][0], options)));
          } else if (points.p2_x && points.p3_x) {
            // ABOVE
            linePoint = turf.getCoord(turf.flip(turf.nearestPointOnLine(targetCanopy.alignmentLines.p2_x, coords[0][0], options)));
          } else if (points.p3_y && points.p4_y) {
            // TO THE RIGHT
            linePoint = turf.getCoord(turf.flip(turf.nearestPointOnLine(targetCanopy.alignmentLines.p4_y, coords[0][0], options)));
          } else if (points.p1_x && points.p4_x) {
            // BELOW
            linePoint = turf.getCoord(turf.flip(turf.nearestPointOnLine(targetCanopy.alignmentLines.p1_x, coords[0][0], options)));
          }

          new_canopy.origin = { lat: linePoint[0], lng: linePoint[1] };
          new_canopy.coords = getRectFromOrigin(new_canopy.origin, new_canopy.dimensions, new_canopy.rotation);
        } else {
          // Every other case,
          // which typically should be 1 point (which is easiest of hard)
          // or >1, which means different faces (which defaults to 1 face based on current location)
          let best_intersection = undefined;
          point_keys.forEach((key) => {
            let intersections = [];
            let line = targetCanopy.alignmentLines[key];
            Object.values(corner_circles).forEach((circle) => {
              let intersection = turf.lineIntersect(line, circle);
              if (intersection.features.length > 0) {
                intersections.push(intersection);
              }
            });

            if (best_intersection == undefined || best_intersection.length < intersections.length) {
              best_intersection = { line, length: intersections.length };
            }
          });

          let intersection = turf.lineIntersect(best_intersection.line, corner_circles.p1);
          if (intersection.features.length > 0) {
            // intersects origin
            let linePoint = turf.getCoord(turf.flip(turf.nearestPointOnLine(best_intersection.line, coords[0][0], options)));
            new_canopy.origin = { lat: linePoint[0], lng: linePoint[1] };
            new_canopy.coords = getRectFromOrigin(new_canopy.origin, new_canopy.dimensions, new_canopy.rotation);
          } else {
            // not on origin
            let linePoint = turf.getCoord(turf.flip(turf.nearestPointOnLine(best_intersection.line, coords[0][2], options)));
            new_canopy.coords = getRectFromCounterOrigin({ lat: linePoint[0], lng: linePoint[1] }, new_canopy.dimensions, new_canopy.rotation);
            new_canopy.origin = { lat: new_canopy.coords[0][1], lng: new_canopy.coords[0][0] };
            new_canopy.coords = getRectFromOrigin(new_canopy.origin, new_canopy.dimensions, new_canopy.rotation);
          }
        }
        // console.log('Alignment Object', canopies[canopyWithMostActiveAlginmentLines]); //for testing
      } else {
        coords = cleanLatLng(e.target._latlngs[0]);
        new_canopy.origin = { lat: coords[0][0][1], lng: coords[0][0][0] };
        new_canopy.coords = getRectFromOrigin(new_canopy.origin, new_canopy.dimensions, new_canopy.rotation);

        let block_cells = buildRectangleCells(new_canopy.origin, new_canopy, true);
        new_canopy.editCellsGeoJson = block_cells.cell_arr;
        new_canopy.visibleGeoJson = block_cells.block_arr;
      }
      e.target.transform.disable();
      e.target.dragging.disable();
    } else if (e.type == "scaleend") {
      coords = cleanLatLng(e.layer._latlngs[0]);

      let options = { units: "meters" };
      let origin_point = turf.getCoord(turf.point([new_canopy.origin.lng, new_canopy.origin.lat]));
      let new_origin_point = turf.getCoord(turf.point([coords[0][0][0], coords[0][0][1]]));

      let y_dist = turf.distance(turf.point(coords[0][0]), turf.point(coords[0][1]), options);
      let base_y = new_canopy.module_dimensions.y * new_canopy.base_dimension.modY + (new_canopy.base_dimension.modY - 1) * new_canopy.modYGap + new_canopy.edgeOffset * 2;
      y_dist = Math.max(y_dist, base_y);
      let new_modY = Math.floor(y_dist / (new_canopy.modYGap + new_canopy.module_dimensions.y));

      let x_dist = turf.distance(turf.point(coords[0][0]), turf.point(coords[0][3]), options);
      let base_x = new_canopy.module_dimensions.x * new_canopy.base_dimension.modX + (new_canopy.base_dimension.modX - 1) * new_canopy.modXGap + new_canopy.edgeOffset * 2;
      x_dist = Math.max(x_dist, base_x);
      let new_modX = Math.floor(x_dist / (new_canopy.modXGap + new_canopy.module_dimensions.x));

      let new_lat = coords[0][0][1];
      let new_lng = coords[0][0][0];

      // 'left' = -90  'down' = -180
      // y diff (moved down if positive)
      // if (new_canopy.origin.lat - coords[0][0][1] > 0) {
      //   let y_origin_dist = turf.distance(turf.point(new_origin_point), turf.point(origin_point), options);
      //   let y_translate = y_origin_dist - (y_origin_dist % (new_canopy.module_dimensions.y + new_canopy.modYGap));
      //   let y_translated = turf.getCoord(turf.transformTranslate(turf.point(origin_point), y_translate, -180, options));
      //   new_lat = y_translated[1];
      // }

      // x diff (moved left if positive)
      // if (new_canopy.origin.lng - coords[0][0][0] > 0) {
      //   let x_origin_dist = turf.distance(turf.point(new_origin_point), turf.point(origin_point), options);
      //   let x_translate = x_origin_dist - (x_origin_dist % (new_canopy.module_dimensions.x + new_canopy.modXGap));
      //   let x_translated = turf.getCoord(turf.transformTranslate(turf.point(origin_point), x_translate, -90, options));
      //   new_lng = x_translated[0];
      // }

      let modX_change = Math.abs(new_canopy.dimensions.modX - new_modX) > 0;
      let modY_change = Math.abs(new_canopy.dimensions.modY - new_modY) > 0;
      if (modX_change || modY_change) {
        new_canopy.origin = { lat: new_lat, lng: new_lng };
      }

      new_canopy.dimensions = {
        x: new_canopy.dimensions.x,
        y: new_canopy.dimensions.y,
        modX: new_modX,
        modY: new_modY,
      };
      new_canopy.dimensions = fixProductDimensions(new_canopy);

      if (new_canopy.planeType == "dual_tilt") {
        if (new_canopy.dimensions.modY < 3) {
          new_canopy.dimensions.modY = 3;
        } else if (new_canopy.dimensions.modY % 2 != 0) {
          new_canopy.dimensions.modY = new_canopy.dimensions.modY + 1;
        }
        new_canopy.dimensions = fixProductDimensions(new_canopy);
      }
      // let block_cells = buildRectangleCells(new_canopy.origin, new_canopy, true);
      // new_canopy.editCellsGeoJson = block_cells.cell_arr;
      // new_canopy.visibleGeoJson = block_cells.block_arr;
      new_canopy.coords = getRectFromOrigin(new_canopy.origin, new_canopy.dimensions, new_canopy.rotation);

      e.layer.transform.disable();
      e.layer.dragging.disable();
    } else if (e.type == "rotateend") {
      coords = cleanLatLng(e.layer._latlngs[0]);
      new_canopy.origin = { lat: coords[0][0][1], lng: coords[0][0][0] };

      let rot_deg = rotation * (180 / Math.PI);
      fix_rotation_degrees = rot_deg > 180 ? rot_deg - 360 : rot_deg;
      fix_rotation_degrees = Math.round(fix_rotation_degrees, 0);
      new_canopy.coords = getRectFromOrigin(new_canopy.origin, new_canopy.dimensions, new_canopy.rotation + fix_rotation_degrees);

      new_canopy.geoJson.properties["rotation"] = new_canopy.rotation + fix_rotation_degrees;
      // let block_cells = buildRectangleCells(new_canopy.origin, new_canopy, true);
      // new_canopy.editCellsGeoJson = block_cells.cell_arr;
      // new_canopy.block_arr = block_cells.block_arr;
      // new_canopy.visibleGeoJson = buildVisibleRectangle(new_canopy.block_arr);

      e.layer.transform.disable();
      e.layer.dragging.disable();
    }

    // new_canopy.rotation += fix_rotation_degrees;
    // new_canopy.rotation = new_canopy.rotation > 180 ? new_canopy.rotation - 360 :new_canopy.rotation;

    new_canopy.azimuth += fix_rotation_degrees;
    // new_canopy.azimuth += new_canopy.rotation + 180;
    // new_canopy.azimuth = new_canopy.azimuth > 360 ? new_canopy.azimuth - 360 : new_canopy.azimuth;
    new_canopy.azimuth = new_canopy.azimuth < 0 ? new_canopy.azimuth + 360 : new_canopy.azimuth;
    new_canopy.azimuth = Math.abs(new_canopy.azimuth) % 360;

    new_canopy.rotation = new_canopy.azimuth - 180;

    // console.log(new_canopy.rotation, new_canopy.azimuth)
    // this is the underlying polygon
    new_canopy.geoJson = turf.polygon([new_canopy.coords]);
    // this is the visible polygon, built from the visible cells
    // new_canopy.editCellsGeoJson = turf.polygon([new_canopy.coords]);

    new_canopy.geoJson.properties["rotation"] = new_canopy.rotation;
    let block_cells = buildRectangleCells(new_canopy.origin, new_canopy, true);
    new_canopy.editCellsGeoJson = block_cells.cell_arr;
    new_canopy.block_arr = block_cells.block_arr;
    new_canopy.visibleGeoJson = buildVisibleRectangle(block_cells.block_arr);

    new_canopy.alignmentLines = drawAlignmentLines(new_canopy);
    new_canopy.alignmentCircles = drawAlignmentCircles(new_canopy.coords);
    new_canopy.azimuthHolder = getAzimuthRectPosition(new_canopy.geoJson.geometry.coordinates, new_canopy.rotation);
    dispatch(carportActions.updateCanopy(old_id, new_canopy));
    setMovingRectId(undefined);
    setInputUpdateFromMap(false);
  }, 200);

  const updateFromInputs = debounce((type, value) => {
    setInputUpdateFromMap(true);
    let layer = getLayer(currentlySelectedCanopy.current);
    if (!layer) layer = currentEditingLayer;
    let old_id = carport.rectangles[carport.selectedRectId].id;
    let new_canopy = {
      ...JSON.parse(JSON.stringify(carport.rectangles[carport.selectedRectId])),
      id: create_UUID(),
    };

    let fix_rotation_degrees = 0;

    if (type == "updateDimension") {
      // let origin = { lat: rect.geoJson.geometry.coordinates[0][0][1], lng: rect.geoJson.geometry.coordinates[0][0][0] };
      // let new_dimension = { x: value.xDim, y: value.yDim };

      new_canopy.dimensions.modX = Math.max(value.xDim, new_canopy.base_dimension.modX);
      new_canopy.dimensions.modY = Math.max(value.yDim, new_canopy.base_dimension.modY);
      new_canopy.dimensions = fixProductDimensions(new_canopy);
      new_canopy.coords = getRectFromOrigin(new_canopy.origin, new_canopy.dimensions, new_canopy.rotation);
    }
    if (type == "updateAzimuth") {
      let azi = parseInt(value.azimuth);
      if (isNaN(azi)) return;
      let new_azimuth = Math.abs(value.azimuth) % 360;
      new_canopy.azimuth = new_azimuth;
      let rotation_diff = new_azimuth - 180 - new_canopy.rotation;
      new_canopy.rotation = new_azimuth - 180;

      let centroid = turf.centroid(new_canopy.geoJson);
      let turfOptions = { pivot: [centroid.geometry.coordinates[0], centroid.geometry.coordinates[1]] };
      let rotatedPoly = turf.transformRotate(new_canopy.geoJson, rotation_diff || 0, turfOptions);
      let coords = turf.getCoords(rotatedPoly);

      new_canopy.origin = { lat: coords[0][0][1], lng: coords[0][0][0] };
      new_canopy.coords = coords[0];
    }

    if (type == "updateModuleDimension") {
      new_canopy.module = { ...new_canopy.module, ...value };

      new_canopy.module_dimensions = value;
      new_canopy.module_dimensions = getModuleDimsByOrientation(new_canopy.orientation, new_canopy.module_dimensions);
      new_canopy.dimensions = fixProductDimensions(new_canopy);
      new_canopy.coords = getRectFromOrigin(new_canopy.origin, new_canopy.dimensions, new_canopy.rotation);
    }

    if (type == "changeModuleOrientation") {
      new_canopy.orientation = value;
      // { mod_width: new_canopy.module_dimensions.x, mod_height: new_canopy.module_dimensions.y }
      new_canopy.module_dimensions = getModuleDimsByOrientation(new_canopy.orientation, new_canopy.module_dimensions);
      new_canopy.dimensions = calculateModuleDimensions(new_canopy);

      new_canopy.dimensions = fixProductDimensions(new_canopy);
      new_canopy.coords = getRectFromOrigin(new_canopy.origin, new_canopy.dimensions);

      layer.transform.disable();
      layer.dragging.disable();

      new_canopy.geoJson = turf.polygon([new_canopy.coords]);

      // handle canopy rotation during orientation change
      let pivotOptions = { pivot: [new_canopy.geoJson.geometry.coordinates[0][0][0], new_canopy.geoJson.geometry.coordinates[0][0][1]] };
      new_canopy.geoJson = turf.transformRotate(new_canopy.geoJson, new_canopy.rotation, pivotOptions);
      // new_canopy.modules = drawModules(new_canopy);

      let block_cells = buildRectangleCells(new_canopy.origin, new_canopy, true);
      new_canopy.editCellsGeoJson = block_cells.cell_arr;
      new_canopy.block_arr = block_cells.block_arr;
      new_canopy.visibleGeoJson = buildVisibleRectangle(new_canopy.block_arr);

      new_canopy.alignmentLines = drawAlignmentLines(new_canopy);
      new_canopy.alignmentCircles = drawAlignmentCircles(new_canopy.coords);

      new_canopy.azimuthHolder = getAzimuthRectPosition(new_canopy.geoJson.geometry.coordinates, new_canopy.rotation);
      dispatch(carportActions.updateCanopy(old_id, new_canopy));

      //return out of here so that we do not redraw and rest everything again below
      return;
    }

    if (type == "setCanopyModule") {
      // called when the user changes the module within the selected canopy panel
      new_canopy.module = carport.modules[value];
      dispatch(carportActions.prodModSelect("selectedCarportModule", value));

      new_canopy.module_dimensions = getModuleDimsByOrientation(new_canopy.orientation, new_canopy.module);
      new_canopy.dimensions = fixProductDimensions(new_canopy);
      new_canopy.coords = getRectFromOrigin(new_canopy.origin, new_canopy.dimensions, new_canopy.rotation);
    }

    if (type == "changePlaneType") {
      new_canopy.planeType = value;
      if (value == "dual_tilt") {
        if (new_canopy.dimensions.modY < 3) {
          new_canopy.dimensions.modY = 3;
        } else if (new_canopy.dimensions.modY % 2 != 0) {
          new_canopy.dimensions.modY = new_canopy.dimensions.modY + 1;
        }
        new_canopy.dimensions = fixProductDimensions(new_canopy);
      }
      new_canopy.coords = getRectFromOrigin(new_canopy.origin, new_canopy.dimensions, new_canopy.rotation);
    }
    if (type == "changeTilt") {
      new_canopy.tilt = value;
    }
    if (type == "changeTowardAzimuth") {
      new_canopy.towardAzimuth = value;
    }
    if (type == "changeTiltToAzimuth") {
      new_canopy.tiltToAzimuth = value;
    }
    if (type == "changeTiltFromAzimuth") {
      new_canopy.tiltFromAzimuth = value;
    }
    if (type == "modGap") {
      // both shared for now
      new_canopy.modXGap = value;
      new_canopy.modYGap = value;
      new_canopy.dimensions = fixProductDimensions(new_canopy);
      new_canopy.coords = getRectFromOrigin(new_canopy.origin, new_canopy.dimensions, new_canopy.rotation);
    }

    layer.transform.disable();
    layer.dragging.disable();
    new_canopy.geoJson = turf.polygon([new_canopy.coords]);
    new_canopy.geoJson.properties["rotation"] = new_canopy.rotation;
    // new_canopy.modules = drawModules(new_canopy);
    let block_cells = buildRectangleCells(new_canopy.origin, new_canopy, true);
    new_canopy.editCellsGeoJson = block_cells.cell_arr;
    new_canopy.block_arr = block_cells.block_arr;
    new_canopy.visibleGeoJson = buildVisibleRectangle(new_canopy.block_arr);

    new_canopy.alignmentLines = drawAlignmentLines(new_canopy);
    new_canopy.alignmentCircles = drawAlignmentCircles(new_canopy.coords);
    new_canopy.azimuthHolder = getAzimuthRectPosition(new_canopy.geoJson.geometry.coordinates, new_canopy.rotation);
    dispatch(carportActions.updateCanopy(old_id, new_canopy));
    setInputUpdateFromMap(true);
  }, 250);

  const applyGlobalOverrides = (type = undefined, value) => {
    let canopies = {
      ...JSON.parse(JSON.stringify(carport.rectangles)),
    };

    if (type == "setGlobalTemplate") {
      Object.values(canopies).map((canopy) => {
        let layer = getLayer(canopy.id);
        let old_id = canopy.id;
        canopy.id = create_UUID(); // new ID to force update

        canopy.base_dimension.modX = value.globalProduct.base_dimension.modX;
        canopy.base_dimension.modY = value.globalProduct.base_dimension.modY;
        canopy.edgeOffset = value.globalProduct.edgeOffset;
        canopy.modXGap = value.globalProduct.modXGap;
        canopy.planeType = value.globalProduct.planeType;
        canopy.orientation = value.globalProduct.orientation;
        canopy.tilt = value.globalProduct.tilt;
        canopy.tiltFromAzimuth = value.globalProduct.tiltFromAzimuth;
        canopy.tiltToAzimuth = value.globalProduct.tiltToAzimuth;
        canopy.towardAzimuth = value.globalProduct.towardAzimuth;

        canopy.module_dimensions = getModuleDimsByOrientation(canopy.orientation, canopy.module);
        canopy.dimensions = calculateModuleDimensions(canopy);

        canopy.dimensions = fixProductDimensions(canopy);
        canopy.coords = getRectFromOrigin(canopy.origin, canopy.dimensions);

        layer.transform.disable();
        layer.dragging.disable();

        canopy.geoJson = turf.polygon([canopy.coords]);

        let pivotOptions = { pivot: [canopy.geoJson.geometry.coordinates[0][0][0], canopy.geoJson.geometry.coordinates[0][0][1]] };
        canopy.geoJson = turf.transformRotate(canopy.geoJson, canopy.rotation, pivotOptions);
        // canopy.modules = drawModules(canopy);
        let block_cells = buildRectangleCells(canopy.origin, canopy, true);
        canopy.editCellsGeoJson = block_cells.cell_arr;
        canopy.block_arr = block_cells.block_arr;
        canopy.visibleGeoJson = buildVisibleRectangle(block_cells.block_arr);
        canopy.alignmentLines = drawAlignmentLines(canopy);
        canopy.alignmentCircles = drawAlignmentCircles(canopy.coords);
        canopy.azimuthHolder = getAzimuthRectPosition(canopy.geoJson.geometry.coordinates, canopy.rotation);

        dispatch(carportActions.updateCanopy(old_id, canopy));
      });
      message.success(`${value.globalProduct.name} template has been applied to all Canopies`, 5);
      return;
    }

    if (type == "setGlobalModule") {
      Object.values(canopies).map((canopy) => {
        let layer = getLayer(canopy.id);
        let old_id = canopy.id;
        canopy.id = create_UUID();
        canopy.module = value.globalModule;

        canopy.module_dimensions = getModuleDimsByOrientation(canopy.orientation, canopy.module);
        // canopy.dimensions = calculateModuleDimensions(canopy);

        canopy.dimensions = fixProductDimensions(canopy);
        canopy.coords = getRectFromOrigin(canopy.origin, canopy.dimensions);

        layer.transform.disable();
        layer.dragging.disable();

        canopy.geoJson = turf.polygon([canopy.coords]);

        let pivotOptions = { pivot: [canopy.geoJson.geometry.coordinates[0][0][0], canopy.geoJson.geometry.coordinates[0][0][1]] };
        canopy.geoJson = turf.transformRotate(canopy.geoJson, canopy.rotation, pivotOptions);
        // canopy.modules = drawModules(canopy);
        let block_cells = buildRectangleCells(canopy.origin, canopy, true);
        canopy.editCellsGeoJson = block_cells.cell_arr;
        canopy.block_arr = block_cells.block_arr;
        canopy.visibleGeoJson = buildVisibleRectangle(block_cells.block_arr);

        canopy.alignmentLines = drawAlignmentLines(canopy);
        canopy.alignmentCircles = drawAlignmentCircles(canopy.coords);
        canopy.azimuthHolder = getAzimuthRectPosition(canopy.geoJson.geometry.coordinates, canopy.rotation);
        dispatch(carportActions.updateCanopy(old_id, canopy));
      });
      message.success(`${value.globalModule.name} has been applied to all Canopies`, 5);
      return;
    }

    if (type == "setGlobalOrientation") {
      Object.values(canopies).map((canopy) => {
        let layer = getLayer(canopy.id);
        let old_id = canopy.id;
        canopy.id = create_UUID();
        canopy.orientation = value;

        canopy.module_dimensions = getModuleDimsByOrientation(canopy.orientation, canopy.module);
        canopy.dimensions = calculateModuleDimensions(canopy);

        canopy.dimensions = fixProductDimensions(canopy);
        canopy.coords = getRectFromOrigin(canopy.origin, canopy.dimensions);

        layer.transform.disable();
        layer.dragging.disable();

        canopy.geoJson = turf.polygon([canopy.coords]);

        // handle canopy rotation during orientation change
        let pivotOptions = { pivot: [canopy.geoJson.geometry.coordinates[0][0][0], canopy.geoJson.geometry.coordinates[0][0][1]] };
        canopy.geoJson = turf.transformRotate(canopy.geoJson, canopy.rotation, pivotOptions);
        // canopy.modules = drawModules(canopy);
        let block_cells = buildRectangleCells(canopy.origin, canopy, true);
        canopy.editCellsGeoJson = block_cells.cell_arr;
        canopy.block_arr = block_cells.block_arr;
        canopy.visibleGeoJson = buildVisibleRectangle(block_cells.block_arr);

        canopy.alignmentLines = drawAlignmentLines(canopy);
        canopy.alignmentCircles = drawAlignmentCircles(canopy.coords);

        canopy.azimuthHolder = getAzimuthRectPosition(canopy.geoJson.geometry.coordinates, canopy.rotation);
        dispatch(carportActions.updateCanopy(old_id, canopy));
      });
      message.success(`${value == 1 ? "Portait" : "Landscape"} Orientation has been applied to all Canopies`, 5);
      return;
    }

    if (type == "setGlobalAzimuth") {
      Object.values(canopies).map((canopy) => {
        let layer = getLayer(canopy.id);
        let old_id = canopy.id;
        canopy.id = create_UUID(); //new id to force update
        let new_azimuth = Math.min(Math.max(value, 0), 360);
        canopy.azimuth = new_azimuth;
        let rotation_diff = new_azimuth - 180 - canopy.rotation;
        canopy.rotation = new_azimuth - 180;

        let centroid = turf.centroid(canopy.geoJson);
        let turfOptions = { pivot: [centroid.geometry.coordinates[0], centroid.geometry.coordinates[1]] };
        let rotatedPoly = turf.transformRotate(canopy.geoJson, rotation_diff, turfOptions);
        let coords = turf.getCoords(rotatedPoly);

        canopy.origin = { lat: coords[0][0][1], lng: coords[0][0][0] };
        canopy.coords = coords[0];

        layer.transform.disable();
        layer.dragging.disable();
        canopy.geoJson = turf.polygon([canopy.coords]);
        // canopy.modules = drawModules(canopy);
        let block_cells = buildRectangleCells(canopy.origin, canopy, true);
        canopy.editCellsGeoJson = block_cells.cell_arr;
        canopy.block_arr = block_cells.block_arr;
        canopy.visibleGeoJson = buildVisibleRectangle(block_cells.block_arr);

        canopy.alignmentLines = drawAlignmentLines(canopy);
        canopy.alignmentCircles = drawAlignmentCircles(canopy.coords);
        canopy.azimuthHolder = getAzimuthRectPosition(canopy.geoJson.geometry.coordinates, canopy.rotation);
        dispatch(carportActions.updateCanopy(old_id, canopy));
      });
      message.success(`${value}° Azimuth has been applied to all Canopies`, 5);

      return;
    }

    if (type == "setGlobalTilt") {
      Object.values(canopies).map((canopy) => {
        let layer = getLayer(canopy.id);
        let old_id = canopy.id;
        canopy.tilt = value;
        canopy.id = create_UUID(); //new id to force update
        dispatch(carportActions.updateCanopy(old_id, canopy));
      });
      message.info(`${value}° Tilt has been applied to all Canopies`, 5);
      return;
    }

    if (type == "setGlobalPlaneType") {
      Object.values(canopies).map((canopy) => {
        let layer = getLayer(canopy.id);
        let old_id = canopy.id;
        canopy.planeType = value;

        layer.transform.disable();
        layer.dragging.disable();

        // canopy.modules = drawModules(canopy);
        let block_cells = buildRectangleCells(canopy.origin, canopy, true);
        canopy.editCellsGeoJson = block_cells.cell_arr;
        canopy.block_arr = block_cells.block_arr;
        canopy.visibleGeoJson = buildVisibleRectangle(block_cells.block_arr);

        canopy.id = create_UUID(); //new id to force update
        dispatch(carportActions.updateCanopy(old_id, canopy));
      });
      message.info(`${prettifyText(value)} plane type has been applied to all Canopies`, 5);
      return;
    }
  };

  const stopEditing = (id) => {
    if (!id) return;
    let layer = getLayer(id);

    if (!layer) {
      layer = currentEditingLayer;
    }
    // disable editing
    layer.transform.disable();
    layer.dragging.disable();
    layer.off("rotateend");
    layer.off("dragend");
    layer.off("scaleend");
    layer.off("drag");
    layer.off("rotatestart");
    layer.off("dragstart");
    layer.off("scalestart");
  };

  const startEditing = (id) => {
    // dispatch(carportActions.setActiveTool(undefined));

    let layer = getLayer(id);
    setCurrentEditingLayer(layer);
    if (layer == undefined) return;
    // enable editing
    layer.transform.enable();
    layer.dragging.enable();
    layer.transform.setOptions({ uniformScaling: false });
    // layer.setStyle({color: '#333', fillColor: '#60de4f', opacity: '0.8'})

    layer.on("rotateend", (e) => {
      updateRectangle(e, e.rotation);
    });

    layer.on("dragstart", (e) => {
      // filter out the canopy that is seleceted as to compare the selected against all the other canopies
      if (alignmentToolOn.current) {
        let filteredAlignmentCanopies = Object.values(carport.rectangles).filter((rectangle) => rectangle.id != carport.selectedRectId);
        // setFilteredCanopies([...filteredAlignmentCanopies]);
        availableAlignmentCanopies.current = [...filteredAlignmentCanopies];
      }
    });

    layer.on("drag", (e) => {
      if (alignmentToolOn.current) {
        // this.checkAlignment(e);
        checkCanopyAlignment(e);
      }
    });

    layer.on("dragend", (e) => {
      updateRectangle(e, e.rotation, alignmentCanopyIds.current);
      alignmentCanopyIds.current = undefined;
      forceUpdate();
      // setAlignmentCanopyIds(undefined);
    });

    layer.on("scaleend", (e) => {
      updateRectangle(e);
    });

    layer.on("rotatestart", (e) => {
      setMovingRectId(carport.selectedRectId);
    });

    layer.on("dragstart", (e) => {
      setMovingRectId(carport.selectedRectId);
    });

    layer.on("scalestart", (e) => {
      setMovingRectId(carport.selectedRectId);
    });
  };

  const copyRectangle = (msg = undefined) => {
    if (carport.selectedRectId) {
      dispatch(carportActions.copyRectangle(carport.selectedRectId));
      if (carport.copiedRectangle && msg) {
        message.info(msg, 5);
      }
    }
  };

  const pasteRectangle = (latlng) => {
    let origin = latlng;

    let copied_canopy = {
      ...JSON.parse(JSON.stringify(carport.copiedRectangle)),
      id: create_UUID(),
    };
    // update name & index
    let found_name = Object.values(carport.rectangles).findIndex((x) => x.name == `${copied_canopy.name} (Copy)`);
    copied_canopy.copiedFrom = copied_canopy.name;
    copied_canopy.name = `${copied_canopy.name} (Copy)`;
    copied_canopy.index = carport.currentCanopyIndex;

    copied_canopy.dimensions = { ...copied_canopy.dimensions };
    copied_canopy.dimensions = fixProductDimensions(copied_canopy);
    copied_canopy.coords = getRectFromOrigin(origin, copied_canopy.dimensions);
    copied_canopy.geoJson = turf.polygon([copied_canopy.coords]);

    copied_canopy.origin = origin;
    // rotate canopy if it has a rotation
    let pivotOptions = { pivot: [origin.lng, origin.lat] };
    copied_canopy.geoJson = turf.transformRotate(copied_canopy.geoJson, copied_canopy.rotation, pivotOptions);
    // copied_canopy.modules = drawModules(copied_canopy);
    let block_cells = buildRectangleCells(copied_canopy.origin, copied_canopy, true);
    copied_canopy.editCellsGeoJson = block_cells.cell_arr;
    copied_canopy.block_arr = block_cells.block_arr;
    copied_canopy.visibleGeoJson = buildVisibleRectangle(block_cells.block_arr);

    copied_canopy.alignmentLines = drawAlignmentLines(copied_canopy);
    copied_canopy.alignmentCircles = drawAlignmentCircles(copied_canopy.coords);
    copied_canopy.azimuthHolder = getAzimuthRectPosition(copied_canopy.geoJson.geometry.coordinates, copied_canopy.rotation);
    // only send canopy_obect
    dispatch(carportActions.createCanopy(copied_canopy));
    canopyMap.current.leafletElement.off("mousemove", null);
  };

  const toggleAlignmentTool = () => {
    if (carport.activeTool == undefined && alignmentToolOn.current === false) {
      // setAlignmentToolActive(true);
      alignmentToolOn.current = true;
      forceUpdate();
    } else {
      // setAlignmentToolActive(false);
      alignmentToolOn.current = false;
      forceUpdate();
    }
  };

  const toggleNPEditor = () => {
    if (!carport.selectedRectId) {
      message.info("You must have a Canopy selected to activate the non-rectangular editor");
      return;
    }

    // dispatch(carportActions.toggleNonPolyEdit());

    if (carport.activeTool == undefined && nonPolygonalEditOn.current === false && currentlySelectedCanopy.current) {
      // setAlignmentToolActive(true);
      // setCurrentNonPolygonalEditingId(currentlySelectedCanopy.current);
      // setCurrentNonPolyRect(currentRectangles.current[currentlySelectedCanopy.current])
      setCurrentNonPolyRect(carport.rectangles[carport.selectedRectId]);
      stopEditing(carport.selectedRectId);
      nonPolygonalEditOn.current = true;
      set_do_update_rect(false);
    } else {
      set_do_update_rect(true);
      console.log(non_poly_cells_and_blocks.cells);
      console.log(non_poly_cells_and_blocks.blocks);
      // let new_id = create_UUID();
      // let new_canopy = {
      // 	...JSON.parse(JSON.stringify(carport.rectangles[currentlySelectedCanopy.current])),
      // 	id: new_id,
      // 	editCellsGeoJson: non_poly_cells_and_blocks.cells,
      // 	block_arr: non_poly_cells_and_blocks.blocks,
      // 	visibleGeoJson: buildVisibleRectangle(_bock_arr)
      // };
      // dispatch(carportActions.updateCanopy(id, new_canopy));

      nonPolygonalEditOn.current = false;
      setCurrentNonPolyRect(undefined);
      currentlySelectedCanopy.current = undefined;
      // dispatch(carportActions.selectRectangle(new_id))
    }
    forceUpdate();
  };

  const moduleSelectBoxCheck = (box) => {
    if (carport.rectangles[currentlySelectedCanopy.current]) {
      let new_canopy = {
        ...JSON.parse(JSON.stringify(carport.rectangles[currentlySelectedCanopy.current])),
        id: create_UUID(),
      };

      let new_editCellsGeoJson = new_canopy.editCellsGeoJson;
      let new_visibleGeoJson = new_canopy.block_arr;

      Object.values(new_canopy.editCellsGeoJson).map((cell, index) => {
        // console.log('cell contained', turf.booleanContains(cell, box));
        // console.log('cell overlaps', turf.booleanOverlap(box, cell));
        let centroid = turf.buffer(turf.centroid(cell), 0.25, { units: "meters" });
        if (turf.booleanContains(box, centroid)) {
          new_editCellsGeoJson[index].properties.enabled = !new_editCellsGeoJson[index].properties.enabled;
          new_visibleGeoJson[index].properties.enabled = !new_visibleGeoJson[index].properties.enabled;
        }
      });
      new_canopy.editCellsGeoJson = new_editCellsGeoJson;
      let visGeo = buildVisibleRectangle(new_visibleGeoJson);
      if (visGeo) {
        new_canopy.visibleGeoJson = visGeo;
        new_canopy.block_arr = new_visibleGeoJson;
      } else {
        return;
      }

      dispatch(carportActions.updateCanopy(currentlySelectedCanopy.current, new_canopy));
      currentlySelectedCanopy.current = new_canopy.id;
    }
  };

  const checkCanopyAlignment = (e) => {
    // grab latlngs from moving canopy
    let LatLngs = e.target.getLatLngs();
    let currentCoords = cleanLatLng(LatLngs[0]);
    let options = { units: "meters" };
    // create a few variables to be used in checking alignment
    let poly = turf.polygon(currentCoords);
    let center = turf.centroid(poly);
    let area = turf.area(poly);
    let bounds = getBounds(currentCoords[0]);
    let range_x = [bounds.min_x, bounds.max_x];
    let range_y = [bounds.min_y, bounds.max_y];
    let m_pt1 = [bounds.min_x, bounds.min_y];
    let m_pt2 = [bounds.min_x, bounds.max_y];
    let m_pt3 = [bounds.max_x, bounds.max_y];
    let m_pt4 = [bounds.max_x, bounds.min_y];
    let m_midX = turf.midpoint(m_pt2, m_pt3).geometry.coordinates[0];
    let m_midY = turf.midpoint(m_pt1, m_pt2).geometry.coordinates[1];
    // this is the threshold to toggle on the alignment lines.
    // the lat/lng positions of points must be below this to trigger an alignment line
    // let threshold = 0.000004;
    let threshold = 0.00002; // for testing so you can visually see the alignment happen in the map
    let found = false;
    let foundCanopies = {};
    let newCircles = drawAlignmentCircles(currentCoords[0]);
    let currentCanopy = carport.rectangles[carport.selectedRectId];

    Object.values(availableAlignmentCanopies.current).forEach((rectangle) => {
      // first check the distance and make sure it's close
      let dist_center = turf.distance(center, turf.centroid(rectangle.geoJson), options);
      // area/2 is arbitrary value but works..
      // check azimuth as well
      let azi_check =
        Math.abs(currentCanopy.azimuth - rectangle.azimuth) <= 10 || Math.abs(currentCanopy.azimuth + 90 - rectangle.azimuth) <= 10 || Math.abs(currentCanopy.azimuth - 90 - rectangle.azimuth) <= 10;
      if (dist_center <= area / 2 && azi_check) {
        // figure out which type of checks we are doing (x_checks vs y_checks)
        let bounds2 = getBounds(rectangle.coords);
        // check if this canopy's lat/lngs overlap with the moving canopy
        // copy the checks_og structure to use here
        let checks = JSON.parse(JSON.stringify(checks_og));

        Object.keys(rectangle.alignmentLines).forEach((lineKey) => {
          let line = rectangle.alignmentLines[lineKey];
          Object.values(newCircles).forEach((circle) => {
            let intersection = turf.lineIntersect(line, circle);
            // console.log(intersection)
            if (intersection.features.length > 0) {
              // console.log(`corner intersects with alignment line on key ${lineKey}`)
              // find which point is being intersected with
              // p1_x, p2_x, p3_x, or p4_x
              // p1_Y, p2_Y, p3_Y, or p4_Y
              checks[lineKey] = true;
              found = true;
            }
          });
        });

        // if the canopy is in range, add it to the foundCanopies object
        foundCanopies = { ...foundCanopies, [rectangle.id]: { id: rectangle.id, checks } };
      } else if (!dist_center <= area / 2) {
        // remove canopy from foundCanopies object if it goes out of range
        delete foundCanopies[rectangle.id];
      }
    });

    //run through the foundCanopies object to see which points are true/false and add only true
    Object.values(foundCanopies).map((canopy) => {
      let canopyId = canopy.id;
      let checks = {};
      Object.keys(canopy.checks).map((key) => {
        if (canopy.checks[key] == true) {
          checks = { ...checks, [key]: canopy.checks[key] };
        }
      });
      foundCanopies = { ...foundCanopies, [canopyId]: { id: canopyId, checks } };
    });

    // loop through again to determine if canopies.checks is empty(all false)
    Object.values(foundCanopies).map((canopy) => {
      if (Object.values(canopy.checks).length == 0) {
        delete foundCanopies[canopy.id];
      }
    });

    if (Object.values(foundCanopies).length == 0) {
      // setAlignmentCanopyIds(undefined);
      alignmentCanopyIds.current = undefined;
      forceUpdate();
    } else {
      // console.log('found canopies', foundCanopies);
      // setAlignmentCanopyIds({ ...foundCanopies });
      alignmentCanopyIds.current = { ...foundCanopies };
      // forceupdate to cause a re-render so the above object updates are readable to render
      forceUpdate();
    }
  };

  const selectImageId = (imgKey) => {
    if (imgKey == carport.selectedImageId) return;
    dispatch(carportActions.selectImage(imgKey));
  };

  const updateImage = (type = undefined, value = undefined) => {
    let id = carport.selectedImageId;
    let image = carport.images[id];

    if (type == "updateCorners") {
      image.corners = value;
    }

    if (type == "changeOpacity") {
      image.opacity = value / 100;
    }

    dispatch(carportActions.updateImage(id, image));
  };

  const removeImageLayer = (key) => {
    var image_x = document.getElementsByClassName(key);
    let deleting = true;
    let runCount = 0;
    while (deleting) {
      // tried using a for loop, but whenever you removeChild
      // it also removes that from the image_x list
      image_x[0].parentNode.removeChild(image_x[0]);
      runCount++;
      // exit loop when image_x list is depleted or has ran more than 4 times (prevent inf loops)
      if (image_x.length == 0 || runCount > 4) {
        deleting = false;
      }
    }
  };

  const handleCanopyZoomExtents = (zoomLevel = undefined) => {
    // check to make sure there are canopies present on the map
    if (Object.values(carport.rectangles).length == 0) return;
    // define canopy feature array
    let allCanopyFeatures = [];
    // loop through all the canopies and push the geoJson into allCanopyFeatures array
    Object.values(carport.rectangles).map((canopy) => {
      allCanopyFeatures.push(canopy.geoJson);
    });

    // get the bounds of the features collection
    let bounds = getBoundingBox(allCanopyFeatures);

    if (zoomLevel) {
      canopyMap.current.leafletElement.fitBounds(
        [
          [bounds[1], bounds[0]],
          [bounds[3], bounds[2]],
        ],
        { maxZoom: zoomLevel }
      );
    } else {
      canopyMap.current.leafletElement.fitBounds([
        [bounds[1], bounds[0]],
        [bounds[3], bounds[2]],
      ]);
    }
  };

  const handleRectangleCreated = (e) => {
    let new_id = create_UUID();
    dispatch(carportActions.createRectangle(e.layer, new_id));
    // Remove this layer - we have a different styling for creating polygons
    canopyMap.current.leafletElement.removeLayer(e.layer);
  };

  const handleLayerClicked = (e = undefined, rectangle = undefined) => {
    // stop other mouse events on leaflet map
    if (e.originalEvent) e.originalEvent.view.L.DomEvent.stopPropagation(e);
    if (e.nativeEvent) e.nativeEvent.view.L.DomEvent.stopPropagation(e);

    if (rectangle && carport.selectedRectId == rectangle.id) return;
    dispatch(carportActions.selectRectangle(rectangle.id));
  };

  const handleMapClicked = (e) => {
    if (alignmentToolOn.current) {
      toggleAlignmentTool();
      return;
    }
    if (carport.activeTool != undefined) {
      if (carport.activeTool == "draw" && carport.zoom >= 13.75) {
        handleRectangleDraw(e); //draw the rectangle
        return; // get out of here before the stopEditing function is ran below. If not, rectangle is created and then gets deselected
      }
      // if we copy a canopy of the rectanglePanel this tool is set and if you click the map the rectangle is pasted at the pointer location
      if (carport.activeTool == "pasteFromRectanglePanel") {
        pasteRectangle(e.latlng);
        dispatch(carportActions.setActiveTool(undefined));
        return;
      }

      if (carport.activeTool == "search") {
        dispatch(carportActions.setActiveTool(undefined));
      }
    }
    // make sure any move / resize / rotate is complete before disabling and updating store
    if (movingRectId == undefined && carport.selectedRectId && !nonPolygonalEditOn.current) {
      // currentlySelectedCanopy.current = undefined;
      // stopEditing(carport.selectedRectId)
      dispatch(carportActions.selectRectangle(undefined));
      // forceUpdate();
    }
  };

  const getRectStyle = (id) => {
    if (movingRectId !== id) {
      return { color: "none", fillColor: nonPolygonalEditOn.current ? "none" : "transparent", opacity: "0.2" };
    } else {
      return { color: "#333", fillColor: "#60de4f", opacity: "0.8" };
    }
  };

  console.log("render", nonPolygonalEditOn.current, carport.selectedRectId);
  return (
    <CanopyWrapper>
      <CanopyControlPanelWrapper>
        <CanopyControlPanel
          copyRectangle={copyRectangle}
          stopEditing={stopEditing}
          updateFromInputs={updateFromInputs}
          applyGlobalOverrides={applyGlobalOverrides}
          removeImageLayer={removeImageLayer}
          handleCanopyZoomExtents={handleCanopyZoomExtents}
          setInputUpdateFromMap={setInputUpdateFromMap}
          setImageToolbarPosition={setImageToolbarPosition}
        />
        <ImportTool mode="carport" />
      </CanopyControlPanelWrapper>
      <CanopyMapWrapper showCanopyReport={carport.uiState.show_canopy_report} showThiryPercentSet={carport.uiState.show_thirty_percent_set}>
        <Dropzone
          accept="image/*"
          style={divStyle}
          multiple
          minSize={0}
          maxSize={10000000}
          onDrop={(files) => {
            handleOnDrop(files);
            setDropzoneActive(false);
          }}
          onDragEnter={() => setDropzoneActive(true)}
          onDragLeave={() => setDropzoneActive(true)}
          disableClick={true}
        >
          {dropzoneActive && <div style={overlayStyle}>Drop Image...</div>}
          {dropzoneLoading && (
            <div style={overlayStyle}>
              <h2 style={{ color: "#fff" }}>Images Loading...</h2>
              <Spin size="large" />
            </div>
          )}

          <Map
            ref={canopyMap}
            attributionControl={false}
            center={[carport.lat, carport.lng]}
            zoom={carport.zoom}
            // minZoom={10}
            // maxZoom={25}
            zoomControl={false}
            zoomSnap={carport.zoomGranularity}
            zoomDelta={carport.zoomGranularity}
            onzoomend={() => dispatch(carportActions.setZoom(canopyMap.current.leafletElement.getZoom()))}
            id="leaflet-map"
            // boxZoom={false}
            noFit={true}
            // customBoxZoom={true}
            onClick={(e) => {
              handleMapClicked(e);
            }}
          >
            {/* map tiles */}
            {bingLayerVisible && <BingLayer bingkey={bing_key} type={TileSets[activeTileSet]} maxZoom={25} maxNativeZoom={18} />}

            {/* <TileLayer url={TileSets[activeTileSet]} errorTileUrl="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" crossOrgin /> */}

            <FeatureGroup>
              <EditControl
                position="topleft"
                // onEditMove={e => this.updateRectangle(e)}
                // onEditResize={e => this.updateRectangle(e)}
                onDrawVertex={(e) => console.log(e)}
                onEditVertex={(e) => console.log(e)}
                onCreated={(e) => {
                  handleRectangleCreated(e);
                }}
                draw={{
                  rectangle: false,
                  marker: false,
                  circle: false,
                  circlemarker: false,
                  polygon: false,
                  polyline: false,
                }}
                edit={{
                  remove: false,
                  edit: false,
                }}
              />
            </FeatureGroup>

            {/* Canopy tools */}
            {canopy_ui_visible.current && (
              <LeafletControl position="topleft">
                <CanopyToolbar
                  stopEditing={stopEditing}
                  toggleAlignmentTool={toggleAlignmentTool}
                  alignmentToolActive={alignmentToolOn.current}
                  duplicateRectangle={duplicateRectangle}
                  setInputUpdateFromMap={setInputUpdateFromMap}
                  toggleNPEditor={toggleNPEditor}
                  nonPolygonalEditOn={nonPolygonalEditOn.current}
                />
              </LeafletControl>
            )}

            {/* Zoom, extent and layer control */}
            {canopy_ui_visible.current && (
              <LeafletControl position="topright">
                <ZoomAndLayerControl
                  mode="canopy"
                  tileSets={TileSets}
                  setActivetileSet={onChangeTileset}
                  handleCanopyZoomExtents={handleCanopyZoomExtents}
                  setShowCanopyNameLabels={setShowCanopyNameLabels}
                  showCanopyNameLabels={showCanopyNameLabels}
                />
              </LeafletControl>
            )}

            {/* {rightClickMenu.current && (
              <LeafletControl position="controlMenu">
                <div style={{ left: menuX.current, top: menuY.current + 1, opacity: 1, position: 'fixed' }}>
                  <Menu defaultSelectedKeys={[]} defaultOpenKeys={[]} mode="vertical" theme="light" subMenuCloseDelay={2}>
                    <Menu.Item
                      key="0"
                      onClick={() => {
                        console.log('hooray!');
                      }}
                    >
                      <span>A menu item</span>
                    </Menu.Item>
                  </Menu>
                </div>
              </LeafletControl>
            )} */}

            {/* state abb and county */}
            {Object.values(carport.rectangles).length > 0 && (
              <LeafletControl position="bottomright">
                {carport.county && carport.stateAbb && (
                  <div
                    style={{
                      height: "25px",
                      background: "#ffffffb0",
                      color: "black",
                      left: "15px",
                      top: "15px",
                      position: "relative",
                      padding: "5px",
                      fontWeight: "bold",
                      borderRadius: "3px",
                    }}
                  >
                    <p>{`${carport.county} County, ${carport.stateAbb}`}</p>
                  </div>
                )}
              </LeafletControl>
            )}

            {/* this geojson layer needs to be above the canopy geojson layer in order to avoid a layer selection problem */}
            {/* {Object.values(carport.rectangles).length > 0 && (
              <LayerGroup>
                {Object.values(carport.rectangles).map((rectangle) => {
                  if (rectangle.id != movingRectId) {
                    return Object.values(rectangle.modules).map((module, index) => {
                      return (
                        <Fragment>
                          <GeoJSON
                            style={{
                              fillColor: '#ffffff',
                              fillOpacity: 0,
                              weight: 1,
                              color: module.properties.color,
                            }}
                            transform={false}
                            draggable={false}
                            scaling={false}
                            // data={rectangle.modules}
                            data={module}
                            key={`mod_${index}_${rectangle.id}`}
                            onclick={(e) => {
                              e.layer.bringToBack();
                              handleLayerClicked(e, rectangle);                          
                            }}
                          />
                        </Fragment>                       
                      );
                    });
                  }
                })}
              </LayerGroup>
            )} */}

            {Object.values(carport.rectangles).length > 0 && !nonPolygonalEditOn.current && (
              <LayerGroup>
                {Object.values(carport.rectangles).map((rectangle) => {
                  return (
                    <Fragment key={`frag_${rectangle.id}`}>
                      {/* Canopy Names */}
                      {showCanopyNameLabels && carport.selectedRectId != rectangle.id && carport.rectangles[rectangle.id].name && (
                        <Popup
                          key={`nameLabel_${rectangle.id}`}
                          closeOnClick={false}
                          draggable={false}
                          className="canopy-label"
                          position={canopyNameLabelPosition(rectangle.geoJson)}
                          autoClose={false}
                        >
                          <p style={{ margin: "0px" }}>{carport.rectangles[rectangle.id].name}</p>
                        </Popup>
                      )}

                      {/* Canopy Azimuth Holder */}
                      {rectangle.id != movingRectId && rectangle.id == carport.selectedRectId && (
                        <GeoJSON
                          style={{ color: "none", fillColor: "#60de4f", fillOpacity: "0.8" }}
                          transform={false}
                          draggable={false}
                          scaling={false}
                          data={rectangle.azimuthHolder}
                          key={`ah_${rectangle.id}`}
                        />
                      )}

                      {/* Canopy Visible/Roof? Layer */}
                      {movingRectId !== rectangle.id && Object.values(rectangle.visibleGeoJson).length > 0 && (currentNonPolyRect == undefined || currentNonPolyRect.id !== rectangle.id) && (
                        <GeoJSON
                          style={{
                            color: "#60de4f",
                            fillColor: "#60de4f",
                            fillOpacity: 0.2,
                            interactive: false,
                          }}
                          transform={false}
                          draggable={false}
                          scaling={false}
                          data={rectangle.visibleGeoJson}
                          key={`viscell_${create_UUID()}`}
                        />
                      )}

                      {/* Canopy Modules */}
                      {movingRectId !== rectangle.id &&
                        Object.values(rectangle.editCellsGeoJson).length > 0 &&
                        (currentNonPolyRect == undefined || currentNonPolyRect.id !== rectangle.id) &&
                        Object.values(rectangle.editCellsGeoJson).map((cell, index) => (
                          <GeoJSON
                            style={{
                              fillColor: cell.properties.enabled ? "#ffffff" : "none",
                              fillOpacity: 0.1,
                              weight: 2,
                              color: cell.properties.enabled ? (cell.properties.override_color ? cell.properties.override_color : "#333") : "none",
                            }}
                            transform={false}
                            draggable={false}
                            scaling={false}
                            data={cell}
                            key={`mods_${rectangle.id}-${index}-${refreshKey}`}
                            onclick={(e) => {
                              handleLayerClicked(e, rectangle);
                            }}
                          />
                        ))}

                      {/* {movingRectId !== rectangle.id && nonPolygonalEditOn.current && rectangle.id == carport.selectedRectId &&
                        Object.values(rectangle.editCellsGeoJson).length > 0 &&
                        Object.values(rectangle.editCellsGeoJson).map((cell, index) => (
                          <GeoJSON
                            style={{
                                    color: '#333',
                                    fillColor: cell.properties.enabled ? '#60de4f' : '#fc2003',
                                    fillOpacity: '0.8',
                                  }
                            }
                            transform={false}
                            draggable={false}
                            scaling={false}
                            data={cell}
                            key={`cell_${rectangle.id}-${index}-${refreshKey}`}
                            onclick={(e) => {
															moduleClicked(rectangle, cell.properties.indexes);
                            }}
                          />
											))} */}

                      {/* Canopy Base Editable True Shape Layer */}
                      {!nonPolygonalEditOn.current && (
                        <GeoJSON
                          style={() => getRectStyle(rectangle.id)}
                          transform={true}
                          draggable={true}
                          scaling={true}
                          ref={setRef(rectangle.id)}
                          data={rectangle.geoJson}
                          key={rectangle.id}
                          onclick={(e) => {
                            e.layer.bringToFront();
                            handleLayerClicked(e, rectangle);
                          }}
                        />
                      )}

                      {/* Canopy Alignment Layers */}
                      {movingRectId &&
                        rectangle.id != movingRectId &&
                        alignmentCanopyIds.current &&
                        Object.values(alignmentCanopyIds.current).length > 0 &&
                        Object.keys(rectangle.alignmentLines).map((key, index) => {
                          if (alignmentCanopyIds.current[rectangle.id] && alignmentCanopyIds.current[rectangle.id].checks[key]) {
                            return (
                              <GeoJSON
                                style={{ color: "#D7A320", dashArray: "10,8" }}
                                transform={false}
                                draggable={false}
                                scaling={false}
                                data={rectangle.alignmentLines[key]}
                                key={`align_${rectangle.id}_${key}`}
                              />
                            );
                          }
                        })}

                      {/* {getEditLayer(rectangle)} */}
                    </Fragment>
                  );
                })}

                {/* Canopy Overlapping layers */}
                {Object.values(overlappingCanopies).length > 0 &&
                  Object.values(overlappingCanopies).map((id) => {
                    return (
                      <Fragment key={`fragOverlap_${id}`}>
                        {carport.rectangles[id] && (
                          <Popup
                            key={`popup_${id}`}
                            closeOnClick={false}
                            draggable={false}
                            className="canopy-label"
                            position={canopyNameLabelPosition(carport.rectangles[id].geoJson)}
                            autoClose={false}
                          >
                            <p style={{ margin: "0px" }}>{carport.rectangles[id].name}</p>
                          </Popup>
                        )}
                      </Fragment>
                    );
                  })}
              </LayerGroup>
            )}

            {do_update_rect && <CurrentNonPolyLayer rectangle={carport.rectangles[carport.selectedRectId]} do_update_rect={do_update_rect} />}

            {Object.values(carport.images).length > 0 && (
              <LayerGroup>
                {Object.keys(carport.images).map((key, index) => {
                  return (
                    <Fragment key={key}>
                      {toolbarPosition && carport.activeImageEditTool != "none" && (
                        <Popup closeOnClick={false} draggable={false} position={toolbarPosition}>
                          <ImageToolbar updateImage={updateImage} />
                        </Popup>
                      )}
                      <Polygon
                        fillOpacity={0.01}
                        fillColor={"#ffffff"}
                        color={carport.activeImageEditTool != "none" && carport.selectedImageId == key ? "#60de4f" : "none"}
                        weight={carport.activeImageEditTool != "none" && carport.selectedImageId == key ? "1" : "0"}
                        ref={setRef(key)}
                        key={`overlay_${key}`}
                        positions={[carport.images[key].corners[0], carport.images[key].corners[1], carport.images[key].corners[3], carport.images[key].corners[2]]}
                        onClick={(e) => {
                          // prevent clicking through the polygon and hitting the map click listener
                          L.DomEvent.stop(e);
                          // dispatch(carportActions.selectImage(key));
                          setImageToolbarPosition(carport.images[key].corners);
                        }}
                      />

                      <ImageOverlay
                        key={`image_${key}`}
                        imgKey={key}
                        editMode={carport.images[key].editMode}
                        imgSrc={carport.images[key].imgSrc}
                        corners={carport.images[key].corners}
                        opacity={carport.images[key].opacity}
                        updateImage={updateImage}
                        // selectImageId={selectImageId}
                        setImageToolbarPosition={setImageToolbarPosition}
                      />
                    </Fragment>
                  );
                })}
              </LayerGroup>
            )}

            {imageTarget.current && (
              <LayerGroup>
                <Polygon
                  fillOpacity={0.2}
                  fillColor={"#c71306"}
                  color={"#c71306"}
                  // weight={'1'}
                  // ref={key}
                  key={`imageTarget`}
                  positions={imageTarget.current}
                />
              </LayerGroup>
            )}

            {Object.values(overlappingCanopies).length > 0 && <OverLapWarning overlappingCanopies={overlappingCanopies} />}
          </Map>
          {carport.generatingCanopyResults && (
            <section className="generating-box">
              {carport.generatingCanopyResults ? (
                <div className="initializing-box">
                  <Spin />
                  <p> Initializing... </p>
                </div>
              ) : (
                <div className="generating-box-contents">
                  <Progress type="circle" percent={carport.percentComplete.toFixed(0)} width={65} strokeColor="#60de4f" strokeWidth={12} />
                  <p>Generating...</p>
                </div>
              )}
            </section>
          )}
          {carport.results && carport.view_results_table && (
            <div className="results">
              <div className="results-box">
                <div className="results-close-button">
                  <Button type="link" onClick={() => dispatch(carportActions.setUIState("view_results_table", false))}>
                    <img src={close} />
                  </Button>
                </div>
                <div className="results-table">
                  <CanopyResults setShowCanopyNameLabels={setShowCanopyNameLabels} />
                </div>
              </div>
            </div>
          )}
        </Dropzone>
      </CanopyMapWrapper>
    </CanopyWrapper>
  );
};

export { Canopy };

const create_UUID = () => {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};
const arraysMatch = function(arr1, arr2) {
  // Check if the arrays are the same length
  if (arr1.length !== arr2.length) return false;

  // Check if all items exist and are in the same order
  for (var i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  // Otherwise, return true
  return true;
};
const buildVisibleRectangle = (block_arr) => {
  let blocks = [];
  let orphan_blocks = [];
  let visibleGeoJson = null;
  Object.values(block_arr).map((block, index) => {
    if (block.properties.enabled) {
      if (blocks.length == 2) {
        visibleGeoJson = turf.union(blocks[0], blocks[1]);
        blocks = [];
        blocks.push(visibleGeoJson);
      }

      blocks.push(block);
    }
  });
  let blocks_fetColl = turf.featureCollection(blocks);

  try {
    visibleGeoJson = turf.dissolve(blocks_fetColl);
  } catch {
    alert("Cannot have orphaned modules on a Canopy");
    return false;
  }

  // if (orphan_blocks.length > 0) {
  //   visibleGeoJson = turf.featureCollection([visibleGeoJson, ...orphan_blocks]);
  // }

  return visibleGeoJson;
};

const CurrentNonPolyLayer = ({ rectangle, do_update_rect }) => {
  console.log(rectangle);
  const { editCellsGeoJson, visibleGeoJson, block_arr, id } = rectangle;
  const [_editCellsGeoJson, set_editCellsGeoJson] = useState(JSON.parse(JSON.stringify(editCellsGeoJson)));
  const [_bock_arr, set_bock_arr] = useState(JSON.parse(JSON.stringify(block_arr)));

  // non_poly_edit

  const [_do_update_rect, set_do_update_rect] = useState(do_update_rect);
  const [_num_changes, set_num_changes] = useState(0);

  const dispatch = useDispatch();
  const moduleClicked = (indexes) => {
    let found_cell = _editCellsGeoJson.findIndex((cell) => arraysMatch(cell.properties.indexes, indexes));

    if (found_cell >= 0) {
      let cells = JSON.parse(JSON.stringify(_editCellsGeoJson));
      cells[found_cell].properties.enabled = !cells[found_cell].properties.enabled;
      set_editCellsGeoJson(cells);
      let blocks = JSON.parse(JSON.stringify(_bock_arr));
      blocks[found_cell].properties.enabled = !blocks[found_cell].properties.enabled;
      set_bock_arr(blocks);

      dispatch(carportActions.updateNonPolyData(cells, blocks));
      set_num_changes(_num_changes + 1);
    }
  };

  // useEffect(() => {
  // 	console.log(_num_changes)
  // 	if (_num_changes == 0) {
  // 		return
  // 	}

  // 	console.log('update rect')
  // 	let new_canopy = {
  // 		...JSON.parse(JSON.stringify(rectangle)),
  // 		id: create_UUID(),
  // 		editCellsGeoJson: _editCellsGeoJson,
  // 		block_arr: _bock_arr,
  // 		visibleGeoJson: buildVisibleRectangle(_bock_arr)
  // 	};
  // 	dispatch(carportActions.updateCanopy(id, new_canopy));

  // }, [_do_update_rect])

  return (
    <FeatureGroup>
      {Object.values(_editCellsGeoJson).map((cell, index) => (
        <GeoJSON
          style={{
            color: "#333",
            weight: 1,
            fillColor: cell.properties.enabled ? "#60de4f" : "#fc2003",
            fillOpacity: "0.8",
          }}
          transform={false}
          draggable={false}
          scaling={false}
          data={cell}
          key={`cell_${id}-${index}-${cell.properties.enabled}`}
          onclick={(e) => {
            moduleClicked(cell.properties.indexes);
          }}
        />
      ))}
    </FeatureGroup>
  );
};
