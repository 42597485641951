import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { carportActions } from '../../Redux';

import { Input, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
const suffix_loading = <LoadingOutlined style={{ fontSize: 'x-small' }} spin />;
import { isNumber } from '../../Redux'

import { InputRow } from './styles'
import { debouncedUpdateinput } from './inputs.helper'

function ModulesWidthInput() {
	const dispatch = useDispatch();

	const modY = useSelector((state) => state.carport.selectedInputs.modY);
	const base_modY = useSelector((state) => state.carport.selectedInputs.base_dimension.modY);
	const [local_modY, set_local_modY] = useState(modY)
	const [local_loading, set_local_loading] = useState(false)
	const [modY_error, set_modY_error] = useState(undefined)

	useEffect(() => {
		if (modY && modY == local_modY) {
			set_local_loading(false);
		} else if (!local_loading) {
			set_local_modY(modY)
		}
	}, [modY]);

	function onChangeInput(e) {
		set_local_modY(e.target.value)
		let changed_value = parseInt(e.target.value);

		if (!isNumber(changed_value)) {
			set_modY_error('Invalid Input')
			return
		} else if (modY < base_modY) {
			set_modY_error('Must be bigger than base modY')
			return
		} else if (modY_error != undefined) {
			set_modY_error(undefined);
		}

		set_local_loading(true)
		// debounced method
		debouncedUpdateinput.cancel();
		debouncedUpdateinput(dispatch, 'modY', changed_value)	
	}

	return (
		<InputRow>
			<label>Modules, Width:</label>
			<Input
				size="small"
				value={local_modY}
				type="number"
				step={1}
				min={0}
				suffix={
					local_loading ? <Spin indicator={suffix_loading} />
					: <span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>mod</span>
				}
				max="999"
				onChange={onChangeInput}
			></Input>		
		</InputRow>
	)
}

export { ModulesWidthInput };
