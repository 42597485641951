import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { carportActions } from "../../Redux";

import { ImageToolbar } from "./ImageToolbar";
import { ImageOverlay } from "./ImageOverlay";

import { LayerGroup, Polygon, Popup } from "react-leaflet";

import * as turf from "@turf/turf";

const ImageLayer = () => {
  const dispatch = useDispatch();

  const images = useSelector((state) => state.carport.images);
  const activeImageEditTool = useSelector((state) => state.carport.activeImageEditTool);
  const selectedImageId = useSelector((state) => state.carport.selectedImageId);

  const [toolbarPosition, setToolbarPosition] = useState(undefined);

  const setImageToolbarPosition = (coords) => {
    // creat a turf poly so we hae more control over coordinate manipulation which lets us move the toolbar exactly where we want it
    let polyPoints = [
      [coords[2].lng, coords[2].lat],
      [coords[0].lng, coords[0].lat],
      [coords[1].lng, coords[1].lat],
      [coords[3].lng, coords[3].lat],
      [coords[2].lng, coords[2].lat],
    ];

    let poly = turf.polygon([polyPoints]);
    let center = turf.center(poly);

    let lineString = turf.lineString(poly.geometry.coordinates[0]);
    let bbox = turf.bbox(lineString);

    let toolbarPosition = { lat: bbox[3], lng: center.geometry.coordinates[0] };
    setToolbarPosition(toolbarPosition);
  };

  const updateImage = (type = undefined, value = undefined) => {
    let id = selectedImageId;
    let image = images[id];

    if (type == "updateCorners") {
      image.corners = value;
    }

    if (type == "changeOpacity") {
      image.opacity = value / 100;
    }

    dispatch(carportActions.updateImage(id, image));
  };

  return (
    <>
      {Object.values(images).length > 0 && (
        <LayerGroup>
          {Object.keys(images).map((key, index) => {
            return (
              <Fragment key={key}>
                {toolbarPosition && activeImageEditTool != "none" && (
                  <Popup closeOnClick={false} draggable={false} position={toolbarPosition}>
                    <ImageToolbar updateImage={updateImage} />
                  </Popup>
                )}
                <Polygon
                  fillOpacity={0.01}
                  fillColor={"#ffffff"}
                  color={activeImageEditTool != "none" && selectedImageId == key ? "#60de4f" : "none"}
                  weight={activeImageEditTool != "none" && selectedImageId == key ? "1" : "0"}
                  // ref={setRef(key)}
                  key={`overlay_${key}`}
                  positions={[images[key].corners[0], images[key].corners[1], images[key].corners[3], images[key].corners[2]]}
                  onClick={(e) => {
                    // prevent clicking through the polygon and hitting the map click listener
                    L.DomEvent.stop(e);
                    // dispatch(ctions.selectImage(key));
                    setImageToolbarPosition(images[key].corners);
                  }}
                />

                <ImageOverlay
                  key={`image_${key}`}
                  imgKey={key}
                  editMode={images[key].editMode}
                  imgSrc={images[key].imgSrc}
                  corners={images[key].corners}
                  opacity={images[key].opacity}
                  updateImage={updateImage}
                  // selectImageId={selectImageId}
                  setImageToolbarPosition={setImageToolbarPosition}
                />
              </Fragment>
            );
          })}
        </LayerGroup>
      )}

      {/* {imageTarget.current && (
			<LayerGroup>
				<Polygon
					fillOpacity={0.2}
					fillColor={"#c71306"}
					color={"#c71306"}
					key={`imageTarget`}
					positions={imageTarget.current}
				/>
			</LayerGroup>
		)} */}
    </>
  );
};

export { ImageLayer };
