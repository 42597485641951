// # -*//- coding: utf-8 -*-
// """
// Created on Tue May 11 12:44:11 2021

// @author: Sunfig
// Portal Racking Dims Calculators
// """

export function Terratrak_2P_SAT(_Mod_X, _Mod_Y, Mod_per_Tracker, Bifacial) {
  // #Mod_X, Mod_Y in meters. Imperial units come from TS' calculators and are converted within

  // #Below code expects module dims in mm
  let Mod_X = _Mod_X * 1000;
  let Mod_Y = _Mod_Y * 1000;

  // #Inches
  let Torque_Tube_Width = 5.17;
  let Motor_Gap = 26.93;
  let Hole_Spacing = 3.108;
  let Torque_Tube_Overhang = 3;

  // #mm
  let Panel_Flange_Width_Short = 10;
  let Panel_Flange_Width_Long = 30;

  // #25.4 is mm to inch
  let NS_Mod_Gap = Hole_Spacing - (Panel_Flange_Width_Long / 25.4) * 2;

  let EW_Mod_Spacing;
  if (Bifacial == true) {
    // #25.4 is mm to inch
    EW_Mod_Spacing = Torque_Tube_Width - (Panel_Flange_Width_Short / 25.4) * 2;
  } else {
    // #Inches
    EW_Mod_Spacing = 0.5;
  }

  let Tracker_Y = Motor_Gap + Math.floor(Mod_per_Tracker / 2 + 0.5) * (Mod_X / 25.4) + Torque_Tube_Overhang * 2 + NS_Mod_Gap * (Math.floor(Mod_per_Tracker / 2 + 0.5) - 1);
  // #int(Mod_per_Tracker/2 + 0.5) round up for odd, down for even. 13 modules turns to 14, 14 modules stays at 14.

  let Tracker_X = (Mod_Y / 25.4) * 2 + EW_Mod_Spacing;

  Tracker_X = Tracker_X * 0.0254;
  Tracker_Y = Tracker_Y * 0.0254;

  // #Terratrak max dims is 47x2 modules (94) (assuming 2mx1m module). Max mod/string of 31 for a 3 string tracker

  return [Tracker_X, Tracker_Y];
}

export function Standard_GFT(Mod_X, Mod_Y, Mod_per_Rack, Mods_High, Orientation, Gap_Vert, Gap_Horz) {
  // #User inputs a mods per tracker, returns an X and Y. Agnostic of modules per string or 2,1,0.5 string targets. That logic should exist before this
  let Rack_Y;
  let Rack_X;

  if (Orientation == "portrait") {
    Rack_Y = Mods_High * Mod_Y + (Mods_High - 1) * Gap_Vert;
  } else {
    Rack_Y = Mods_High * Mod_X + (Mods_High - 1) * Gap_Vert;
  }

  // #Complex Python but it only rounds up. 25mod/4high = 7 wide.
  let Mods_Wide = Math.floor(Mod_per_Rack / Mods_High) + (Mod_per_Rack % Mods_High);

  if (Orientation == "portrait") {
    Rack_X = Mods_Wide * Mod_X + (Mods_Wide - 1) * Gap_Horz;
  } else {
    Rack_X = Mods_Wide * Mod_Y + (Mods_Wide - 1) * Gap_Horz;
  }

  return [Rack_X, Rack_Y];
}

// ins = {}
// ins['Mod_X'] = 1.015
// ins['Mod_Y'] = 2.038
// ins['Mod_per_Rack'] = 25
// ins['Bifacial'] = True

// X, Y = Terratrak_2P_SAT(ins['Mod_X'], ins['Mod_Y'], ins['Mod_per_Rack'], ins['Bifacial'])

// ins['Mods_High'] = 2
// ins['Orientation'] = 'P'
// ins['Gap_Vert'] = 0.01
// ins['Gap_Horz'] = 0.01

// X, Y = Standard_GFT(ins['Mod_X'], ins['Mod_Y'], ins['Mod_per_Rack'], ins['Mods_High'], ins['Orientation'], ins['Gap_Vert'], ins['Gap_Horz'])

// #CALCULATORS TO BUILD
// #2HP GFT?
// #4HL
// #4HP "Dahlia"
// #3HP "??"
// #2HP Terraglide 2HP with gap
