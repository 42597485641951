import styled, { css } from "styled-components";

export const ResultTableWrapper = styled.section`
  width: 100%;
  height: ${(props) => (props.type === "canopy" ? "280px" : "320px")};
  overflow: auto;
  border: 1px solid #60de4f;

  table {
    text-align: center;
    position: relative;
  }

  table thead {
    position: sticky;
    top: 0px;
    background: #fff;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);

    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background: rgb(217, 217, 217);
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }

  table > tbody > tr > td {
    border: 1px solid rgb(217, 217, 217);
  }

  .column-name {
    border: 1px solid #d3d3d3;
    position: relative;
    vertical-align: bottom;
    font-size: 10pt;
    font-weight: 500;
    height: 44px;
    color: #333;
    cursor: pointer;
    white-space: nowrap;
    padding: 0px 5px;
  }
  .column-name.active {
    color: #60de4f;
    border-top: 1px solid #60de4f;
    border-bottom: 1px solid #60de4f;
  }
  .column-name:hover {
    color: #60de4f;
  }

  .mini-sort {
    position: absolute;
    font-size: 15px;
    top: 0;
    right: 2px;
  }
  .mini-sort:hover {
    color: #60de4f;
  }
  .mini-data > td {
    text-align: left;
    padding: 2px 4px;
    min-width: 55px;
    border: 1px solid #d9d9d9;
    width: min-content;
    white-space: nowrap;
  }

  .mini-data.selected,
  .mini-data:hover {
    color: #60de4f;
    background-color: #f3f3f3;
    cursor: pointer;
  }
`;

// export const ResultTableWrapper = styled.section`
//   overflow-x: scroll;
//   border: 1px solid #60de4f;

//   table {
//     width: 100%;
//     height: 325px;
//   }

//   thead,
//   tbody,
//   tr,
//   td,
//   th {
//     display: block;
//   }

//   thead {
//     tr {
//       display: flex;
//       th {
//         display: flex;
//         width: 100%;
//         justify-content: center;
//         align-items: flex-end;
//         position: relative;
//         font-size: 10pt;
//         font-weight: 500;
//         height: 35px;
//         color: #333;
//         cursor: pointer;
//         padding: 0px 8px;
//         white-space: nowrap;
//         border: 1px solid #d3d3d3;

//         &.active {
//           color: #60de4f;
//           border-top: 1px solid #60de4f;
//           border-bottom: 1px solid #60de4f;
//         }

//         &:hover {
//           color: #60de4f;
//         }

//         .mini-sort {
//           position: absolute;
//           font-size: 15px;
//           top: 0;
//           right: 2px;
//         }
//       }

//       &:after {
//         content: ' ';
//         display: block;
//         visibility: hidden;
//         clear: both;
//       }
//     }
//   }

//   tbody {
//     height: 280px;
//     overflow-y: auto;
//     overflow-x: scroll;

//     .mini-data {
//       display: flex;

//       &:after {
//         content: ' ';
//         display: block;
//         visibility: hidden;
//         clear: both;
//       }

//       &:hover {
//         color: #60de4f;
//         background-color: #f3f3f3;
//         cursor: pointer;
//       }
//       td {
//         opacity: 0.8;
//         display: flex;
//         font-size: 10pt;
//         cursor: pointer;
//         padding: 0px 8px;
//         padding: 2px 4px;
//         border: 1px solid #d9d9d9;
//         white-space: nowrap;
//       }
//     }

//     .selected {
//       color: #60de4f;
//       background-color: #f3f3f3;
//       cursor: pointer;
//     }
//   }

//   tbody tr td,
//   thead tr th {
//     float: left;

//     ${(props) =>
//       props.type !== 'canopy' &&
//       css`
//         &:nth-child(1) {
//           width: 120px;
//         }
//         &:nth-child(2) {
//           width: 175px;
//         }
//         &:nth-child(3) {
//           width: 65px;
//         }
//         &:nth-child(4) {
//           width: 135px;
//         }
//         &:nth-child(5) {
//           width: 55px;
//         }
//         &:nth-child(6) {
//           width: 55px;
//         }
//         &:nth-child(7) {
//           width: 105px;
//         }
//         &:nth-child(8) {
//           width: 140px;
//         }
//         &:nth-child(9) {
//           width: 100px;
//         }
//         &:nth-child(10),
//         &:nth-child(11),
//         &:nth-child(12),
//         &:nth-child(13),
//         &:nth-child(14) {
//           width: 75px;
//         }
//       `}

//     ${(props) =>
//       props.type == 'canopy' &&
//       css`
//         &:nth-child(1) {
//           width: 120px;
//         }
//         &:nth-child(2) {
//           width: 170px;
//         }
//         &:nth-child(3) {
//           width: 100px;
//         }
//         &:nth-child(4) {
//           width: 85px;
//         }
//         &:nth-child(5) {
//           width: 190px;
//         }
//         &:nth-child(6) {
//           width: 145px;
//         }
//         &:nth-child(7) {
//           width: 130px;
//         }
//         &:nth-child(8) {
//           width: 85px;
//         }
//         &:nth-child(9) {
//           width: 115px;
//         }
//         &:nth-child(10) {
//           width: 110px;
//         }
//       `}
//   }
// `;
