import React, { Fragment, useState, useEffect } from 'react';

import { SimpleInputsWrap } from '../styles';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { inputsActions } from '../../Redux';

// antd
import { Select, Input, Collapse } from 'antd';

//components
import { InputHeader, ModuleInputs } from '../';
// assets
import { product } from '../../../assets';

const SimpleInputs = () => {
  const portal = useSelector((state) => state.portal);
  const inputs = useSelector((state) => state.inputs);
  const dispatch = useDispatch();
	const [local_inputs, set_local_inputs] = useState(inputs)
	useEffect(() => {
		set_local_inputs(inputs)
	}, [inputs]);

	const updateInput = (key, value) => {
		set_local_inputs({
			...local_inputs,
			[key]: value
		})
		// do some input validation here
		let fixed_value = value
		// console.log(key, fixed_value)

		if (key == 'mod_rating' || key == 'mod_per_string') {
			if (_.isEmpty(value) || _.isUndefined(value) || _.isNaN(value)) {
				return
			}
			fixed_value = parseInt(value);
		}

		// console.log(key,fixed_value)
		// dispatch(portalActions.updatePerformance(key,fixed_value));
		dispatch(inputsActions.update_input(key,fixed_value));
	}

  const moduleHeader = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={product} style={{ height: '25px', width: '25px', marginRight: '5px' }} />
      <p style={{ margin: '0' }}>Module</p>
    </div>
  );

  return (
    <SimpleInputsWrap>
      <section className="simple-select-dropdown top-border">
        <label>Product</label>
        <Select
          showSearch
          optionFilterProp="children"
          onChange={(value) => {
            dispatch(inputsActions.select_product(parseInt(value)))
          }}
          value={portal.products[local_inputs.uiState.selected_product_index].racking_name || 'Select a Product'}
          filterOption={(input, option) => option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {Object.values(portal.products).map((product, index) => (
            <Select.Option key={index} value={index}>
              <span>{product.racking_name}</span>
            </Select.Option>
          ))}
        </Select>
      </section>


      <Fragment>
        <div className="simple-input">
          <label>Module Rating</label>
          <Input
            id="mod_rating"
            style={{ width: '120px', marginLeft: 'auto' }}
            value={local_inputs.mod_rating}
            onChange={(e) => updateInput(e.target.id, e.target.value) }  
            size="small"
            disabled={false}
            suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>W</span>}
          />
        </div>

        {local_inputs.string_counts.length > 0 
        &&
        <div className="simple-input">
          <label>Mod/Str</label>
          <Input
            id="mod_per_string"
            style={{ width: '120px', marginLeft: 'auto' }}
            value={local_inputs.mod_per_string}
            size="small"
            min="0"
            step="1"
            onChange={(e) => updateInput(e.target.id, e.target.value) } 
          />
        </div>
        }

        <section className="gm-input-panels">
          <Collapse expandIconPosition="right" defaultActiveKey={[]}>
            <Collapse.Panel className="custom-input-panel-header" header={moduleHeader} key="module">
              <InputHeader mode="portal" tab="module" />
              <ModuleInputs mode="portal" />
            </Collapse.Panel>
          </Collapse>
        </section>

      </Fragment>
    </SimpleInputsWrap>
  );
};

export { SimpleInputs };
