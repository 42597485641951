import styled, { keyframes } from "styled-components";

const InputHeaderWrap = styled.section`
  /* height: 70px; */
  box-shadow: ${(props) => (props.simple ? "none" : "0px 2px 2px rgba(0, 0, 0, 0.2)")};
  padding: 10px;

  .select-content {
    text-align: center;
    display: flex;

    label {
      color: #5f5f5f;
      display: block;
      text-align: left;
      line-height: 15px;
    }
  }

  .module-name-row {
    width: 100%;
    padding: 3px 6px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    label {
      grid-column: 1;
      margin-right: auto;
    }

    p {
      grid-column: 2;
      margin: 0px;
      text-align: center;
    }
  }

  .input-header-name {
    display: grid;
    grid-template-columns: 1fr 2fr;
    place-items: center start;
  }
`;
const GroundControlPanelWrapper = styled.section`
  grid-column: 1;
  grid-row: 1;
  display: grid;
  grid-template-rows: repeat(3, auto) 1fr auto;
  width: 100%;
  height: 100vh;
  z-index: 999;

  .gm-prodMod-select {
    width: 100%;
    display: grid;
    flex-direction: column;
    place-items: center;
    overflow-y: scroll;

    button {
      width: 200px;
      margin: 5px auto;
    }
  }

  .portal-tabs {
    z-index: 9;
    grid-row: 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0px 1px;
    padding: 0px 2px;
    box-shadow: ${(props) => (props.panelScrollPosition == 0 ? "none" : "0px 4px 4px rgba(0, 0, 0, 0.2)")};

    .tab {
      grid-row: 1;
      display: grid;
      place-content: center;
      width: 100%;
      border-top: 1px solid rgb(170, 169, 170);
      border-left: 1px solid rgb(170, 169, 170);
      border-right: 1px solid rgb(170, 169, 170);
      border-radius: 3px 3px 0px 0px;
      padding: 5px;
      background-color: #e2e2e2;
      color: #5f5f5f;
      font-size: 14px;

      &:hover {
        cursor: pointer;
        color: #60de4f;
      }
    }

    .active {
      color: #60de4f;
      background: #fff;
    }
  }

  .gm-input-panels {
    grid-row: 4;
    width: 100%;
    overflow-y: scroll;
    z-index: 8;

    .simple-select-dropdown {
      width: 100%;
      display: grid;
      place-items: center stretch;
      grid-template-columns: 1fr 2fr;
      padding: 5px 10px;
    }
    .top-border {
      border-top: 1px solid rgb(170, 169, 170);
    }

    .custom-input-panel-header {
      border: none;
      border-radius: 0;
      background: #fff;

      .inputs-content-box {
        padding: 16px;

        h3 {
          padding-left: 10px;
        }
      }
      .topo-input-box {
        padding: 16px 0;
        h3 {
          margin-top: 5px;
          padding-left: 10px;
        }

        .layer-header-layer {
          margin: 0 auto;
        }
        .layer-header-small {
          width: 20px;
          display: inline-block;
          padding-right: 5px;
          margin-right: 10px;
          text-align: center;
        }
        .layer-header-limit {
          width: 120px;
          margin-left: 5px;
        }
      }
      .ant-collapse-header {
        /* border: 1px solid #1cac5094; */
        /* box-shadow: 1px 2px #5a6f6254; */
      }
    }

    .product-input,
    .perf-input,
    .module-input {
      width: 100%;
      padding: 3px 0px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      place-items: center right;

      label {
        grid-column: 1;
        margin-right: auto;
      }

      input,
      button {
        grid-column: 2;
        margin-left: auto;
      }

      input:invalid {
        box-shadow: none;
      }

      .gm-input {
        width: 120px;
      }

      .module-area-info {
        grid-column: 2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 116px;
        padding: 0px 5px;

        p {
          margin: 0px;
        }
      }

      .input-table {
        float: "left";
        width: 180px;
      }
    }

    .warning-text {
      grid-column: 1 /-1;
      padding: 10px 15px;
    }
    .input-row {
      width: 100%;
      padding: 3px 16px;
      display: grid;
      grid-template-columns: 1fr;
      place-items: center right;
      label {
        margin-right: auto;
      }
      .input-box {
        width: 70px;
        margin-left: 5px;
      }
    }
    .input-row.two {
      grid-template-columns: repeat(2, 1fr);
      .ant-checkbox-wrapper {
        /* margin-left: 8px; */
        padding-right: 5px;
      }
    }
    .input-row.bold {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
    }
    .input-row.three {
      grid-template-columns: repeat(3, 1fr);
      place-items: center;

      img {
        width: 60px;
        border: 3px solid #1cac51fa;
      }
      .filler-icon {
        height: 60px;
        width: 60px;
        border: 3px solid #1cac51fa;
        border-radius: 3px;
        text-align: center;
        display: flex;
        span {
          margin: auto 0;
          width: 100%;
        }
      }
      .filler-icon.ON {
        border: 3px solid #1cac51fa;
      }
      .filler-icon.OFF {
        border: 3px solid #ff4c4cfa;
      }
    }
    .input-row.source {
      grid-template-columns: repeat(2, 1fr);
    }

    .toggled-input {
      margin: 0px auto;
      display: grid;
      flex-direction: column;
      place-items: center;
      grid-template-columns: repeat(2, 1fr);

      .toggled-input-labeled {
        display: flex;
        align-items: center;
        color: #333;
        margin: 2px auto;

        .small-label {
          color: #000000a6;
          margin-right: 10px;
          margin-bottom: 0;
        }

        .small-span {
          width: 95px;
          margin-left: auto;
        }
      }
    }
    .toggled-input.one-col {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .topo-source {
    width: 100%;
    label {
      margin-right: 10px;
    }
  }

  .topo-input {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    margin: 8px auto;

    label {
      grid-column: 1;
    }

    .topo-labeled-input-box {
      grid-column: 2;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      margin-left: auto;

      label {
        grid-column: 1;
        margin-right: 5px;
      }

      input {
        grid-column: 2;
      }

      input:invalid {
        box-shadow: none;
      }
    }

    .visual-divider {
      margin: 8px -15px !important;
      font-size: 10pt;

      .ant-divider-inner-text {
        padding-left: 0px !important;
        cursor: pointer !important;
      }
      .ant-divider-inner-text:hover {
        color: #1cac51 !important;
      }
    }
  }

  .generate-buttons-container {
    grid-row: 5;
    display: grid;
    grid-template-rows: auto 1fr;
    width: 100%;
    place-items: center;
    border-top: 1px solid rgb(217, 217, 217);

    .inputs-have-changed {
      grid-row: 1;
      width: 100%;
      padding: 5px 15px 0px 15px;
    }

    .generate-buttons {
      width: 100%;
      grid-row: 2;
      display: flex;
      place-items: center;
      padding: 10px;

      button {
        width: 45%;
        margin: 0 5px;
      }
    }
  }
  .empty-box {
    grid-row: 4;
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
  }

  .spacer {
    height: 25px;
  }
`;

const CanopyControlPanelWrapper = styled.section`
  grid-column: 1;
  display: grid;
  grid-template-rows: auto 1fr min-content auto;
  width: 100%;
  height: 100vh;

  .project-manager-box {
    width: 100%;
    grid-row: 1;
    z-index: 100;
    height: min-content;
    box-shadow: ${(props) => (props.panelScrollPosition == 0 ? "none" : "0px 4px 4px rgba(0, 0, 0, 0.2)")};
  }

  .ant-collapse-item.canopy-input-panel-header-list .ant-collapse-content-box {
    height: 150px;
  }

  .canopy-input-panels {
    grid-row: 2;
    width: 100%;
    overflow-y: scroll;

    .canopy-input-panel-header-list,
    .canopy-input-panel-header {
      border: none;
      border-radius: 0;
      background: #f3f3f3;

      .ant-collapse-header {
        // border: 1px solid #1cac5094;
        box-shadow: 1px 2px #5a6f6254;
      }
    }

    .canopy-menu {
      height: 150px;
      padding: 0px;
      overflow-y: scroll;

      .canopy-menu-item {
        width: 100%;
        margin: 4px auto !important;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .canopy-menu-item-name {
          display: flex;
          align-items: center;
          margin-left: 16px;
          img {
            height: 20px;
            width: 20px;
            margin: 0px 6px 0px 0px;
            filter: brightness(0.1) sepia(1) hue-rotate(180deg) saturate(2);
          }
        }

        .canopy-image-item {
          display: flex;
          align-items: center;
          margin-left: 16px;

          img,
          svg {
            height: 20px;
            width: 20px;
            margin: 0px 6px 0px 0px;
            filter: brightness(0.5);
          }

          p {
            width: 20ch;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0px;
          }
        }

        .canopy-menu-item-buttons {
          display: flex;

          & > button {
            padding: 4px;

            img {
              height: 20px;
              width: 20px;
              filter: brightness(2);
            }
          }
        }
      }
    }

    .canopy-inputs-accordian {
      width: 100%;
      padding: 8px 16px 10px 16px;

      .canopy-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        .copy-delete {
          display: flex;

          button {
            padding: 5px;
            img {
              height: 20px;
              width: 20px;
              filter: brightness(2);
            }
          }
        }
      }

      .canopy-input {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        place-items: center;
        padding: 0px;
        margin: 2px auto;

        label {
          grid-column: 1;
          margin-right: auto;
        }

        input {
          grid-column: 2;
        }
        input:invalid {
          box-shadow: none;
        }
      }

      .canopy-dimensions {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 0px auto;

        & > p {
          color: #000000a6;
          margin: 5px auto;
        }
      }

      .canopy-prodMod-select {
        grid-column: 1/ -1;
        width: 100%;
        display: grid;
        place-items: center;

        z-index: 9;
        margin-top: 5px;

        & button {
          width: 100% !important;
          padding: 0 15px;
          display: flex;
          justify-content: space-between;
          border: none;
          box-shadow: none;
          margin: 4px auto;
        }
      }

      .canopy-inputs-radios {
        margin: 5px auto;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-around;
      }

      .canopy-warning-text {
        margin: 5px auto;
        width: 100%;
        text-align: center;
      }
    }

    .canopy-inputs-empty-box {
      padding: 20px;
      grid-column: 1 / -1;
    }

    .custom-input-panel-header {
      border: none;
      border-radius: 0;
      background: #f3f3f3;

      .inputs-content-box {
        padding: 16px;

        h3 {
          padding-left: 10px;
        }
      }
      .topo-input-box {
        padding: 16px 0;
        h3 {
          margin-top: 5px;
          padding-left: 10px;
        }

        .layer-header-layer {
          margin: 0 auto;
        }
        .layer-header-small {
          width: 20px;
          display: inline-block;
          padding-right: 5px;
          margin-right: 10px;
          text-align: center;
        }
        .layer-header-limit {
          width: 120px;
          margin-left: 5px;
        }
      }
      .ant-collapse-header {
        // border: 1px solid #1cac5094;
        box-shadow: 1px 2px #5a6f6254;
      }
    }

    .module-input {
      width: 100%;
      padding: 3px 0px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      place-items: center right;

      label {
        grid-column: 1;
        margin-right: auto;
      }

      input,
      button {
        grid-column: 2;
        margin-left: auto;
      }

      input:invalid {
        box-shadow: none;
      }

      .gm-input {
        width: 100px;
      }
      .module-area-info {
        grid-column: 2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 116px;
        padding: 0px 5px;

        p {
          margin: 0px;
        }
      }

      .input-table {
        float: "left";
        width: 180px;
      }
    }
  }

  .global-overrides {
    padding: 16px;

    .global-override-input {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
      margin: 10px auto;

      & > label {
        grid-row: 1;
        margin-bottom: 8px;
      }
      .global-dropdown,
      .input-wrapper {
        grid-row: 2;
        grid-column: 1;
        display: grid;
        place-items: center;
        & > button {
          width: 150px;
        }
      }

      .global-radio-buttons {
        grid-row: 2;
        grid-column: 1;
        display: flex;
        align-items: center;
      }

      .global-set-button {
        grid-row: 2;
        grid-column: 2;
        display: grid;
        place-items: end;
        & > button {
          width: 90px;
        }
      }
    }
  }

  .warning-text {
    padding: 10px;
    grid-row: 3;
    width: 100%;
    display: grid;
    place-items: center;
    p {
      margin: 0px;
      font-size: 12px;
    }
  }

  .generate-buttons-container {
    grid-row: 4;
    display: grid;
    width: 100%;
    place-items: center;
    border-top: 1px solid rgb(217, 217, 217);

    .generate-buttons {
      width: 100%;
      display: flex;
      place-items: center;
      padding: 10px;

      button {
        width: 45%;
        margin: 0 5px;
      }
    }
  }

  .empty-box {
    grid-row: 2;
    height: 70%;
    width: 100%;
    display: grid;
    place-items: center;
  }
`;

const CanopyHeaderWrap = styled.section`
  display: flex;
  align-items: end;

  img {
    width: 25px;
    margin-right: 10px;
  }

  svg {
    margin-right: 10px;
  }

  p {
    margin: 0px;
  }
`;

const SimpleInputsWrap = styled.section`
  overflow-y: scroll;
  .simple-select-dropdown {
    width: 100%;
    display: grid;
    place-items: center stretch;
    grid-template-columns: 1fr 2fr;
    padding: 5px 10px;
  }
  .top-border {
    border-top: 1px solid rgb(170, 169, 170);
  }

  .simple-input {
    width: 100%;
    display: grid;
    place-items: center start;
    grid-template-columns: 1fr 2fr;
    padding: 10px;

    .simple-text {
      width: 100%;
      text-align: right;

      p {
        margin: 0px;
      }
    }
  }
`;

export { GroundControlPanelWrapper, CanopyControlPanelWrapper, CanopyHeaderWrap, InputHeaderWrap, SimpleInputsWrap };
