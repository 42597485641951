import React, { useCallback, useState, useEffect, useRef, Fragment } from "react";

import { useSelector, useDispatch } from "react-redux";

import { ZoomAndLayerControlLayout } from "../styles";
import { TileSets, zoomGranularity } from "../CanopyConstants";
import { LeafletControl } from "./index";

import { carportActions, getFeaturesBounds } from "../../Redux";

import { add_song, minus, expand, layer, textBubble } from "../../../assets";
import { Menu, Tooltip, Button, Dropdown } from "antd";

const ZoomAndLayerControl = ({ mapRef }) => {
  const activeTileSet = useSelector((state) => state.carport.activeTileSet);
  const map = useSelector((state) => state.carport.map);
  const visual = useSelector((state) => state.carport.visual);
  const uiState = useSelector((state) => state.carport.uiState);
  const names = useSelector((state) => state.carport.names);

  const dispatch = useDispatch();
  const tileMenu = () => (
    <Menu
      onClick={(e) => {
        // setActivetileSet(e.item.props.children[1]);
        dispatch(carportActions.changeTileSet(e.item.props.children[1]));
      }}
    >
      {Object.keys(TileSets).map((key, index) => (
        <Menu.Item key={index} eventKey={key} disabled={key == activeTileSet}>
          {key}
        </Menu.Item>
      ))}
    </Menu>
  );

  const zoomExtents = () => {
    if (Object.values(visual).length == 0) {
      mapRef.panTo(map.mapCenter);
    } else {
      // define canopy feature array
      let allCanopyFeatures = [];
      // loop through all the canopies and push the geoJson into allCanopyFeatures array
      Object.values(visual).map((vis) => {
        allCanopyFeatures.push(vis.structure);
      });

      // get the bounds of the features collection
      let bounds = getFeaturesBounds(allCanopyFeatures);

      // adjust the map to fit all canopies inside of the viewing area
      mapRef.fitBounds([
        [bounds[1], bounds[0]],
        [bounds[3], bounds[2]],
      ]);
    }
  };

  const zoomIn = () => {
    mapRef.zoomIn(zoomGranularity);
  };
  const zoomOut = () => {
    mapRef.zoomOut(zoomGranularity);
  };

  const toggleNames = () => {
    dispatch(carportActions.toggleShowCanopyNames());
  };

  return (
    <LeafletControl position="topright">
      <ZoomAndLayerControlLayout>
        <Tooltip title="Zoom In" placement="left">
          <Button className="zoom-control-layer-button" shape="circle" onClick={() => zoomIn()}>
            <img src={add_song} />
          </Button>
        </Tooltip>
        <Tooltip title="Zoom Out" placement="left">
          <Button className="zoom-control-layer-button" shape="circle" size="large" onClick={() => zoomOut()}>
            <img src={minus} />
          </Button>
        </Tooltip>
        <Tooltip title="Zoom Extents" placement="left">
          <Button className="zoom-control-layer-button" shape="circle" size="large" onClick={() => zoomExtents()}>
            <img style={{ marginBottom: "2px" }} src={expand} />
          </Button>
        </Tooltip>
        <Tooltip title={names.visible ? "Hide Names" : "Show Names"} placement="left">
          <Button
            disabled={Object.values(visual).length == 0}
            className="zoom-control-layer-button"
            style={{ backgroundColor: names.visible ? "#60de4f" : null }}
            shape="circle"
            size="large"
            onClick={toggleNames}
          >
            <img src={textBubble} />
          </Button>
        </Tooltip>
        <Tooltip title="Layer Select" placement="left">
          <Dropdown overlay={tileMenu} placement="bottomRight" trigger={["click"]}>
            <Button className="zoom-control-layer-button" shape="circle" size="large">
              <img src={layer} />
            </Button>
          </Dropdown>
        </Tooltip>
      </ZoomAndLayerControlLayout>
    </LeafletControl>
  );
};

export { ZoomAndLayerControl };
