import React, { useState, useEffect, useRef } from "react";
import { Map, LayerGroup, GeoJSON, Polygon } from "react-leaflet";

//styles
import { ReportMapWrap } from "./styles";

// redux
import { useSelector } from "react-redux";

// components
import { ZoomControl } from "./ZoomControl";

// helpers
import { getBounds } from "../../utils/map_helper";
import { Button } from "antd";

import BingLayer from "../Sunfig/Bing";

const bing_key = "Apg1Wo2sThhv7onPyHncSIy7J3Nn98FFIzAhWYNFDrNLDpCRkZaSic1uuBHRoppM";
const TileSets = {
  Satellite: "Aerial",
  "Satellite With Labels": "AerialWithLabels",
  "Satellite With OnDemand Labels": "AerialWithLabelsOnDemand",
  "Street Map": "Road",
  // "Street Map Dark": "CanvasDark",
  // "CanvasLight": "CanvasLight",
  // "CanvasGray": "CanvasGray"
};
const basePolyStates = {
  Boundary: true,
  Exclusions: true,
  Inactive: true,
  Layout: true,
  Wiring: true,
};

const LiveReportMap = ({ mapName, setLiveReportMapRefs }) => {
  const portal = useSelector((state) => state.portal);
  const inputs = useSelector((state) => state.inputs);

  const [activeTileSet, setActivetileSet] = useState("Satellite");
  const [showControls, setShowControls] = useState(false);
  const [polygonStates, setPolygonStates] = useState(JSON.parse(JSON.stringify(basePolyStates)));
  const [zoom, setZoom] = useState(16);

  const [showBoundary, setShowBoundary] = useState(true);
  const [showExclusion, setShowExclusion] = useState(true);
  const [showInactive, setShowInactive] = useState(true);

  const liveReportMap = useRef(null);
  const liveReportFeatures = useRef({});
  const [bingLayerVisible, setBingLayerVisible] = useState(true);

  useEffect(() => {
    setBingLayerVisible(true);
  }, [activeTileSet]);

  useEffect(() => {
    setLiveReportMapRefs(`${mapName}`, { height: liveReportMap.current.leafletElement._size.y, width: liveReportMap.current.leafletElement._size.x, className: mapName });

    setTimeout(() => {
      handleLiveMapZoomExtents();
    }, 500);
  }, []);

  useEffect(() => {
    liveReportFeatures.current = inputs.site_features;
  }, [inputs.site_features]);

  const handleLiveMapZoom = (zoom) => {
    setZoom(zoom);
  };

  const handleLiveMapZoomExtents = (zoomLevel = undefined) => {
    if (Object.values(liveReportFeatures.current).length == 0) return;

    let bounds = getBounds(Object.values(liveReportFeatures.current));

    if (zoomLevel) {
      liveReportMap.current.leafletElement.fitBounds(
        [
          [bounds[1], bounds[0]],
          [bounds[3], bounds[2]],
        ],
        { maxZoom: zoomLevel }
      );
    } else {
      liveReportMap.current.leafletElement.fitBounds([
        [bounds[1], bounds[0]],
        [bounds[3], bounds[2]],
      ]);
    }
  };

  return (
    <ReportMapWrap onMouseEnter={() => setShowControls(true)} onMouseLeave={() => setShowControls(false)}>
      <section className="map-toggles">
        <h3>Large Layout Map</h3>
        <section className="map-toggle-buttons">
          <div className="toggle">
            <p>Show Boundaries</p>
            <label className="switch">
              <input type="checkbox" checked={showBoundary} onChange={(e) => setShowBoundary(!showBoundary)} />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="toggle">
            <p>Show Exclusions</p>
            <label className="switch">
              <input type="checkbox" checked={showExclusion} onChange={(e) => setShowExclusion(!showExclusion)} />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="toggle">
            <p>Show Inactive</p>
            <label className="switch">
              <input type="checkbox" checked={showInactive} onChange={(e) => setShowInactive(!showInactive)} />
              <span className="slider round"></span>
            </label>
          </div>
        </section>
      </section>
      <Map
        ref={liveReportMap}
        id={mapName}
        key={mapName}
        className={mapName}
        animate={true}
        noWrap={true}
        attributionControl={false}
        center={[inputs.latitude, inputs.longitude]}
        zoom={zoom}
        minZoom={0}
        maxZoom={25}
        zoomControl={false}
        zoomSnap={portal.inputs.zoomGranularity}
        zoomDelta={portal.inputs.zoomGranularity}
        style={{ width: "100%", height: "95%" }}
      >
        {bingLayerVisible && <BingLayer bingkey={bing_key} type={TileSets[activeTileSet]} maxZoom={25} maxNativeZoom={18} />}

        {showControls && liveReportMap.current && liveReportMap.current.leafletElement && <ZoomControl mapRef={liveReportMap.current.leafletElement} />}

        <LayerGroup>
          {liveReportFeatures.current &&
            Object.values(liveReportFeatures.current).map((poly, index) => {
              if (poly.properties.identity == 0 && !showInactive) return;
              if (poly.properties.identity == 1 && !showBoundary) return;
              if (poly.properties.identity == 2 && !showExclusion) return;
              return (
                <GeoJSON
                  data={poly}
                  key={poly.properties.index}
                  style={{
                    fillColor: "#ffffff",
                    fillOpacity: 0.01,
                    weight: 1,
                    color:
                      poly.properties.identity == 0 && showInactive
                        ? "white"
                        : poly.properties.identity == 1 && showBoundary
                        ? "red"
                        : poly.properties.identity == 2 && showExclusion
                        ? "yellow"
                        : "white",
                  }}
                ></GeoJSON>
              );
            })}
        </LayerGroup>

        {/* Racks */}
        {/* {portal.layout.isLayout && (
          <LayerGroup>{polygonStates.Layout && <Polygon positions={portal.layout.racks.geometry.coordinates} key={599} id={-999} color="#33A2FF" fillColor="none" weight={1} />}</LayerGroup>
        )} */}
        {/* Roads */}
        {portal.layout.isLayout && (
          <LayerGroup>{polygonStates.Layout && <Polygon positions={portal.layout.roads.geometry.coordinates} key={598} id={-998} color="#e0710f" fillColor="none" weight={1} />}</LayerGroup>
        )}
        {portal.layout.isLayout &&
          Object.values(portal.layout.inverter_groups).map((group, group_index) => {
            return Object.keys(group).map((key, index) => {
              if (!polygonStates.Wiring && WiringKeys.includes(key)) {
                return;
              }

              // console.log(key,index,group[key])
              // group[key].color = '#33A2FF'
              if (key == "inverter") {
                group[key].color = "#FFA500";
                group[key].fillColor = "#FFA500";
                group[key].weight = 1;
              }
              if (key == "combiner_boxes") {
                group[key].color = "#7FFF00";
                group[key].fillColor = "#7FFF00";
                group[key].weight = 4;
              }
              if (key == "racks") {
                group[key].color = "#33A2FF";
                group[key].fillColor = "none";
                group[key].weight = 2;
              }
              if (key == "wires") {
                // return
                group[key].color = group[key].properties.color;
                group[key].fillColor = "none";
                group[key].weight = 0.5;

                // 131 - #AAFFFF
                // 141 - #AAEAFF
                // 151 - #AAD4FF
                // 161 - #AABFFF
                // 171 - #AAAAFF
                // 181 - #BFAAFF
              }
              if (key == "strings") {
                group[key].color = group[key].properties.color;
                group[key].fillColor = "none";
                group[key].weight = 0.5;
              }
              return (
                <LayerGroup key={key}>
                  {polygonStates.Layout && (
                    <Polygon positions={group[key].geometry.coordinates} key={index} color={group[key].color} fillColor={group[key].fillColor} weight={group[key].weight}></Polygon>
                  )}
                </LayerGroup>
              );
            });
          })}
      </Map>
    </ReportMapWrap>
  );
};

export { LiveReportMap };
