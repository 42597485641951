import * as turf from '@turf/turf';

export const default_canopy_inputs = {
  // module size x/y 1modx, 2mody
  // name: 'Canopy',
  base_dimension: { modX: 4, modY: 2 },

  modXGap: 0.02, // in meters
  modYGap: 0.02, // in meters
  edgeOffset: 0.02, // in meters
  rotation: 0,
  azimuth: 180,
  geoJson: undefined,

  orientation: 1, // 1 = portrait (default), 2 = landscape
  planeType: 'single_slope',
  tilt: 7,
  towardAzimuth: 1,
  tiltToAzimuth: 7,
  tiltFromAzimuth: 7,
  action: 'create',
	disabled_module_indexes: []
};

export function fixProductDimensions(canopy) {
  return {
    x: canopy.module_dimensions.x * canopy.dimensions.modX + (canopy.dimensions.modX - 1) * canopy.modXGap + canopy.edgeOffset * 2,
    y: canopy.module_dimensions.y * canopy.dimensions.modY + (canopy.dimensions.modY - 1) * canopy.modYGap + canopy.edgeOffset * 2,
    modX: canopy.dimensions.modX,
    modY: canopy.dimensions.modY,
  };
}

export function calculateModuleDimensions(canopy) {
  return {
    x: canopy.dimensions.x,
    y: canopy.dimensions.y,
    modX: Math.max(Math.floor((canopy.dimensions.x - canopy.edgeOffset * 2 - canopy.module_dimensions.x) / (canopy.modXGap + canopy.module_dimensions.x)) + 1, canopy.base_dimension.modX),
    // IF orientation==2 (canopy has been swapped to landscape) then simply double the modY..
    // ELSE (canopy has been swapped to portrait) then divide by 2 and round down(floor)
    modY: canopy.orientation == 2 ? canopy.dimensions.modY * 2 : Math.floor(canopy.dimensions.modY / 2),
    // modY: Math.max(
    //   Math.floor((canopy.dimensions.y - canopy.edgeOffset * 2 - canopy.module_dimensions.y) / (canopy.modYGap + canopy.module_dimensions.y)) + 1,
    //   canopy.base_dimension.modY
    // ),
  };
}

// gets modx/mody
export function getModuleDimsByOrientation(orientation, module) {
  // return {
  //   x: module.mod_width,
  //   y: module.mod_height,
  // };

  if (orientation == 1) {
    // 1 = portrait (default), 2 = landscape
    return {
      x: module.mod_width,
      y: module.mod_height,
      mod_width: module.mod_width,
      mod_height: module.mod_height,
    };
  } else {
    // landscape
    return {
      x: module.mod_height,
      y: module.mod_width,
      mod_width: module.mod_width,
      mod_height: module.mod_height,
    };
  }
}

function getPoint(dist, origin, bearing) {
  let lat1 = (origin.lat * Math.PI) / 180;
  let lon1 = (origin.lng * Math.PI) / 180;
  let distRadians = dist / 6372797.6; // earth radius in meters
  let bearingRad = (bearing * Math.PI) / 180;

  let lat2 = Math.asin(Math.sin(lat1) * Math.cos(distRadians) + Math.cos(lat1) * Math.sin(distRadians) * Math.cos(bearingRad));

  let lon2 = lon1 + Math.atan2(Math.sin(bearingRad) * Math.sin(distRadians) * Math.cos(lat1), Math.cos(distRadians) - Math.sin(lat1) * Math.sin(lat2));

  lat2 = lat2 * (180 / Math.PI);
  lon2 = lon2 * (180 / Math.PI);

  return { lng: lon2, lat: lat2 };
}
export function arraysMatch(arr1, arr2) {
  // Check if the arrays are the same length
  if (arr1.length !== arr2.length) return false;

  // Check if all items exist and are in the same order
  for (var i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  // Otherwise, return true
  return true;
}
export function arrayInArray(lookArray, inArray) {
	if (inArray.length == 0) return true
	let bool = inArray.some(function(arr) {
		return arr.every(function(prop, index) {
			return lookArray[index] === prop
		})
	});

	return !bool
}


function getBounds(coords) {
  return {
    min_x: coords[0][0],
    min_y: coords[0][1],
    max_x: coords[2][0],
    max_y: coords[2][1],
  };
}

export function getRectFromOrigin(origin, dimension, rotation = 0) {
  //  origin in param
  let bottomRight = getPoint(dimension.x, origin, 90 + rotation);
  let topLeft = getPoint(dimension.y, origin, 0 + rotation);
  let topRight = getPoint(dimension.y, bottomRight, 0 + rotation);

  let latLngs = [
    [origin.lng, origin.lat],
    [topLeft.lng, topLeft.lat],
    [topRight.lng, topRight.lat],
    [bottomRight.lng, bottomRight.lat],
    [origin.lng, origin.lat],
  ];

  return latLngs;
}

export function buildRectangleCells(origin, canopy, redraw = false) {
  let cell_size = { x: canopy.dimensions.x / canopy.dimensions.modX, y: canopy.dimensions.y / canopy.dimensions.modY };
  let cell_arr = [];
  let block_arr = [];
  let do_override_color = false;
  let awayFromAzimuth = canopy.dimensions.modY - canopy.towardAzimuth; //number of modules away from azimuth

  let origin_rect = getRectFromOrigin(origin, canopy.dimensions);
  let origin_bounds = getBounds(origin_rect);
  let current_position = origin;
  // adjust for edge gaps

  let moduleCoords = getRectFromOrigin({ lat: origin_bounds.min_y, lng: origin_bounds.min_x }, canopy.module_dimensions);
  let moduleBounds = getBounds(moduleCoords);
  let xIncrement = moduleBounds.max_x - moduleBounds.min_x;
  let yIncrement = moduleBounds.max_y - moduleBounds.min_y;

  let last_max_y = 0;

  let gapCoords = getRectFromOrigin({ lat: origin_bounds.min_y, lng: origin_bounds.min_x }, { x: canopy.modXGap, y: canopy.modYGap });
  let gapBounds = getBounds(gapCoords);
  let xModGap = gapBounds.max_x - gapBounds.min_x;
  let yModGap = gapBounds.max_y - gapBounds.min_y;

  let edgeCoords = getRectFromOrigin({ lat: origin_bounds.min_y, lng: origin_bounds.min_x }, { x: canopy.edgeOffset, y: canopy.edgeOffset });
  let edgeBounds = getBounds(edgeCoords);
  let xEdgeGap = edgeBounds.max_x - edgeBounds.min_x;
  let yEdgeGap = edgeBounds.max_y - edgeBounds.min_y;

  current_position = { lat: current_position.lat + yEdgeGap, lng: current_position.lng + xEdgeGap };
  let block_position = origin;
  let block_size = {
    x: canopy.edgeOffset + canopy.module_dimensions.x + canopy.modXGap,
    y: canopy.edgeOffset + canopy.module_dimensions.y + canopy.modYGap,
  };

  for (var y = 0; y < canopy.dimensions.modY; y++) {
    if (canopy.planeType == 'inverted') {
      //
      do_override_color = y < awayFromAzimuth;
    }
    if (canopy.planeType == 'dual_tilt') {
      //
      do_override_color = y % 2 != 0;
    }
    for (var x = 0; x < canopy.dimensions.modX; x++) {
      // console.log(current_position, canopy.module_dimensions);
      let block_latLng = getRectFromOrigin(block_position, block_size);
      let block_poly = turf.polygon([block_latLng]);

      if (canopy.rotation !== 0) {
        let turfOptions = { pivot: [canopy.origin.lng, canopy.origin.lat] };
        block_poly = turf.transformRotate(block_poly, canopy.rotation, turfOptions);
      }
			let disabled_module = arrayInArray([x, y], canopy.disabled_module_indexes);
      block_poly.properties.indexes = [x, y];
      block_poly.properties.enabled = disabled_module;
      block_arr.push(block_poly);

      let cell_latLng = getRectFromOrigin(current_position, canopy.module_dimensions);
      let cell_poly = turf.polygon([cell_latLng]);

      if (canopy.rotation !== 0) {
        let turfOptions = { pivot: [canopy.origin.lng, canopy.origin.lat] };
        cell_poly = turf.transformRotate(cell_poly, canopy.rotation, turfOptions);
      }

      cell_poly.properties.indexes = [x, y];
      cell_poly.properties.enabled = disabled_module;
      cell_poly.properties.override_color = undefined;

      if (do_override_color) {
        cell_poly.properties.override_color = '#fff';
        cell_poly.properties.awayFromAzimuth = true;
      } else {
        cell_poly.properties.override_color = '#333';
        cell_poly.properties.awayFromAzimuth = false;
      }

      cell_arr.push(cell_poly);
      let cell_bounds = getBounds(cell_latLng);

      last_max_y = cell_bounds.max_y;
      current_position = { ...current_position, lng: cell_bounds.max_x + xModGap };
      block_position = { ...block_position, lng: cell_bounds.max_x };
    }
    current_position = { lat: last_max_y + yModGap, lng: origin.lng + xEdgeGap };
    block_position = { ...origin, lat: last_max_y };
  }

	// TODO: Change this to look at a removed indexes object 
  // if (redraw) {
  //   let disabled_indexes = canopy.editCellsGeoJson.filter((block) => !block.properties.enabled);
  //   for (let i = 0; i < disabled_indexes.length; i++) {
  //     let found_cell = block_arr.findIndex((_block) => arraysMatch(_block.properties.indexes, disabled_indexes[i].properties.indexes));
  //     if (found_cell >= 0) {
  //       block_arr[found_cell].properties.enabled = false;
  //       cell_arr[found_cell].properties.enabled = false;
  //     }
  //   }
  // }

  let visibleGeoJson;
	if (canopy.disabled_module_indexes.length == 0) {
		visibleGeoJson = turf.bboxPolygon([origin_bounds.min_x,origin_bounds.min_y,origin_bounds.max_x,origin_bounds.max_y]);
		if (canopy.rotation !== 0) {
			let turfOptions = { pivot: [canopy.origin.lng, canopy.origin.lat] };
			visibleGeoJson = turf.transformRotate(visibleGeoJson, canopy.rotation, turfOptions);
		}		
	} else {
		visibleGeoJson = buildVisibleRectangle(block_arr);
	}
	
  return { cell_arr, visibleGeoJson };
  // return { structure, squareGrid };
}

export function buildVisibleRectangle(block_arr) {
  let blocks = [];
  let orphan_blocks = [];
  let visibleGeoJson = null;
  Object.values(block_arr).map((block, index) => {
    if (block.properties.enabled) {
      if (blocks.length == 2) {
        visibleGeoJson = turf.union(blocks[0], blocks[1]);
        blocks = [];
        blocks.push(visibleGeoJson);
      }

      blocks.push(block);
    }
  });
  let blocks_fetColl = turf.featureCollection(blocks);

  try {
    visibleGeoJson = turf.dissolve(blocks_fetColl);
  } catch {
    alert('Cannot have orphaned modules on a Canopy');
    return false;
  }

  // if (orphan_blocks.length > 0) {
  //   visibleGeoJson = turf.featureCollection([visibleGeoJson, ...orphan_blocks]);
  // }

  return visibleGeoJson;
}

export function getAzimuthRectPosition(coords, rotation) {
  let point1 = coords[0][0];
  let point2 = coords[0][3];
  let pointsObj = turf.midpoint(point1, point2);
  let middlePoint = [pointsObj.geometry.coordinates[0], pointsObj.geometry.coordinates[1]];
  let poly = turf.polygon([
    [
      [middlePoint[0] - 0.00003, middlePoint[1] - 0.00002],
      [middlePoint[0] - 0.00001, middlePoint[1] - 0.00002],
      [middlePoint[0] - 0.00001, middlePoint[1]],
      [middlePoint[0] + 0.00001, middlePoint[1]],
      [middlePoint[0] + 0.00001, middlePoint[1] - 0.00002],
      [middlePoint[0] + 0.00003, middlePoint[1] - 0.00002],
      [middlePoint[0], middlePoint[1] - 0.00004],
      [middlePoint[0] - 0.00003, middlePoint[1] - 0.00002],
    ],
  ]);

  let turfOptions = { pivot: middlePoint };
  let rotatedPoly = turf.transformRotate(poly, rotation, turfOptions);
  return rotatedPoly;
}

export const create_UUID = () => {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

export function getFeaturesBounds(features) {
  let _features = [];
  for (var feat in features) {
    _features.push(features[feat]);
  }
  var featCollection = {
    type: 'FeatureCollection',
    features: _features,
  };
  var bbox = turf.bbox(featCollection);
  return bbox;
}

export function isNumber(value) {
  return typeof value === 'number' && !isNaN(value);
}

export function drawAlignmentLines(canopy) {
  let center = turf.center(canopy.geoJson).geometry.coordinates;
  var pivotOrigin = { pivot: [canopy.origin.lng, canopy.origin.lat] };

  let coords = getRectFromOrigin(canopy.origin, canopy.dimensions);
  let canopyBounds = getBounds(coords);

  let line_botleft_y = turf.lineString([
    [canopyBounds.min_x, canopyBounds.min_y],
    [canopyBounds.min_x - 0.0003, canopyBounds.min_y],
  ]);
  line_botleft_y = turf.transformRotate(line_botleft_y, canopy.rotation, pivotOrigin);
  let line_botleft_x = turf.lineString([
    [canopyBounds.min_x, canopyBounds.min_y],
    [canopyBounds.min_x, canopyBounds.min_y - 0.0003],
  ]);
  line_botleft_x = turf.transformRotate(line_botleft_x, canopy.rotation, pivotOrigin);
  let line_botright_y = turf.lineString([
    [canopyBounds.max_x, canopyBounds.min_y],
    [canopyBounds.max_x + 0.0003, canopyBounds.min_y],
  ]);
  line_botright_y = turf.transformRotate(line_botright_y, canopy.rotation, pivotOrigin);
  let line_botright_x = turf.lineString([
    [canopyBounds.max_x, canopyBounds.min_y],
    [canopyBounds.max_x, canopyBounds.min_y - 0.0003],
  ]);
  line_botright_x = turf.transformRotate(line_botright_x, canopy.rotation, pivotOrigin);
  let line_topleft_y = turf.lineString([
    [canopyBounds.min_x, canopyBounds.max_y],
    [canopyBounds.min_x - 0.0003, canopyBounds.max_y],
  ]);
  line_topleft_y = turf.transformRotate(line_topleft_y, canopy.rotation, pivotOrigin);
  let line_topleft_x = turf.lineString([
    [canopyBounds.min_x, canopyBounds.max_y],
    [canopyBounds.min_x, canopyBounds.max_y + 0.0003],
  ]);
  line_topleft_x = turf.transformRotate(line_topleft_x, canopy.rotation, pivotOrigin);
  let line_topright_y = turf.lineString([
    [canopyBounds.max_x, canopyBounds.max_y],
    [canopyBounds.max_x + 0.0003, canopyBounds.max_y],
  ]);
  line_topright_y = turf.transformRotate(line_topright_y, canopy.rotation, pivotOrigin);
  let line_topright_x = turf.lineString([
    [canopyBounds.max_x, canopyBounds.max_y],
    [canopyBounds.max_x, canopyBounds.max_y + 0.0003],
  ]);
  line_topright_x = turf.transformRotate(line_topright_x, canopy.rotation, pivotOrigin);

  let alignmentLines = {
    p1_x: line_botleft_x,
    p1_y: line_botleft_y,
    p2_x: line_topleft_x,
    p2_y: line_topleft_y,
    p3_x: line_topright_x,
    p3_y: line_topright_y,
    p4_x: line_botright_x,
    p4_y: line_botright_y,
  };
  return alignmentLines;
}

export function drawAlignmentCircles(coords) {
  let radius = 0.5 / 1000;
  let options = { steps: 8, units: 'kilometers' };
  let alignmentCircles = {
    p1: turf.circle([coords[0][0], coords[0][1]], radius, options),
    p2: turf.circle([coords[1][0], coords[1][1]], radius, options),
    p3: turf.circle([coords[2][0], coords[2][1]], radius, options),
    p4: turf.circle([coords[3][0], coords[3][1]], radius, options),
  };
  return alignmentCircles;
}

export function run_overlap_check(visual) {
  let overlappingStructures = [];
	if (Object.keys(visual).length <= 1) {
		return overlappingStructures
	}

  Object.values(visual).map((vis) => {
		let structure_geometry;
		if (turf.getType(vis.structure) == 'Polygon') {
			structure_geometry = turf.geometryCollection([vis.structure])
		} else {
			structure_geometry = turf.geometryCollection([...vis.structure.features]);
		}		 
    // check each structure against all the others
    Object.values(visual).map((visToCompare) => {
			let structure_geometry_to_compare;
			if (turf.getType(visToCompare.structure) == 'Polygon') {
				structure_geometry_to_compare = turf.geometryCollection([visToCompare.structure])
			} else {
				structure_geometry_to_compare = turf.geometryCollection([...visToCompare.structure.features]);
			}		 			
      // skip the one in the upper loop
      if (vis.id !== visToCompare.id) {
        //
        if (turf.booleanOverlap(structure_geometry.geometry.geometries[0], structure_geometry_to_compare.geometry.geometries[0])) {
          overlappingStructures.push(vis.id);
        } else if (turf.booleanContains(structure_geometry.geometry.geometries[0], structure_geometry_to_compare.geometry.geometries[0])) {
          overlappingStructures.push(vis.id);
          overlappingStructures.push(visToCompare.id);
        }
      }
    });
  });

  return [...new Set(overlappingStructures)];
}
