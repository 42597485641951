import React, { Fragment } from "react";

import { rectWithOffsetVerts } from "../../../../../assets";
import { Tooltip, Button } from "antd";

import { useSelector, useDispatch } from "react-redux";
import { carportActions } from "../../../../Redux/actions/carport.actions";

import { Control } from "../styles";

const PlaceCanopy = () => {
  const activeTool = useSelector((state) => state.carport.activeTool);
  const dispatch = useDispatch();

  return (
    <Control column={2}>
      <Tooltip title="Place Canopy">
        <Button
          shape="circle"
          size="large"
          style={{ margin: "0 4px", background: activeTool == "draw" ? "#60de4f" : null }}
          onClick={() => {
            if (activeTool == "draw") {
              dispatch(carportActions.setActiveTool(undefined));
            } else {
              dispatch(carportActions.setActiveTool("draw"));
              // message.info('Click Map to Place Canopy');
            }
          }}
        >
          <img style={{ marginBottom: "2px" }} src={rectWithOffsetVerts} />
        </Button>
      </Tooltip>
    </Control>
  );
};

export { PlaceCanopy };
