const defaultProducts = [
  {
    racking_name: "Terrasmart - TerraTrak - 2HT",
    track_mode: 1,
    racks: [ { key: 1, active: 1, xdim: 4.141, ydim: 44.621, module: 84, }, { key: 2, active: 1, xdim: 4.141, ydim: 30.02, module: 56, }, { key: 3, active: 1, xdim: 4.141, ydim: 15.419, module: 28, }, ],
    mods_high: 2,
    gap: 0.3,
    tilt_min: 0,
    tilt_max: 0,
    continuous: 0,
		can_continuous: 0,
    en_backtrack: 1,
		en_irradtrack: 0,
    rlim: 60,
		tilts: [0],
    shade_mode: 1,
    // string_steps: 1,
    // cell_trav: 0.156,
    bi_groundClearanceHeight: 2.1,
    bi_transmissionFactor: 0,
    bi_structureShadeFactor: 10,
    rack_grade_limit: 20,
    orientation: "portrait",
    vert_gap: 0.02,
    hori_gap: 0.01,
    string_counts: [3, 2, 1],
		mod_per_string: 28,
		Fshd_CollWidth: 4.141,
		Fshd_StringSteps: 2,
		Fshd_Celltrav: 0.156,
  },
  {
    racking_name: "RBI Solar - 2HP",
    track_mode: 0,
    racks: [
      {
        key: 1,
        active: 1,
        xdim: 28.807,
        ydim: 4.133,
        module: 56,
      },
      {
        key: 2,
        active: 1,
        xdim: 14.401,
        ydim: 4.133,
        module: 28,
      },
      {
        key: 3,
        active: 1,
        xdim: 7.198,
        ydim: 4.133,
        module: 14,
      },
    ],
    mods_high: 2,
    gap: 0,
    tilt_min: 10,
    tilt_max: 30,
		tilt_inc: 5,
    tilts: [10, 15, 20, 25, 30],
    continuous: 1,
		can_continuous: 1,
    en_backtrack: 0,
		en_irradtrack: 0,
    rlim: 0,
    shade_mode: 1,
    // string_steps: 2,
    // cell_trav: 0.156,
    bi_groundClearanceHeight: 1,
    bi_transmissionFactor: 0,
    bi_structureShadeFactor: 10,
    rack_grade_limit: 20,
    orientation: "portrait",
    vert_gap: 0.025,
    hori_gap: 0.025,
    string_counts: [2, 1, 0.5],
		mod_per_string: 28,
		Fshd_CollWidth: 4.133,
		Fshd_StringSteps: 2,
		Fshd_Celltrav: 0.156,		
  },
  {
    racking_name: "RBI Solar - 3HP",
    track_mode: 0,
    racks: [
      {
        key: 1,
        active: 1,
        xdim: 18.798,
        ydim: 3.123,
        module: 84,
      },
      {
        key: 2,
        active: 1,
        xdim: 9.399,
        ydim: 3.123,
        module: 56,
      },
      {
        key: 3,
        active: 1,
        xdim: 4.7,
        ydim: 3.123,
        module: 28,
      },
    ],
    mods_high: 3,
    gap: 0,
    tilt_min: 10,
    tilt_max: 30,
		tilt_inc: 5,
    tilts: [10, 15, 20, 25, 30],
    continuous: 1,
		can_continuous: 1,
    en_backtrack: 0,
		en_irradtrack: 0,
    rlim: 0,
    shade_mode: 1,
    // string_steps: 3,
    // cell_trav: 0.156,
    bi_groundClearanceHeight: 1,
    bi_transmissionFactor: 0,
    bi_structureShadeFactor: 10,
    rack_grade_limit: 10,
    orientation: "portrait",
    vert_gap: 0.025,
    hori_gap: 0.025,
    string_counts: [3, 2, 1],
		mod_per_string: 28,
		Fshd_CollWidth: 3.123,
		Fshd_StringSteps: 3,
		Fshd_Celltrav: 0.156,			
  },
  {
    racking_name: "RBI Solar - Dahlia - 4HP",
    track_mode: 0,
    racks: [
      {
        key: 1,
        active: 1,
        xdim: 28.514,
        ydim: 8.076,
        module: 112,
      },
      {
        key: 2,
        active: 0,
        xdim: 28.514,
        ydim: 8.076,
        module: 112,
      },
      {
        key: 3,
        active: 0,
        xdim: 28.514,
        ydim: 8.076,
        module: 112,
      },
    ],
    mods_high: 4,
    gap: 0,
    tilt_min: 7.5,
    tilt_max: 12.5,
		tilt_inc: 2.5,
    tilts: [7.5, 10, 12.5],
    continuous: 1,
		can_continuous: 1,
    en_backtrack: 0,
		en_irradtrack: 0,
    rlim: 0,
    shade_mode: 1,
    // string_steps: 4,
    // cell_trav: 0.156,
    bi_groundClearanceHeight: 1.0,
    bi_transmissionFactor: 0,
    bi_structureShadeFactor: 10,
    rack_grade_limit: 10,
    orientation: "portrait",
    vert_gap: 0.025,
    hori_gap: 0.025,
    string_counts: [4, 4, 4],
		mod_per_string: 28,
		Fshd_CollWidth: 8.076,
		Fshd_StringSteps: 4,
		Fshd_Celltrav: 0.156,			
  },
  {
    racking_name: "Terrasmart - GLIDE - 2HP",
    track_mode: 0,
    racks: [
      {
        key: 1,
        active: 1,
        xdim: 28.807,
        ydim: 4.133,
        module: 24,
      },
      {
        key: 2,
        active: 0,
        xdim: 28.807,
        ydim: 4.133,
        module: 24,
      },
      {
        key: 3,
        active: 0,
        xdim: 28.807,
        ydim: 4.133,
        module: 24,
      },
    ],
    mods_high: 2,
    gap: 0.15,
    tilt_min: 10,
    tilt_max: 30,
		tilt_inc: 5,
    tilts: [10, 15, 20, 25, 30],
    continuous: 0,
		can_continuous: 0,
    en_backtrack: 0,
		en_irradtrack: 0,
    rlim: 0,
    shade_mode: 1,
    string_steps: 2,
    cell_trav: 0.156,
    bi_groundClearanceHeight: 1.0,
    bi_transmissionFactor: 0,
    bi_structureShadeFactor: 10,
    rack_grade_limit: 30,
    orientation: "portrait",
    vert_gap: 0.02,
    hori_gap: 0.01,
    string_counts: [],
		mod_per_string: 24,
		Fshd_CollWidth: 4.133,
		Fshd_StringSteps: 2,
		Fshd_Celltrav: 0.156,				
  },
  {
    racking_name: "Terrasmart - GLIDE - 4HL",
    track_mode: 0,
    racks: [
      {
        key: 1,
        active: 1,
        xdim: 28.807,
        ydim: 4.133,
        module: 24,
      },
      {
        key: 2,
        active: 0,
        xdim: 28.807,
        ydim: 4.133,
        module: 24,
      },
      {
        key: 3,
        active: 0,
        xdim: 28.807,
        ydim: 4.133,
        module: 24,
      },
    ],
    mods_high: 4,
    gap: 0.15,
    tilt_min: 10,
    tilt_max: 30,
		tilt_inc: 5,
    tilts: [10, 15, 20, 25, 30],
    continuous: 0,
		can_continuous: 0,
    en_backtrack: 0,
		en_irradtrack: 0,
    rlim: 0,
    shade_mode: 1,
    string_steps: 16,
    cell_trav: 0.156,
    bi_groundClearanceHeight: 1.0,
    bi_transmissionFactor: 0,
    bi_structureShadeFactor: 10,
    rack_grade_limit: 30,
    orientation: "landscape",
    vert_gap: 0.02,
    hori_gap: 0.01,
    string_counts: [],
		mod_per_string: 24,
		Fshd_CollWidth: 4.133,
		Fshd_StringSteps: 16,
		Fshd_Celltrav: 0.156,			
  },
];


export default defaultProducts;
