export const divStyle = {
  width: '100%',
  height: '100%',
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
  // borderWidth: 0,
  // borderColor: '#666',
  // borderStyle: 'solid',
  // borderRadius: 5
};
export const divStyle2 = {
  width: '100%',
  height: 35,
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  borderWidth: 0,
  borderColor: '#666',
  borderStyle: 'solid',
  borderRadius: 5,
  whiteSpace: 'normal'
};
export const toolbarButton = {
  width: 45,
  height: 45,
  paddingLeft: 1,
  paddingRight: 1,
  paddingTop: 1,
  paddingBottom: 1
};
export const toolbarSpacing = {
  width: 2,
  height: 5
};
export const expandiv = {
  position: 'fixed',
  width: 35,
  height: 35,
  paddingLeft: 5,
  paddingRight: 0,
  paddingTop: 5,
  paddingBottom: 0
};
export const overlayStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  padding: '2.5em 0',
  background: 'rgba(0,0,0,0.5)',
  textAlign: 'center',
  color: '#fff',
  zIndex: 10000
};
export const overlayStyle2 = {
  position: 'relative',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  padding: '0.2em 0',
  background: 'rgba(0,0,0,0.5)',
  // textAlign: 'center',
  color: '#fff',
  zIndex: 100000
};
export const mapOptionsStyle = {
  background: '#fff',
  left: 55,
  top: 5,
  display: 'block',
  position: 'relative',
  paddingLeft: 5,
  paddingRight: 5,
  paddingTop: 5,
  paddingBottom: 5
  // paddingLeft:"45px", /* adjust if necessary */
  // zIndex: -50
};
export const labelStyle = {
  display: 'block',
  position: 'relative',
  paddingLeft: '45px' /* adjust if necessary */
};
export const labelStyle2 = {
  display: 'block',
  position: 'relative',
  paddingLeft: '75px' /* adjust if necessary */,
  marginBottom: '0px'
};
export const spanStyle = {
  position: 'absolute',
  left: 0
};
export const resizablePanelStyle = {
  // display: 'flex',
  // alignItems: "center",
  position: 'relative',
  // justifyContent: "center",
  transform: 'translate(0px, 64px)',
  border: 'solid 1px #ddd',
  background: '#f0f0f0'
};
export const surfaceDropDownOptionStyle = {
  width: 30,
  height: 30,
  paddingLeft: 1,
  paddingRight: 1,
  paddingTop: 1,
  paddingBottom: 1
};
export const hidden = {
  display: 'none'
};
export const ArcGIS = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';
export const OpenStreet = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
export const a3Size = {
  width: 1123,
  height: 1587,
  className: 'a3CssClass',
  tooltip: 'A custom A3 size'
};
