import config from 'config';
import { authHeader } from '../helpers';

export const userServices = {
  getAll,
  filterProject,
  importData,
};

function getAll() {
  // console.log('isAccessTokenExpired:', isAccessTokenExpired)
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/platform/rbi/project/`, requestOptions).then(handleResponse);
}

function filterProject(projects, filterText) {
  if (filterText > 1) {
    let filteredProject = projects.filter((project) => project.name.includes(filterText));
    return filteredProject;
  }
}

function handleResponse(response) {
  return response.text().then((text) => {
    let data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function importData(data) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}/dash/import/`, requestOptions).then(handleResponse);
}
