import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { carportActions } from '../../Redux';

import { Typography, Tooltip, Button, Popconfirm, message } from "antd";

import { InputRow } from './styles'
import { trashCanBlack, copy } from '../../../assets'

function NameInput() {
	const dispatch = useDispatch();

	const name = useSelector((state) => state.carport.selectedInputs.name);
	const canopyId = useSelector((state) => state.carport.selectedInputs.id);
	const [name_error, set_name_error] = useState(undefined)

	function onChangeName(newCanopyName) {
		if (newCanopyName == "") {
			set_name_error('Canopy must have a name.')
			return
		} else if (name_error != undefined) {
			set_name_error(undefined)
		}
		if (newCanopyName != name) {
			dispatch(carportActions.updateInputByName('name', newCanopyName));
		}
	}

	return (
		<InputRow>
			<Typography.Text
				strong
				editable={{
					onChange: onChangeName,
				}}
			>
				{name}
			</Typography.Text>
			<>
				<div className="copy-delete">
					<Tooltip title="Copy">
						<Button
							type="link"
							onClick={() => {
								dispatch(carportActions.copyRectangle(canopyId));
								message.info('Click map to paste Canopy', 5);
							}}
						>
							<img src={copy} />
						</Button>
					</Tooltip>
					<Tooltip title="Delete">
						<Popconfirm
							placement="right"
							title={`Delete ${name}`}
							onCancel={() => console.log("canceled")}
							okText="Yes"
							cancelText="No"
							onConfirm={(e) => {
								if (e.originalEvent) e.originalEvent.view.L.DomEvent.stopPropagation(e);
								if (e.nativeEvent) e.nativeEvent.view.L.DomEvent.stopPropagation(e);																		
								dispatch(carportActions.deleteRectangle(canopyId));
							}}
						>
							<Button type="link">
								<img src={trashCanBlack} />
							</Button>
						</Popconfirm>
					</Tooltip>
				</div>
			</>		
		</InputRow>
	)
}

export { NameInput }