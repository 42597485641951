import config from 'config';

// const config.apiUrl = 'http://localhost:8000'
// const config.apiUrl = 'https://api.sunfig.com/swa'
// const config.apiUrl = 'https://api.sunfig.solar/swa-test'
// const config.apiUrl = 'https://api.sunfig.solar/swa-dev'

import { verifyToken, refreshToken } from './authenticate';
const contactUrl = 'https://api.sunfig.solar/contact';

export async function initAnalysis(myInputs) {
  let response = await fetch(`${backendUrl}/init/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      inputs: myInputs
    })
  });

  let responseJson = await response.json();

  return responseJson;
}

export async function getSurfaces() {
  let response = await fetch(`${backendUrl}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      action: 'get_all'
    })
  });

  let responseJson = await response.json();
  return responseJson;
}
export async function deleteSurface(id) {
  let response = await fetch(`${backendUrl}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      action: 'delete',
      id: id
    })
  });

  let responseJson = await response.json();
  return responseJson;
}

// used to get projects from backend
export async function getProjects() {
  let token = await localStorage.getItem('access');
  if (await verifyToken(token)) {
    let response = await fetch(`${config.apiUrl}/platform/rbi/project/`, {
      method: 'GET',
      headers: {
        // 'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    let responseJson = await response.json();
    return responseJson;
  } else {
    let refresh = localStorage.getItem('refresh');
    let access = await refreshToken(refresh);
    if (access) {
      let response = await fetch(`${config.apiUrl}/platform/rbi/project/`, {
        method: 'GET',
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization': `Bearer ${access}`
        }
      });

      let responseJson = await response.json();
      return responseJson;
    }
  }
}

export async function getTopoId(bbox, generate_layers, grade_limits) {
  let token = await localStorage.getItem('access');
  const requestOptions = {
    method: 'POST',
    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    body: JSON.stringify({ bbox, generate_layers, grade_limits })
  };
  let response = await fetch(`${config.apiUrl}/dash/topo/`, requestOptions);
  let responseJson = await response.json();
  return responseJson;
}
export async function generateTopoLayers(topoId, grade_limits, generate_layers) {
  let token = await localStorage.getItem('access');
  const requestOptions = {
    method: 'POST',
    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    body: JSON.stringify({ topo_id: topoId, grade_limits, generate_layers })
  };
  let response = await fetch(`${config.apiUrl}/dash/topo/`, requestOptions);
  let responseJson = await response.json();
  return responseJson;
}
export async function checkTopoId(id) {
  let token = await localStorage.getItem('access');
  const requestOptions = {
    method: 'GET',
    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
  };
  let response = await fetch(`${config.apiUrl}/dash/topo/?topo_id=${id}`, requestOptions);
  let responseJson = await response.json();
  return responseJson;
}

// used to create new and save previously existing projects
export async function saveProject(project) {
  let token = await localStorage.getItem('access');
  if (await verifyToken(token)) {
    let response = await fetch(`${config.apiUrl}/platform/rbi/project/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        id: project.id,
        name: project.name,
        active: project.active,
        weather: project.weather,
        inputs: project.inputs,
        surface: project.surface
      })
    });

    let responseJson = await response.json();
    return responseJson;
  } else {
    let refresh = localStorage.getItem('refresh');
    let access = await refreshToken(refresh);
    if (access) {
      let response = await fetch(`${config.apiUrl}/platform/rbi/project/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${access}`
        },
        body: JSON.stringify({
          id: project.id,
          name: project.name,
          active: project.active,
          weather: project.weather,
          inputs: project.inputs,
          surface: project.surface
        })
      });

      let responseJson = await response.json();
      return responseJson;
    }
  }
}
// used to check and receive results of a runId
// export async function _getResults(id) {
//   // console.log('_init ID', id);
//   let response = await fetch(`${config.apiUrl}/dash/swm/?runId=${id}`, {
//     // mode: 'no-cors',
//     method: 'GET',
//     headers: { ...authHeader(), 'Content-Type': 'application/json' }
//   });

//   let responseJson = await response.json();
//   console.log('services results', responseJson);
//   return responseJson;
// }

// OLD WAY
export async function _getResults(id) {
  let token = await localStorage.getItem('access');
  if (await verifyToken(token)) {
    // GET http://localhost:8000/platform/rbi/result/?runId=a34ae313fc87420295e9417d61d18543&action=results
    let response = await fetch(`${config.apiUrl}/platform/rbi/result/?runId=${id}&action=results`, {
      // mode: 'no-cors',
      method: 'GET',
      headers: {
        // 'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    let responseJson = await response.json();
    return responseJson;
  } else {
    let refresh = localStorage.getItem('refresh');
    let access = await refreshToken(refresh);
    if (access) {
      let response = await fetch(`${config.apiUrl}/platform/rbi/result/?runId=${id}&action=results`, {
        method: 'GET',
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization': `Bearer ${access}`
        }
      });

      let responseJson = await response.json();
      return responseJson;
    }
  }
}

export async function _getRacks(id) {
  let token = await localStorage.getItem('access');
  if (await verifyToken(token)) {
    // GET http://localhost:8000/platform/rbi/result/?runId=a34ae313fc87420295e9417d61d18543&action=results
    let response = await fetch(`${config.apiUrl}/platform/rbi/result/?runId=${id}&action=racks`, {
      method: 'GET',
      headers: {
        // 'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    let responseJson = await response.json();
    return responseJson;
  } else {
    let refresh = localStorage.getItem('refresh');
    let access = await refreshToken(refresh);
    if (access) {
      let response = await fetch(`${config.apiUrl}/platform/rbi/result/?runId=${id}&action=racks`, {
        method: 'GET',
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization': `Bearer ${access}`
        }
      });

      let responseJson = await response.json();
      return responseJson;
    }
  }
}
// used to initialize a run
// export async function _init(inputs) {
//   let response = await fetch(`${config.apiUrl}/dash/swm/`, {
//     method: 'POST',
//     headers: { ...authHeader(), 'Content-Type': 'application/json' },
//     body: JSON.stringify({
//       ...inputs
//     })
//   });

//   let responseJson = await response.json();
//   return responseJson;
// }

// OLD WAY
export async function _init(inputs) {
  let token = await localStorage.getItem('access');
  if (await verifyToken(token)) {
    let response = await fetch(`${config.apiUrl}/platform/rbi/run/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        inputs
      })
    });

    let responseJson = await response.json();
    return responseJson;
  } else {
    let refresh = localStorage.getItem('refresh');
    let access = await refreshToken(refresh);
    if (access) {
      let response = await fetch(`${config.apiUrl}/platform/rbi/run/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${access}`
        },
        body: JSON.stringify({
          inputs
        })
      });

      let responseJson = await response.json();
      return responseJson;
    }
  }
}

export async function reportBug(msg, data) {
  let response = await fetch(`${contactUrl}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      type: 3,
      email: 'email',
      message: `RBI Bug Report - ${msg}`,
      state: data
    })
  });

  let responseJson = await response;
  return responseJson;
}
export async function sendBugReportSecure(params) {
  let token = await localStorage.getItem('access');
  let response = await fetch(`${config.apiUrl}/platform/rbi/bug/`, {
    method: 'POST',
    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...params })
  });

  let responseJson = await response;
  return responseJson;
}

export async function shareProject(params) {
  let token = await localStorage.getItem('access');
  let response = await fetch(`${config.apiUrl}/platform/rbi/share/`, {
    method: 'POST',
    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...params })
  });

  let responseJson = await response;
  return responseJson;
}

export async function getCounty(lat, lon) {
  let response = await fetch(`https://geo.fcc.gov/api/census/area?lat=${lat}&lon=${lon}&format=json`, {
    method: 'GET'
  });

  let responseJson = await response.json();
  return responseJson;
}

function handleResponse(response) {
  return response.text().then(text => {
    let data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        console.log('error 401');
        // logout();
        // location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
