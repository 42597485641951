import React, { Fragment } from 'react';

//assets
import ground from '../PDFReport/ground.png';
import sunPower from '../PDFReport/sunPower.png';
import lightbulb from '../PDFReport/lightbulb.png';
import counting from '../PDFReport/counting.png';
import icon from '../PDFReport/icon.png';

const LiveCanopyReportSummary = (props) => {
  return (
    <Fragment>
      <section className="info">
        <div className="info-img">
          <img src={lightbulb} />
        </div>
        <div className="info-text">
          <p>Module Rating</p>
          <p className="info-subtext">{`${props.reportData.overview.rating} W`}</p>
        </div>
      </section>
      <section className="info">
        <div className="info-img">
          <img src={counting} />
        </div>
        <div className="info-text">
          <p>Module Qty</p>
          <p className="info-subtext">{`${props.reportData.overview.mod_qty}`}</p>
        </div>
      </section>
      <section className="info">
        <div className="info-img">
          <img src={sunPower} />
        </div>
        <div className="info-text">
          <p>Site Capacity</p>
          <p className="info-subtext">{`${(props.reportData.overview.capacity / 1000).toFixed(2)} KW`}</p>
        </div>
      </section>
      <section className="info">
        <div className="info-img">
          <img src={ground} />
        </div>
        <div className="info-text">
          <p>Canopy Count</p>
          <p className="info-subtext">{`${props.reportData.overview.count}`}</p>
        </div>
      </section>
      <section className="info">
        <div className="info-img">
          <img src={icon} />
        </div>
        <div className="info-text">
          <p>Location</p>
          <p className="info-subtext">{`${props.reportData.overview.loc[0].toFixed(4)}, ${props.reportData.overview.loc[1].toFixed(4)}`}</p>
        </div>
      </section>
    </Fragment>
  );
};

export { LiveCanopyReportSummary };
