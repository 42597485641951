import React, { Fragment } from "react";

import { upArrow, downArrow, leftArrow, rightArrow, duplicate } from "../../../../../assets";
import { Tooltip, Button, Dropdown, Menu } from "antd";

import { useSelector, useDispatch } from "react-redux";
import { carportActions } from "../../../../Redux/actions/carport.actions";

import { Control } from "../styles";

const DuplicateCanopy = () => {
  const _selected = useSelector((state) => state.carport.selected);
  const dispatch = useDispatch();

  const duplicateRectangle = (direction) => {
    dispatch(carportActions.duplicateCanopy(direction));
  };

  const duplicateDropdown = (duplicateRectangle) => (
    <Menu style={{ background: "none" }}>
      <Menu.Item style={{ margin: "2px", padding: "0" }}>
        <Tooltip placement="left" title="Duplicate Up">
          <Button shape="circle" size="large" onClick={() => duplicateRectangle("up")}>
            <img src={upArrow} alt="" className="toolbar-control" />
          </Button>
        </Tooltip>
      </Menu.Item>
      <Menu.Item style={{ margin: "2px", padding: "0" }}>
        <Tooltip placement="left" title="Duplicate Down">
          <Button shape="circle" size="large" onClick={() => duplicateRectangle("down")}>
            <img src={downArrow} alt="" className="toolbar-control" />
          </Button>
        </Tooltip>
      </Menu.Item>
      <Menu.Item style={{ margin: "2px", padding: "0" }}>
        <Tooltip placement="left" title="Duplicate Left">
          <Button shape="circle" size="large" onClick={() => duplicateRectangle("left")}>
            <img src={leftArrow} alt="" className="toolbar-control" />
          </Button>
        </Tooltip>
      </Menu.Item>
      <Menu.Item style={{ margin: "2px", padding: "0" }}>
        <Tooltip placement="left" title="Duplicate Right">
          <Button shape="circle" size="large" onClick={() => duplicateRectangle("right")}>
            <img src={rightArrow} alt="" className="toolbar-control" />
          </Button>
        </Tooltip>
      </Menu.Item>
    </Menu>
  );

  return (
    <Control column={1}>
      <Tooltip title="Duplicate Selected">
        <Dropdown disabled={_selected ? false : true} overlay={() => duplicateDropdown(duplicateRectangle)} placement="bottomLeft">
          <Button shape="circle" size="large" style={{ margin: "0 4px", backgroundColor: _selected ? "#fff" : "#9e9e9e", borderColor: _selected ? "#60de4f" : "#fff" }}>
            <img src={duplicate} alt="" />
          </Button>
        </Dropdown>
      </Tooltip>
    </Control>
  );
};

export { DuplicateCanopy };
