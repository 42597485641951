import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { portalActions, inputsActions } from "../../Redux/actions";

//antd
import { Tooltip } from "antd";
import { DownSquareOutlined, UpSquareOutlined } from "@ant-design/icons";

// components
import { resultColumns } from "./resultColumns";

import { ResultTableWrapper } from "./styles";

const ResultTable = (props) => {
  const [sortMode, setSortMode] = useState({ col: props.type == "groundmount" ? "gcr" : "moduleCount", sort: "ascend" });

  const dispatch = useDispatch();

  const selectedResult = useSelector((state) => state.portal.selectedResult);
  const selectedRow = useSelector((state) => state.portal.selectedRow);
  const portal = useSelector((state) => state.portal);
  const inputs = useSelector((state) => state.inputs);
  const carport = useSelector((state) => state.carport);
  const download_lock = useSelector((state) => state.inputs.download_lock);

  // write a custom sorter for this results to be based on sortMode
  const results = props.type == "groundmount" ? onSortResults(Object.values(portal.results)) : onSortResults(Object.values(carport.results));

  const tableColumns = resultColumns(props.type, inputs);

  function onSetMiniSort(_sorter, event) {
    // this button is nested in a column header that is clickable too, so stop event from bubbling to that header
    event.stopPropagation();
    let sorter;
    if (props.type != "groundmount" && _sorter == "name") {
      sorter = "index";
    } else {
      sorter = _sorter;
    }

    if (sorter == sortMode.col && sortMode.sort == "ascend") {
      // flip
      setSortMode({ col: sorter, sort: "descend" });
    } else {
      // defaults to ascend
      setSortMode({ col: sorter, sort: "ascend" });
    }
  }
  function onSelectMiniResult(result) {
    if (selectedRow && selectedRow.id == result.id) return;
    if (selectedRow && selectedRow.id !== result.id && download_lock == false) {
      dispatch(inputsActions.reset_sf_capture());
    }
    dispatch(portalActions.selectResult(result));
  }

  function onDoubleClickResult(result) {
    dispatch(portalActions.getLayoutData(result.id));
  }
  function onSortResults(results) {
    if (sortMode.sort == "ascend") {
      // ascending
      results.sort((a, b) => a[sortMode.col] - b[sortMode.col]);
    } else {
      // descending
      results.sort((a, b) => b[sortMode.col] - a[sortMode.col]);
    }
    return results;
  }

  return (
    <ResultTableWrapper type={props.type}>
      <table>
        <thead>
          <tr key="column-headers">
            {tableColumns.map((col, index) => {
              if (!col.visible) return;
              return (
                <th
                  className={`column-name ${sortMode.col == col.key ? "active" : ""}`}
                  // style={{ width: 'min-content', whiteSpace: 'nowrap', textAlign: 'center' }}
                  key={index}
                  onClick={(e) => onSetMiniSort(col.key, e)}
                >
                  <span>{col.title}</span>
                  <span className="mini-sort">{sortMode.col == col.key ? sortMode.sort == "ascend" ? <UpSquareOutlined /> : <DownSquareOutlined /> : <span></span>}</span>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {results.map((res) => {
            return (
              <Tooltip placement="left" title={props.type == "groundmount" && "Double click to Plot"} mouseEnterDelay={0.2} key={res.id}>
                <tr
                  key={res.id}
                  className={`mini-data ${selectedRow && selectedRow.id == res.id && "selected"}`}
                  onClick={props.type == "groundmount" ? () => onSelectMiniResult(res) : null}
                  onDoubleClick={props.type == "groundmount" ? () => onDoubleClickResult(res) : null}
                >
                  {tableColumns.map((col, index) => {
                    if (col.visible) {
                      if (props.type !== "groundmount" && res["Canopy Type"] == "inverted" && col.key == "Tilt (°)") {
                        return <td key={`${res.id}-${col.key}`}>{col.render ? col.render(res) : `${res["Tilt To Azimuth"]} to Azimuth / ${res["Tilt From Azimuth"]} from Azimuth`}</td>;
                      } else {
                        return <td key={`${res.id}-${col.key}`}>{col.render ? col.render(res) : res[col.key]}</td>;
                      }
                    }
                  })}
                </tr>
              </Tooltip>
            );
          })}
        </tbody>
      </table>
    </ResultTableWrapper>
    // <ResultTableWrapper type={props.type}>
    //   <table>
    //     <thead>
    //       <tr key="column-headers">
    //         {tableColumns.map((col, index) => {
    //           if (!col.visible) return;
    //           return (
    //             <th
    //               className={`column-name ${sortMode.col == col.key ? 'active' : ''}`}
    //               style={{ width: 'min-content', whiteSpace: 'nowrap', textAlign: 'center' }}
    //               key={index}
    //               onClick={(e) => onSetMiniSort(col.key, e)}
    //             >
    //               <span>{col.title}</span>
    //               <span className="mini-sort">{sortMode.col == col.key ? sortMode.sort == 'ascend' ? <UpSquareOutlined /> : <DownSquareOutlined /> : <span></span>}</span>
    //             </th>
    //           );
    //         })}
    //       </tr>
    //     </thead>
    //     <tbody>
    //       {results.map((res) => {
    //         return (
    //           <Tooltip placement="left" title={props.type == 'groundmount' && 'Double click to Plot'} mouseEnterDelay={0.2} key={res.id}>
    //             <tr
    //               key={res.id}
    //               className={`mini-data ${selectedRow && selectedRow.id == res.id && 'selected'}`}
    //               onClick={props.type == 'groundmount' ? () => onSelectMiniResult(res) : null}
    //               onDoubleClick={props.type == 'groundmount' ? () => onDoubleClickResult(res) : null}
    //             >
    //               {tableColumns.map((col, index) => {
    //                 if (col.visible) {
    //                   if (props.type !== 'groundmount' && res['Canopy Type'] == 'inverted' && col.key == 'Tilt (°)') {
    //                     return <td key={`${res.id}-${col.key}`}>{col.render ? col.render(res) : `${res['Tilt To Azimuth']} to Azimuth / ${res['Tilt From Azimuth']} from Azimuth`}</td>;
    //                   } else {
    //                     return <td key={`${res.id}-${col.key}`}>{col.render ? col.render(res) : res[col.key]}</td>;
    //                   }
    //                 }
    //               })}
    //             </tr>
    //           </Tooltip>
    //         );
    //       })}
    //     </tbody>
    //   </table>
    // </ResultTableWrapper>
  );
};

export { ResultTable };
