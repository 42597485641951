import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { carportActions } from '../../Redux';

import { Input, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
const suffix_loading = <LoadingOutlined style={{ fontSize: 'x-small' }} spin />;
import { isNumber } from '../../Redux'

import { InputRow } from './styles'
import { debouncedUpdateinput } from './inputs.helper'

function ModuleWidthInput() {
	const dispatch = useDispatch();

	const mod_width = useSelector((state) => state.carport.selectedInputs.module.mod_width);
	const [local_mod_width, set_local_mod_width] = useState(mod_width)
	const [local_loading, set_local_loading] = useState(false)
	const [mod_width_error, set_mod_width_error] = useState(undefined)

	useEffect(() => {
		if (mod_width && mod_width == local_mod_width) {
			set_local_loading(false);
		} else if (!local_loading) {
			set_local_mod_width(mod_width)
		}
	}, [mod_width]);

	function onChangeInput(e) {
		set_local_mod_width(e.target.value)
		let changed_value = parseFloat(e.target.value);

		if (!isNumber(changed_value)) {
			set_mod_width_error('Invalid Input')
			return
		} else if (mod_width_error != undefined) {
			set_mod_width_error(undefined);
		}
		set_local_loading(true)
		// debounced method
		debouncedUpdateinput.cancel();
		debouncedUpdateinput(dispatch, 'module.mod_width', changed_value)
	}

	return (
		<InputRow>
			<label>Module Width:</label>
			<Input
				size="small"
				value={local_mod_width}
				type="number"
				step={0.1}
				min={0}
				suffix={
					local_loading ? <Spin indicator={suffix_loading} />
					: <span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>m</span>
				}
				max="999"
				onChange={onChangeInput}
			></Input>		
		</InputRow>
	)
}

export { ModuleWidthInput };
