import React from 'react';

import { MapSearch, PlaceCanopy, DeleteAll, DuplicateCanopy, ToggleAlignment, ToggleNonRect } from './components';
import { LeafletControl } from '../index';

import { ToolbarLayout, HighLevelControls, SelectedControls } from './styles';

const CanopyToolbar = () => {
  return (
    <LeafletControl position="topleft">
      <ToolbarLayout>
        <HighLevelControls>
          <MapSearch />
          <PlaceCanopy />
          <DeleteAll />
        </HighLevelControls>
        <SelectedControls>
          <DuplicateCanopy />
          <ToggleAlignment />
          <ToggleNonRect />
        </SelectedControls>
      </ToolbarLayout>
    </LeafletControl>
  );
};

export { CanopyToolbar };
