
import styled from 'styled-components';

export const SelectRow = styled.div`
	grid-column: 1 / -1;
	width: 100%;
	display: grid;
	place-items: center;
	z-index: 9;
	margin-top: 5px;
`;
