import React from "react";
import { DrawerExt as DrawerDeep } from "./Drawer";
// Drawer for displaying info text about a specific input
// activated through ? button from several different sections of SIFT
// Lives in CapacityTool.jsx
// drawer content pulled based on index provided in call

/*
pages called from:
-pan = 0
-ond = 1
-module gamma = 2
-racking specs = 3
-racking shade mode = 4
-weather location = 5
-weather sources = 6
-performance model = 7
-config margin = 8
-config ai = 9
-config racking align = 10
-config azimuth = 11
-finance install costs = 12
-finance operating costs = 13
-map controls = 14
-weather import 15
-config help 16
*/

import { Drawer, Button } from "antd";
import * as Drawers from "./DrawerContent";

function getContent(props, drawer) {
  const content = [
    // ------------------------------------------ Ground Mount Content ---------------------------------------------
    { key: "rbi_root", title: "Ground Mount Help", component: <Drawers.Root_Tab {...props} />, width: "55%" },
    { key: "sunfig_support", title: "Keys to Support", component: <Drawers.Sunfig_Support {...props} />, width: "40%" },

    // General
    { key: "how_portal_works", title: "How it Works", component: <Drawers.How_Portal_Works {...props} />, width: "40%" },
    { key: "portal_side_bar", title: "Sidebar Features", component: <Drawers.Portal_Sidebar {...props} />, width: "40%" },
    { key: "portal_project_management", title: "Project Management", component: <Drawers.Portal_Project_Management {...props} />, width: "40%" },

    // Map
    { key: "map_overview", title: "Map Overview", component: <Drawers.Map_Overview {...props} />, width: "40%" },
    { key: "import_kmz", title: "Import from KMZ", component: <Drawers.Import_KMZ {...props} />, width: "40%" },
    { key: "map_controls", title: "Map Controls", component: <Drawers.Map_Controls {...props} />, width: "40%" },
    { key: "map_hotkeys", title: "Map Hotkeys", component: <Drawers.Map_Hotkeys {...props} />, width: "40%" },

    // Simplified Tab
    { key: "basic_inputs", title: "Basic Inputs", component: <Drawers.Basic_Inputs {...props} />, width: "40%" },
    { key: "portal_module_tab", title: "Module Tab", component: <Drawers.Portal_Module_Tab {...props} />, width: "40%" },

    // Advanced Tab
    // **** Module Tab is the same tab located above in the simplfied tab
    { key: "racking_tab", title: "Racking Tab", component: <Drawers.Racking_Tab {...props} />, width: "40%" },
    { key: "performance_tab", title: "Performance Tab", component: <Drawers.Performance_Tab {...props} />, width: "40%" },
    { key: "topography_tab", title: "Topography Tab", component: <Drawers.Topography_Tab {...props} />, width: "40%" },

    // Generating Results
    { key: "generating_ground_mount_results", title: "Overview", component: <Drawers.Generating_Portal_Results {...props} />, width: "40%" },
    { key: "portal_results_table", title: "Results Table", component: <Drawers.Portal_Results_Table {...props} />, width: "40%" },
    { key: "portal_report", title: "Report", component: <Drawers.Portal_Report {...props} />, width: "40%" },
    { key: "plot_dims", title: "Plot Dimensions", component: <Drawers.Plot_Dims {...props} />, width: "40%" },

    // Additional Information
    { key: "weather_source", title: "Weather Source", component: <Drawers.Weather_Source {...props} />, width: "40%" },
    { key: "bifacial", title: "Bifacial Parameters", component: <Drawers.Bifacial {...props} />, width: "40%" },

    // ---------------------------------------- Canopy Content -----------------------------------------------
    // root canopy help drawers
    { key: "canopy_root", title: "Canopy Help", component: <Drawers.Canopy_Tab {...props} />, width: "55%" },

    // General
    { key: "how_it_works", title: "How It Works", component: <Drawers.How_It_Works {...props} />, width: "40%" },
    { key: "side_bar_features", title: "Sidebar Features", component: <Drawers.Sidebar_Features {...props} />, width: "40%" },
    { key: "project_management", title: "Project Management", component: <Drawers.Project_Management {...props} />, width: "40%" },

    // Map
    { key: "canopy_overview", title: "Overview", component: <Drawers.Canopy_Overview {...props} />, width: "40%" },
    { key: "import_image", title: "Import Image", component: <Drawers.Import_Image {...props} />, width: "40%" },
    { key: "canopy_controls", title: "Canopy_Controls", component: <Drawers.Canopy_Controls {...props} />, width: "40%" },
    { key: "canopy_hotkeys", title: "Canopy Hotkeys", component: <Drawers.Canopy_Hotkeys {...props} />, width: "40%" },

    // Inputs
    { key: "module_tab", title: "Module Tab", component: <Drawers.Module_Tab {...props} />, width: "40%" },
    { key: "canopies_tab", title: "Canopies Tab", component: <Drawers.Canopies_Tab {...props} />, width: "40%" },
    { key: "canopy_inputs_tab", title: "Canopy Inputs Tab", component: <Drawers.Canopy_Inputs_Tab {...props} />, width: "40%" },
    { key: "global_override_tab", title: "Global Override Tab", component: <Drawers.Global_Override_Tab {...props} />, width: "40%" },

    // Generating Results
    { key: "canopy_results_overview", title: "Canopy Results Overview", component: <Drawers.Canopy_Results_Overview {...props} />, width: "40%" },
    { key: "results_table", title: "Results Table", component: <Drawers.Results_Table {...props} />, width: "40%" },
    { key: "pdf_reports", title: "PDF Reports", component: <Drawers.PDF_Reports {...props} />, width: "40%" },

    // additional information
    { key: "additional_canopy_information", title: "Weather Source", component: <Drawers.Canopy_Weather_Source {...props} />, width: "40%" },

    // ------------------------------------------------ Archived Content ---------------------------------------------------
    // { key: "export_map", title: "Export to KMZ or DXF", component: <Drawers.Export_Map {...props} />, width: "40%" },
    // { key: "plot_map", title: "Dimensions in Plot (GCR & Pitch Definitions)", component: <Drawers.Plot_Map {...props} />, width: "40%" },
    // { key: "rack_specs", title: "Product Dimensions", component: <Drawers.Rack_Specs {...props} />, width: "40%" },
    // { key: "product_tab", title: "Product Tab", component: <Drawers.Product_Tab {...props} />, width: "40%" },
    // { key: "select_product_module", title: "Select Product and Module", component: <Drawers.Select_Product_Module {...props} />, width: "40%" },
    // { key: "generating_results_overview", title: " Generating Results Overview", component: <Drawers.Generating_Results_Overview {...props} />, width: "40%" },
    // { key: "result_explanations", title: " Result Explanations", component: <Drawers.Result_Explanations {...props} />, width: "40%" },
    // { key: "module_target", title: "Module Target", component: <Drawers.Module_Target {...props} />, width: "40%" },
    // { key: "azimuth", title: "Azimuth", component: <Drawers.Azimuth {...props} />, width: "40%" },
    // { key: "import_topo", title: "Import USGS Topography", component: <Drawers.Import_Topo {...props} />, width: "40%" },
    // { key: "slope_analysis", title: "Slope Analysis Layers", component: <Drawers.Slope_Analysis {...props} />, width: "40%" },
    // { key: "topo_actions", title: "Topography Actions", component: <Drawers.Topo_Actions {...props} />, width: "40%" },
    // { key: "rack_align", title: "Racking Align", component: <Drawers.Rack_Align {...props} />, width: "40%" },
    // { key: "ew_roads", title: "East-West Roads", component: <Drawers.EW_Roads {...props} />, width: "40%" },
    // { key: "rbi_intro", title: "RBI Portal Introduction", component: <Drawers.RBI_Intro {...props} />, width: "40%" },
    // { key: "ground_mount_features", title: "Ground Mount Features", component: <Drawers.Ground_Mount_Features {...props} />, width: "40%" },
    // { key: "bug_report", title: "Bug Report", component: <Drawers.Bug_Report {...props} />, width: "40%" },
    // { key: "map_layers", title: "Map Controls", component: <Drawers.Map_Layers {...props} />, width: "40%" },
  ];
  return content.find((c) => c.key == drawer);
}

const DrawerExt = (props) => {
  let content = getContent(props, props.drawer);
  let deep_content = getContent(props, props.current_deep_drawer);
  if (!content) return <div></div>;
  return (
    <div>
      <Drawer
        zIndex={100000}
        title={content.title}
        // placement="right"
        width={content.width}
        closable={false}
        onClose={props.onCloseDrawer}
        visible={props.visible}
        bodyStyle={{ overflowY: "auto" }}
      >
        {content.component}

        {deep_content && (
          <Drawer
            zIndex={100001}
            title={deep_content.title}
            width={deep_content.width}
            closable={false}
            onClose={props.onCloseDeepDrawer}
            visible={props.deep_drawer_visible}
            bodyStyle={{ overflowY: "auto" }}
            // drawer={props.current_deep_drawer}
            // onCloseDrawer={props.onCloseDeepDrawer}
          >
            {deep_content.component}
          </Drawer>
        )}

        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e8e8e8",
            padding: "10px 16px",
            textAlign: "right",
            left: 0,
            background: "#fff",
            borderRadius: "0 0 4px 4px",
          }}
        >
          {/* Footer */}
        </div>
      </Drawer>
    </div>
  );
};

export { DrawerExt };
