import styled from "styled-components";

export const PerformanceInputWrapper = styled.section`
  width: 100%;
  padding: 10px;

  .inputs-panel {
    display: grid;
    grid-template-rows: repeat(19, auto);
  }

  .input-one-col {
    display: grid;
    width: 100%;
    height: 20px;
    grid-template-columns: 1fr;
    place-items: center;
    align-items: center;
    /* padding: 0 5px; */
    margin: 4px auto 10px auto;

    span {
      font-size: 11px;
    }
  }
  .input-one-col.coord {
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;
    place-items: normal;
  }
  .input-one-col.indent {
    margin-left: 10px;
  }

  .input-two-col {
    display: grid;
    grid-template-columns: 1fr 120px;
    /* padding: 0 5px; */
    margin-top: 8px;
    align-content: center;

    label {
      grid-column: 1;
      background-color: #fff;
    }
    Button {
      grid-column: 2;
      margin: auto 0px auto auto;
    }
    Input {
      grid-column: 2;
    }
  }

  .input-do-roads {
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 0 5px;
    margin-top: 8px;
    align-content: center;
  }

  .input-three-col {
    display: grid;
    grid-template-columns: 50% 12% 38%;
    padding: 0 5px;
    margin-top: 8px;

    label {
      grid-column: 1;
      background-color: #fff;
      align-self: center;
    }
    Button {
      grid-column: 2;
      margin: 0px 5px 0px auto;
      align-self: center;
    }
    Input {
      grid-column: 3;
    }
  }

  .racking-input {
    padding: 0 5px;
    margin-top: 8px;
    display: grid;
    grid-template-columns: 1fr;

    .radio-buttons {
      grid-column: 1;
      display: grid;
      place-items: center;
    }
  }

  .text-block {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: 1fr;
    place-items: center;
    align-items: center;
    padding: 0 5px;

    .ant-select-selector {
      width: 290px;
    }
  }

	.weather-divider {
		margin: 8px -15px !important;
		font-size: 10pt;
		pointer-events: none;

		.ant-divider-inner-text {
			padding-left: 0px;
			cursor: pointer;
		}
		.ant-divider-inner-text:hover {
			color: var(--primary-brand-color);
		}
	}
`;