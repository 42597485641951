import config from "config";
import { authHeader } from "../helpers";

export const carportServices = {
  _init,
  _getResults,
	canopy_wire_diagram,
};

// used to initialize a run
async function _init(inputs) {
  let response = await fetch(`${config.apiUrl}/dash/swm/canopy`, {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      ...inputs,
    }),
  });

  let responseJson = await response.json();
  return responseJson;
}

async function _getResults(id) {
  // console.log('_init ID', id);
  let response = await fetch(`${config.apiUrl}/dash/swm/?runId=${id}`, {
    // mode: 'no-cors',
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  });

  let responseJson = await response.json();
  // console.log("response", responseJson);
  return responseJson;
}
async function canopy_wire_diagram(inputs) {
  // console.log('_init ID', id);
  let response = await fetch(`${config.apiUrl}/dash/swm/canopy_wire_diagram`, {
    // mode: 'no-cors',
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      ...inputs
    }),		
  });

  let responseJson = await response.json();
  // console.log("response", responseJson);
  return responseJson;
}


function handleResponse(response) {
  return response.text().then((text) => {
    // console.log(text)
    let data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
