import { inputsConstants } from "../constants";
import { portal } from "../reducers/portal.reducer";
import { portalServices } from "../services";

export const inputsActions = {
  select_module,
  select_product,

  update_ui_state,
  update_bulk_ui_state,

  update_input,
  update_bulk_inputs,

  // map inputs
  update_site_input,

  // bulk input actions
  reset_inputs_to_default,
  clear_topo_data,
  submit_salesforce_capture,
  reset_sf_capture,
  toggle_sf_modal,
};

function select_module(index) {
  return {
    type: inputsConstants.SELECT_MODULE_BY_INDEX,
    index,
  };
}
function select_product(index) {
  return {
    type: inputsConstants.SELECT_PRODUCT_BY_INDEX,
    index,
  };
}

function update_ui_state(key, value) {
  return {
    type: inputsConstants.UPDATE_UI_STATE,
    key,
    value,
  };
}
function update_bulk_ui_state(bulk_inputs) {
  return {
    type: inputsConstants.UPDATE_BULK_UI_STATE,
    bulk_inputs,
  };
}
function update_input(key, value) {
  return {
    type: inputsConstants.UPDATE_INPUT_BY_NAME,
    key,
    value,
  };
}
function update_bulk_inputs(bulk_inputs) {
  return {
    type: inputsConstants.UPDATE_BULK_INPUTS,
    bulk_inputs,
  };
}

function update_site_input(key, value) {
  return {
    type: inputsConstants.UPDATE_SITE_INPUT,
    key,
    value,
  };
}

function reset_inputs_to_default() {
  return { type: inputsConstants.RESET_INPUTS_TO_DEFAULT };
}

function clear_topo_data(which_data) {
  return {
    type: inputsConstants.CLEAR_TOPO_DATA,
    which_data,
  };
}

function toggle_sf_modal(key, value, download_type = undefined) {
  return { type: inputsConstants.TOGGLE_SF_MODAL, key, value, download_type };
}

function submit_salesforce_capture(download_type, url, project_id, bypass = false) {
  return (dispatch) => {
    if (!bypass) {
      dispatch(request());
      portalServices.log_downlaod(download_type, url, project_id).then(
        (response) => {
          // console.log("reponse", response);
          dispatch(success());
        },
        (error) => {
          dispatch(failure());
        }
      );
    } else {
      dispatch(success());
    }
  };

  function request() {
    return { type: inputsConstants.SF_CAPTURE_REQUEST };
  }

  function success() {
    return { type: inputsConstants.SF_CAPTURE_SUCCESS };
  }

  function failure() {
    return { type: inputsConstants.SF_CAPTURE_RESET };
  }
}

function reset_sf_capture() {
  return { type: inputsConstants.SF_CAPTURE_RESET };
}
