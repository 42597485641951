// creates the final dimensions
// export function fixProductDimensions(product, module, dimension) {
export function fixProductDimensions(canopy) {
  return {
    x: canopy.module_dimensions.x * canopy.dimensions.modX + (canopy.dimensions.modX - 1) * canopy.modXGap + canopy.edgeOffset * 2,
    y: canopy.module_dimensions.y * canopy.dimensions.modY + (canopy.dimensions.modY - 1) * canopy.modYGap + canopy.edgeOffset * 2,
    modX: canopy.dimensions.modX,
    modY: canopy.dimensions.modY,
  };

  // ** modified below. trying to ge the 2 to 1 ratio thing sorted out ***
  // if (canopy.orientation == 1) {
  //   return {
  //     x: canopy.module_dimensions.x * canopy.dimensions.modX + (canopy.dimensions.modX - 1) * canopy.modXGap + canopy.edgeOffset * 2,
  //     y: canopy.module_dimensions.y * canopy.dimensions.modY + (canopy.dimensions.modY - 1) * canopy.modYGap + canopy.edgeOffset * 2,
  //     modX: Math.floor(canopy.dimensions.modX * 2),
  //     modY: Math.floor(canopy.dimensions.modY / 2),
  //   };
  // } else {
  //   return {
  //     y: (canopy.module_dimensions.x * canopy.dimensions.modX) / 2 + (canopy.dimensions.modX - 1) * canopy.modXGap + canopy.edgeOffset * 2,
  //     x: canopy.module_dimensions.y * canopy.dimensions.modY * 2 + (canopy.dimensions.modY - 1) * canopy.modYGap + canopy.edgeOffset * 2,
  //     modX: Math.floor(canopy.dimensions.modX / 2),
  //     modY: Math.floor(canopy.dimensions.modY * 2),
  //   };
  // }

  // let mod_x = 0,
  //   mod_y = 0;
  // if (product.orientation == 1) {
  //   mod_x = module.mod_width;
  //   mod_y = module.mod_height;
  // } else {
  //   // landscape
  //   mod_x = module.mod_height ;
  //   mod_y = module.mod_width ;
  // }

  // let gap_x = product.modXGap;
  // let gap_y = product.modYGap;

  // let final_dimension = {
  //   x: mod_x * dimension.modX + (dimension.modX - 1) * gap_x + product.edgeOffset * 2,
  //   y: mod_y * dimension.modY + (dimension.modY - 1) * gap_y + product.edgeOffset * 2,
  //   modX: dimension.modX,
  //   modY: dimension.modY,
  // };
}

export function calculateModuleDimensions(canopy) {
  // if (canopy.orientation == 1) {
  //   return {
  //     x: canopy.dimensions.x,
  //     y: canopy.dimensions.y,
  //     modX: Math.max(
  //       Math.floor((canopy.dimensions.x - canopy.edgeOffset * 2 - canopy.module_dimensions.x) / (canopy.modXGap + canopy.module_dimensions.x)) + 1,
  //       canopy.base_dimension.modX
  //     ),
  //     modY: Math.floor(
  //       ((canopy.dimensions.y - canopy.edgeOffset * 2 - canopy.module_dimensions.y) / (canopy.modYGap + canopy.module_dimensions.y) + 1) / 2
  //     ),
  //     // modY: Math.max(
  //     //   Math.floor((canopy.dimensions.y - canopy.edgeOffset * 2 - canopy.module_dimensions.y) / (canopy.modYGap + canopy.module_dimensions.y) + 1) /
  //     //     2,
  //     //   canopy.base_dimension.modY
  //     // ),
  //   };
  // } else {
  //   return {
  //     x: canopy.dimensions.x,
  //     y: canopy.dimensions.y,
  //     modX: Math.max(
  //       Math.floor((canopy.dimensions.x - canopy.edgeOffset * 2 - canopy.module_dimensions.x) / (canopy.modXGap + canopy.module_dimensions.x)) + 1,
  //       canopy.base_dimension.modX
  //     ),
  //     // modY: Math.floor(
  //     //   ((canopy.dimensions.y - canopy.edgeOffset * 2 - canopy.module_dimensions.y) / (canopy.modYGap + canopy.module_dimensions.y) + 1) * 2
  //     // ),
  //     modY: Math.max(
  //       Math.floor((canopy.dimensions.y - canopy.edgeOffset * 2 - canopy.module_dimensions.y) / (canopy.modYGap + canopy.module_dimensions.y) + 1) *
  //         2,
  //       canopy.base_dimension.modY
  //     ),
  //   };
  // }
  return {
    x: canopy.dimensions.x,
    y: canopy.dimensions.y,
    modX: Math.max(
      Math.floor((canopy.dimensions.x - canopy.edgeOffset * 2 - canopy.module_dimensions.x) / (canopy.modXGap + canopy.module_dimensions.x)) + 1,
      canopy.base_dimension.modX
    ),
    // IF orientation==2 (canopy has been swapped to landscape) then simply double the modY.. 
    // ELSE (canopy has been swapped to portrait) then divide by 2 and round down(floor)
    modY: canopy.orientation == 2 ? canopy.dimensions.modY*2 : Math.floor(canopy.dimensions.modY / 2),
    // modY: Math.max(
    //   Math.floor((canopy.dimensions.y - canopy.edgeOffset * 2 - canopy.module_dimensions.y) / (canopy.modYGap + canopy.module_dimensions.y)) + 1,
    //   canopy.base_dimension.modY
    // ),
  };
}

// gets modx/mody
export function getModuleDimsByOrientation(orientation, module) {

  // return {
  //   x: module.mod_width,
  //   y: module.mod_height,
  // };

  if (orientation == 1) {
    // 1 = portrait (default), 2 = landscape
    return {
      x: module.mod_width,
      y: module.mod_height,
      mod_width: module.mod_width,
      mod_height: module.mod_height,      
    };
  } else {
    // landscape
    return {
      x: module.mod_height,
      y: module.mod_width,
      mod_width: module.mod_width,
      mod_height: module.mod_height,            
    };
  }
}

function getPoint (dist, origin, bearing) {
	let lat1 = (origin.lat * Math.PI) / 180;
	let lon1 = (origin.lng * Math.PI) / 180;
	let distRadians = dist / 6372797.6; // earth radius in meters
	let bearingRad = (bearing * Math.PI) / 180;

	let lat2 = Math.asin(Math.sin(lat1) * Math.cos(distRadians) + Math.cos(lat1) * Math.sin(distRadians) * Math.cos(bearingRad));

	let lon2 = lon1 + Math.atan2(Math.sin(bearingRad) * Math.sin(distRadians) * Math.cos(lat1), Math.cos(distRadians) - Math.sin(lat1) * Math.sin(lat2));

	lat2 = lat2 * (180 / Math.PI);
	lon2 = lon2 * (180 / Math.PI);

	return { lng: lon2, lat: lat2 };
};
export function getRectFromOrigin(origin, dimension, rotation = 0) {
	//  origin in param
	let bottomRight = getPoint(dimension.x, origin, 90 + rotation);
	let topLeft = getPoint(dimension.y, origin, 0 + rotation);
	let topRight = getPoint(dimension.y, bottomRight, 0 + rotation);

	let latLngs = [
		[origin.lng, origin.lat],
		[topLeft.lng, topLeft.lat],
		[topRight.lng, topRight.lat],
		[bottomRight.lng, bottomRight.lat],
		[origin.lng, origin.lat],
	];

	return latLngs;
};