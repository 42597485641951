/*
	EditGeoJsonLayer.js
	 Functional component that renders nothing,
	 When a visual layer is selected, the leaflet layer
	 as well as the base geoJson is saved in the selected object
	 in the store, this way this component is the only one that
	 renders when selected{} is updated

	 We store the previous selected{}
	 As well as the previous created edit leaflet Layer

	 When a vis layer is selected:
	 	1- if there is a previous selected, reset its style and remove the created edit layer
		2- create a new editable geoJson layer with the path.transform enabled
		3- add editable geoJson to map and save in the ref
*/

import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { carportActions, usePrevious, arraysMatch } from "../../Redux";

import "./Path.Transform";
import * as turf from "@turf/turf";

function ModuleComponent({ geoJson }) {
  const dispatch = useDispatch();

  return <></>;
}

function NonRectangularLayer({ mapRef }) {
  const dispatch = useDispatch();
  // selected = layerRef and geoJsonRef
  // -- layerRef is the leaflet layer that was clicked from the visual layer
  // -- geoJsonRef is the base geoJson shape of the layer that was clicked
  // -- idRef is the ID of the canopy object selected
  const _selected = useSelector((state) => state.carport.nonRectSelected);
  const _selectedId = useSelector((state) => state.carport.nonRectSelectedId);
  // useRef naturally does NOT cause a re-render when you set it
  // this is important because this only re-renders when selected{}
  // is changed, but if we useState, then in the useEffect to handle
  // selected{} being updated, it will cause a second re-render when
  // we setState.
  const prevEditLayers = useRef(undefined);
  // save the previous _selected
  const prevSelected = usePrevious(_selected);
  const prevSelectedId = usePrevious(_selectedId);

  useEffect(() => {
    if (_selected && mapRef) {
      if (prevSelected) {
        stopEditing(prevEditLayers.current);
      }

      startEditing(_selected);
    } else if (_selected === undefined && prevSelected !== undefined) {
      stopEditing(prevEditLayers.current);
    }
  }, [_selected]);

  function stopEditing(moduleLayers) {
    let geoJsons = [];
    Object.values(moduleLayers).map((layer) => {
      layer.off("onclick");
      let _lyrJson = layer.toGeoJSON();
      if (!_lyrJson.properties.enabled) {
        geoJsons.push(_lyrJson.properties.indexes);
      }
      mapRef.removeLayer(layer);
    });
    mapRef.off("boxDragEnd");
    dispatch(carportActions.updateCanopyById(prevSelectedId, geoJsons, "nonRectEdit"));
  }

  function startEditing(selected) {
    let moduleLayers = [];
    Object.values(selected).map((geoJson, index) => {
      let module = L.geoJSON(geoJson, {
        transform: false,
        draggable: false,
        scaling: false,
      });

      module.addTo(mapRef);

      let moduleIndex = module._leaflet_id - 1;
      let moduleLayer = module._layers[moduleIndex];
      moduleLayer.bringToFront();
      moduleLayers.push(moduleLayer);
      moduleLayer.setStyle({
        color: "#333",
        weight: 1,
        fillColor: geoJson.properties.enabled ? "#60de4f" : "#fc2003",
        fillOpacity: "0.8",
      });

      moduleLayer.on("click", (e) => {
        if (e.originalEvent) e.originalEvent.view.L.DomEvent.stopPropagation(e);
        if (e.nativeEvent) e.nativeEvent.view.L.DomEvent.stopPropagation(e);

        moduleLayer.feature.properties.enabled = !moduleLayer.feature.properties.enabled;

        moduleLayer.setStyle({
          fillColor: e.target.feature.properties.enabled ? "#60de4f" : "#fc2003",
        });
      });
    });

    mapRef.on("boxDragEnd", (e) => {
      // console.log('boxDragEnd', e);
      let box_bounds = e.boxZoomBounds;
      let bbox = [box_bounds._southWest.lng, box_bounds._southWest.lat, box_bounds._northEast.lng, box_bounds._northEast.lat];
      let zoom_box_poly = turf.bboxPolygon(bbox);
      Object.values(moduleLayers).map((layer) => {
        let layerGeoJson = layer.toGeoJSON();
        let centroid = turf.buffer(turf.centroid(layerGeoJson), 0.25, { units: "meters" });
        if (turf.booleanContains(zoom_box_poly, centroid)) {
          layer.feature.properties.enabled = !layer.feature.properties.enabled;
          layer.setStyle({
            fillColor: layer.feature.properties.enabled ? "#60de4f" : "#fc2003",
          });
        }
      });
    });

    prevEditLayers.current = moduleLayers;
  }

  return <></>;
}

export { NonRectangularLayer };
