import { carportConstants, portalConstants } from "../constants";
import { carportServices } from "../services";
import { portalServices, userServices } from "../services";

export const carportActions = {
  selectLayer,
  updateCanopyById,
  mapClicked,
  changeTileSet,

  // selected canopy actions
  duplicateCanopy,
  updateInputByName,

  applyGlobalOverride,
  toggleShowCanopyNames,
  toggleDraggingSelected,

  collectImages,

  selectRectangle,
  toggleNonRectangle,
  createRectangle,
  updateRectangle,
  duplicateRectangle,
  copyRectangle,
  deleteRectangle,
  deleteAllRectangles,
  deleteAllRectanglesFinalize,

  updateCarportInput,
  updateModule,
  prodModSelect,
  setActiveTool,
  changeMapCoords,
  updateRectangleName,
  saveRectangleName,
  // generateCanopyResults,
  // setResults,
  createCanopy,
  updateCanopy,
  setNewProjectLoader,
  startNewProject,
  selectGlobalOverride,
  setGlobalOverrideInputs,
  setZoom,
  initResults,
  cancelRun,
  generateResults,
  // Project manager - this needs its own action/constant/reducer/service so it can be used by both carport and groundmount
  saveProject,
  newProject,
  toggleProjectManagerModal,
  selectProjectById,
  loadProjectById,
  importImage,
  selectImage,
  setActiveImageEditTool,
  updateImage,
  deleteImage,
  getCounty,
  setUIState,

  prepareReport,
  importData,

  updateNonPolyData,
  toggleNonPolyEdit,

	generateWireDiagram,
	save_project_inputs
};

function selectLayer(layer, id) {
  return {
    type: carportConstants.SELECT_CANOPY_LAYER,
    layer,
    id,
  };
}
function toggleNonRectangle() {
  return {
    type: carportConstants.TOGGLE_NON_RECT_EDIT,
  };
}
function updateCanopyById(id, geoJson, eventType) {
  return {
    type: carportConstants.UPDATE_CANOPY_BY_ID,
    id,
    geoJson,
    eventType,
  };
}
function mapClicked(mapRef, origin) {
  return {
    type: carportConstants.MAP_CLICKED,
    mapRef,
    origin,
  };
}

function duplicateCanopy(direction) {
  return {
    type: carportConstants.DUPLICATE_CANOPY,
    direction,
  };
}

function updateInputByName(name, value) {
  return {
    type: carportConstants.UPDATE_CANOPY_BY_NAME,
    name,
    value,
  };
}

function applyGlobalOverride(g_var, g_value) {
  return {
    type: carportConstants.APPLY_GLOBAL_OVERRIDE,
    g_var,
    g_value,
  };
}

function toggleShowCanopyNames() {
  return {
    type: carportConstants.TOGGLE_CANOPY_NAMES,
  };
}
function toggleDraggingSelected() {
  return {
    type: carportConstants.TOGGLE_DRAG_SELECTED,
  };
}

function collectImages(keys) {
  return {
    type: carportConstants.COLLECT_IMAGES,
    keys,
  };
}

function changeTileSet(tileSet) {
  return {
    type: carportConstants.CHANGE_TILESET,
    tileSet,
  };
}

function selectRectangle(id) {
  return {
    type: carportConstants.STORE_CURRENT_RECTANGLE,
    id,
  };
}

function createRectangle(geoJson, options) {
  return {
    type: carportConstants.CREATE_RECTANGLE,
    geoJson,
    options,
  };
}

function updateRectangle(geoJson, options) {
  return {
    type: carportConstants.UPDATE_RECTANGLE,
    geoJson,
    options,
  };
}

function duplicateRectangle(geoJson, id, prevRect) {
  return {
    type: carportConstants.DUPLICATE_RECTANGLE,
    geoJson,
    id,
    prevRect,
  };
}

function copyRectangle(id) {
  return {
    type: carportConstants.COPY_RECTANGLE,
    id,
  };
}

function deleteRectangle(id) {
  return {
    type: carportConstants.DELETE_RECTANGLE,
    id,
  };
}

function deleteAllRectangles(projectToLoad = undefined) {
  return {
    type: carportConstants.DELETE_ALL_RECTANGLES,
    projectToLoad,
  };
}
function deleteAllRectanglesFinalize() {
  return {
    type: carportConstants.DELETE_ALL_RECTANGLES_FINAL,
  };
}

// updates the UI input but does not save the name
function updateRectangleName(name) {
  return {
    type: carportConstants.UPDATE_RECTANGLE_NAME,
    name,
  };
}
// saves the name and updates the store
function saveRectangleName(name) {
  return {
    type: carportConstants.SAVE_RECTANGLE_NAME,
    name,
  };
}

function updateCarportInput(key, value = undefined) {
  return {
    type: carportConstants.INPUT_UPDATE,
    key,
    value,
  };
}
function updateModule(key, value) {
  return {
    type: carportConstants.UPDATE_MODULE,
    key,
    value,
  };
}

function prodModSelect(key, value = undefined) {
  return {
    type: carportConstants.PROD_MOD_SELECT,
    key,
    value,
  };
}

function setActiveTool(tool) {
  return {
    type: carportConstants.SET_ACTIVE_TOOL,
    tool,
  };
}

function changeMapCoords(coords) {
  return {
    type: carportConstants.CHANGE_MAP_COORDS,
    coords,
  };
}

// function generateCanopyResults() {
//   return {
//     type: carportConstants.GENERATE_CANOPY_RESULTS,
//   };
// }

// function setResults() {
//   return {
//     type: carportConstants.SET_RESULTS,
//   };
// }

function createCanopy(canopy) {
  return {
    type: carportConstants.CREATE_CANOPY,
    canopy,
  };
}

function updateCanopy(prevId, canopy) {
  return {
    type: carportConstants.UPDATE_CANOPY,
    prevId,
    canopy,
  };
}

function setNewProjectLoader(loading) {
  return {
    type: carportConstants.SET_NEW_PROJECT_LOADER,
    loading,
  };
}

function startNewProject() {
  return {
    type: carportConstants.START_NEW_PROJECT,
  };
}

function selectGlobalOverride(boolValue) {
  return {
    type: carportConstants.SELECT_GLOBAL_OVERRIDE,
    boolValue,
  };
}

function setGlobalOverrideInputs(key, value = undefined) {
  return {
    type: carportConstants.SET_GLOBAL_OVERRIDES_INPUT,
    key,
    value,
  };
}

function setZoom(value) {
  return {
    type: carportConstants.SET_ZOOM,
    value,
  };
}

function initResults(inputs) {
  return (dispatch) => {
    dispatch(request());
    carportServices._init(inputs).then((response) => {
      if (response.run_id) {
        dispatch(success(response));
        dispatch(carportActions.generateResults(response.run_id, 10));
      } else {
        dispatch(failure("Unable to contact Weather API."));
      }
    });
  };

  function request() {
    return { type: carportConstants.INIT_RESULTS_REQUEST };
  }
  function success(data) {
    return { type: carportConstants.INIT_RESULTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: carportConstants.INIT_RESULTS_ERROR, error };
  }
}

function cancelRun(id) {
  return (dispatch) => {
    dispatch(request());
    portalServices._cancelRun(id);
  };
  function request() {
    return { type: carportConstants.CANCEL_RUN_REQUEST };
  }
}

function generateResults(run_id, delay) {
  return (dispatch) => {
    dispatch(request());
    carportServices._getResults(run_id).then((response) => {
      if (response.status == 100) {
        // COMPLETE
        dispatch(success(response));
      } else if (response.status == 50) {
        // CANCEL
        dispatch(failure("cancel"));
      } else if (response.status == 97) {
        // ERROR
        dispatch(failure("error"));
      } else {
        dispatch(update(response.complete));
        setTimeout(dispatch(carportActions.generateResults(run_id, Math.pow(delay, 2))), delay);
      }
    });
  };

  function request() {
    return { type: carportConstants.GENERATE_RESULTS_REQUEST };
  }
  function update(complete) {
    return { type: carportConstants.GENERATE_RESULTS_UPDATE, complete };
  }
  function success(data) {
    return { type: carportConstants.GENERATE_RESULTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: carportConstants.GENERATE_RESULTS_FAILURE, error };
  }
}

function save_project_inputs (inputs) {
	return (dispatch) => {
    dispatch(request(inputs));
		portalServices.save_project_inputs(inputs)
			.then(save_response => {
				dispatch(success(inputs, save_response));
				// dispatch(update(save_response.project_id));
				dispatch(complete(save_response))
			})
	}
  function request(current_project) {
    return { type: carportConstants.SAVE_PROJECT_REQUEST, current_project };
  }
  function success(inputs, response) {
    return { type: carportConstants.SAVE_PROJECT_SUCCESS, inputs, response };
  }	
	function complete(response) {
    return { type: carportConstants.SAVE_PROJECT_COMPLETE, response };
  }
	// function update(project_id) {
	// 	return {
	// 		type: inputsConstants.UPDATE_INPUT_BY_NAME,
	// 		key: 'project_id', value: project_id
	// 	}
	// }
}

function saveProject(project) {
  return (dispatch) => {
    dispatch(request(project));
    portalServices.saveProject(project).then(
      (response) => {
        dispatch(success(response.project_id, response.editDate, project));

        portalServices.getProjects().then(
          (response) => dispatch(complete(response)),
          (error) => dispatch(failure(error.toString()))
        );
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request(project) {
    return { type: carportConstants.SAVE_PROJECT_REQUEST, project };
  }
  function success(id, date, project) {
    return { type: carportConstants.SAVE_PROJECT_SUCCESS, id, date, project };
  }
  function complete(response) {
    return { type: carportConstants.SAVE_PROJECT_COMPLETE, response };
  }
  function failure() {
    return { type: carportConstants.SAVE_PROJECT_FAILURE };
  }
}

function newProject() {
  return (dispatch) => {
    dispatch(clearMap());
    dispatch(request());
    setTimeout(() => dispatch(complete()), 300);
  };
  function clearMap() {
    return { type: carportConstants.DELETE_ALL_RECTANGLES };
  }
  function request() {
    return { type: carportConstants.NEW_PROJECT_REQUEST };
  }
  function complete() {
    return { type: carportConstants.NEW_PROJECT_COMPLETE };
  }
}

function toggleProjectManagerModal(bool) {
  return {
    type: carportConstants.TOGGLE_PROJECTMANAGER_MODAL,
    bool,
  };
}
function selectProjectById(id) {
  return {
    type: carportConstants.SELECT_PROJECT,
    id,
  };
}
function loadProjectById(id) {
  return (dispatch) => {
    dispatch(request(id));
    setTimeout(() => dispatch(complete()), 300);
  };
  function request() {
    return { type: carportConstants.LOAD_PROJECT_REQUEST, id };
  }
  function complete() {
    return { type: carportConstants.LOAD_PROJECT_COMPLETE };
  }
}

function importImage(imageId, imageFile) {
  return {
    type: carportConstants.IMPORT_IMAGE,
    imageId,
    imageFile,
  };
}

function selectImage(imageId) {
  return {
    type: carportConstants.SELECT_IMAGE,
    imageId,
  };
}

function setActiveImageEditTool(tool) {
  return {
    type: carportConstants.SET_IMAGE_EDIT_TOOL,
    tool,
  };
}

function updateImage(imageId, image) {
  return {
    type: carportConstants.UPDATE_IMAGE,
    imageId,
    image,
  };
}

function deleteImage(imageId) {
  return {
    type: carportConstants.DELETE_IMAGE,
    imageId,
  };
}

function getCounty(coord1, coord2) {
  return (dispatch) => {
    dispatch(update([coord1, coord2]));
    portalServices.getCounty(coord1, coord2).then((response) => {
      let results = response.results[0];
      if (results) {
        dispatch(request("county", results.county_name));
        dispatch(request("stateAbb", results.state_code));
      }
    });
  };

  function update(center) {
    return { type: carportConstants.UPDATE_CENTER, center };
  }
  function request(key, value) {
    return {
      type: carportConstants.SET_COUNTY,
      key,
      value,
    };
  }
}

function setUIState(input, value) {
  return {
    type: carportConstants.SET_UI_STATE,
    input,
    value,
  };
}

function prepareReport(prepare, url = undefined) {
  return {
    type: carportConstants.PREPARE_REPORT,
    prepare,
    url,
  };
}

function importData(data) {
  return (dispatch) => {
    dispatch(request(data));

    userServices.importData(data).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request(data) {
    return { type: portalConstants.IMPORTDATA_REQUEST, data };
  }
  function success(response) {
    return { type: portalConstants.IMPORTDATA_SUCCESS, response };
  }
  function failure(error) {
    return { type: portalConstants.IMPORTDATA_FAILURE, error };
  }
}

function updateNonPolyData(cells, blocks) {
  return { type: carportConstants.UPDATE_NON_POLY_DATA, cells, blocks };
}

function toggleNonPolyEdit() {
  return { type: carportConstants.TOGGLE_NON_POLY_EDIT };
}

function generateWireDiagram(id, input) {
  return (dispatch) => {
    dispatch(request());

    carportServices.canopy_wire_diagram(input).then(
      (response) => dispatch(success(id, response)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: carportConstants.CANOPY_WIRE_REQUEST };
  }
  function success(id, response) {
    return { type: carportConstants.CANOPY_WIRE_SUCCESS, id, response };
  }
  function failure(error) {
    return { type: carportConstants.CANOPY_WIRE_FAILURE, error };
  }
}