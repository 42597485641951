import React, { useState, useEffect } from "react";
import sunfig_header_logo from "../PDFReport/sunfig_header_logo.png";

import { ReportPageHeaderWrap } from "./styles";

// assets
import { rbilogo, RBI_TS_Logo, ts_logo_hori } from "../../assets";

// Redux
import { useDispatch, useSelector } from "react-redux";

const ReportPageHeader = ({ page, location, user, date }) => {
  const portal = useSelector((state) => state.portal);

  return (
    <ReportPageHeaderWrap>
      <div className="report-header-image">
        <img src={ts_logo_hori} alt="RBI And Terrasmart comapny logo" />
      </div>
      <div className="report-header-content">
        <div className="report-header-row-1">
          <p>{`Project Name: ${portal.current_project.name ? portal.current_project.name : "Solar Project"}`}</p>
          <p>{`${date}`}</p>
        </div>
        <div className="report-header-row-2">
          <p>{`Location : ${location?.lat.toFixed(3)}°, ${location?.lng.toFixed(3)}°`}</p>
          <p>{`User: ${user}`}</p>
        </div>
      </div>
    </ReportPageHeaderWrap>
  );
};

export { ReportPageHeader };
