import React, { useEffect, useState } from "react";
import { carportActions } from "../Redux";
import { useSelector, useDispatch } from "react-redux";

import { CanopyResults } from "../Sunfig";

import { close } from "../../assets";
import { Progress, Spin, Button } from "antd";

function Results() {
  const dispatch = useDispatch();
  const carport = useSelector((state) => state.carport);
  const [showCanopyNameLabels, setShowCanopyNameLabels] = useState(false);

  useEffect(() => {
    if (carport.uiState.view_results_table === true) {
      setShowCanopyNameLabels(false);
    }
  }, [carport.uiState.view_results_table]);

  // console.log(carport.results, carport.uiState.view_results_table)
  return (
    <>
      {carport.generatingCanopyResults && (
        <section className="generating-box">
          {carport.generatingCanopyResults ? (
            <div className="initializing-box">
              <Spin />
              <p> Initializing... </p>
            </div>
          ) : (
            <div className="generating-box-contents">
              <Progress type="circle" percent={carport.percentComplete.toFixed(0)} width={65} strokeColor="#60de4f" strokeWidth={12} />
              <p>Generating...</p>
            </div>
          )}
        </section>
      )}
      {carport.results && carport.uiState.view_results_table && (
        <div className="results">
          <div className="results-box">
            <div className="results-close-button">
              <Button
                type="link"
                onClick={() => {
                  if (carport.uiState.show_thirty_percent_set) {
                    dispatch(carportActions.setUIState("show_thirty_percent_set", false));
                  } else if (carport.uiState.show_canopy_report) {
                    dispatch(carportActions.setUIState("show_canopy_report", false));
                  } else {
                    dispatch(carportActions.setUIState("view_results_table", false));
                  }
                }}
              >
                <img src={close} />
              </Button>
            </div>
            <div className="results-table">
              <CanopyResults setShowCanopyNameLabels={setShowCanopyNameLabels} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export { Results };
