import styled from "styled-components";

const SankeyLayout = styled.section`
  grid-area: sankey;
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
`;

export { SankeyLayout };
