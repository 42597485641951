import React, { useState, useEffect } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { portalActions, inputsActions } from '../../Redux';

import { LayoutInputWrapper } from "./style";
import { gft_on, gft_off, sat_on, sat_off } from "./images";

// ant design
// documentation here ====> https://ant.design/
import { Input, Switch, Radio, Divider, Tooltip } from 'antd';
import { DownOutlined, RightOutlined } from "@ant-design/icons";

const LayoutInputs = () => {
  const portal = useSelector((state) => state.portal);
  // const inputs = useSelector((state) => state.portal.inputs.layout);
  const inputs = useSelector((state) => state.inputs);
	// const rackType = useSelector((state) => state.inputs.track_mode);
	const [local_inputs, set_local_inputs] = useState(inputs)
  const dispatch = useDispatch();


	const [showDesLimits, setShowDesLimits] = useState(true);

	useEffect(() => {
		set_local_inputs(inputs)
	}, [inputs]);

	const updateInput = (key, value) => {
		set_local_inputs({
			...local_inputs,
			[key]: value
		})
		// do some input validation here
		let fixed_value = value
		// console.log(key, fixed_value)

		if (key == 'road_spacing' || key == 'strings_per_combiner' || key == 'sazm' || key == 'wiring_inv_rating') {
			if (_.isEmpty(value) || _.isUndefined(value) || _.isNaN(value)) {
				return
			}
			if (key == 'road_spacing' || key == 'sazm') {
				// Should parse the value as the type the input is (float / int / etc)
				fixed_value = parseFloat(value)
			} else {
				//  'strings_per_combiner' or 'wiring_inv_rating'
				fixed_value = parseInt(value)
			}
		}

		// dispatch(portalActions.updateWiring(key,fixed_value));
		dispatch(inputsActions.update_input(key,fixed_value));
	}

	// enabled
	// -inverter rating override
	// -max row length
	// -combiner box logic (centroid vs by roads)
	// -strings per combiner box
	// local_inputs

	// do_rack_align
	// sazm
	// do_roads
	// road_spacing
	// road_spacing_option

	// do_wiring
	// combiner_location
	// strings_per_combiner
	// wire_stringing

  return (
		<LayoutInputWrapper>
			{/* <div className="inputs-content-box"> */}
				<Divider className="weather-divider" orientation="left" onClick={() => setShowDesLimits(!showDesLimits)}>
          <span style={{ color: "#002bcb" }}>{showDesLimits ? <DownOutlined /> : <RightOutlined />}</span>
          <span>Rack Positioning</span>
        </Divider>
				<div className="layout-racking-input">
					<div className="radio-buttons">
						{inputs.track_mode == 0 ? (
							<span>
								<img style={{ marginRight: "40px" }} src={gft_on}></img>
								<img src={gft_off}></img>
							</span>
						) : (
							<span>
								<img style={{ marginRight: "40px" }} src={sat_on}></img>
								<img src={sat_off}></img>
							</span>
						)}
					</div>
				</div>

				<div className="layout-racking-input">
					<div className="radio-buttons">
						<Radio.Group
							name="do_rack_align"
							onChange={(event) => {
								updateInput(event.target.name, event.target.value);
							}}
							value={local_inputs.do_rack_align}
						>
							<Radio style={{ marginRight: "40px" }} value={1}>
								Aligned
							</Radio>
							<Radio value={0}>Unaligned</Radio>
						</Radio.Group>
					</div>
				</div>
				<div className="layout-input-two-col">
					<label>Azimuth</label>
					<Input
						id="sazm"
						type="number"
						onChange={(event) => {
							updateInput(event.target.id, event.target.value);
						}}
						value={local_inputs.sazm}
						size="small"
						// disabled={saving}
						suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>°</span>}
					/>
				</div>


				<Divider className="weather-divider" orientation="left" onClick={() => setShowDesLimits(!showDesLimits)}>
          <span style={{ color: "#002bcb" }}>{showDesLimits ? <DownOutlined /> : <RightOutlined />}</span>
          <span>Roads</span>
        </Divider>				
				{/* East-West Roads */}
				<div className="layout-input-do-roads">
					<label>Draw East-West Roads</label>
					{/* <span style={{ color: '#1cac50', textAlign: 'center' }}>Disabled</span> */}
						<Tooltip placement="right" mouseEnterDelay={0.5} title={
								local_inputs.track_mode == 1 && !local_inputs.do_rack_align ? 
									"Roads automatically disabled when Racking Align off for SAT" :
									null
								}>
							<Radio.Group
								style={{ marginLeft: "auto" }}
								value={local_inputs.track_mode == 1 && !local_inputs.do_rack_align ? 0 : local_inputs.road_spacing_option}
								disabled={local_inputs.track_mode == 1 && !local_inputs.do_rack_align}
								buttonStyle="solid"
								size="small"
								onChange={(event) => {
									if (event.target.value === 0) {
										updateInput("do_roads", 0);
									} else {
										updateInput("do_roads", 1);
									}
									updateInput(event.target.id, event.target.value);
								}}
								>
								<Radio.Button id="road_spacing_option" value={0}>
									Off
								</Radio.Button>
								<Radio.Button id="road_spacing_option" value={2}>
									Auto
								</Radio.Button>
								<Radio.Button id="road_spacing_option" value={1}>
									Manual
								</Radio.Button>
							</Radio.Group>
						</Tooltip>					
				</div>
				{/* value dependent on inputs */}
				<div className="layout-input-two-col">
					<label>Max Road Spacing</label>
					<Tooltip placement="right" mouseEnterDelay={0.5} title={
								local_inputs.track_mode == 1 && !local_inputs.do_rack_align ? 
									"Roads automatically disabled when Racking Align off for SAT" :
									null
								}>					
					<Input
						disabled={local_inputs.road_spacing_option == 2 || local_inputs.road_spacing_option == 0}
						size="small"
						id="road_spacing"
						type="number"
						min={1}
						max={500}
						value={local_inputs.road_spacing}
						onChange={(event) => {
							updateInput(event.target.id, event.target.value);
						}}
						suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
					/>
					</Tooltip>			
				</div>			

				<Divider className="weather-divider" orientation="left" onClick={() => setShowDesLimits(!showDesLimits)}>
          <span style={{ color: "#002bcb" }}>{showDesLimits ? <DownOutlined /> : <RightOutlined />}</span>
          <span>Inverters & Grouping</span>
        </Divider>
				<div className="layout-input-do-roads">
					<label>Inverter Groups</label>
					<Radio.Group
						style={{ marginLeft: "auto" }}
						value={local_inputs.inverter_grouping}
						// disabled={rackType == 2}
						buttonStyle="solid"
						size="small"
						onChange={(event) => {
							updateInput(event.target.id, event.target.value);
						}}
					>
						<Radio.Button id="inverter_grouping" value={0}>
							Off
						</Radio.Button>
						<Radio.Button id="inverter_grouping" value={1}>
							Fill
						</Radio.Button>
						{/* <Radio.Button id="inverter_grouping" value={2}>
							Integer
						</Radio.Button> */}
					</Radio.Group>
				</div>
				<div className="layout-input-two-col">
					<label>Inverter Rating:</label>
					<Input
						className="gm-input"
						size="small"
						disabled={local_inputs.inverter_grouping != 1}
						value={local_inputs.wiring_inv_rating}
						onChange={(e) => updateInput('wiring_inv_rating', e.target.value) }
						min="0"
						max="100"
						suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>kW</span>}
					/>
				</div>				

				<Divider className="weather-divider" orientation="left" onClick={() => setShowDesLimits(!showDesLimits)}>
          <span style={{ color: "#002bcb" }}>{showDesLimits ? <DownOutlined /> : <RightOutlined />}</span>
          <span>Wiring</span>
        </Divider>
				<div className="layout-input-two-col">
					<label>String Wiring</label>
					<Switch
						// style={{position: 'absolute'}}
						size='small'
						checked={local_inputs.do_wiring}
						onChange={(checked) => updateInput('do_wiring', checked ? 1 : 0) }
					/>
				</div>

				<div className="layout-input-two-col">			
					<label>Combiner Location</label>
					<Radio.Group
						// value={inputs.do_roads==0 || inputs.do_rack_align ==0 ? 0 : local_inputs.combiner_location}
						value={local_inputs.combiner_location}
						onChange={(e) => updateInput('combiner_location', e.target.value)}
						disabled={local_inputs.do_wiring==0}
						// style={{textAlign: 'center'}}
					>
						<Radio value={0}>Centroid</Radio>
						<Radio value={1} disabled={local_inputs.do_roads==0}>Road</Radio>
					</Radio.Group>	
				</div>
				<div className="layout-input-two-col">
					<label>Strings per Combiner:</label>
					<Input
						className="gm-input"
						size="small"
						value={local_inputs.strings_per_combiner}
						onChange={(e) => updateInput('strings_per_combiner', e.target.value) }
						min="0"
						max="100"
						suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>#</span>}
						disabled={local_inputs.do_wiring==0}
					/>
				</div>

				<div className="layout-input-two-col">			
					<label>Stringing</label>
					<Radio.Group
						value={local_inputs.wire_stringing}
						onChange={(e) => updateInput('wire_stringing', e.target.value)}
						disabled={local_inputs.do_wiring==0}
						// style={{textAlign: 'center'}}
					>
						<Radio value={0}>Standard</Radio>
						<Radio value={1} disabled>Skip</Radio>
					</Radio.Group>	
				</div>


				{/* 
				<div className="product-input">
					<label>Integer Inverter Groups:</label>
					<Switch
						checked={local_inputs.do_integer_inv_groups}
						onChange={(checked) => updateInput('do_integer_inv_groups', checked) }
					/>
				</div> */}

				{/* <div className="perf-input">
					<label>Inverter Rating:</label>
					<Input
						className="gm-input"
						size="small"
						type="number"
						value={local_inputs.wiring_inv_rating}
						onChange={(e) => updateInput('wiring_inv_rating', e.target.value) }
						min="0"
						max="100"
						suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>kW</span>}
					/>
				</div>		 */}
				{/* <div className="perf-input">
					<label>Modules Per String:</label>
					<Input
						className="gm-input"
						size="small"
						type="number"
						value={local_inputs.wiring_modules_per_string}
						onChange={(e) => updateInput('wiring_modules_per_string', e.target.value) }
						min="0"
						max="100"
						// suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}></span>}
					/>
				</div>		 */}

	{/* 
				<div className="perf-input">
					<label>DCAC:</label>
					<Input
						className="gm-input"
						size="small"
						type="number"
						value={local_inputs.wiring_dcac}
						onChange={(e) => updateInput('wiring_dcac', e.target.value) }
						min="0"
						max="100"
						// suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}></span>}
					/>
				</div>

				<div className="perf-input">
					<label>Calculated SPI</label>
					<label style={{ gridColumn: 2, margin: '0 auto' }}>{local_inputs.wiring_spi}</label>
				</div> */}

				{/* <div className="perf-input">
					<label>Max Row Length:</label>
					<Input
						className="gm-input"
						id="mismatch_loss"
						size="small"
						type="number"
						min="0"
						value={local_inputs.wiring_max_row_length}
						onChange={(e) => updateInput('wiring_max_row_length', e.target.value) }
						suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>#</span>}
					/>
				</div> */}







				{/* <div className="perf-input">
					<label>Module Quality:</label>
					<Input
						className="gm-input"
						size="small"
						type="number"
						value={portal.inputs.losses.module_quality_loss}
						onChange={(e) => {
							dispatch(
								portalActions.updateInput('setSoilingLosses', {
									key: 'module_quality_loss',
									payload: e.target.value,
								})
							);
						}}
						min="-100"
						max="100"
						suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
					/>
				</div>

				<div className="perf-input">
					<label>Structure Shade %:</label>
					<Input
						className="gm-input"
						size="small"
						type="number"
						value={portal.inputs.bifacial_structure_shade_factor}
						onChange={(e) => {
							dispatch(portalActions.updateInput('bifacial_structure_shade_factor', e.target.value));
						}}
						min="0"
						max="100"
						suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
					/>
				</div>

				<div className="perf-input">
					<label>Inverter Efficiency:</label>
					<Input
						className="gm-input"
						size="small"
						type="number"
						value={portal.inputs.losses.inverter_eff}
						onChange={(e) => {
							dispatch(
								portalActions.updateInput('setSoilingLosses', {
									key: 'inverter_eff',
									payload: e.target.value,
								})
							);
						}}
						min="0"
						max="100"
						suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
					/>
				</div>

				<div className="perf-input">
					<label>Combined AC Losses:</label>
					<Input
						className="gm-input"
						size="small"
						type="number"
						value={portal.inputs.losses.combined_ac_loss}
						onChange={(e) => {
							dispatch(
								portalActions.updateInput('setSoilingLosses', {
									key: 'combined_ac_loss',
									payload: e.target.value,
								})
							);
						}}
						min="0"
						max="100"
						suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
					/>
				</div>
				<div className="perf-input">
					<label>DC:AC:</label>
					<Input
						className="gm-input"
						size="small"
						type="number"
						step="0.01"
						value={portal.inputs.dcac}
						onChange={(e) => {
							dispatch(portalActions.updateInput('dcac', e.target.value));
						}}
						min="0.1"
						max="3.0"
						suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}> </span>}
					/>
				</div> */}

			{/* </div> */}
		</LayoutInputWrapper>
  );
};

export { LayoutInputs };
