import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Navigation from "./Navigation";
import { DrawerExt } from "./Drawer";
// import Header from './Header';
// import Sidebar from './Sidebar';
// import Footer from './Footer';

const PageLayout = styled.section`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 95px 1fr;
  grid-template-rows: 1fr;
  position: relative;

  .main-content {
    width: 100%;
    grid-column: 2;
    color: #333;
    font-family: Sans-Serif;
  }

  .drawer-container {
    grid-row: 1;
    grid-column: 1/-1;
    position: absolute;
  }
`;

const Base = ({ dispatch, role, children, page }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [drawerVisbile, setDrawerVisible] = useState(true);
  const [currentDrawer, setCurrentDrawer] = useState(-1);
  const [deepDrawerVisible, setDeepDrawerVisible] = useState(false);
  const [currentDeepDrawer, setCurrentDeepDrawer] = useState(-1);

  const showDeepDrawer = (drawer) => {
    setDeepDrawerVisible(true);
    setCurrentDeepDrawer(drawer);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
    setCurrentDrawer(-1);
  };

  const onCloseDeepDrawer = () => {
    setDeepDrawerVisible(false);
    setCurrentDeepDrawer(-1);
  };

  const handleOpenDrawer = () => {
    if (window.location.pathname == "/canopy") {
      showDrawer("canopy_root");
    } else {
      showDrawer("rbi_root");
    }
  };

  const showDrawer = (drawer) => {
    setDrawerVisible(true);
    setCurrentDrawer(drawer);
  };

  return (
    <PageLayout>
      {/* navigation */}
      <Navigation collapsed={collapsed} dispatch={dispatch} role={role} handleOpenDrawer={handleOpenDrawer} page={page} />
      {/* content */}
      <section className="main-content">{children}</section>
      {/* help drawer */}
      <div className="drawer-container">
        <DrawerExt
          visible={drawerVisbile}
          drawer={currentDrawer}
          deep_drawer_visible={deepDrawerVisible}
          current_deep_drawer={currentDeepDrawer}
          onOpenDrawer={showDeepDrawer}
          onCloseDrawer={onCloseDrawer}
          onCloseDeepDrawer={onCloseDeepDrawer}
        />
      </div>
    </PageLayout>
  );
};

export default Base;

{
  /* <Layout>
<section className="content-container">
  <Navigation collapsed={this.state.collapsed} dispatch={this.props.dispatch} role={this.props.role} />
  <Content className="content">{this.props.children}</Content>
</section>
</Layout> */
}

{
  /* <Layout className="rbi-with-sider">
          <Sider trigger={null} collapsible collapsed={this.state.collapsed} className="rbi-sider">
            <Navigation collapsed={this.state.collapsed} dispatch={this.props.dispatch} role={this.props.role} />
          </Sider>
          <div className="content-container">
            <Content className="content">{this.props.children}</Content>

            <Footer style={{ textAlign: 'center', backgroundColor: 'white' }}>
              <span>
                &copy; 2020 -{' '}
                <a href="https://www.sunfig.com" target="_blank" rel="noopener">
                  Sunfig
                </a>
              </span>
            </Footer>
          </div>
        </Layout> */
}
