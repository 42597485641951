import { Terratrak_2P_SAT, Standard_GFT } from "../helpers/gmCalculators";

const run_side_effects = (inputs, keys = undefined, trigger_update = false) => {
  let fixed_inputs = { ...inputs };
  let inputs_keys = keys || Object.keys(fixed_inputs);

  let run_dim_calc = ["mod_width", "mod_height", "mod_per_string", "do_dimension_override"];
  let trigger_dim_update = false;

  inputs_keys.map((key) => {
    if (run_dim_calc.findIndex((type) => type == key) >= 0) trigger_dim_update = true;
  });

  if (trigger_dim_update || trigger_update) {
    if (fixed_inputs.track_mode == 1) {
      fixed_inputs.racks.forEach((rack, index) => {
        let mod_count = fixed_inputs.mod_per_string * fixed_inputs.string_counts[index];
        let Tracker_XY = Terratrak_2P_SAT(fixed_inputs.mod_width, fixed_inputs.mod_height, mod_count, fixed_inputs.module_isbifacial == 1);
        rack.module = mod_count;
        rack.xdim = +Tracker_XY[0].toFixed(4);
        rack.ydim = +Tracker_XY[1].toFixed(4);
      });
    } else {
      // GFT
      fixed_inputs.racks.forEach((rack, index) => {
        let mod_count = rack.module;
        if (fixed_inputs.string_counts.length == 0) {
          // GLIDE products are LIMITED to 24 modules no matter what
          mod_count = 24;
        } else {
          mod_count = fixed_inputs.mod_per_string * fixed_inputs.string_counts[index];
        }
        let Rack_XY = Standard_GFT(fixed_inputs.mod_width, fixed_inputs.mod_height, mod_count, fixed_inputs.mods_high, fixed_inputs.orientation, fixed_inputs.vert_gap, fixed_inputs.hori_gap);
        rack.module = mod_count;
        rack.xdim = +Rack_XY[0].toFixed(4);
        rack.ydim = +Rack_XY[1].toFixed(4);
      });
    }
  }

  return fixed_inputs;
};

export { run_side_effects };
