import React, { Fragment } from 'react';
import { InputHeaderWrap } from '../styles';

import { useSelector, useDispatch } from 'react-redux';
import { portalActions, carportActions, inputsActions } from '../../Redux';

import { Select, Switch, Dropdown, Button, Tooltip, Input, Menu } from 'antd';

import { SaveOutlined, ImportOutlined, EllipsisOutlined } from '@ant-design/icons';

import { MenuOverlay } from './InputManager';
import { useEffect } from 'react/cjs/react.development';

const InputHeader = ({ mode, simple, tab }) => {
  const dispatch = useDispatch();

  const store = useSelector((state) => state[mode]);
  const portal = useSelector((state) => state.portal);
  const inputs = useSelector((state) => state.inputs);
  const dropDown = mode == 'carport' ? store.modules : tab == 'module' ? store.modules : tab == 'performance' ? store.performance : store.products;

  const canopies = mode == 'carport' ? store.rectangles : undefined;
  const selectedRectId = mode == 'carport' ? store.selectedRectId : undefined;

  const module = mode == 'carport' ? store.prodModSelection.selectedCarportModule : portal.inputs.selectedModule;
  const selectedModuleIndex = mode == 'carport' ? store.prodModSelection.selectedCarportModuleIndex : portal.inputs.selectedModuleIndex;

  // const tab = useSelector((state) => state.sift.ioManager.uiState.tab);
  // const inputs = useSelector((state) => state.sift.ioManager.inputs[tab]);

  // const account_loading = useSelector((state) => state.account.account_loading);
  // const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  // const saving = account_loading || input_loading;

  // const dropdown = useSelector((state) => state.sift.ioManager.dropdowns[tab]);
  // const selected = inputs && inputs.data;
  // const isAuthed = useSelector((state) => isAuthenticated(state));

  // const running = useSelector((state) => state.sift.ioManager.outputs.runState.running);
  // const doFinance = useSelector((state) => state.sift.ioManager.inputs.config.data.do_finance == 1);
  // const topo_live = useSelector((state) => state.sift.ioManager.inputs.topo.topo_live);
  // const rack_type = useSelector((state) => state.sift.ioManager.inputs.racking.data.type);
  // const current_plan = useSelector((state) => state.account.current_plan);
  // const can_import = current_plan > 1;
  // const screenWidth = useSelector((state) => state.sift.ioManager.uiState.screenWidth);

  function updateInput(key, value) {
    dispatch(portalActions.updateInput(key, value));
  }

  function onSaveInputPressed(key) {
    // this function is used by save, saveas, and delete buttons
    let input = JSON.parse(JSON.stringify(portal.inputs));
    // console.log(input)
    if (key == 'saveas') {
      // if (input.id == 0) {
      //   let tabName = sift.ioManager.uiState.tab.charAt(0).toUpperCase() + sift.ioManager.uiState.tab.slice(1);
      //   input.data.name = `Custom ${tabName} [${Date.now()}]`
      // } else {
      //   input.data.name = `${input.data.name} - copy`
      // }

      input.data.name = `${input.data.name} - copy`;

      input.id = '';
      input.data.id = '';
      input.data.default = 0;
    } else if (key == 'delete') {
      input.delete = 'delete';
    } else if (key == 'save') {
      if (input.id == 0) {
        input.id = '';
        input.data.id = '';
        input.data.default = 0;
      }
    }
    // console.log(input)
    // dispatch(portalActions.saveInput(input, tab, key));
  }

  function capitalizeFirstLetter(type) {
    return type.replace(/^./, type[0].toUpperCase());
  }

  function updateInput(value) {
    if (mode == 'portal') {
      if (tab == 'module') {
        // dispatch(portalActions.updateInput('selectedModuleIndex', value));
				dispatch(inputsActions.select_module(value))
      } else if (tab == 'racking') {
        // dispatch(portalActions.updateInput('selectedProductIndex', value));
				dispatch(inputsActions.select_product(value))
      }
    } else {
      dispatch(carportActions.prodModSelect('selectedCarportModule', value));
    }
  }

  function importOnClick() {
    dispatch(portalActions.updateInput('toggle_import', true));
  }

  return (
    <InputHeaderWrap simple={simple}>
      <div className="select-content" id="import-menu">

				
        <Select
          // disabled={saving}
          showSearch
          size="small"
          style={{ width: '100%', marginBottom: 10, marginRight: tab == 'module' ? 5 : 0 }}
          optionFilterProp="children"
          onChange={(value) => {
            updateInput(parseInt(value));
          }}
          dropdownMatchSelectWidth={290}
          dropdownStyle={{ width: 290 }}
          // value={store.modules[selectedModuleIndex]?.name || 'Select a Module'}
          value={'Search...'}
          filterOption={(input, option) => option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {Object.values(dropDown).map((item, index) => (
            <Select.Option key={index} value={index}>
              <span>{item.mod_name}</span>
            </Select.Option>
          ))}
        </Select>

        {/* INPUT MANAGER */}
        <div className="button-group">
          <Button.Group>
            {tab == 'module' && (
              <Tooltip placement="top" title={`Import`}>
                <Button size="small" onClick={() => importOnClick()}>
                  <span>
                    <ImportOutlined /> Import
                  </span>
                </Button>
              </Tooltip>
            )}

          </Button.Group>
        </div>
      </div>

      <div className="input-header-name">
        <label>Name</label>
				{mode == 'portal' &&
					<Input
						id="custom_name"
						onChange={(event) => {
							dispatch(inputsActions.update_input(event.target.id, event.target.value))
							// dispatch(portalActions.updateInput(event.target.id, event.target.value));
						}}
						value={mode == 'carport' || tab == 'module' ? inputs.mod_name : inputs.rack_name}
						size="small"
					/>
				}
				{mode == 'carport' &&
					<Input
						id="custom_name"
						onChange={(event) => {
							// dispatch(inputsActions.update_input(event.target.id, event.target.value))
							dispatch(portalActions.updateInput(event.target.id, event.target.value));
						}}
						value={module.mod_name}
						size="small"
					/>
				}
      </div>

      {/* <div className="module-name-row">
        <label>Name</label>
        <p>{portal.inputs.name}</p>
      </div> */}
    </InputHeaderWrap>
  );
};

export { InputHeader };
