import React, { Component, Fragment } from 'react';
// import ReactDistortableImageOverlay from 'react-leaflet-distortable-imageoverlay';
import ReactDistortableImageOverlay from './distortable';

// import './leaflet-distortableimage.js'
// redux
import { connect } from 'react-redux';
import { carportActions } from '../Redux/actions/carport.actions';

class ImageOverlay extends Component {
  constructor(props) {
    super(props);
  }

  onWellKnownTextUpdated = (value) => {
    // console.log(value);
  };

  onCornersUpdated = (corners) => {
    this.props.updateImage('updateCorners', corners);
  };

  render() {
    return (
      <ReactDistortableImageOverlay
        url={this.props.imgSrc}
        // editMode={this.props.carport.activeImageEditTool}
        editMode={this.props.editMode}
        onCornersUpdated={(e) => this.onCornersUpdated(e)}
        onWellKnownTextUpdated={(value) => this.onWellKnownTextUpdated(value)}
        corners={this.props.corners}
        opacity={this.props.opacity}
        imgKey={this.props.imgKey}
        selectImageId={this.props.selectImageId}
        setImageToolbarPosition={this.props.setImageToolbarPosition}

        // onClickPolygon={this.props.onClickPolygon}
      />
    );
  }
}

function mapStateToProps(state) {
  const { carport } = state;
  return {
    carport,
  };
}

const connectedStore = connect(mapStateToProps)(ImageOverlay);
export { connectedStore as ImageOverlay };
