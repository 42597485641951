import React, { useState, useEffect } from "react";

import ReactDataSheet from "react-datasheet";
import { SheetRenderer } from "../SheetRenderer";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { inputsActions, portalActions } from "../../Redux";

// ant design
// documentation here ====> https://ant.design/
import { Input, Switch } from "antd";
import { PerformanceInputWrapper } from "./style";

const Months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

const columns = [
  { label: "", width: "5%" },
  { label: "", width: "5%" },
  { label: "", width: "5%" },
];

const renderSheet = (props) => {
  return <SheetRenderer columns={columns} {...props} />;
};

const PerformanceInputs = () => {
  const inputs = useSelector((state) => state.inputs);

  const dispatch = useDispatch();

  const [local_inputs, set_local_inputs] = useState(inputs);

  useEffect(() => {
    set_local_inputs(inputs);
    set_local_monthly_dt(createMonthlyTable(inputs));
  }, [inputs]);

  const updateInput = (key, value) => {
    set_local_inputs({
      ...local_inputs,
      [key]: value,
    });
    // do some input validation here
    let fixed_value = value;
    // console.log(key, fixed_value)
    if (_.isUndefined(value) || _.isNaN(value)) {
      return;
    }

    if (key != "do_monthly_losses" && key != "soiling" && key != "albedo") {
      fixed_value = parseFloat(value);
    }

    // console.log(key,fixed_value)
    // dispatch(portalActions.updatePerformance(key,fixed_value));
    dispatch(inputsActions.update_input(key, fixed_value));
  };

  function handleChanges(changes) {
    const data = local_monthly_dt.map((row) => [...row]);
    changes.forEach(({ cell, row, col, value }) => {
      if (data[row] && data[row][col]) {
        // NS: Fix for stripping % and $ from excel copied values
        let fixed_value = value.replace("$", "").replace("%", "");
        data[row][col] = { ...data[row][col], value: fixed_value };
      }
    });
    let newSoilData = data.slice(1, data.length);
    var Soiling = [];
    var Albedo = [];
    newSoilData.forEach((row) => {
      let soil = parseFloat(row[1].value) == row[1].value ? parseFloat(row[1].value) : 0;
      let albedo = parseFloat(row[2].value) == row[2].value ? parseFloat(row[2].value) : 0;
      Soiling.push(soil);
      Albedo.push(albedo);
    });
    updateInput("soiling", Soiling);
    updateInput("albedo", Albedo);
  }

  const createMonthlyTable = (inputs) => {
    var data = [
      [
        { value: "Month", readOnly: true },
        { value: "Soiling (%)", readOnly: true },
        { value: "Albedo", readOnly: true },
      ],
    ];
    for (var i = 0; i < 12; i++) {
      data.push([
        { readOnly: true, value: Months[i] },
        { readOnly: false, value: inputs.soiling[i] },
        { readOnly: false, value: inputs.albedo[i] },
      ]);
    }
    return data;
  };
  const [local_monthly_dt, set_local_monthly_dt] = useState(createMonthlyTable(local_inputs));

  return (
    <PerformanceInputWrapper>
      <div className="input-two-col">
        <label>Modules Per String</label>
        <>{local_inputs.mod_per_string}</>
      </div>
      <div className="input-two-col">
        <label>DC Degradation</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={local_inputs.dc_degrade}
          onChange={(e) => updateInput("dc_degrade", e.target.value)}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>Thermal Constant Loss</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={local_inputs.dc_thermal_Uc}
          onChange={(e) => updateInput("dc_thermal_Uc", e.target.value)}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>W/m²k</span>}
        />
      </div>

      <div className="input-two-col">
        <label>Thermal Wind Loss</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={local_inputs.dc_thermal_Uv}
          onChange={(e) => updateInput("dc_thermal_Uv", e.target.value)}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>W/m²k/m/s</span>}
        />
      </div>

      <div className="input-two-col">
        <label>Mod Quality Loss</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={local_inputs.dc_module_quality_loss}
          onChange={(e) => updateInput("dc_module_quality_loss", e.target.value)}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>Mod LID Loss</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={local_inputs.dc_module_lid_loss}
          onChange={(e) => updateInput("dc_module_lid_loss", e.target.value)}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>DC Module Mismatch</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={local_inputs.dc_module_mismatch}
          onChange={(e) => updateInput("dc_module_mismatch", e.target.value)}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>DC Strings Mismatch</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={local_inputs.dc_strings_mismatch}
          onChange={(e) => updateInput("dc_strings_mismatch", e.target.value)}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>DC Wiring Loss @ STC</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={local_inputs.dc_wiring_loss_at_stc}
          onChange={(e) => updateInput("dc_wiring_loss_at_stc", e.target.value)}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>Bifacial Back Mismatch</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={local_inputs.bi_back_mismatch}
          onChange={(e) => updateInput("bi_back_mismatch", e.target.value)}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>Auxiliaries</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={local_inputs.ac_aux_kw}
          onChange={(e) => updateInput("ac_aux_kw", e.target.value)}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>kW</span>}
        />
      </div>

      <div className="input-two-col">
        <label>AC Wiring Loss @ STC</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={local_inputs.ac_wiring_loss_at_stc}
          onChange={(e) => updateInput("ac_wiring_loss_at_stc", e.target.value)}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>Transformer Constant Loss</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={local_inputs.ac_transformer_loss_constant}
          onChange={(e) => updateInput("ac_transformer_loss_constant", e.target.value)}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>Transformer Loss @ STC</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={local_inputs.ac_transformer_loss_at_stc}
          onChange={(e) => updateInput("ac_transformer_loss_at_stc", e.target.value)}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>MV Line Loss @ STC</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={local_inputs.ac_MV_line_loss_stc}
          onChange={(e) => updateInput("ac_MV_line_loss_stc", e.target.value)}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>Transmission Loss</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={local_inputs.ac_transmission_loss}
          onChange={(e) => updateInput("ac_transmission_loss", e.target.value)}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>Other AC Losses</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={local_inputs.ac_other_loss}
          onChange={(e) => updateInput("ac_other_loss", e.target.value)}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>DC:AC</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={local_inputs.simple_inverter_dcac}
          onChange={(e) => updateInput("simple_inverter_dcac", e.target.value)}
          // suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}></span>}
        />
      </div>

      {local_inputs.do_monthly_losses == 0 && (
        <>
          <div className="input-two-col">
            <label>Albedo</label>
            <Input
              className="gm-input"
              size="small"
              type="number"
              value={local_inputs.albedo_single}
              onChange={(e) => updateInput("albedo_single", e.target.value)}
              // suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}></span>}
            />
          </div>

          <div className="input-two-col">
            <label>Soiling</label>
            <Input
              className="gm-input"
              size="small"
              type="number"
              value={local_inputs.soiling_single}
              onChange={(e) => updateInput("soiling_single", e.target.value)}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
            />
          </div>
        </>
      )}

      <div className="input-two-col">
        <label>Monthly Values</label>
        <Switch id="do_monthly_losses" checked={local_inputs.do_monthly_losses == 1} onChange={(b, e) => updateInput("do_monthly_losses", b ? 1 : 0)} />
      </div>

      {local_inputs.do_monthly_losses == 1 && (
        <div className="input-table">
          <ReactDataSheet
            data={local_monthly_dt}
            sheetRenderer={renderSheet}
            valueRenderer={(cell) => cell.value}
            onContextMenu={(e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null)}
            onCellsChanged={handleChanges}
          />
        </div>
      )}

      {/* soiling */}
      {/* albedo */}

      {/* <div className="input-two-col">
        <label>Soiling:</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={portal.inputs.losses.soiling_loss}
          onChange={(e) => {
            dispatch(
              portalActions.updateInput('setSoilingLosses', {
                key: 'soiling_loss',
                payload: e.target.value,
              })
            );
          }}
          min="0"
          max="100"
          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>Clearance Height:</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={portal.inputs.bifacial_ground_clearance_height}
          onChange={(e) => {
            dispatch(portalActions.updateInput('bifacial_ground_clearance_height', e.target.value));
          }}
          min="0"
          max="100"
          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>m</span>}
        />
      </div>

      <div className="input-two-col">
        <label> Transmission %:</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={portal.inputs.bifacial_transmission_factor}
          onChange={(e) => {
            dispatch(portalActions.updateInput('bifacial_transmission_factor', e.target.value));
          }}
          min="0"
          max="100"
          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>DC Mismatch:</label>
        <Input
          className="gm-input"
          id="mismatch_loss"
          size="small"
          type="number"
          min="0"
          value={portal.inputs.losses.mismatch_loss}
          onChange={(e) => {
            dispatch(portalActions.updateInput(e.target.id, parseInt(e.target.value)));
          }}
          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%/yr</span>}
        />
      </div>

      <div className="input-two-col">
        <label>DC Wiring:</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={portal.inputs.losses.wiring_loss}
          onChange={(e) => {
            dispatch(
              portalActions.updateInput('setSoilingLosses', {
                key: 'wiring_loss',
                payload: e.target.value,
              })
            );
          }}
          min="0"
          max="100"
          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>Module LID:</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={portal.inputs.losses.module_lid_loss}
          onChange={(e) => {
            dispatch(
              portalActions.updateInput('setSoilingLosses', {
                key: 'module_lid_loss',
                payload: e.target.value,
              })
            );
          }}
          min="0"
          max="100"
          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>Module Quality:</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={portal.inputs.losses.module_quality_loss}
          onChange={(e) => {
            dispatch(
              portalActions.updateInput('setSoilingLosses', {
                key: 'module_quality_loss',
                payload: e.target.value,
              })
            );
          }}
          min="-100"
          max="100"
          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>Structure Shade %:</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={portal.inputs.bifacial_structure_shade_factor}
          onChange={(e) => {
            dispatch(portalActions.updateInput('bifacial_structure_shade_factor', e.target.value));
          }}
          min="0"
          max="100"
          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>Inverter Efficiency:</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={portal.inputs.losses.inverter_eff}
          onChange={(e) => {
            dispatch(
              portalActions.updateInput('setSoilingLosses', {
                key: 'inverter_eff',
                payload: e.target.value,
              })
            );
          }}
          min="0"
          max="100"
          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
        />
      </div>

      <div className="input-two-col">
        <label>Combined AC Losses:</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={portal.inputs.losses.combined_ac_loss}
          onChange={(e) => {
            dispatch(
              portalActions.updateInput('setSoilingLosses', {
                key: 'combined_ac_loss',
                payload: e.target.value,
              })
            );
          }}
          min="0"
          max="100"
          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
        />
      </div>
			
      <div className="input-two-col">
        <label>DC:AC:</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          step="0.01"
          value={portal.inputs.dcac}
          onChange={(e) => {
            dispatch(portalActions.updateInput('dcac', e.target.value));
          }}
          min="0.1"
          max="3.0"
          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}> </span>}
        />
      </div> */}

      {/* <div className="input-two-col">
        <label>DC Loss @STC</label>
        <Input
          id="dc_loss_stc"
          className="gm-input"
          onChange={(e) => {
            dispatch(
              portalActions.updateInput('setSoilingLosses', {
                key: 'dc_loss_stc',
                payload: e.target.value,
              })
            );
          }}
          value={portal.inputs.losses.dc_loss_stc}
          size="small"
          autoComplete="off"
          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
        />
      </div> */}

      {/* <div className="input-two-col">
        <label>PV Quality Loss</label>
        <Input
          id="module_quality_loss"
          className="gm-input"
          onChange={(e) => {
            dispatch(
              portalActions.updateInput('setSoilingLosses', {
                key: 'module_quality_loss',
                payload: e.target.value,
              })
            );
          }}
          value={portal.inputs.losses.module_quality_loss}
          size="small"
          autoComplete="off"
          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
        />
      </div> */}

      {/* <div className="input-two-col">
        <label>PV LID Loss</label>
        <Input
          id="lid_loss"
          className="gm-input"
          onChange={(e) => {
            dispatch(
              portalActions.updateInput('setSoilingLosses', {
                key: 'lid_loss',
                payload: e.target.value,
              })
            );
          }}
          value={portal.inputs.losses.module_lid_loss}
          size="small"
          autoComplete="off"
          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
        />
      </div> */}

      {/* <div className="input-two-col">
        <label>AC Losses:</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={portal.inputs.losses.ac_losses}
          onChange={(e) => {
            dispatch(
              portalActions.updateInput('setSoilingLosses', {
                key: 'ac_losses',
                payload: e.target.value,
              })
            );
          }}
          min="0"
          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
        />
      </div> */}

      {/* <div className="input-two-col">
        <label>Thermal Uc:</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={portal.inputs.losses.thermal_uc}
          onChange={(e) => {
            dispatch(
              portalActions.updateInput('setSoilingLosses', {
                key: 'thermal_uc',
                payload: e.target.value,
              })
            );
          }}
          min="0"
          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>W/m²k</span>}
        />
      </div> */}

      {/* <div className="input-two-col">
        <label>Thermal Uv:</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={portal.inputs.losses.thermal_uv}
          onChange={(e) => {
            dispatch(
              portalActions.updateInput('setSoilingLosses', {
                key: 'thermal_uv',
                payload: e.target.value,
              })
            );
          }}
          min="0"
          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>W/m²k/m/s</span>}
        />
      </div> */}

      {/* <div className="input-two-col">
        <label>Albedo:</label>
        <Input
          className="gm-input"
          size="small"
          type="number"
          value={portal.inputs.losses.albedo}
          onChange={(e) => {
            dispatch(
              portalActions.updateInput('setSoilingLosses', {
                key: 'albedo',
                payload: e.target.value,
              })
            );
          }}
          min="0.1"
          step="0.1"
        />
      </div> */}

      {/* <div className="input-two-col">
        <label>Monthly Values:</label>
        <Switch
          id="doMonthlySoiling"
          checked={portal.inputs.doMonthlySoiling == 1}
          onChange={(b, e) => {
            dispatch(portalActions.updateInput(e.target.id, b ? 1 : 0));
          }}
        />
      </div>

      {portal.inputs.doMonthlySoiling == 1 && (
        <div className="input-table">
          <ReactDataSheet
            data={dt_data}
            sheetRenderer={renderSheet}
            valueRenderer={(cell) => cell.value}
            onContextMenu={(e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null)}
            onCellsChanged={handleChanges}
          />
        </div>
      )} */}
    </PerformanceInputWrapper>
  );
};

export { PerformanceInputs };
