import React, { useState, useEffect, Fragment } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { portalActions, inputsActions } from "../../Redux";

//components
import { DimensionOverrideTable } from "../../Sunfig/DimensionOverrideTable";

// ant design
// documentation here ====> https://ant.design/
import { Input, Switch, Tooltip } from "antd";

const RackingInputs = ({ panelScrollPosition, panelOffsetTop }) => {
  const portal = useSelector((state) => state.portal);
  const inputs = useSelector((state) => state.inputs);
  const dispatch = useDispatch();

  const [local_inputs, set_local_inputs] = useState(inputs);
  useEffect(() => {
    set_local_inputs(inputs);
    // console.log("inputs", inputs);
  }, [inputs]);

  const updateUIState = (key, value) => {
    if (key == "do_gcr_override" && !value) {
      dispatch(inputsActions.update_input("gcr_min", 0.3));
      dispatch(inputsActions.update_input("gcr_max", 0.6));
    }
    if (key == "do_module_target" && !value) {
      dispatch(inputsActions.update_input("dc_lock_value", 0));
    }
    if (key == "do_tilt_override" && !value) {
      dispatch(inputsActions.update_input("tilt_min", 10));
      dispatch(inputsActions.update_input("tilt_max", 30));
    }

    dispatch(inputsActions.update_ui_state(key, value));
  };

  const updateInput = (key, value) => {
    set_local_inputs({
      ...local_inputs,
      [key]: value,
    });
    // do some input validation here
    let fixed_value = value;
    // console.log(key, fixed_value)

    if (key == "dc_lock_value" || key == "tilt_min" || key == "tilt_max") {
      if (_.isEmpty(value) || _.isUndefined(value) || _.isNaN(value)) {
        return;
      }
      fixed_value = parseInt(value);
    }
    if (key == "rack_grade_limit" || key == "gcr_min" || key == "gcr_max") {
      if (_.isEmpty(value) || _.isUndefined(value) || _.isNaN(value)) {
        return;
      }
      fixed_value = parseFloat(value);
    }

    // console.log(key,fixed_value)
    // dispatch(portalActions.updatePerformance(key,fixed_value));
    dispatch(inputsActions.update_input(key, fixed_value));
  };

  return (
    <div className="inputs-content-box">
      <div className="product-input">
        <label>Module Target:</label>
        <Switch checked={local_inputs.uiState.do_module_target} onChange={(checked) => updateUIState("do_module_target", checked)} />
      </div>
      {local_inputs.uiState.do_module_target && (
        <div className="toggled-input one-col">
          <div className="toggled-input-labeled">
            <label className="small-label">Target</label>
            <span className="small-span">
              <Input
                className="gm-input"
                value={local_inputs.dc_lock_value}
                disabled={!local_inputs.uiState.do_module_target}
                id="dc_lock_value"
                min="0"
                size="small"
                onChange={(e) => updateInput(e.target.id, e.target.value)}
                suffix={<span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>mod</span>}
              />
            </span>
          </div>
        </div>
      )}
      <div className="product-input">
        <label>Grade Limit:</label>
        <Input
          className="gm-input"
          value={local_inputs.rack_grade_limit}
          id="rack_grade_limit"
          type="number"
          min="0"
          size="small"
          onChange={(e) => updateInput(e.target.id, e.target.value)}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
        />
      </div>
      {/* <div className="product-input">
        <label>Rack to Rack Gap:</label>
        <Input
          className="gm-input"
          value={portal.inputs.gap}
          id="gap"
          type="number"
          min="0"
          size="small"
          onChange={(e) => {
            dispatch(portalActions.updateInput(e.target.id, parseFloat(e.target.value)));
          }}
          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>m</span>}
        />
      </div> */}
      {/* <div className="product-input">
        <label>Height Above Ground:</label>
        <Input
          className="gm-input"
          value={portal.inputs.module_clearance_height}
          id="module_clearance_height"
          type="number"
          min="0"
          step="0.1"
          size="small"
          onChange={(e) => {
            dispatch(portalActions.updateInput(e.target.id, parseFloat(e.target.value)));
          }}
          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>m</span>}
        />
      </div> */}

      {/* <div className="product-input">
        <label>Road Spacing:</label>
        {portal.inputs.selectedProduct.type == 1 && !portal.inputs.doAlign ? (
          <Tooltip placement="right" mouseEnterDelay={0.5} title="Roads automatically disabled when Racking Align off for SAT">
            <span style={{ color: '#1cac50' }}>Disabled</span>
          </Tooltip>
        ) : (
          <Switch
            checked={portal.inputs.doRoads}
            onChange={(checked) => {
              dispatch(portalActions.updateInput('doRoads', checked));
            }}
          />
        )}
      </div> */}
      {/* <div className="product-input">
        <label>Racking Align:</label>
        <Switch
          checked={portal.inputs.doAlign}
          onChange={(checked) => {
            dispatch(portalActions.updateInput('doAlign', checked));
          }}
        />
      </div> */}
      <div className="product-input">
        <label>GCR Override:</label>
        <Switch checked={local_inputs.uiState.do_gcr_override} onChange={(checked) => updateUIState("do_gcr_override", checked)} />
      </div>
      {local_inputs.uiState.do_gcr_override && (
        <div className="toggled-input">
          <div className="toggled-input-labeled">
            <label className="small-label">Min</label>
            <span className="small-span">
              <Input
                className="gm-input"
                type="number"
                size="small"
                id="gcr_min"
                value={local_inputs.gcr_min}
                onChange={(e) => updateInput(e.target.id, e.target.value)}
                step="0.01"
                suffix={<span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>gcr</span>}
              />
            </span>
          </div>
          <div className="toggled-input-labeled">
            <label className="small-label">Max</label>
            <span className="small-span">
              <Input
                className="gm-input"
                type="number"
                size="small"
                id="gcr_max"
                value={local_inputs.gcr_max}
                onChange={(e) => updateInput(e.target.id, e.target.value)}
                step="0.01"
                suffix={<span style={{ color: "rgba(0,0,0,.45)", fontSize: "x-small" }}>gcr</span>}
              />
            </span>
          </div>
        </div>
      )}
      {local_inputs.track_mode == 0 && (
        // && portal.local_inputs.selectedProduct.id != 'b48a8ce7d35b4a72a715bafc1100cefc'
        // && portal.local_inputs.selectedProduct.id != 'a5fb2c48e4084690998f615324018d63'
        <Fragment>
          {local_inputs.can_continuous == 1 && (
            <div className="product-input">
              <label>Display Continuous:</label>
              <Switch checked={local_inputs.continuous == 1} onChange={(checked) => updateInput("continuous", checked ? 1 : 0)} />
            </div>
          )}
          <div className="product-input">
            <label>Tilt Angle Override:</label>
            <Switch
              checked={local_inputs.uiState.do_tilt_override}
              onChange={(checked) => {
                updateUIState("do_tilt_override", checked);
              }}
            />
          </div>
          {local_inputs.uiState.do_tilt_override && (
            // <div className="toggled-input">
            <div className="toggled-input">
              <div className="toggled-input-labeled">
                <label className="small-label">Min</label>
                <span className="small-span">
                  <Input
                    className="gm-input"
                    value={local_inputs.tilt_min}
                    id="tilt_min"
                    onChange={(e) => updateInput(e.target.id, e.target.value)}
                    min="0"
                    max="45"
                    size="small"
                    suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>°</span>}
                  />
                </span>
              </div>
              <div className="toggled-input-labeled">
                <label className="small-label">Max</label>
                <span className="small-span">
                  <Input
                    className="gm-input"
                    value={local_inputs.tilt_max}
                    id="tilt_max"
                    onChange={(e) => updateInput(e.target.id, e.target.value)}
                    min="0"
                    max="45"
                    size="small"
                    suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>°</span>}
                  />
                </span>
              </div>
            </div>
          )}
        </Fragment>
      )}
      <div className="product-input">
        <label>Dimension Override:</label>
        <Switch
          checked={local_inputs.uiState.do_dimension_override}
          onChange={(checked) => {
            dispatch(inputsActions.update_ui_state("do_dimension_override", checked));
          }}
        />
      </div>
      {local_inputs.uiState.do_dimension_override && <DimensionOverrideTable panelScrollPosition={panelScrollPosition} panelOffsetTop={panelOffsetTop} />}
    </div>
  );
};

export { RackingInputs };
