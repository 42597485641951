import styled from 'styled-components';

export const DropZoneOverlay = styled.section`
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		padding: 2.5em 0;
		background: rgba(0,0,0,0.5);
		text-align: center;
		color: #fff;
		z-index: 10000;
`;