// This is the Tenant entry point Component
// and serves as the base for any tenant apps
// copy this into your base for the tenant 
// and build from here, also change import in
// index

import React from 'react';

import { Canopy } from '../components';


const CanopyWrapper = (props) => {
    return (
			<>
				<Canopy />
			</>
    )
}

export { CanopyWrapper }