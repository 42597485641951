// these keys will not be shown by default on SunfigMap component load
const selectedResultFilter = {
  keys: [
    // 'racking_name',
    // 'module_name',
    // 'azimuth',
    'id',
    'layout_id',
    // 'gcr',
    'rtr',
    'pitch',
    'spi',
    'dcac',
    'inverter_count',
    'rack_count',
    // 'module_count',
    'string_count',
    // 'capacity',
    'rack_breakdown',
    'removed_modules',
    // 'tilt',
    // 'YIELD',
    // 'generation',
    'generation_array',
    'dc_degradation',
    'horizontal_global_irradiance',
    'global_incident_in_col_plane',
    'near_shadings_irradiance_loss',
    'iam_factor_on_global',
    'soiling_loss',
    'bifacial_gain',
    'global_effective_irradiation_on_col',
    'array_nominal_energy',
    'pv_loss_irrad',
    'pv_loss_temp',
    'pv_elec_loss',
    'lid_loss',
    'quality_loss',
    'dc_mismatch_loss',
    'ohmic_wiring_loss',
    'e_array',
    'inverter_loss',
    'ac_loss',
    'interconnect_lim_loss',
    'table_a',
    'table_b',
    'table_c',
    'table_d',
  ]
};

export default selectedResultFilter;
