import React, { useState, useEffect, useRef, Fragment } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { Map, TileLayer, LayerGroup, FeatureGroup, GeoJSON, Polygon, LayersControl, Popup } from "react-leaflet";
import LeafletControl from "./MapControl";
import { EditControl } from "react-leaflet-draw";
import L from "leaflet";
import tokml from "tokml";
import domtoimage from "dom-to-image";
const FileSaver = require("file-saver");
import selectedResultFormat from "../../utils/defaultSelectedResultFormat";
import * as turf from "@turf/turf";
import { motion } from "framer-motion";

// helpers
import { useDynamicRefs } from "../../utils";
import { getBounds } from "../../utils/map_helper";

// assets
import { vertex_white, vertex_green, ts_logo_hori } from "../../assets";

// components
import { GroundMountToolbar, ZoomAndLayerControl, GroundMountResults } from "./";
import { SalesForceCapture } from "../SalesForceCapture";
import { OffsetPolygonTool } from "../GroundMount";

// antd
import { Menu, Progress, Spin, Button, Card, Modal, Checkbox, Tooltip } from "antd";
import {
  UnorderedListOutlined,
  CheckOutlined,
  EditOutlined,
  BuildOutlined,
  FontColorsOutlined,
  FileImageOutlined,
  ExportOutlined,
  GatewayOutlined,
  BorderOuterOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  GlobalOutlined,
  MenuUnfoldOutlined,
  BorderInnerOutlined,
  BorderHorizontalOutlined,
  BorderVerticleOutlined,
  StopOutlined,
  ColumnHeightOutlined,
} from "@ant-design/icons";

// redux
import { useSelector, useDispatch } from "react-redux";
import { portalActions, inputsActions } from "../Redux/actions";

import BingLayer from "./Bing";
const bing_key = "Apg1Wo2sThhv7onPyHncSIy7J3Nn98FFIzAhWYNFDrNLDpCRkZaSic1uuBHRoppM";
// const TileSets = {
//   'Satellite': 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
//   'Map': 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
//   'Topography': 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
//   'ESRI World Street': 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
//   'Map Tiler Satelite': 'https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=tdZKmEGOeC3kM451zVDC',
// };

const TileSets = {
  Satellite: "Aerial",
  "Satellite With Labels": "AerialWithLabels",
  "Satellite With OnDemand Labels": "AerialWithLabelsOnDemand",
  "Street Map": "Road",
  // "Street Map Dark": "CanvasDark",
  // "CanvasLight": "CanvasLight",
  // "CanvasGray": "CanvasGray"
};

const colors = ["white", "red", "yellow"];

const GroundMountMapWrap = styled.section`
  grid-column: 2;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .generating-box {
    width: 100%;
    height: 100px;
    position: absolute;
    display: grid;
    place-items: center;
    bottom: 0;
    z-index: 600;

    .generating-box-contents,
    .initializing-box {
      color: #60de4f;
      height: 80px;
      width: 250px;
      background-color: #fff;
      border-radius: 2px;
      border: 1px solid #60de4f;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      p {
        font-size: 16px;
        margin: 0;
      }
    }
  }

  .results {
    width: 100%;
    height: ${(props) => (props.showLiveReport ? "100%" : "380px")};
    position: absolute;
    bottom: 0;
    z-index: 1501;
    display: grid;
    place-items: center;
    transition: height 0.5s cubic-bezier(0.23, 1, 0.32, 1.05);

    .results-box {
      overflow: hidden;
      width: 100%;
      height: 100vh;
      background-color: #fff;
      border: 1px solid #60de4f;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);

      .results-close-button {
        position: absolute;
        right: 0px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`;

const SingleResultWindow = styled(motion.section)`
  width: 265px;
  position: relative;
  bottom: ${(props) => props.bottomOffset};
  /* border: 1px solid #60de4f; */
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  border-radius: 3px;

  img {
    width: 162px;
    display: block;
    margin: 0px auto;
    padding-bottom: 5px;
  }
  label {
    display: inline-block;
    margin-right: 5px;
    width: 80px;
  }
  span {
    display: block;
    margin-bottom: 2px;
  }

  &:hover {
    cursor: move;
  }
`;

const TopoBox = styled(motion.section)`
  height: 350px;
  width: 75px;
  margin: 0 35px 0 -13px;
  & > img {
    height: 100%;
    width: 100%;
  }
  &:hover {
    cursor: move;
  }
`;

const basePolyStates = {
  Boundary: true,
  Exclusions: true,
  Inactive: true,
  Layout: true,
  Wiring: true,
};

const baseTopoStates = {
  "Elevation Ungraded": false,
  "NS Ungraded": false,
  "EW Ungraded": false,
  "Max Ungraded": false,
  "Elevation Graded": false,
  "NS Graded": false,
  "EW Graded": false,
  "Max Graded": false,
  "Cut/Fill": false,
  Off: true,
};

const TopoTranslations = {
  "Elevation Ungraded": "ele",
  "NS Ungraded": "NS",
  "EW Ungraded": "EW",
  "Max Ungraded": "U",
  "Elevation Graded": "ele/G",
  "NS Graded": "NS/G",
  "EW Graded": "EW/G",
  "Max Graded": "U/G",
  "Cut/Fill": "CF",
  Off: "Off",
};
const TopoKeys = {
  ele: "Elevation Ungraded",
  NS: "NS Ungraded",
  EW: "EW Ungraded",
  U: "Max Ungraded",
  "ele/G": "Elevation Graded",
  "NS/G": "NS Graded",
  "EW/G": "EW Graded",
  "U/G": "Max Graded",
  CF: "Cut/Fill",
  Off: "Off",
};
const TopoModeMapper = {
  ele: "vis_ele",
  NS: "vis_ns",
  EW: "vis_ew",
  U: "vis_max",
  "ele/G": "vis_ele",
  "NS/G": "vis_ns",
  "EW/G": "vis_ew",
  "U/G": "vis_max",
  CF: "vis_g",
  Off: "Off",
};

const WiringKeys = ["inverter", "wires", "combiner_boxes", "strings"];

const GroundMountMap = () => {
  // setting up redux
  const dispatch = useDispatch();
  const portal = useSelector((state) => state.portal);
  const inputs = useSelector((state) => state.inputs);
  const uiControls = useSelector((state) => state.portal.uiControls);
  const offsetToolVisible = useSelector((state) => state.portal.uiControls.offsetToolVisible);
  const offsetPolygonKey = useSelector((state) => state.portal.inputs.offsetPolygonKey);
  const show_sf_modal = useSelector((state) => state.inputs.uiState.show_sf_modal);

  // map ref
  const leafletMap = useRef(null);
  const mapContainer = useRef(null);

  // helper function to setup dynamic refs
  const [getRef, setRef] = useDynamicRefs();

  // state
  const [activeTileSet, setActivetileSet] = useState("Satellite");
  const [bingLayerVisible, setBingLayerVisible] = useState(true);
  const [clicked, setClicked] = useState(0);
  const [currentEditingLayer, setCurrentEditingLayer] = useState(undefined);
  const [highlightPoly, setHighlightPoly] = useState(undefined);
  const [summaryLegendDragged, setSummaryLegendDragged] = useState(false);

  const [polygonStates, setPolygonStates] = useState(JSON.parse(JSON.stringify(basePolyStates)));
  const [topoStates, setTopoStates] = useState(JSON.parse(JSON.stringify(baseTopoStates)));
  const [topoMode, setTopoMode] = useState("Off");
  const [topoOn, setTopoOn] = useState(false);

  // this keeps a ref to the previous id of a feature that is passed to it.
  const usePrevious = (id) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = id;
    });
    return ref.current;
  };

  // const [menuVisible, setMenuVisible] = useState(false);
  // const [menuFeatures, setMenuFeatures] = useState([]);
  // const [menuX, setMenuX] = useState(0);
  // const [menuY, setMenuY] = useState(0);
  // const [showDims, setShowDims] = useState(false);
  // const [showAzimuth, setShowAzimuth] = useState(false);
  const menuVisible = useRef(false);
  const menuFeatures = useRef([]);
  const menuX = useRef(0);
  const menuY = useRef(0);
  const takingPicture = useRef(false);
  const currentSelectedResult = useRef(undefined);
  const showDims = useRef(0);
  const showAzimuth = useRef(0);
  const selectedAzimuth = useRef(0);
  const currentLayer = useRef(undefined);
  const searchToolActive = useRef(false);
  const bugReportActive = useRef(false);
  const drawToolActive = useRef(false);
  const selectedFeatureId = useRef(undefined);
  const currentDrawingPolygon = useRef({});
  const features = useRef({});
  const showProjectLoader = useRef(false);
  const imageTarget = useRef(undefined);
  const generatingReport = useRef(false);

  // wrapper
  const useForceUpdate = () => {
    const [, setState] = useState();
    return () => setState({});
  };
  // call forceUpdate() after any update to one of the above useRef variables if you NEED that update to be visible in the render
  // example: alignmentCanopyIds is updated within an event listener function, and when Id's are added, they are then read in
  //  the render function in order to toggle alignment lines on/off.. so you would need to call forceUpdate after updating alignmentCanopyIds
  const forceUpdate = useForceUpdate();

  const prevPolyId = usePrevious(portal.inputs.selectedFeatureId);

  useEffect(() => {
    setBingLayerVisible(true);
  }, [activeTileSet]);
  const onChangeTileset = (tileset) => {
    setBingLayerVisible(false);
    setActivetileSet(tileset);
  };

  // this keeps track when selectedfeatureid is updated and reacts accordingly. basically a ComponentDidUpdate lifecycle
  useEffect(() => {
    selectedFeatureId.current = portal.inputs.selectedFeatureId;
    if (portal.inputs.selectedFeatureId != prevPolyId && portal.uiControls.isDeleting != true) {
      // something new was selected
      if (prevPolyId && portal.inputs.features[prevPolyId]) stopEditing(prevPolyId);
      if (portal.inputs.selectedFeatureId && portal.inputs.features[portal.inputs.selectedFeatureId]) startEditing(portal.inputs.selectedFeatureId);
    }
  }, [portal.inputs.selectedFeatureId]);

  useEffect(() => {
    features.current = inputs.site_features;
    // console.log('features', features.current);
  }, [inputs.site_features]);

  const prevPreparingReport = usePrevious(portal.report.preparingReport);

  useEffect(() => {
    if (prevPreparingReport == false && portal.report.preparingReport == true) {
      let location = leafletMap.current.leafletElement.getCenter();
      dispatch(portalActions.updateReportData("location", location));
      // dispatch(portalActions.selectFeature(undefined));
      // // let featuresWithBoundary = Object.values(features.current).filter((feature) => feature.properties.identity == 1);
      // // let targetKey = portal.reportData.id;
      // imageTarget.current = undefined;
      // takingPicture.current = true;
      // forceUpdate();
      // handleCaptureReportImages();
    }
  }, [portal.report.preparingReport]);

  useEffect(() => {
    showProjectLoader.current = portal.uiControls.showProjectLoader;
  }, [portal.uiControls.showProjectLoader]);

  useEffect(() => {
    let selResult = {};
    if (portal.selectedResult) {
      portal.selectedResult.map((item) => {
        selResult[item.key] = item;
      });
      // selResult.generation.value = parseFloat(selResult.generation.value / 1000).toFixed(2);
    }

    currentSelectedResult.current = selResult;
    forceUpdate();
  }, [portal.selectedResult]);

  useEffect(() => {
    setTopoMode(TopoKeys[portal.topoData.topo_mode]);
  }, [portal.topoData]);
  useEffect(() => {
    setTopoMode(TopoKeys[inputs.topo_mode]);
  }, [inputs.topo_mode]);

  useEffect(() => {
    searchToolActive.current = portal.uiControls.searchToolActive;
  }, [portal.uiControls.searchToolActive]);

  useEffect(() => {
    bugReportActive.current = portal.uiControls.bug_visible;
  }, [portal.uiControls.bug_visible]);

  useEffect(() => {
    drawToolActive.current = portal.uiControls.isDrawing;
  }, [portal.uiControls.isDrawing]);

  useEffect(() => {
    setPolygonStates(JSON.parse(JSON.stringify(basePolyStates)));
  }, [portal.uiControls.project_loading]);

  // this handles zoom extents when a project is laoded
  useEffect(() => {
    if (portal.inputs.mapBounds) {
      leafletMap.current.leafletElement.fitBounds([portal.inputs.mapBounds]);
    }
  }, [portal.inputs.mapBounds]);

  // event listener add/remove
  useEffect(() => {
    L.DomEvent.addListener(document, "keydown", onKeyDown, leafletMap.current.leafletElement);
    L.DomEvent.addListener(document, "mousedown", onMouseClick, leafletMap.current.leafletElement);
    L.DomEvent.addListener(document, "contextmenu", onContextMenu, leafletMap.current.leafletElement);

    return () => {
      L.DomEvent.removeListener(document, "keydown", onKeyDown, leafletMap.current.leafletElement);
      L.DomEvent.removeListener(document, "mousedown", onMouseClick, leafletMap.current.leafletElement);
      L.DomEvent.removeListener(document, "contextmenu", onContextMenu, leafletMap.current.leafletElement);
    };
  }, []);

  useEffect(() => {
    // Setup the map the way we want it
    let corners = leafletMap.current.leafletElement._controlCorners,
      l = "leaflet-",
      container = leafletMap.current.leafletElement._controlContainer;

    function createCorner(vSide, hSide) {
      let className = l + vSide + " " + l + hSide;
      corners[vSide + hSide] = L.DomUtil.create("div", className, container);
    }

    createCorner("control", "menu");
  }, []);

  // leaflet overrides
  useEffect(() => {
    L.DomEvent.addListener(document, "keydown", onKeyDown, leafletMap.current.leafletElement);

    // let target = L.DomUtil.get('app')
    // L.DomUtil.removeClass(target, 'azimuth-mode');
    // L.DomUtil.removeClass(target, 'dimension-mode');

    // override the leaflet createMarker function to implement our custom icon
    L.Edit.PolyVerticesEdit.prototype._createMarker = function(latlng, index) {
      // Extending L.Marker in TouchEvents.js to include touch.
      let marker = new L.Marker.Touch(latlng, {
        draggable: true,
        icon: new L.Icon({
          iconUrl: vertex_white,
          iconSize: [12, 12],
          iconAnchor: [6, 6],
        }),
      });

      marker._origLatLng = latlng;
      marker._index = index;
      marker
        .on("dragstart", this._onMarkerDragStart, this)
        .on("drag", this._onMarkerDrag, this)
        .on("dragend", this._fireEdit, this)
        .on("touchmove", this._onTouchMove, this)
        .on("touchend", this._fireEdit, this)
        .on("MSPointerMove", this._onTouchMove, this)
        .on("MSPointerUp", this._fireEdit, this);

      this._markerGroup.addLayer(marker);

      return marker;
    };

    // override the leaflet endPoint function to prevent it from happening from middle mouse
    L.Draw.Polygon.prototype._endPoint = function(clientX, clientY, e) {
      e.originalEvent.preventDefault();
      if (this._mouseDownOrigin) {
        let dragCheckDistance = L.point(clientX, clientY).distanceTo(this._mouseDownOrigin);
        let lastPtDistance = this._calculateFinishDistance(e.latlng);
        if (this.options.maxPoints > 1 && this.options.maxPoints == this._markers.length + 1) {
          this.addVertex(e.latlng);
          this._finishShape();
        } else if (lastPtDistance < 10 && L.Browser.touch) {
          this._finishShape();
        } else if (Math.abs(dragCheckDistance) < 9 * (window.devicePixelRatio || 1)) {
          if (e.originalEvent.which != 2) {
            this.addVertex(e.latlng);
          }
        }
        this._enableNewMarkers(); // after a short pause, enable new markers
      }
      this._mouseDownOrigin = null;
    };
    // overide the leaflet updateTooltip function to insert our text directly into lib
    L.Draw.Polygon.prototype._updateTooltip = function(latLng) {
      if (this._markers) {
        if (this._markers.length == 0) {
          // console.log(this._markers.length)
          // text.text = "Click to start drawing";
          let text = this._getTooltipText();
          text.text = "";

          if (latLng) {
            this._tooltip.updatePosition(latLng);
          }

          if (!this._errorShown) {
            this._tooltip.updateContent(text);
          }
        }
      }
    };
    L.Draw.Polygon.prototype._getTooltipText = function() {
      let showLength = this.options.showLength,
        labelText,
        distanceStr;
      if (L.Browser.touch) {
        showLength = false; // if there's a better place to put this, feel free to move it
      }
      if (this._markers.length === 0) {
        labelText = {
          text: "",
        };
      } else {
        distanceStr = showLength ? this._getMeasurementString() : "";

        if (this._markers.length === 1) {
          labelText = {
            text: "",
            subtext: distanceStr,
          };
        } else {
          labelText = {
            text: "",
            subtext: distanceStr,
          };
        }
      }
      return labelText;
    };

    // MEASUREMENTS OVERRIDES

    let override = function(method, fn, hookAfter) {
      if (!hookAfter) {
        return function() {
          let originalReturnValue = method.apply(this, arguments);
          let args = Array.prototype.slice.call(arguments);
          args.push(originalReturnValue);
          return fn.apply(this, args);
        };
      } else {
        return function() {
          fn.apply(this, arguments);
          return method.apply(this, arguments);
        };
      }
    };
    L.Edit.PolyVerticesEdit.include({
      addHooks: override(L.Edit.PolyVerticesEdit.prototype.addHooks, function(originalReturnValue) {
        this.showMeasurements();
        return originalReturnValue;
      }),
      removeHooks: override(L.Edit.PolyVerticesEdit.prototype.removeHooks, function(originalReturnValue) {
        this.hideMeasurements();
        return originalReturnValue;
      }),
      updateMarkers: override(L.Edit.PolyVerticesEdit.prototype.updateMarkers, function(originalReturnValue) {
        this.updateMeasurements();
        return originalReturnValue;
      }),
      _onMarkerDrag: override(L.Edit.PolyVerticesEdit.prototype._onMarkerDrag, function(originalReturnValue) {
        this.updateMeasurements();
        return originalReturnValue;
      }),
      _onMarkerClick: override(L.Edit.PolyVerticesEdit.prototype._onMarkerClick, function(originalReturnValue) {
        this.updateMeasurements();
        return originalReturnValue;
      }),

      showMeasurements: function(options) {
        if (!this._map || this._measurementLayer) return this;

        this._measurementOptions = L.extend(
          {
            // this can be true as well
            imperial: true,
            showOnHover: false,
            minPixelDistance: 30,
            showDistances: true,
            showArea: true,
            lang: {
              totalLength: "Total length",
              totalArea: "Total area",
              segmentLength: "Segment length",
              segmentAzimuth: "Segment Azimuth",
            },
          },
          options || {}
        );

        this._measurementLayer = L.layerGroup().addTo(this._map);
        this.updateMeasurements();

        this._map.on("zoomend", this.updateMeasurements, this);

        return this;
      },
      hideMeasurements: function() {
        if (!this._map) return this;

        this._map.off("zoomend", this.updateMeasurements, this);

        if (!this._measurementLayer) return this;
        this._map.removeLayer(this._measurementLayer);
        this._measurementLayer = null;

        return this;
      },

      updateMeasurements: function() {
        if (!this._measurementLayer) return this;
        let latLngs = this._poly.getLatLngs(),
          isPolygon = true,
          options = this._measurementOptions,
          totalDist = 0,
          formatter,
          ll1,
          ll2,
          p1,
          p2,
          pixelDist,
          dist;
        if (latLngs && latLngs.length && L.Util.isArray(latLngs[0])) {
          // Outer ring is stored as an array in the first element,
          // use that instead.
          latLngs = latLngs[0];
        }

        // check which mode we're in
        let target = L.DomUtil.get("app");
        let isDimension = L.DomUtil.hasClass(target, "dimension-mode");
        let isAzimuth = L.DomUtil.hasClass(target, "azimuth-mode");
        // console.log(`isDimension?:${isDimension} - isAzimuth?:${isAzimuth}`)

        this._measurementLayer.clearLayers();
        if (latLngs.length > 1 && (isDimension || isAzimuth)) {
          formatter = L.bind(this.formatDistance, this);
          let sumEdges = 0;
          for (let i = 1, len = latLngs.length; i <= len; i++) {
            ll1 = latLngs[i - 1];
            ll2 = latLngs[i % len];
            let _p1 = this._map.project(ll1),
              _p2 = this._map.project(ll2);

            let sum = (_p2.x - _p1.x) * (_p2.y + _p1.y);
            sumEdges += sum;
          }
          let isClockWise = sumEdges <= 0;

          for (let i = 1, len = latLngs.length; i <= len; i++) {
            ll1 = latLngs[i - 1];
            ll2 = latLngs[i % len];
            dist = ll1.distanceTo(ll2);
            totalDist += dist;

            p1 = this._map.latLngToLayerPoint(ll1);
            p2 = this._map.latLngToLayerPoint(ll2);

            pixelDist = p1.distanceTo(p2);

            if (pixelDist >= options.minPixelDistance) {
              let _p1 = this._map.project(ll1),
                _p2 = this._map.project(ll2);
              let rotation = Math.atan((_p2.y - _p1.y) / (_p2.x - _p1.x));
              let mode = isDimension ? "dimension" : "azimuth";
              let title = isDimension ? options.lang.segmentLength : options.lang.segmentAzimuth;
              let sp = this._map.layerPointToLatLng([(p1.x + p2.x) / 2, (p1.y + p2.y) / 2]);
              L.marker.measurement(sp, formatter(dist), title, rotation, options, _p2.x > _p1.x, _p2.y > _p1.y, mode, isClockWise).addTo(this._measurementLayer);
            }
          }

          // Show total length for polylines
          if (!isPolygon) {
            L.marker.measurement(ll2, formatter(totalDist), options.lang.totalLength, 0, options).addTo(this._measurementLayer);
          }
        }
        return this;
      },

      formatDistance: function(d) {
        let unit, feet;

        if (this._measurementOptions.imperial) {
          feet = d / 0.3048;
          d = feet;
          unit = "ft";
        } else {
          if (d > 1000) {
            d = d / 1000;
            unit = "km";
          } else {
            unit = "m";
          }
        }

        if (d < 100) {
          return d.toFixed(1) + " " + unit;
        } else {
          return Math.round(d) + " " + unit;
        }
      },

      formatArea: function(a) {
        let unit;

        if (this._measurementOptions.imperial) {
          if (a > 404.685642) {
            a = a / 4046.85642;
            unit = "ac";
          } else {
            a = a / 0.09290304;
            unit = "ft²";
          }
        } else {
          if (a > 1000000) {
            a = a / 1000000;
            unit = "km²";
          } else {
            unit = "m²";
          }
        }

        if (a < 100) {
          return a.toFixed(1) + " " + unit;
        } else {
          return Math.round(a) + " " + unit;
        }
      },

      ringArea: function ringArea(coords) {
        let rad = function rad(_) {
          return (_ * Math.PI) / 180;
        };
        let p1,
          p2,
          p3,
          lowerIndex,
          middleIndex,
          upperIndex,
          area = 0,
          coordsLength = coords.length;

        if (coordsLength > 2) {
          for (let i = 0; i < coordsLength; i++) {
            if (i === coordsLength - 2) {
              // i = N-2
              lowerIndex = coordsLength - 2;
              middleIndex = coordsLength - 1;
              upperIndex = 0;
            } else if (i === coordsLength - 1) {
              // i = N-1
              lowerIndex = coordsLength - 1;
              middleIndex = 0;
              upperIndex = 1;
            } else {
              // i = 0 to N-3
              lowerIndex = i;
              middleIndex = i + 1;
              upperIndex = i + 2;
            }
            p1 = coords[lowerIndex];
            p2 = coords[middleIndex];
            p3 = coords[upperIndex];
            area += (rad(p3.lng) - rad(p1.lng)) * Math.sin(rad(p2.lat));
          }

          area = (area * 6378137 * 6378137) / 2;
        }

        return Math.abs(area);
      },
    });

    const jsx = (azimuth) => (
      // PUT YOUR JSX FOR THE COMPONENT HERE:
      <div className="azimuth-holder">
        <img
          id={azimuth}
          src={vertex_white}
          className="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive leaflet-marker-draggable azimuth-holder"
          style={{
            width: "100%",
            height: "20px",
            opacity: "0",
            zIndex: "10000",
          }}
        />
        <div className="azimuth-centered">
          {azimuth}
          <span>°</span>
        </div>
      </div>
    );
    L.Marker.Measurement = L.Marker.extend({
      options: {
        pane: "markerPane",
      },

      initialize: function(latlng, measurement, title, rotation, options, inside, movingUp, mode, isClockWise) {
        L.setOptions(this, options);
        this._latlng = latlng;
        this._measurement = measurement;
        this._title = title;
        this._inside = inside;
        this._movingUp = movingUp;
        this._rotation = rotation;
        this._rot_def = Math.round(180 + (rotation * 180) / Math.PI).toFixed(0);
        this._mode = mode;
        this._clockwise = isClockWise;
      },

      addTo: function(map) {
        map.addLayer(this);
        return this;
      },

      onAdd: function(map) {
        this._map = map;
        let pane = this.getPane ? this.getPane() : map.getPanes().markerPane;
        let el = (this._element = L.DomUtil.create("div", "leaflet-zoom-animated leaflet-measure-path-measurement", pane));
        el.style["position"] = "absolute";
        el.style["fontSize"] = "12px";
        el.style["color"] = "black";
        el.style["font-weight"] = "bold";
        el.style["white-space"] = "nowrap";
        el.style["transform-origin"] = "0";
        el.style["pointer-events"] = "none";
        el.style["width"] = "40px";
        el.style["text-align"] = "center";

        let inner = L.DomUtil.create("div", "leaflet-interactive", el);
        inner.title = this._title;

        if (this._mode == "dimension") {
          inner.innerHTML = this._measurement;
        } else if (this._mode == "azimuth") {
          inner.innerHTML = this._rot_def;
          ReactDOM.render(jsx(this._rot_def), inner);
        }

        inner.style["position"] = "relative";
        inner.style["background"] = "#ffffffb0";
        inner.style["color"] = "black";
        inner.style["font-weight"] = "bold";

        if (this._inside && !this._movingUp) {
          inner.style["left"] = `${-35}%`;
          inner.style["top"] = !this._clockwise ? "15px" : "-25px";
        } else if (this._inside && this._movingUp) {
          inner.style["left"] = `${-55}%`;
          inner.style["top"] = !this._clockwise ? "15px" : "-25px";
        } else if (!this._inside && !this._movingUp) {
          inner.style["left"] = `${-55}%`;
          inner.style["top"] = !this._clockwise ? "-25px" : "15px";
        } else {
          inner.style["left"] = `${-55}%`;
          inner.style["top"] = !this._clockwise ? "-25px" : "15px";
        }

        map.on("zoomanim", this._animateZoom, this);

        this._setPosition();
      },

      onRemove: function(map) {
        map.off("zoomanim", this._animateZoom, this);
        let pane = this.getPane ? this.getPane() : map.getPanes().markerPane;
        pane.removeChild(this._element);
        this._map = null;
      },

      _setPosition: function() {
        let point = this._map.latLngToLayerPoint(this._latlng);

        L.DomUtil.setPosition(this._element, point);
        this._element.style.transform += " rotate(" + this._rotation + "rad)";
      },

      _animateZoom: function(opt) {
        let pos = this._map._latLngToNewLayerPoint(this._latlng, opt.zoom, opt.center).round();
        L.DomUtil.setPosition(this._element, pos);
        this._element.style.transform += " rotate(" + this._rotation + "rad)";
      },
    });

    L.marker.measurement = function(latLng, measurement, title, rotation, options, inside, movingUp, mode, isClockWise) {
      return new L.Marker.Measurement(latLng, measurement, title, rotation, options, inside, movingUp, mode, isClockWise);
    };
  }, []);

  const onKeyDown = (e) => {
    let keyCode = e.keyCode || e.which;

    // check 3 windows that require typing
    if (showProjectLoader.current === true || searchToolActive.current === true || bugReportActive.current === true) return;

    if (((currentDrawingPolygon.current && e.metaKey === true) || e.ctrlKey === true) && keyCode === 90) {
      currentDrawingPolygon.current.deleteLastVertex();
    }

    // B pressed - set as boundary
    if (selectedFeatureId.current) {
      if (keyCode == 66) {
        updateIdentity(1);
      }

      // E pressed - set as exclusion
      if (keyCode == 69) {
        updateIdentity(2);
      }

      // I pressed - set as inactive
      if (keyCode == 73) {
        updateIdentity(0);
      }
    }

    // D press - activate draw tool
    // if D is pressed and the search bar is open the draw tool wont activate
    if (keyCode == 68) {
      const theMap = document.querySelector("#leaflet-map");
      if (theMap === document.activeElement && !drawToolActive.current) {
        // D pressed - start Drawing
        handleDraw();
      } else {
        cancelDraw();
      }
      // drawToolActive.current ? cancelDraw() : handleDraw();
    }

    // N pressed - cycle through boundariess
    if (keyCode == 78) {
      if (Object.keys(features.current).length > 1) {
        let ids = Object.keys(features.current);
        let current_index = ids.findIndex((id) => id == selectedFeatureId.current);
        let next_index = current_index + 1 == ids.length ? 0 : current_index + 1;
        dispatch(portalActions.selectFeature(ids[next_index]));
      }
    }
    // ESC pressed - unhighlight layer, and stop editing it
    if (keyCode == 27) {
      unhighlightLayer();
      // if (portal.inputs.selectedFeatureId) stopEditing();
      if (searchToolActive) dispatch(portalActions.updateUIControls("searchToolActive", false));
      if (selectedFeatureId.current) dispatch(portalActions.selectFeature(undefined));
      if (drawToolActive.current) cancelDraw();
    }

    // check to see if control or CMD is pressed
    if ((e.metaKey && e.shiftKey == false) || (e.ctrlKey && e.shiftKey == false)) {
      // DEL pressed - delete the selected feature/polygon from the store
      // console.log('keycode', selectedFeatureId.current);

      if ((keyCode == 8 || keyCode == 46) && selectedFeatureId.current) {
        dispatch(portalActions.deleteFeature(selectedFeatureId.current));
        dispatch(inputsActions.update_site_input("remove_site_feature", selectedFeatureId.current));
      }
    }
  };

  const onMouseClick = (e) => {
    if (e.button === 1) {
      handleZoomExtents();
    }

    if (e.which == 1 && menuVisible.current && e.target && !e.target.className.includes("ant-menu-item")) {
      // setMenuVisible(false);
      menuVisible.current = false;
      forceUpdate();
      return false;
    }

    if (e.which == 2) {
      // console.log('right mouse click')
      e.view.L.DomEvent.stopPropagation(e);
      e.preventDefault();
      if (Object.keys(portal.inputs.features).length > 0 && !portal.uiControls.isDrawing) {
        let click = Date.now();
        let diff = click - clicked;
        setClicked(click);
        if (diff < 200) {
          handleZoomExtents();
        }
      }
      return false;
    }

    return true;
  };

  const updateIdentity = (identity, polygon = undefined, e = undefined) => {
    if (e) {
      e.nativeEvent.view.L.DomEvent.stopPropagation(e);
      e.nativeEvent.preventDefault();
    }

    if (polygon == undefined) {
      polygon = features.current[selectedFeatureId.current];
    }

    polygon.properties.identity = identity;
    if (identity == 0) polygon.properties.active = !polygon.properties.active;
    else polygon.properties.active = true;

    // let layer = getLayer(polygon.properties.index);
    // layer.setStyle({ color: colors[identity] });

    currentLayer.current = getLayer(polygon.properties.index);
    currentLayer.current.setStyle({ color: colors[identity] });
    forceUpdate();

    dispatch(portalActions.updatePolygon(polygon));
    dispatch(inputsActions.update_site_input("edit_site_feature", polygon));
  };

  const create_UUID = () => {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  };

  const handleToggleDims = () => {
    showDims.current = !showDims.current;
    showAzimuth.current = false;
    forceUpdate();

    let target = L.DomUtil.get("app");
    if (showDims.current) {
      L.DomUtil.removeClass(target, "azimuth-mode");
      L.DomUtil.addClass(target, "dimension-mode");

      // L.DomUtil.addClass(document.body, 'dimension-mode');
      // L.DomUtil.removeClass(document.body, 'azimuth-mode');
    } else {
      L.DomUtil.removeClass(target, "dimension-mode");
      // L.DomUtil.removeClass(document.body, 'dimension-mode');
    }
    if (portal.inputs.selectedFeatureId) {
      // let layer = currentEditingLayer;
      // stopEditing(layer);
      dispatch(portalActions.selectFeature(undefined));
    }
  };

  const handleToggleAzi = () => {
    showDims.current = false;
    showAzimuth.current = !showAzimuth.current;
    forceUpdate();

    let target = L.DomUtil.get("app");
    if (showAzimuth.current) {
      L.DomUtil.removeClass(target, "dimension-mode");
      L.DomUtil.addClass(target, "azimuth-mode");

      // L.DomUtil.addClass(document.body, 'azimuth-mode');
      // L.DomUtil.removeClass(document.body, 'dimension-mode');
    } else {
      L.DomUtil.removeClass(target, "azimuth-mode");
      // L.DomUtil.removeClass(document.body, 'azimuth-mode');
    }
    if (portal.inputs.selectedFeatureId) {
      let layer = currentEditingLayer;
      // stopEditing(layer);
      dispatch(portalActions.selectFeature(undefined));
    }
  };

  const updateResultColumnFilter = (checked, item) => {
    // console.log(checked, item, currentSelectedResult)
    currentSelectedResult.current = {
      ...currentSelectedResult.current,
      [item.key]: {
        ...item,
        hide: !checked,
      },
    };
    forceUpdate();
  };

  const onContextMenu = (e) => {
    let map = document.getElementById("leaflet-map");
    let menuHeight = 224;
    let menuWidth = 150;
    // console.log(e)
    //pollyfill
    let path = e.path || (e.composedPath && e.composedPath());
    // determine area clicked for context
    const clickInsideSingleResultWindow = path.find(function(element) {
      return element.className === "leaflet-bottom leaflet-left";
    });

    // control panel display col menu
    if (e.which === 3 && clickInsideSingleResultWindow) {
      // stop default context menu
      e.view.L.DomEvent.stopPropagation(e);
      e.preventDefault();

      const content = (
        <div>
          {Object.values(currentSelectedResult.current).map((item, index) => {
            // see if we have formatting
            const formatEntry = selectedResultFormat.keysformats.find((entry) => entry.key === item.key);
            return (
              <div key={index}>
                <Checkbox onChange={(e) => updateResultColumnFilter(e.target.checked, item)} defaultChecked={!item.hide}>
                  <label>{formatEntry && formatEntry.displayLabel ? formatEntry.displayLabel : item.key}</label>
                </Checkbox>
              </div>
            );
          })}
        </div>
      );

      Modal.info({
        title: "Select Display Columns",
        content: content,
        icon: <UnorderedListOutlined style={{ color: "#60de4f" }} />,
      });

      return false;
    }

    // normal map context menu
    if (e.which === 3 && !clickInsideSingleResultWindow) {
      let openSunfigMenu = false;
      let clickedPolygon = false;
      let azimuth = 0;

      if (e.target.classList.contains("azimuth-holder")) {
        openSunfigMenu = true;
        azimuth = parseInt(e.target.id);
      }
      // setShowAzimuth(azimuth);
      selectedAzimuth.current = azimuth;

      if (e.target.nodeName == "DIV") {
        if (e.target.className.includes("leaflet")) {
          openSunfigMenu = true;
        }
      }

      if (e.target.nodeName == "path") {
        if (e.target.outerHTML.includes("leaflet")) {
          openSunfigMenu = true;
          clickedPolygon = true;
        }
      }

      if (openSunfigMenu) {
        if (clickedPolygon && leafletMap.current) {
          menuHeight = 250;
          // get latlng of mouse click
          let latlng = leafletMap.current.leafletElement.mouseEventToLatLng(e);
          // turn into turf point
          let clickPoint = turf.point([latlng.lat, latlng.lng]);
          let intersectingLayers = [];

          Object.values(features.current).forEach((feature) => {
            let poly = turf.flip(feature);
            let contained = turf.booleanContains(poly, clickPoint);
            if (contained) {
              intersectingLayers.push(feature);
            }
          });
          // setMenuFeatures(intersectingLayers);
          menuFeatures.current = intersectingLayers;
        } else {
          // setMenuFeatures([]);
          menuFeatures.current = [];
        }

        e.view.L.DomEvent.stopPropagation(e);
        e.preventDefault();
        // let x = e.layerX;
        // let y = e.layerY;
        // let x = e.clientX;
        // let y = e.clientY;
        // setMenuX(x);
        // setMenuY(y);
        let mapWidth = map.clientWidth;
        let mapHeight = map.clientHeight;
        let menuXPos = e.clientX;
        let menuYPos = e.clientY;

        if (menuYPos + menuHeight > mapHeight) {
          menuYPos = menuYPos - menuHeight;
        }

        if (menuXPos + menuWidth > mapWidth) {
          menuXPos = menuXPos - menuWidth;
        }

        menuX.current = menuXPos;
        menuY.current = menuYPos;
        // setMenuVisible(!menuVisible);
        menuVisible.current = !menuVisible.current;

        forceUpdate();
      }
    }
    return false;
  };

  const toggleOffsetPopup = (poly) => {
    let currentPoly = poly == undefined ? features.current[selectedFeatureId.current] : poly;

    unhighlightLayer(currentPoly);

    if (offsetToolVisible == false) {
      dispatch(portalActions.toggleOffsetTools(true, currentPoly.properties.index));
      menuVisible.current = false;
    } else {
      dispatch(portalActions.toggleOffsetTools(false, currentPoly.properties.index));
      menuVisible.current = false;
    }
  };

  const handleTogglePolygon = (event) => {
    event.domEvent.view.L.DomEvent.stopPropagation(event.domEvent);
    event.domEvent.preventDefault();
    // console.log(currentLayer.current);
    if (currentLayer.current) {
      currentLayer.current = undefined;
      forceUpdate();
    }

    setPolygonStates({ ...polygonStates, [event.key]: !polygonStates[event.key] });
  };

  const handleToggleTopoLayer = (event) => {
    event.domEvent.view.L.DomEvent.stopPropagation(event.domEvent);
    event.domEvent.preventDefault();

    let new_topo_layer = topoStates;
    new_topo_layer[topoMode] = !new_topo_layer[topoMode];
    new_topo_layer[event.key] = !new_topo_layer[event.key];

    setTopoOn(event.key != "Off");
    setTopoMode(event.key);
    setTopoStates({ ...new_topo_layer });

    handleUpdateTopoMode(TopoTranslations[event.key]);
  };

  const handleUpdateTopoMode = (mode) => {
    // console.log(mode, portal.topoData.topo_live, portal.topoData.topo_id);
    if (inputs.topo_id) {
      let mode_ext = mode;
      let scale = "scale.png";
      if (mode == "NS" || mode == "EW" || mode == "U" || mode == "CF") {
        if (!inputs[TopoModeMapper[mode]].ungraded_avail) return;
        mode_ext = `${mode}/${inputs[TopoModeMapper[mode]].ungraded_percent}`;
      }
      if (mode == "NS/G" || mode == "EW/G" || mode == "U/G") {
        let g_mode = mode.replace("/G", "");
        if (!inputs[TopoModeMapper[mode]].graded_avail) return;
        mode_ext = `${mode}/${inputs[TopoModeMapper[mode]].graded_percent}`;
      }
      // console.log(mode_ext);
      let data = {
        topo_mode: mode,
        // topo_url: `https://topo.xyz.s3-website.us-east-2.amazonaws.com/test/${this.props.topoData.topo_id}/${mode_ext}/{z}/{x}/{y}.png`,
        // topo_scale_url: `https://topo.xyz.s3-website.us-east-2.amazonaws.com/test/${this.props.topoData.topo_id}/${mode_ext}/scale.png`
        // topo_url: `https://s3.us-east-2.amazonaws.com/topo.xyz/test/${portal.topoData.topo_id}/${mode_ext}/{z}/{x}/{y}.png`,
        // topo_scale_url: `https://s3.us-east-2.amazonaws.com/topo.xyz/test/${portal.topoData.topo_id}/${mode_ext}/scale.png`,
        topo_url: `https://topo-tiles.sunfig.com/test/${inputs.topo_id}/${mode_ext}/{z}/{x}/{y}.png`,
        topo_scale_url: `https://topo-tiles.sunfig.com/test/${inputs.topo_id}/${mode_ext}/scale.png`,
      };
      dispatch(portalActions.updateTopoMode(data));
      dispatch(inputsActions.update_input("topo_mode", mode));
      dispatch(inputsActions.update_input("topo_url", `https://topo-tiles.sunfig.com/test/${inputs.topo_id}/${mode_ext}/{z}/{x}/{y}.png`));
      dispatch(inputsActions.update_input("topo_scale_url", `https://topo-tiles.sunfig.com/test/${inputs.topo_id}/${mode_ext}/scale.png`));
    }
  };

  const getArea = (coords) => {
    let turfPoly = turf.polygon(coords);
    return turf.area(turfPoly) / 1000000;
  };

  const breakMultiPolygon = (mp) => {
    let polys = [];
    mp[0].map((poly) => {
      polys.push(poly);
    });
    return polys;
  };

  const unhighlightLayer = (_poly = undefined) => {
    let poly = _poly || highlightPoly;
    if (poly) {
      currentLayer.current = getLayer(poly.properties.index);
      currentLayer.current.setStyle({ weight: 1, fillOpacity: 0.01, fillColor: "#ffffff" });
    }
    setHighlightPoly(undefined);
  };

  const highlightLayer = (poly) => {
    currentLayer.current = getLayer(poly.properties.index);
    currentLayer.current.setStyle({ weight: 3, fillOpacity: 0.5, fillColor: "#60de4f" });
    setHighlightPoly(poly);
  };

  const handleExportKML = () => {
    let layers = [];
    leafletMap.current.leafletElement.eachLayer((layer) => {
      let feature = (layer.feature = layer.feature || {});
      feature.type = "Feature";
      feature.properties = feature.properties || {};
      if (layer.options && layer.options.interactive && layer.options.data) {
        // Boundary or Exclusion
        if (layer.options.data.properties.identity == 1) {
          // Boundary
          feature.name = "Boundary";
          feature.properties["title"] = "Boundary";
          feature.properties["fill-opacity"] = 0.0;
          feature.properties["fill"] = "#000000";
          feature.properties["stroke-width"] = 3;
          feature.properties["stroke"] = "#ff0001";
        } else if (layer.options.data.properties.identity == 2) {
          // Exclusion
          feature.name = "Exclusion";
          feature.properties["title"] = "Exclusion";
          feature.properties["fill-opacity"] = 0.0;
          feature.properties["fill"] = "#000000";
          feature.properties["stroke-width"] = 3;
          feature.properties["stroke"] = "ffff03";
        } else if (layer.options.data.properties.identity == 0) {
          // Boundary
          feature.name = "Boundary-Unused";
          feature.properties["title"] = "Boundary-Unused";
          feature.properties["fill-opacity"] = 0.0;
          feature.properties["fill"] = "#000000";
          feature.properties["stroke-width"] = 3;
          feature.properties["stroke"] = "#ffffff";
        }
        layers.push(layer);
      } else {
        if (layer.options.id < 0 && layer.options.positions[0]?.length != 0 && layer.options.positions[0][0]?.length != 0) {
          if (layer.options.id == -999) {
            // Autolayout
            feature.name = "Autolayout";
            feature.properties["title"] = "Autolayout";
            feature.properties["description"] = "This is the layout";
            if (currentSelectedResult.current) {
              Object.keys(currentSelectedResult.current).forEach((key) => {
                feature.properties[key] = currentSelectedResult.current[key];
              });
            }
            feature.properties["fill-opacity"] = layer.options.opacity ? layer.options.opacity : 0;
            feature.properties["fill"] = layer.options.fillColor;
            feature.properties["stroke-width"] = layer.options.weight;
            feature.properties["stroke"] = layer.options.color;
          } else {
            // Road
            feature.name = "Road";
            feature.properties["title"] = "Road";
            feature.properties["description"] = "This is an auto placed road";
            feature.properties["fill-opacity"] = 0.0;
            feature.properties["fill"] = "#000000";
            feature.properties["stroke-width"] = 3;
            feature.properties["stroke"] = "#e07111";
          }
          if (layer._latlngs.length >= 1) {
            layers.push(layer);
          }
        }
      }
    });
    let allLayers = L.layerGroup(layers);
    let featureCollection = allLayers.toGeoJSON();

    let kml = tokml(featureCollection, {
      documentName: "RBI KML Export",
      documentDescription: "Export from RBI Map",
      simplestyle: true,
    });

    let blob = new Blob([kml], { type: "xml;encoding=UTF-8" });
    FileSaver.saveAs(blob, `RBI-Portal-Map-${portal.uiControls.project_name}.kml`);
  };

  const handleDraw = () => {
    let options = {
      shapeOptions: {
        stroke: true,
        color: "white",
        opacity: 1.0,
        weight: 4,
        fillColor: "#FFFFFF",
      },
      icon: new L.Icon({
        iconUrl: vertex_green,
        iconSize: [16, 16],
        iconAnchor: [7, 7],
      }),
      showMeasurements: showDims.current,
    };
    if (!portal.uiControls.isDrawing) {
      var polygon = new L.Draw.Polygon(leafletMap.current.leafletElement, options);

      polygon.enable();
      currentDrawingPolygon.current = polygon;
      dispatch(portalActions.updateUIControls("isDrawing", true));
    } else {
      cancelDraw();
    }
  };
  const handleDrawTree = () => {
    let options = {
      shapeOptions: {
        stroke: true,
        color: "green",
        opacity: 1.0,
        weight: 2,
        fillColor: "green",
      },
      icon: new L.Icon({
        iconUrl: vertex_white,
        iconSize: [14, 14],
        iconAnchor: [7, 7],
      }),
      // showMeasurements: showDims.current,
    };
    if (!portal.uiControls.isDrawing) {
      var circle = new L.Draw.Circle(leafletMap.current.leafletElement, options);

      circle.enable();
      console.log("circle", circle);
      // currentDrawingPolygon.current = polygon;
      // dispatch(portalActions.updateUIControls('isDrawing', true));
    } else {
      cancelDraw();
    }
  };

  const cancelDraw = () => {
    if (drawToolActive.current) {
      if (currentDrawingPolygon.current) currentDrawingPolygon.current.disable();
      dispatch(portalActions.updateUIControls("isDrawing", false));
      // setCurrentDrawingPolygon({});
    }
  };

  const handlePolygonCreated = (e) => {
    let new_id = create_UUID();

    let latlngs = e.layer.getLatLngs()[0];
    let coords = [];
    latlngs.forEach((latlng) => {
      let wrapped = latlng.wrap();
      coords.push([wrapped.lat, wrapped.lng]);
    });
    coords.push(coords[0]);
    let real_poly = turf.flip(turf.polygon([coords]));

    let geoJson = e.layer.toGeoJSON();

    geoJson.properties["index"] = new_id;
    geoJson.properties["identity"] = 1;
    geoJson.properties["active"] = true;
    geoJson.properties["area"] = getArea(geoJson.geometry.coordinates);
    geoJson.properties["center"] = turf.getCoords(turf.centroid(real_poly));

    setPolygonStates(JSON.parse(JSON.stringify(basePolyStates)));

    dispatch(inputsActions.update_site_input("add_site_feature", geoJson));

    dispatch(portalActions.createPolygon(geoJson, new_id));
    dispatch(portalActions.setCounty(geoJson.properties["center"][1], geoJson.properties["center"][0]));
    // Remove this layer - we have a different styling for creating polygons
    leafletMap.current.leafletElement.removeLayer(e.layer);
    // setMenuVisible(false);
    menuVisible.current = false;
    forceUpdate();
  };

  const handleEditVertex = (e) => {
    let geoJson = e.poly.toGeoJSON();
    geoJson.properties["area"] = getArea(geoJson.geometry.coordinates);
    dispatch(portalActions.updatePolygon(geoJson));
    dispatch(inputsActions.update_site_input("edit_site_feature", geoJson));
  };

  const _onDrawVertex = () => {
    let options = {
      shapeOptions: {
        stroke: true,
        color: "white",
        opacity: 1.0,
        weight: 4,
        fillColor: "#FFFFFF",
      },
      icon: new L.Icon({
        iconUrl: vertex_white,
        iconSize: [12, 12],
        iconAnchor: [6, 6],
      }),
    };
  };

  const handleZoomExtents = (zoomLevel = undefined) => {
    if (Object.values(features.current).length == 0) return;

    let bounds = getBounds(Object.values(features.current));
    if (zoomLevel) {
      leafletMap.current.leafletElement.fitBounds(
        [
          [bounds[1], bounds[0]],
          [bounds[3], bounds[2]],
        ],
        { maxZoom: zoomLevel }
      );
    } else {
      leafletMap.current.leafletElement.fitBounds([
        [bounds[1], bounds[0]],
        [bounds[3], bounds[2]],
      ]);
    }
  };

  const handleCaptureReportImages = (key = undefined) => {
    generatingReport.current = true;
    // let poly = key && turf.getCoords(turf.flip(portal.inputs.features[key].geoJson));
    // imageTarget.current = poly;
    handleZoomExtents(18);
    // forceUpdate();
    let location = leafletMap.current.leafletElement.getCenter();

    setTimeout(async () => {
      let value = await getPngUrl();
      let url = { key: key || "overall", value };

      // let new_list = key && carport.prepareReport.filter((id) => id != key);
      dispatch(portalActions.prepareGroundReport(portal.report.reportData, url, true, location));

      if (key == undefined) {
        generatingReport.current = false;
        takingPicture.current = false;
        forceUpdate();
      }
    }, 1250);
  };

  const getPngUrl = async () => {
    return new Promise((resolve, reject) => {
      let width = leafletMap.current.leafletElement._size.x;
      let height = leafletMap.current.leafletElement._size.y;
      let node = document.getElementById("leaflet-map");
      domtoimage
        .toJpeg(node, { width, height, quality: 0.5 })
        .then((url) => resolve({ url, width, height }))
        .catch((error) => reject(error));
    });
  };

  const printMap = async () => {
    menuVisible.current = false;
    takingPicture.current = true;
    forceUpdate();

    let width = leafletMap.current.leafletElement._size.x;
    let height = leafletMap.current.leafletElement._size.y;
    var node = document.getElementById("leaflet-map");

    domtoimage
      .toBlob(node, { width, height, cacheBust: true })
      .then(
        function(blob) {
          window.saveAs(blob, `RBI-Portal-Export.png`);
          takingPicture.current = false;
          forceUpdate();
        }.bind(this)
      )
      .catch(() => {
        takingPicture.current = false;
        forceUpdate();
      });
  };

  const getLayer = (id = undefined) => {
    let refLayer = getRef(id);
    if (refLayer && refLayer.current) {
      let leaflet_index = refLayer.current.leafletElement._leaflet_id - 1;
      return refLayer.current.leafletElement._layers[leaflet_index];
    } else {
      return undefined;
    }
  };

  const startEditing = (id) => {
    currentLayer.current = getLayer(id);
    if (currentLayer.current == undefined) return;
    currentLayer.current.editing.enable();
  };

  const stopEditing = (id) => {
    // id provided, disable it
    if (currentLayer.current == undefined || (currentLayer.current && currentLayer.current.feature.properties.index != id)) {
      currentLayer.current = getLayer(id);
    }
    if (currentLayer.current) {
      currentLayer.current.editing.disable();
      currentLayer.current = undefined;
    }
  };

  const handleMapClicked = (e) => {
    if (portal.uiControls.isDrawing) {
      return;
    }
    if (searchToolActive) {
      dispatch(portalActions.updateUIControls("searchToolActive", false));
    }

    if (portal.inputs.selectedFeatureId && !menuVisible.current) {
      dispatch(portalActions.selectFeature(undefined));
    }
  };

  const handlePolygonClicked = (e, id) => {
    // stop other mouse events on leaflet map
    if (e.originalEvent) e.originalEvent.view.L.DomEvent.stopPropagation(e);
    if (e.nativeEvent) e.nativeEvent.view.L.DomEvent.stopPropagation(e);

    menuVisible.current = false;
    forceUpdate();
    if (currentLayer && highlightPoly) {
      unhighlightLayer();
    }

    // prevent trying to select the same rectangle
    if (portal.inputs.selectedFeatureId == id) return;

    dispatch(portalActions.selectFeature(id));
  };

  const handleSetAzimuth = () => {
    if (!isNaN(selectedAzimuth.current)) {
      menuVisible.current = false;
      forceUpdate();
      dispatch(portalActions.updateInput("azimuth", selectedAzimuth.current));
      // this.props.OnSetAzimuth(this.state.selectedAzimuth);
    }
  };

  // *** some initial map settings ***
  let position = [portal.inputs.map_center.lat, portal.inputs.map_center.lng];
  // create topo_bounds if available
  let southWest = portal.inputs.boundary_bbox && L.latLng(portal.inputs.boundary_bbox[1] - 0.0009, portal.inputs.boundary_bbox[0] - 0.0009),
    northEast = portal.inputs.boundary_bbox && L.latLng(portal.inputs.boundary_bbox[3] + 0.0009, portal.inputs.boundary_bbox[2] + 0.0009),
    boundary_bbox = portal.inputs.boundary_bbox && L.latLngBounds(southWest, northEast);

  return (
    <GroundMountMapWrap key="ground_mount_map" ref={mapContainer} showLiveReport={portal.uiControls.show_live_report}>
      <Map
        ref={leafletMap}
        id="leaflet-map"
        key="leaflet-map"
        showDims={showDims.current}
        animate={true}
        noWrap={true}
        attributionControl={false}
        center={position}
        zoom={portal.inputs.map_zoom}
        minZoom={0}
        // maxZoom={25}

        zoomControl={false}
        zoomSnap={portal.inputs.zoomGranularity}
        zoomDelta={portal.inputs.zoomGranularity}
        onzoomend={() => dispatch(portalActions.updateMapZoom(leafletMap.current.leafletElement.getZoom()))}
        style={{ width: "100%", height: "100%", touchAction: "none" }}
        onClick={handleMapClicked}
      >
        {bingLayerVisible && <BingLayer bingkey={bing_key} type={TileSets[activeTileSet]} maxZoom={25} maxNativeZoom={18} />}
        {/* <TileLayer url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}' 
          errorTileUrl="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" 
          crossOrgin 
          maxZoom={25}
          maxNativeZoom={18}
          options={{maxZoom: 25, maxNativeZoom: 18}} 
        /> */}
        {/* !takingPicture.current &&  */}
        {inputs.topo_id && inputs.topo_mode != "Off" && (
          <TileLayer
            // url='http://topo.xyz.s3-website.us-east-2.amazonaws.com/test/e5f0ab4e3c1c45d9b38412126ef43b83/{z}/{x}/{y}.png'
            url={inputs.topo_url}
            opacity={0.7}
            tms
            zIndex={10}
            // bounds={boundary_bbox}
            options={{ errorTileUrl: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" }}
            crossOrgin
            tileerror={(e) => console.log("Error loading tile, probably nothing exists", e)}
          />
        )}

        {!takingPicture.current && (
          <Fragment key="map_toolbars">
            {/* Tool Bars */}
            <LeafletControl position="topleft">
              <GroundMountToolbar handleDraw={handleDraw} handleDrawTree={handleDrawTree} cancelDraw={cancelDraw} stopEditing={stopEditing} handleExportKML={handleExportKML} printMap={printMap} />
            </LeafletControl>

            {/* zoom, zoom extents and layers tools */}
            <LeafletControl position="topright" className="layer-control">
              <ZoomAndLayerControl mode={"Ground"} tileSets={TileSets} activeTileSet={activeTileSet} setActivetileSet={onChangeTileset} handleZoomExtents={handleZoomExtents} />
            </LeafletControl>
          </Fragment>
        )}

        {menuVisible.current && (
          <LeafletControl position="controlmenu" key="leaflet_toolbars">
            <div style={{ left: menuX.current, top: menuY.current + 1, opacity: 1, position: "fixed" }}>
              <Menu defaultSelectedKeys={[]} defaultOpenKeys={[]} mode="vertical" theme="light" subMenuCloseDelay={2}>
                {selectedAzimuth.current > 0 && (
                  <Menu.Item
                    key="0"
                    onClick={() => {
                      handleSetAzimuth();
                    }}
                  >
                    <CheckOutlined />
                    <span>Set Project Azimuth to {selectedAzimuth.current} °</span>
                  </Menu.Item>
                )}

                <Menu.Item
                  key="3"
                  onClick={() => {
                    handleDraw();
                    menuVisible.current = false;
                    forceUpdate();
                  }}
                >
                  <EditOutlined />
                  <span>Draw</span>
                </Menu.Item>

                <Menu.SubMenu
                  title={
                    <span>
                      <GlobalOutlined />
                      <span>Topo Mode</span>
                    </span>
                  }
                  // onTitleClick={(eventKey, domEvent) => {
                  //   console.log('title clicked', { eventKey, domEvent });
                  // }}
                  children={Object.keys(topoStates).map((key) => {
                    return (
                      <Menu.Item key={key} onClick={handleToggleTopoLayer}>
                        {key} {key == topoMode && <CheckOutlined />}
                      </Menu.Item>
                    );
                  })}
                ></Menu.SubMenu>

                <Menu.Item
                  key="1"
                  onClick={() => {
                    handleToggleDims();
                  }}
                >
                  <BuildOutlined />
                  <span>Toggle Dimensions {showDims.current ? "Off" : "On"}</span>
                </Menu.Item>
                <Menu.Item
                  key="2"
                  onClick={() => {
                    handleToggleAzi();
                  }}
                >
                  <FontColorsOutlined />
                  <span>Toggle Azimuth {showAzimuth.current ? "Off" : "On"}</span>
                </Menu.Item>

                <Menu.SubMenu
                  title={
                    <span>
                      <BorderOuterOutlined />
                      <span>Show/Hide</span>
                    </span>
                  }
                  children={Object.keys(polygonStates).map((key) => {
                    return (
                      <Menu.Item key={key} onClick={handleTogglePolygon}>
                        {polygonStates[key] ? <CheckCircleOutlined style={{ color: "green" }} /> : <CloseCircleOutlined style={{ color: "red" }} />}
                        {key}
                        {/* <Switch checked={polygonStates[key]} size="small"/> {key} */}
                      </Menu.Item>
                    );
                  })}
                ></Menu.SubMenu>

                <Menu.Item
                  key="4"
                  onClick={() => {
                    handleExportKML();
                    menuVisible.current = false;
                    forceUpdate();
                  }}
                >
                  <ExportOutlined />
                  <span>Export KMZ</span>
                </Menu.Item>

                <Menu.Item
                  key="5"
                  onClick={() => {
                    printMap();
                  }}
                >
                  <FileImageOutlined />
                  <span>Export Image</span>
                </Menu.Item>

                {menuFeatures.current.map((poly, index) => {
                  return (
                    // console.log(poly.options.data.properties.index)
                    <Menu.SubMenu
                      onMouseEnter={() => highlightLayer(poly)}
                      onMouseLeave={() => unhighlightLayer(poly)}
                      onTitleClick={({ key, domEvent }) => {
                        handlePolygonClicked(domEvent, poly.properties.index);
                      }}
                      key={poly.properties.index}
                      title={
                        <span>
                          <GatewayOutlined />
                          <span>Polygon {index + 1}</span>
                        </span>
                      }
                    >
                      <Menu.Item
                        key={`offset_${poly.properties.index}`}
                        onClick={() => {
                          toggleOffsetPopup(poly);
                        }}
                      >
                        Offset Polygon
                      </Menu.Item>

                      <Menu.Item
                        onClick={(e) => {
                          updateIdentity(1, poly, e.domEvent);
                        }}
                        key={`bound_${poly.properties.index}`}
                      >
                        Set as Boundary
                      </Menu.Item>

                      <Menu.Item
                        key={`exclusion_${poly.properties.index}`}
                        onClick={(e) => {
                          updateIdentity(2, poly, e.domEvent);
                        }}
                      >
                        Set as Exclusion
                      </Menu.Item>

                      <Menu.Item
                        key={`inactive_${poly.properties.index}`}
                        onClick={(e) => {
                          updateIdentity(0, poly, e.domEvent);
                        }}
                      >
                        Set as Inactive
                      </Menu.Item>

                      <Menu.Item
                        key={`delete_${poly.properties.index}`}
                        onClick={() => {
                          dispatch(portalActions.deleteFeature(poly.properties.index));
                          dispatch(inputsActions.update_site_input("remove_site_feature", poly.properties.index));
                          menuVisible.current = false;
                          unhighlightLayer();
                          forceUpdate();
                        }}
                      >
                        Delete
                      </Menu.Item>
                    </Menu.SubMenu>
                  );
                })}
              </Menu>
            </div>
          </LeafletControl>
        )}

        {/* county indicator */}
        {portal.uiControls.county && portal.uiControls.stateAbb && Object.values(portal.inputs.features).length > 0 && (
          <LeafletControl position="bottomright" className="county" key="county">
            <div
              style={{
                height: "25px",
                background: "#ffffffb3",
                borderRadius: "2px",
                color: "#333",
                padding: "5px 10px",
                fontWeight: "bold",
              }}
            >
              <p>{`${portal.uiControls.county} County, ${portal.uiControls.stateAbb}`}</p>
            </div>
          </LeafletControl>
        )}

        {/* topo scale */}
        {!takingPicture.current && inputs.topo_id && (
          <LeafletControl position="topright" className="topo" key="topo">
            {/* <div style={{ marginRight: '35px', marginBottom: '-13px' }}>
              <img src={portal.topoData.topo_scale_url} style={{ height: '350px' }}></img>
            </div> */}
            <div style={{ marginRight: "55px", height: "360px" }}>{inputs.topo_mode != "Off" && <img src={inputs.topo_scale_url}></img>}</div>

            <div className="topo-controls">
              <label className="topo-map-label">
                <b>Topography Controls</b>
              </label>
              <label className="topo-map-label">Current: {TopoKeys[inputs.topo_mode]}</label>
            </div>
            <div className="topo-controls">
              <div style={{ pointerEvents: "all" }}>
                <label className="topo-map-label">Ungraded</label>
                <Tooltip placement="top" title="Elevation" mouseEnterDelay={0.25}>
                  <Button
                    className="topo-button"
                    icon={<MenuUnfoldOutlined />}
                    type={inputs.topo_mode == "ele" ? "primary" : "default"}
                    onClick={() => {
                      handleUpdateTopoMode("ele");
                    }}
                  ></Button>
                </Tooltip>
                <Tooltip placement="top" title="Max Slope" mouseEnterDelay={0.25}>
                  <Button
                    className="topo-button"
                    icon={<BorderInnerOutlined />}
                    type={inputs.topo_mode == "U" ? "primary" : "default"}
                    disabled={!inputs.vis_max.ungraded_avail}
                    onClick={() => {
                      handleUpdateTopoMode("U");
                    }}
                  ></Button>
                </Tooltip>
                <Tooltip placement="top" title="North-South Slope" mouseEnterDelay={0.25}>
                  <Button
                    className="topo-button"
                    icon={<BorderHorizontalOutlined />}
                    type={inputs.topo_mode == "NS" ? "primary" : "default"}
                    disabled={!inputs.vis_ns.ungraded_avail}
                    onClick={() => {
                      handleUpdateTopoMode("NS");
                    }}
                  ></Button>
                </Tooltip>
                <Tooltip placement="top" title="East-West Slope" mouseEnterDelay={0.25}>
                  <Button
                    className="topo-button"
                    icon={<BorderVerticleOutlined />}
                    type={inputs.topo_mode == "EW" ? "primary" : "default"}
                    disabled={!inputs.vis_ew.ungraded_avail}
                    onClick={() => {
                      handleUpdateTopoMode("EW");
                    }}
                  ></Button>
                </Tooltip>
              </div>

              <div style={{ pointerEvents: "all" }}>
                <label className="topo-map-label">Graded</label>
                <Tooltip placement="bottom" title="Elevation Graded" mouseEnterDelay={0.25}>
                  <Button
                    className="topo-button"
                    icon={<MenuUnfoldOutlined />}
                    type={inputs.topo_mode == "ele/G" ? "primary" : "default"}
                    disabled={!inputs.vis_ele.graded_avail}
                    onClick={() => {
                      handleUpdateTopoMode("ele/G");
                    }}
                  ></Button>
                </Tooltip>
                <Tooltip placement="bottom" title="Resultant Slope Graded" mouseEnterDelay={0.25}>
                  <Button
                    className="topo-button"
                    icon={<BorderInnerOutlined />}
                    type={inputs.topo_mode == "U/G" ? "primary" : "default"}
                    disabled={!inputs.vis_max.graded_avail}
                    onClick={() => {
                      handleUpdateTopoMode("U/G");
                    }}
                  ></Button>
                </Tooltip>
                <Tooltip placement="bottom" title="North-South Slope Graded" mouseEnterDelay={0.25}>
                  <Button
                    className="topo-button"
                    icon={<BorderHorizontalOutlined />}
                    type={inputs.topo_mode == "NS/G" ? "primary" : "default"}
                    disabled={!inputs.vis_ns.graded_avail}
                    onClick={() => {
                      handleUpdateTopoMode("NS/G");
                    }}
                  ></Button>
                </Tooltip>
                <Tooltip placement="bottom" title="East-West Slope Graded" mouseEnterDelay={0.25}>
                  <Button
                    className="topo-button"
                    icon={<BorderVerticleOutlined />}
                    type={inputs.topo_mode == "EW/G" ? "primary" : "default"}
                    disabled={!inputs.vis_ew.graded_avail}
                    onClick={() => {
                      handleUpdateTopoMode("EW/G");
                    }}
                  ></Button>
                </Tooltip>
              </div>

              <div>
                <label className="topo-map-label">Cut/Fill</label>
                <Tooltip placement="top" title="Cut/Fill" mouseEnterDelay={0.25}>
                  <Button
                    className="topo-button"
                    icon={<ColumnHeightOutlined />}
                    type={inputs.topo_mode == "CF" ? "primary" : "default"}
                    disabled={!inputs.vis_g.graded_avail}
                    onClick={() => {
                      handleUpdateTopoMode("CF");
                    }}
                  ></Button>
                </Tooltip>
              </div>

              <div>
                <label className="topo-map-label">On/Off</label>
                <Tooltip placement="top" title="Slope Layers Off" mouseEnterDelay={0.25}>
                  <Button
                    className="topo-button"
                    icon={<StopOutlined />}
                    type={inputs.topo_mode == "Off" ? "primary" : "default"}
                    onClick={() => {
                      handleUpdateTopoMode("Off");
                    }}
                  ></Button>
                </Tooltip>
              </div>
            </div>
          </LeafletControl>
        )}

        {/* hotkey context indicator */}
        {Object.values(features.current).length > 0 && selectedFeatureId.current && !portal.uiControls.project_loading && (
          <LeafletControl position="bottomleft" key="hotkeys">
            <div
              style={{
                height: "25px",
                background: "#ffffffb3",
                borderRadius: "2px",
                color: "#333",
                position: "relative",
                top: "5px",
                padding: "5px 10px",
                fontWeight: "bold",
              }}
            >
              <span style={{ color: "rgb(0, 0, 0)" }}>
                {features.current[selectedFeatureId.current]?.properties.identity == 1 ? "Boundary" : features.current[selectedFeatureId.current]?.properties.identity == 2 ? "Exclusion" : "Inactive"}{" "}
                polygon. Set as: [
              </span>
              {features.current[selectedFeatureId.current]?.properties.identity != 1 && (
                <span>
                  <a
                    onClick={() => {
                      updateIdentity(1);
                    }}
                  >
                    <span style={{ color: "#60de4f" }}>B</span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>oundary, </span>
                  </a>
                </span>
              )}
              {features.current[selectedFeatureId.current]?.properties.identity != 2 && (
                <span>
                  <a
                    onClick={() => {
                      updateIdentity(2);
                    }}
                  >
                    <span style={{ color: "#60de4f" }}>E</span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>xclusion</span>
                  </a>
                </span>
              )}
              {features.current[selectedFeatureId.current]?.properties.identity == 1 && <span style={{ color: "rgb(0, 0, 0)" }}>, </span>}
              {features.current[selectedFeatureId.current]?.properties.identity != 0 && (
                <span>
                  <a
                    // href="javascript:void(0);"
                    onClick={() => {
                      updateIdentity(0);
                    }}
                  >
                    <span style={{ color: "#60de4f" }}>I</span>
                    <span style={{ color: "rgb(0, 0, 0)" }}>nactive</span>
                  </a>
                </span>
              )}
              <span style={{ color: "rgb(0, 0, 0)" }}>]</span>
            </div>
          </LeafletControl>
        )}

        {show_sf_modal && <SalesForceCapture />}

        {/* {portal.layout.isLayout && currentSelectedResult.current && !generatingReport.current && (
          <Fragment>
            <LeafletControl position="bottomleft">
              <SingleResultWindow
                drag
                dragConstraints={mapContainer}
                dragMomentum={false}
                onDragStart={() => setSummaryLegendDragged(true)}
                bottomOffset={portal.uiControls.show_results_table && !summaryLegendDragged ? "375px" : "auto"}
              >
                <Card
                  style={{ width: "285px" }}
                  onDragStart={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  className="ground-mount-result"
                >
                  <img src={ts_logo_hori} />
                  {Object.values(currentSelectedResult.current).map((item, index) => {
                    // console.log(item);
                    if (item.hide !== true) {
                      return (
                        <span key={`${index}_selectedResultsItem`}>
                          <label>{item.displayLabel ? `${item.displayLabel}`.substring(0, 11) : `${item.key}`.substring(0, 11)}</label>
                          {`${item.formatPreceding ? item.formatPreceding : ""}${
                            item.displayValue
                              ? item.displayValue
                              : item.key == "capacity"
                              ? parseFloat(item.value / 1000).toFixed(2)
                              : (item.key == "combiner_count" || item.key == "wire_length_ft") && inputs.inverter_grouping == 0
                              ? `Off`
                              : item.value
                          }${item.formatTrailing ? item.formatTrailing : ""}`}
                        </span>
                      );
                    }
                  })}
                </Card>
              </SingleResultWindow>
            </LeafletControl>
          </Fragment>
        )} */}

        <FeatureGroup>
          <EditControl
            position="bottomright"
            onCreated={(e) => handlePolygonCreated(e)}
            onDrawVertex={() => _onDrawVertex()}
            onEditVertex={(e) => handleEditVertex(e)}
            draw={{
              polygon: false,
              polyline: false,
              rectangle: false,
              circle: false,
              marker: false,
              circlemarker: false,
            }}
            edit={{
              remove: false,
              edit: false,
            }}
          />
        </FeatureGroup>
        {/* Boundary */}
        <LayerGroup>
          {inputs.site_features &&
            Object.values(inputs.site_features).map((poly, index) => {
              if (poly.properties.identity == 0 && !polygonStates.Inactive) return;
              if (poly.properties.identity == 1 && !polygonStates.Boundary) return;
              if (poly.properties.identity == 2 && !polygonStates.Exclusions) return;
              return (
                <GeoJSON
                  data={poly}
                  ref={setRef(poly.properties.index)}
                  key={poly.properties.index}
                  style={{
                    fillColor: "#ffffff",
                    fillOpacity: 0.01,
                    weight: 1,
                    color: poly.properties.identity == 0 ? "white" : poly.properties.identity == 1 ? "red" : poly.properties.identity == 2 ? "yellow" : "white",
                  }}
                  onClick={(e) => {
                    // console.log("e", e);
                    handlePolygonClicked(e, poly.properties.index);
                  }}
                >
                  {offsetToolVisible && offsetPolygonKey == poly.properties.index && <OffsetPolygonTool />}
                </GeoJSON>
              );
            })}
        </LayerGroup>

        {/* Racks */}
        {/* {portal.layout.isLayout && (
          <LayerGroup>{polygonStates.Layout && <Polygon positions={portal.layout.racks.geometry.coordinates} key={599} id={-999} color="#33A2FF" fillColor="none" weight={1} />}</LayerGroup>
        )} */}
        {/* Roads */}
        {portal.layout.isLayout && (
          <LayerGroup>{polygonStates.Layout && <Polygon positions={portal.layout.roads.geometry.coordinates} key={598} id={-998} color="#e0710f" fillColor="none" weight={1} />}</LayerGroup>
        )}
        {/* {portal.layout.isLayout && (
          <LayerGroup>{polygonStates.Layout && <Polygon positions={portal.layout.inverter.geometry.coordinates} key={598} id={-998} color="#FFA500" fillColor="none" weight={1} />}</LayerGroup>
        )}
        {portal.layout.isLayout && (
          <LayerGroup>{polygonStates.Layout && <Polygon positions={portal.layout.combiner_box.geometry.coordinates} key={598} id={-998} color="#e0710f" fillColor="none" weight={1} />}</LayerGroup>
        )}
        {portal.layout.isLayout && (
          <LayerGroup>{polygonStates.Layout && <Polygon positions={portal.layout.wires.geometry.coordinates} key={598} id={-998} color="#39FF14" fillColor="none" weight={0.5} />}</LayerGroup>
        )}
        {portal.layout.isLayout && (
          <LayerGroup>{polygonStates.Layout && <Polygon positions={portal.layout.strings.geometry.coordinates} key={598} id={-998} color="#FF69B4" fillColor="none" weight={0.5} />}</LayerGroup>
        )} */}

        {portal.layout.isLayout &&
          Object.values(portal.layout.inverter_groups).map((group, group_index) => {
            return Object.keys(group).map((key, index) => {
              if (!polygonStates.Wiring && WiringKeys.includes(key)) {
                return;
              }

              // console.log(key,index,group[key])
              // group[key].color = '#33A2FF'
              if (key == "inverter") {
                group[key].color = "#FFA500";
                group[key].fillColor = "#FFA500";
                group[key].weight = 1;
              }
              if (key == "combiner_boxes") {
                group[key].color = "#7FFF00";
                group[key].fillColor = "#7FFF00";
                group[key].weight = 4;
              }
              if (key == "racks") {
                group[key].color = "#33A2FF";
                group[key].fillColor = "none";
                group[key].weight = 2;
              }
              if (key == "wires") {
                // return
                group[key].color = group[key].properties.color;
                group[key].fillColor = "none";
                group[key].weight = 0.5;

                // 131 - #AAFFFF
                // 141 - #AAEAFF
                // 151 - #AAD4FF
                // 161 - #AABFFF
                // 171 - #AAAAFF
                // 181 - #BFAAFF
              }
              if (key == "strings") {
                group[key].color = group[key].properties.color;
                group[key].fillColor = "none";
                group[key].weight = 0.5;
              }

              return (
                <LayerGroup>
                  {polygonStates.Layout && (
                    <Polygon positions={group[key].geometry.coordinates} key={index} color={group[key].color} fillColor={group[key].fillColor} id={-999} weight={group[key].weight}></Polygon>
                  )}
                </LayerGroup>
              );
            });
          })}

        {/* {key == 'inverter' &&
						<Popup>
							<div>
							Inverter {group_index+1} Information:
							<br />
							Combiner box count: {group[key].properties.combiner_count}
							<br />
							Total string count: {group[key].properties.total_string_count}
							<br />
							Total wire length (meters): {group[key].properties.total_wire_length.toFixed(3)}
							</div>

						</Popup>
					} */}
        {!takingPicture && (
          <Fragment>
            {/* Topo Layer */}
            {portal.topo_live && portal.topo_mode != "Off" && (
              <TileLayer
                url={portal.topo_url}
                opacity={0.7}
                tms
                zIndex={10}
                bounds={boundary_bbox}
                errorTileUrl="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                crossOrgin
              />
            )}

            {/* topo scale */}
            {portal.topoData.topo_live && portal.topoData.topo_mode != "Off" && (
              <LeafletControl position="bottomright">
                <Topobox drag dragConstraints={mapContainer} dragMomentum={false}>
                  <img
                    onDragStart={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    style={{ height: "350px" }}
                    src={portal.topoData.topo_scale_url}
                  ></img>
                </Topobox>
              </LeafletControl>
            )}
          </Fragment>
        )}
      </Map>
      {portal.isRunning && (
        <section className="generating-box">
          {!portal.isGenerating ? (
            <div className="initializing-box">
              <Spin />
              <p> Initializing... </p>
            </div>
          ) : (
            <div className="generating-box-contents">
              <Progress type="circle" percent={portal.result_count.toFixed(0)} width={65} strokeColor="#60de4f" strokeWidth={12} />
              <p>Generating...</p>
            </div>
          )}
        </section>
      )}
      {portal.uiControls.show_results_table && portal.hasResults && (
        <section className="results">
          <div className="results-box">
            <GroundMountResults />
          </div>
        </section>
      )}
    </GroundMountMapWrap>
  );
};

export { GroundMountMap };
