import { inputsConstants } from "../constants";
import { authHeader } from "../helpers";
import config from "config";

const loopLimit = 500;

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const topo_job = (inputs, action) => {

	let gen_ele = [false, false];
	let gen_ns = [false, false];
	let gen_ew = [false, false];
	let gen_max = [false, false];

	if (action == 'pull_ele') {
		// this tells topo server to generate the elevation vis layer
		// along with importing elevation dataset
		gen_ele = [true, false]
	}

	if (action == 'gen_layers') {
		gen_ele = [false, inputs.uiState.ele_graded_checked]
		gen_ns = [inputs.uiState.ns_ungraded_checked, inputs.uiState.ns_graded_checked]
		gen_ew = [inputs.uiState.ew_ungraded_checked, inputs.uiState.ew_graded_checked]
		gen_max = [inputs.uiState.max_ungraded_checked, inputs.uiState.max_graded_checked]
	}

	return {
		// started undefined till we get a job_id
		job_id: undefined,
		// temp var till normalized across all jobs
		job_string: 'topo_id',
		// action the job is taking
		action: action,
		// inputs sent to backend -- some jobs won't send any
		inputs: {
			//
			'env': inputs.env == 'prod' ? 'prod' : 'test',
			'id': inputs.topo_id,
			'source': inputs.topo_id ? 'file' : 'USGS',
			'bbox': inputs.boundary_bbox,
			// # bools for which tiles to generate
			'generate_layers': { 
				'generate_ele': gen_ele, 
				'generate_ns': gen_ns, 
				'generate_ew': gen_ew, 
				'generate_u': gen_max, 
				'generate_cf': action=='calc_grade'
			},
			// # used as limits for slope analysis
			'grade_limits': {
				'ns_grade_limit': parseInt(inputs.vis_ns.ungraded_percent),
				'ew_grade_limit': parseInt(inputs.vis_ew.ungraded_percent),
				'u_grade_limit': parseInt(inputs.vis_max.ungraded_percent),
			},
			// # grading inputs
			'grading': {
				'enabled': action=='calc_grade',
				'ns_grade_target': parseFloat(inputs.grade_target),
				'ew_grade_target': parseFloat(inputs.grade_target),
				'grade_target': parseFloat(inputs.grade_target),
				'grade_target_type': 0,
			},
			// map features
			'features': Object.values(inputs.site_features),
			topo: {
				graded_avail: inputs.graded_avail,
				grade_target: inputs.grade_target,
				vis_ele: { 
					...inputs.vis_ele, 
					graded_avail: inputs.vis_ele.graded_avail || gen_ele[1] 
				},
				vis_max: { 
					...inputs.vis_max, 
					ungraded_avail: inputs.vis_max.ungraded_avail || gen_max[0], 
					graded_avail: inputs.vis_max.graded_avail || gen_max[1]
				},
				vis_ns: { 
					...inputs.vis_ns, 
					ungraded_avail: inputs.vis_ns.ungraded_avail || gen_ns[0], 
					graded_avail: inputs.vis_ns.graded_avail || gen_ns[1]
				},
				vis_ew: { 
					...inputs.vis_ew, 
					ungraded_avail: inputs.vis_ew.ungraded_avail || gen_ew[0], 
					graded_avail: inputs.vis_ew.graded_avail || gen_ew[1]
				},		
				vis_g: {
					...inputs.vis_g,
					graded_avail: inputs.vis_ew.vis_g || action=='calc_grade',
					ungraded_percent: inputs.grade_target
				}		
			}			
		},
		// job request action for redux
		request() {
			return { type: inputsConstants.UPDATE_UI_STATE, key: 'topo_loading', value: true }
		},
		// get job id (should be standardized across all jobs)
		async get_job_id(inputs) {
			const requestOptions = {
				method: "POST",
				headers: { ...authHeader(), "Content-Type": "application/json" },
				body: JSON.stringify(inputs),
			};			
			return fetch(`${config.apiUrl}/dash/swm/topo/`, requestOptions).then((response) => response.json());
		},
		async upload_to_s3(data) {
			// implement later
		},
		// poll job id (should be standardized across all jobs essentially)
		async poll_job(job) {
			const requestOptions = {
				method: "GET",
				headers: { ...authHeader(), "Content-Type": "application/json" },
			};
			let loopBool = true;
			let loopCount = 0;
			let results = { job: job, error: false }
			// polling loop
			while (loopBool) {
				// poll request to backend
				var topo_check = await fetch(`${config.apiUrl}/dash/swm/topo/?topo_id=${job.job_id}`, requestOptions).then((response) => response.json());
				if (topo_check["code"] == 100) {
					loopBool = false;
					// 
					results.output = { ...JSON.parse(topo_check["output"]) }
					return results;
				} else if (topo_check["code"] == 97 || loopCount > loopLimit) {
					// ERROR OR TIMEOUT
					loopBool = false;
					results.error = { msg: "Error Connecting to Topography Server", }
					return results;
				} else {
					// wait a cool 1000ms then loop again
					await sleep(1000);
					loopCount += 1;
				}
			}		
			
		},
		async download_from_s3(url) {
			// implement later
		},
		// completion of job -- maybe we handle the redux updates here?
		complete(results) {
			let _results;
			if (results.error) {
				_results = results.error
			} else {
				if (results.job.action == 'pull_ele') {
					_results = {
						topo_id: results.job.job_id,
						topo_mode: 'ele',
						topo_url: `https://topo-tiles.sunfig.com/test/${results.job.job_id}/ele/{z}/{x}/{y}.png`,
						topo_scale_url: `https://topo-tiles.sunfig.com/test/${results.job.job_id}/ele/scale.png`,
						uiState: {}
					}
				}
				if (results.job.action == 'calc_grade') {
					_results = {
						topo_id: results.job.job_id,
						graded_avail: true,
						grade_cut_amt: results.output.cut_sum,
						grade_fill_amt: results.output.fill_sum,
						topo_mode: 'CF',
						topo_url: `https://topo-tiles.sunfig.com/test/${results.job.job_id}/CF/${results.job.inputs.topo.grade_target}/{z}/{x}/{y}.png`,
						topo_scale_url: `https://topo-tiles.sunfig.com/test/${results.job.job_id}/CF/${results.job.inputs.topo.grade_target}/scale.png`,
						vis_g: results.job.inputs.topo.vis_g,
						uiState: {}
					}
				}
				if (results.job.action == 'gen_layers') {
					_results = {
						topo_id: results.job.job_id,
						topo_mode: 'ele',
						topo_url: `https://topo-tiles.sunfig.com/test/${results.job.job_id}/ele/{z}/{x}/{y}.png`,
						topo_scale_url: `https://topo-tiles.sunfig.com/test/${results.job.job_id}/ele/scale.png`,
						vis_ele: results.job.inputs.topo.vis_ele,
						vis_max: results.job.inputs.topo.vis_max,
						vis_ns: results.job.inputs.topo.vis_ns,
						vis_ew: results.job.inputs.topo.vis_ew,
						uiState: { 
							ele_graded_checked: false,
							max_ungraded_checked: false,
							max_graded_checked: false,
							ns_ungraded_checked: false,
							ns_graded_checked: false,
							ew_ungraded_checked: false,
							ew_graded_checked: false,
						}
					}					
				}
			}

			return { type: inputsConstants.TOPO_JOB_COMPLETE, results: _results, action: action }
		}
	}
}