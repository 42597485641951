import React, { Fragment } from "react";

import { pencil } from "../../../../../assets";
import { Tooltip, Button } from "antd";

import { useSelector, useDispatch } from "react-redux";
import { carportActions } from "../../../../Redux/actions/carport.actions";

import { Control } from "../styles";

const ToggleNonRect = () => {
  const _selected = useSelector((state) => state.carport.selected);
  const activeTool = useSelector((state) => state.carport.activeTool);
  const dispatch = useDispatch();

  const toggleNPEditor = () => {
    dispatch(carportActions.toggleNonRectangle());
  };

  return (
    <Control column={3}>
      <Tooltip title="Toggle Non-Rect On/Off" placement="bottom">
        <Button
          shape="circle"
          size="large"
          disabled={_selected ? false : true}
          style={{
            margin: "0 4px",
            backgroundColor: activeTool === "nonRect" ? "#60de4f" : _selected ? "#fff" : "#9e9e9e",
            borderColor: activeTool === "nonRect" ? "#d9d9d9" : _selected ? "#60de4f" : "#fff",
          }}
          onClick={toggleNPEditor}
        >
          <img style={{ marginBottom: "2px" }} src={pencil} />
        </Button>
      </Tooltip>
    </Control>
  );
};

export { ToggleNonRect };
