import styled, { keyframes } from "styled-components";

const rotate = keyframes`
	to { transform: rotate(360deg); }
`;

export const SpinnerWrap = styled.section`
  width: ${(props) => (props.width ? props.width : "15px")};
  height: ${(props) => (props.height ? props.height : "15px")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "10px")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "10px")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "5px")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
  border-radius: 50%;
  background: transparent;
  border-top: ${(props) => (props.color ? `2px solid ${props.color}` : "2px solid #fff")};
  border-right: ${(props) => (props.color ? `2px solid ${props.color}` : "2px solid #fff")};
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  animation: ${rotate} 1.2s infinite linear;
`;
