/* eslint-disable */
// libraries
import React from 'react';
import matchSorter from 'match-sorter';
import Dropzone from 'react-dropzone';
import ZipLoader from 'zip-loader';
import XMLParser from 'react-xml-parser';
import textEncoding from 'text-encoding';
import { CSVLink } from 'react-csv';
import Geocode from 'react-geocode';
import { Rnd } from 'react-rnd';
import pubsub from 'pubsub-js';
import { Switch, Progress, Input, Row, Col, Tabs, Button, Dropdown, Menu, Empty, Table, Spin, Tooltip, Radio, Popconfirm, Checkbox, Modal, message, Typography } from 'antd';
import tokml from 'tokml';

// utils
import { _init, _getResults, _getRacks, getSurfaces, sendBugReportSecure, shareProject, getCounty, getProjects, saveProject, getTopoId, checkTopoId, generateTopoLayers } from '../utils/analysis';
import { getLengthGFT } from '../utils/GFTautoSize';
import { getLengthSAT } from '../utils/SATautoSize';
import { loadSurfaceFromXML, getBounds } from '../utils/map_helper.js';
import { debounce } from '../utils/common';

// Redux
import { connect } from 'react-redux';
import { portalActions } from '../components/Redux/actions/portal.actions';

// components
import { GroundMountControlPanel } from '../components';
import { ProjectManager, SunfigMap, BugReport, GroundMountResults } from '../components/Sunfig';
// data
import defaultProducts from '../components/Sunfig/data/defaultProductData';
import defaultModules from '../components/Sunfig/data/defaultModules';
import { divStyle, overlayStyle, resizablePanelStyle } from '../components/Sunfig/data/defaultStyles';
import { withRouter } from 'react-router';

// swm
import { convert_to_swm } from '../components/Sunfig/data/swm_input_sheet';
import { portalConstants } from '../components/Redux/constants';

const TabPane = Tabs.TabPane;
const FileSaver = require('file-saver');
const json2csv = require('json2csv').parse;
const letters = ['A', 'B', 'C', 'D', 'E'];
const products = defaultProducts;
const modules = defaultModules;
// const productData = productsImport.productData;

const topo_modes = ['NS', 'EW', 'U', 'ele'];
const radioStyle = {
  display: 'block',
  height: '20px',
  lineHeight: '20px',
  fontWeight: '400',
};
const rowStyle = { lineHeight: '25px' };

class Portal extends React.Component {
  componentDidMount() {
    // fetch data from backend
    this.props.dispatch(portalActions.handleNewProject());
    this.onStart();
  }
  componentWillUnmount() {
    // React removed me from the DOM, I have to unsubscribe from the pubsub using my token
    pubsub.unsubscribe(this.pubsub_token);
    pubsub.unsubscribe(this.token);
  }

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.submissionValidate = this.submissionValidate.bind(this);

    this.state = {
      // project search filter
      filteredProjects: this.props.Projects,
      filterText: undefined,

      CurrentProject: {
        id: undefined,
        name: '',
        active: true,
        editDt: undefined,
        inputs: {
          test: '',
          input1: 0,
        },
        surface: [],
        weather: 'none',
      },
      Projects: [],
      project_sortedInfo: {
        columnKey: 'edit_dt',
        order: 'descend',
      },
    };

    this.pubsub_token = pubsub.subscribe('resetState', () => {
      this.resetState();
    });
    this.token = pubsub.subscribe('reportBug', this.showBugReport);
  }

  submissionValidate() {
    // Do some validations before running
    if (this.props.inputs.doOverrideDims) {
      for (var p in this.props.inputs.current_product.filter((product) => product.active)) {
        for (var i in this.props.inputs.current_product[p]) {
          if (i != 'active' && i != 'continous') {
            // light weight force value to be float
            var val = parseFloat(this.props.inputs.current_product[p][i]);
            if (isNaN(val) && i != 'key') {
              message.error('Non-Number value detected in one of the override dimensions', 3);
              return false;
            }
            this.props.inputs.current_product[p][i] = val;
          }
        }
      }
    }
    if (this.props.inputs.doOverrideGCR) {
      if (this.props.inputs.OverrideGCR[0] > this.props.inputs.OverrideGCR[1]) {
        message.error('Minimum GCR must be lower or equal to Maximum GCR', 3);
        return false;
      }
      if (this.props.inputs.OverrideGCR[0] < 0.2 || this.props.inputs.OverrideGCR[0] > 0.95) {
        message.error('Minimum GCR is not valid. Keep it between 0.2-0.9', 3);
        return false;
      }
      if (this.props.inputs.OverrideGCR[1] < 0.2 || this.props.inputs.OverrideGCR[1] > 0.95) {
        message.error('Maximum GCR is not valid. Keep it between 0.2-0.9', 3);
        return false;
      }
    }
    if (this.props.inputs.selectedProductIndex < 0) {
      message.error('Please select a Product to analyze', 3);
      return false;
    }
    if (this.props.inputs.selectedModuleIndex < 0) {
      message.error('Please select a Module', 3);
      return false;
    }
    if (!this.props.inputs.features || this.props.inputs.features.length == 0) {
      message.error('No Boundary!', 3);
      return false;
    }
    if (this.state.isDrawing) {
      message.error('Finish drawing polygon before running.', 3);
      return false;
    }
    if (this.props.inputs.doOverrideTilt) {
      if (isNaN(this.props.inputs.OverrideTilt[0]) || typeof this.props.inputs.OverrideTilt[0] == 'undefined') {
        message.error('Min tilt is Not-A-Number', 3);
        return false;
      }
      if (isNaN(this.props.inputs.OverrideTilt[1]) || this.props.inputs.OverrideTilt[1] == 'undefined') {
        message.error('Max tilt is Not-A-Number', 3);
        return false;
      }
      if (this.props.inputs.OverrideTilt[0] > this.props.inputs.OverrideTilt[1]) {
        message.error('Min tilt greater than Max tilt', 3);
        return false;
      }
      if (this.props.inputs.OverrideTilt[0] == 0 && this.props.inputs.OverrideTilt[1] == 0) {
        message.error('Please enter a min/max for Tilt', 3);
        return false;
      }
    }
    if (isNaN(this.props.inputs.losses.soiling_loss)) {
      message.error('Non-Number value detected in soiling loss', 3);
      return false;
    }
    if (isNaN(this.props.inputs.losses.mismatch_loss)) {
      message.error('Non-Number value detected in DC Mismatch loss', 3);
      return false;
    }
    if (isNaN(this.props.inputs.losses.wiring_loss)) {
      message.error('Non-Number value detected in DC Wiring loss', 3);
      return false;
    }
    if (isNaN(this.props.inputs.losses.module_lid_loss)) {
      message.error('Non-Number value detected in Module LID loss', 3);
      return false;
    }
    if (isNaN(this.props.inputs.losses.module_quality_loss)) {
      message.error('Non-Number value detected in Module Quality loss', 3);
      return false;
    }
    if (isNaN(this.props.inputs.losses.inverter_eff)) {
      message.error('Non-Number value detected in Inverter Efficiency loss', 3);
      return false;
    }
    if (isNaN(this.props.inputs.losses.combined_ac_loss)) {
      message.error('Non-Number value detected in Combined AC loss', 3);
      return false;
    }
    if (isNaN(this.props.inputs.bifaciality)) {
      message.error('Non-Number value detected in Bifaciality', 3);
      return false;
    }
    if (isNaN(this.props.inputs.bifacial_ground_clearance_height)) {
      message.error('Non-Number value detected in Clearance Height', 3);
      return false;
    }
    if (isNaN(this.props.inputs.bifacial_transmission_factor)) {
      message.error('Non-Number value detected in Transmission Percent', 3);
      return false;
    }
    if (isNaN(this.props.inputs.bifacial_structure_shade_factor)) {
      message.error('Non-Number value detected in Structural Shade', 3);
      return false;
    }
    if (isNaN(this.props.inputs.ns_grade_limit)) {
      message.error('Non-Number value detected in NS Grade Limit', 3);
      return false;
    }
    if (isNaN(this.props.inputs.ew_grade_limit)) {
      message.error('Non-Number value detected in EW Grade Limit', 3);
      return false;
    }
    if (isNaN(this.props.inputs.u_grade_limit)) {
      message.error('Non-Number value detected in Universal Grade Limit', 3);
      return false;
    }

    return true;
  }

  // Helper function to be called on start
  onStart() {
    this.resetState(() => {});
  }

  resetState(cb = undefined) {
    if (this.state.isLoading) {
      return;
    }

    this.setState({ isLoading: true }, () => {
      setTimeout(
        function() {
          this.setState(
            {
              loading_racks: false,
              isLoading: false,
              files: [],
              boundary: [],
              center: [39.188, -84.47],
              center_placeholder: '',
              isUploading: false,
              images: [],
              advancedOptions: false,
              takingPic: false,
              showPolygons: true,

              map_reset: false,
              map_resize: false,

              // azimuth: 180,
              doAlign: true,
              doRoads: false,

              bug_visible: false,
              bug_sending: false,
              bug_sent: false,
              bud_desc: '',

              grade_limit: 10,
              dcac: 1.4,

              // product: [],

              // This needs to be a struct with (surface_id, surface_name, and surfaces[])
              surfaceFiles: ['Bluestar.kmz', 'GlennRowan.kmz'],
              currentLayer: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
              dropzoneActive: false,
              StoredSurfaces: [],
              selectedSurface: null, // default selected value
              selectedSurfaceIndex: -2,
              kmzImported: false,

              // selectedProduct: products[0],
              // selectedProductIndex: -1,

              // selectedModule: defaultModules[0],
              // selectedModuleIndex: -1,

              ewShift: false,
              // doOverrideTilt: false,
              // OverrideTilt: [false],
              overrideDims: false,
              doContinous: false,
              // overrideGCR: false,
              // minGCR: 0.3,
              // maxGCR: 0.7,
              zoom: 15,

              // bifacial defaults
              is_bifacial: 0,
              bifaciality: 0.7,
              clearance_height: 0,
              transmission_perc: 0,
              struc_shade: 0,

              result_count: 50,

              soiling_loss: 2.0,
              mismatch_loss: 1.0,
              wiring_loss: 1.5,
              module_lid_loss: 2.0,
              module_quality_loss: 0.0,
              inverter_eff: 97.0,
              combined_ac_loss: 2.0,

              filterText: '',
              // FilteredProject: this.props.Projects,
              saving: false,
              mapOptions: -1,

              isRunning: false, // Is the simulation running?
              isGenerating: false, // are the results generating?
              hasResults: true, // Do we have results from simulation?

              Surfaces: [],
              isSurface: false,
              isAutolayout: false,
              surface_id: '',
              surface_name: '',

              // topo info
              topo_id: undefined,
              topo_loading: false,
              topo_live: false,
              topo_url: undefined,
              topo_mode: 'Off',
              topo_scale_url: undefined,
              boundary_bbox: undefined,
              grade_limit: 10,
              ns_grade_limit: 10,
              ew_grade_limit: 20,
              u_grade_limit: 15,
              do_generate_layers: false,
              generate_ns: false,
              generate_ew: false,
              generate_u: false,
              layers_generated: {
                ele: { avail: false },
                NS: { avail: false, limit: 10 },
                EW: { avail: false, limit: 20 },
                U: { avail: false, limit: 15 },
              },
              topo_action: 'nothing', // flag, nothing

              selectedResult: {},

              active_surface: -1,
              active_surface_name: '',
              active_surface_identity: 0,
              active_surface_active: 0,

              rackPolygons: [],
              roadPolygons: [],
              autolayout: {
                racks: {},
                roads: {},
              },
              csvResults: [],
              tsvResult: [],
              map_data: [],
              project_loading: true,

              searchValue: '',
              resultCount: 0,
              results: [],
              isDeleting: false,
              isCreating: false,
              isDrawing: false,
              isEditing: false,
              disableEditing: false,
              currentDrawingPolygon: undefined,
              doCancel: false,
              analysisId: '51025398-b931-11e8-89af-e0d55e27c3c3',

              currentlyEditing: undefined,
              county: undefined,

              mod_height: 0,
              mod_width: 0,
              mod_string: 0,
              table_count: 0,

              projectLoaded: false,
              showProjectLoader: false,
              editProject: false,
              modalMode: 0,
              selectedProject: -1,
              project_name: '',
              CurrentProject: {
                id: undefined,
                name: '',
                active: true,
                editDt: undefined,
                inputs: {
                  test: '',
                  input1: 0,
                },
                surface: [],
                weather: 'none',
              },
              Projects: [],
              filteredInfo: null,
              sortedInfo: {
                columnKey: 'GCR',
                order: 'ascend',
              },
              project_sortedInfo: {
                columnKey: 'edit_dt',
                order: 'descend',
              },
            },
            () => {
              if (cb) cb();
            }
          );
        }.bind(this),
        500
      );
    });
  }

  handleChange = (pagination, filters, sorter) => {
    // console.log('Current Sorter', sorter);
    // console.log('Previous Sorter', this.state.sortedInfo);
    let _sorter = sorter;
    if (!sorter.order) {
      // interrupt the default functionality
      _sorter = this.state.sortedInfo;
      _sorter.order = 'ascend';
    }
    this.setState({
      filteredInfo: filters,
      sortedInfo: _sorter,
    });
  };

  // INPUT FUNCTION
  // *** MOVED TO REDUX ***
  handlePullTopo = async (e) => {
    if (this.props.inputs.boundary_bbox && this.props.inputs.boundary_bbox.every((x) => isFinite(x))) {
      // called when topo is pulled from topo tab.
      let generate_layers = {
        generate_ele: true,
        generate_ns: true,
        generate_ew: true,
        generate_u: true,
      };
      let grade_limits = {
        ns_grade_limit: parseInt(this.props.inputs.ns_grade_limit),
        ew_grade_limit: parseInt(this.props.inputs.ew_grade_limit),
        u_grade_limit: parseInt(this.state.u_grade_limit),
      };
      let inputs = {
        boundary_bbox: this.props.inputs.boundary_bbox,
        selectedProductIndex: this.props.inputs.selectedProductIndex,
        generate_layers,
        grade_limits,
      };
      this.props.dispatch(portalActions.getTopoData(inputs));

      // this.setState({ topo_loading: true }, async () => {
      //   let generate_layers = {
      //     generate_ele: true,
      //     generate_ns: true,
      //     generate_ew: true,
      //     generate_u: true
      //   };
      //   let grade_limits = {
      //     ns_grade_limit: parseFloat(this.props.inputs.ns_grade_limit),
      //     ew_grade_limit: parseFloat(this.props.inputs.ew_grade_limit),
      //     u_grade_limit: parseFloat(this.state.u_grade_limit)
      //   };
      //   let resp = await getTopoId(this.props.inputs.boundary_bbox, generate_layers, grade_limits);
      //   console.log(resp);
      //   if (resp['topo_id']) {
      //     let topo_id = resp['topo_id'];
      //     var loopBool = true;
      //     let run_count = 0;

      //     // polling loop
      //     while (loopBool) {
      //       var topo_check = await checkTopoId(topo_id);
      //       // console.log(topo_check)

      //       if (topo_check['code'] == 100) {
      //         loopBool = false;
      //         let layers_gen = {
      //           ele: { avail: true },
      //           NS: {
      //             avail: true,
      //             limit: parseFloat(this.props.inputs.ns_grade_limit)
      //           },
      //           EW: {
      //             avail: true,
      //             limit: parseFloat(this.props.inputs.ew_grade_limit)
      //           },
      //           U: { avail: true, limit: parseFloat(this.state.u_grade_limit) }
      //         };
      //         let mode = this.props.inputs.selectedProductIndex > 0 ? 'EW' : 'NS';
      //         let mode_ext = `${mode}/${layers_gen[mode].limit}`;
      //         this.setState({
      //           topo_id: topo_id,
      //           topo_mode: mode,
      //           topo_url: `http://topo.xyz.s3-website.us-east-2.amazonaws.com/test/${topo_id}/${mode_ext}/{z}/{x}/{y}.png`,
      //           topo_scale_url: `http://topo.xyz.s3-website.us-east-2.amazonaws.com/test/${topo_id}/${mode_ext}/scale.png`,
      //           topo_loading: false,
      //           topo_live: true,
      //           topo_layers_live: true,
      //           layers_generated: layers_gen
      //         });
      //       } else if (topo_check['code'] == 97) {
      //         // ERROR
      //         loopBool = false;
      //         this.setState({ topo_loading: false });
      //         message.error('Error Connecting to Topography Server', 2);
      //       } else {
      //         setTimeout(async () => {}, 10000);
      //         run_count += 1;
      //         if (run_count > 10000) {
      //           loopBool = false;
      //         }
      //       }
      //     }
      //   }
      // });
    }
  };
  // INPUT FUNCTION
  // *** Moved o Redux ***
  // handleClearTopoData = () => {
  //   this.setState({
  //     topo_id: undefined,
  //     topo_url: '',
  //     topo_scale_url: '',
  //     topo_live: false,
  //     topo_layers_live: false,
  //     layers_generated: {
  //       ele: { avail: false },
  //       NS: { avail: false, limit: 10 },
  //       EW: { avail: false, limit: 20 },
  //       U: { avail: false, limit: 15 }
  //     }
  //   });
  // };

  // MAP FUNCTION
  handleUpdateTopoMode = (mode) => {
    if (this.props.topoData.topo_live) {
      let mode_ext = mode;
      if (mode == 'NS' || mode == 'EW' || mode == 'U' || mode == 'CF') {
        if (!this.props.topoData.layers_generated[mode].avail) return;
        mode_ext = `${mode}/${this.props.topoData.layers_generated[mode].limit}`;
      }

      let data = {
        topo_mode: mode,

        // topo_url: `https://topo.xyz.s3-website.us-east-2.amazonaws.com/test/${this.props.topoData.topo_id}/${mode_ext}/{z}/{x}/{y}.png`,
        // topo_scale_url: `https://topo.xyz.s3-website.us-east-2.amazonaws.com/test/${this.props.topoData.topo_id}/${mode_ext}/scale.png`
        topo_url: `https://s3.us-east-2.amazonaws.com/topo.xyz/test/${this.props.topoData.topo_id}/${mode_ext}/{z}/{x}/{y}.png`,
        topo_scale_url: `https://s3.us-east-2.amazonaws.com/topo.xyz/test/${this.props.topoData.topo_id}/${mode_ext}/scale.png`,
      };

      this.props.dispatch(portalActions.updateTopoMode(data));
    }
  };

  handleMapSearch = (center) => {
    this.props.dispatch(portalActions.updateMapCenter(center));
  };
  handleMapZoom = (zoom) => {
    this.props.dispatch(portalActions.updateMapZoom(zoom));
  };

  // INPUT FUNCTION
  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.submissionValidate() !== true) {
      return;
    }

    // get rbi inputs
    let rbi_inputs = this.collectInputs();
    // convert to swm inputs
    let swm_inputs = convert_to_swm(rbi_inputs);
    // console.log(swm_inputs)
    // return
    // init run in the backend
    this.props.dispatch(portalActions.initResults(swm_inputs));

    // if (this.submissionValidate() !== true) {
    //   return;
    // }
    // // Reset back to basic
    // REMOVE WHEN REDUX IS IMPLEMENTED
    // this.setState({
    //   resultCount: 0, // this controls loading bar
    //   hasResults: false, // this controls if should show results table
    //   results: [], // this is the content of the results table
    //   rackPolygons: [], // this is for any drawings user has live
    //   mapOptions: 0, // This sets the map toolbar to nothing
    //   isAutolayout: false, // This turns off the autolayout results panel in map
    //   selected_result: undefined,
    //   doCancel: false,
    //   autolayout: {
    //     racks: [],
    //     roads: []
    //   }
    // });

    // // var surface_name = this.state.surface_name;
    // var surface_id = '';
    // var surfaces = [];
    // for (var surf in this.state.Surfaces) {
    //   if (this.state.Surfaces[surf]['active']) {
    //     surfaces.push(this.state.Surfaces[surf]);
    //   }
    // }

    // console.log('project = features', current_project);

    // if (current_project.weather == '') {
    //   current_project.weather = 'none';
    // }

    // // // make sure dealing with most recent surface / inputs
    // current_project.surface = this.state.CurrentProject;
    // // // ghetto hotfix to get weather reader to work in django
    // current_project.surface[0]['coordinates'] = [current_project.surface[0]['geometry']['coordinates'][0][0]];
    // current_project.surface[0]['coordinates'].forEach((latlon, index) => {
    //   current_project.surface[0]['coordinates'][index] = [
    //     current_project.surface[0]['coordinates'][index][1],
    //     current_project.surface[0]['coordinates'][index][0]
    //   ];
    // });

    // return
    // this.setState({ isRunning: true });
    // this.props.dispatch(portalActions.generateResults(current_project));

    // collect all inputs
    // current_project.inputs = this.collectInputs();

    // console.log(swm_inputs);
    // return;

    // let response = await _init(swm_inputs);
    // if (response['runId'] && response['runId'] == 'error') {
    //   Swal({
    //     title: 'Error',
    //     text: 'Weather data is not available for this site.',
    //     type: 'warning',
    //     confirmButtonColor: '#d9534f'
    //   });
    //   this.setState({
    //     resultCount: 0, // this controls loading bar
    //     hasResults: false, // this controls if should show results table
    //     results: [], // this is the content of the results table
    //     rackPolygons: [], // this is for any drawings user has live
    //     mapOptions: 0, // This sets the map toolbar to nothing
    //     isAutolayout: false, // This turns off the autolayout results panel in map
    //     selected_result: undefined,
    //     doCancel: false,
    //     isRunning: false,
    //     autolayout: {
    //       racks: [],
    //       roads: []
    //     }
    //   });
    //   return false;
    // }

    // Init has completed, now to wait for worker's to finish
    // this.setState({ analysisId: response['runId'], resultCount: 0 });

    // if (!response['runId']) {
    //   Swal({
    //     title: 'Error',
    //     text: 'Trouble connecting to server, please refresh',
    //     type: 'warning',
    //     confirmButtonColor: '#d9534f'
    //   });
    //   return;
    // }

    //   var result_count = 51;
    //   if (this.props.inputs.doOverrideGCR) {
    //     result_count = this.props.inputs.OverrideGCR[1] * 100 - this.props.inputs.OverrideGCR[0] * 100 + 1;
    //   }

    //   this.setState({ result_count: result_count });

    //   let loopMax = 50;
    //   let counter = 0;
    //   let loopBool = true;
    //   let minDelay = 100;
    //   let maxDelay = 3000;
    //   var resultsDjango = {};

    //   while (loopBool) {
    //     if (this.props.inputs.doCancel) {
    //       loopBool = false;
    //       return;
    //     }
    //     counter++;
    //     resultsDjango = await _getResults(this.state.analysisId);

    //     minDelay *= 2;
    //     setTimeout(() => {}, Math.min(minDelay, maxDelay));
    //     var percentComplete = Math.round((resultsDjango['code'] / result_count) * 100);

    //     if (percentComplete > 5) {
    //       this.setState({ resultCount: percentComplete, isGenerating: true });
    //     }
    //     if (resultsDjango['code'] == 100) {
    //       var bools = [];
    //       var product = current_project.inputs.product;
    //       console.log(product);
    //       for (var p in product) {
    //         bools.push(product[p].active);
    //       }
    //       var res = resultsDjango['data'];
    //       for (var r in res) {
    //         var breakdown = JSON.parse(res[r]['ProductBreakdown']);
    //         var index = 0;
    //         for (var b in bools) {
    //           if (bools[b]) {
    //             // active table
    //             res[r][`Table-${letters[b]}`] = breakdown[index];
    //             index++;
    //           } else {
    //             // insactive table
    //             res[r][`Table-${letters[b]}`] = 0;
    //           }
    //         }
    //         delete res[r]['ProductBreakdown'];
    //       }

    //       this.setState({ results: resultsDjango['data'] }, () => {
    //         var csvobj = [];
    //         var xlsxObj = [];

    //         for (var r in this.state.results) {
    //           var obj;

    //           if (this.props.inputs.selectedProductIndex == 0) {
    //             obj = {
    //               Racking: this.state.results[r]['ProductSelection'],
    //               Module: this.state.results[r]['ModuleSelection'],
    //               GCR: this.state.results[r]['GCR'],
    //               IntraRow: this.state.results[r]['rtr'],
    //               Pitch: this.state.results[r]['pitch'],
    //               Tilt: this.state.results[r]['Tilt'],
    //               'Yield (kWh/kWp)': parseInt(this.state.results[r]['Yield']),
    //               'Generation Yr 1 (kWh)': parseInt(this.state.results[r]['generation_yr_1']),
    //               Capacity: this.state.results[r]['Capacity'],
    //               'Module Qty': this.state.results[r]['Totalmodules'],
    //               'Table Qty-A': this.state.results[r]['Table-A'],
    //               'Table Qty-B': this.state.results[r]['Table-B'],
    //               'Table Qty-C': this.state.results[r]['Table-C'],
    //               'Table Qty-D': this.state.results[r]['Table-D'],
    //               'Azimuth': this.props.inputs.azimuth
    //             };
    //           } else {
    //             obj = {
    //               Racking: this.state.results[r]['ProductSelection'],
    //               Module: this.state.results[r]['ModuleSelection'],
    //               GCR: this.state.results[r]['GCR'],
    //               IntraRow: this.state.results[r]['rtr'],
    //               Pitch: this.state.results[r]['pitch'],
    //               Tilt: this.state.results[r]['Tilt'],
    //               'Yield (kWh/kWp)': parseInt(this.state.results[r]['Yield']),
    //               'Generation Yr 1 (kWh)': parseInt(this.state.results[r]['generation_yr_1']),
    //               Capacity: this.state.results[r]['Capacity'],
    //               'Module Qty': this.state.results[r]['Totalmodules'],
    //               // "Table Qty": this.state.results[r]['BOM'],
    //               // "Table Breakdown": this.state.results[r]['ProductBreakdown'],
    //               'Table Qty-A': this.state.results[r]['Table-A'],
    //               'Table Qty-B': this.state.results[r]['Table-B'],
    //               'Table Qty-C': this.state.results[r]['Table-C'],
    //               'Azimuth': this.props.inputs.azimuth
    //             };
    //           }

    //           csvobj.push(obj);
    //         }

    //         if (this.state.results.length > 0) {
    //           var fields;
    //           if (this.props.inputs.selectedProductIndex == 0)
    //             fields = [
    //               'Racking',
    //               'Module',
    //               'GCR',
    //               'Azimuth',
    //               'IntraRow',
    //               'Pitch',
    //               'Tilt',
    //               'Yield (kWh/kWp)',
    //               'Generation Yr 1 (kWh)',
    //               'Capacity',
    //               'Module Qty',
    //               'Table Qty-A',
    //               'Table Qty-B',
    //               'Table Qty-C',
    //               'Table Qty-D'
    //             ];
    //           else
    //             fields = [
    //               'Racking',
    //               'Module',
    //               'GCR',
    //               'Azimuth',
    //               'IntraRow',
    //               'Pitch',
    //               'Tilt',
    //               'Yield (kWh/kWp)',
    //               'Generation Yr 1 (kWh)',
    //               'Capacity',
    //               'Module Qty',
    //               'Table Qty-A',
    //               'Table Qty-B',
    //               'Table Qty-C'
    //             ];
    //           var opts = {
    //             fields,
    //             delimiter: '\t',
    //             eol: '',
    //             header: true,
    //             withBOM: false
    //           };

    //           this.setState({
    //             isRunning: false,
    //             result_count: result_count,
    //             csvResults: json2csv(csvobj),
    //             tsvResult: json2csv(csvobj, opts)
    //           });
    //           loopBool = false;
    //         }
    //       });
    //     }
    //   }
    //   if (this.props.inputs.doCancel) {
    //     this.setState({
    //       doCancel: false,
    //       analysisId: '',
    //       isGenerating: false,
    //       resultCount: 0,
    //       results: []
    //     });
    //   } else {
    //     if (this.state.results.length == 0) {
    //       // Results weren't finished in time
    //       this.setState({
    //         hasResults: false,
    //         isRunning: false,
    //         isGenerating: true
    //       });
    //     } else {
    //       this.setState({
    //         hasResults: true,
    //         isRunning: false,
    //         isGenerating: false
    //       });
    //     }
    //   }
    // };
  };

  // INPUT FUNCTION
  // ***MOVED TO REDUX***
  // handleCancel() {
  //   // Reset back to basic
  //   this.setState({
  //     doCancel: true,
  //     isRunning: false,
  //     resultCount: 0, // this controls loading bar
  //     hasResults: false, // this controls if should show results table
  //     results: [], // this is the content of the results table
  //     rackPolygons: [], // this is for any drawings user has live
  //     mapOptions: 0, // This sets the map toolbar to nothing
  //     isAutolayout: false // This turns off the autolayout results panel in map
  //   });
  // }
  // INPUT FUNCTION
  // ***MOVED TO REDUX***
  // handleOverrideTilt(toggle) {
  //   this.setState({ OverrideTilt: toggle });
  //   // Since I'm not worried about shouldComponentUpdate() lifecycle - I can just force update
  // }

  // INPUT FUNCTION
  handleGetRackData = async (row) => {
    this.props.dispatch(portalActions.getLayoutData(row.id));
  };

  // MAP FUNCTION
  onDragEnter() {
    if (this.state.dropzoneActive) {
      return;
    }
    this.setState({ dropzoneActive: true });
  }
  // MAP FUNCTION
  onDragLeave() {
    if (!this.state.dropzoneActive) {
      return;
    }
    this.setState({ dropzoneActive: false });
  }
  // Handles the file that gets dropped into Dropzone
  // MAP FUNCTION
  onDrop(files) {
    this.setState({
      files: files,
      dropzoneActive: false,
    });

    var selectedFile = this.state.files[0];

    this.setState({
      surface_name: selectedFile.name,
      kmzImported: true,
      selectedSurfaceIndex: -2,
    });

    let file_type = selectedFile.name.substr(selectedFile.name.length - 3).toLowerCase();

    if (file_type == 'kml') {
      var reader = new FileReader();

      reader.onloadend = function(evt) {
        if (evt.target.readyState == FileReader.DONE) {
          // DONE == 2
          var xml = new XMLParser().parseFromString(evt.target.result);
          // this.loadSurfaceFromXML(xml);
          let features = loadSurfaceFromXML(xml);
          this.handlePolygonUpdated(features);
          // this.setState({
          //   Surfaces: features,
          //   map_features: [...features]
          // });
        }
      }.bind(this);
      reader.readAsBinaryString(selectedFile);
    } else if (file_type == 'kmz') {
      // If file is *.kmz we need to unzip the .kml to get the polygons
      ZipLoader.unzip(selectedFile).then((ZipLoaderInstance) => {
        // unzipping the doc.kml returns an object that holds the reference to a Utf8Array buffer which holds the kml info
        var TextDecoder = textEncoding.TextDecoder;
        var all_features = [];
        var files_names = Object.keys(ZipLoaderInstance.files);
        for (var file in files_names) {
          // console.log(files_names[file])
          var string = new TextDecoder('utf-8').decode(ZipLoaderInstance.files[files_names[file]]['buffer']);
          // parse the xml document from the string
          var xml = new XMLParser().parseFromString(string);
          let features = loadSurfaceFromXML(xml);
          all_features.push(...features);
        }
        this.handlePolygonUpdated(all_features);
        // this.setState(
        //   {
        //     Surfaces: all_features,
        //     map_features: [...all_features]
        //   },
        //   () => {
        //     // Call our update method that will add surfaces to our map
        //     // this.forceUpdate();
        //     // this.onSurfacesUpdates();
        //   }
        // );
        // // Decode the buffer into a string
        // var string = new TextDecoder('utf-8').decode(ZipLoaderInstance.files['doc.kml']['buffer']);
        // // parse the xml document from the string
        // var xml = new XMLParser().parseFromString(string);
        // this.loadSurfaceFromXML(xml);
      });
    } else {
      message.error(`Cannot import file type of ${file_type}. Only .kmz/.kml acceptable.`, 3);
    }
  }

  // Function that handles all input changes to Redux

  handleInputUpdate = (key, value) => {
    this.props.dispatch(portalActions.updateInput(key, value));
  };
  handleCancelRun = () => {
    this.props.dispatch(portalActions.cancelRun(this.props.inputs.run_id));
  };

  // INPUT FUNCTION
  calcDimensions = () => {
    if (this.props.inputs.selectedProductIndex == 0) {
      // SAT
      this.handleInputUpdate('setYDimensions', undefined);
    } else {
      // GFT
      this.handleInputUpdate('setXDimensions', undefined);
    }
  };

  // PROJECT MANAGER FUNCTION
  handleProjectManagerFeedback = (type) => {
    if (type == 'new') {
      this.props.dispatch(portalActions.handleNewProject());
      return;
    }

    if (type == 'load') {
      this.props.dispatch(portalActions.showProjectLoadMenu('load'));
      return;
    }

    // we've got to form the project save obj as the old structure for now...
    let old_format_project = {
      surface: JSON.stringify(this.props.inputs.features),
      inputs: JSON.stringify(this.collectInputs(false)),
      name: this.props.current_project.name,
      id: this.props.current_project.id,
      active: 1,
      project_type: 0,
    };

    if (!this.props.current_project.loaded) {
      // hit save or save as and no project loaded.. open modal
      this.props.dispatch(portalActions.showProjectLoadMenu('save'));
      return;
    } else if (type == 'save' && this.props.current_project.loaded) {
      // hit save and project IS loaded.. save

      // dispatch project to save to backend
      this.props.dispatch(portalActions.saveProject(old_format_project));
      return;
    } else {
      // hit save as
      this.props.dispatch(portalActions.showProjectLoadMenu('saveas'));
      return;
    }
  };
  // worker method - clicked from modal
  // PROJECT MANAGER FUNCTION
  _saveProject = async () => {
    // this was called from the project manager modal, which means
    // a name was entered into the input box
    let old_format_project = {
      surface: JSON.stringify(this.props.inputs.features),
      inputs: JSON.stringify(this.collectInputs(false)),
      // this is the typed name vs current_project.name
      name: this.props.inputs.project_name,
      // below two assuming this is save, change if saveas
      id: this.props.current_project.id,
      active: 1,
      project_type: 0,
    };

    if (this.props.uiControls.modalMode == 'save') {
      //
      this.props.dispatch(portalActions.saveProject(old_format_project));
    } else if (this.props.uiControls.modalMode == 'saveas') {
      //
      old_format_project.id = undefined;
      old_format_project.edit_dt = this.props.current_project.edit_dt;
      this.props.dispatch(portalActions.saveProject(old_format_project));
    }
  };
  // PROJECT MANAGER FUNCTION
  // handleLoadProjectRow = (event, record, rowIndex) => {
  //   this.setState({ selectedProject: record.id }, () => {
  //     this.loadProject();
  //   });
  // };
  // worker method - clicked from modal
  // PROJECT MANAGER FUNCTION
  loadProject = async () => {
    this.props.dispatch(portalActions.loadProject(this.props.selectedProject));
  };
  // PROJECT MANAGER FUNCTION
  // *** MOVED TO REDUX ***
  // handleProjectNameUpdate = (value) => {
  //   this.props.dispatch(portalActions.projectNameUpdate(value));
  // };
  // PROJECT MANAGER FUNCTION
  // *** MOVED TO REDUX ***
  // handleProjectSelect = (event, record, rowIndex) => {
  //   this.props.dispatch(portalActions.projectSelect(record.id));
  // };
  // PROJECT MANAGER FUNCTION
  // handleDeleteProject = (record) => {
  //   let project_to_delete = this.props.Projects.find((project) => record.id == project.id);
  //   project_to_delete.active = 0;
  //   this.props.dispatch(portalActions.deleteProject(project_to_delete));
  // };
  // PROJECT MANAGER FUNCTION
  // *** MOVED TO REDUX ***
  // handleClose = () => {
  //   this.props.dispatch(portalActions.toggleOpenClose(false));
  // };

  // PROJECT MANAGER FUNCTION
  // handleFilter = (e) => {
  //   let filter =
  //     matchSorter(this.props.Projects, e.target.value, {
  //       keys: ['name'],
  //     }) || this.props.Projects;

  //   this.setState({
  //     FilteredProject: filter,
  //     filterText: e.target.value,
  //   });
  // };
  // PROJECT MANAGER FUNCTION
  // handleChangeSort = (pagination, filters, sorter) => {
  //   let _sorter = sorter;
  //   if (!sorter.order) {
  //     // interrupt the default functionality
  //     _sorter = this.state.project_sortedInfo;
  //     _sorter.order = 'ascend';
  //   }
  //   this.setState({
  //     filteredInfo: filters,
  //     project_sortedInfo: _sorter,
  //   });
  // };

  // INPUT FUNCTION
  collectInputs = (inc_features = true) => {
    return {
      features: inc_features ? this.props.inputs.features : undefined,

      center: this.props.inputs._map_center,
      azimuth: this.props.inputs.azimuth,
      doRoads: this.props.inputs.doRoads,
      doAlign: this.props.inputs.doAlign,
      selectedProductIndex: this.props.inputs.selectedProductIndex,
      selectedModuleIndex: this.props.inputs.selectedModuleIndex,
      doOverrideTilt: this.props.inputs.doOverrideTilt,
      OverrideTilt: [this.props.inputs.OverrideTilt[0], this.props.inputs.OverrideTilt[1]],
      doOverrideGCR: this.props.inputs.doOverrideGCR,
      OverrideGCR: [this.props.inputs.OverrideGCR[0], this.props.inputs.OverrideGCR[1]],
      doEWShifting: true,
      doOverrideDims: this.props.inputs.doOverrideDims,
      // overrideDims: [this.props.inputs.current_product.filter(product => product.active)],
      product: this.props.inputs.current_product,
      do_continuous: this.props.inputs.do_continuous,

      do_dc_lock: this.props.inputs.do_dc_lock,
      dc_lock_value: isNaN(this.props.inputs.dc_lock_value) == true ? 0 : this.props.inputs.dc_lock_value,

      mod_width: this.props.inputs.mod_width,
      mod_height: this.props.inputs.mod_height,
      mod_string: this.props.inputs.mod_string,
      table_count: this.props.inputs.table_count,

      doTopo: this.props.topoData.topo_live && (this.state.topo_action == 'delete' || this.state.topo_action == 'flag'),
      topo_id: this.props.topoData.topo_id,
      // grade_limit: this.props.inputs.selectedProductIndex == 0 ? 10 : 20,

      grade_limit: this.props.inputs.grade_limit,
      dcac: this.props.inputs.dcac,

      ns_grade_limit: this.props.inputs.ns_grade_limit,
      ew_grade_limit: this.props.inputs.ew_grade_limit,
      u_grade_limit: this.props.inputs.u_grade_limit,
      do_generate_layers: this.props.topoData.do_generate_layers,
      generate_ns: this.props.topoData.layers_generated.NS.avail,
      generate_ew: this.props.topoData.layers_generated.EW.avail,
      generate_u: this.props.topoData.layers_generated.U.avail,
      layers_generated: this.props.topoData.layers_generated,
      topo_action: this.props.topoData.topo_action,

      is_bifacial: this.props.inputs.is_bifacial,
      bifaciality: this.props.inputs.bifaciality,
      bifacial_transmission_factor: this.props.inputs.bifacial_transmission_factor,
      bifacial_ground_clearance_height: this.props.inputs.bifacial_ground_clearance_height,
      bifacial_structure_shade_factor: this.props.inputs.bifacial_structure_shade_factor,

      losses: {
        soiling_loss: this.props.inputs.losses.soiling_loss,
        mismatch_loss: this.props.inputs.losses.mismatch_loss,
        wiring_loss: this.props.inputs.losses.wiring_loss,
        module_lid_loss: this.props.inputs.losses.module_lid_loss,
        module_quality_loss: this.props.inputs.losses.module_quality_loss,
        inverter_eff: this.props.inputs.losses.inverter_eff,
        combined_ac_loss: this.props.inputs.losses.combined_ac_loss,
      },
    };
  };

  // MAP FUNCTION
  handlePolygonRemoved = (id) => {
    // make sure there is nothing selected before updating features
    if (id == this.props.inputs.selectedFeatureId) this.handleInputUpdate('selectedFeatureId', undefined);

    // clone the array without the removed feature
    let clonedFeatures = JSON.parse(JSON.stringify(this.props.inputs.features.filter((feature) => feature.properties.index != id)));
    // dispatch to store
    this.handleInputUpdate('features', clonedFeatures);
  };
  handlePolygonUpdated = (poly, all = false) => {
    let polys = [];

    if (poly.length && poly.length > 0) {
      // append KMZ to map
      polys = [...this.props.inputs.features, ...poly];
      // overwrite KMZ to map
      // polys = [...poly];
      this.props.dispatch(portalActions.uploadKMZFeatures(polys));
    } else if (poly.length == 0) {
      // CLEAR POLYGONS
      this.handleInputUpdate('features', []);
      this.handleInputUpdate('selectedFeatureId', undefined);
    } else {
      // This function being called from Map
      let found_poly = this.props.inputs.features.findIndex((feature) => feature.properties.index == poly.properties.index);

      let selectedPoly = undefined;
      if (found_poly == -1) {
        selectedPoly = poly.properties.index;
        if (this.props.inputs.features.length > 0) {
          // there is already some feature polygons, so we need to append it to the list
          polys = [...this.props.inputs.features, poly];
        } else {
          // this is the first feature polygons
          polys = [poly];
        }
      } else {
        // we're editing a feature polygon within the features
        polys = [...this.props.inputs.features];
        polys[found_poly] = poly;
      }
      // dispatch to store
      this.handleInputUpdate('features', polys);
      if (selectedPoly) this.handleInputUpdate('selectedFeatureId', selectedPoly);
    }

    if (all) {
      this.props.dispatch(portalActions.clearLayout());
    }
  };
  // MAP FUNCTION
  handleCenterUpdated = (center) => {};
  // MAP FUNCTION
  handleAreaUpdated = (area) => {};
  // MAP FUNCTION
  handleSetAzimuth = (azimuth) => {
    this.handleInputUpdate('azimuth', azimuth);
  };

  // RESULTS FUNCTION
  handleSelectResult = (record) => {
    this.setState({ selected_result: record.layout_id, selected_row: record });
    this.props.dispatch(portalActions.selectResult(record));
  };

  // LEAVE THIS HERE
  showBugReport = () => {
    this.setState({ bug_visible: true });
  };
  // LEAVE THIS HERE
  hideBugReport = () => {
    this.setState({ bug_visible: false, bug_sending: false, bug_sent: false });
  };
  // LEAVE THIS HERE
  handleBugReport = (event) => {
    this.setState({ bug_sending: true });
    let params = {
      message: `RBI Bug Report - ${this.state.bug_desc}`,
      surface: JSON.stringify(this.props.inputs.features),
      inputs: JSON.stringify(this.collectInputs()),
    };
    // console.log(params)
    // Send bug report to backend
    // let resp = sendBugReport({...params})
    let resp = sendBugReportSecure({ ...params });

    setTimeout(() => {
      this.setState({
        bug_sent: true,
        bug_sending: false,
      });

      setTimeout(() => {
        this.setState({
          bug_visible: false,
          bug_sent: false,
        });
      }, 2000);
    }, 2000);
  };
  // LEAVE THIS HERE
  handleBugDescUpdate = (desc) => {
    this.setState({ bug_desc: desc });
  };

  // PROJECT MANAGER FUNCTION
  // handleProjectShare = async (email, project_share) => {
  //   let params = {
  //     message: `Project Shared with you.`,
  //     email: email,
  //     name: project_share.name,
  //     surface: project_share.surface,
  //     inputs: project_share.inputs,
  //   };

  //   let resp = await shareProject({ ...params });

  //   return resp;
  // };

  render() {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    let { project_sortedInfo } = this.state;
    project_sortedInfo = project_sortedInfo || {};

    if (this.props.loading) {
      return (
        <div className="loader">
          <Spin />
          <h3> Loading New Project... </h3>
        </div>
      );
    } else {
      const productMenu = (
        <Menu onClick={(param) => this.handleInputUpdate('selectedProductIndex', parseInt(param.key))}>
          {this.props.products.map((opt, i) => (
            <Menu.Item key={i} eventKey={i}>
              {opt.name}
            </Menu.Item>
          ))}
        </Menu>
      );

      const moduleMenu = (
        <Menu
          onClick={(param) => {
            this.handleInputUpdate('selectedModuleIndex', parseInt(param.key));
          }}
          style={{ height: '200px', overflowY: 'auto' }}
        >
          {this.props.modules.map((opt, i) => (
            <Menu.Item key={i} eventKey={i}>
              {opt.name}
            </Menu.Item>
          ))}
        </Menu>
      );

      return (
        <div className="capacity-tool-container">
          <div>
            <GroundMountControlPanel />
            {/* Bug Report Modal */}
            <BugReport
              bug_visible={this.state.bug_visible}
              bug_sending={this.state.bug_sending}
              bug_sent={this.state.bug_sent}
              handleBugReport={this.handleBugReport}
              hideBugReport={this.hideBugReport}
              handleBugDescUpdate={this.handleBugDescUpdate}
            />

            {/* MAP */}
            <Dropzone style={divStyle} onDrop={this.onDrop.bind(this)} onDragEnter={this.onDragEnter.bind(this)} onDragLeave={this.onDragLeave.bind(this)} disableClick={true}>
              {this.state.dropzoneActive ? <div style={overlayStyle}>Drop KMZ...</div> : <div />}

              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <ProjectManager OnTopLevelPressed={this.handleProjectManagerFeedback} saveProject={this._saveProject} project_sortedInfo={project_sortedInfo} />
                  {/* <ProjectManager
                    Projects={this.props.Projects}
                    saving={this.props.uiControls.loading}
                    projectLoaded={this.props.uiControls.projectLoaded}
                    project_loading={this.props.uiControls.project_loading}
                    visible={this.props.uiControls.showProjectLoader}
                    current_project={this.props.current_project}
                    FilteredProjects={this.state.FilteredProjects}
                    selectedProject={this.props.selectedProject}
                    modalMode={this.props.uiControls.modalMode}
                    filterText={this.state.filterText}
                    project_sortedInfo={project_sortedInfo}
                    dispatch={this.props.dispatch}
                    OnTopLevelPressed={this.handleProjectManagerFeedback}
                    saveProject={this._saveProject}
                    onShareProject={this.handleProjectShare}
                    // handleNewProject={this.handleNewProject}
                    // handleSaveProject={this.handleSaveProject}
                    // handleSaveAs={this.handleSaveAs}
                    // handleLoadProject={this.handleLoadProject}

                    handleFilter={this.handleFilter}
                    handleClose={this.handleClose}
                    loadProject={this.loadProject}
                    handleProjectNameUpdate={this.handleProjectNameUpdate}
                    handleLoadProjectRow={this.handleLoadProjectRow}
                    handleDeleteProject={this.handleDeleteProject}
                    handleProjectSelect={this.handleProjectSelect}
                    handleChangeSort={this.handleChangeSort}
                  /> */}
                  <Row>
                    <Typography.Text style={{ marginRight: '8px' }}>Map UI:</Typography.Text>
                    <Switch checkedChildren="On" unCheckedChildren="Off" defaultChecked onChange={(e) => this.props.dispatch(portalActions.toggleMapUI(e))} />
                  </Row>
                </div>

                {/* This is where the map goes */}

                <div className="map-container-wrapper">
                  <SunfigMap
                    dispatch={this.props.dispatch}
                    center={this.props.inputs.map_center}
                    zoom={this.props.inputs.map_zoom}
                    features={this.props.inputs.features}
                    force_zoom={this.props.inputs.map_force_zoom}
                    boundary_bbox={this.props.inputs.boundary_bbox}
                    selectedFeatureId={this.props.inputs.selectedFeatureId}
                    bugReportVisible={this.state.bug_visible}
                    // reset={this.props.inputs.map_reset}
                    resize={this.state.map_resize}
                    project_visible={this.props.uiControls.showProjectLoader}
                    OnPolygonUpdated={this.handlePolygonUpdated}
                    OnPolygonRemoved={this.handlePolygonRemoved}
                    OnMapSearch={this.handleMapSearch}
                    OnUpdateZoom={this.handleMapZoom}
                    OnUpdateArea={this.handleAreaUpdated}
                    OnSetAzimuth={this.handleSetAzimuth}
                    // LoadedFeatures={this.state.Surfaces}
                    // Autolayout={undefined}

                    layout={this.props.layout}
                    county={this.props.inputs.county}
                    stateAbb={this.props.inputs.stateAbb}
                    project_name={this.props.current_project.name}
                    // isAutolayout={this.state.isAutolayout}
                    selectedResult={this.props.selectedResult}
                    topo_live={this.props.topoData.topo_live}
                    topo_url={this.props.topoData.topo_url}
                    topo_scale_url={this.props.topoData.topo_scale_url}
                    topo_mode={this.props.topoData.topo_mode}
                    layers_generated={this.props.topoData.layers_generated}
                    OnUpdateTopoMode={this.handleUpdateTopoMode}
                    toolbarMode={'groundMount'}
                    map_ui_visible={this.props.uiControls.map_ui_visible}
                  />
                </div>

                {/* This is where the prduct/module selection goes */}
                <Row>
                  <Col span={24} offset={0}>
                    <Dropdown overlay={productMenu}>
                      <Button style={{ width: '200px', marginRight: '10px' }}>
                        {this.props.inputs.selectedProductIndex == -1 ? 'Select Ground Product' : this.props.inputs.selectedProduct.name}{' '}
                      </Button>
                    </Dropdown>
                    <Dropdown overlay={moduleMenu}>
                      <Button style={{ width: '200px', marginRight: '10px' }}>
                        {this.props.inputs.selectedModuleIndex == -1 || this.props.inputs.selectedModule === undefined ? 'Select Module' : this.props.inputs.selectedModule.name}{' '}
                      </Button>
                    </Dropdown>
                    <Tooltip placement="right" title="Advanced Options" mouseEnterDelay={0.5}>
                      <Button
                        type="ghost"
                        onClick={() =>
                          this.setState({
                            advancedOptions: !this.state.advancedOptions,
                          })
                        }
                      ></Button>
                    </Tooltip>
                  </Col>
                </Row>

                {/* This is where the advanced options goes */}
                {this.state.advancedOptions && (
                  <Row>
                    <Col xs={24} offset={0}>
                      <Tabs type="card" defaultActiveKey="1" id="tabs">
                        <TabPane tab="Product" key="1">
                          <div
                            style={{
                              padding: '2%',
                              height: '300px',
                              overflowY: 'auto',
                              border: '1px solid #a0a0a0',
                            }}
                          >
                            {this.props.inputs.selectedProductIndex >= 0 ? (
                              <Row>
                                <Row>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    <label>Azimuth:</label>
                                  </Col>
                                  <Col span={3}>
                                    <Input
                                      style={{ width: '85px' }}
                                      value={this.props.inputs.azimuth}
                                      type="number"
                                      id="azimuth"
                                      onChange={(e) => this.handleInputUpdate(e.target.id, parseFloat(e.target.value))}
                                      size="small"
                                      // addonAfter={'°'}
                                      suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>°</span>}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    <label>Module Target:</label>
                                  </Col>
                                  <Col span={1}>
                                    <Switch checked={this.props.inputs.do_dc_lock} onChange={(checked) => this.handleInputUpdate('do_dc_lock', checked)} size="small" />
                                  </Col>

                                  <div>
                                    <Col
                                      span={1}
                                      style={{
                                        textAlign: 'right',
                                        paddingRight: '5px',
                                        lineHeight: '25px',
                                      }}
                                    ></Col>
                                    <Col span={3}>
                                      {this.props.inputs.do_dc_lock && (
                                        <Input
                                          style={{ width: '100px' }}
                                          value={this.props.inputs.dc_lock_value}
                                          disabled={!this.props.inputs.do_dc_lock}
                                          id="dc_lock_value"
                                          type="number"
                                          min="0"
                                          onChange={(e) => this.handleInputUpdate(e.target.id, parseInt(e.target.value))}
                                          size="small"
                                          suffix={<span style={{ color: 'rgba(0,0,0,.45)', fontSize: 'x-small' }}>mod</span>}
                                        />
                                      )}
                                    </Col>
                                  </div>
                                </Row>

                                <Row>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    <label>Grade Limit:</label>
                                  </Col>
                                  <Col span={3}>
                                    <Input
                                      style={{ width: '85px' }}
                                      value={this.props.inputs.grade_limit}
                                      id="grade_limit"
                                      type="number"
                                      onChange={(e) => {
                                        this.handleInputUpdate(e.target.id, parseFloat(e.target.value));
                                      }}
                                      size="small"
                                      // addonAfter={'%'}
                                      suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    <label>Road Spacing:</label>
                                  </Col>
                                  <Col span={10}>
                                    <div>
                                      {this.props.inputs.selectedProductIndex == 0 && !this.props.inputs.doAlign ? (
                                        <span style={{ lineHeight: '25px' }}>Roads automatically disabled when not Aligned</span>
                                      ) : (
                                        <Switch
                                          checked={this.props.inputs.doRoads}
                                          size="small"
                                          onChange={(checked) => {
                                            this.handleInputUpdate('doRoads', checked);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    <label>Racking Align:</label>
                                  </Col>
                                  <Col span={3}>
                                    <div>
                                      <Switch
                                        checked={this.props.inputs.doAlign}
                                        size="small"
                                        onChange={(checked) => {
                                          this.handleInputUpdate('doAlign', checked);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    <label>GCR Override:</label>
                                  </Col>
                                  <Col span={1}>
                                    <Switch checked={this.props.inputs.doOverrideGCR} onChange={(checked) => this.handleInputUpdate('doOverrideGCR', checked)} size="small" />
                                  </Col>

                                  {this.props.inputs.doOverrideGCR && (
                                    <div>
                                      <Col
                                        span={1}
                                        style={{
                                          textAlign: 'right',
                                          paddingRight: '5px',
                                          lineHeight: '25px',
                                        }}
                                      >
                                        min
                                      </Col>
                                      <Col span={3}>
                                        <Input
                                          style={{ width: '85px' }}
                                          type="number"
                                          value={this.props.inputs.OverrideGCR[0]}
                                          onChange={(e) => {
                                            this.handleInputUpdate('changeMinGCR', e.target.value);
                                          }}
                                          // min="0.2"
                                          // max="0.85"
                                          step="0.01"
                                          size="small"
                                          // addonAfter={' '}
                                          suffix={<span style={{ color: 'rgba(0,0,0,.45)', fontSize: 'x-small' }}>gcr</span>}
                                        />
                                      </Col>
                                      <Col
                                        span={1}
                                        style={{
                                          textAlign: 'right',
                                          paddingRight: '5px',
                                          lineHeight: '25px',
                                        }}
                                      >
                                        max
                                      </Col>
                                      <Col span={3}>
                                        <Input
                                          style={{ width: '85px' }}
                                          type="number"
                                          value={this.props.inputs.OverrideGCR[1]}
                                          onChange={(e) => {
                                            this.handleInputUpdate('changeMaxGCR', e.target.value);
                                          }}
                                          // min="0.2"
                                          // max="0.85"
                                          step="0.01"
                                          size="small"
                                          // addonAfter={' '}
                                          suffix={<span style={{ color: 'rgba(0,0,0,.45)', fontSize: 'x-small' }}>gcr</span>}
                                        />
                                      </Col>
                                    </div>
                                  )}
                                </Row>

                                {this.props.inputs.selectedProductIndex > 0 && (
                                  <div>
                                    <Row>
                                      <Col
                                        span={5}
                                        style={{
                                          textAlign: 'right',
                                          paddingRight: '5px',
                                          lineHeight: '25px',
                                        }}
                                      >
                                        <label>Display Continuous:</label>
                                      </Col>
                                      <Col span={3}>
                                        <div>
                                          <Switch
                                            checked={this.props.inputs.do_continuous}
                                            size="small"
                                            onChange={(checked) => {
                                              this.handleInputUpdate('do_continuous', checked);
                                            }}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col
                                        span={5}
                                        style={{
                                          textAlign: 'right',
                                          paddingRight: '5px',
                                          lineHeight: '25px',
                                        }}
                                      >
                                        <label>Tilt Angle Override:</label>
                                      </Col>
                                      <Col span={1}>
                                        <Switch
                                          size="small"
                                          checked={this.props.inputs.doOverrideTilt}
                                          onChange={(checked) => {
                                            this.handleInputUpdate('doOverrideTilt', checked);
                                          }}
                                        />
                                      </Col>
                                      {this.props.inputs.doOverrideTilt && (
                                        <div>
                                          <Col
                                            span={1}
                                            style={{
                                              textAlign: 'right',
                                              paddingRight: '5px',
                                              lineHeight: '25px',
                                            }}
                                          >
                                            min
                                          </Col>
                                          <Col span={3}>
                                            <Input
                                              style={{ width: '85px' }}
                                              size="small"
                                              type="number"
                                              value={this.props.inputs.OverrideTilt[0].toString()}
                                              onChange={(e) => {
                                                this.handleInputUpdate('changeMinTilt', parseFloat(e.target.value));
                                              }}
                                              min="0"
                                              max="45"
                                              // addonAfter={'°'}
                                              suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>°</span>}
                                            />
                                          </Col>
                                          <Col
                                            span={1}
                                            style={{
                                              textAlign: 'right',
                                              paddingRight: '5px',
                                              lineHeight: '25px',
                                            }}
                                          >
                                            max
                                          </Col>
                                          <Col span={3}>
                                            <Input
                                              style={{ width: '85px' }}
                                              size="small"
                                              type="number"
                                              value={this.props.inputs.OverrideTilt[1].toString()}
                                              onChange={(e) => {
                                                this.handleInputUpdate('changeMaxTilt', parseFloat(e.target.value));
                                              }}
                                              min="0"
                                              max="45"
                                              // addonAfter={'°'}
                                              suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>°</span>}
                                            />
                                          </Col>
                                        </div>
                                      )}
                                    </Row>
                                  </div>
                                )}

                                <Row>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    <div style={{ position: 'relative' }}>
                                      <label>Dimension Override:</label>
                                    </div>
                                  </Col>
                                  <Col span={3}>
                                    <div>
                                      <Switch
                                        checked={this.props.inputs.doOverrideDims}
                                        size="small"
                                        onChange={(checked) => {
                                          this.handleInputUpdate('doOverrideDims', checked);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                {this.props.inputs.doOverrideDims && (
                                  <div>
                                    <Row>
                                      {/* <Col span={10} offset={5}> */}
                                      {/* {this.props.inputs.selectedProductIndex >= 0 && this.props.inputs.doOverrideDims && */}
                                      <div style={{ margin: 'auto' }}>
                                        <div
                                          style={{
                                            float: 'left',
                                            position: 'relative',
                                            left: '160px',
                                            width: '420px',
                                          }}
                                        >
                                          <Table
                                            columns={[
                                              {
                                                title: '#',
                                                dataIndex: 'key',
                                                align: 'center',
                                                width: '5px',
                                                render: (text, record, index) => <span>{letters[index]}</span>,
                                              },
                                              {
                                                title: 'Active',
                                                dataIndex: 'active',
                                                align: 'center',
                                                width: '55px',
                                                render: (text, record, index) => (
                                                  // TODO INPUTS
                                                  <Checkbox
                                                    checked={this.props.inputs.current_product[index].active == 1}
                                                    disabled={!this.props.inputs.doOverrideDims}
                                                    style={{
                                                      marginTop: '0px',
                                                      marginBottom: '0px',
                                                    }}
                                                    onChange={(e) => {
                                                      this.handleInputUpdate('updateOverrideDimsCheckBox', index);
                                                    }}
                                                  />
                                                ),
                                              },
                                              {
                                                title: 'X (m)',
                                                dataIndex: 'xdim',
                                                align: 'center',
                                                width: this.props.inputs.selectedProductIndex == 0 ? '90px' : '110px',
                                                render: (text, record, index) => {
                                                  const obj = {
                                                    children: text,
                                                    props: {},
                                                  };

                                                  if (this.props.inputs.selectedProductIndex == 0) {
                                                    // only combine this column for SAT
                                                    if (index === 0) {
                                                      obj.props.rowSpan = 4;
                                                    }
                                                    // merge columns after index0
                                                    if (index > 0) {
                                                      obj.props.rowSpan = 0;
                                                      obj.props.colSpan = 0;
                                                    }
                                                  }

                                                  obj.children = (
                                                    <div style={{ width: this.props.inputs.selectedProductIndex == 0 ? '90px' : '110px' }}>
                                                      <Input
                                                        size="small"
                                                        type="number"
                                                        disabled={!this.props.inputs.doOverrideDims}
                                                        value={typeof text == 'number' ? text.toFixed(3) : text}
                                                        onChange={(e) => {
                                                          this.handleInputUpdate('setOverrideXDims', {
                                                            'dim': e.target.value,
                                                            index,
                                                          });
                                                        }}
                                                        suffix={<span style={{ color: 'rgba(0,0,0,.45)', fontSize: 'x-small' }}>m</span>}
                                                      />
                                                    </div>
                                                  );

                                                  return obj;
                                                },
                                              },
                                              {
                                                title: 'Y (m)',
                                                dataIndex: 'ydim',
                                                align: 'center',
                                                width: this.props.inputs.selectedProductIndex == 0 ? '110px' : '90px',
                                                render: (text, record, index) => {
                                                  const obj = {
                                                    children: text,
                                                    props: {},
                                                  };

                                                  if (this.props.inputs.selectedProductIndex > 0) {
                                                    // only combine this column for GFT
                                                    if (index === 0) {
                                                      obj.props.rowSpan = 3;
                                                    }
                                                    // merge columns after index0
                                                    if (index === 1) {
                                                      obj.props.rowSpan = 0;
                                                      obj.props.colSpan = 0;
                                                    }
                                                    if (index === 2) {
                                                      obj.props.rowSpan = 0;
                                                      obj.props.colSpan = 0;
                                                    }
                                                  }
                                                  obj.children = (
                                                    <div style={{ width: this.props.inputs.selectedProductIndex == 0 ? '110px' : '90px' }}>
                                                      <Input
                                                        size="small"
                                                        type="number"
                                                        disabled={!this.props.inputs.doOverrideDims}
                                                        value={typeof text == 'number' ? text.toFixed(3) : text}
                                                        onChange={(e) => {
                                                          this.handleInputUpdate('setOverrideYDims', {
                                                            'dim': e.target.value,
                                                            index,
                                                          });
                                                        }}
                                                        suffix={<span style={{ color: 'rgba(0,0,0,.45)', fontSize: 'x-small' }}>m</span>}
                                                      />
                                                    </div>
                                                  );

                                                  return obj;
                                                },
                                              },
                                              {
                                                title: 'Modules',
                                                dataIndex: 'modules',
                                                align: 'center',
                                                width: '75px',
                                                render: (text, record, index) => (
                                                  <div style={{ width: '75px' }}>
                                                    <Input
                                                      size="small"
                                                      type="number"
                                                      disabled={!this.props.inputs.doOverrideDims}
                                                      value={record.module}
                                                      onChange={(e) => {
                                                        this.handleInputUpdate('setOverrideModules', {
                                                          'module': parseInt(e.target.value),
                                                          index,
                                                        });
                                                      }}
                                                      suffix={<span style={{ color: 'rgba(0,0,0,.45)', fontSize: 'x-small' }}>mod</span>}
                                                    />
                                                  </div>
                                                ),
                                              },
                                            ]}
                                            dataSource={this.props.inputs.current_product}
                                            size="small"
                                            className="dims-row"
                                            pagination={false}
                                            rowKey="key"
                                          />
                                        </div>
                                        <div
                                          style={{
                                            float: 'left',
                                            position: 'relative',
                                            left: '160px',
                                          }}
                                        >
                                          <Table
                                            columns={[
                                              {
                                                title: 'Dimension Calc',
                                                dataIndex: 'calc',
                                                key: 'calc',
                                                render: (text, record, index) => {
                                                  const obj = {
                                                    children: text,
                                                    props: {},
                                                  };
                                                  // [⠀]<---INVIS CHARACTER
                                                  obj.children = (
                                                    <div style={{ width: '170px' }}>
                                                      <div
                                                        style={{
                                                          lineHeight: '31px',
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            float: 'left',
                                                            position: 'relative',
                                                          }}
                                                        >
                                                          Mod X
                                                        </div>
                                                        <div
                                                          style={{
                                                            float: 'right',
                                                          }}
                                                        >
                                                          <Input
                                                            size="small"
                                                            style={{
                                                              width: '100px',
                                                            }}
                                                            type="number"
                                                            value={this.props.inputs.mod_width}
                                                            onChange={(e) => {
                                                              this.handleInputUpdate('mod_width', e.target.value);
                                                            }}
                                                            // addonAfter={'m'}
                                                            suffix={<span style={{ color: 'rgba(0,0,0,.45)', fontSize: 'x-small' }}>m</span>}
                                                          />
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: '31px',
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            float: 'left',
                                                            position: 'relative',
                                                          }}
                                                        >
                                                          Mod Y
                                                        </div>
                                                        <div
                                                          style={{
                                                            float: 'right',
                                                          }}
                                                        >
                                                          <Input
                                                            size="small"
                                                            style={{
                                                              width: '100px',
                                                            }}
                                                            value={this.props.inputs.mod_height}
                                                            type="number"
                                                            onChange={(e) => {
                                                              this.handleInputUpdate('mod_height', e.target.value);
                                                            }}
                                                            // addonAfter={'m'}
                                                            suffix={<span style={{ color: 'rgba(0,0,0,.45)', fontSize: 'x-small' }}>m</span>}
                                                          />
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          lineHeight: '31px',
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            float: 'left',
                                                            position: 'relative',
                                                          }}
                                                        >
                                                          Mod/Str
                                                        </div>
                                                        <div
                                                          style={{
                                                            float: 'right',
                                                          }}
                                                        >
                                                          <Input
                                                            size="small"
                                                            style={{
                                                              width: '100px',
                                                            }}
                                                            value={this.props.inputs.mod_string}
                                                            type="number"
                                                            onChange={(e) => {
                                                              this.handleInputUpdate('mod_string', parseInt(e.target.value));
                                                            }}
                                                            // addonAfter={' '}
                                                            suffix={<span style={{ color: 'rgba(0,0,0,.45)', fontSize: 'x-small' }}>mod</span>}
                                                          />
                                                        </div>
                                                      </div>
                                                      {this.props.inputs.selectedProductIndex == 0 && (
                                                        <div
                                                          style={{
                                                            lineHeight: '31px',
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              float: 'left',
                                                              position: 'relative',
                                                            }}
                                                          >
                                                            Table
                                                          </div>
                                                          <div
                                                            style={{
                                                              float: 'right',
                                                            }}
                                                          >
                                                            <Input
                                                              size="small"
                                                              style={{
                                                                width: '100px',
                                                              }}
                                                              value={this.props.inputs.table_count}
                                                              type="number"
                                                              onChange={(e) => {
                                                                this.handleInputUpdate('table_count', parseInt(e.target.value));
                                                              }}
                                                              // addonAfter={' '}
                                                              suffix={<span style={{ color: 'rgba(0,0,0,.45)', fontSize: 'x-small' }}>tab</span>}
                                                            />
                                                          </div>
                                                        </div>
                                                      )}
                                                      <Button
                                                        onClick={this.calcDimensions}
                                                        size="small"
                                                        type="primary"
                                                        disabled={!this.props.inputs.doOverrideDims}
                                                        style={{
                                                          width: '75px',
                                                          float: 'right',
                                                        }}
                                                      >
                                                        <span></span>
                                                      </Button>
                                                    </div>
                                                  );

                                                  return obj;
                                                },
                                              },
                                            ]}
                                            size="small"
                                            className="dims-row"
                                            pagination={false}
                                            rowKey="calc"
                                            dataSource={[{ calc: 'SAT' }]}
                                          />
                                        </div>
                                      </div>
                                      {/* } */}
                                      {/* </Col> */}
                                    </Row>
                                  </div>
                                )}
                              </Row>
                            ) : (
                              <div style={{ padding: '7%' }}>
                                <Empty description="Please Select a Product" />
                              </div>
                            )}
                          </div>
                        </TabPane>
                        <TabPane tab="Performance" key="2">
                          <div
                            style={{
                              padding: '2%',
                              height: '300px',
                              overflowY: 'auto',
                              border: '1px solid #a0a0a0',
                            }}
                          >
                            {this.props.inputs.selectedProductIndex >= 0 ? (
                              <Row>
                                <Row>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    Soiling:
                                  </Col>
                                  <Col span={3}>
                                    <Input
                                      size="small"
                                      style={{ width: '85px' }}
                                      type="number"
                                      value={this.props.inputs.losses.soiling_loss}
                                      onChange={(e) => {
                                        this.handleInputUpdate('setSoilingLosses', {
                                          key: 'soiling_loss',
                                          payload: e.target.value,
                                        });
                                      }}
                                      min="0"
                                      max="100"
                                      // addonAfter={'%'}
                                      suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
                                    />
                                  </Col>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    Bifacial Module
                                  </Col>
                                  <Col span={1}>
                                    <Switch checked={this.props.inputs.is_bifacial} onChange={(checked) => this.handleInputUpdate('is_bifacial', checked)} size="small" />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    DC Mismatch:
                                  </Col>
                                  <Col span={3}>
                                    <Input
                                      size="small"
                                      style={{ width: '85px' }}
                                      type="number"
                                      value={this.props.inputs.losses.mismatch_loss}
                                      onChange={(e) => {
                                        this.handleInputUpdate('setSoilingLosses', {
                                          key: 'mismatch_loss',
                                          payload: e.target.value,
                                        });
                                      }}
                                      min="0"
                                      max="100"
                                      // addonAfter={'%'}
                                      suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
                                    />
                                  </Col>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    Bifaciality Factor
                                  </Col>
                                  <Col span={3}>
                                    <Input
                                      size="small"
                                      style={{ width: '85px' }}
                                      // disabled
                                      type="number"
                                      value={this.props.inputs.bifaciality}
                                      onChange={(e) => {
                                        this.handleInputUpdate('bifaciality', e.target.value);
                                        // this.state.bifaciality = e.target.value;
                                        // this.forceUpdate();
                                      }}
                                      min="0"
                                      max="100"
                                      // addonAfter={' '}
                                      suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}> </span>}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    DC Wiring:
                                  </Col>
                                  <Col span={3}>
                                    <Input
                                      size="small"
                                      style={{ width: '85px' }}
                                      type="number"
                                      value={this.props.inputs.losses.wiring_loss}
                                      onChange={(e) => {
                                        this.handleInputUpdate('setSoilingLosses', {
                                          key: 'wiring_loss',
                                          payload: e.target.value,
                                        });
                                        // this.state.wiring_loss = e.target.value;
                                        // this.forceUpdate();
                                      }}
                                      min="0"
                                      max="100"
                                      // addonAfter={'%'}
                                      suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
                                    />
                                  </Col>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    Clearance Height
                                  </Col>
                                  <Col span={3}>
                                    <Input
                                      size="small"
                                      style={{ width: '85px' }}
                                      // disabled
                                      type="number"
                                      value={this.props.inputs.bifacial_ground_clearance_height}
                                      onChange={(e) => {
                                        this.handleInputUpdate('bifacial_ground_clearance_height', e.target.value);
                                        // this.state.clearance_height = e.target.value;
                                        // this.forceUpdate();
                                      }}
                                      min="0"
                                      max="100"
                                      // addonAfter={'m'}
                                      suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>m</span>}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    Module LID:
                                  </Col>
                                  <Col span={3}>
                                    <Input
                                      size="small"
                                      style={{ width: '85px' }}
                                      type="number"
                                      value={this.props.inputs.losses.module_lid_loss}
                                      onChange={(e) => {
                                        this.handleInputUpdate('setSoilingLosses', {
                                          key: 'module_lid_loss',
                                          payload: e.target.value,
                                        });
                                        // this.state.module_lid_loss = e.target.value;
                                        // this.forceUpdate();
                                      }}
                                      min="0"
                                      max="100"
                                      // addonAfter={'%'}
                                      suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
                                    />
                                  </Col>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    Transmission %
                                  </Col>
                                  <Col span={3}>
                                    <Input
                                      size="small"
                                      style={{ width: '85px' }}
                                      // disabled
                                      type="number"
                                      value={this.props.inputs.bifacial_transmission_factor}
                                      onChange={(e) => {
                                        this.handleInputUpdate('bifacial_transmission_factor', e.target.value);
                                      }}
                                      min="0"
                                      max="100"
                                      // addonAfter={'%'}
                                      suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    Module Quality:
                                  </Col>
                                  <Col span={3}>
                                    <Input
                                      size="small"
                                      style={{ width: '85px' }}
                                      type="number"
                                      value={this.props.inputs.losses.module_quality_loss}
                                      onChange={(e) => {
                                        this.handleInputUpdate('setSoilingLosses', {
                                          key: 'module_quality_loss',
                                          payload: e.target.value,
                                        });
                                      }}
                                      min="-100"
                                      max="100"
                                      // addonAfter={'%'}
                                      suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
                                    />
                                  </Col>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    Structure Shade %
                                  </Col>
                                  <Col span={3}>
                                    <Input
                                      size="small"
                                      style={{ width: '85px' }}
                                      // disabled
                                      type="number"
                                      value={this.props.inputs.bifacial_structure_shade_factor}
                                      onChange={(e) => {
                                        this.handleInputUpdate('bifacial_structure_shade_factor', e.target.value);
                                      }}
                                      min="0"
                                      max="100"
                                      // addonAfter={'%'}
                                      suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    Inverter Efficiency:
                                  </Col>
                                  <Col span={3}>
                                    <Input
                                      size="small"
                                      style={{ width: '85px' }}
                                      type="number"
                                      value={this.props.inputs.losses.inverter_eff}
                                      onChange={(e) => {
                                        this.handleInputUpdate('setSoilingLosses', {
                                          key: 'inverter_eff',
                                          payload: e.target.value,
                                        });
                                      }}
                                      min="0"
                                      max="100"
                                      // addonAfter={'%'}
                                      suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    Combined AC Losses:
                                  </Col>
                                  <Col span={3}>
                                    <Input
                                      size="small"
                                      style={{ width: '85px' }}
                                      type="number"
                                      value={this.props.inputs.losses.combined_ac_loss}
                                      onChange={(e) => {
                                        this.handleInputUpdate('setSoilingLosses', {
                                          key: 'combined_ac_loss',
                                          payload: e.target.value,
                                        });
                                      }}
                                      min="0"
                                      max="100"
                                      // addonAfter={'%'}
                                      suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                      lineHeight: '25px',
                                    }}
                                  >
                                    DC:AC:
                                  </Col>
                                  <Col span={3}>
                                    <Input
                                      size="small"
                                      style={{ width: '85px' }}
                                      type="number"
                                      value={this.props.inputs.dcac}
                                      onChange={(e) => {
                                        this.handleInputUpdate('dcac', e.target.value);
                                      }}
                                      min="0"
                                      max="3.0"
                                      // addonAfter={'%'}
                                      suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}> </span>}
                                    />
                                  </Col>
                                </Row>
                              </Row>
                            ) : (
                              <div style={{ padding: '7%' }}>
                                <Empty description="Please Select a Product" />
                              </div>
                            )}
                          </div>
                        </TabPane>
                        <TabPane tab="Topo" key="3">
                          <div
                            style={{
                              padding: '2%',
                              height: '300px',
                              overflowY: 'auto',
                              border: '1px solid #a0a0a0',
                            }}
                          >
                            {this.props.inputs.selectedProductIndex >= 0 ? (
                              <div>
                                {/* STATUS ROW */}
                                <Row style={rowStyle}>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                    }}
                                  >
                                    Status:
                                  </Col>
                                  <Col span={12}>
                                    {this.props.topoData.topo_live ? (
                                      <span>Elevation data ready. View elevation layer in the map.</span>
                                    ) : (
                                      <span>No elevation data. Verify boundary in map before importing elevation.</span>
                                    )}
                                  </Col>
                                </Row>
                                <div style={{ height: '10px' }}></div>
                                {/* SOURCE ROW */}
                                <Row style={rowStyle}>
                                  <Col
                                    span={5}
                                    style={{
                                      textAlign: 'right',
                                      paddingRight: '5px',
                                    }}
                                  >
                                    Source:
                                  </Col>
                                  <Col span={3}>
                                    <Radio.Group value={'USGS'}>
                                      <Radio style={radioStyle} value={'USGS'}>
                                        USGS
                                      </Radio>
                                    </Radio.Group>
                                  </Col>
                                </Row>
                                <div style={{ height: '5px' }}></div>

                                <Row style={rowStyle}>
                                  <Col offset={5} span={12}>
                                    {/* NS GRADE ROW */}
                                    <Row style={rowStyle}>
                                      <Col span={6}>
                                        <span> NS Grade</span>
                                      </Col>
                                      <Col
                                        span={4}
                                        style={{
                                          textAlign: 'right',
                                          paddingRight: '5px',
                                          lineHeight: '25px',
                                        }}
                                      >
                                        Grade Limit:
                                      </Col>
                                      <Col span={3}>
                                        <Input
                                          size="small"
                                          style={{ width: '85px' }}
                                          type="number"
                                          min="0"
                                          max="100"
                                          value={this.props.inputs.ns_grade_limit}
                                          disabled={this.props.topoData.topo_live}
                                          onChange={(e) => {
                                            this.handleInputUpdate('adjustTopo_ns_grade_limit', e.target.value);
                                            // this.props.inputs.ns_grade_limit = e.target.value;
                                            // this.forceUpdate();
                                          }}
                                          // addonAfter={'%'}
                                          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
                                        />
                                      </Col>
                                    </Row>

                                    {/* EW GRADE ROW */}
                                    <Row style={rowStyle}>
                                      <Col span={6}>
                                        <span> EW Grade</span>
                                      </Col>
                                      <Col
                                        span={4}
                                        style={{
                                          textAlign: 'right',
                                          paddingRight: '5px',
                                          lineHeight: '25px',
                                        }}
                                      >
                                        Grade Limit:
                                      </Col>
                                      <Col span={3}>
                                        <Input
                                          size="small"
                                          style={{ width: '85px' }}
                                          type="number"
                                          min="0"
                                          max="100"
                                          value={this.props.inputs.ew_grade_limit}
                                          disabled={this.props.topoData.topo_live}
                                          onChange={(e) => {
                                            this.handleInputUpdate('adjustTopo_ew_grade_limit', e.target.value);
                                          }}
                                          // addonAfter={'%'}
                                          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
                                        />
                                      </Col>
                                    </Row>

                                    {/* UNIVERSAL GRADE ROW */}
                                    <Row style={rowStyle}>
                                      <Col span={6}>
                                        <span> Universal Grade</span>
                                      </Col>
                                      <Col
                                        span={4}
                                        style={{
                                          textAlign: 'right',
                                          paddingRight: '5px',
                                          lineHeight: '25px',
                                        }}
                                      >
                                        Grade Limit:
                                      </Col>
                                      <Col span={3}>
                                        <Input
                                          size="small"
                                          style={{ width: '85px' }}
                                          type="number"
                                          min="0"
                                          max="100"
                                          value={this.props.inputs.u_grade_limit}
                                          disabled={this.props.topoData.topo_live}
                                          onChange={(e) => {
                                            this.handleInputUpdate('adjustTopo_u_grade_limit', e.target.value);
                                          }}
                                          // addonAfter={'%'}
                                          suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>%</span>}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>

                                {/* IMPORT BUTTON ROW */}
                                <Row style={rowStyle}>
                                  <Col offset={5} span={3}>
                                    {this.props.topoData.topo_live ? (
                                      <Popconfirm
                                        title="Are you sure you want to remove all topography data, including visual layers, from this project?"
                                        onConfirm={() => {
                                          this.handleInputUpdate('clearTopoData');
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <Button type="primary" loading={this.props.topoData.topo_loading} ghost>
                                          <span>Clear All Topo Data</span>
                                        </Button>
                                      </Popconfirm>
                                    ) : (
                                      <Tooltip
                                        placement="right"
                                        mouseEnterDelay={0.5}
                                        title={
                                          <span style={{ width: '300px' }}>
                                            It can take up to 2 minutes to import the requested elevation data from USGS. Elevation request will only cover arreas on the map marked as boundaries
                                            (red). Please ensure your boundaries are complete before proceeding.
                                          </span>
                                        }
                                      >
                                        <Button onClick={() => this.handlePullTopo()} type="primary" loading={this.props.topoData.topo_loading} ghost>
                                          <span>Import Elevation</span>
                                        </Button>
                                      </Tooltip>
                                    )}
                                  </Col>
                                </Row>

                                {/* ACTION/LAYER OPTIONS */}
                                {this.props.topoData.topo_live && (
                                  <div style={{ paddingTop: '15px' }}>
                                    {/* LAYOUT ACTION ROW */}
                                    <Row style={rowStyle}>
                                      <Col
                                        span={5}
                                        style={{
                                          textAlign: 'right',
                                          paddingRight: '5px',
                                        }}
                                      >
                                        Layout Actions:
                                      </Col>
                                      <Col span={3}>
                                        <Radio.Group
                                          value={this.state.topo_action}
                                          onChange={(e) => {
                                            this.setState({
                                              topo_action: e.target.value,
                                            });
                                            this.forceUpdate();
                                          }}
                                        >
                                          <Radio style={radioStyle} value={'delete'}>
                                            Remove Racks out of Grade Spec
                                          </Radio>
                                          <Radio style={radioStyle} value={'flag'} disabled>
                                            Highlight Racks out of Grade Spec
                                          </Radio>
                                          <Radio style={radioStyle} value={'nothing'}>
                                            No Action, Ignore Grade
                                          </Radio>
                                        </Radio.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div style={{ padding: '7%' }}>
                                <Empty description="Please Select a Product" />
                              </div>
                            )}
                          </div>
                        </TabPane>
                        ?
                      </Tabs>
                    </Col>
                  </Row>
                )}

                {/* This is where the Submit button is */}
                <Row>
                  <Col xs={12} offset={0}>
                    <Button
                      onClick={this.handleSubmit}
                      type="primary"
                      // disabled={this.props.run_state.isRunning}
                      loading={this.props.run_state.isRunning}
                      style={{ marginRight: '10px' }}
                    >
                      GENERATE RESULTS
                    </Button>

                    {this.props.run_state.isRunning && (
                      <Button
                        onClick={() => {
                          this.handleCancelRun();
                        }}
                        type="danger"
                        disabled={!this.props.inputs.run_id}
                        loading={this.props.uiControls.canceling}
                      >
                        CANCEL SIMULATION
                      </Button>
                    )}
                    {this.props.inputs.inputsHaveChanged && this.props.run_state.isRunning !== true && (
                      <Typography.Text style={{ marginLeft: '10px' }} type="danger">
                        *Inputs have changed since last results were generated
                      </Typography.Text>
                    )}
                  </Col>
                </Row>
              </div>
            </Dropzone>

            <Row>
              {this.props.run_state.isRunning && (
                <Col xs={24} offset={0}>
                  {!this.props.run_state.isGenerating ? (
                    <div className="loader results">
                      <Spin />
                      <h3> Initializing... </h3>
                    </div>
                  ) : (
                    <div className="loader results">
                      <div style={{ textAlign: 'center' }}>
                        <Progress type="circle" percent={this.props.run_state.result_count} strokeColor="#117e39" />
                      </div>
                    </div>
                  )}
                </Col>
              )}
            </Row>

            {/* RESULTS */}

            {this.props.run_state.hasResults && <GroundMountResults />}
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  const { portal } = state;
  let run_state = {
    hasResults: portal.hasResults,
    result_count: portal.result_count,
    isRunning: portal.isRunning,
    isGenerating: portal.isGenerating,
    results: portal.results,
    csvResults: portal.csvResults,
    tsvResult: portal.tsvResult,
  };
  return {
    loading: portal.loading,
    Projects: portal.Projects,
    current_project: portal.current_project,
    selectedProject: portal.selectedProject,
    uiControls: portal.uiControls,
    products: portal.products,

    product_data: portal.product_data,
    modules: portal.modules,
    inputs: { ...portal.inputs },
    topoData: { ...portal.topoData },
    layout: { ...portal.layout },
    run_state: run_state,
    selectedResult: portal.selectedResult,
  };
}

const connectedStore = connect(mapStateToProps)(Portal);
export { connectedStore as Portal };
