import React, { useState, useEffect, useRef, Fragment } from "react";
import { Map, LayerGroup, GeoJSON } from "react-leaflet";
import LeafletControl from "../Sunfig/MapControl";

//styles
import { ReportMapWrap } from "../LiveReport/styles";

// redux
import { useSelector } from "react-redux";

// components
import { LiveReportZoomAndLayerControl } from "./";
import { ImageLayer } from "../Canopy/components/ImageLayer";
// helpers
import { getBounds } from "../../utils/map_helper";

import BingLayer from "../Sunfig/Bing";

const bing_key = "Apg1Wo2sThhv7onPyHncSIy7J3Nn98FFIzAhWYNFDrNLDpCRkZaSic1uuBHRoppM";
const TileSets = {
  Satellite: "Aerial",
  "Satellite With Labels": "AerialWithLabels",
  "Satellite With OnDemand Labels": "AerialWithLabelsOnDemand",
  "Street Map": "Road",
  // "Street Map Dark": "CanvasDark",
  // "CanvasLight": "CanvasLight",
  // "CanvasGray": "CanvasGray"
};

const LiveCanopyReportMap = ({ canopyId, interactiveMap, setRef, getRef, setCanopyMapRefs }) => {
  const carport = useSelector((state) => state.carport);

  const [activeTileSet, setActivetileSet] = useState("Satellite");
  const [showControls, setShowControls] = useState(false);
  const [zoom, setZoom] = useState(16);
  const [bingLayerVisible, setBingLayerVisible] = useState(true);
  const [refresh, setRefresh] = useState();

  const canopyReportMap = useRef();
  const canopies = useRef({});

  useEffect(() => {
    setBingLayerVisible(true);
  }, [activeTileSet]);

  const onChangeTileset = (tileset) => {
    setBingLayerVisible(false);
    setActivetileSet(tileset);
  };

  useEffect(() => {
    setRefresh(create_UUID());
    if (interactiveMap) {
      setCanopyMapRefs("mainMap", canopyReportMap.current);
    }
    setTimeout(() => {
      handleLiveCanopyZoomExtents();
    }, 500);
  }, []);

  useEffect(() => {
    let fixed_data = {};
    Object.values(carport.visual).map((vis) => {
      if (interactiveMap) {
        fixed_data[vis.id] = { ...vis };
      } else {
        fixed_data[vis.id] = {
          id: vis.id,
          structure: vis.structure,
          modules: {},
        };
      }
    });

    canopies.current = fixed_data;
  }, []);

  const create_UUID = () => {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  };

  const handleCanopyZoom = (zoom) => {
    setZoom(zoom);
  };

  const handleLiveCanopyZoomExtents = (zoomLevel = undefined) => {
    // check to make sure there are canopies present on the map
    if (Object.values(canopies.current).length == 0) return;
    // define canopy feature array
    let allCanopyFeatures = [];
    // loop through all the canopies and push the geoJson into allCanopyFeatures array
    Object.values(canopies.current).map((canopy) => {
      if (canopy.structure.features == undefined) {
        // polygon structure -- meaning no modules have been turned off
        allCanopyFeatures.push(canopy.structure);
      } else {
        allCanopyFeatures.push(canopy.structure.features[0]);
      }
    });

    // get the bounds of the features collection
    let bounds = getBounds(allCanopyFeatures);

    if (interactiveMap) {
      if (zoomLevel) {
        canopyReportMap?.current?.leafletElement.fitBounds(
          [
            [bounds[1], bounds[0]],
            [bounds[3], bounds[2]],
          ],
          { maxZoom: zoomLevel }
        );
      } else {
        canopyReportMap?.current?.leafletElement.fitBounds([
          [bounds[1], bounds[0]],
          [bounds[3], bounds[2]],
        ]);
      }
    } else {
      let liveCanopyMapRef = getRef(`liveMap_${canopyId}`);
      if (zoomLevel) {
        liveCanopyMapRef.current &&
          liveCanopyMapRef.current.leafletElement.fitBounds(
            [
              [bounds[1], bounds[0]],
              [bounds[3], bounds[2]],
            ],
            { maxZoom: zoomLevel }
          );
      } else {
        liveCanopyMapRef.current &&
          liveCanopyMapRef.current.leafletElement.fitBounds([
            [bounds[1], bounds[0]],
            [bounds[3], bounds[2]],
          ]);
      }
      setCanopyMapRefs(`liveMap_${canopyId}`, liveCanopyMapRef.current);
    }
  };

  return (
    <ReportMapWrap onMouseEnter={() => setShowControls(true)} onMouseLeave={() => setShowControls(false)}>
      <Map
        ref={interactiveMap ? canopyReportMap : setRef(`liveMap_${canopyId}`)}
        id={interactiveMap ? "live-canopy-report-map" : `liveMap_${canopyId}`}
        className={interactiveMap ? "live-canopy-report-map" : `liveMap_${canopyId}`}
        animate={true}
        noWrap={true}
        attributionControl={false}
        center={[carport.lat, carport.lng]}
        zoom={zoom}
        minZoom={0}
        maxZoom={25}
        onzoomend={() => interactiveMap && handleCanopyZoom(canopyReportMap.current.leafletElement.getZoom())}
        zoomControl={false}
        scrollWheelZoom={interactiveMap}
        interactive={interactiveMap}
        dragging={interactiveMap}
        zoomSnap={carport.zoomGranularity}
        zoomDelta={carport.zoomGranularity}
        style={{ width: interactiveMap ? "650px" : "435px", height: interactiveMap ? "475px" : "300px" }}
      >
        {bingLayerVisible && <BingLayer bingkey={bing_key} type={TileSets[activeTileSet]} maxZoom={25} maxNativeZoom={18} />}

        {showControls && canopyReportMap.current && canopyReportMap.current.leafletElement && (
          <LiveReportZoomAndLayerControl mapRef={canopyReportMap.current.leafletElement} activeTileSet={activeTileSet} />
        )}

        <ImageLayer />

        {Object.values(canopies.current).length > 0 && (
          <LayerGroup>
            {Object.values(canopies.current).map((rectangle) => {
              return (
                <Fragment key={`frag_${rectangle.id}`}>
                  {Object.values(rectangle.structure).length > 0 && (
                    <GeoJSON
                      style={{
                        color: rectangle.id == canopyId ? "red" : "#60de4f",
                        fillColor: rectangle.id == canopyId ? "red" : "#60de4f",
                        fillOpacity: 0.2,
                        interactive: false,
                      }}
                      transform={false}
                      draggable={false}
                      scaling={false}
                      data={rectangle.structure}
                      key={`cell_id_(${rectangle.id})`}
                    />
                  )}
                  {/* cells */}
                  {interactiveMap &&
                    rectangle.modules.length > 0 &&
                    rectangle.modules.map((cell, index) => (
                      <GeoJSON
                        style={{
                          fillColor: cell.properties.enabled ? "#ffffff" : "none",
                          fillOpacity: 0.1,
                          weight: 1,
                          color: cell.properties.enabled ? (cell.properties.override_color ? cell.properties.override_color : "#666") : "none",
                        }}
                        transform={false}
                        draggable={false}
                        scaling={false}
                        data={cell}
                        key={`cell_${rectangle.id}-${index}`}
                      />
                    ))}

                  {/* <GeoJSON style={{ color: 'none', fillColor: 'none', opacity: '0.8' }} transform={true} draggable={true} scaling={true} data={rectangle.geoJson} key={rectangle.id} /> */}
                </Fragment>
              );
            })}
          </LayerGroup>
        )}
      </Map>
    </ReportMapWrap>
  );
};

export { LiveCanopyReportMap };
