import React, { useState, useEffect, Fragment } from "react";

// components
import { LiveCanopyReportVicinityMap } from "./LiveCanopyReportVincinityMap";
import { LiveThirtyPercentMap } from "./LiveThirtyPercentMap";
import { ThirtyPercentForm } from "./ThirtyPercentForm";

// styles
import { LiveCanopyReportWrapper } from "./styles";

import { Spin } from "antd";

// assets
import thirtyPercent from "./thirty-percent.svg";
import thirtyPercent_two from "./thirty-percent-2.svg";
import thirtyPercent_three from "./thirty-percent-3.svg";
import thirtyPercent_four from "./thirty-percent-4.svg";
import northIndicator from "./north_indicator.svg";
import { rightArrow } from "../../assets";

const ThirtyPercentSet = ({ reportData, setCanopyMapRefs, saveThirtyPercentInputs, loadingCanopyLiveReport }) => {
  const [nameToggle, setNameToggle] = useState(false);
  const [addressToggle, setAddressToggle] = useState(false);
  const [formToggle, setFormToggle] = useState(false);

  useEffect(() => {
    if (nameToggle) {
      document.querySelector("#customer-name-input").focus();
    }
  }, [nameToggle]);

  useEffect(() => {
    if (addressToggle) {
      document.querySelector("#project-address-input").focus();
    }
  }, [addressToggle]);

  // useEffect(() => {
  //   console.log("report data", reportData);
  // }, [reportData]);

  return (
    <LiveCanopyReportWrapper nameToggle={nameToggle} addressToggle={addressToggle}>
      {!loadingCanopyLiveReport && reportData ? (
        <Fragment>
          <section className="thirty-percent">
            <img src={thirtyPercent} />
            <div className="thirty-percent-map">
              <LiveCanopyReportVicinityMap setCanopyMapRefs={setCanopyMapRefs} />
            </div>
            <section className="edit-fields">
              <p>Edit 30% Fields</p>
              <svg onClick={() => setFormToggle(!formToggle)} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20 18V11H18V18H2V2H9V0H2C0.89543 0 0 0.89543 0 2V18C0 19.1046 0.89543 20 2 20H18C19.1046 20 20 19.1046 20 18ZM17.1781 0.723423C16.7197 0.26142 16.0921 0 15.4374 0C14.7834 0 14.1564 0.26083 13.6954 0.724628L5.3265 9.09342C4.57867 9.75233 4.08844 10.7328 4.00325 11.7873L4 15.0023V16.0023H8.1346C9.2689 15.9245 10.259 15.4295 10.9575 14.6238L19.279 6.30584C19.7407 5.84416 20.0001 5.21799 20.0001 4.56508C20.0001 3.91217 19.7407 3.286 19.279 2.82432L17.1781 0.723423ZM8.06398 14.0048C8.59821 13.967 9.09549 13.7184 9.49479 13.2616L15.5567 7.19972L12.8024 4.44527L6.6961 10.5496C6.29095 10.9079 6.04031 11.4092 6 11.8678V14.0029L8.06398 14.0048ZM14.2169 3.03128L16.9709 5.78551L17.8648 4.89162C17.9514 4.80502 18.0001 4.68756 18.0001 4.56508C18.0001 4.4426 17.9514 4.32514 17.8648 4.23854L15.7611 2.13486C15.6755 2.04855 15.5589 2 15.4374 2C15.3158 2 15.1992 2.04855 15.1136 2.13486L14.2169 3.03128Z"
                  fill="#60de4f"
                />
              </svg>
            </section>
            <div className="customer-name-box">
              <p>{reportData.thirtyPercentInputs?.customer_for}</p>
            </div>
            <div className="project-address-box">
              <p>{reportData.thirtyPercentInputs?.customer_at}</p>
              <p>{reportData.thirtyPercentInputs?.address_one}</p>
              <p>{reportData.thirtyPercentInputs?.address_two}</p>
              {/* {addressToggle ? (
                <input
                  id="project-address-input"
                  type="text"
                  value={reportData.project_address}
                  onBlur={(e) => setAddressToggle(!addressToggle)}
                  onChange={(e) => updateNameAndAddress("project_address", e.target.value)}
                  onKeyDown={(e) => e.keyCode === 13 && setAddressToggle(!addressToggle)}
                />
              ) : reportData.thirtyPercentInputs?.customer_at ? (
                <p>{reportData.thirtyPercentInputs?.customer_at}</p>
              ) : (
                <p>Edit 30% Fields</p>
              )}
              <svg onClick={() => setFormToggle(!formToggle)} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20 18V11H18V18H2V2H9V0H2C0.89543 0 0 0.89543 0 2V18C0 19.1046 0.89543 20 2 20H18C19.1046 20 20 19.1046 20 18ZM17.1781 0.723423C16.7197 0.26142 16.0921 0 15.4374 0C14.7834 0 14.1564 0.26083 13.6954 0.724628L5.3265 9.09342C4.57867 9.75233 4.08844 10.7328 4.00325 11.7873L4 15.0023V16.0023H8.1346C9.2689 15.9245 10.259 15.4295 10.9575 14.6238L19.279 6.30584C19.7407 5.84416 20.0001 5.21799 20.0001 4.56508C20.0001 3.91217 19.7407 3.286 19.279 2.82432L17.1781 0.723423ZM8.06398 14.0048C8.59821 13.967 9.09549 13.7184 9.49479 13.2616L15.5567 7.19972L12.8024 4.44527L6.6961 10.5496C6.29095 10.9079 6.04031 11.4092 6 11.8678V14.0029L8.06398 14.0048ZM14.2169 3.03128L16.9709 5.78551L17.8648 4.89162C17.9514 4.80502 18.0001 4.68756 18.0001 4.56508C18.0001 4.4426 17.9514 4.32514 17.8648 4.23854L15.7611 2.13486C15.6755 2.04855 15.5589 2 15.4374 2C15.3158 2 15.1992 2.04855 15.1136 2.13486L14.2169 3.03128Z"
                  fill="#60de4f"
                />
              </svg> */}
            </div>

            <section className="customer-name-right">
              <h2>{reportData.thirtyPercentInputs?.customer_for}</h2>
            </section>

            <section className="customer-title-right">
              <h5>{reportData.thirtyPercentInputs?.customer_at}</h5>
              <section className="cutomer-title">
                <p>{reportData.thirtyPercentInputs?.address_one}</p>
                <p>{reportData.thirtyPercentInputs?.address_two}</p>
              </section>
            </section>

            <section className="project-number">
              <p>{reportData.thirtyPercentInputs?.project_number}</p>
            </section>

            <section className="drawn-by">
              <p>{reportData.thirtyPercentInputs?.drawn_by}</p>
            </section>
            <section className="reviewed-by">
              <p>{reportData.thirtyPercentInputs?.reviewed_by}</p>
            </section>

            <div className="spec-box module-quantity-box">
              <p>{reportData.thirtyPercentInputs?.total_pv_modules || reportData.overview.mod_qty}</p>
            </div>

            <div className="spec-box active-pv-modules">
              <p>{reportData.overview.mod_qty}</p>
            </div>

            <div className="spec-box active-rating">
              <p>{reportData.overview.rating}</p>
            </div>

            <div className="spec-box total-watts">
              <p>{reportData.overview.mod_qty * reportData.overview.rating}</p>
            </div>

            <div className="spec-box array-type">
              <p>{reportData.thirtyPercentInputs?.array_type}</p>
            </div>
          </section>

          <section className="thirty-percent">
            <img src={thirtyPercent_two} />
            <section className="customer-name-right">
              <h2>{reportData.thirtyPercentInputs?.customer_for}</h2>
            </section>

            <section className="customer-title-right">
              <h5>{reportData.thirtyPercentInputs?.customer_at}</h5>
              <section className="cutomer-title">
                <p>{reportData.thirtyPercentInputs?.address_one}</p>
                <p>{reportData.thirtyPercentInputs?.address_two}</p>
              </section>
            </section>

            <section className="project-number">
              <p>{reportData.thirtyPercentInputs?.project_number}</p>
            </section>

            <section className="drawn-by">
              <p>{reportData.thirtyPercentInputs?.drawn_by}</p>
            </section>
            <section className="reviewed-by">
              <p>{reportData.thirtyPercentInputs?.reviewed_by}</p>
            </section>
          </section>

          <section className="thirty-percent">
            <img src={thirtyPercent_three} />
            <div className="thirty-percent-layout-map">
              <LiveThirtyPercentMap setCanopyMapRefs={setCanopyMapRefs} />
              <div className="north-indicator-img">
                <img src={northIndicator} />
              </div>
            </div>
            <section className="customer-name-right">
              <h2>{reportData.thirtyPercentInputs?.customer_for}</h2>
            </section>

            <section className="customer-title-right">
              <h5>{reportData.thirtyPercentInputs?.customer_at}</h5>
              <section className="cutomer-title">
                <p>{reportData.thirtyPercentInputs?.address_one}</p>
                <p>{reportData.thirtyPercentInputs?.address_two}</p>
              </section>
            </section>

            <section className="project-number">
              <p>{reportData.thirtyPercentInputs?.project_number}</p>
            </section>

            <section className="drawn-by">
              <p>{reportData.thirtyPercentInputs?.drawn_by}</p>
            </section>
            <section className="reviewed-by">
              <p>{reportData.thirtyPercentInputs?.reviewed_by}</p>
            </section>
          </section>

          <section className="thirty-percent">
            <img src={thirtyPercent_four} />
            <section className="customer-name-right">
              <h2>{reportData.thirtyPercentInputs?.customer_for}</h2>
            </section>

            <section className="customer-title-right">
              <h5>{reportData.thirtyPercentInputs?.customer_at}</h5>
              <section className="cutomer-title">
                <p>{reportData.thirtyPercentInputs?.address_one}</p>
                <p>{reportData.thirtyPercentInputs?.address_two}</p>
              </section>
            </section>

            <section className="project-number">
              <p>{reportData.thirtyPercentInputs?.project_number}</p>
            </section>

            <section className="drawn-by">
              <p>{reportData.thirtyPercentInputs?.drawn_by}</p>
            </section>
            <section className="reviewed-by">
              <p>{reportData.thirtyPercentInputs?.reviewed_by}</p>
            </section>
          </section>
          {formToggle && <ThirtyPercentForm saveThirtyPercentInputs={saveThirtyPercentInputs} setFormToggle={setFormToggle} thirtyPercentInputValues={reportData.thirtyPercentInputs} />}
        </Fragment>
      ) : (
        <section className="spin-page landscape">
          <Spin size="large" tip="Loading" />
        </section>
      )}
    </LiveCanopyReportWrapper>
  );
};

export { ThirtyPercentSet };
