/*
	VisualGeoJsonLayer.js
	This handles ALL feature manipulation/display/interactions
	with features within the map (GeoJSON, Polygon, etc)
*/

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { FeatureGroup, LayerGroup, GeoJSON } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { carportActions, usePrevious } from "../../Redux";
import { create_UUID } from "../CanopyConstants";

function CanopyVisualLayer({ canopyId, mapRef }) {
  // TODO: Even after deleted, this component still exists.. need to delete properly.
  // Holdonto a list of Deleted ID's and re-use them so this component can be used again
  const dispatch = useDispatch();
  const canopy = useSelector((state) => state.carport.visual[canopyId]);

  // console.log('rendering ', canopyId, canopy)
  const prevVisLayers = useRef(undefined);

  useEffect(() => {
    if (canopy) {
      if (prevVisLayers.current) {
        mapRef.removeLayer(prevVisLayers.current.modules);
        mapRef.removeLayer(prevVisLayers.current.structure);
				if (prevVisLayers.current.wiresLayer_connector) {
        	mapRef.removeLayer(prevVisLayers.current.wiresLayer_connector);
        	mapRef.removeLayer(prevVisLayers.current.wiresLayer_neg);
        	mapRef.removeLayer(prevVisLayers.current.wiresLayer_pos);
				}
      }
      if (canopy.action == "create" || canopy.action == "select") {
        createCanopy();
      }
    } else if (prevVisLayers.current) {
      // cleanup
      mapRef.removeLayer(prevVisLayers.current.modules);
      mapRef.removeLayer(prevVisLayers.current.structure);
			if (prevVisLayers.current.wiresLayer_connector) {
      	mapRef.removeLayer(prevVisLayers.current.wiresLayer_connector);
      	mapRef.removeLayer(prevVisLayers.current.wiresLayer_neg);
      	mapRef.removeLayer(prevVisLayers.current.wiresLayer_pos);
			}
    }
  }, [canopy]);

  function _onClickFeature(e, id) {
    // make sure to stop the propagation of the click so it doesn't trigger
    // the map_clicked event
    if (e.originalEvent) e.originalEvent.view.L.DomEvent.stopPropagation(e);
    if (e.nativeEvent) e.nativeEvent.view.L.DomEvent.stopPropagation(e);
    //
    dispatch(carportActions.selectLayer(e.target, id));
  }

  function createCanopy() {
    let moduleLayer = L.geoJSON([...canopy.modules], {
      transform: false,
      draggable: false,
      scaling: false,
      style: function(module) {
        return {
          fillColor: module.properties.enabled ? "#ffffff" : "none",
          fillOpacity: 0.1,
          weight: 2,
          color: module.properties.enabled ? (module.properties.override_color ? module.properties.override_color : "#333") : "none",
          interactive: false,
        };
      },
    });
    let structureLayer = L.geoJSON(canopy.structure, {
      transform: false,
      draggable: false,
      scaling: false,
      style: {
        color: "#60de4f",
        fillColor: "#60de4f",
        fillOpacity: 0.2,
        interactive: true,
      },
    });

		let wiresLayer_connector;
		let wiresLayer_neg;
		let wiresLayer_pos;
		if (canopy.wires) {
			wiresLayer_connector = L.geoJSON(canopy.wires.connector_wires_geojson, {
				transform: false,
				draggable: false,
				scaling: false,
				style: {
					color: "#FFFF00",
					// fillColor: "#60de4f",
					fillOpacity: 0.0,
					interactive: false,
				},
			});			
			wiresLayer_connector.addTo(mapRef);

			wiresLayer_neg = L.geoJSON(canopy.wires.neg_wires_geojson, {
				transform: false,
				draggable: false,
				scaling: false,
				style: {
					color: "#FF0000",
					// fillColor: "#60de4f",
					fillOpacity: 0.0,
					interactive: false,
				},
			});			
			wiresLayer_neg.addTo(mapRef);

			wiresLayer_pos = L.geoJSON(canopy.wires.pos_wires_geojson, {
				transform: false,
				draggable: false,
				scaling: false,
				style: {
					color: "#00ff00",
					// fillColor: "#60de4f",
					fillOpacity: 0.0,
					interactive: false,
				},
			});			
			wiresLayer_pos.addTo(mapRef);
		}
		// console.log([...canopy.modules])
		// console.log(canopy.structure)
		// console.log(moduleLayer.toGeoJSON())
		// console.log(structureLayer.toGeoJSON())

    // console.log('createCanopy called')
    moduleLayer.addTo(mapRef);
    structureLayer.addTo(mapRef);
    structureLayer.on("click", (e) => {
      _onClickFeature(e, canopyId);
    });

    if (canopy.action == "select") {
      structureLayer.fire("click");
    }

    prevVisLayers.current = {
      modules: moduleLayer,
      structure: structureLayer,
			wiresLayer_connector: wiresLayer_connector,
			wiresLayer_neg: wiresLayer_neg,
			wiresLayer_pos: wiresLayer_pos,
    };
  }

  return <></>;
}

const VisualGeoJsonLayer = ({ mapRef }) => {
  const dispatch = useDispatch();
  const visualIds = useSelector((state) => state.carport.visualIds);
  const action = useSelector((state) => state.carport.mapAction);

  useEffect(() => {
    if (action == "delete") {
      // delete all rectangles was called, we need to finalize it now
      dispatch(carportActions.deleteAllRectanglesFinalize());
    }
  }, [action]);

  return (
    <FeatureGroup>
      {Object.values(visualIds).map((id) => {
        return <CanopyVisualLayer key={id} canopyId={id} mapRef={mapRef} />;
      })}
    </FeatureGroup>
  );
};

export { VisualGeoJsonLayer };
