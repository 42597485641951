export const carportConstants = {
  SELECT_CANOPY_LAYER: 'SELECT_CANOPY_LAYER',
  TOGGLE_NON_RECT_EDIT: 'TOGGLE_NON_RECT_EDIT',
  UPDATE_CANOPY_BY_ID: 'UPDATE_CANOPY_BY_ID',
  MAP_CLICKED: 'MAP_CLICKED',

  DELETE_ALL_RECTANGLES: 'DELETE_ALL_RECTANGLES',
  DELETE_ALL_RECTANGLES_FINAL: 'DELETE_ALL_RECTANGLES_FINAL',
  DUPLICATE_CANOPY: 'DUPLICATE_CANOPY',
  UPDATE_CANOPY_BY_NAME: 'UPDATE_CANOPY_BY_NAME',
  APPLY_GLOBAL_OVERRIDE: 'APPLY_GLOBAL_OVERRIDE',
  TOGGLE_CANOPY_NAMES: 'TOGGLE_CANOPY_NAMES',
  TOGGLE_DRAG_SELECTED: 'TOGGLE_DRAG_SELECTED',
  COLLECT_IMAGES: 'COLLECT_IMAGES',
  CHANGE_TILESET: 'CHANGE_TILESET',


  STORE_CURRENT_RECTANGLE: 'STORE_CURRENT_RECTANGLE',
  CREATE_RECTANGLE: 'CREATE_RECTANGLE',
  UPDATE_RECTANGLE: 'UPDATE_RECTANGLE',
  DUPLICATE_RECTANGLE: 'DUPLICATE_RECTANGLE',
  COPY_RECTANGLE: 'COPY_RECTANGLE',
  DELETE_RECTANGLE: 'DELETE_RECTANGLE',
  UPDATE_RECTANGLE_NAME: 'UPDATE_RECTANGLE_NAME',
  SAVE_RECTANGLE_NAME: 'SAVE_RECTANGLE_NAME',

  INPUT_UPDATE: 'INPUT_UPDATE_CARPORT',

  PROD_MOD_SELECT: 'PROD_MOD_SELECT',
  UPDATE_MODULE: 'UPDATE_MODULE',
  SET_ACTIVE_TOOL: 'SET_ACTIVE_TOOL',
  CHANGE_MAP_COORDS: 'CHANGE_MAP_COORDS',

  GENERATE_CANOPY_RESULTS: 'GENERATE_CANOPY_RESULTS',
  SET_RESULTS: 'SET_RESULTS',
  SET_NEW_PROJECT_LOADER: 'SET_NEW_PROJECT_LOADER',
  START_NEW_PROJECT: 'START_NEW_PROJECT',

  CREATE_CANOPY: 'CREATE_CANOPY',
  UPDATE_CANOPY: 'UPDATE_CANOPY',

  SELECT_GLOBAL_OVERRIDE: 'SELECT_GLOBAL_OVERRIDE',
  SET_GLOBAL_OVERRIDES_INPUT: 'SET_GLOBAL_OVERRIDES_INPUT',
  SET_ZOOM: 'SET_ZOOM',

  INIT_RESULTS_REQUEST: 'INIT_RESULTS_REQUEST_CARPORT',
  INIT_RESULTS_SUCCESS: 'INIT_RESULTS_SUCCESS_CARPORT',
  INIT_RESULTS_ERROR: 'INIT_RESULTS_ERROR_CARPORT',

  GENERATE_RESULTS_REQUEST: 'GENERATE_RESULTS_REQUEST_CARPORT',
  GENERATE_RESULTS_SUCCESS: 'GENERATE_RESULTS_SUCCESS_CARPORT',
  GENERATE_RESULTS_UPDATE: 'GENERATE_RESULTS_UPDATE_CARPORT',
  GENERATE_RESULTS_FAILURE: 'GENERATE_RESULTS_FAILURE_CARPORT',

  GET_USER_DATA_SUCCESS: 'GET_USER_DATA_SUCCESS',
  GETALL_REQUEST: 'GETALL_REQUEST',
  GETALL_FAILURE: 'GETALL_FAILURE',
	CANOPY_WIRE_REQUEST: 'CANOPY_WIRE_REQUEST',
	CANOPY_WIRE_SUCCESS: 'CANOPY_WIRE_SUCCESS',
	CANOPY_WIRE_FAILURE: 'CANOPY_WIRE_FAILURE',

  TOGGLE_PROJECTMANAGER_MODAL: 'TOGGLE_PROJECTMANAGER_MODAL',
  NEW_PROJECT_REQUEST: 'NEW_PROJECT_REQUEST',
  NEW_PROJECT_COMPLETE: 'NEW_PROJECT_COMPLETE',
  SELECT_PROJECT: 'SELECT_PROJECT',
  LOAD_PROJECT_REQUEST: 'LOAD_PROJECT_REQUEST_CARPORT',

  LOAD_PROJECT_COMPLETE: 'LOAD_PROJECT_COMPLETE',
  DELETE_PROJECT_REQUEST: 'DELETE_PROJECT_REQUEST_CARPORT',
  DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS_CARPORT',
  DELETE_PROJECT_COMPLETE: 'DELETE_PROJECT_COMPLETE_CAPORT',
  DELETE_PROJECT_FAILURE: 'DELETE_PROJECT_FAILURE_CARPORT',
  SAVE_PROJECT_REQUEST: 'SAVE_PROJECT_REQUEST_CARPORT',
  SAVE_PROJECT_SUCCESS: 'SAVE_PROJECT_SUCCESS_CARPORT',
  SAVE_PROJECT_COMPLETE: 'SAVE_PROJECT_COMPLETE',
  SAVE_PROJECT_FAILURE: 'SAVE_PROJECT_FAILURE',
  IMPORT_IMAGE: 'IMPORT_IMAGE',
  UPDATE_IMAGE: 'UPDATE_IMAGE',
  SELECT_IMAGE: 'SELECT_IMAGE',
  DELETE_IMAGE: 'DELETE_IMAGE',
  SET_IMAGE_EDIT_TOOL: 'SET_IMAGE_EDIT_TOOL',
  SET_COUNTY: 'SET_COUNTY',
  SET_UI_STATE: 'SET_UI_STATE',
  PREPARE_REPORT: 'PREPARE_REPORT',
  UPDATE_CENTER: 'UPDATE_CENTER',
  CANCEL_RUN_REQUEST: 'CANCEL_RUN_REQUEST',
	
	
  UPDATE_NON_POLY_DATA: 'UPDATE_NON_POLY_DATA',
  TOGGLE_NON_POLY_EDIT: 'TOGGLE_NON_POLY_EDIT',
};
