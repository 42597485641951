import React, { Component } from "react";
import { withRouter, Switch, Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Base from "./layouts/Base";
import BasePage from "./layouts/BasePage";

import { Login, UserManagement, Carport, GroundMount, Canopy, CanopyWrapper } from "./routes/";

import { Report, New_GM_Report } from "./components/PDFReport";

import { PrivateRoute } from "./components/Auth/PrivateRoute";

import { authActions } from "./components/Redux/actions";
import { isAuthenticated } from "./components/Redux/reducers";

class Routes extends Component {
  constructor(props) {
    super(props);

    if (this.props.isAuthenticated && !this.props.reset_pw) {
      // user is authenticated by means of having a non-expired refresh token
      let user = JSON.parse(localStorage.getItem("user"));
      this.props.dispatch(authActions.continueLogin(user));
    }
  }

  render() {
    const currentKey = this.props.location.pathname.split("/")[1] || "/";
    const timeout = { enter: 500, exit: 500 };
    const childProps = { ...this.props };
    const animationName = "rag-fadeInLeft";

    if (currentKey == "login" || !this.props.isAuthenticated || this.props.reset_pw) {
      return (
        // Page Layout component wrapper
        <Login dispatch={this.props.dispatch} loggingIn={this.props.loggingIn} error={this.props.error_msg} reset_pw={this.props.reset_pw} />
      );
    } else {
      return (
        // Layout component wrapper
        // Use <BaseHorizontal> to change layout
        <Base dispatch={this.props.dispatch} role={this.props.role} page={currentKey}>
          <div>
            <Switch>
              {/* Sunfig */}
              <PrivateRoute exact path="/userManagement" component={UserManagement} isAuthenticated={this.props.isAuthenticated} />
              <PrivateRoute exact path="/" component={GroundMount} isAuthenticated={this.props.isAuthenticated} />
              {/* <PrivateRoute exact path="/groundMount" component={GroundMount} isAuthenticated={this.props.isAuthenticated} /> */}
              {/* <PrivateRoute exact path="/canopy" component={Canopy} isAuthenticated={this.props.isAuthenticated} /> */}
              <PrivateRoute exact path="/canopy" component={CanopyWrapper} isAuthenticated={this.props.isAuthenticated} role={this.props.role} />
              <Redirect to="/login" />
              {/* this is used for testing the report generation feature */}
              {/* <PrivateRoute exact path="/pdf" component={Report} isAuthenticated={this.props.isAuthenticated} /> */}
            </Switch>
          </div>
        </Base>
      );
    }
  }
}

Routes.propTypes = {
  location: PropTypes.object,
};

function mapStateToProps(state) {
  const { loggingIn, error_msg, reset_pw, role } = state.authentication;
  return {
    loggingIn,
    error_msg,
    reset_pw,
    role,
    isAuthenticated: isAuthenticated(state),
  };
}

const connectedRoutes = withRouter(connect(mapStateToProps)(Routes));
export { connectedRoutes as Routes };
