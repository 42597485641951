import { carportConstants, portalConstants } from "../constants";
import products from "../../Sunfig/data/carportTestData";
import { getBounds } from "../../../utils/map_helper";
import defaultModules from "../../Sunfig/data/defaultModsCanopy";
import { message } from "antd";
const json2csv = require("json2csv").parse;
// dummy data for carport results
import results from "../../Sunfig/data/carportDummyResults";
import { convert_to_swm_canopy } from "../../Sunfig/data/swm_input_sheet";

import { scale_canopy, drag_canopy, rotate_canopy, create_canopy, duplicate_canopy, non_rect_edit, redraw_canopy, paste_canopy, translate_canopy } from "../helpers/canopy.editor";
import { run_overlap_check } from "../helpers/canopy.helper";

const csvFields = [
  "Key",
  "Name",
  "Module",
  "Canopy Type",
  "Azimuth (°)",
  "Module Qty",
  "String Count",
  "Capacity (kw)",
  "Tilt (°)",
  "Generation (KWh)",
  "Yield (kWh/kWp)",
  "Tilt From Azimuth",
  "Tilt To Azimuth",
  "Toward Azimuth",
  "Orientation",
  "Mod Dimension X Qty",
  "Mod Dimension Y Qty",
  "Mod Dimension X Ft",
  "Mod Dimension Y Ft",
];

const initialState = {
  // REBUILDING THE CANOPY STORE TO SEGMENT IT OFF

  wiring_loading: false,
  canopy_wires: {},
  show_canopy_wires: false,

  visual: {},
  visualIds: [],
  selected: undefined,
  selectedInputs: undefined,
  nonRectSelected: undefined,
  names: {
    visble: false,
    visual: {},
  },
  dragging: {
    active: false,
  },
  overlapping: {},
  mapAction: "create",
  map: {
    mapBounds: undefined,
    mapCenter: [39.18648929133045, -84.4719573994339],
    zoom: 16,
    activeTileSet: "Satellite",
  },
  image_keys: [],
  uiState: {
    loading: false,
    view_results_table: false,
    canceling: false,
    show_canopy_report: false,
    show_thirty_percent_set: false,
    inputs_changed_warning: false,
    do_advanced_mod_specs: false,
  },

  non_poly_cells_and_blocks: {
    cells: undefined,
    blocks: undefined,
  },
  non_poly_edit: false,

  prepareReport: undefined,
  reportImages: {},

  // old parking lot
  lat: 39.18648929133045,
  lng: -84.4719573994339,
  // new lot in florida
  // lat: 28.541680640755267,
  // lng: -81.3813016734557,
  zoom: 16,
  zoomGranularity: 0.25,
  mapBounds: undefined,

  county: undefined,
  stateAbb: undefined,

  newProjectLoading: false,

  // for testing the results table
  generatingCanopyResults: false,
  run_id: undefined,
  percentComplete: 0,
  hasCanopyResults: false,

  // fake results of course
  canopyResults: results,
  modules: defaultModules,
  prodModSelection: {
    selectedCarportProduct: JSON.parse(JSON.stringify(products["Aisle1"])),
    selectedCarportModule: JSON.parse(JSON.stringify(defaultModules[13])),
    selectedCarportProductIndex: 0,
    selectedCarportModuleIndex: 13,
  },

  projectManager: {
    loading: false,
    loaded: false,
    deleting: false,
    modal_visible: false,

    projects: undefined,
    localProjects: [],
    selectedProjectId: undefined,
    currentProjectId: undefined,
  },

  // image import and image tools
  images: {},
  activeImageEditTool: "translate",
  selectedImageId: undefined,

  canopyName: undefined, // used for renaming canopies when the name is changed, or when copying, pasting and duplcating
  currentCanopyIndex: 1, // used for naming new Canopy's
  activeTool: undefined,
  isEditing: false,
  selectedRectId: undefined,
  currentRect: {},
  copiedRectangle: undefined,
  selectGlobalOverride: false,
  // global overrides
  globalOverrides: {
    id: "global",
    name: "Global Overrides",
    template: {
      productIndex: -1,
      globalProduct: undefined,
    },
    module: {
      moduleIndex: -1,
      globalModule: undefined,
    },
    azimuth: 180,
    tilt: 7, //default
    orientation: 1,
    planeType: "single_slope", //default
  },
  rectangles: {},
  trees: {},
  results: [],

  // props: {
  // 	rectangles: state.rectangles,
  // 	project_loading: state.projectManager.loading,
  // 	selectedRectId: state.selectedRectId,
  // 	prepareReport: state.prepareReport,
  // 	selectedImageId: state.selectedImageId,
  // 	mapBounds: state.mapBounds,
  // 	images: state.images,
  // 	activeTool: state.activeTool,
  // 	selectGlobalOverride: state.selectGlobalOverride,
  // 	copiedRectangle: state.copiedRectangle,
  // 	currentCanopyIndex: state.currentCanopyIndex,
  // 	prodModSelection: state.prodModSelection,
  // 	modules: state.modules,
  // 	zoom: state.zoom,
  // 	lat: state.lat,
  // 	lng: state.lng,
  // 	zoomGranularity: state.zoomGranularity,
  // 	county: state.county,
  // 	stateAbb: state.stateAbb,
  // 	county: state.county,
  // 	stateAbb: state.stateAbb,
  // 	activeImageEditTool: state.activeImageEditTool,
  // 	generatingCanopyResults: state.generatingCanopyResults,
  // 	percentComplete: state.percentComplete,
  // 	results: state.results,
  // 	view_results_table: state.uiState.view_results_table
  // }
};

export function carport(state = initialState, action) {
  let overlap_check = [];
  let rectangles = { ...state.rectangles };
  let images = { ...state.images };
  let theActiveImagesEditingTool = state.activeImageEditTool;
  let geoJson;
  let rect_object;
  let prodModSelection = { ...state.prodModSelection };
  let rectangleIndex;
  let id;
  let activeTool;
  let canopyName = state.canopyName;
  let mapBounds = state.mapBounds;
  let zoom = state.zoom;

  let uiState = { ...state.uiState };
  let projectManager = { ...state.projectManager };

  switch (action.type) {
    case carportConstants.SELECT_CANOPY_LAYER:
      // console.log(action)
      let new_selected = undefined;
      let new_selectedInputs = undefined;
      if (action.layer) {
        new_selected = { layerRef: action.layer, geoJsonRef: state.rectangles[action.id].geoJson, idRef: action.id, azimuthHolder: state.rectangles[action.id].azimuthHolder };
        new_selectedInputs = {
          id: action.id,
          name: state.rectangles[action.id].name,
          azimuth: state.rectangles[action.id].azimuth,
          orientation: state.rectangles[action.id].orientation,
          base_dimension: state.rectangles[action.id].base_dimension,
          dimensions: state.rectangles[action.id].dimensions,
          modGap: state.rectangles[action.id].modXGap,
          module: state.rectangles[action.id].module,
          modX: state.rectangles[action.id].dimensions.modX,
          modY: state.rectangles[action.id].dimensions.modY,

          planeType: {
            type: state.rectangles[action.id].planeType,
            tilt: state.rectangles[action.id].tilt,
            towardAzimuth: state.rectangles[action.id].towardAzimuth,
            tiltToAzimuth: state.rectangles[action.id].tiltToAzimuth,
            tiltFromAzimuth: state.rectangles[action.id].tiltFromAzimuth,
          },
        };
      }
      return {
        ...state,
        selected: new_selected,
        selectedInputs: new_selectedInputs,
        nonRectSelected: undefined,
        selectedRectId: action.id,
        activeTool: undefined,
      };

    case carportConstants.TOGGLE_NON_RECT_EDIT:
      let nonRect_selected = undefined;
      if (state.nonRectSelected == undefined) {
        nonRect_selected = {
          ...state.rectangles[state.selected.idRef].editCellsGeoJson,
        };
      }
      return {
        ...state,
        nonRectSelectedId: state.selected.idRef,
        nonRectSelected: nonRect_selected,
      };

    case carportConstants.UPDATE_CANOPY_BY_ID:
      let updated_canopy;
      let updated = false;
      if (action.eventType == "scaleend") {
        // canopy was scaled, we need to create a new visual piece for it
        // only if there was a change
        updated_canopy = scale_canopy(action.geoJson, state.rectangles[action.id]);
        // console.log(updated_canopy)
        updated = true;
      }
      if (action.eventType == "dragend") {
        // if alignment on and alignment line assigned, align to that

        // canopy was scaled, we need to create a new visual piece for it
        // only if there was a change
        updated_canopy = drag_canopy(action.geoJson, state.rectangles[action.id]);
        // console.log(updated_canopy)

        updated = true;
      }
      if (action.eventType == "rotateend") {
        // canopy was scaled, we need to create a new visual piece for it
        // only if there was a change
        updated_canopy = rotate_canopy(action.geoJson, state.rectangles[action.id]);
        // console.log(updated_canopy)
        updated = true;
      }
      if (action.eventType == "nonRectEdit") {
        updated_canopy = non_rect_edit(action.geoJson, state.rectangles[action.id]);
      }
      if (action.eventType == "rotateright") {
        updated_canopy = rotate_canopy(state.rectangles[action.id].geoJson, state.rectangles[action.id], +1);
      }
      if (action.eventType == "rotateleft") {
        updated_canopy = rotate_canopy(state.rectangles[action.id].geoJson, state.rectangles[action.id], -1);
      }
      if (action.eventType == "translateright") {
        updated_canopy = translate_canopy(state.rectangles[action.id], 90);
      }
      if (action.eventType == "translateleft") {
        updated_canopy = translate_canopy(state.rectangles[action.id], -90);
      }
      if (action.eventType == "translateup") {
        updated_canopy = translate_canopy(state.rectangles[action.id], 0);
      }
      if (action.eventType == "translatedown") {
        updated_canopy = translate_canopy(state.rectangles[action.id], -180);
      }

      let updated_visual_by_id = {
        ...state.visual,
        [action.id]: {
          ...state.visual[action.id],
          action: "create",
          name: updated_canopy.name,
          structure: updated_canopy.visibleGeoJson,
          modules: updated_canopy.editCellsGeoJson,
          wires: undefined,
        },
      };
      overlap_check = run_overlap_check(updated_visual_by_id);

      //update selected inputs
      let updated_selected_inputs = {
        id: action.id,
        name: updated_canopy.name,
        azimuth: updated_canopy.azimuth,
        orientation: updated_canopy.orientation,
        base_dimension: updated_canopy.base_dimension,
        dimensions: updated_canopy.dimensions,
        modGap: updated_canopy.modXGap,
        module: updated_canopy.module,
        modX: updated_canopy.dimensions.modX,
        modY: updated_canopy.dimensions.modY,

        planeType: {
          type: updated_canopy.planeType,
          tilt: updated_canopy.tilt,
          towardAzimuth: updated_canopy.towardAzimuth,
          tiltToAzimuth: updated_canopy.tiltToAzimuth,
          tiltFromAzimuth: updated_canopy.tiltFromAzimuth,
        },
      };

      return {
        ...state,
        rectangles: {
          ...state.rectangles,
          [action.id]: {
            ...updated_canopy,
          },
        },
        visual: updated_visual_by_id,
        selected: {
          ...state.selected,
          geoJsonRef: updated_canopy.geoJson,
          azimuthHolder: updated_canopy.azimuthHolder,
        },
        nonRectSelected: undefined,
        overlapping: overlap_check,
        selectedInputs: updated_selected_inputs,
      };

    case carportConstants.UPDATE_CANOPY_BY_NAME:
      // certain actions will require re-rendering
      // that canopy

      // made this check to determine whether or not the value coming in is valid and can be used to redraw the canopy.
      // if it's an invalid value, the input is updated. However, the canopy is not redrawn.
      //  Addresses the issue of not being able to manually alter the canopy inputs or have blank input fields

      let valid_value =
        (action.value && typeof action.value != "string" && !isNaN(action.value) && action.value < 400) || action.value == "single_slope" || action.value == "inverted" || action.value == "dual_tilt";

      let render_actions = ["name", "modY", "modX", "mod_height", "mod_width", "orientation", "planeType", "azimuth", "module", "modGap"];

      let primary_update_action = action.name;
      let secondary_update_action;
      // first check if this is a split action
      if (action.name.indexOf(".") >= 0) {
        let split_action = action.name.split(".");
        primary_update_action = split_action[0];
        secondary_update_action = split_action[1];
      }

      let updated_selectedInputs;
      let eventType;
      if (secondary_update_action) {
        eventType = secondary_update_action;
        updated_selectedInputs = {
          ...state.selectedInputs,
          [primary_update_action]: {
            ...state.selectedInputs[primary_update_action],
            [secondary_update_action]: action.value,
          },
        };
      } else {
        // new_canopy.dimensions.modX = Math.max(value.xDim, new_canopy.base_dimension.modX);
        // new_canopy.dimensions.modY = Math.max(value.yDim, new_canopy.base_dimension.modY);
        eventType = primary_update_action;
        updated_selectedInputs = {
          ...state.selectedInputs,
          [primary_update_action]: action.value,
        };
      }

      let redrawn_canopy = state.rectangles[state.selectedInputs.id];
      let updated_visual_input_update = state.visual;
      if (render_actions.findIndex((action) => action == primary_update_action >= 0)) {
        // need to rerender the visual and edit layers
        redrawn_canopy = redraw_canopy(updated_selectedInputs, redrawn_canopy, eventType);
        redrawn_canopy.geoJson.properties.rotation = redrawn_canopy.rotation;

        updated_selectedInputs = {
          id: redrawn_canopy.id,
          name: redrawn_canopy.name,
          azimuth: redrawn_canopy.azimuth,
          orientation: redrawn_canopy.orientation,
          base_dimension: redrawn_canopy.base_dimension,
          dimensions: redrawn_canopy.dimensions,
          modGap: redrawn_canopy.modGap,
          module: redrawn_canopy.module,
          modX: redrawn_canopy.dimensions.modX,
          modY: redrawn_canopy.dimensions.modY,

          planeType: {
            type: redrawn_canopy.planeType,
            tilt: redrawn_canopy.tilt,
            towardAzimuth: redrawn_canopy.towardAzimuth,
            tiltToAzimuth: redrawn_canopy.tiltToAzimuth,
            tiltFromAzimuth: redrawn_canopy.tiltFromAzimuth,
          },
        };

        updated_visual_input_update = {
          ...state.visual,
          [state.selectedInputs.id]: {
            ...state.visual[state.selectedInputs.id],
            action: "create",
            name: redrawn_canopy.name,
            structure: redrawn_canopy.visibleGeoJson,
            modules: redrawn_canopy.editCellsGeoJson,
            wires: undefined,
          },
        };
        overlap_check = run_overlap_check(updated_visual_input_update);
      }
      // console.log("redrawn", redrawn_canopy);
      return {
        ...state,
        selectedInputs: updated_selectedInputs,
        rectangles: {
          ...state.rectangles,
          [state.selectedInputs.id]: {
            ...redrawn_canopy,
          },
        },
        visual: updated_visual_input_update,
        selected: {
          ...state.selected,
          geoJsonRef: redrawn_canopy.geoJson,
          azimuthHolder: redrawn_canopy.azimuthHolder,
        },
        overlapping: overlap_check,
        uiState: { ...state.uiState, inputs_changed_warning: state.hasCanopyResults ? true : false },
      };

    case carportConstants.APPLY_GLOBAL_OVERRIDE:
      let new_override_rects = {};
      let new_override_vis = {};
      let overrideType;
      let update_global = false;
      Object.values(state.rectangles).map((rect) => {
        update_global = true;
        let override_selectedInputs = {
          id: rect.id,
          name: rect.name,
          azimuth: rect.azimuth,
          orientation: rect.orientation,
          base_dimension: rect.base_dimension,
          dimensions: rect.dimensions,
          modGap: rect.modGap,
          module: rect.module,
          modX: rect.dimensions.modX,
          modY: rect.dimensions.modY,

          planeType: {
            type: rect.planeType,
            tilt: rect.tilt,
            towardAzimuth: rect.towardAzimuth,
            tiltToAzimuth: rect.tiltToAzimuth,
            tiltFromAzimuth: rect.tiltFromAzimuth,
          },
        };

        if (action.g_var == "setGlobalModule") {
          let index = action.g_value.moduleIndex == -1 ? 0 : action.g_value.moduleIndex;
          prodModSelection.selectedCarportModuleIndex = index;
          if (index == 0) {
            override_selectedInputs.module = JSON.parse(JSON.stringify(prodModSelection.selectedCarportModule));
            override_selectedInputs.module.rating = override_selectedInputs.module.mod_rating;
          } else {
            prodModSelection.selectedCarportModule = JSON.parse(JSON.stringify(state.modules[index]));
            override_selectedInputs.module = JSON.parse(JSON.stringify(state.modules[index]));
          }
          overrideType = "module";
        }
        if (action.g_var == "setGlobalOrientation") {
          override_selectedInputs.orientation = action.g_value;
          overrideType = "orientation";
        }
        if (action.g_var == "setGlobalAzimuth") {
          override_selectedInputs.azimuth = action.g_value;
          overrideType = "azimuth";
        }
        if (action.g_var == "setGlobalTilt") {
          override_selectedInputs.planeType.tilt = action.g_value;
          overrideType = "tilt";
        }
        if (action.g_var == "setGlobalPlaneType") {
          override_selectedInputs.planeType.type = action.g_value;
          overrideType = "type";
        }

        let override_canopy = redraw_canopy(override_selectedInputs, rect, overrideType);

        new_override_rects[override_canopy.id] = {
          ...override_canopy,
        };
        new_override_vis[override_canopy.id] = {
          id: override_canopy.id,
          name: override_canopy.name,
          structure: override_canopy.visibleGeoJson,
          modules: override_canopy.editCellsGeoJson,
          action: "create",
        };
      });

      if (update_global) {
        overlap_check = run_overlap_check(new_override_vis);
      }

      message.success("Global Override has been applied");

      return {
        ...state,
        selectedInputs: undefined,
        selectedRectId: undefined,
        rectangles: new_override_rects,
        visual: new_override_vis,
        selected: undefined,
        overlapping: overlap_check,
      };

    case carportConstants.MAP_CLICKED:
      // console.log('activeTool', state.activeTool)

      let created_canopy;
      let new_rectangles = state.rectangles;
      let new_visuals = state.visual;
      let do_update = false;

      if (state.activeTool == "draw") {
        // create a new canopy
        created_canopy = create_canopy(action.origin, JSON.parse(JSON.stringify(prodModSelection.selectedCarportModule)));
        created_canopy.module.rating = created_canopy.module.mod_rating;
        new_rectangles = {
          ...state.rectangles,
          [created_canopy.id]: {
            ...created_canopy,
          },
        };
        new_visuals = {
          ...state.visual,
          [created_canopy.id]: {
            id: created_canopy.id,
            name: created_canopy.name,
            structure: created_canopy.visibleGeoJson,
            modules: created_canopy.editCellsGeoJson,
            action: "create",
          },
        };
        do_update = true;
      }
      if (state.activeTool == "copy") {
        // create a new canopy
        created_canopy = paste_canopy(action.origin, JSON.parse(JSON.stringify(state.rectangles[state.copiedCanopyId])));

        new_rectangles = {
          ...state.rectangles,
          [created_canopy.id]: {
            ...created_canopy,
          },
        };
        new_visuals = {
          ...state.visual,
          [created_canopy.id]: {
            id: created_canopy.id,
            name: created_canopy.name,
            structure: created_canopy.visibleGeoJson,
            modules: created_canopy.editCellsGeoJson,
            action: "create",
          },
        };
        do_update = true;
      }

      if (Object.values(new_rectangles).length > 1) {
        do_update = true;
      }

      if (do_update) {
        new_visuals = {
          ...state.visual,
          ...new_visuals,
        };
        overlap_check = run_overlap_check(new_visuals);
      }

      return {
        ...state,
        selected: undefined,
        selectedRectId: undefined,
        nonRectSelected: undefined,
        activeTool: undefined,
        rectangles: new_rectangles,
        visual: new_visuals,
        visualIds: !do_update || !created_canopy ? state.visualIds : [...state.visualIds, created_canopy.id],
        overlapping: overlap_check,
      };

    // TOOlBAR CASES
    case carportConstants.CHANGE_MAP_COORDS:
      return {
        ...state,
        activeTool: undefined,
        map: { ...state.map, zoom: zoom, mapCenter: [action.coords.lat, action.coords.lng] },
      };

    case carportConstants.DELETE_ALL_RECTANGLES:
      let delete_visual = {};
      Object.values(state.visual).map((vis) => {
        delete_visual[vis.id] = {
          ...vis,
          action: "delete",
        };
      });

      // projectManager.loading = action.projectToLoad != undefined;
      projectManager.loading = true;
      projectManager.currentProjectId = action.projectToLoad;

      return {
        ...state,
        mapAction: "delete",
        selected: undefined,
        visual: delete_visual,
        visualIds: [...state.visualIds],
        selectGlobalOverride: false,
        hasCanopyResults: false,
        currentCanopyIndex: 1,
        rectangles: {},
        projectToLoad: action.projectToLoad,
        projectManager,
        uiState: { ...state.uiState, view_results_table: false },
      };

    case carportConstants.DELETE_ALL_RECTANGLES_FINAL:
      // final step of deleting all canopies
      let post_delete_vis_ids = [];
      let post_delete_visual = {};
      let post_delete_rectangles = {};
      let post_delete_map = state.map;

      projectManager.loading = false;
      if (state.projectToLoad) {
        projectManager.modal_visible = false;
        // load inputs from project into ioManager
        let proj_inputs;
        if (projectManager.localProjects[state.projectToLoad].inputs.old_inputs) {
          proj_inputs = JSON.parse(projectManager.localProjects[state.projectToLoad].inputs.old_inputs);
        } else {
          proj_inputs = JSON.parse(JSON.stringify(projectManager.localProjects[state.projectToLoad].inputs));
          // projectManager.localProjects[state.projectToLoad].name = proj_inputs.project_name
          // projectManager.localProjects[state.projectToLoad].id = proj_inputs.project_id
        }
        // console.log(proj_inputs)
        if (proj_inputs.images) {
          // new project structure
          post_delete_rectangles = { ...proj_inputs.rectangles };
          images = { ...proj_inputs.images };
          theActiveImagesEditingTool = "none";
        } else {
          // old project structure
          post_delete_rectangles = { ...proj_inputs };
          images = {};
        }

        // fix dimensions if missing from older project
        let canopyRectangles = [];
        Object.values(post_delete_rectangles).map((rectangle) => {
          if (rectangle.module_dimensions.mod_width == undefined && rectangle.module_dimensions.x) {
            rectangle.module_dimensions.mod_width = rectangle.module_dimensions.x;
            rectangle.module.mod_width = rectangle.module_dimensions.x;
          }
          if (rectangle.module_dimensions.mod_height == undefined && rectangle.module_dimensions.y) {
            rectangle.module_dimensions.mod_height = rectangle.module_dimensions.y;
            rectangle.module.mod_height = rectangle.module_dimensions.y;
          }
          if (!rectangle.visibleGeoJson || !rectangle.editCellsGeoJson) {
            rectangle.visibleGeoJson = {};
            rectangle.editCellsGeoJson = {};
          }
          if (rectangle.disabled_module_indexes == undefined) {
            rectangle.disabled_module_indexes = [];
            if (Object.keys(rectangle.editCellsGeoJson).length > 0) {
              let disabled_indexes = rectangle.editCellsGeoJson.filter((block) => !block.properties.enabled);
              for (let i = 0; i < disabled_indexes.length; i++) {
                rectangle.disabled_module_indexes.push(disabled_indexes[i].properties.indexes);
              }
            }
          }
          canopyRectangles.push(rectangle.geoJson);

          let loading_inputs = {
            id: rectangle.id,
            name: rectangle.name,
            azimuth: rectangle.azimuth,
            orientation: rectangle.orientation,
            base_dimension: rectangle.base_dimension,
            dimensions: rectangle.dimensions,
            modGap: rectangle.modXGap,
            module: rectangle.module,
            modX: rectangle.dimensions.modX,
            modY: rectangle.dimensions.modY,
            disabled_module_indexes: rectangle.disabled_module_indexes,

            planeType: {
              type: rectangle.planeType,
              tilt: rectangle.tilt,
              towardAzimuth: rectangle.towardAzimuth,
              tiltToAzimuth: rectangle.tiltToAzimuth,
              tiltFromAzimuth: rectangle.tiltFromAzimuth,
            },
          };

          let draw_load_canopy = redraw_canopy(loading_inputs, rectangle, "none");
          post_delete_visual[rectangle.id] = {
            id: rectangle.id,
            structure: draw_load_canopy.visibleGeoJson,
            modules: draw_load_canopy.editCellsGeoJson,
            action: "create",
          };
          post_delete_vis_ids.push(rectangle.id);
        });

        // console.log(post_delete_visual)

        if (canopyRectangles.length > 0) {
          let bounds = getBounds(canopyRectangles);
          mapBounds = [
            [bounds[1], bounds[0]],
            [bounds[3], bounds[2]],
          ];
          let _center = {
            lat: bounds[1] + Math.abs(bounds[3] - bounds[1]),
            lng: bounds[0] + Math.abs(bounds[2] - bounds[0]),
          };
          post_delete_map = { ...state.map, mapBounds: mapBounds, mapCenter: _center };
        }
      }

      return {
        ...state,
        mapAction: "create",
        activeImageEditTool: theActiveImagesEditingTool,
        rectangles: post_delete_rectangles,
        visualIds: post_delete_vis_ids,
        visual: post_delete_visual,
        map: post_delete_map,
        projectManager,
        images,
        projectToLoad: undefined,
      };

    case carportConstants.DUPLICATE_CANOPY:
      let dup_canopy = duplicate_canopy(action.direction, state.rectangles[state.selected.idRef]);
      let new_rectangles_from_duplicate = {
        ...state.rectangles,
        [dup_canopy.id]: {
          ...dup_canopy,
        },
      };
      let new_visuals_from_duplicate = {
        ...state.visual,
        [dup_canopy.id]: {
          id: dup_canopy.id,
          name: dup_canopy.name,
          structure: dup_canopy.visibleGeoJson,
          modules: dup_canopy.editCellsGeoJson,
          action: "create",
        },
      };

      return {
        ...state,
        selected: undefined,
        activeTool: undefined,
        rectangles: new_rectangles_from_duplicate,
        visual: new_visuals_from_duplicate,
        visualIds: [...state.visualIds, dup_canopy.id],
      };

    case carportConstants.CHANGE_TILESET:
      return {
        ...state,
        map: {
          ...state.map,
          activeTileSet: action.tileSet,
        },
      };

    case carportConstants.TOGGLE_CANOPY_NAMES:
      return {
        ...state,
        names: {
          ...state.names,
          visible: !state.names.visible,
        },
      };
    case carportConstants.TOGGLE_DRAG_SELECTED:
      return {
        ...state,
        dragging: {
          ...state.dragging,
          active: !state.dragging.active,
        },
      };

    case carportConstants.COLLECT_IMAGES:
      return {
        ...state,
        image_keys: action.keys,
      };

    case carportConstants.STORE_CURRENT_RECTANGLE:
      // if (state.selectedRectId == undefined) {
      //   canopyName = "";
      // } else if (action.id && rectangles[action.id]) {
      //   canopyName = rectangles[action.id].name;
      // }
      let stored_visual = {
        [action.id]: {
          ...state.visual[action.id],
          action: "select",
        },
      };
      return {
        ...state,
        // selectedRectId: action.id,
        // canopyName,
        visual: {
          ...state.visual,
          ...stored_visual,
        },
        selectGlobalOverride: false,
      };

    case carportConstants.CREATE_RECTANGLE:
      // used for naming. But not a great solution. needs rewritten. As it uses
      // length for incrementing the naming convention(Canopy 1, Canopy 2 etc.), and if you delete a canopy
      // and then create one, you end up with duplicate names
      rectangleIndex = Object.values(rectangles).length == 0 ? 1 : Object.values(rectangles).length + 1;
      geoJson = action.geoJson;

      if (action.options.copiedFrom) {
        geoJson.properties["name"] = `Canopy ${rectangleIndex}`;
        geoJson.properties["copiedFrom"] = action.options.copiedFrom;
      } else {
        geoJson.properties["name"] = `Canopy ${rectangleIndex}`;
      }

      //deal with dimensions of a copy and pasted rectangle/canopy
      let newDimension;
      if (action.options.dimension) {
        newDimension = { ...action.options.dimension };
      } else {
        newDimension = { ...action.options.product.base_dimension };
      }

      rect_object = {
        id: action.options.id,
        geoJson,
        azimuth: 180,
        rotation: 0,

        orientation: 1, // 1 = portrait (default), 2 = landscape
        planeType: action.options.planeType,
        tilt: action.options.product.tilt,
        towardAzimuth: 5,
        tiltToAzimuth: 6,
        tiltFromAzimuth: 7,

        product: action.options.product,
        module: action.options.module,
        dimension: newDimension,
        modules: undefined || action.options.modules,
      };

      return {
        ...state,
        selectedRectId: action.options.id,
        activeTool: undefined,
        canopyName: geoJson.properties["name"],
        prodModSelection: {
          ...prodModSelection,
          selectedCarportProductIndex: -1,
          selectedCarportModuleIndex: -1,
        },
        rectangles: { ...rectangles, [action.options.id]: rect_object },
      };

    case carportConstants.UPDATE_RECTANGLE:
      // rotation is in radians
      // let rotation_degrees = action.options.rotation * (180 / Math.PI);
      id = state.selectedRectId;
      let edit_rect = rectangles[state.selectedRectId];
      // grab the name before we start updating. Name gets deleted unless we grab it before we start shaping the data
      let name = edit_rect.geoJson.properties.name;
      let orientation = action.options.orientation || edit_rect.orientation;
      let planeType = action.options.planeType || edit_rect.planeType;
      let tilt = action.options.tilt || edit_rect.tilt;
      let towardAzimuth = action.options.towardAzimuth || edit_rect.towardAzimuth;
      let tiltToAzimuth = action.options.tiltToAzimuth || edit_rect.tiltToAzimuth;
      let tiltFromAzimuth = action.options.tiltFromAzimuth || edit_rect.tiltFromAzimuth;

      if (action.options.new_dimension) {
        // only way I can find to redraw is to update the id to a new id
        id = action.options.new_id;
        edit_rect = JSON.parse(JSON.stringify(edit_rect));
        edit_rect.id = action.options.new_id;
        edit_rect.dimension.modX = action.options.new_dimension.x;
        edit_rect.dimension.modY = action.options.new_dimension.y;
        edit_rect.dimension.x = action.options.new_dimension.x;
        edit_rect.dimension.y = action.options.new_dimension.y;
        delete rectangles[state.selectedRectId];
      }

      edit_rect.geoJson = action.geoJson;
      edit_rect.geoJson.properties["name"] = name;
      edit_rect.rotation += action.options.rotation;
      edit_rect.rotation = edit_rect.rotation > 180 ? edit_rect.rotation - 360 : edit_rect.rotation;
      edit_rect.azimuth += action.options.rotation;
      edit_rect.azimuth = edit_rect.azimuth > 360 ? edit_rect.azimuth - 360 : edit_rect.azimuth;
      edit_rect.orientation = orientation;
      edit_rect.planeType = planeType;
      edit_rect.tilt = tilt;
      edit_rect.towardAzimuth = towardAzimuth;
      edit_rect.tiltToAzimuth = tiltToAzimuth;
      edit_rect.tiltFromAzimuth = tiltFromAzimuth;

      edit_rect.modules = action.options.modules;

      return {
        ...state,
        selectedRectId: id,
        rectangles: { ...rectangles, [id]: edit_rect },
      };

    case carportConstants.DUPLICATE_RECTANGLE:
      //used for naming
      rectangleIndex = Object.values(rectangles).length + 1;

      geoJson = action.geoJson;

      if (action.prevRect.copiedFrom) {
        geoJson.properties["name"] = `Canopy ${rectangleIndex}`;
        geoJson.properties["copiedFrom"] = action.prevRect.copiedFrom;
      } else {
        geoJson.properties["name"] = `Canopy ${rectangleIndex}`;
      }

      rect_object = {
        id: action.id,
        geoJson,
        identity: 1,
        active: true,
        azimuth: action.prevRect.azimuth,
        rotation: action.prevRect.rotation,
        product: action.prevRect.product,
        module: action.prevRect.module,
        modules: action.prevRect.modules,
        planeType: action.prevRect.planeType,
        dimension: action.prevRect.dimension,
        tilt: action.prevRect.tilt,
        towardAzimuth: action.prevRect.towardAzimuth,
        tiltToAzimuth: action.prevRect.tiltToAzimuth,
        tiltFromAzimuth: action.prevRect.tiltFromAzimuth,
      };

      return {
        ...state,
        selectedRectId: action.id,
        rectangles: { ...rectangles, [action.id]: rect_object },
      };

    case carportConstants.COPY_RECTANGLE:
      // console.log(action);
      return {
        ...state,
        // copiedRectangle: copiedRect
        activeTool: "copy",
        copiedCanopyId: action.id,
      };

    case carportConstants.DELETE_RECTANGLE:
      console.log(action);
      // create copy so we dont mutate state
      let rectanglesCopy = Object.assign(rectangles);
      //delete rectangle
      delete rectanglesCopy[action.id];

      let deleteVisual = Object.assign(state.visual);
      delete deleteVisual[action.id];
      let deleted_visual = {
        [action.id]: {
          ...state.visual[action.id],
          action: "delete",
        },
      };

      return {
        ...state,
        selectGlobalOverride: false,
        selectedRectId: undefined,
        rectangles: rectanglesCopy,
        visualdIds: [...state.visualIds.filter((id) => id != action.id)],
        selected: undefined,
        visual: deleteVisual,
        // visual: {
        // 	...state.visual,
        // 	...deleted_visual
        // },
      };

    case carportConstants.PROD_MOD_SELECT:
      let carportProduct = Object.keys(products)[action.value];

      if (action.key == "selectedCarportProduct") {
        prodModSelection.selectedCarportProductIndex = action.value;
        prodModSelection.selectedCarportProduct = products[carportProduct];
      }
      if (action.key == "selectedCarportModule") {
        prodModSelection.selectedCarportModuleIndex = action.value;
        prodModSelection.selectedCarportModule = JSON.parse(JSON.stringify(state.modules[action.value]));
      }

      return {
        ...state,
        prodModSelection,
      };

    case carportConstants.UPDATE_MODULE:
      prodModSelection.selectedCarportModule[action.key] = action.value;
      return {
        ...state,
        prodModSelection,
      };

    case carportConstants.UPDATE_RECTANGLE_NAME:
      return {
        ...state,
        canopyName: action.name,
      };

    case carportConstants.SAVE_RECTANGLE_NAME:
      let edit_rect_name = rectangles[state.selectedRectId];
      edit_rect_name["name"] = action.name;
      edit_rect_name["copiedFrom"] = undefined;
      return {
        ...state,
        rectangles: {
          ...rectangles,
          [state.selectedRectId]: edit_rect_name,
        },
      };

    case carportConstants.INPUT_UPDATE:
      if (action.key == "prod_and_mod_selected") {
        prodModSelection.prod_and_mod_selected = action.value;
      }
      return {
        ...state,
        prodModSelection,
      };

    case carportConstants.SET_ACTIVE_TOOL:
      activeTool = action.tool;

      return {
        ...state,
        activeTool,
        selected: undefined,
      };

    // case carportConstants.CHANGE_MAP_COORDS:
    //   return {
    //     ...state,
    //     lat: action.coords.lat,
    //     lng: action.coords.lng,
    //   };

    case carportConstants.INIT_RESULTS_REQUEST:
      // console.log('Sending Inputs off to backend to start run...');
      uiState.view_results_table = false;
      return {
        ...state,
        hasCanopyResults: false,
        generatingCanopyResults: true,
        selectedRectId: undefined,
        copiedCanopies: JSON.parse(JSON.stringify(rectangles)),
        uiState: { ...state.uiState, inputs_changed_warning: false },
      };
    case carportConstants.INIT_RESULTS_SUCCESS:
      // console.log('Weather was loaded automatically, and run has been started with id:', action.data.run_id);
      return {
        ...state,
        generatingCanopyResults: true,
        run_id: action.data.run_id,
      };
    case carportConstants.INIT_RESULTS_ERROR:
      // console.log('There was an Error initializing this run...');
      return {
        ...state,
        generatingCanopyResults: false,
      };

    case carportConstants.GENERATE_RESULTS_REQUEST:
      // console.log('Making request to backend to see run status...');
      return { ...state };
    case carportConstants.GENERATE_RESULTS_UPDATE:
      // console.log('Percent complete:', action.complete);
      return {
        ...state,
        percentComplete: action.complete,
        generatingCanopyResults: true,
      };
    case carportConstants.GENERATE_RESULTS_SUCCESS:
      // console.log('Run has complete, results here:', action.data.data);
      let final_results = {};
      let csv_results = [];
      let total_modules = 0;
      let total_generation = 0;
      let total_capacity = 0;
      let module_wattage = 0;
      Object.values(action.data.data).map((result, index) => {
        // match the result canopy data with localcopied canopies (so we don't have to send data all over)
        let canopy = state.copiedCanopies[result.perf_id];
        // below was just for testing
        // let canopy = statee.copiedCanopies[Object.keys(statee.copiedCanopies)[0]]

        total_modules += result.module_count;
        total_generation += result.generation;
        total_capacity += result.module_count * canopy.module.rating;
        // total_capacity += result.capacity_kw;
        module_wattage = canopy.module.rating;

        let value = {
          id: canopy.id,
          Key: canopy.id,
          Name: canopy.name,
          Module: canopy.module.name,
          "Canopy Type": canopy.planeType,
          "Azimuth (°)": result.azimuth,
          "Module Qty": result.module_count,
          "String Count": result.string_count,
          "Capacity (kw)": result.capacity_kw,
          "Tilt (°)": result.tilt,
          "Generation (KWh)": result.generation,
          "Yield (kWh/kWp)": result.yield,
          "Tilt From Azimuth": canopy.planeType == "inverted" ? canopy.tiltFromAzimuth : undefined,
          "Tilt To Azimuth": canopy.planeType == "inverted" ? canopy.tiltToAzimuth : undefined,
          "Toward Azimuth": canopy.planeType == "inverted" ? canopy.towardAzimuth : undefined,
          Orientation: canopy.orientation,
          "Mod Dimension X Qty": canopy.dimensions.modX,
          "Mod Dimension Y Qty": canopy.dimensions.modY,
          "Mod Dimension X Ft": canopy.dimensions.x * 3.281,
          "Mod Dimension Y Ft": canopy.dimensions.y * 3.281,
          "Lead Quantity": canopy.lead_quantity || "N/A",
          "Linear Feet": canopy.linear_feet || "N/A",
          "MC4 Connectors": canopy.mc4_connectors || "N/A",
        };
        csv_results.push(value);
        final_results[canopy.id] = { ...value, index };
      });
      // console.log('Final formatted results here:', final_results);

      let copy_opts = { csvFields, delimiter: "\t", eol: "", header: true, withBOM: false };
      let csv_opts = { csvFields, delimiter: ",", eol: "", header: true, withBOM: false };

      return {
        ...state,
        generatingCanopyResults: false,
        hasCanopyResults: true,
        results: final_results,
        totalModules: total_modules,
        totalGeneration: total_generation,
        total_capacity: total_capacity,
        module_wattage: module_wattage,
        project_name: projectManager.currentProjectId && projectManager.projects[projectManager.currentProjectId].name,
        tsvResult: json2csv(csv_results, copy_opts),
        csvResult: json2csv(csv_results, csv_opts),
        uiState: {
          ...uiState,
          view_results_table: true,
        },
      };
    case carportConstants.GENERATE_RESULTS_FAILURE:
      console.log("Generating results has failed..");
      return {
        ...state,
        generatingCanopyResults: false,
      };

    case carportConstants.CREATE_CANOPY:
      return {
        ...state,
        currentCanopyIndex: state.currentCanopyIndex + 1,
        selectedRectId: action.canopy.id,
        activeTool: undefined,
        prodModSelection: {
          ...prodModSelection,
          selectedCarportProductIndex: -1,
        },
        canopyName: action.canopy.name,
        rectangles: { ...rectangles, [action.canopy.id]: action.canopy },
      };

    case carportConstants.UPDATE_CANOPY:
      delete rectangles[action.prevId];

      return {
        ...state,
        globalOverrides: {
          ...state.globalOverrides,
          template: {
            productIndex: -1,
          },
          module: {
            moduleIndex: -1,
          },
        },
        selectedRectId: action.canopy.id,
        rectangles: { ...rectangles, [action.canopy.id]: action.canopy },
      };

    case carportConstants.SET_NEW_PROJECT_LOADER:
      return {
        ...state,
        newProjectLoading: action.loading,
      };

    case carportConstants.START_NEW_PROJECT:
      return {
        ...state,
        selectedRectId: undefined,
        generatingCanopyResults: false,
        hasCanopyResults: false,
        currentCanopyIndex: 1,
        images: {},
        activeImageEditTool: "delete",
        selectedImageId: undefined,
        prodModSelection: {
          ...prodModSelection,
          selectedCarportProductIndex: -1,
          selectedCarportModuleIndex: -1,
        },
        selectGlobalOverride: false,
        rectangles: rectangles,
      };

    case carportConstants.SELECT_GLOBAL_OVERRIDE:
      return {
        ...state,
        selectedRectId: undefined,
        selectGlobalOverride: action.boolValue,
      };

    case carportConstants.SET_GLOBAL_OVERRIDES_INPUT:
      let globalOverrides = { ...state.globalOverrides };
      let globalProduct = Object.keys(products)[action.value];

      if (action.key == "selectGlobalProduct") {
        globalOverrides.template.productIndex = action.value;
        globalOverrides.template.globalProduct = products[globalProduct];
        globalOverrides.orientation = products[globalProduct].orientation;
      }
      if (action.key == "selectGlobalModule") {
        globalOverrides.module.moduleIndex = action.value;
        globalOverrides.module.globalModule = state.modules[action.value];
      }

      if (action.key == "setGlobalPlaneType") {
        globalOverrides.planeType = action.value;
      }

      globalOverrides[action.key] = action.value;

      return {
        ...state,
        globalOverrides,
      };

    case carportConstants.SET_ZOOM:
      return {
        ...state,
        zoom: action.value,
      };

    case portalConstants.GET_USER_DATA_SUCCESS:
      let projects = {};

      Object.values(action.data).map((proj) => {
        if (proj.project_type == 1) {
          projects[proj.id] = proj;

          projects[proj.id].inputs.id = proj.id;

          if (projects[proj.id].inputs.old_inputs) {
            let o_inp = JSON.parse(projects[proj.id].inputs.old_inputs);
            projects[proj.id].inputs = {
              id: proj.id,
              project_name: proj.name,
              project_type: 1,
              images: o_inp.images,
              rectangles: o_inp.rectangles,
            };
            delete projects[proj.id].old_inputs;
          }

          // projects[proj.id] = {
          //   id: proj.id,
          //   editDate: proj.edit_dt,
          //   name: proj.name,
          //   inputs: proj.inputs,
          //   project_type: proj.project_type,
          //   active: 1,
          //   weather: '',
          //   surface: '',
          // };
        }
      });
      projectManager.projects = projects;
      if (!projectManager.localProjects || projectManager.localProjects !== projects) {
        projectManager.localProjects = projects;
      }

      projectManager.loading = false;
      projectManager.loaded = Object.keys(projectManager.localProjects).length > 0;

      return {
        ...state,
        projectManager: projectManager,
        newProjectLoading: false,
        map: { ...state.map, zoom: 16 },
      };

    case portalConstants.GET_USER_DATA_REQUEST:
      projectManager.loading = true;
      projectManager.loaded = false;

      return { ...state, projectManager: { ...projectManager }, newProjectLoading: true };

    case portalConstants.GET_USER_DATA_FAILURE:
      projectManager.loading = false;
      projectManager.loaded = projectManager.localProjects && Object.keys(projectManager.localProjects).length > 0;
      return { ...state, projectManager, newProjectLoading: false };

    // PROJECT MANAGER
    case carportConstants.TOGGLE_PROJECTMANAGER_MODAL:
      projectManager.modal_visible = action.bool;
      return { ...state, projectManager };

    case carportConstants.NEW_PROJECT_REQUEST:
      uiState.loading = true;
      projectManager.loading = true;
      projectManager.selectedProjectId = undefined;
      projectManager.currentProjectId = undefined;

      return {
        ...state,
        uiState,
        projectManager,
        selectedRectId: undefined,
        generatingCanopyResults: false,
        hasCanopyResults: false,
        currentCanopyIndex: 1,
        images: {},
        activeImageEditTool: "none",
        selectedImageId: undefined,
        prodModSelection: {
          ...prodModSelection,
          selectedCarportProductIndex: -1,
          selectedCarportModuleIndex: -1,
        },
        selectGlobalOverride: false,
        rectangles: {},
        results: [],
        newProjectLoading: true,
      };

    case carportConstants.NEW_PROJECT_COMPLETE:
      uiState.loading = false;
      projectManager.loading = false;
      return { ...state, uiState, projectManager, newProjectLoading: false };

    case carportConstants.SELECT_PROJECT:
      projectManager.selectedProjectId = action.id;
      return { ...state, projectManager };

    case carportConstants.LOAD_PROJECT_REQUEST:
      projectManager.loading = true;
      projectManager.currentProjectId = action.id;

      uiState.view_results_table = false;

      // load inputs from project into ioManager
      let proj_inputs = JSON.parse(projectManager.localProjects[action.id].inputs);
      if (proj_inputs.images) {
        // new project structure
        rectangles = { ...proj_inputs.rectangles };
        images = { ...proj_inputs.images };
      } else {
        // old project structure
        rectangles = { ...proj_inputs };
        images = {};
      }

      // fix dimensions if missing from older project
      let visGeoJson = {};
      let visIds = [];
      let visual_from_loading;
      Object.values(rectangles).map((rectangle) => {
        if (rectangle.module_dimensions.mod_width == undefined && rectangle.module_dimensions.x) {
          rectangle.module_dimensions.mod_width = rectangle.module_dimensions.x;
          rectangle.module.mod_width = rectangle.module_dimensions.x;
        }
        if (rectangle.module_dimensions.mod_height == undefined && rectangle.module_dimensions.y) {
          rectangle.module_dimensions.mod_height = rectangle.module_dimensions.y;
          rectangle.module.mod_height = rectangle.module_dimensions.y;
        }
        if (!rectangle.visibleGeoJson || !rectangle.editCellsGeoJson) {
          rectangle.visibleGeoJson = {};
          rectangle.editCellsGeoJson = {};
        }

        // let loading_inputs = {
        //   id: rectangle.id,
        //   name: rectangle.name,
        //   azimuth: rectangle.azimuth,
        //   orientation: rectangle.orientation,
        //   base_dimension: rectangle.base_dimension,
        //   dimensions: rectangle.dimensions,
        //   modGap: rectangle.modXGap,
        //   module: rectangle.module,
        //   modX: rectangle.dimensions.modX,
        //   modY: rectangle.dimensions.modY,

        //   planeType: {
        //     type: rectangle.planeType,
        //     tilt: rectangle.tilt,
        //     towardAzimuth: rectangle.towardAzimuth,
        //     tiltToAzimuth: rectangle.tiltToAzimuth,
        //     tiltFromAzimuth: rectangle.tiltFromAzimuth,
        //   },
        // };

        // let draw_load_canopy = redraw_canopy(loading_inputs, rectangle, 'none');
        // visual_from_loading[state.selectedInputs.id] = {
        // 	id: rectangle.id,
        // 	action: 'create',
        // 	name: rectangle.name,
        // 	structure: draw_load_canopy.visibleGeoJson,
        // 	modules: draw_load_canopy.editCellsGeoJson,
        // }
        visIds.push(rectangle.id);
      });

      let canopyRectangles = [];
      Object.values(rectangles).map((rectangle) => canopyRectangles.push(rectangle.geoJson));

      if (canopyRectangles.length > 0) {
        let bounds = getBounds(canopyRectangles);
        mapBounds = [
          [bounds[1], bounds[0]],
          [bounds[3], bounds[2]],
        ];
      }

      return {
        ...state,
        projectManager,
        selectedRectId: undefined,
        generatingCanopyResults: false,
        hasCanopyResults: false,
        currentCanopyIndex: 1,
        rectangles: rectangles,
        images: images,
        prodModSelection: {
          ...prodModSelection,
          selectedCarportProductIndex: -1,
          selectedCarportModuleIndex: -1,
        },
        results: [],
        mapBounds,
        uiState,

        visual: visual_from_loading,
        visualIds: visIds,
        selected: undefined,
        map: { ...state.map, mapBounds: mapBounds },
      };

    case carportConstants.LOAD_PROJECT_COMPLETE:
      return {
        ...state,
        projectManager: { ...state.projectManager, loading: false, modal_visible: false },
      };

    case carportConstants.DELETE_PROJECT_REQUEST:
      projectManager.localProjects = projectManager.localProjects.filter((p) => p.id != action.id);
      projectManager.loading = true;
      return { ...state, projectManager };

    case carportConstants.DELETE_PROJECT_SUCCESS:
      return state;

    case carportConstants.DELETE_PROJECT_COMPLETE:
      projectManager.loading = false;
      return { ...state, projectManager };

    case carportConstants.DELETE_PROJECT_FAILURE:
      projectManager.localProjects = JSON.stringify(JSON.parse(projectManager.projects));
      projectManager.loading = false;
      return { ...state, projectManager };

    case carportConstants.SAVE_PROJECT_REQUEST:
      projectManager.loading = true;
      return { ...state, projectManager };

    case carportConstants.SAVE_PROJECT_SUCCESS:
      // console.log('the project', action.inputs);
      // console.log('the django response', action.response);
      // add project to  localProjects

      action.inputs.id = action.response.project_id;
      action.inputs.name = action.inputs.project_name;
      action.inputs.edit_dt = action.response.editDate;
      // console.log(action.id, action.date, action.project)

      projectManager.remainOpen = action.inputs.active == 0 || action.inputs.editing;

      if (action.inputs.active == 1) {
        // active project, overwrite
        if (action.inputs.editing) {
          // clear this and don't "load" the project
          action.inputs.editing = undefined;
          projectManager.remainOpen = true;
        } else {
          projectManager.selectedProjectId = action.inputs.id;
          projectManager.currentProjectId = action.inputs.id;
        }
        projectManager.localProjects = {
          ...projectManager.localProjects,
          [action.inputs.id]: {
            id: action.inputs.project_id || action.inputs.id,
            inputs: action.inputs,
            edit_dt: action.response.editDate,
            name: action.inputs.project_name || action.inputs.name,
            project_type: 1,
          },
        };

        // projectManager.localProjects[action.inputs.id]["inputs"] = action.inputs;
        // projectManager.localProjects[action.inputs.id].edit_dt = action.response.editDate;
      } else {
        // deleted project, remove
        delete projectManager.localProjects[action.inputs.id];
        projectManager.selectedProjectId = undefined;
        projectManager.currentProjectId = action.id == projectManager.currentProjectId ? undefined : projectManager.currentProjectId;
        projectManager.remainOpen = true;
      }

      console.log("project", projectManager.localProjects);
      // console.log(action	)
      return { ...state, projectManager: { ...projectManager, localProjects: { ...projectManager.localProjects } } };

    case carportConstants.SAVE_PROJECT_COMPLETE:
      // update localProjects if its not == to actions.projects
      projectManager.loading = false;
      if (projectManager.remainOpen) {
        projectManager.remainOpen = false;
      } else {
        projectManager.modal_visible = false;
      }

      return { ...state, projectManager };

    case carportConstants.SAVE_PROJECT_FAILURE:
      projectManager.localProjects = JSON.stringify(JSON.parse(projectManager.projects));
      projectManager.loading = false;
      return { ...state, projectManager };

    case carportConstants.IMPORT_IMAGE:
      if (state.selectedImageId) {
        // turn other off
        images[state.selectedImageId].editMode = "none";
      }

      return {
        ...state,
        // activeImageEditTool: 'translate',
        selectedImageId: action.imageId,
        images: {
          ...images,
          [action.imageId]: action.imageFile,
        },
      };

    case carportConstants.SELECT_IMAGE:
      if (state.selectedImageId) {
        // turn other off
        images[state.selectedImageId].editMode = "none";
      }
      images[action.imageId].editMode = "translate";

      return {
        ...state,
        activeImageEditTool: "edit",
        selectedImageId: action.imageId,
        images: images,
      };

    case carportConstants.UPDATE_IMAGE:
      return {
        ...state,
        images: {
          ...state.images,
          [action.imageId]: action.image,
        },
      };

    case carportConstants.SET_IMAGE_EDIT_TOOL:
      let imageId = state.selectedImageId;

      if (imageId) {
        images[imageId].editMode = action.tool;
      }

      if (action.tool == "none") {
        imageId = undefined;
      }
      return {
        ...state,
        activeImageEditTool: action.tool,
        selectedImageId: imageId,
        images: images,
      };

    case carportConstants.DELETE_IMAGE:
      // console.log("action", action);
      // clone images object
      let newImagesObject = { ...state.images };
      // delete selected image
      delete newImagesObject[action.imageId];

      // console.log("newImages", newImagesObject);
      return {
        ...state,
        activeImageEditTool: "none",
        images: newImagesObject,
        selectedImageId: undefined,
      };

    case carportConstants.SET_COUNTY:
      let theCounty = state.county;
      let theStateAbb = state.stateAbb;

      if (action.key == "county") {
        theCounty = action.value;
      }

      if (action.key == "stateAbb") {
        theStateAbb = action.value;
      }

      return {
        ...state,
        county: theCounty,
        stateAbb: theStateAbb,
      };

    case carportConstants.SET_UI_STATE:
      return {
        ...state,
        uiState: {
          ...uiState,
          [action.input]: action.value,
        },
      };

    case carportConstants.UPDATE_CENTER:
      return { ...state, center: action.center };

    case carportConstants.PREPARE_REPORT:
      let reportImages = state.reportImages;

      if (action.url) {
        reportImages[action.url.key] = action.url.value;
      }
      return {
        ...state,
        prepareReport: action.prepare,
        reportImages: reportImages,
        selectedRectId: undefined,
      };

    case carportConstants.CANCEL_RUN_REQUEST:
      uiState.canceling = true;
      return {
        ...state,
        uiState,
      };

    case portalConstants.IMPORTDATA_REQUEST:
      state.import_loading = true;
      // console.log(action);
      return { ...state };

    case portalConstants.IMPORTDATA_SUCCESS:
      state.import_loading = false;
      state.toggle_import = false;
      let new_object = JSON.parse(JSON.stringify(action.response.object));

      let newModules = state.modules;
      newModules.push(new_object.data);

      let index = newModules.findIndex((module) => module.name == new_object.data.name);

      // inputs.selectedModuleIndex = index == -1 ? 1 : index;
      // inputs.selectedModule = { ...new_object.data };
      prodModSelection.selectedCarportModuleIndex = index == -1 ? 1 : index;
      prodModSelection.selectedCarportModule = JSON.parse(JSON.stringify(state.modules[prodModSelection.selectedCarportModuleIndex]));

      return {
        ...state,
        modules: newModules,
        prodModSelection: { ...prodModSelection },
      };

    case portalConstants.IMPORTDATA_FAILURE:
      state.import_loading = false;
      state.toggle_import = false;
      return { ...state };

    case carportConstants.UPDATE_NON_POLY_DATA:
      // console.log(action);

      return { ...state, non_poly_cells_and_blocks: { cells: action.cells, blocks: action.blocks } };
    case carportConstants.TOGGLE_NON_POLY_EDIT:
      // console.log(action);

      return { ...state, non_poly_edit: !state.non_poly_edit };

    case carportConstants.CANOPY_WIRE_REQUEST:
      // console.log(action)
      return { ...state, wiring_loading: true, show_canopy_wires: false };
    case carportConstants.CANOPY_WIRE_SUCCESS:
      console.log(action.response.wires);
      let lead_quantity = action.response.wires.lead_qty;
      let linear_feet = _.round(action.response.wires.neg_wire_length + action.response.wires.pos_wire_length, 2);
      let mc4_connectors = lead_quantity * 2;

      let visual_wires_by_id = {
        ...state.visual,
        [action.id]: {
          ...state.visual[action.id],
          action: "create",
          wires: action.response.wires,
        },
      };

      let updated_rects = {
        ...state.rectangles,
        [action.id]: {
          ...state.rectangles[action.id],
          lead_quantity: lead_quantity,
          linear_feet: linear_feet,
          mc4_connectors: mc4_connectors,
        },
      };

      return {
        ...state,
        wiring_loading: false,
        canopy_wires: action.response.wires,
        show_canopy_wires: true,
        visual: visual_wires_by_id,
        rectangles: updated_rects,
      };
    case carportConstants.CANOPY_WIRE_FAILURE:
      console.log(action);
      return { ...state, wiring_loading: false, show_canopy_wires: false };

    default:
      return state;
  }
}

export function getInputs(state) {
  return convert_to_swm_canopy(state.rectangles, [0, 0]);
}

export function getCellsAndBlocks(non_poly_cells_and_blocks) {
  return {
    cells: non_poly_cells_and_blocks.cells,
    blocks: non_poly_cells_and_blocks.blocks,
  };
}

export function getCanopyProps(state) {
  return {
    rectangles: state.rectangles,
    project_loading: state.projectManager.loading,
    selectedRectId: state.selectedRectId,
    prepareReport: state.prepareReport,
    selectedImageId: state.selectedImageId,
    mapBounds: state.mapBounds,
    images: state.images,
    activeTool: state.activeTool,
    selectGlobalOverride: state.selectGlobalOverride,
    copiedRectangle: state.copiedRectangle,
    currentCanopyIndex: state.currentCanopyIndex,
    prodModSelection: state.prodModSelection,
    modules: state.modules,
    zoom: state.zoom,
    lat: state.lat,
    lng: state.lng,
    zoomGranularity: state.zoomGranularity,
    county: state.county,
    stateAbb: state.stateAbb,
    county: state.county,
    stateAbb: state.stateAbb,
    activeImageEditTool: state.activeImageEditTool,
    generatingCanopyResults: state.generatingCanopyResults,
    percentComplete: state.percentComplete,
    results: state.results,
    view_results_table: state.uiState.view_results_table,
  };
}

export function getVisualGeoJsonLayer(rectangles) {
  let visGeoJson = [];
  Object.values(rectangles).map((rect) => {
    visGeoJson.push({
      id: rect.id,
      structure: rect.visibleGeoJson,
      visualModules: rect.editCellsGeoJson,
    });
  });

  return visGeoJson;
}
