export const inputsConstants = {
  //
  SELECT_MODULE_BY_INDEX: "SELECT_MODULE_BY_INDEX",
  SELECT_PRODUCT_BY_INDEX: "SELECT_PRODUCT_BY_INDEX",

  UPDATE_UI_STATE: "UPDATE_UI_STATE",
  UPDATE_BULK_UI_STATE: "UPDATE_BULK_UI_STATE",

  UPDATE_INPUT_BY_NAME: "UPDATE_INPUT_BY_NAME",
  UPDATE_BULK_INPUTS: "UPDATE_BULK_INPUTS",
  UPDATE_SITE_INPUT: "UPDATE_SITE_INPUT",

  RESET_INPUTS_TO_DEFAULT: "RESET_INPUTS_TO_DEFAULT",
  CLEAR_TOPO_DATA: "CLEAR_TOPO_DATA",
  TOPO_JOB_COMPLETE: "TOPO_JOB_COMPLETE",

  TOGGLE_SF_MODAL: "TOGGLE_SF_MODAL",
  SF_CAPTURE_REQUEST: "SF_CAPTURE_REQUEST",
  SF_CAPTURE_FAILURE: "SF_CAPTURE_FAILURE",
  SF_CAPTURE_SUCCESS: "SF_CAPTURE_SUCCESS",
  SF_CAPTURE_RESET: "SF_CAPTURE_RESET",
};
