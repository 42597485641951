import React, { useEffect, Fragment } from "react";
import { basicSearch } from "../../../../../assets";
import { Tooltip, Button, Input, message } from "antd";

// Geocode for search
import Geocode from "react-geocode";

// redux
import { useSelector, useDispatch } from "react-redux";
import { carportActions } from "../../../../Redux/actions/carport.actions";

import { Control } from "../styles";

const MapSearch = () => {
  const activeTool = useSelector((state) => state.carport.activeTool);
  const dispatch = useDispatch();

  useEffect(() => {
    // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
    Geocode.setApiKey("AIzaSyDFwy0txveMzEYIi42SOSlD2hwq9h9Xqb8");
    // Enable or disable logs. Its optional.
    Geocode.enableDebug();
  });

  const handleSearch = (value) => {
    if (value.length < 3) {
      return;
    }
    // search for the value
    Geocode.fromAddress(value).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;

        dispatch(carportActions.changeMapCoords({ lng, lat }));
      },
      (error) => {
        message.warning("Sorry, that Location was not found");
        console.error(`💥🔥🔥🔥🔥🔥${error}🔥🔥🔥🔥🔥💥`);
      }
    );
  };

  return (
    <Fragment>
      <Control column={1}>
        <Tooltip title="Search">
          <Button
            shape="circle"
            size="large"
            style={{
              margin: "0 4px",
              background: activeTool == "search" ? "#60de4f" : null,
            }}
            onClick={() => {
              dispatch(carportActions.setActiveTool(activeTool == "search" ? undefined : "search"));
            }}
          >
            <img style={{ marginBottom: "2px" }} src={basicSearch} />
          </Button>
        </Tooltip>
      </Control>
      <Control column={4} row={1}>
        {activeTool == "search" && (
          <Input.Search
            ref={(input) => input && input.focus()}
            style={{ left: "5px", width: "300px" }}
            placement="buttom"
            placeholder="Latitude, Longitude or Address"
            onSearch={(value) => handleSearch(value)}
          ></Input.Search>
        )}
      </Control>
    </Fragment>
  );
};

export { MapSearch };
