import styled from 'styled-components';

export const ToolbarLayout = styled.section`
	display: grid;
	position: relative;
	/* grid-template-columns: repeat(4,auto); */
	grid-template-rows: repeat(2, 46px);
	align-items: center;
	justify-content: start;
	align-content: center;

`;