import React, { Fragment } from 'react';

import { trashCan } from '../../../../../assets';
import { Tooltip, Button, Popconfirm } from 'antd';

import { carportActions } from '../../../../Redux/actions/carport.actions';
import { useDispatch } from 'react-redux';

import { Control } from '../styles'

const DeleteAll = () => {
	const dispatch = useDispatch();

  return (
    <Control column={3}>
      <Tooltip title="Delete All" placement="right">
        <Popconfirm
          title="Confirm Delete All?"
          placement="bottom"
          onConfirm={(e) => {
            dispatch(carportActions.deleteAllRectangles());
          }}
          onCancel={() => console.log('canceled')}
          okText="Yes"
          cancelText="No"
        >
          <Button shape="circle" size="large" style={{ margin: '0 4px' }} onClick={(e) => dispatch(carportActions.selectLayer(undefined, undefined))}>
            <img style={{ marginBottom: '2px', filter: 'brightness(0.1) sepia(1) hue-rotate(180deg) saturate(2)' }} src={trashCan} />
          </Button>
        </Popconfirm>
      </Tooltip>
    </Control>
  );

}


export { DeleteAll }