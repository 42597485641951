import React, { useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { sendBugReportSecure } from "../../utils/analysis";

import { Modal, Result, Spin, Input } from "antd";
import { collectGMInputs, getCanopyInputs } from "../Redux";

const BugReport = (props) => {
  const [bugSending, setbugSending] = useState(false);
  const [bugSent, setbugSent] = useState(false);
  const [bugDesc, setbugDesc] = useState("");

  const carport = useSelector((state) => state.carport);
  const portal = useSelector((state) => state.portal);
  const inputs = useSelector((state) => state.inputs);

  const hideBugReport = () => {
    // this.setState({ bugVisible: false, bugSending: false, bugSent: false });
    setbugDesc("");
    setbugSending(false);
    setbugSent(false);
    props.toggleVis(false);
  };
  const handleBugReport = (event) => {
    // this.setState({ bugSending: true });
    // let portal_inputs = props.page == "/" ? collectGMInputs(state) : { rectangles: carport.rectangles, images: {} };
    // let features = props.page == "/" ? JSON.stringify(Object.values(inputs.site_features)) : "";
    setbugSending(true);

    let params;

    if (props.page == "/") {
      params = {
        message: `RBI Bug Report(Portal) - ${bugDesc}`,
        // surface: features,
        inputs: { ...inputs },
        project_type: 0,
      };
    } else {
      let user = JSON.parse(localStorage.getItem("user"));

      let new_input_structure = {};

      Object.values(carport.rectangles).map((rect) => {
        new_input_structure[rect.id] = {
          ...rect,
          visibleGeoJson: undefined,
          editCellsGeoJson: undefined,
        };
      });

      params = {
        message: `RBI Bug Report(Canopy) - ${bugDesc}`,
        inputs: {
          rectangles: new_input_structure,
          images: {},
          project_name: carport.projectManager.currentProjectId ? carport.projectManager.localProjects[carport.projectManager.currentProjectId].name : `Bug Report - ${user}`,
          active: 1,
        },
        project_type: 1,
      };
    }
    // Send bug report to backend
    let resp = sendBugReportSecure({ ...params });

    setTimeout(() => {
      setbugSending(false);
      setbugSent(true);

      setTimeout(() => {
        hideBugReport();
      }, 2000);
    }, 2000);
  };
  // LEAVE THIS HERE
  const handleBugDescUpdate = (desc) => {
    // this.setState({ bug_desc: desc });
    setbugDesc(desc);
  };

  return (
    <BugReportModal bugVisible={props.visible} bugSending={bugSending} bugSent={bugSent} handleBugReport={handleBugReport} hideBugReport={hideBugReport} handleBugDescUpdate={handleBugDescUpdate} />
  );
};

const BugReportModal = (props) => {
  /* inputs
      bugVisible
      bugSending
      bugSent
      handleBugReport
      hideBugReport
      handleBugDescUpdate
    */
  return (
    <Modal
      title="Bug Report"
      visible={props.bugVisible}
      onOk={props.handleBugReport}
      okText="Send"
      confirmLoading={props.bugSending}
      onCancel={props.hideBugReport}
      okButtonProps={{ disabled: props.bugSent }}
    >
      {props.bugSent ? (
        <div>
          Success
          <Result status="success" title="Thank you for the report!" subTitle="This window will close in 2 seconds" />
        </div>
      ) : (
        <div>
          <div style={{ height: "5px" }}></div>
          {props.bugSending ? (
            <Spin spinning={props.bugSending} tip="Sending report...">
              <div style={{ width: "100%", height: "75px" }}></div>
            </Spin>
          ) : (
            <Input.TextArea rows={3} placeholder="Enter a short description of the bug." onChange={(e) => props.handleBugDescUpdate(e.target.value)} />
          )}
        </div>
      )}
    </Modal>
  );
};

export { BugReport };
