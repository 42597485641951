import React from 'react'

import {
	AzimuthInput,
	CanopyDimensionInput,
	ModuleGapInput,
	ModuleHeightInput,
	ModuleSelect,
	ModuleWidthInput,
	ModulesLengthInput,
	ModulesWidthInput,
	NameInput,
	OrientationInput,
	PlaneTypeInput,
	GenerateWireDiagram
} from '../InputComponents'


export const canopyInputs = [
	{ var_n: 'name', label: '', component: <NameInput /> },
	{ var_n: 'modules', label: '', component: <ModuleSelect /> },
	{ var_n: 'orientation', label: '', component: <OrientationInput /> },
	{ var_n: 'modY', label: '', component: <ModulesWidthInput /> },
	{ var_n: 'modX', label: '', component: <ModulesLengthInput /> },
	{ var_n: 'module_height', label: '', component: <ModuleHeightInput /> },
	{ var_n: 'module_width', label: '', component: <ModuleWidthInput /> },
	{ var_n: 'modGap', label: '', component: <ModuleGapInput /> },
	{ var_n: 'dimensions', label: '', component: <CanopyDimensionInput /> },
	{ var_n: 'azimuth', label: '', component: <AzimuthInput /> },
	{ var_n: 'planeType', label: '', component: <PlaneTypeInput /> },
	{ var_n: 'wire_diagram', label: '', component: <GenerateWireDiagram /> },
	// modY: 2,
	// base_dimension: { modX: 4, modY: 2 },
	// dimensions: {},

	// module: undefined,	
	// // module_height, mlm_Width
	// // module_width, mlm_Length

	// azimuth: 180,
	
	// module_to_edge_gap: 0.02, // in meters
	// modXGap: 0.02, // in meters
	// modYGap: 0.02, // in meters
	// edgeOffset: 0.02, // in meters

	// orientation: 1, // 1 = portrait (default), 2 = landscape
	// planeType: 'single_slope',

	// // used for single_slope & dual_tilt
	// tilt: 7,

	// // used for inverted
	// towardAzimuth: 0,
	// awayAzimuth: 0,
	// tiltToAzimuth: 0,
	// tiltFromAzimuth: 0,

	// origin: [0,0],
	// coords: [],
	// geoJson: {},


]
const input_components = [
	<AzimuthInput />,
	<CanopyDimensionInput />,
	<ModuleHeightInput />,
	<ModuleWidthInput />,
	<ModuleSelect />,
	<ModulesLengthInput />,
	<ModulesWidthInput />,
	<OrientationInput	 />,
]