/*
	EditGeoJsonLayer.js
	 Functional component that renders nothing,
	 When a visual layer is selected, the leaflet layer
	 as well as the base geoJson is saved in the selected object
	 in the store, this way this component is the only one that
	 renders when selected{} is updated

	 We store the previous selected{}
	 As well as the previous created edit leaflet Layer

	 When a vis layer is selected:
	 	1- if there is a previous selected, reset its style and remove the created edit layer
		2- create a new editable geoJson layer with the path.transform enabled
		3- add editable geoJson to map and save in the ref
*/

import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { carportActions, usePrevious } from "../../Redux";
import { message } from "antd";
import "./Path.Transform";

function SelectedGeoJsonLayer({ mapRef }) {
  const dispatch = useDispatch();
  // selected = layerRef and geoJsonRef
  // -- layerRef is the leaflet layer that was clicked from the visual layer
  // -- geoJsonRef is the base geoJson shape of the layer that was clicked
  // -- idRef is the ID of the canopy object selected
  const _selected = useSelector((state) => state.carport.selected);
  const copiedRectangle = useSelector((state) => state.carport.copiedRectangle);
  // useRef naturally does NOT cause a re-render when you set it
  // this is important because this only re-renders when selected{}
  // is changed, but if we useState, then in the useEffect to handle
  // selected{} being updated, it will cause a second re-render when
  // we setState.
  const prevEditLayer = useRef(undefined);
  // console.log('selected render')

  // save the previous _selected
  const prevSelected = usePrevious(_selected);

  useEffect(() => {
    if (_selected && mapRef) {
      if (prevSelected) {
        stopEditing(prevSelected.layerRef, prevEditLayer.current);
      }

      startEditing(_selected);
    } else if (_selected === undefined && prevSelected !== undefined) {
      stopEditing(prevSelected.layerRef, prevEditLayer.current);
    }
  }, [_selected]);

  function stopEditing(visLayer, editLayer) {
    if (visLayer) visLayer.setStyle({ color: "#60de4f" });
    editLayer.edit.transform.disable();
    editLayer.edit.dragging.disable();
    editLayer.edit.off("rotateend");
    editLayer.edit.off("dragend");
    editLayer.edit.off("scaleend");
    editLayer.edit.off("drag");
    editLayer.edit.off("rotatestart");
    editLayer.edit.off("dragstart");
    editLayer.edit.off("scalestart");
    mapRef.off("keydown");
    mapRef.removeLayer(editLayer.edit);
    mapRef.removeLayer(editLayer.azi);
  }

  function startEditing(selected) {
    if (selected.layerRef) selected.layerRef.layer?.bringToFront();

    let editLayer = L.geoJSON(selected.geoJsonRef, {
      transform: true,
      draggable: true,
      scaling: true,
    });
    let editIndex = editLayer._leaflet_id - 1;
    let aziLayer = L.geoJSON(selected.azimuthHolder, {
      transform: false,
      draggable: false,
      scaling: false,
    });
    let aziIndex = aziLayer._leaflet_id - 1;
    prevEditLayer.current = { edit: editLayer._layers[editIndex], azi: aziLayer._layers[aziIndex] };

    editLayer.addTo(mapRef);
    aziLayer.addTo(mapRef);

    editLayer._layers[editIndex].setStyle({ color: "#002bcb" });
    editLayer._layers[editIndex].transform.enable();
    editLayer._layers[editIndex].dragging.enable();
    editLayer._layers[editIndex].transform.setOptions({ uniformScaling: false });

    aziLayer._layers[aziIndex].setStyle({ color: "none", fillColor: "#60de4f", fillOpacity: "0.8" });

    editLayer._layers[editIndex].on("rotateend", (e) => {
      // updateRectangle(e, e.rotation);
      let rotated_geoJson = e.layer.toGeoJSON();
      rotated_geoJson.properties.rotation = e.rotation;
      // console.log('drag end -- need to update all the geoJson layers')
      dispatch(carportActions.updateCanopyById(_selected.idRef, rotated_geoJson, "rotateend"));
    });

    editLayer._layers[editIndex].on("dragstart", (e) => {
      // filter out the canopy that is seleceted as to compare the selected against all the other canopies
      // if (alignmentToolOn.current) {
      //   let filteredAlignmentCanopies = Object.values(carport.rectangles).filter((rectangle) => rectangle.id != carport.selectedRectId);
      //   // setFilteredCanopies([...filteredAlignmentCanopies]);
      //   availableAlignmentCanopies.current = [...filteredAlignmentCanopies];
      // }
      dispatch(carportActions.toggleDraggingSelected());
    });

    editLayer._layers[editIndex].on("drag", (e) => {
      // if (alignmentToolOn.current) {
      //   checkCanopyAlignment(e);
      // }
      // console.log('dragging -- need to check is alignment is on')
    });

    editLayer._layers[editIndex].on("dragend", (e) => {
      // updateRectangle(e, e.rotation, alignmentCanopyIds.current);
      // alignmentCanopyIds.current = undefined;
      // forceUpdate();
      // console.log('drag end -- need to update all the geoJson layers')
      dispatch(carportActions.updateCanopyById(_selected.idRef, e.target.toGeoJSON(), "dragend"));
    });

    editLayer._layers[editIndex].on("scaleend", (e) => {
      // updateRectangle(e);
      // console.log('scale end -- need to update all the geoJson layers')
      dispatch(carportActions.updateCanopyById(_selected.idRef, e.layer.toGeoJSON(), "scaleend"));
    });

    editLayer._layers[editIndex].on("rotatestart", (e) => {
      // setMovingRectId(carport.selectedRectId);
    });

    editLayer._layers[editIndex].on("dragstart", (e) => {
      // setMovingRectId(carport.selectedRectId);
    });

    editLayer._layers[editIndex].on("scalestart", (e) => {
      // setMovingRectId(carport.selectedRectId);
    });

    mapRef.on("keydown", onKeyDown);
  }

  function onKeyDown(e) {
    // if the search bar is active disable hotkey presses so that one may type stuff into the search bar without firing off other events
    // if (carport.activeTool == 'search' && !e.keyCode == 27) return;
    // console.log(e.originalEvent)

    if (e.originalEvent.keyCode == 27) {
      dispatch(carportActions.selectLayer(undefined, undefined));
    }

    // alignment tool hotey functonality
    if (e.originalEvent.altKey) {
      if (e.originalEvent.keyCode == 65) {
        dispatch(carportActions.setActiveTool("alignment"));
      }
    }
    if (e.originalEvent.altKey && e.originalEvent.shiftKey) {
      // Rotation right
      if (e.originalEvent.keyCode == 39) {
        dispatch(carportActions.updateCanopyById(_selected.idRef, undefined, "rotateright"));
      }
      // Rotation left
      if (e.originalEvent.keyCode == 37) {
        dispatch(carportActions.updateCanopyById(_selected.idRef, undefined, "rotateleft"));
      }
    }

    // check to see if control or CMD is pressed
    if ((e.originalEvent.metaKey && e.originalEvent.shiftKey == false) || (e.originalEvent.ctrlKey && e.originalEvent.shiftKey == false)) {
      // delete canopy
      if (e.originalEvent.keyCode == 8) {
        if (e.originalEvent) e.originalEvent.view.L.DomEvent.stopPropagation(e);
        if (e.nativeEvent) e.nativeEvent.view.L.DomEvent.stopPropagation(e);
        dispatch(carportActions.deleteRectangle(_selected.idRef));
      }
      // duplicate right
      if (e.originalEvent.keyCode == 39) {
        if (e.originalEvent) e.originalEvent.preventDefault();
        if (e.nativeEvent) e.nativeEvent.preventDefault();
        dispatch(carportActions.duplicateCanopy("right"));
      }
      // duplicate left
      if (e.originalEvent.keyCode == 37) {
        // the prevent default has to be here on the duplicate left as the browser will just go back a page(befault browser behavior)
        if (e.originalEvent) e.originalEvent.preventDefault();
        if (e.nativeEvent) e.nativeEvent.preventDefault();
        dispatch(carportActions.duplicateCanopy("left"));
      }
      // duplicate up
      if (e.originalEvent.keyCode == 38) {
        if (e.originalEvent) e.originalEvent.preventDefault();
        if (e.nativeEvent) e.nativeEvent.preventDefault();
        dispatch(carportActions.duplicateCanopy("up"));
      }
      // duplicate down
      if (e.originalEvent.keyCode == 40) {
        if (e.originalEvent) e.originalEvent.preventDefault();
        if (e.nativeEvent) e.nativeEvent.preventDefault();
        dispatch(carportActions.duplicateCanopy("down"));
      }
      if (e.originalEvent.keyCode == 67) {
        dispatch(carportActions.copyRectangle(_selected.idRef));
        message.info("Click map to paste Canopy", 5);
      }
      if (e.originalEvent.keyCode == 86) {
        if (!copiedRectangle) return; // if there is no copied rectangle, just return
        mapRef.fire("click");
        // document.querySelector('#leaflet-map').click();
        // mapRef.on('mousemove', (e) => pasteRectangle(e.originalEvent.latlng));
      }
    }

    if ((e.originalEvent.metaKey && e.originalEvent.shiftKey) || (e.originalEvent.ctrlKey && e.originalEvent.shiftKey)) {
      // translate right
      if (e.originalEvent.keyCode == 39) {
        // handleCanopyTranslate('right');
        dispatch(carportActions.updateCanopyById(_selected.idRef, undefined, "translateright"));
      }
      // translate left
      if (e.originalEvent.keyCode == 37) {
        // handleCanopyTranslate('left');
        dispatch(carportActions.updateCanopyById(_selected.idRef, undefined, "translateleft"));
      }
      // translate up
      if (e.originalEvent.keyCode == 38) {
        // handleCanopyTranslate('up');
        dispatch(carportActions.updateCanopyById(_selected.idRef, undefined, "translateup"));
      }
      // translate down
      if (e.originalEvent.keyCode == 40) {
        // handleCanopyTranslate('down');
        dispatch(carportActions.updateCanopyById(_selected.idRef, undefined, "translatedown"));
      }
    }
  }
  return <></>;
}

export { SelectedGeoJsonLayer };
