import { inputsConstants, portalConstants } from "../constants";
import { authHeader } from "../helpers";
import config from "config";

const loopLimit = 500;

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const run_job = (inputs, action = undefined) => {
  return {
    // started undefined till we get a job_id
    job_id: undefined,
    // temp var till normalized across all jobs
    job_string: "job_id",
    // action the job is taking
    action: action,
    // inputs sent to backend -- some jobs won't send any
    inputs: inputs,
    // job request action for redux
    request() {
      return { type: portalConstants.INIT_RESULTS_REQUEST };
    },
    // get job id (should be standardized across all jobs)
    async get_job_id(inputs) {
      const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(inputs),
      };
      return fetch(`${config.apiUrl}/dash/jobs/`, requestOptions).then((response) => response.json());
    },
    async upload_to_s3(data) {
      // implement later
    },
    // poll job id (should be standardized across all jobs essentially)
    async poll_job(job) {
      const requestOptions = {
        method: "GET",
        headers: { ...authHeader(), "Content-Type": "application/json" },
      };
      let loopBool = true;
      let loopCount = 0;
      let results = { error: false };
      // polling loop
      while (loopBool) {
        // poll request to backend
        var job_poll_result = await fetch(`${config.apiUrl}/dash/jobs/?job_id=${job.job_id}`, requestOptions).then((response) => response.json());
        // console.log("job poll results", job_poll_result["status"]);
        if (job_poll_result["status"] == 100) {
          loopBool = false;
          if (job_poll_result["count"] == 0) {
            results.error = { msg: "Error Running Project" };
            return results;
          }
          //
          results.output = job_poll_result;
          results.meta = job.inputs;
          return results;
        } else if (job_poll_result["status"] == 97 || loopCount > loopLimit) {
          // ERROR OR TIMEOUT
          loopBool = false;
          results.error = { msg: "Error Running Project" };
          return results;
        } else {
          // wait a cool 1000ms then loop again
          await sleep(1000);
          loopCount += 1;
        }
      }
    },
    // does something need to be done before final_complete?
    async pre_complete(pre_results) {
      // console.log('pre_complete', pre_results)
      if (pre_results.error.msg == undefined) {
        var layout_response = await get_layout(pre_results.output.results[0].id);

        var download_json = await download_from_s3(layout_response);

        // console.log(download_json)
        pre_results.output.layout = download_json;
        // console.log('download_from_s3', pre_results)
      }

      return pre_results;
    },
    // completion of job -- maybe we handle the redux updates here?
    complete(results) {
      // console.log('complete', results)
      let _results;
      if (results.error) {
        return { type: portalConstants.GENERATE_RESULTS_FAILURE, data: results };
      } else {
        _results = results.output;
        _results.meta = results.meta;
        return { type: portalConstants.GENERATE_RESULTS_SUCCESS, data: _results };
      }
    },
  };
};

async function get_layout(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${config.apiUrl}/dash/swm/layout?resultId=${id}`, requestOptions).then((response) => response.json());
}
async function download_from_s3(url) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "attachment" },
  };
  return fetch(url, requestOptions).then((response) => response.json());
}
function handleResponse(response) {
  return response.text().then((text) => {
    // console.log(text)
    let data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
