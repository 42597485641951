import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Menu, Popconfirm } from 'antd';
import { PlusOutlined,
  SaveOutlined,
  EllipsisOutlined,
  FolderOpenOutlined } from '@ant-design/icons';

import matchSorter from 'match-sorter';
import { ProjectManagerModal } from './modals';

// utils
import { shareProject } from '../../utils/analysis';

// Redux
import { portalActions } from '../Redux/actions/portal.actions';
import { useSelector, useDispatch } from 'react-redux';

const ProjectManager = (props) => {
  // get the portal store from redux
  const portal = useSelector((state) => state.portal);
  const dispatch = useDispatch();

  // set up some state hooks
  const [selectedProject, setSelectedProject] = useState(undefined);
  const [filterText, setFilterText] = useState('');
  const [filteredProjects, setFilteredProjects] = useState();
  const [filteredInfo, setFilteredInfo] = useState(undefined);
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: 'edit_dt',
    order: 'descend',
  });

  useEffect(() => {
    setFilteredProjects(portal.Projects);
  }, [portal.Projects]);

  // Project Manager Functions

  const handleChangeSort = (pagination, filters, sorter) => {
    let _sorter = sorter;
    if (!sorter.order) {
      // interrupt the default functionality
      _sorter = sortedInfo;
      _sorter.order = 'ascend';
    }

    setSortedInfo(_sorter);
    setFilteredInfo(filters);
  };

  const handleClose = () => {
    dispatch(portalActions.toggleOpenClose(false));
  };

  const loadProject = async () => {
    dispatch(portalActions.loadProject(portal.selectedProject));
  };

  const handleProjectNameUpdate = (value) => {
    dispatch(portalActions.projectNameUpdate(value));
  };

  const handleProjectShare = async (email, project_share) => {
    let params = {
      message: `Project Shared with you.`,
      email: email,
      name: project_share.name,
      surface: project_share.surface,
      inputs: project_share.inputs,
    };

    let resp = await shareProject({ ...params });

    return resp;
  };

  const handleLoadProjectRow = (event, record, rowIndex) => {
    setSelectedProject(record.id);
    loadProject();
  };

  const handleDeleteProject = (record) => {
    let project_to_delete = portal.Projects.find((project) => record.id == project.id);
    project_to_delete.active = 0;
    dispatch(portalActions.deleteProject(project_to_delete));
  };

  const handleProjectSelect = (event, record, rowIndex) => {
    dispatch(portalActions.projectSelect(record.id));
  };

  return (
    <div>
      <Popconfirm title="Start a new project?" onConfirm={(e) => props.OnTopLevelPressed('new')}>
        <Button disabled={portal.uiControls.loading} style={{ marginRight: '5px' }}>
          <span>
          <PlusOutlined /> New
          </span>
        </Button>
      </Popconfirm>

      <Button.Group>
        <Button loading={portal.uiControls.loading} onClick={(e) => props.OnTopLevelPressed('save')}>
          <span>
            <SaveOutlined /> Save
          </span>
        </Button>
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu onClick={(e) => props.OnTopLevelPressed('saveas')}>
              <Menu.Item key="1">
                <SaveOutlined />
                Save As
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
        >
          <Button style={{ paddingLeft: '8px', paddingRight: '8px' }}>
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      </Button.Group>

      <Button
        disabled={portal.Projects.length == 0}
        style={{ marginRight: '5px', marginLeft: '5px' }}
        onClick={(e) => props.OnTopLevelPressed('load')}
      >
        <span>
          <FolderOpenOutlined /> Load
        </span>
      </Button>

      {portal.current_project.name && (
        <label className="mod-label project">
          <span style={{ paddingLeft: '10px' }}>{portal.current_project.name}</span>
        </label>
      )}

      <ProjectManagerModal
        loading={portal.uiControls.project_loading || portal.uiControls.loading}
        modalMode={portal.uiControls.modalMode}
        visible={portal.uiControls.showProjectLoader}
        handleClose={handleClose}
        saveProject={props.saveProject}
        loadProject={loadProject}
        onShareProject={handleProjectShare}
        handleProjectNameUpdate={handleProjectNameUpdate}
        handleLoadProjectRow={handleLoadProjectRow}
        handleDeleteProject={handleDeleteProject}
        handleProjectSelect={handleProjectSelect}
        FilteredProject={filteredProjects}
        selectedProject={portal.selectedProject}
        handleChangeSort={handleChangeSort}
        project_sortedInfo={props.project_sortedInfo}
      />
    </div>
  );
};

export { ProjectManager };
