const selectedResultOrder = [
  "Year 1",
  "Yield",
  "Capacity",
  "Module Qty", 
  "Mods/Str",
  "Module",
  "GCR",
  "Azimuth",
  "Tilt", 
  "Racking", 

	"Wire Length (ft)",
	"Combiner Box Count",
  "wire_length_ft", 
  "combiner_count", 

  
  'racking_name',
  'tilt',
  'azimuth',
  'gcr',
  'module_name',
  'module_count',
  'modules_per_string',
  'capacity',
  'YIELD',
  'generation',

  'id',
  'layout_id',
  'rtr',
  'pitch',
  'spi',
  'dcac',
  'inverter_count',
  'rack_count',
  'string_count',
  'rack_breakdown',
  'removed_modules',
  'generation_array',
  'dc_degradation',
  'horizontal_global_irradiance',
  'global_incident_in_col_plane',
  'near_shadings_irradiance_loss',
  'iam_factor_on_global',
  'soiling_loss',
  'bifacial_gain',
  'global_effective_irradiation_on_col',
  'array_nominal_energy',
  'pv_loss_irrad',
  'pv_loss_temp',
  'pv_elec_loss',
  'lid_loss',
  'quality_loss',
  'dc_mismatch_loss',
  'ohmic_wiring_loss',
  'e_array',
  'inverter_loss',
  'ac_loss',
  'interconnect_lim_loss',
  'table_a',
  'table_b',
  'table_c',
  'table_d', 
];

export default selectedResultOrder
