import React, { Component, useState, useEffect } from "react";
import styled from "styled-components";
import { divStyle, overlayStyle } from "../components/Sunfig/data/defaultStyles";

// components
import { GroundMountControlPanel, ImportTool } from "../components";
import { GroundMountMap } from "../components/Sunfig";

// libraries
import Dropzone from "react-dropzone";
import ZipLoader from "zip-loader";
import XMLParser from "react-xml-parser";
import textEncoding from "text-encoding";

// antd
import { message } from "antd";

// utils
import { loadSurfaceFromXML } from "../utils/map_helper.js";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { portalActions, inputsActions } from "../components/Redux/actions";

const GroundMountWrapper = styled.section`
  display: grid;
  position: relative;
  grid-template-columns: 325px 1fr;
`;

const GroundMount = () => {
  const portal = useSelector((state) => state.portal);
  const dispatch = useDispatch();

  // state
  const [dropzoneActive, setDropzoneActive] = useState(false);
  const [files, setFiles] = useState(undefined);
  const [surfaceName, setSurfaceName] = useState(undefined);
  const [kmzImported, setKmzImported] = useState(false);
  const [selectedSurfaceIndex, setSeclectedSurfaceIndex] = useState(0);

  const onDragEnter = () => {
    if (dropzoneActive) {
      return;
    }
    setDropzoneActive(true);
  };

  const onDragLeave = () => {
    if (!dropzoneActive) {
      return;
    }
    setDropzoneActive(false);
  };

  const loadFile = (poly) => {
    let polys;
    if (poly.length && poly.length > 0) {
      // append KMZ to map
      polys = { ...portal.inputs.features, ...poly };

      // overwrite KMZ to map
      // polys = [...poly];
      dispatch(portalActions.uploadKMZFeatures(polys));
      Object.values(polys).map((poly) => {
        console.log(poly);
        dispatch(inputsActions.update_site_input("add_site_feature", poly));
      });
    } else if (poly.length == 0) {
      // CLEAR POLYGONS
      dispatch(portalActions.updateInput("features", []));
      dispatch(portalActions.updateInput("selectedFeatureId", undefined));
    }
  };

  // handles files that get dropped into the map
  const onDrop = (files) => {
    setFiles(files);
    setDropzoneActive(false);

    let selectedFile = files[0];

    setSurfaceName(selectedFile.name);
    setKmzImported(true);
    setSeclectedSurfaceIndex(-2);

    let file_type = selectedFile.name.substr(selectedFile.name.length - 3).toLowerCase();

    if (file_type == "kml") {
      var reader = new FileReader();

      reader.onloadend = function(evt) {
        if (evt.target.readyState == FileReader.DONE) {
          // DONE == 2
          var xml = new XMLParser().parseFromString(evt.target.result);
          let features = loadSurfaceFromXML(xml);
          loadFile(features);
        }
      }.bind(this);
      reader.readAsBinaryString(selectedFile);
    } else if (file_type == "kmz") {
      // If file is *.kmz we need to unzip the .kml to get the polygons
      ZipLoader.unzip(selectedFile).then((ZipLoaderInstance) => {
        // unzipping the doc.kml returns an object that holds the reference to a Utf8Array buffer which holds the kml info
        let TextDecoder = textEncoding.TextDecoder;
        let all_features = [];
        let files_names = Object.keys(ZipLoaderInstance.files);
        for (let file in files_names) {
          if (files_names[file].substr(-3, 3).toLowerCase() == "kml") {
            let string = new TextDecoder("utf-8").decode(ZipLoaderInstance.files[files_names[file]]["buffer"]);
            // parse the xml document from the string
            // console.log(string)
            let xml = new XMLParser().parseFromString(string);
            let features = loadSurfaceFromXML(xml);
            all_features.push(...features);
          }
        }
        loadFile(all_features);
      });
    } else {
      message.error(`Cannot import file type of ${file_type}. Only .kmz/.kml acceptable.`, 3);
    }
  };

  return (
    <GroundMountWrapper>
      <GroundMountControlPanel />
      <Dropzone key="dropzone" style={divStyle} onDrop={onDrop} onDragEnter={onDragEnter} onDragLeave={onDragLeave} disableClick={true}>
        {dropzoneActive ? <div style={overlayStyle}>Drop KMZ...</div> : <div />}
        <GroundMountMap />
      </Dropzone>
      <ImportTool mode="portal" />
    </GroundMountWrapper>
  );
};

export { GroundMount };
