import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { carportActions } from '../../Redux';

import { Button, Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { SelectRow } from './styles'

function ModuleOverlay(modules, dispatch) {
	function onSelectModule(e) { 
		dispatch(carportActions.updateInputByName('module', modules[parseInt(e.key)]));
	}

	return (		
		<Menu
			style={{ height: "300px", overflowY: "auto" }}
			onClick={onSelectModule}
		>
			{Object.values(modules).map((module, index) => (
				<Menu.Item key={index} eventKey={index}>
					{module.name}
				</Menu.Item>
			))}
		</Menu>
	)
}


function ModuleSelect() {
	const dispatch = useDispatch();
	const module_name = useSelector((state) => state.carport.selectedInputs.module.name);
	const modules = useSelector((state) => state.carport.modules);
	return (
		<SelectRow>
			<Dropdown overlay={ModuleOverlay(modules, dispatch)} placement="bottomCenter" trigger={["click"]}>
				<Button style={{ width: "200px" }}>
					{module_name}
					<DownOutlined />
				</Button>
			</Dropdown>
		</SelectRow>
	)
}

export { ModuleSelect }