import styled from "styled-components";
import { motion } from "framer-motion";

const OverlapWarningBoxLayout = styled(motion.div)`
  z-index: 500;
  position: absolute;
  width: 300px;
  padding: 5px;
  height: auto;
  /* background-color: rgba(0, 0, 0, 0.7); */
  background: #fff;
  top: 15px;
  right: 65px;
  border: 1px solid #60de4f;
  border-radius: 3px;
  display: grid;
  grid-template-columns: 25px 1fr;
  cursor: default;
  place-items: start;

  .overlap-img {
    grid-column: 1;
    display: grid;
    place-items: center;

    img {
      width: 20px;
    }
  }

  .overlap-text {
    color: #333;
    grid-column: 2;
    margin-left: 10px;
    font-size: 12px;

    p {
      margin: 0;
    }
  }
`;
export { OverlapWarningBoxLayout };
